import AttachFile from "@mui/icons-material/AttachFile";
import { Box, Grid, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { cardStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import CustomCard from "components/CustomCard";
import UploadFileModal from "../UploadFileModal";
import Attachments from "../Attachments";


const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  section_image_delete: {
    position: "absolute!important",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },
});

const IssuesAttachments = () => {

  const { currentIssue } =
    useAppContextController();


  return (

    <CustomCard
      icon={<AttachFile color="white" />}
      iconContainerSx={{ backgroundColor: "#F44335", ...styles.icon }}
      cardTitle={`Issue Attachments: ${currentIssue?.issueTitle}`}
      cardSx={styles.card}
      titleSx={styles.title}
    >
      <Box sx={styles.container}>
        <Box mb={0}>
          {/* <MDTypography component="label" variant="body2">
            Header Media
          </MDTypography> */}

          <Grid item xs={12}>
            <UploadFileModal />
          </Grid>
          <MDBox mt={2}>
            <Attachments />
          </MDBox>
        </Box>
      </Box>
    </CustomCard>

  );
};

export default IssuesAttachments;
