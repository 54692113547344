import { useState } from "react";
import { baseAxios } from "config";
import { useSnackbar } from "notistack";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMutation, useQueryClient } from "react-query";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import MDBox from "components/MDBox";
import { EastOutlined } from "@mui/icons-material";

const VerifyCard = ({ user,
    newEmail = null,
    newEmailEdit,
    setNewEmailEdit,
    oldEmail,
    id,
    otp,
    setEmailChangeVerified,
    parent,
    setChangeEmailModalOpen,
    setOpenOTPModal
}) => {

    const isProfile = parent === "profile"
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [missingEmailError, setMissingEmailError] = useState(false)
    const [wrongMailError, setWrongMailError] = useState(false)
    const verifyMail = async (body) => {
        try {
            const res = await baseAxios.post(`/outside-public/users/account/id/${id}/verify`, body);
            return res;
        } catch (err) {
            console.log("err", err)
            throw new Error(err.toString());
        }
    };

    const { mutateAsync: verifyEmailMutation, isLoading } = useMutation(verifyMail, {
        onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
        onSuccess: (_, { data }) => {
            setEmailChangeVerified(true)
        }

    });

    const updateUserMutation = useMutation(updateProfile, {
        onError: (error) =>
            enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
        onSuccess: async (_, { data }) => {
            await queryClient.invalidateQueries("applicants");
            if (!isProfile) { enqueueSnackbar("User has been updated!", { variant: "success" }); }
        },
    });

    const handleChanges = async () => {
        if (!user?.userId) {
            enqueueSnackbar("Something went wrong!, no Id", { variant: "error" });
            return;
        }
        const values = {
            oldEmail: user?.emailAddress,
            newEmail: newEmailEdit,
            isProfile: true
        }


        await updateUserMutation.mutateAsync({
            userId: user?.userId,
            data: values,
        });
    };




    const handleVerifyEmailSubmit = async (data) => {
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
        const emailMatch = newEmailEdit?.match(emailRegex);
        if (isProfile && newEmailEdit && emailMatch) {
            handleChanges()
            setChangeEmailModalOpen(false)
            setOpenOTPModal(true)
            setMissingEmailError(false)
            setWrongMailError(false)
        }
        else if (isProfile && newEmailEdit && !emailMatch) {
            setWrongMailError(true)

        }
        else if (isProfile && !newEmailEdit) {
            setMissingEmailError(true)
        }
        else {
            await verifyEmailMutation({ otp: data })
        }
    };


    return (
        <MDBox position="relative" flexDirection="row">
            <Card
                sx={{
                    display: "flex",
                    mb: 15,
                    py: 2,
                    px: 2,
                    position: isProfile ? "absolute" : "static",
                    top: isProfile ? "50%" : "auto",
                    left: isProfile ? "50%" : "auto",
                    transform: isProfile ? "translate(-50%, 50%)" : "none",
                    maxHeight: isProfile ? "calc(100vh - 100px)" : "none",
                }}
            >
                <CardContent sx={{ p: 3 }}>
                    <CardHeader title="Verify User Account Email Change" subheader="" />
                    <CardContent sx={{ p: 2 }}>
                        <MDTypography variant="body2" textAlign="center">
                            You have requested to update the email address connected to your account.
                        </MDTypography>
                        <MDTypography variant="body2" textAlign="center">
                            An OTP will be sent to you current email one you click the Verify Now button below
                        </MDTypography>
                        <Grid container spacing={1} mt={3}>
                            <Grid item sm={5.5}>
                                <TextField
                                    label="Old Email"
                                    disabled
                                    value={oldEmail}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={1} display='flex' justifyContent='center' alignItems='center'>
                                <EastOutlined color='info' />
                            </Grid>
                            <Grid item sm={5.5}>
                                <TextField
                                    label="New Email"
                                    disabled={!isProfile}
                                    value={newEmail || newEmailEdit}
                                    onChange={(e) => {
                                        setMissingEmailError(false)
                                        setWrongMailError(false)
                                        setNewEmailEdit(e.target.value?.toLowerCase())
                                    }}
                                    fullWidth
                                />
                                <MDTypography color="error" fontSize="0.75rem">
                                    {missingEmailError && "You Must Insert an Email"}
                                </MDTypography>
                                <MDTypography color="error" fontSize="0.75rem">
                                    {wrongMailError && "Enter a Valid Email"}
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions sx={{ justifyContent: "center", mt: 4 }}>
                        <Button
                            color="error"
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => {
                                const value = parseFloat(otp)
                                handleVerifyEmailSubmit(value)
                            }}
                        >
                            Verify Now
                        </Button>
                    </CardActions>
                    <CardContent sx={{ p: 2 }}>
                        <MDTypography fontSize="0.65rem" textAlign="center">
                            I this wasn&apos;t you, or if you believe that an unauthorized person has accessed your account, please reset your password right away.
                        </MDTypography>
                        <MDTypography fontSize="0.65rem" textAlign="center">
                            If you have any concerns, please contact us at   <Link color="info" sx={{ fontWeight: 'bold' }} href="mailto:support@gignology.biz">support@gignology.biz</Link>
                        </MDTypography>

                    </CardContent>
                </CardContent>

            </Card>
        </MDBox >
    )
}

export default VerifyCard
