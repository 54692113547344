import { Grid, Stack, Link, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";

const RosterInformation = ({ currentApp }) => {
  const getClockDateTime = (date) => {
    if (date) {
      return new Date(date).toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    }
    return null;
  };

  const handleClickLink = (event) => {
    event.preventDefault();
    window.open(event.currentTarget.href, "_blank");
  };


  let inDateTime;

  if (getClockDateTime(currentApp?.timeIn)) {
    if (currentApp?.clockInCoordinates) {
      inDateTime = (
        <Link
          href={`https://www.google.com/maps?q=${currentApp?.clockInCoordinates?.latitude || 0},${currentApp?.clockInCoordinates?.longitude || 0}&z=12`}
          onClick={handleClickLink}
        >
          <MDTypography variant="body2" sx={{ color: "#008000", textDecoration: "underline" }}>
            {getClockDateTime(currentApp?.timeIn)}
          </MDTypography>
        </Link>
      );
    } else {
      inDateTime = (
        <Tooltip title="No coordinates available">
          <MDTypography variant="body2" sx={{ color: "#008000", textDecoration: "underline" }}>
            {getClockDateTime(currentApp?.timeIn)}
          </MDTypography>
        </Tooltip>
      );
    }
  } else {
    inDateTime = "--";
  }
  let outDateTime;

  if (getClockDateTime(currentApp?.timeOut)) {
    if (currentApp?.clockOutCoordinates) {
      outDateTime = (
        <Link
          href={`https://www.google.com/maps?q=${currentApp?.clockOutCoordinates?.latitude || 0
            },${currentApp?.clockOutCoordinates?.longitude || 0}&z=12`}
          onClick={handleClickLink}
        >
          <MDTypography variant="body2" sx={{ color: "#cc3300", textDecoration: "underline" }}>
            {getClockDateTime(currentApp?.timeOut)}
          </MDTypography>
        </Link>
      );
    } else {
      outDateTime = (
        <Tooltip title="No coordinates available">
          <MDTypography variant="body2" sx={{ color: "#cc3300", textDecoration: "underline" }}>
            {getClockDateTime(currentApp?.timeOut)}
          </MDTypography>
        </Tooltip>
      );
    }
  } else {
    outDateTime = "--";
  }

  return (
    <Stack spacing={2} width={350}>
      <MDTypography variant="h6">{currentApp?.name}</MDTypography>
      <Grid container>
        <Grid item xs={6}>
          <MDTypography variant="body2">{currentApp?.primaryPosition}</MDTypography>
        </Grid>
        <Grid item xs={6}>
          <MDTypography variant="body2">Report Time: {currentApp?.reportTime}</MDTypography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <MDTypography variant="body2">Clock In:</MDTypography>
          {inDateTime}
        </Grid>
        <Grid item xs={6}>
          <MDTypography variant="body2">Clock Out:</MDTypography>
          {outDateTime}
        </Grid>
      </Grid >
    </Stack >
  );
};

export default RosterInformation;
