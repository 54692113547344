import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { makeStyles } from "@mui/styles";
// Authentication layout components
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { Controller, useForm } from "react-hook-form";
// Images
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { baseAxios } from "config";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button/Button";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { createUserSchema } from "data/createUser";
import FormField from "layouts/applications/wizard/components/FormField";
import { useAppContextController } from "context/AppContext";
import useSessionAuth from "hooks/useSessionAuth";

const useStyle = makeStyles({
  container: {},
});

function CreateAccount() {
  const { loginWithRedirect } = useAuth0();
  const { company } = useAppContextController();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.has("email") ? params.get("email") : "";
  const [disabledCreateLogin, setDisabledCreateLogin] = useState(true);
  const handleLoginWithGoogle = () => {
    loginWithRedirect({ connection: "google-oauth2" });
  };

  const [spUser, setSpUser] = useState(null);
  const navigate = useNavigate();

  const classes = useStyle();

  const resolver = useYupValidationResolver(createUserSchema("protected"));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: "onBlur" });

  useEffect(() => {
    if (email) setValue("emailAddress", email);
  }, [email]);

  const { logoutAuth0User } = useSessionAuth();

  const apiCallToVerifyEmail = async (eml) => {
    try {
      const url = `/outside-protected/users/checkemail/${eml}`;
      const result = await baseAxios.get(url);
      if (result?.data?.user) {
        setSpUser(result.data.user);
        return result.data.user;
      }
      return null;
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
      return null;
    }
  };

  const handleEmailPasswordSignup = async () => {
    if (getValues("password") && getValues("password") === getValues("passwordConfirm")) {
      // Call your API to verify that the email is in your database already.
      const auth0User = await apiCallToVerifyEmail(getValues("emailAddress"));
      if (auth0User) {
        const id = auth0User._id.toString();
        const user = {
          id,
          firstName: auth0User.firstName,
          lastName: auth0User.lastName,
          email: getValues("emailAddress"),
          password: getValues("password"),
          connection: "Username-Password-Authentication",
        };
        // const newUser = await createAuth0User(user);
        const url = `/outside-protected/users/id/${id}/createAuth0`;
        let newUser;
        try {
          newUser = await baseAxios.post(url, user);
        } catch (err) {
          if (err.message === "Request failed with status code 409") {
            setError("accountAlreadyExist", {
              type: "custom",
              message: "Account Already Exists. Please Log in",
            });
          } else {
            setError("error400", {
              type: "custom",
              message: err.message,
            });
          }

          if (String(err).includes("401") || err?.response?.status === 401) {
            logoutAuth0User();
          }
        }

        if (newUser) {
          // Redirect to a verification instruction page after successful account creation.
          navigate(`/auth/createaccount/verification?email=${getValues("emailAddress")}`);
        }
      } else {
        alert("This email is not allowed to create an account.");
      }
    } else {
      alert("Passwords do not match.");
    }

    return "";
  };
  const createUserHandler = () => {
    handleEmailPasswordSignup();
  };

  const handleGoogleSignup = async () => {
    handleLoginWithGoogle();
  };
  useEffect(() => {
    if (
      watch().emailAddress !== undefined &&
      watch().password !== undefined &&
      watch().passwordConfirm !== undefined &&
      Object.keys(errors).length === 0
    ) {
      setDisabledCreateLogin(false);
    } else {
      setDisabledCreateLogin(true);
    }
  }, [watch(), errors]);

  return (
    <>
      <MDBox
        width="100vw"
        height="100vh"
        sx={{
          backgroundColor: "#000",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "absolute",
        }}
      />
      <Card
        className={classes.container}
        sx={(theme) => ({
          paddingTop: "20px",
          padding: "30px",
          [theme.breakpoints.up("sTablet")]: {
            maxWidth: "420px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%",
            width: "90%",
          },

          [theme.breakpoints.down("sTablet")]: {
            width: "100%",
            maxWidth: "100vw",
            height: "100vh",
          },
        })}
      >
        <FlexWrapper flexDirection="column" flexWrap="nowrap">
          <img
            src={`${company?.imageUrl}/stadiumpeople/SP-App-Store-Icon.png`}
            width="50"
            height="50"
            className={classes.logoImage}
            alt="Stadium People Logo"
          />
          <MDTypography variant="h4" color="black" my={1}>
            Stadium People
          </MDTypography>
          <MDTypography variant="h5" color="black" my={1}>
            Create Your Account
          </MDTypography>
        </FlexWrapper>

        {/* <MDTypography variant="body2" color="black" px={3}>
                    Log in to dev-m3kmliv9 to continue to Stadium People.
                </MDTypography> */}
        <form onSubmit={handleSubmit(createUserHandler)}>
          <MDBox pt={1} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mt={1}>
                <Controller
                  name="emailAddress"
                  control={control}
                  render={({ field }) => (
                    <FormField
                      placeholder={email || "Email Address"}
                      defaultValue={email || ""}
                      type="text"
                      sx={{ border: "1px solid gray", padding: "0.3rem" }}
                      {...field}
                      disabled={!!email}
                      onChange={(e) => {
                        setValue("emailAddress", e.target.value);
                        if (errors?.accountAlreadyExist) {
                          clearErrors("accountAlreadyExist");
                        }
                      }}
                      inputProps={{ tabIndex: -1 }}
                    />
                  )}
                />
                {errors?.emailAddress && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.emailAddress.message}
                  </MDTypography>
                )}
                {errors?.accountAlreadyExist && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.accountAlreadyExist.message}
                  </MDTypography>
                )}
              </MDBox>
              {!(
                errors?.emailAddress?.type === "gmailDomain" ||
                errors?.emailAddress?.type === "accountAlreadyExist"
              ) && (
                <>
                  <MDBox mt={1}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          sx={{ border: "1px solid gray", padding: "0.3rem" }}
                          placeholder="Password"
                          type={showPassword ? "text" : "password"} //
                          {...field}
                          onChange={(e) => {
                            setValue("password", e.target.value);
                            if (watch().passwordConfirm !== undefined) {
                              trigger("passwordConfirm");
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors?.password && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.password.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        placeholder="Confirm Password"
                        type={showPassword2 ? "text" : "password"} //
                        {...field}
                        sx={{ border: "1px solid gray", padding: "0.3rem" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword2}
                              >
                                {showPassword2 ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors?.passwordConfirm && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.passwordConfirm.message}
                    </MDTypography>
                  )}
                  {errors?.error400 && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.error400.message}
                    </MDTypography>
                  )}
                </>
              )}
            </MDBox>

            <MDBox textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login here
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="button" color="text">
                Need help? Email{" "}
                <MDTypography
                  component={Link}
                  to={`mailto:${company?.companyEmail}`}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {company?.companyEmail}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox textAlign="left">
              <MDTypography variant="body2" paragraph>
                By creating a login, I agree to receive SMS messages from Stadium People regarding
                events, updates, and reminders. I understand that I can opt out at any time by
                replying STOP to any message. Message and data rates may apply.
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={2}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "red", borderRadius: "0" }}
                fullWidth
                disabled={disabledCreateLogin}
                type="submit" // onClick={handleEmailPasswordSignup}
              >
                Create Login
              </Button>
            </MDBox>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={5}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <MDTypography variant="body2">OR</MDTypography>
              </Grid>
              <Grid item xs={5}>
                <hr />
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <Button
                startIcon={<img alt="logo" width="30px" src="/images/png/logos/logo-google.png" />}
                variant="contained"
                sx={{ backgroundColor: "#FFF", color: "#000", borderRadius: "0" }}
                fullWidth
                onClick={handleGoogleSignup}
              >
                Login With Google
              </Button>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </>
  );
}

export default CreateAccount;
