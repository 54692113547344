import axios from "axios";

import { baseAxios } from "config";

const createActivity = async (data) => {
  const res = await baseAxios.post(`/activities`, data);
  return res.data;
};

export default createActivity;
