import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const MyJobsActions = ({ slug }) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    navigate(`/jobs/${slug}/action/${action}`);
  };

  return (
    <MDBox display="flex" justifyContent="end">
      <TableAction
        color="error"
        icon={<PersonAddIcon fontSize="large" color="warning" />}
        showBadge
        clickAction={(e) => onClickHandler("jobapplicants", e)}
        tooltip="Jobs Applicants"
      />
      &nbsp;
      <TableAction
        color="secondary"
        icon="edit"
        clickAction={(e) => onClickHandler("jobinfo", e)}
        tooltip="Edit Job"
      />
      {/* &nbsp;
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler("jobevents", e)}
        tooltip="Job Interviews"
      /> */}
    </MDBox>
  );
};
export default MyJobsActions;

MyJobsActions.defaultProps = {
  slug: "",
};

MyJobsActions.propTypes = {
  slug: PropTypes.string,
};
