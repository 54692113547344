import { useState } from "react";
import moment from "moment";
import { Stack, Grid, Icon, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const OrganizationsItem = ({ row, idx, onEdit, onRemove, onPrimaryChange }) => {
  const { slug, userType, status, primary, modifiedDate } = row;
  const isPrimary = primary === "Yes";
  const [anchorEl, setAnchorEl] = useState(null);
  const isIconMenuOpen = Boolean(anchorEl);

  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  const handleChange = (e) => {
    e.stopPropagation();
    onPrimaryChange(idx);
    handleIconClose();
  };

  return (
    <Stack
      display="flex"
      direction="row"
      justifyContent="space-between"
      onClick={() => onEdit(row, true, idx)}
    >
      <Grid container display="flex" alignItems="center" spacing={1}>
        <Grid item md={12} lg={2}>
          <MDTypography sx={{ fontSize: "0.8rem" }}>{slug}</MDTypography>
        </Grid>
        <Grid item md={12} lg={2}>
          <MDTypography sx={{ fontSize: "0.8rem" }}>{userType}</MDTypography>
        </Grid>
        <Grid item md={12} lg={2}>
          <MDTypography sx={{ fontSize: "0.8rem" }}>{status}</MDTypography>
        </Grid>
        <Grid item md={12} lg={2}>
          {isPrimary ? (
            <Tooltip title="Primary" placement="top">
              <CheckCircle color="success" fontSize="medium" sx={{ mb: -0.5, mt: 0.5 }} />
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Set Primary" placement="top">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIconClick(e);
                  }}
                >
                  <CheckCircleOutline color="" fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Menu anchorEl={anchorEl} open={isIconMenuOpen} onClose={handleIconClose}>
                <MenuItem onClick={handleChange} disableRipple sx={{ p: 0 }}>
                  <MDTypography variant="h6">Set as Primary</MDTypography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
        <Grid item md={12} lg={2}>
          <MDTypography sx={{ fontSize: "0.8rem" }}>
            {moment(modifiedDate).format("ddd MM/DD/YYYY hh:mm A")}
          </MDTypography>
        </Grid>

        <Grid item md={12} lg={2} mt="1px">
          {!isPrimary && (
            <MDBox textAlign="right">
              <Icon
                color="error"
                fontSize="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(idx);
                }}
              >
                cancel
              </Icon>
            </MDBox>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default OrganizationsItem;
