import { Backdrop, CircularProgress } from "@mui/material";
import ConditionalWrapper from "components/Wrapper/ConditionalWrapper";

const CircularLoader = ({ isLoading, hasBackdrop = true, color, size }) => {
  if (!isLoading) return null;
  return (
    <ConditionalWrapper
      condition={hasBackdrop}
      wrap={(children) => (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
          open={isLoading}
        >
          {children}
        </Backdrop>
      )}
    >
      <CircularProgress
        color={color}
        size={size}
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          margin: "auto",
          top: 0,
          bottom: 0,
          zIndex: 50,
        }}
      />
    </ConditionalWrapper>
  );
};

export default CircularLoader;
