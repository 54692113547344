import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const initialVal = {
  title: "Data has changed",
  description: "Please Confirm/Cancel to proceed.",
  negativeBtn: {
    label: "Cancel",
    fn: null,
    isShown: true,
  },
  positiveBtn: {
    label: "Confirm",
    fn: null,
    isShown: true,
  },
  isOpen: false,
};

const ConfirmModal = ({
  title,
  description,
  negativeBtn,
  negativeAltBtn,
  positiveBtn,
  isOpen,
  closeFn,
}) => {
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={closeFn}>
        <DialogTitle>{title || initialVal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description || initialVal.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {negativeBtn?.isShown && <Button onClick={negativeBtn.fn}>{negativeBtn.label}</Button>}
          {negativeAltBtn?.isShown && (
            <Button onClick={negativeAltBtn.fn}>{negativeAltBtn.label}</Button>
          )}
          {positiveBtn?.isShown && (
            <Button variant="contained" style={{ color: "white" }} onClick={positiveBtn.fn}>
              {positiveBtn.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmModal;
