import axios from "axios";
import { baseAxios } from "config";

const uploadFileVenue = async ({ venueSlug, uploadForm }) => {
  try {
    const res = await baseAxios.post(`/upload/${venueSlug}/venues/other`, uploadForm);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default uploadFileVenue;
