import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import useCompanyStore from "stores/useCompanyStore";

const CompanyHeader = ({ sourceComponent }) => {
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const isEdit = !!currentCompany?._id;
  return (
    <>
      {isEdit ? (
        <FlexWrapper
          flex="1 0 50%"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
        >
          <MDTypography variant="h5" color="dark" display="block" sx={{ whiteSpace: "wrap" }}>
            {sourceComponent === "Company" ? "Primary Company" : "Customer"}: {currentCompany?.name}
          </MDTypography>
          <MDTypography
            fontSize="14px"
            variant="p"
            color="dark"
            display="block"
            sx={{ whiteSpace: "nowrap" }}
          >
            {currentCompany?.modifiedDate &&
              `Last Updated: ${new Date(
                currentCompany?.modifiedDate
              ).toLocaleDateString()} ${new Date(
                currentCompany?.modifiedDate
              ).toLocaleTimeString()}`}
          </MDTypography>
        </FlexWrapper>
      ) : (
        <>
          <MDTypography variant="h5" color="dark" display="block" sx={{ whiteSpace: "wrap" }}>
            Create Customer
          </MDTypography>
          <MDTypography
            fontSize="14px"
            variant="p"
            color="dark"
            display="block"
            sx={{ whiteSpace: "nowrap" }}
          >
            {currentCompany?.modifiedDate &&
              `Last Updated: ${new Date(
                currentCompany?.modifiedDate
              ).toLocaleDateString()} ${new Date(
                currentCompany?.modifiedDate
              ).toLocaleTimeString()}`}
          </MDTypography>
        </>
      )}
    </>
  );
};

export default CompanyHeader;
