const getBrowserAndOS = () => {
  const { userAgent } = navigator;
  let os = "Unknown OS";
  let browser = "Unknown Browser";

  // Detect OS
  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
  if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  if (userAgent.indexOf("Android") !== -1) os = "Android";
  if (userAgent.indexOf("like Mac") !== -1) os = "iOS";

  // Detect Browser
  if (userAgent.indexOf("Chrome") !== -1) {
    browser = "Chrome";
  } else if (userAgent.indexOf("Safari") !== -1) {
    browser = "Safari";
  } else if (userAgent.indexOf("Firefox") !== -1) {
    browser = "Firefox";
  } else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
    browser = "Internet Explorer";
  } else if (userAgent.indexOf("Edge") !== -1) {
    browser = "Edge";
  }

  return { os, browser };
};

export default getBrowserAndOS;
