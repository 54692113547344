export const JOB_POSITION_STATUS_ENUM = {
  OPEN: "Open",
  FILLED: "Filled",
  ON_HOLD: "On-Hold",
  CANCELED: "Canceled",
};

export const JOB_SEARCH_ALL_MIN_DISTANCE = 5;
export const JOB_SEARCH_ALL_MAX_DISTANCE = 70;
export const JOB_SEARCH_ALL_MIN_RESULTS = 1;
export const JOB_SEARCH_ALL_MAX_RESULTS = 25;
