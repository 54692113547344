import { Button, Grid } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CustomTextField from "components/Form/CustomTextField";

const AddStringValues = ({ value, setValue, onAdd, errors }) => {
  return (
    <Grid container mb={2} display="flex" alignItems="center">
      <Grid item xs={10}>
        <CustomTextField
          type="text"
          label="Add New Value"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onAdd();
            }
          }}
          showError={errors?.stringValue}
          errorMessage={errors?.stringValue?.message}
        />
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Button variant="contained" endIcon={<AddCircleRoundedIcon />} onClick={onAdd}>
          add
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddStringValues;
