import {
  Cancel as CancelIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Visibility
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import ConfirmDialog from "components/ConfirmDialog/ConfirmModal";
import { useAppContextController } from "context/AppContext";
import deleteEvent from "layouts/pages/events/actions/deleteEvent";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const EventActions = ({ submitted, hasChanges, resetChanges, setEditorValue, updateEventMutation, onPreview }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentEvent: event, setCurrentEvent } = useAppContextController();
  const isEdit = !!event?._id;
  const [searchParams] = useSearchParams();
  const copyEventId = searchParams.get("copyEventId");
  const [confirmModal, setConfirmModal] = useState(initialValueModal);

  const closeModalHandler = () => setConfirmModal(initialValueModal);

  const copyEventHandler = () => navigate(`/events/create?copyEventId=${event._id}`);

  const deleteEventVenue = useMutation(deleteEvent, {
    onSuccess: async () => {
      enqueueSnackbar(`Event ${event?.eventName} has been deleted`, { variant: "success" });
      setCurrentEvent({});
      navigate(`/events/venue/${event?.venueSlug}`);
    },
    onError: (error) => {
      enqueueSnackbar(`Something went wrong -- ${error}`, { variant: "error" });
    },
  });

  const cancelChangesHandler = () => {
    const url = event?.eventUrl;
    resetChanges();
    setEditorValue(event.description);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
    navigate(`/events/${url}`);
  };

  const confirmInfo = {
    cancelCreate: {
      title: "You are leaving create mode",
      description: "Please Confirm to abandon new Event.",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: () => {
          setConfirmModal(initialValueModal);
          navigate(`/events`);
        },
        isShown: true,
      },
      isOpen: true,
    },
    deleteEvent: {
      isOpen: true,
      title: "Delete Event",
      bodyText: "Please confirm you want to DELETE this event!",
      response: false,
      description: "Please confirm you want to DELETE this event!",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          // await deleteEventVenue.mutateAsync(event._id, event.name);
          await updateEventMutation.mutateAsync({
            venueSlug: event.venueSlug,
            eventId: event._id,
            data: { status: "Deleted" },
          });
          closeModalHandler();
          navigate(`/events`);
        },
        isShown: true,
      },
    },
    cancelModified: {
      title: copyEventId ? "Copy Event" : "Data has changed!",
      description: copyEventId
        ? "Abandon Copy Event?"
        : "Please confirm you want to abandon changes",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: () => {
          if (!copyEventId) navigate(`/events/venue/${event?.venueSlug}`);
          closeModalHandler();
          cancelChangesHandler();
          setConfirmModal(initialValueModal);
        },
        isShown: true,
      },
      isOpen: true,
    },
  };

  const confirmModalHandler = (action) => setConfirmModal(confirmInfo[action]);

  const formActionBtnInfo = [
    {
      label: "Cancel",
      variant: "text",
      iconInfo: {
        placement: "start",
        icon: <CancelIcon />,
      },
      func: confirmModalHandler,
      isDisabled: submitted,
      type: "button",
      forEdit: "",
      forModified: true,
      style: {},
      action: isEdit ? "cancelModified" : "cancelCreate",
    },
    {
      label: "Create",
      variant: "contained",
      iconInfo: {
        placement: "end",
        icon: <SaveIcon />,
      },
      isDisabled: submitted,
      type: "submit",
      forEdit: false,
      forModified: true,
      style: {},
      action: "create",
    },
    {
      label: "Preview Event",
      variant: "text",
      iconInfo: {
        placement: "start",
        icon: <Visibility />,
      },

      isDisabled: submitted,
      type: "button",
      func: onPreview,
      forEdit: true,
      forModified: false,
      style: {},
      action: "onPreview",
    },
    {
      label: "Delete Event",
      variant: "text",
      iconInfo: {
        placement: "end",
        icon: <DeleteIcon />,
      },
      func: confirmModalHandler,
      isDisabled: submitted,
      type: "button",
      forEdit: true,
      forModified: false,
      style: { color: "red" },
      action: "deleteEvent",
    },
    {
      label: "Copy Event",
      variant: "contained",
      iconInfo: {
        placement: "end",
        icon: <ContentCopyIcon />,
      },
      func: copyEventHandler,
      isDisabled: submitted,
      type: "button",
      forEdit: true,
      forModified: false,
      style: {},
      action: "copyEvent",
    },
    {
      label: "Save",
      variant: "text",
      iconInfo: {
        placement: "end",
        icon: <SaveIcon />,
      },
      isDisabled: submitted,
      type: "submit",
      forEdit: true,
      forModified: true,
      style: { color: "#4CAF50" },
      action: "save",
    },
  ];
  const formBtnHandler = useCallback(() => {
    if (!isEdit || copyEventId) {
      return [formActionBtnInfo[2], formActionBtnInfo[0], formActionBtnInfo[1]];
    }
    if (hasChanges) {
      return [formActionBtnInfo[0], formActionBtnInfo[5]];
    }
    return [formActionBtnInfo[2], formActionBtnInfo[3], formActionBtnInfo[4]];
  }, [submitted, hasChanges, cancelChangesHandler, copyEventId]);

  return formBtnHandler().map((btnInfo, index) => {
    const { variant, iconInfo, style, func, label, isDisabled, action } = btnInfo;

    return (
      <>
        <StyledFormBtn
          key={`form-button-${label}`}
          type={btnInfo.type}
          variant={variant}
          endIcon={isEqual(iconInfo.placement, "end") && iconInfo.icon}
          startIcon={isEqual(iconInfo.placement, "start") && iconInfo.icon}
          disabled={isDisabled}
          style={style}
          onClick={() => !isEqual(label, "Create") && func?.(action)}
        >
          {label}
        </StyledFormBtn>
        {confirmModal.isOpen && (
          <ConfirmDialog
            title={confirmModal.title}
            description={confirmModal.description}
            positiveBtn={confirmModal.positiveBtn}
            negativeBtn={confirmModal.negativeBtn}
            isOpen={confirmModal.isOpen}
            closeFn={closeModalHandler}
          />
        )}
      </>
    );
  });
};

export default EventActions;

const StyledFormBtn = styled(Button)(({ theme }) => ({
  padding: "0.5rem 1rem",
}));
