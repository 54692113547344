import MDBox from "components/MDBox";

const EditorBox = ({ children, ...props }) => (
  <MDBox
    sx={{
      "& div:last-child": {
        lg: {
          height: "100%",
          width: "100%",
        },
        "& .ql-editor": {
          minHeight: 113,
          maxHeight: 113,
          overflow: "hidden",
          overflowY: "auto",
          overflowX: "auto",
        },
      },
    }}
    {...props}
  >
    {children}
  </MDBox>
);

export default EditorBox;
