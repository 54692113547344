import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CloudIcon from "@mui/icons-material/Cloud";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 PRO React components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController,
} from "context";

import { Button, Divider, MenuItem } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import fetchNotifications from "layouts/pages/dashboard/actions/fetchNotifications";
import { useQuery } from "react-query";

function DashboardNavbar({ absolute, light, isMini }) {
  const {
    backendEnv,
    setBackendEnv,
    userType,
    setUserType,
    companyType,
    setCompanyType,
    currentLoggedUser,
  } = useAppContextController();

  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode, apiServer } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openEnvMenu, setOpenEnvMenu] = useState(false);
  const [openUserTypeMenu, setOpenUserTypeMenu] = useState(false);
  const [openOrgTypeMenu, setOpenOrgTypeMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const options = { orderBy: "sendTime:desc", page: 1, limit: 11 };

  const allOptions = {
    ...options,
    filters: { userId: currentLoggedUser?._id },
  };

  const {
    data: { data: notifications, numberUnread } = { data: [], numberUnread: 0 },
    isLoading,
    refetch,
  } = useQuery(["notificationsTable", allOptions], () => fetchNotifications(allOptions), {});

  const userTypeColorMap = {
    Master: "success",
    Admin: "warning",
    User: "info",
    Internet: "secondary",
  };

  const envColorMap = {
    L: "secondary",
    S: "warning",
    P: "success",
  };

  const userTypeColor = userTypeColorMap[userType];
  const envColor = envColorMap[backendEnv];

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenEnv = (event) => setOpenEnvMenu(event.currentTarget);
  const handleOpenUserType = (event) => setOpenUserTypeMenu(event.currentTarget);
  const handleOpenOrgType = (event) => setOpenOrgTypeMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseEnv = () => setOpenEnvMenu(false);
  const handleCloseUserType = () => setOpenUserTypeMenu(false);
  const handleCloseOrgType = () => setOpenOrgTypeMenu(false);

  const handleEnvClick = (which) => {
    //    setWhichApiServer(which);
    setBackendEnv(which);
    handleCloseEnv();
    // return true;
  };

  const handleOrgTypeClick = (which) => {
    //    setWhichApiServer(which);
    setCompanyType(which);
    handleCloseOrgType();
    // return true;
  };

  const handleUserTypeClick = (which) => {
    setUserType(which);
    handleCloseUserType();
    // return true;
  };

  // Render the userType menu
  const renderUserTypeMenu = () => (
    <Menu
      anchorEl={openUserTypeMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserTypeMenu)}
      onClose={handleCloseUserType}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        color={userTypeColorMap.Master}
        icon={<ManageAccountsIcon />}
        title="Master"
        onClick={() => handleUserTypeClick("Master")}
      />
      <NotificationItem
        color={userTypeColorMap.Admin}
        icon={<AdminPanelSettingsIcon />}
        title="Admin"
        onClick={() => handleUserTypeClick("Admin")}
      />
      <NotificationItem
        color={userTypeColorMap.User}
        icon={<PersonIcon />}
        title="User"
        onClick={() => handleUserTypeClick("User")}
      />
      <NotificationItem
        color={userTypeColorMap.Internet}
        icon={<CloudIcon />}
        title="Internet"
        onClick={() => handleUserTypeClick("Internet")}
      />
    </Menu>
  );

  // Render the environment menu
  const renderOrgTypeMenu = () => (
    <Menu
      anchorEl={openOrgTypeMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openOrgTypeMenu)}
      onClose={handleCloseOrgType}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        color="success"
        icon={<Icon>stadium</Icon>}
        title="Venues"
        onClick={() => handleOrgTypeClick("Venue")}
      />
      <NotificationItem
        color="warning"
        icon={<Icon>business</Icon>}
        title="Company"
        onClick={() => handleOrgTypeClick("Company")}
      />
    </Menu>
  );

  // Render the environment menu
  const renderEnvMenu = () => (
    <Menu
      anchorEl={openEnvMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openEnvMenu)}
      onClose={handleCloseEnv}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        color="secondary"
        icon={<Icon>local_activity</Icon>}
        title="Local"
        onClick={() => handleEnvClick("L")}
      />
      <NotificationItem
        color="warning"
        icon={<Icon>theater_comedy</Icon>}
        title="Staging"
        onClick={() => handleEnvClick("S")}
      />
      <NotificationItem
        color="success"
        icon={<Icon>precision_manufacturing</Icon>}
        title="Production"
        onClick={() => handleEnvClick("P")}
      />
    </Menu>
  );

  // Render the notifications menu
  const renderNotificationsMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications?.length
        ? notifications.slice(0, 10).map((item) => (
            <NotificationItem
              key={item._id}
              icon={<Icon>email</Icon>}
              title={item.subject}
              isUnread={item.status === "unread"}
              onClick={() => {
                handleCloseMenu();
                navigate(`/notifications/${item._id}`);
              }}
            />
          ))
        : "No Notifications"}
      {notifications?.length > 10 && (
        <div>
          <Divider />
          <MenuItem sx={{ justifyContent: "center", py: 0 }} onClick={handleCloseMenu}>
            <Link to="/notifications">
              <Button>View All</Button>
            </Link>
          </MenuItem>
        </div>
      )}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              {/* {userType === "Master" && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenOrgType}
                >
                  <MDBadge
                    badgeContent={companyType.slice(0, 1)}
                    color="warning"
                    size="xs"
                    circular
                  >
                    <Icon sx={iconsStyle}>business</Icon>
                  </MDBadge>
                </IconButton>
              )} */}
              {userType === "Master" && renderOrgTypeMenu()}
              {userType === "Master" && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenUserType}
                >
                  <MDBadge
                    badgeContent={userType.slice(0, 1)}
                    color={userTypeColor}
                    size="xs"
                    circular
                  >
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </MDBadge>
                </IconButton>
              )}
              {userType === "Master" && renderUserTypeMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {userType === "Master" && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenEnv}
                >
                  <MDBadge badgeContent={backendEnv} color={envColor} size="xs" circular>
                    <Icon sx={iconsStyle}>storage</Icon>
                  </MDBadge>
                </IconButton>
              )}
              {userType === "Master" && renderEnvMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={numberUnread} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton>
              {renderNotificationsMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
