import { Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataList from "components/DataList";
import { useAppContextController } from "context/AppContext";
import fetchNotifications from "layouts/pages/dashboard/actions/fetchNotifications";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Notification from "./components/Notification";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  button: {
    fontSize: "40px!important",
  },
});

const MyNotifications = ({ fetchAll, currentLoggedUser, filters }) => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const classes = useStyle();
  const navigate = useNavigate();
  const { setUnreadNotifications } = useAppContextController();
  const options = fetchAll ? { fetchAll } : { orderBy: "sendTime", order: "desc" };

  const allOptions = {
    ...options,
    filters: { ...filters, userId: currentLoggedUser?._id },
    setUnreadNotifications,
  };
  const {
    data: notifications,
    isLoading,
    refetch,
  } = useQuery(["notifications", allOptions], () => fetchNotifications(allOptions));
  const viewAll = () => navigate(`/notifications/userId/${currentLoggedUser._id}`);
  const renderRow = (row) => <Notification row={row} refetch={refetch} />;

  const header = (
    <Stack>
      {/* <Grid mt={2}>
        <Button variant="text" color="primary" onClick={viewAll}>
          View All
        </Button>
        |
        <Button variant="text" color="primary" disabled>
          Add New
        </Button>
      </Grid> */}
    </Stack>
  );

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={notifications?.data}
        onRowClick={() => {}}
        icon="notifications"
        iconColor="dark"
        title="My Notifications"
        isLoading={isLoading}
        greybar
        divider
        header={header}
        tableHeight={420}
        height={isLaptop ? 500 : null}
        cardVariant="outlined"
        scrollY
      />
    </>
  );
};

export default MyNotifications;
