import { FormControlLabel, Radio, RadioGroup, Stack, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { alabamaStateFormSchema } from "data/alabamaStateTaxForm";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";
import CommonTaxFields from "components/FormFields/CommonTaxFields";
import CurrencyField from "components/FormFields/CurrencyField";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },
});

const AlabamaForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(alabamaStateFormSchema);
  const classes = useStyle();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
    reset,
  } = useForm({
    resolver, mode: "onBlur", defaultValues: {
      noPersonalExemption: 0
    }
  });

  const onSubmit = async (data) => {
    const parsedData = {
      stateTaxForm: { state: "alabama", ...data },
    };
    updateApplicantAction(applicant._id, parsedData);
    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.value, { shouldDirty: true });
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);
  
  useEffect(() => {
    if (applicant?.stateTaxForm) {
      reset(applicant.stateTaxForm);
    }
  }, [applicant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} selectedState="AL" />
      </Grid>

      <MDBox>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8} display="flex">
            <MDTypography variant="body2">
              {" "}
              1.if you claim no personal exemption for yourself and wish to withhold at the highest
              rate, write the figure &ldquo;0&ldquo;, sign and date Form A4 and file it with your
              employer
            </MDTypography>
            <Controller
              name="noPersonalExemption"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value === 1}
                  onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                  inputProps={{ "aria-label": "" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomTextField
              variant="outlined"
              disabled
              label=""
              inputProps={{ tabIndex: -1 }}
              value={watch("noPersonalExemption")?.toString()}
              sx={{ input: { textAlign: "end" } }}
            />
          </Grid>
        </Grid>
        <RadioGroup
          name="filingStatus"
          aria-labelledby={`filingStatus_${applicant?.venueSlug} `}
          {...(applicant?.stateTaxForm?.filingStatus
            ? { defaultValue: applicant?.stateTaxForm?.filingStatus }
            : {})}
          onChange={handleFilingStatusChange}
        >
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={8}>
              <MDTypography variant="body2">
                {" "}
                2. If you are SINGLE or MARRIED FILING SEPARATELY, a $1,500 personal exemption is
                allowed
              </MDTypography>
              <MDTypography variant="body2">
                {" "}
                Select the letter &ldquo;S&ldquo; if claiming the SINGLE exemption or
                &ldquo;MS&ldquo; if claiming the MARRIED FILING SEPARATELY exemption
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container justifyContent="space-around">
                <Grid item sm={6}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <FormControlLabel value="S" control={<Radio />} sx={{ marginRight: "0" }} />
                    <MDTypography fontWeight="bold" fontSize="0.875rem">
                      S{" "}
                    </MDTypography>
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <FormControlLabel value="MS" control={<Radio />} sx={{ marginRight: "0" }} />
                    <MDTypography fontWeight="bold" fontSize="0.875rem">
                      MS{" "}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8}>
              <MDTypography variant="body2">
                {" "}
                3. If you are MARRIED or SINGLE CLAIMING HEAD OF FAMILY, a $3,000 personal exemption
                is allowed.
              </MDTypography>
              <MDTypography variant="body2">
                {" "}
                Select the letter &ldquo;M&ldquo; if you are claiming an exemption for both yourself
                and your spouse or &ldquo;H&ldquo; if you are single with a qualifying dependents
                and are claiming the HEAD OF FAMILY exemption
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container justifyContent="space-around">
                <Grid item sm={6}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <FormControlLabel value="M" control={<Radio />} sx={{ marginRight: "0" }} />
                    <MDTypography fontWeight="bold" fontSize="0.875rem">
                      M{" "}
                    </MDTypography>
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <FormControlLabel value="H" control={<Radio />} sx={{ marginRight: "0" }} />
                    <MDTypography fontWeight="bold" fontSize="0.875rem">
                      H{" "}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RadioGroup>

        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              4. Number of dependents (other than spouse) that you will provide more than one-half
              of the support for during the year. See dependent qualification below
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="dependents"
              control={control}
              render={({ field }) => (
                <NumericFormat
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                      type: "number",
                      step: 1,
                      min: 0,
                      max: 25,
                    },
                  }}
                  sx={{ input: { textAlign: "end" } }}
                  variant="outlined"
                  decimalScale={0}
                  customInput={CustomTextField}
                  defaultValue={applicant?.stateTaxForm?.dependents}
                  label=""
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              5. Additional amount, if any, you want deducted each pay period
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="additionalAmountDeducted"
              control={control}
              render={({ field }) => <CurrencyField
                label=""
                value={field.value}
                onChange={field.onChange} />}
            />
          </Grid>
        </Grid>
      </MDBox>

      <FormErrors errors={errors} />
    </form>
  );
};

export default AlabamaForm;
