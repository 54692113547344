import * as yup from "yup";

export const customerSchema = (workweek,
    overtimeWarnings,
    clockoutReminders,
    approvalReminders,
    states,
    payFrequencyValues,
    groupIds,
    wcCodes
) => {
    return yup.object().shape({
        timeClockSettings: yup.object().shape({
            workWeek: yup
                .string()
                .nullable()
                .trim()
                .test({
                    name: "workWeek exist",
                    test(value, ctx) {
                        if (value && !workweek.includes(value)) {
                            return ctx.createError({ message: "Insert a valid value for work week" });
                        }
                        return true;
                    },
                })
                .default(""),
            overtimeWarning: yup
                .string()
                .nullable()
                .trim()
                .test({
                    name: "overtimeWarning exist",
                    test(value, ctx) {
                        if (value && !overtimeWarnings.includes(value)) {
                            return ctx.createError({ message: "Insert a valid value for overtime warning" });
                        }
                        return true;
                    },
                })
                .default(""),
            clockOutReminders: yup
                .string()
                .nullable()
                .trim()
                .test({
                    name: "clockOutReminders exist",
                    test(value, ctx) {
                        if (value && !clockoutReminders.includes(value)) {
                            return ctx.createError({ message: "Insert a valid value for clockout reminders" });
                        }
                        return true;
                    },
                })
                .default(""),
            approvalReminders: yup
                .string()
                .nullable()
                .trim()
                .test({
                    name: "approvalReminders exist",
                    test(value, ctx) {
                        if (value && !approvalReminders.includes(value)) {
                            return ctx.createError({ message: "Insert a valid value for approval reminders" });
                        }
                        return true;
                    },
                })
                .default(""),
        }),
        fein: yup
            .string()
            .nullable()
            .default("")
            .test({
                name: "test-fein-format",
                test: (value, ctx) => {
                    const parsed = value.replace(/\D/g, "");
                    if (parsed.length === 0 || parsed.length === 9) {
                        return true;
                    }
                    return ctx.createError({ message: "FEIN should only be 9 digit format" });
                },
            }),
        wcState: yup
            .string()
            .nullable()
            .trim()
            .test({
                name: "State exist",
                test(value, ctx) {
                    if (value && !states.includes(value)) {
                        return ctx.createError({ message: "Insert a valid value State" });
                    }
                    return true;
                },
            })
            .default(""),
        payFrequency: yup
            .string()
            .nullable()
            .trim()
            .default(""),
        groupId: yup
            .string()
            .nullable()
            .trim()
            .test({
                name: "group id exist",
                test(value, ctx) {
                    if (value && !groupIds.includes(value)) {
                        return ctx.createError({ message: "Insert a valid group id" });
                    }
                    return true;
                },
            })
            .default(""),
        wcCode: yup
            .string()
            .nullable()
            .trim()
            .test({
                name: "wcCode exist",
                test(value, ctx) {
                    if (value && !wcCodes.includes(value)) {
                        return ctx.createError({ message: "Insert a valid wc code" });
                    }
                    return true;
                },
            })
            .default(""),
    })
};
