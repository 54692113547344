import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState = {
  companies: [],
  currentCompany: {},
  companyFormState: {},
  dataType: "company",
};

let store = (set, get) => ({
  ...initialState,
  setCompanies: (companies) => set({ companies }),
  setCurrentCompany: (currentCompany) => set({ currentCompany }),
  setCompanyFormState: (companyFormState) => set({ companyFormState }),
  setDataType: (dataType) => set({ dataType }),
  isCompany: () => get().dataType === "company",
  isCustomer: () => get().dataType === "customer",
});

store = devtools(store);

store = persist(store, { name: "companyStore" });

const useCompanyStore = create(store);

export default useCompanyStore;
