import axios from "axios";

import { baseAxios } from "config";

export const generateCompanyTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/companies-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCompanyChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/companies-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantCompanyTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-companies-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantCompanyChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-companies-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
