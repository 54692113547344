import {
  AccountCircle,
  AttachFile,
  Business,
  Description,
  Email,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useEffect } from "react";
import ProfileMainInfoPanelForm from "./ProfileMainInfoPanelForm";

const ProfileMainInfoPanel = ({
  setPanelSelect,
  panelSelect,
  parent = "User",
  setFilters,
  currentUser,
  setCurrentUser,
  mode,
  currentUserType,
  currentLoggedUser,
  loggedUserType,
  company,
}) => {
  const isEdit = currentUser?._id || currentUser?.createAgent;
  const handleClick = (selection) => {
    setPanelSelect(selection);
  };
  const disabled =
    currentUser?.userType === "Client" ||
    (currentUser?.userType === "User" && currentUser?.employeeType === "Event Admin");

  const applicantTabs = [
    {
      title: "Activities",
      icon: <TimelineIcon />,
      hasShadow: panelSelect === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handleClick("activities"),
      hidden: false,
      disabled: false,
    },
    {
      title: "User Attachments",
      icon: <AttachFile sx={{ color: "#FB8C00" }} />,
      hasShadow: panelSelect === "attachments" ? "emd" : "",
      color: "",
      ariaLabel: "attachments",
      fn: () => handleClick("attachments"),
      disabled: false,
    },
    {
      title: "User Account",
      icon: <AccountCircle color="info" />,
      hasShadow: panelSelect === "currentUser" ? "emd" : "",
      color: "",
      ariaLabel: "userPanel",
      fn: () => handleClick("currentUser"),
      hidden: false,
      disabled: false,
    },
    {
      title: "Organizations",
      icon: <Business />,
      hasShadow: panelSelect === "organizations" ? "emd" : "",
      color: "blue",
      ariaLabel: "business",
      fn: () => handleClick("organizations"),
      hidden: false,
      disabled: disabled !== true,
    },
    // {
    //   title: "Email Verification",
    //   icon: <Email />,
    //   hasShadow: panelSelect === "message" ? "emd" : "",
    //   color: "warning",
    //   ariaLabel: "Email",
    //   fn: () => handleClick("message"),
    // },
    {
      title: "User Notes",
      icon: <Description sx={{ color: "#FB8C00" }} />,
      hasShadow: panelSelect === "note" ? "emd" : "",
      color: "",
      ariaLabel: "description",
      fn: () => handleClick("note"),
      disabled: false,
    },
    {
      title: "User Report Data",
      icon: <AssessmentIcon sx={{ color: "#8935F4" }} />,
      hasShadow: panelSelect === "report" ? "emd" : "",
      color: "",
      ariaLabel: "folderShared",
      fn: () => handleClick("report"),
      disabled: false,
    },
  ];

  useEffect(() => {
    if (!isEdit) {
      setPanelSelect("");
    } else {
      setPanelSelect("activities");
    }
  }, [isEdit, setPanelSelect]);

  return (
    <ProfileMainInfoPanelForm
      parent={parent}
      setFilters={setFilters}
      currentUser={currentUser}
      mode={mode}
      setCurrentUser={setCurrentUser}
      applicantTabs={applicantTabs}
      isEdit={isEdit}
      currentUserType={currentUserType}
      currentLoggedUser={currentLoggedUser}
      loggedUserType={loggedUserType}
      company={company}
      setPanelSelect={setPanelSelect}
    />
  );
};

export default ProfileMainInfoPanel;
