import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Card, Grid, Icon } from "@mui/material";
import { Lock, LockOpen } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
// import DataTable from "components/DataTable";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { useAppContextController } from "context/AppContext";
// eslint-disable-next-line import/no-unresolved
import { useRosterApplicants } from "hooks/useRosterApplicants";
// import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchApplicantsOnly from "layouts/pages/applicants/actions/fetchApplicantsOnly";
// import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import EventRosterTable from "layouts/pages/clientevents/components/EventRosterTable";
import InterviewEventFilterCards from "layouts/pages/events/components/InterviewEventFilterCards";
import TimeRosterFilterCards from "layouts/pages/events/components/TimeRosterFilterCards";
import PartnerEventTimeClockTable from "layouts/pages/events/components/PartnerEventTimeClockTable";
import useSort from "utils/useSort";

// import EmployeeStatus from "components/EmployeeStatus";
import EventStatusButton from "components/EventStatusButton";
import VenueStatusButton from "components/VenueStatusButton";
import { getActiveFilterTitle, getApplicantVenueStatus } from "utils/helpers/applicant";
// import { BulkMessagingModal } from "../BulkMessagingModal";
import EventRosterActions from "../EventRosterActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
  },
  button: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const EventRosterAndTime = ({
  id,
  actionSelected,
  setActionSelected,
  setFilters,
  filters,
  showFiltersList,
  setExportFieldModalOpen,
  setApplicantsData,
  setRosterData,
  rosterStatus,
  setRosterStatus,
}) => {
  const queryClient = useQueryClient();
  const classes = useStyle();

  const { eventUrl } = useParams();

  const { currentEvent, venues, currentLoggedUser } = useAppContextController();
  const [applicantPoolSize, setApplicantPoolSize] = useState(0);

  const d = new Date();
  d.setDate(d.getDate() + 2);
  const today = d.toISOString().split("T")[0];
  const d2 = new Date();
  d2.setDate(d2.getDate() - 1);
  const yesterday = d2.toISOString().split("T")[0];

  const todayDate = new Date(today).getTime();
  const yesterdayDate = new Date(yesterday).getTime();
  const eventDateTime = new Date(currentEvent?.eventDate).getTime();

  const eventDay = eventDateTime <= todayDate && eventDateTime >= yesterdayDate;
  const [roster, setRoster] = useState("Signups");
  const [rosterIds, setRosterIds] = useState([]);
  const [rosterFilters, setRosterFilters] = useState({ status: "" });
  const [readRoster, setReadRoster] = useState(false);
  const [readApps, setReadApps] = useState(false);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [selectedCardNumber, setSelectedCardNumber] = useState(0);
  const [refreshBadges, setRefreshBadges] = useState(false);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const { order, orderBy, toggleSort } = useSort();
  const options = {
    fetchAll: true,
    page,
    limit,
    order,
    orderBy,
    useElemMatch: currentEvent?.eventType !== "Interview",
  };

  const applicantOptions = {
    ...options,
    filters: { ...filters, "venues.venueSlug": currentEvent?.venueSlug },
    // TODO:  Create filter includeEmployees that also fetches employees who are included in this event
  };
  if (
    currentEvent?.eventType !== "Interview" &&
    rosterFilters &&
    (!rosterFilters.status || rosterFilters.status === "Not Roster")
  ) {
    applicantOptions.filters.status = "Employee";
    applicantOptions.filters["venues.status"] = "StaffingPool";
  }
  const eventVenue = venues?.[currentEvent?.venueSlug] || {};

  const {
    data: rosterApplicants,
    isLoading: rosterLoading,
    isRefetching,
    refetch: requeryRoster,
  } = useRosterApplicants({
    options: {
      onSuccess: (data) => {
        if (data.result) {
          setRosterIds(
            data?.result?.map((item) => item.id) // .filter((item) => item.status === "Roster")?
          );
          setRosterData(data?.result.filter((item) => ["Roster"].includes(item.status)));
        } else {
          setRosterIds([]);
        }
      },
    },
  });

  const toggleReadRoster = () => {
    requeryRoster();
  };

  const {
    data: applicants,
    isLoading: appLoading,
    isRefetching: isRefetchingApps,
    refetch: requeryApplicants,
  } = useQuery(
    ["applicants", applicantOptions],
    () =>
      currentEvent?.eventType === "Interview"
        ? fetchApplicantsOnly(applicantOptions)
        : fetchStaffingPool(applicantOptions),
    {
      onSuccess: (retValue) => {
        toggleReadRoster();
      },
    }
  );

  const toggleReadApps = () => requeryApplicants(); // queryClient.invalidateQueries(["applicant", applicantOptions]);

  const toggleRefreshBadges = () => setRefreshBadges((p) => !p);

  useEffect(() => {
    toggleReadApps();
    toggleReadRoster();
  }, [rosterFilters]);

  const isOnRoster = (applId, i) => {
    const returnValue = rosterIds.findIndex((item) => item === applId) > -1;
    return returnValue;
  };

  const columns = useMemo(
    () => [
      {
        title: "Roster",
        field: "_id",
        width: 15,
        customCell: (check, row, field) => {
          const eventApplicant =
            rosterApplicants?.result?.find((item) => item.id === row?._id) || null;
          const eventStatus = eventApplicant?.status || "Not Roster";
          const applicantVenueStatus = getApplicantVenueStatus(row, currentEvent?.venueSlug);
          const isInvalid = applicantVenueStatus === "Locked" || row?.recordLocked === "Yes";
          return (
            <>
              <EventStatusButton
                status={eventStatus}
                isInvalid={isInvalid}
                applicant={row}
                event={{ _id: currentEvent?._id, eventUrl, applicants: rosterApplicants?.result }}
                onUpdate={() => toggleReadRoster()}
                iconType="distinct"
              />
            </>
          );
        },
      },
      // {
      //   title: "Locked",
      //   field: "recordLocked",
      //   width: 15,
      //   customCell: (recordLocked) => (
      //     <Box pl={0.75}>
      //       {recordLocked === "Yes" ? (
      //         <Lock fontSize="medium" color="error" />
      //       ) : (
      //         <LockOpen fontSize="medium" color="success" />
      //       )}
      //     </Box>
      //   ),
      // },
      {
        title: "Venue",
        field: "venues",
        width: 15,
        customCell: (venue, row) => (
          <>
            <VenueStatusButton
              status={getApplicantVenueStatus(row, currentEvent?.venueSlug)}
              venue={eventVenue}
              onUpdate={() => requeryApplicants()}
              applicant={row}
            />
          </>
        ),
      },
      // {
      //   title: "Status",
      //   field: "profileImg",
      //   customCell: (image, field, row) => <EmployeeStatus applicant={row} size="md" />,
      // },

      {
        title: "Last Name",
        field: "lastName",
        customCell: (name, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (name, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      { title: "City", field: "city" },
      // { title: "State", field: "state" },
      // ...(currentEvent?.eventType === "Interview"
      //   ? [{ title: "Status", field: "status" }]
      //   : [{ title: "Rank", field: "rank" }]),
      { title: "Email", field: "email" },
      // ...(currentEvent?.eventType === "Event"
      //   ? [
      //       {
      //         title: "Position",
      //         field: "primaryPosition",
      //       },
      //     ]
      //   : []),
    ],
    [rosterIds, rosterApplicants, currentEvent, eventUrl, eventVenue, queryClient]
  );

  const navigate = useNavigate();
  const addNew = () => {
    navigate("/partners/create");
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item xs={6}>
          <MDBox display="flex" flexWrap="wrap">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              borderRadius="xl"
              mt={-4}
              ml={3}
            >
              <Icon fontSize="large">people</Icon>
            </MDBox>

            <MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {currentLoggedUser?.employeeType === "Event Admin" ? "Event Admin" : "Client"} Event
                Roster - {roster}
                {` - Applicant Pool Size: ${applicantPoolSize || 0}`}
              </MDTypography>

              <MDTypography variant="h6" color="dark" sx={{ ml: 2 }}>
                {`${currentEvent?.eventName} at ${moment(currentEvent?.eventDate).format(
                  "ddd, MMM DD, YYYY hh:mm a"
                )}`}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>

        {/* <Grid item xs={3}>
          <Searchbar
            fetch={
              currentEvent?.eventType === "Interview" ? fetchApplicantsOnly : fetchStaffingPool
            }
            placeholder="Add To Staffing Pool"
            columns={columns}
            queryCharacterLimit={2}
            resultsLimit={20}
            scrollLimit={200}
            setFilters={setFilters}
            sortFunction={sortLastFirst}
            setPage={setPage}
            searchBy={["firstName", "lastName", "email"]}
            filterBy="email"
            refetchDependency={applicants}
          />
        </Grid> */}
        <Grid item xs={3} textAlign="center">
          <EventRosterActions
            id={id}
            setRoster={setRoster}
            setFilters={setFilters}
            currentEvent={currentEvent}
            setExportFieldModalOpen={setExportFieldModalOpen}
            toggleBulkMessageModal={toggleBulkMessageModal}
          />
        </Grid>
      </Grid>

      <Box display="flex" pl={3} mt={2} mb={3}>
        {currentEvent?.eventType !== "Interview" ? (
          <TimeRosterFilterCards
            setFilters={setRosterFilters}
            selectedCardNumber={selectedCardNumber}
            rosterFilters={rosterFilters}
            setRosterStatus={setRosterStatus}
            refreshBadges={refreshBadges}
          />
        ) : (
          <InterviewEventFilterCards
            setFilters={setRosterFilters}
            selectedCardNumber={selectedCardNumber}
            rosterFilters={rosterFilters}
          />
        )}
      </Box>
      <MDBox>
        <>
          {(roster === "Signups" || roster === "") && (
            <Grid item xs={12} sm={12}>
              <EventRosterTable
                name="Events Time Roster"
                fetchAll
                // filters={{ "venues.venueSlug": currentEvent?.venueSlug }}
                rosterFilters={rosterFilters}
                showEventStatus
                setApplicantPoolSize={setApplicantPoolSize}
                eventDay={eventDay}
                applicants={applicants}
                rosterApplicants={rosterApplicants}
                rosterLoading={rosterLoading}
                appLoading={appLoading}
                defaultSort="lastName"
                rosterIds={!rosterLoading ? rosterIds : []}
                isOnRoster={isOnRoster}
                setApplicantsData={setApplicantsData}
                readRoster={readRoster}
                setReadRoster={setReadRoster}
                readApps={readApps}
                setReadApps={setReadApps}
                toggleReadApps={toggleReadApps}
                toggleReadRoster={toggleReadRoster}
                toggleRefreshBadges={toggleRefreshBadges}
                setSelectedCardNumber={setSelectedCardNumber}
                requeryApplicants={requeryApplicants}
              />
            </Grid>
          )}
          {roster === "Partners" && (
            <Grid item xs={12} sm={12}>
              <PartnerEventTimeClockTable
                setActionSelected={setActionSelected}
                showFiltersList
                filters={filters}
                setFilters={setFilters}
              />
            </Grid>
          )}
        </>
      </MDBox>
      {/* {isBulkMessageModalOpen && (
        <BulkMessagingModal
          applicantFilter={rosterFilters}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          currentEvent={currentEvent}
          messageContext="Event"
          recipientText={getActiveFilterTitle(rosterFilters.filt)}
        />
      )} */}
    </Card>
  );
};

EventRosterAndTime.defaultProps = {
  showFiltersList: 0,
};

// Typechecking props
EventRosterAndTime.propTypes = {
  showFiltersList: PropTypes.bool,
};

export default EventRosterAndTime;
