import axios from "axios";
import { baseAxios } from "config";

import createApiParams from "utils/createApiParams";

const fetchEventsCurrent = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  venues,
  useOr = false,
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters: { ...filters, timeFrame: filters?.timeFrame ? filters?.timeFrame : "Current" },
  });
  
  try {
    const res = await baseAxios.get(
      `/events${paginationQuery}${sortQuery}${filterQuery}${useOr ? "&useOr=true" : ""}`
    );
    // we need to preprocess the data to format the image URLs and other fields
    if (res?.data?.data?.length) {
      const retData = res.data.data.map((event) => ({
        ...event,
        logoUrl: venues?.[event.venueSlug]?.logoUrl,
      }));
      res.data = { ...res.data, data: [...retData] };
    }
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchEventsCurrent;
