/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useState, useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

// store/api/helper
import { useAppContextController } from "context/AppContext";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import LocationsPanel from "components/LocationsPanel";

const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const VenueLocations = () => {
  const { currentVenue, setCurrentVenue } = useAppContextController();

  const [locs, setLocs] = useState([]);
  const [tempLocs, setTempLocs] = useState(locs);
  const [primaryInfo, setPrimaryInfo] = useState();
  const [currentLocation, setCurrentLocation] = useState(emptyValues);

  const { mutateAsync: updateVenueMutation } = useMutation(updateVenue);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateVenue = async (values, method = "update", onSuccess) => {
    if (!currentVenue?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateVenueMutation(
        { venueId: currentVenue?._id, data: values },
        {
          onSuccess: async (res) => {
            setCurrentVenue({ ...currentVenue, ...values });
            onSuccess(method, values?.locations);
            enqueueSnackbar("Venue locations has been updated.", { variant: "success" });
            await queryClient.invalidateQueries("venues");
          },
        }
      );
    }
  };

  // on company change or current company update
  useEffect(() => {
    setPrimaryInfo();
    if (currentVenue?.locations) {
      setLocs(currentVenue?.locations);
      setTempLocs(currentVenue?.locations);
      if (currentLocation && currentLocation?.primaryLocation === "Yes") {
        const temp = currentVenue?.locations?.find((item) => item?.primaryLocation === "Yes");
        setCurrentLocation(temp);
      }
    } else {
      setLocs([]);
      setTempLocs([]);
    }
  }, [currentVenue]);

  return (
    <LocationsPanel
      name={currentVenue?.name}
      onUpdate={handleUpdateVenue}
      locs={locs}
      tempLocs={tempLocs}
      setTempLocs={setTempLocs}
      primaryInfo={primaryInfo}
      setPrimaryInfo={setPrimaryInfo}
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      currentData={currentVenue}
      isVenue
    />
  );
};

export default VenueLocations;
