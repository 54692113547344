/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import useYupValidationResolver from "hooks/useYupValidationResolver";

import updateEntityDropdown from "context/actions/updateEntityDropdown";
import postEntityDropdown from "context/actions/postEntityDropdown";
import { isEmpty, isEqual } from "lodash";

import { Box, Button, Grid, FormHelperText, TableHead } from "@mui/material";
import { Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";

import MDTypography from "components/MDTypography";
import GenericModal from "components/GenericModal";
import DataList from "components/DataList";
import DropdownStringValue from "components/DropdownValueItem/DropdownStringValue";
import DropdownObjectValue from "components/DropdownValueItem/DropdownObjectValue";

import useSessionAuth from "hooks/useSessionAuth";
import AddStringValues from "./AddStringValues";
import AddObjectValues from "./AddObjectValues";
import DropdownEntitiesFields from "./DropdownEntitiesFields";
import dropdownEntitiesSchema from "./dropdownEntitiesSchema";
import ObjectFieldTags from "./ObjectFieldTags";

const emptyValues = {
  page: "Apply",
  entityType: "String",
  name: "",
  arrayValue: [],
};

const commonDataListValues = {
  greybar: true,
  divider: true,
  scrollY: true,
  tableHeight: 200,
  height: 247,
};

const DropdownEntitiesModal = ({ allDropdowns, open, setOpen, dropdown }) => {
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = dropdownEntitiesSchema(dropdown, allDropdowns);
  const resolver = useYupValidationResolver(validationSchema);

  const queryClient = useQueryClient();
  const [stringValue, setStringValue] = useState(null);
  const [objectValue, setObjectValue] = useState({});
  const [fieldName, setFieldName] = useState("label");
  const [fieldValue, setFieldValue] = useState("value");
  const [tags, setTags] = useState([]);

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm({ defaultValues: emptyValues, resolver });

  const entityType = watch("entityType");
  const arrayValue = watch("arrayValue");

  const isRenderString =
    entityType === "String" && (isEmpty(arrayValue) || typeof arrayValue?.[0] === "string");
  const isRenderObject =
    entityType === "Object" && (isEmpty(arrayValue) || typeof arrayValue?.[0] === "object");

  const checkEmptyValue = (val, err, message = "You must enter a value") => {
    const type = "empty";

    if (val?.trim() === "" || val === null || val === undefined) {
      setError(err, { message, type });
    } else if (errors?.[err]?.type === type) {
      clearErrors(err);
    }
  };

  const checkUniqueValue = (val, err, list, message = "This item already exists") => {
    const type = "duplicate";
    const repeatedVal = list.find((item) => val?.toLowerCase()?.trim() === item?.toLowerCase());

    if (repeatedVal) {
      setError(err, { message, type });
    } else if (errors?.[err]?.type === type) {
      clearErrors(err);
    }
  };

  const handleAddStringValue = () => {
    checkEmptyValue(stringValue, "stringValue");
    checkUniqueValue(stringValue, "stringValue", arrayValue);
    if (errors?.stringValue) return;
    setValue("arrayValue", [...arrayValue, stringValue], { shouldDirty: true });
    setStringValue("");
  };

  const handleAddObjectValue = () => {
    // for checking unique values
    // const list = arrayValue.map((item) => item[fieldName]);

    tags?.forEach((tag) => {
      checkEmptyValue(objectValue[tag], tag);
    });

    const errorCheck = tags.map((tag) => errors?.[tag]).filter((e) => e !== undefined);

    if (!isEmpty(errorCheck)) return;
    setValue("arrayValue", [...arrayValue, objectValue], { shouldDirty: true });
    setObjectValue({});
  };

  const handleRemoveListValue = (idx) => {
    const tempList = arrayValue.filter((_, i) => i !== idx);
    setValue("arrayValue", tempList, { shouldDirty: true });
  };

  const handleEditStringValue = (row, idx, text) => {
    if (isEqual(row, text)) {
      clearErrors(`stringValue_${idx}`);
      return;
    }

    checkEmptyValue(text, `stringValue_${idx}`);
    checkUniqueValue(text, `stringValue_${idx}`, arrayValue);

    if (errors?.[`stringValue_${idx}`]) return;

    const tempList = arrayValue.map((item, i) => {
      if (i === idx) return text;
      return item;
    });
    setValue("arrayValue", tempList, { shouldDirty: true });
  };

  const handleEditObjectValue = (oldVal, idx, field, text) => {
    if (isEqual(oldVal, text)) {
      clearErrors(`${field}_${idx}`);
      return;
    }

    checkEmptyValue(text, `${field}_${idx}`);
    // if (field === fieldName) {
    //   const list = arrayValue.map((item) => item[fieldName]);
    //   checkUniqueValue(text, `${fieldName}_${idx}`, list);
    // }

    if (errors?.[`${field}_${idx}`]) return;

    const tempList = arrayValue.map((item, i) => {
      if (i === idx) return { ...item, [field]: text };
      return item;
    });
    setValue("arrayValue", tempList, { shouldDirty: true });
  };

  const { logoutAuth0User } = useSessionAuth();

  const handleResetChanges = () => {
    setFieldName("label");
    setFieldValue("value");
    setStringValue("");
    setObjectValue({});
    setTags([]);
    clearErrors();
    reset(emptyValues);
  };

  const handleSaveChanges = async (data) => {
    let body;
    try {
      let res;
      let saveAction = "updated";
      if (dropdown?._id) {
        body = { ...dropdown, ...data, dateModified: new Date().toISOString() }; // double check
        res = await updateEntityDropdown(dropdown._id, body);
      } else {
        saveAction = "created";
        body = data;
        res = await postEntityDropdown(body);
      }
      if (res.status === 200 || res.status === 201 || res.acknowledged) {
        await queryClient.invalidateQueries("dropdowns");
        enqueueSnackbar(`Dropdown ${saveAction}`, { variant: "success" });
        handleResetChanges();
        setOpen(false);
      }
    } catch (err) {
      enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
      if (String(err).includes("401") || err?.response?.status === 401) {
        logoutAuth0User();
      }
      throw new Error(err.toString());
    }
  };

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      handleSaveChanges(data);
    }
  };

  const initializeDropdown = () => {
    const { arrayValue: array, entityType: type, name, page } = dropdown;
    const keys = Object.keys(array[0]);
    setFieldName(keys[0]);
    setFieldValue(keys[1]);
    const defaultValues = {
      page,
      entityType: type,
      name,
      arrayValue: array,
    };
    reset(defaultValues);
  };

  useEffect(() => {
    if (dropdown) {
      const tempTags = Object.keys(dropdown?.arrayValue?.[0]);
      setStringValue("");
      setObjectValue({});
      setTags(tempTags);
      initializeDropdown();
    } else {
      handleResetChanges();
    }
  }, [dropdown, open]);

  useEffect(() => {
    if (!dropdown) setValue("arrayValue", []);
    clearErrors(["stringValue", fieldName, fieldValue, "arrayValue"]);
  }, [entityType]);

  useEffect(() => {
    if (!isEmpty(arrayValue)) clearErrors("arrayValue");
  }, [arrayValue]);

  const tableHead = (
    <TableHead sx={{ py: 1, zIndex: 3, position: "sticky", top: "-25px", bgcolor: "#FFF" }}>
      <Grid container spacing={2}>
        <Grid item sm={10}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ ...(tags?.length > 3 && { width: 300 + tags?.length * 100 }) }}
          >
            {tags?.map((tag) => (
              <Grid key={tag} item xs={12 / tags?.length}>
                <MDTypography variant="body2" fontWeight="bold">
                  {tag || ""}
                </MDTypography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item sm={2} />
      </Grid>
    </TableHead>
  );

  const renderStringValues = (row, idx) => {
    return (
      <DropdownStringValue
        row={row}
        idx={idx}
        onEdit={handleEditStringValue}
        onRemove={handleRemoveListValue}
        clearErrors={clearErrors}
        errors={errors}
      />
    );
  };

  const renderObjectValues = (row, idx) => {
    return (
      <DropdownObjectValue
        row={row}
        idx={idx}
        onEdit={handleEditObjectValue}
        onRemove={handleRemoveListValue}
        clearErrors={clearErrors}
        errors={errors}
        tags={tags}
      />
    );
  };

  const header = (
    <Box display="flex" justifyContent="space-between" ml={1}>
      <MDTypography variant="h4">Drop Down Entity</MDTypography>
    </Box>
  );

  const modalBody = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" justifyContent="flex-end" mt={-7.5} mb={3}>
        <Button
          variant="text"
          startIcon={<CancelIcon />}
          onClick={() => {
            handleResetChanges();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            (!isDirty && !!dropdown) ||
            !!errors?.page ||
            !!errors?.type ||
            !!errors?.name ||
            !!errors?.arrayValue
          } // !isEmpty(errors)
          type="submit"
          variant="contained"
          endIcon={<SaveIcon />}
          style={{ color: "white" }}
        >
          Save
        </Button>
      </Box>
      <DropdownEntitiesFields
        dropdown={dropdown}
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
      />
      {isRenderObject && (
        <Box mt={1}>
          <ObjectFieldTags
            tags={tags}
            setTags={setTags}
            setValue={setValue}
            arrayValue={arrayValue}
          />
        </Box>
      )}
      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography component="label" variant="button" fontWeight="bold" color="info">
          VALUES
        </MDTypography>
      </Box>
      {isRenderString && (
        <>
          <AddStringValues
            value={stringValue}
            setValue={setStringValue}
            onAdd={handleAddStringValue}
            errors={errors}
          />
          {errors?.arrayValue && (
            <FormHelperText error sx={{ fontSize: "0.75rem" }}>
              {errors?.arrayValue?.message}
            </FormHelperText>
          )}
          <DataList
            renderRow={renderStringValues}
            data={arrayValue.sort((a, b) => a.localeCompare(b))} // newList?.sort()}
            {...commonDataListValues}
          />
        </>
      )}
      {isRenderObject && (
        <>
          <AddObjectValues
            tags={tags}
            value={objectValue}
            setValue={setObjectValue}
            onAdd={handleAddObjectValue}
            errors={errors}
            fieldName={fieldName}
            fieldValue={fieldValue}
          />
          {errors?.arrayValue && (
            <FormHelperText error sx={{ fontSize: "0.75rem" }}>
              {errors?.arrayValue?.message}
            </FormHelperText>
          )}
          <DataList
            renderRow={renderObjectValues}
            data={arrayValue} // newList?.sort()}
            {...commonDataListValues}
            tableHead={tableHead}
          />
        </>
      )}
    </form>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      body={modalBody}
      header={header}
      sx={(theme) => ({
        width: "90% !important",
        maxWidth: "700px !important",
        [theme.breakpoints.down("md")]: {
          "& .MuiCardContent-root": {
            padding: "0px",
          },
        },
      })}
    />
  );
};

export default DropdownEntitiesModal;
