import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import uploadFileCompany from "api/companies/uploadFileCompany";
import uploadFileVenue from "layouts/pages/venues/actions/uploadFileVenue";

const useAttachmentUpload = ({
  parentData,
  data,
  parentName,
  name,
  onUpdate,
  currentData,
  page = "companies",
  isNested = false,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: uploadFileCompanyMutation } = useMutation(uploadFileCompany);
  const { mutateAsync: uploadFileVenueMutation } = useMutation(uploadFileVenue);

  const uploadFileHandler = async (form, filePath, fileExt) => {
    const newAttachment = {
      docType: fileExt,
      filename: filePath,
      uploadDate: new Date(),
    };
    const newAttachmentList = data ? [...data, newAttachment] : [newAttachment];

    const updatedValues = isNested
      ? { [parentName]: { ...parentData, [name]: newAttachmentList } }
      : { [name]: newAttachmentList };

    const resFunc = {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries(page);
        enqueueSnackbar("Attachments has been uploaded.", { variant: "success" });
      },
      onError: (err) => console.log(err),
    };

    if (page === "venues")
      uploadFileVenueMutation({ venueSlug: currentData?.slug, uploadForm: form }, resFunc);

    if (page === "companies")
      uploadFileCompanyMutation({ companySlug: currentData?.slug, uploadForm: form }, resFunc);

    onUpdate(updatedValues);
  };

  const onFileDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length > 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles?.length <= 0) return;

    const formUpload = new FormData();
    const uploadedFile = acceptedFiles[0];
    const uploadedFileExtension = uploadedFile?.path?.split(".").pop();

    formUpload.append("file", uploadedFile);

    if (currentData?._id) uploadFileHandler(formUpload, uploadedFile.path, uploadedFileExtension);
  };

  return { onFileDropHandler };
};

export default useAttachmentUpload;
