import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const renderCustomYAxisTick = ({ x, y, payload }, icons) => (
  <foreignObject x={x - 40} y={y - 20} width={40} height={40}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {icons?.[payload?.value]}
    </div>
  </foreignObject>
);

const CustomBarLabel = ({ x, y, width, height, name }) => (
  <text
    x={x}
    dx={width + 20}
    y={y}
    dy={height * 0.7}
    fill="#344767"
    textAnchor="start"
    fontSize={Math.min(20, height * 0.7)}
  >
    {name}
  </text>
);

const CustomBarValue = ({ x, y, height, value }) => (
  <text
    x={x}
    dx={height * 0.35}
    y={y}
    dy={height * 0.7}
    fill="white"
    textAnchor="start"
    fontSize={Math.min(20, height * 0.7)}
    fontWeight="lighter"
  >
    {value}
  </text>
);

const CustomBarChart = ({ data, icons }) => {
  return (
    <ResponsiveContainer aspect={2}>
      <BarChart data={data} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis type="number" axisLine={false} tick={null} />
        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={(props) => renderCustomYAxisTick(props, icons)}
          interval={0}
        />
        <Tooltip />
        <Bar dataKey="value" radius={[18, 18, 18, 18]} minPointSize={50}>
          {data.map((entry) => (
            <>
              <Cell key={entry?.name} fill={entry?.color} />
              <LabelList content={<CustomBarLabel />} />
              <LabelList content={<CustomBarValue />} />
            </>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
