/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";

// store/api/helper
import useCompanyStore from "stores/useCompanyStore";
import updateCompany from "api/companies/updateCompany";

import ContactsPanel from "components/ContactsPanel";

const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  phoneExt: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const CustomerContacts = ({ setSelectedPanel, setSelectedContact }) => {
  const { currentLoggedUser } = useAppContextController();
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const [contacts, setContacts] = useState([]);
  const [tempContacts, setTempContacts] = useState(contacts);
  const [primaryInfo, setPrimaryInfo] = useState();
  const [currentContact, setCurrentContact] = useState(emptyValues);

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCustomer = async (values, method = "update", onSuccess) => {
    const createAgent = currentLoggedUser?._id;
    if (!currentCompany?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: currentCompany?._id, body: { ...values, createAgent } },
        {
          onSuccess: async (res) => {
            setCurrentCompany({ ...currentCompany, ...values });
            await queryClient.invalidateQueries("customers_data");
            onSuccess(method, values?.contacts);
            enqueueSnackbar("Customer contacts has been updated.", { variant: "success" });
          },
        }
      );
    }
  };

  // on company change or current company update
  useEffect(() => {
    setPrimaryInfo();
    if (currentCompany?.contacts) {
      setContacts(currentCompany?.contacts);
      setTempContacts(currentCompany?.contacts);
      if (currentContact && currentContact?.primaryContact === "Yes") {
        const temp = currentCompany?.contacts?.find((item) => item?.primaryContact === "Yes");
        setCurrentContact(temp);
      }
    } else {
      setContacts([]);
      setTempContacts([]);
    }
  }, [currentCompany]);

  return (
    <ContactsPanel
      name={currentCompany?.name}
      sourceComponent="Customer"
      onUpdate={handleUpdateCustomer}
      setSelectedPanel={setSelectedPanel}
      setSelectedContact={setSelectedContact}
      contacts={contacts}
      tempContacts={tempContacts}
      setTempContacts={setTempContacts}
      primaryInfo={primaryInfo}
      setPrimaryInfo={setPrimaryInfo}
      currentContact={currentContact}
      setCurrentContact={setCurrentContact}
      currentData={currentCompany}
    />
  );
};

export default CustomerContacts;
