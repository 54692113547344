import { useState } from "react";
import { Autocomplete, Chip, ButtonGroup, Button } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import useDropdowns from "hooks/useDropdowns";
import MDBox from "components/MDBox";

const Skills = ({
    setValue,
    value = [],
    setError,
    clearErrors,
    currentComponent,
    outsideMode = "",
    ...props
}) => {
    const [skillInput, setSkillInput] = useState("")

    const addSkill = (skill) => {
        setValue("otherSkills", [...value, skillInput], { shouldDirty: true });
        setSkillInput("");
    }

    const handleSkillInputKeyDown = (event) => {
        if ([";", ",", "Enter", "Tab"].includes(event.key)) {
            event.preventDefault();
            addSkill(skillInput.trim());
        }
    };

    const handleDelete = (chipToDelete) => () => {
        setValue(
            "otherSkills",
            value?.filter((chip) => chip !== chipToDelete),
            { shouldDirty: true }
        );
    };

    return (
        <div>
            <FormField
                {...props}
                type="text"
                label="Other Skills"
                value={skillInput}
                onChange={(e) => setSkillInput(e.target.value)}
                onKeyDown={handleSkillInputKeyDown}
            />


            <MDBox sx={{ border: "1px solid grey" }}>
                {value?.length
                    ? value?.map((skill, index) => (
                        <Chip
                            label={skill}
                            onDelete={handleDelete(skill)}
                            key={skill.replace(" ", "_")}
                            style={{ margin: "0.5em" }}
                        />
                    ))
                    : null}
            </MDBox>
        </div>
    );
};

export default Skills;
