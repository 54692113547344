import { Icon, Stack, Button, Grid, IconButton } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import LoginIcon from "@mui/icons-material/Login";
import AttachFile from "@mui/icons-material/AttachFile";
import SmsIcon from "@mui/icons-material/Sms";
import BadgeIcon from "@mui/icons-material/Badge";
import { SettingsInputComponent } from "@mui/icons-material";

const GetIcons = ({ action, activity }) => {
  if (activity?.type === "Login")
    return <LoginIcon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`} />;
  if (activity?.type === "User")
    return <BadgeIcon fontSize="large" color="error" key={`activity?_icon_${activity?._id}`} />;
  if (activity?.type === "Message")
    return <MessageIcon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`} />;
  if (activity?.type === "Event")
    return (
      <Icon fontSize="large" color="success" key={`activity?_icon_${activity?._id}`}>
        event-seat
      </Icon>
    );
  if (activity?.type === "Applicant")
    return (
      <Icon fontSize="large" color="error" key={`activity?_icon_${activity?._id}`}>
        people
      </Icon>
    );
  if (activity?.type === "Template")
    return <SmsIcon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`} />;
  if (activity?.type === "Venue")
    return (
      <Icon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`}>
        stadium
      </Icon>
    );
  if (activity?.type === "Customer")
    return (
      <Icon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`}>
        business
      </Icon>
    );
  if (activity?.type === "Job")
    return (
      <Icon fontSize="large" color="warning" key={`activity?_icon_${activity?._id}`}>
        work
      </Icon>
    );
  if (activity?.type === "Notification")
    return (
      <Icon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`}>
        description
      </Icon>
    );
  if (activity?.type === "Attachment")
    return <AttachFile fontSize="large" color="dark" key={`activity?_icon_${activity?._id}`} />;
  if (activity?.type === "Integration")
    return (
      <SettingsInputComponent
        fontSize="large"
        color="warning"
        key={`activity?_icon_${activity?._id}`}
      />
    );
  return (
    <Icon fontSize="large" color={activity?.color} key={`activity?_icon_${activity?._id}`}>
      stadium
    </Icon>
  );
};
//   if (activity.action === "User Login")
//     return <LoginIcon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`} />;

//   if (activity?.msgId || activity?.messageId || activity.type === "Message")
//     return <MessageIcon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`} />;
//   if (
//     activity?.eventId &&
//     ["Update Event Roster", "Event Clock In", "Event Clock Out"].includes(action)
//   )
//     return (
//       <Icon fontSize="large" color="success" key={`activity?_icon_${activity?._id}`}>
//         event-seat
//       </Icon>
//     );
//   if (activity?.applicantId)
//     return (
//       <Icon fontSize="large" color="error" key={`activity?_icon_${activity?._id}`}>
//         people
//       </Icon>
//     );
//   if (activity?.templateId)
//     return <SmsIcon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`} />;
//   if (activity?.venueId)
//     return (
//       <Icon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`}>
//         stadium
//       </Icon>
//     );
//   if (activity?.eventId)
//     return (
//       <Icon fontSize="large" color="success" key={`activity?_icon_${activity?._id}`}>
//         event-seat
//       </Icon>
//     );
//   if (activity?.jobId)
//     return (
//       <Icon fontSize="large" color="warning" key={`activity?_icon_${activity?._id}`}>
//         work
//       </Icon>
//     );
//   if (activity?.action.toLowerCase().includes("note"))
//     return (
//       <Icon fontSize="large" color="info" key={`activity?_icon_${activity?._id}`}>
//         description
//       </Icon>
//     );
//   if (activity?.action.toLowerCase().includes("attachment"))
//     return <AttachFile fontSize="large" color="dark" key={`activity?_icon_${activity?._id}`} />;
//   return (
//     <Icon fontSize="large" color={activity?.color} key={`activity?_icon_${activity?._id}`}>
//       stadium
//     </Icon>
//   );
// };

export default GetIcons;
