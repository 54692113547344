import { Box, Grid, Link } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useEffect } from "react";
import parse from "html-react-parser";

const CongratulationsBasic = () => {
  const { updateButtons } = useNewApplicantContext();
  const { company } = useAppContextController();

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: false,
        disabled: false,
      },
      submit: {
        show: false,
        disabled: false,
      },
    });
  }, []);

  return (
    <>
      <Grid container p={3}>
        <Grid item xs={12}>
          <MDTypography variant="h3">Congratulations!</MDTypography>
        </Grid>
        <Grid>
          <Grid item xs={12} mt={3}>
            <MDTypography variant="body2">You have updated your information.</MDTypography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CongratulationsBasic;
