import { Button, Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";

const CustomNotification = ({ message, key, copyMessage, extraButtons }) => {
    const { closeSnackbar } = useSnackbar();

    const handleClose = (key1) => () => {
      closeSnackbar(key1);
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid container>
          <Grid item xs={12} >
            {message}
          </Grid>
          <Grid item xs={12} textAlign="end" mt={2} mb={1}>
            <Button
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(copyMessage);
              }}

            >
              Copy information
            </Button>
            {extraButtons}
          </Grid>
        </Grid>


        <div style={{ alignSelf: "start" }}>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose(key)}
            style={{ marginLeft: '8px' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div >
    );
  };

  export default CustomNotification