const feetToMeters = (feet) => {
  const meters = feet * 0.3048;
  return +meters.toFixed(2) || 0;
};

const metersToFeet = (meters) => {
  const feet = meters * 3.28084;
  return +feet.toFixed(2) || 0;
};

function milesToMeters(miles) {
  const meters = miles * 1609.34;
  return +meters.toFixed(2) || 0;
}

export { feetToMeters, metersToFeet, milesToMeters };
