import moment from "moment";

export const MIN_DATE = moment("2023-01-01").toISOString();
export const MAX_DATE = moment().toISOString();

export const defaultFilterEditorValues = {
  andOr: "And",
  filterEditorModalRows: [
    {
      field: null,
      isOrNot: "Is",
      operator: "Equal",
      value: null,
    },
  ],
};

export const defaultReportModalValues = {
  startDate: MIN_DATE,
  endDate: MAX_DATE,
  filterCards: "",
  subFilterCards: "",
  sortBy: "",
  order: "",
  dateField: "",
  dateRange: "",
  formType: "table",
  filters: defaultFilterEditorValues,
  chartFilters: defaultFilterEditorValues,
  tableColumns: [],
  selectedSubtables: [],
  xAxis: "",
  yAxis: "",
  chartType: "",
  groupBy: "",
  chartDateField: "",
  chartDateRange: "",
  chartStartDate: MIN_DATE,
  chartEndDate: MAX_DATE,
};
