import { Controller } from "react-hook-form";
import useDropdowns from "hooks/useDropdowns";

import { Grid, Autocomplete } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";

import { COMPANY_ENTITIES } from "utils/constants/entityOptions";

const { STATES_ENTITY_NAME } = COMPANY_ENTITIES;

const LocationForm = ({ control, setValue, formSlug, coordinates }) => {
  const { dropdowns: stateCodes } = useDropdowns({ entity: STATES_ENTITY_NAME });

  const handleInputChange = (value, name, limit) => {
    if (value === "-" || value === "+") {
      setValue(name, value, { shouldDirty: true });
      return;
    }

    if (value === "") {
      setValue(name, 0, { shouldDirty: true });
      return;
    }

    const regex = /^[-+]?(\d*\.\d+|\d+\.\d*|\d+)$/;

    if (regex.test(value)) {
      let val = value || 0;
      if (val > limit) {
        val = limit;
      } else if (val < -limit) {
        val = -limit;
      }

      setValue(name, val, { shouldDirty: true });
      let tempCd = coordinates;
      if (name === "longitude") {
        tempCd = [Number(val), Number(coordinates[1])];
      } else {
        tempCd = [Number(coordinates[0]), Number(val)];
      }
      setValue("geocoordinates.coordinates", tempCd, { shouldDirty: true });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Controller
          name="locationName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              name="locationName"
              {...field}
              onChange={(e) => {
                setValue("locationName", e.target.value, { shouldDirty: true });
                setValue("locationSlug", formSlug(e.target.value), { shouldDirty: true });
              }}
              label="Location Name"
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="locationSlug"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              name="locationSlug"
              disabled
              label="Location Slug"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="address"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Address"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <Controller
          name="city"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="City"
              InputLabelProps={{ shrink: true }}
              {...field}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="state"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={stateCodes}
              {...field}
              name="state"
              autoSelect
              freeSolo
              onChange={(e, v) => {
                setValue("state", v?.toUpperCase() || "", { shouldDirty: true });
              }}
              sx={{ maxHeight: "44px", width: "100%" }}
              renderInput={(params) => (
                <CustomTextField
                  type="text"
                  label="State"
                  showError
                  errorMessage={error?.message}
                  {...params}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="zip"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Zip Code"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="latitude"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Latitude"
              {...field}
              onChange={(e) => {
                handleInputChange(e.target.value, "latitude", 90);
              }}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="longitude"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Longitude"
              {...field}
              onChange={(e) => {
                handleInputChange(e.target.value, "longitude", 180);
              }}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="radius"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Radius"
              {...field}
              onChange={(e) => {
                setValue("radius", Number(e.target.value), { shouldDirty: true });
                setValue("geocoordinates.geoFenceRadius", Number(e.target.value), {
                  shouldDirty: true,
                });
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { step: "any", min: 0 },
              }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="graceDistanceFeet"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Grace Distance (ft)"
              {...field}
              onChange={(e) =>
                setValue("graceDistanceFeet", Number(e.target.value), { shouldDirty: true })
              }
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { step: "any", min: 0 },
              }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default LocationForm;
