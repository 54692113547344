import { Grid, Icon, Stack, Switch } from "@mui/material";
import moment from "moment";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const LICENSE_STATUS_COLOR = {
  Active: "success",
  Expired: "error",
  "In-Process": "warning",
};

const ApplicantLicenseItem = ({ row, idx, onEdit, onRemove }) => {
  const { licenseExpDate, licenseStatus, licenseType, thirtyDayAlert } = row;

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => onEdit(row, true, idx)}
      >
        <Grid container display="flex" alignItems="center" spacing={1}>
          <Grid item md={12} lg={3.5}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>{licenseType.label}</MDTypography>
          </Grid>
          <Grid item md={12} lg={2.5}>
            <MDTypography color={LICENSE_STATUS_COLOR[licenseStatus]} sx={{ fontSize: "0.8rem" }}>
              {licenseStatus}
            </MDTypography>
          </Grid>
          <Grid item md={12} lg={3}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>
              {(licenseExpDate !== null && licenseExpDate !== "") ? moment(licenseExpDate).format("L") : ""}
            </MDTypography>
          </Grid>

          <Grid item md={12} lg={2} mt="-2px">
            <Switch checked={thirtyDayAlert === "Yes"} disabled inputProps={{ "aria-label": "" }} />
          </Grid>

          <Grid item md={12} lg={1} mt="1px">
            <MDBox textAlign="right">
              <Icon
                color="error"
                fontSize="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(idx, row?.licenseType?.label);
                }}
              >
                cancel
              </Icon>
            </MDBox>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ApplicantLicenseItem;
