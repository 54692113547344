import { useMemo } from "react";
import { useQuery } from "react-query";
import fetchApplicantActivities from "components/Activities/actions/fetchApplicantActivities";
import PictureAvatar from "components/PictureAvatar";
import indeedLogo from "assets/images/indeed-logo.png";

const applicantActivityOptions = {
  fetchAll: false,
  orderBy: "activityDate",
  order: "desc",
  limit: 5,
  page: 1,
};

const LastActivity = ({ applicantId }) => {
  const allOptions = useMemo(() => ({ ...applicantActivityOptions, applicantId }), [applicantId]);
  const { data: activities = [], isLoading = true } = useQuery(
    ["activities_job_applicant", allOptions],
    () => fetchApplicantActivities(allOptions),
    { keepPreviousData: true, enabled: !!applicantId }
  );

  const avatarProps = useMemo(() => {
    const user = activities?.data?.find((activity) => activity.userId) || null;
    const indeed = activities?.data?.find((activity) => activity.integration === "Indeed") || null;

    if (user) {
      return {
        image: null,
        firstName: user.userFirstName,
        lastName: user.userLastName,
      };
    }
    if (indeed) {
      return {
        image: indeedLogo,
        firstName: "Indeed",
        lastName: "",
      };
    }
    return null;
  }, [activities]);

  if (!avatarProps || isLoading) {
    return null;
  }

  return <PictureAvatar {...avatarProps} size="md" />;
};

export default LastActivity;
