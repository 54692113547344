import { useEffect, useState, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import renameFileJob from "api/jobs/renameFileJob";
import renameFileCompanyJob from "api/jobs/renameFileCompanyJob";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import useCompanyCache from "hooks/useCompanyCache";
import AttachmentFiles from "components/AttachmentFiles";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import updateCompanyJob from "../../actions/updateJob";

const Attachments = ({ isShownLabel = true, isVenue = false }) => {
  const { company, setCompany, setCompanyType, setCurrentCompany, currentJob, setCurrentJob } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { mutateAsync: updateCompanyMutation } = useMutation(
    isVenue ? updateJob : updateCompanyJob
  );
  const { mutateAsync: renameFileMutation } = useMutation(
    isVenue ? renameFileJob : renameFileCompanyJob
  );
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);
  const title = isVenue ? "" : "Company ";
  const idName = isVenue ? "_id" : "jobId";

  const updateCompanyHandler = async (values, action, fileName) => {
    if (!currentJob?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { [idName]: currentJob._id, data: values },
        {
          onSuccess: async (res) => {
            const actionStatus = action === "DELETE" ? "removed" : "renamed";
            setCurrentJob({ ...currentJob, ...values });
            enqueueSnackbar(`${title}Job Attachment file ${fileName} has been ${actionStatus}.`, {
              variant: "success",
            });
            await queryClient.invalidateQueries("jobs");
          },
        }
      );
    }
  };

  const renameCompanyHandler = async (renameVal, originalVal) => {
    if (!currentJob?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      renameFileMutation(
        {
          rename: renameVal,
          original: originalVal,
          jobSlug: currentJob?.jobSlug,
          ...(isVenue && { venueSlug: currentJob?.venueSlug }),
        },
        {
          onError: (err) =>
            enqueueSnackbar(err.toString(), { variant: "error", autoHideDuration: 3000 }),
        }
      );
    }
  };

  const deleteFileHandler = async (e, idx, fileName) => {
    const filteredFiles = files.filter((file, index) => index !== idx);
    await updateCompanyHandler({ ...currentJob, attachments: filteredFiles }, "DELETE", fileName);
    setFiles(filteredFiles);
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];
    // // get the image extension
    const imgExt = newFiles[idx].filename.substring(newFiles[idx].filename.lastIndexOf("."));
    // // check if edited
    const removeExt = newFiles[idx].filename.substring(0, newFiles[idx].filename.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;
    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].filename;
    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].filename;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );

  const confirmFileNameChanges = async (e, idx) => {
    await renameCompanyHandler(files[idx].editedName, files[idx].filename);
    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], filename: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);
    await updateCompanyHandler(
      { ...currentJob, attachments: newFiles },
      "UPDATE",
      newFiles[idx].filename
    );
  };

  useEffect(() => {
    if (currentJob?.attachments?.length > 0) setFiles(currentJob.attachments);
    else setFiles([]);
  }, [currentJob?.attachments]);

  return (
    <AttachmentFiles
      files={files}
      isShownLabel={isShownLabel}
      company={company}
      onDelete={deleteFileHandler}
      onRename={editFileNameHandler}
      onConfirmRename={confirmFileNameChanges}
      onCancelRename={cancelFileNameChanges}
      isVenue={isVenue}
      isJobs
    />
  );
};

export default Attachments;
