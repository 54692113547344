import { useState } from "react";
import { useAppContextController } from "context/AppContext";
import DataListContainer from "components/DataListContainer";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import postNote from "layouts/pages/companyjobs/actions/postNote";
import { useSnackbar } from "notistack";
import JobPositionItem from "../JobPositionItem";

const defaultValues = (positionNumber) => ({
  status: "Open",
  name: `Position ${positionNumber}`,
  startDate: null,
  modifiedDate: new Date(),
  isNew: true,
});

const JobPositions = ({ positions, onPositionChange, watch, setValue, jobSlug }) => {
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [currentStatusForNote, setCurrentStatusForNote] = useState({});

  const { currentLoggedUser } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();

  const handleAdd = () => {
    const updatedPositions = [...positions, defaultValues((positions?.length ?? 0) + 1)];
    updatedPositions.forEach((pos, index) => {
      if (/^Position \d+$/.test(pos.name)) {
        updatedPositions[index].name = `Position ${index + 1}`;
      }
    });
    onPositionChange(updatedPositions);
  };

  const handleConfirmEditPosition = async (idx, status, clearAll, note) => {
    const updatedPositions = [...positions];
    updatedPositions[idx].status = status;
    if (clearAll) {
      updatedPositions[idx].applicantId = null;
      updatedPositions[idx].name = `Position ${idx + 1}`;
      updatedPositions[idx].startDate = null;
    }
    if (note != null) {
      updatedPositions[idx].note = {
        type: "General",
        text: note,
        firstName: currentLoggedUser?.firstName,
        lastName: currentLoggedUser?.lastName,
        userId: currentLoggedUser?._id,
      };
    }
    onPositionChange(updatedPositions);
  };

  const saveNote = (noteRec) => {
    handleConfirmEditPosition(
      currentStatusForNote?.idx,
      currentStatusForNote?.status,
      currentStatusForNote?.clearAll,
      noteRec.text
    );
  };

  const handleEditPosition = (idx, status, clearAll) => {
    setCurrentStatusForNote({ idx, status, clearAll });
    setNoteModalOpen(true);
  };

  const handleRemovePosition = (idx, label) => {
    const updatedPositions = positions.filter((_, index) => index !== idx);
    updatedPositions.forEach((pos, index) => {
      if (/^Position \d+$/.test(pos.name)) {
        updatedPositions[index].name = `Position ${index + 1}`;
      }
    });
    onPositionChange(updatedPositions);
  };

  const renderRow = (row, idx) => (
    <JobPositionItem
      row={row}
      idx={idx}
      onEdit={handleEditPosition}
      onRemove={handleRemovePosition}
    />
  );

  return (
    <>
      <DataListContainer
        onAdd={handleAdd}
        data={positions}
        renderRow={renderRow}
        title="Positions"
        tableHeight={188}
        height={190}
      />
      {notesModalOpen && (
        <AddANoteModal
          // currentApplicant={applicant}
          open={notesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNoteModalOpen}
          saveNote={saveNote}
          showType={false}
          defaultValue={`Position ${currentStatusForNote?.idx + 1} status changed to ${
            currentStatusForNote?.status
          }`}
          onExtraAction={() =>
            handleConfirmEditPosition(
              currentStatusForNote?.idx,
              currentStatusForNote?.status,
              currentStatusForNote?.clearAll
            )
          }
          extraActionLabel="Change Status Only"
        />
      )}
    </>
  );
};

export default JobPositions;
