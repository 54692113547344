import { useEffect, useMemo, useState } from "react";
import { Button, Grid } from "@mui/material";
import moment from "moment";
import fetchApplicantActivities from "components/Activities/actions/fetchApplicantActivities";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import { useQuery } from "react-query";
import GetIcons from "components/Activities/GetIcons";
import InfiniteScrollDataList from "components/InfiniteScrollDataList";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import useSessionAuth from "hooks/useSessionAuth";
import MDBox from "components/MDBox";
// import { useNavigate } from "react-router-dom";
import ProfileActivityModal from "../ProfileActivityModal";

const ProfileActivities = ({
  currentId,
  filter,
  tableHeight,
  height,
  cardVariant = "outlined",
  boxShadow,
  profilePanel,
  pageSize,
}) => {
  const [user, setUser] = useState();
  const [page, setPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const { setCurrentActivity } = useAppContextController();

  // const navigate = useNavigate();

  const options = useMemo(() => {
    return {
      fetchAll: false,
      orderBy: "activityDate",
      order: "desc",
      limit: pageSize || 50,
      filters: filter ? { action: filter } : null,
    };
  }, [filter]);

  const allOptions = useMemo(() => {
    return { ...options, applicantId: user?._id, page };
  }, [page, user?._id, options]);

  const { data: activities, isLoading } = useQuery(
    ["activitiesapplicant", allOptions],
    () => fetchApplicantActivities(allOptions),
    { keepPreviousData: true, enabled: !!user?._id }
  );

  const handleModalOpen = (row) => {
    setCurrentActivity(row);
    setModalOpen(true);
  };

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const fetchAppl = async () => {
      const appl = await fetchSingleApplicant({ applicantId: currentId });
      if (appl?.data) setUser(appl.data);
    };

    try {
      fetchAppl();
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [currentId]);

  const renderRow = (row) => (
    <Grid container alignItems="center" spacing={3} onClick={() => handleModalOpen(row)}>
      <Grid item xs={12} sm={profilePanel ? 1 : 2} display="flex" justifyContent="center">
        <GetIcons action={row.action} activity={row} />
      </Grid>
      <Grid item xs={12} sm={profilePanel ? 9 : 10}>
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="h6" key={`button_${row.action}`}>
              {row.action}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="body2" key={`date_${row.action}`}>
              {moment(row.activityDate).format("ddd YYYY-MM-DD hh:mm A")}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="body2" key={`position_${row.action}`}>
              {row.description}
            </MDTypography>
          </Grid>
        </Grid>
      </Grid>
      {profilePanel && (
        <Grid item xs={12} sm={1} sx={{ textAlign: "end" }}>
          <MDBox>
            <Button
            // onClick={() => {
            //   navigate(`/activities/${row?._id}/action/activitiesinfo`);
            // }}
            >
              View
            </Button>
          </MDBox>
        </Grid>
      )}
    </Grid>
  );
  return (
    <>
      <InfiniteScrollDataList
        renderRow={renderRow}
        infiniteData={activities}
        isLoading={isLoading}
        greybar
        divider
        scrollY
        boxPadding={0}
        cardVariant={cardVariant}
        headerStyle={() => ({ display: "none" })}
        tableHeight={tableHeight}
        height={height}
        setPage={setPage}
        page={page}
        user={user}
        boxShadow={boxShadow}
      />
      <ProfileActivityModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default ProfileActivities;
