import { useState } from "react";
import { Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import EventRemovalReasonModal from "../EventRemovalReasonModal";

const useStyle = makeStyles({
  text: {
    color: "white",
    textAlign: "center",
  },
});

const RemoveFromEventModal = ({ open, setOpen, onClick, event }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isUnder48Hours = () => {
    // Get the current time
    const now = new Date().getTime();

    // Get the event time
    const eventTime = new Date(event?.eventDate).getTime();

    // Calculate the difference between the two times in milliseconds
    const timeDifference = eventTime - now;

    // Check if the difference is less than 48 hours (172800000 milliseconds)
    return timeDifference < 172800000;
  };

  const classes = useStyle();
  const [eventRemovalReasonModalOpen, setEventRemovalReasonModalOpen] = useState(false);

  const handleRemoveEventClick = () => {
    setEventRemovalReasonModalOpen(true);
  };

  const header = (
    <MDTypography variant="h5" className={classes.text}>
      {!isUnder48Hours()
        ? "Are you sure you want to remove and call-off this event?"
        : "You may not remove this event within 48 hours. Contact your Event Manager for more information."}
    </MDTypography>
  );

  const buttons = (
    <>
      <Box display="flex" flexDirection="column">
        <Button
          // disabled={isUnder48Hours()}
          variant="contained"
          color="white"
          sx={{
            color: "red!important",
          }}
          onClick={() => handleRemoveEventClick()}
        >
          {isUnder48Hours() ? "Leave Message for Event Manager" : "Remove Event"}
        </Button>

        <Button
          variant="text"
          size="large"
          onClick={() => {
            setOpen(false);
          }}
        >
          <MDTypography variant="bod2" color="white" fontWeight="bold">
            Leave me on the schedule
          </MDTypography>
        </Button>
      </Box>
    </>
  );

  const modalBody = (
    <Box>
      <MDTypography variant="h5" className={classes.text}>
        Event call-offs and no-shows are tracked in your employment record and may affect future
        scheduling priority.
      </MDTypography>
      <EventRemovalReasonModal
        open={eventRemovalReasonModalOpen}
        setOpen={setEventRemovalReasonModalOpen}
        isUnder48Hours={isUnder48Hours()}
        onClick={onClick}
      />
    </Box>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        backgroundColor="red"
        width={isSmallScreen ? "80%" : "30%"}
        buttonLocation="center"
      />
    </>
  );
};

export default RemoveFromEventModal;
