import { useState } from "react";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UndoIcon from "@mui/icons-material/Undo";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import EditIcon from "@mui/icons-material/Edit";
import {
  LocalizationProvider,
  StaticDatePicker,
  StaticTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { Popover, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import momentParser from "moment";
import { PickersActionBar } from "@mui/x-date-pickers/PickersActionBar";

const ClockInOutCustomButton = ({ disabled, field, clockInOut, defaultDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [time, setTime] = useState(defaultDate ?? "");

  const toggleMenuOpen = (e) => {
    setMenuOpen((prev) => !prev);
  };
  const togglePickerOpen = (e, args) => {
    setPickerOpen((prev) => {
      if (defaultDate && !prev) setTime(defaultDate);
      return !prev;
    });
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    toggleMenuOpen();
  };

  const changeTimeHandler = () => {
    setPickerOpen(false);
    const tm = time;
    const hours = momentParser(tm).hours();
    const mins = momentParser(tm).minutes();
    if (!Number.isNaN(hours) && !Number.isNaN(mins)) {
      const clockInOutTime = new Date(new Date(defaultDate).setHours(hours, mins)).toISOString();
      return clockInOut({}, clockInOutTime);
    }
    return null;
  };

  const open = Boolean(anchorEl);
  const id = open ? "edit-time-popover" : undefined;

  const handlePicker = () => {
    toggleMenuOpen();
    togglePickerOpen();
  };

  const handleNow = (e) => {
    clockInOut(e);
    toggleMenuOpen();
  };

  return (
    <>
      <Button
        // type="submit"
        variant="contained"
        onClick={handleClick}
        color="success"
        style={{ color: "white" }}
        disabled={disabled}
      >
        {`${field === "timeIn" ? "Clock In" : "Clock Out"} Group`}
      </Button>
      <Menu
        id={id}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={toggleMenuOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem p={3} onClick={handleNow}>
          <AlarmOnIcon size="medium" color="warning" fontSize="large" />
          &nbsp;
          <Typography>{`${field === "timeIn" ? "Clock in now" : "Clock out now"}`}</Typography>
        </MenuItem>
        <MenuItem p={3} onClick={handlePicker}>
          <AccessTimeIcon color="info" fontSize="large" />
          &nbsp;
          <Typography>
            {`Use TimePicker ${field === "timeIn" ? "Clock In" : "Clock Out"}`}{" "}
          </Typography>
        </MenuItem>
      </Menu>
      <Popover
        id={id}
        open={pickerOpen}
        anchorEl={anchorEl}
        onClose={togglePickerOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticTimePicker
            value={time ? moment(time) : ""}
            onChange={(newTime) => setTime(newTime)}
            onClose={togglePickerOpen}
            sx={{
              "& .MuiPickersToolbar-penIconButton": { display: "none" },
            }}
            componentsProps={{
              actionBar: {
                onAccept: changeTimeHandler, // Overwrite onAccept. Otherwise it doesn't trigger if the value hasn't changed
                sx: {
                  backgroundColor: "#fff",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};

export default ClockInOutCustomButton;
