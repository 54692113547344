import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
    height: "100%",
  },
  addButton: {
    fontSize: 40,
  },

  list: {
    width: "70%",
    height: "300px",
    overflowY: "scroll",
    border: "solid black 1px",
    padding: 2,
    scrollBehavior: "smooth",
  },
  button: {
    margin: "8px",
  },
});

function ScrolledList({ data, onClick, onDoubleClick, selected }) {
  const [focusIndex, setFocusIndex] = useState(0);
  const listRef = useRef(null);
  const [isGridClicked, setIsGridClicked] = useState(false);
  const [key, setKey] = useState({
    name: "",
    occurance: 0,
    count: 0,
    index: 0,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.querySelector('[name="ScrollLists"]').contains(event.target)) {
        setIsGridClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      if (!isGridClicked) return
      const pressedKey = event.key.toLowerCase();
      const regax = /^[a-zA-Z]+$/;
      if (pressedKey.match(regax)) {
        const index = _.findIndex(data, (item) =>
          item.firstName.toLowerCase().startsWith(pressedKey)
        );
        const count = data.filter((item) =>
          item.firstName.toLowerCase().startsWith(pressedKey)
        ).length;

        setKey((prevKey) => {
          let newKey = {};
          if (key.name === pressedKey) {
            newKey = {
              ...prevKey,
              index,
              count,
              occurance: (prevKey.occurance + 1) % count,
            };
          } else {
            newKey = { name: pressedKey, index, count, occurance: 0 };
          }
          setFocusIndex(newKey.index + newKey.occurance);
          return newKey;
        });
      }
    },
    [data, key.name, setKey, setFocusIndex, isGridClicked]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const classes = useStyle();

  return (
    <List className={classes.list} ref={listRef}
      name="ScrollLists"
      onClick={() => setIsGridClicked(true)}>
      {data?.map((item, i) => (
        <ListItemButton
          autoFocus={isGridClicked && focusIndex === i}
          key={item?.userId}
          selected={i === selected}
          onClick={() => onClick(i)}
          onDoubleClick={() => {
            onDoubleClick(i, "left");
          }}
        >
          <ListItemText primary={item?.fullName} />
        </ListItemButton>
      ))}
    </List>
  );
}

export default ScrolledList;
