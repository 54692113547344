import axios from "axios";

import { baseAxios } from "config";

const updateCompany = async ({ companyId, body }) => {
  try {
    const res = await baseAxios.put(`/companies/${companyId}`, body);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateCompany;
