import { useAppContextController } from "context/AppContext";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import SalesPanel from "components/SalesPanel/SalesPanel";

const VenueSales = () => {
  const { currentVenue, setCurrentVenue, setCurrentFormDirty } = useAppContextController();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateVenueMutation } = useMutation(updateVenue);

  const handleUpdateVenue = async (values, message, variant = "success") => {
    if (!currentVenue?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateVenueMutation(
        { venueId: currentVenue?._id, data: values },
        {
          onSuccess: async (res) => {
            setCurrentVenue({ ...currentVenue, ...values });
            enqueueSnackbar(message || "Venue sales has been updated.", { variant });
            setCurrentFormDirty(false);
            await queryClient.invalidateQueries("venues");
          },
        }
      );
    }
  };
  
  return <SalesPanel currentData={currentVenue} onUpdate={handleUpdateVenue} page="venues" />;
};

export default VenueSales;
