import { useEffect, useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import { Controller, useForm } from "react-hook-form";
import ShiftJobsItem from "layouts/pages/jobs/components/ShiftJobsItem";
import CustomCard from "components/CustomCard";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PanelActions from "components/PanelActions";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import { Autocomplete, Grid, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import DataListContainer from "components/DataListContainer";
import ShiftForm from "layouts/pages/jobs/components/ShiftJobs/ShiftForm";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { shiftsInfoSchema } from "data/shiftInfoSchema";
import MDTypography from "components/MDTypography";
import StaffingPool from "layouts/pages/jobs/components/ShiftJobs/StaffingPool";
import { useMutation, useQueryClient } from "react-query";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import updateCompanyJob from "layouts/pages/companyjobs/actions/updateJob";
import { useSnackbar } from "notistack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomTextField from "components/Form/CustomTextField";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useTheme } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";
import FormField from "layouts/applications/wizard/components/FormField";
import ShiftFormSwitchController from "layouts/pages/jobs/components/ShiftJobs/ShiftForm/ShiftFormSwitchController";
import WeeklyScheduleConfigurationModal from "layouts/pages/jobs/components/ShiftJobs/ShiftForm/WeeklyScheduleConfigurationModal";

const data2 = {
  shiftName: "",
  shiftStartDate: "",
  shiftEndDate: "",
  payRate: "",
  billRate: "",
  defaultSchedule: {
    monday: { start: "", end: "" },
    tuesday: { start: "", end: "" },
    wednesday: { start: "", end: "" },
    thursday: { start: "", end: "" },
    friday: { start: "", end: "" },
    saturday: { start: "", end: "" },
    sunday: { start: "", end: "" },
  },
  exceptions: [],
  shiftRoster: [],
};

const emptyValues = {
  location: "",
  startDate: "",
  endDate: "",
  additionalConfig: {
    geofence: false,
    earlyClockInMinutes: 0, // done
    autoAdjustEarlyClockIn: false, // done
    allowManualPunches: false, // done
    allowOvertime: false, // done
    allowBreaks: false, // done
    // allowPersonnelClockout: false, // done
    allowUnaccruedLeaveRequest: false, // done
    allowNegativeLeave: false, // done
    negativeHoursLimit: 4,
    autoClockoutShiftEnd: false, // done
  },
  shifts: [],
};

const switchData = (watch) => [
  {
    label: "Clock-In at Shift Start",
    name: "additionalConfig.autoAdjustEarlyClockIn",
    disabled: watch("additionalConfig.earlyClockInMinutes") < 1,
  }, // autoAdjustEarlyClockIn mismatch
  { label: "Allow Employee to Edit Time", name: "additionalConfig.allowManualPunches" }, // allowManualPunches mismatch
  { label: "Geofence", name: "additionalConfig.geofence" },
  // { label: "Allow Personal Clockout", name: "additionalConfig.allowPersonnelClockout" }, // done
  {
    label: "Allow Scheduling Unaccrued PTO",
    name: "additionalConfig.allowUnaccruedLeaveRequest",
    disabled: true,
  }, // allowUnaccruedLeaveRequest mismatch
  { label: "Allow Auto Clockout", name: "additionalConfig.autoClockoutShiftEnd" }, // autoClockoutShiftEnd mismatch
  { label: "Allow Breaks", name: "additionalConfig.allowBreaks" }, // done
  { label: "Allow Negative Leave", name: "additionalConfig.allowNegativeLeave", disabled: true }, // done
  { label: "Allow Overtime", name: "additionalConfig.allowOvertime" }, // done
];

const ShiftJobs = ({ isVenue = false }) => {
  const theme = useTheme();
  const [existingData, setExistingData] = useState(emptyValues);
  const [selectedShiftIndex, setSelectedShiftIndex] = useState(null);
  const [isShiftsDirty, setShiftsDirty] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isWeeklyScheduleModalOpen, setWeeklyScheduleModalOpen] = useState(false);

  const {
    currentJob,
    setCurrentJob,
    isMobile,
    setCurrentFormDirty,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    changePanel,
    venues,
  } = useAppContextController();

  const resolver = useYupValidationResolver(shiftsInfoSchema);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
    getValues,
    formState: { isDirty, dirtyFields, errors },
  } = useForm({
    defaultValues: emptyValues,
    resolver,
  });

  useEffect(() => {
    if (currentJob) {
      const data = {
        location: currentJob?.location || "",
        startDate: currentJob.startDate ? new Date(currentJob.startDate) : "",
        endDate: currentJob.endDate ? new Date(currentJob.endDate) : "",
        additionalConfig: currentJob.additionalConfig || emptyValues.additionalConfig,
        shifts: currentJob.shifts || [],
      };
      reset(data);
      setExistingData(data);
    }
  }, [currentJob]);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateJobMutation } = useMutation(isVenue ? updateJob : updateCompanyJob);
  const idName = isVenue ? "_id" : "jobId";

  const handleUpdateJob = (updatedValues) => {
    updateJobMutation(
      { [idName]: currentJob?._id, data: updatedValues },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("company_jobs_table");
          setCurrentJob({ ...currentJob, ...updatedValues });
          enqueueSnackbar("Job has been updated.", { variant: "success" });
          setShiftsDirty(false);
          setCurrentFormDirty(false);
          reset(updatedValues);
          if (selectedShiftIndex === null) setSelectedShiftIndex(updatedValues?.shifts.length - 1);
        },
        onError: (err) => console.log(err),
      }
    );
  };

  console.log(isDirty, dirtyFields, errors);

  const defaultScheduleISOString = (defaultSchedule) => {
    const schedule = { ...defaultSchedule };
    Object.keys(schedule).forEach((day) => {
      const { start, end } = schedule[day];
      if (start) {
        schedule[day].start = new Date(start).toISOString();
      } else schedule[day].start = "";
      if (end) {
        schedule[day].end = new Date(end).toISOString();
      } else schedule[day].end = "";
    });
    return schedule;
  };

  const handleShiftSave = (data) => {
    console.log("data ", data);

    const modifiedData = { ...data };
    modifiedData.startDate = new Date(data.startDate).toISOString();
    modifiedData.endDate = new Date(data.endDate).toISOString();
    if (!modifiedData.additionalConfig.allowNegativeLeave)
      modifiedData.additionalConfig.negativeHoursLimit = 0;
    else
      modifiedData.additionalConfig.negativeHoursLimit = parseInt(
        data.additionalConfig.negativeHoursLimit,
        10
      );
    modifiedData.additionalConfig.earlyClockInMinutes = parseInt(
      data.additionalConfig.earlyClockInMinutes,
      10
    );
    modifiedData.shifts = modifiedData.shifts.map((shift) => {
      const newShift = { ...shift };
      newShift.defaultSchedule = defaultScheduleISOString(shift.defaultSchedule);
      newShift.shiftStartDate = new Date(shift.shiftStartDate).toISOString();
      newShift.shiftEndDate = new Date(shift.shiftEndDate).toISOString();
      return newShift;
    });

    console.log("modifiedData ", modifiedData);
    handleUpdateJob(modifiedData);
  };

  // shows form details and adjusts current shift on add new shift or shift row select
  const addNewShift = () => {
    setSelectedShiftIndex(null);
    setShowDetails(true);
  };

  const handleRemoveShift = (idx) => {
    const updatedShifts = existingData.shifts?.filter((_, i) => i !== idx);
    // setShifts(updatedShifts);
    // setShiftsDirty(true);
    if (idx === selectedShiftIndex) {
      setSelectedShiftIndex(null);
      setShowDetails(false);
    }
    handleUpdateJob({ shifts: updatedShifts });
  };

  const handleCopyShift = (idx) => {
    const shiftToCopy = existingData.shifts[idx];
    const newShift = { ...shiftToCopy, shiftName: `${shiftToCopy.shiftName} (Copy)` };
    setExistingData((prev) => {
      const updatedShifts = [...prev.shifts, newShift];
      setValue("shifts", updatedShifts, { shouldDirty: true });
      return { ...prev, shifts: updatedShifts };
    });
    setSelectedShiftIndex(existingData.shifts.length);
    setShowDetails(true);
    setShiftsDirty(true);
  };

  const assignSchedule = (idx) => {
    setSelectedShiftIndex(idx);
    setShowDetails(true);
    setWeeklyScheduleModalOpen(true);
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleShiftSave)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  const saveChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      await handleSubmit(handleShiftSave)();
      setShiftsDirty(false);
      setCurrentFormDirty(false);
    }
  };

  const handleCancelChanges = () => {
    reset(existingData);
    setShiftsDirty(false);
  };

  // to enable discard changes modal when switching panels
  useEffect(() => {
    setCurrentFormDirty(isDirty || isShiftsDirty);
    return () => {
      setShiftsDirty(false);
      setCurrentFormDirty(false);
    };
  }, [isDirty, isShiftsDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectShift = (index, row) => {
    setSelectedShiftIndex(index);
    setShowDetails(true);
  };

  const renderAdditionalShifts = (row, idx) => (
    <ShiftJobsItem
      row={row}
      idx={idx}
      selectShift={selectShift}
      onRemoveShift={handleRemoveShift}
      handleCopyShift={handleCopyShift}
      assignSchedule={assignSchedule}
    />
  );

  const defaultStaffingPool = useMemo(() => {
    if (!existingData.shifts) return [];
    return existingData?.shifts[selectedShiftIndex]?.shiftRoster ?? [];
  }, [selectedShiftIndex]);

  const locationList = useMemo(() => {
    if (!venues) return [];
    // Find the venue that matches the currentJob.venueName
    return (
      Object.values(venues)?.find((venue) => isEqual(venue.name, currentJob?.venueName))
        ?.locations || []
    );
  }, [venues, currentJob]);

  return (
    <form onSubmit={handleSubmit(handleShiftSave)}>
      <CustomCard
        icon={<PendingActionsIcon color="white" />}
        cardTitle="Job Shift Settings"
        // cardActions={
        //   isDirty || isShiftsDirty ? (
        //     <PanelActions onCancel={handleCancelChanges} />
        //   ) : (
        //     <ShiftJobsPanelActions
        //       onExpandCalender={() => console.log("Top be implemented")}
        //       onCopy={() => console.log("TOn be ")}
        //     />
        //   )
        // }
        cardActions={(isDirty || isShiftsDirty) && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "success")}
      >
        <Stack spacing={4} sx={styles.container}>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="location"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={!isEmpty(locationList) ? locationList : []}
                      getOptionLabel={(option) => option.locationName}
                      name="location"
                      value={getValues("location") ? field.value : null}
                      onChange={(e, data) => {
                        field.onChange(data);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          type="text"
                          label="Location"
                          showError
                          errorMessage={error?.message}
                          {...params}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            color:
                              option.primaryLocation === "Yes"
                                ? theme.palette.info.main
                                : "inherit",
                            fontWeight: option.primaryLocation === "Yes" ? "bold" : "normal",
                          }}
                        >
                          {option.locationName}
                        </li>
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Start Date"
                        value={!field.value ? null : field.value}
                        onChange={(date) => {
                          field.onChange(new Date(date));
                        }}
                        renderInput={(params) => (
                          <CustomTextField {...params} showError errorMessage={error?.message} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="End Date"
                        value={!field.value ? null : field.value}
                        onChange={(date) => {
                          field.onChange(new Date(date));
                        }}
                        renderInput={(params) => (
                          <CustomTextField {...params} showError errorMessage={error?.message} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <MDTypography variant="h5" color="info" mt={4} mb={2}>
                Additional Config
              </MDTypography>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Controller
                    name="additionalConfig.earlyClockInMinutes"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <NumericFormat
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                          },
                        }}
                        allowNegative={false}
                        suffix=" Minutes"
                        label="Early ClockIn Minutes"
                        decimalScale={0}
                        {...field}
                        customInput={FormField}
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </Grid>
                {switchData(watch).map((item, index) => (
                  <ShiftFormSwitchController
                    key={`${item.name}_switch_${index.toString()}`}
                    control={control}
                    label={item.label}
                    name={item.name}
                    disabled={item.disabled}
                  />
                ))}
                {watch("additionalConfig.allowNegativeLeave") && (
                  <Grid item sm={6}>
                    <Controller
                      name="additionalConfig.negativeHoursLimit"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <NumericFormat
                          InputProps={{
                            inputProps: {
                              inputMode: "numeric",
                            },
                          }}
                          allowNegative={false}
                          suffix=" Hours"
                          label="Negative Hours Limit"
                          decimalScale={0}
                          {...field}
                          customInput={FormField}
                          disabled={!watch("additionalConfig.allowNegativeLeave")}
                          showError
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <DataListContainer
              title="Shifts"
              onAdd={addNewShift}
              data={existingData.shifts}
              renderRow={renderAdditionalShifts}
              payRate="Shifts"
              tableHeight={265}
              height={267}
              selected={selectedShiftIndex}
            />
          </MDBox>
          {showDetails && (
            <MDBox>
              {existingData?.shifts?.length > 0 &&
                existingData?.shifts?.map((shift, index) => {
                  if (index === selectedShiftIndex) {
                    return (
                      <ShiftForm
                        key={`${JSON.stringify(shift)}_shift_form`}
                        control={control}
                        setValue={setValue}
                        index={
                          selectedShiftIndex ??
                          (!existingData?.shifts || existingData?.shifts?.length === 0
                            ? 0
                            : existingData?.shifts?.length)
                        }
                      />
                    );
                  }
                  return null;
                })}
              {selectedShiftIndex === null && (
                <ShiftForm
                  control={control}
                  setValue={setValue}
                  index={
                    selectedShiftIndex ??
                    (!existingData?.shifts || existingData?.shifts?.length === 0
                      ? 0
                      : existingData?.shifts?.length)
                  }
                />
              )}
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="secondary"
                sx={{ fontSize: "0.74rem" }}
              >
                Staffing Pool
              </MDTypography>
              <StaffingPool
                defaultValues={defaultStaffingPool}
                defaultSchedule={getValues(`shifts[${selectedShiftIndex}].defaultSchedule`)}
                setValue={setValue}
                selectedIndex={
                  selectedShiftIndex ??
                  (!existingData?.shifts || existingData?.shifts?.length === 0
                    ? 0
                    : existingData?.shifts?.length)
                }
              />
            </MDBox>
          )}
          {isWeeklyScheduleModalOpen && (
            <WeeklyScheduleConfigurationModal
              selectedShiftIndex={selectedShiftIndex}
              getValues={getValues}
              setValue={setValue}
              isWeeklyScheduleModalOpen={isWeeklyScheduleModalOpen}
              setWeeklyScheduleModalOpen={setWeeklyScheduleModalOpen}
              saveChanges={saveChanges}
            />
          )}
        </Stack>
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default ShiftJobs;
