import axios from "axios";

import { baseAxios } from "config";

const deleteImage = async ({ venueId, data }) => {
  try {
    const { url, path, list } = data;
    const res = await baseAxios.put(`/venues/${venueId}`, {
      headers: { Authorization: "***" },
      data: { url, path, list },
    });

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default deleteImage;
