import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Grid } from "@mui/material";
import { ThumbDown as ThumbDownIcon, ThumbUp as ThumbUpIcon } from "@mui/icons-material";

import moment from "moment";
import parse from "html-react-parser";

// const useStyle = makeStyles({
//   editor: {
//     height: 150,
//   },
//   box: {
//     overflow: "visible",
//     height: 550,
//   },
// });

const NotesRow = ({ row, displayNotes }) => {
  const [viewDetail, setViewDetail] = useState(false);
  const toggleRowExpand = (e) => {
    e.stopPropagation();
    setViewDetail((prev) => !prev);
  };

  const isInterview = row?.agentName;
  const thumbUpColors = {
    None: "action",
    Hired: "success",
    Pending: "warning",
    Declined: "error",
  };
  const renderIcon = (info) =>
    info.rating !== "Declined" ? (
      <ThumbUpIcon fontSize="medium" color={thumbUpColors[info.rating]} />
    ) : (
      <ThumbDownIcon fontSize="medium" color={thumbUpColors[info.rating]} />
    );

  useEffect(() => {
    setViewDetail(displayNotes);
  }, [displayNotes]);

  return (
    <>
      <Grid container alignItems="center" onClick={toggleRowExpand}>
        <Grid item xs={11} sm={11}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={isInterview ? 2 : 3.5} sm={isInterview ? 2 : 3.5}>
              <MDTypography variant="h6">
                {row.firstName} {row.lastName}
              </MDTypography>
            </Grid>
            <Grid item xs={isInterview ? 3 : 5} sm={isInterview ? 3 : 5}>
              <MDTypography variant="body2">
                {moment(row.date).format("ddd MM/DD/YYYY hh:mm A")}
              </MDTypography>
            </Grid>
            <Grid item xs={isInterview ? 2.5 : 3.5} sm={isInterview ? 2.5 : 3.5}>
              <MDTypography variant="h6">{row.type}</MDTypography>
            </Grid>
            {isInterview && (
              <Grid item xs={2.5} sm={2.5}>
                <MDTypography variant="h6">{row.agentName}</MDTypography>
              </Grid>
            )}
            {isInterview && (
              <Grid item xs={2} sm={2}>
                {row.rating && renderIcon(row)}
              </Grid>
            )}
            {viewDetail ? (
              <Grid item xs={12} sm={12} sx={{ "text-wrap": "balance", wordBreak: "break-word" }}>
                <hr />
                {row.text ? parse(row.text) : null}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} pr={0} mr={0} sx={{ textAlign: "end" }}>
          <MDBox>
            <Button onClick={toggleRowExpand}>{viewDetail ? "Hide" : "Show"}</Button>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default NotesRow;
