import { Tooltip } from "@mui/material";

import {
  ConnectWithoutContact,
  FolderShared,
  PersonAdd,
  QuestionAnswer,
  ThumbDown,
} from "@mui/icons-material";

const getStatusIcon = (size) => ({
  ATC: <ConnectWithoutContact color="warning" fontSize={size} />,
  Declined: <ThumbDown color="error" fontSize={size} />,
  New: <PersonAdd fontSize={size} sx={{ color: "#9C27B0" }} />,
  "Pre-Hire": <FolderShared color="success" fontSize={size} />,
  Screened: <QuestionAnswer color="info" fontSize={size} />,
});

const sizeAlias = {
  sm: "small",
  md: "medium",
  lg: "large",
};

const ApplicantStatus = ({ applicant, size = "md" }) => {
  const sizeString = sizeAlias[size] ?? size;
  const allIcons = getStatusIcon(sizeString);
  const iconImage = allIcons[applicant?.applicantStatus ?? "New"] ?? allIcons.New;
  const label = applicant?.applicantStatus ?? "New";

  return <Tooltip title={label}>{iconImage}</Tooltip>;
};

export default ApplicantStatus;
