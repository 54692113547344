import * as yup from "yup";

const nameRegex = /^[a-zA-Z]+(?:[-,'.][a-zA-Z]+)*$/;
const nameTest = {
  name: "validate-phone",
  test: (value, ctx) => {
    if (value) {
      const valueLength = value.replace(/\s/g, "").length;
      if (valueLength === 0) return true;
      if (nameRegex.test(value.replace(/\s/g, ""))) return true;
      return ctx.createError({ message: "Contains invalid characters" });
    }
    return true;
  },
};

const contactsSchema = (primaryInfo, showDetails, isContactsDirty) => {
  const isOtherFieldsChanged = (!showDetails && primaryInfo) || isContactsDirty;
  return yup.object().shape({
    firstName: isOtherFieldsChanged
      ? yup.string().nullable().test(nameTest)
      : yup
        .string()
        .typeError("Invalid First Name Input")
        .required("First Name is Required")
        .test(nameTest),
    lastName: isOtherFieldsChanged
      ? yup.string().nullable().test(nameTest)
      : yup
        .string()
        .typeError("Invalid Last Name Input")
        .required("Last Name is Required")
        .test(nameTest),
    email: yup
      .string()
      .email("Invalid Email")
      .matches(/^(?!.*@[^,]*,)/)
      .typeError("Invalid Email Input"),
    phone: yup
      .string()
      .default("")
      .test({
        name: "validate-phone",
        test: (value, ctx) => {
          if (value) {
            const trimValueLength = value.replace(/\D/g, "").length;
            const phoneRegExp =
              /^(?=.*[0-9])((\+\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4})(?:\s?(?:#|x\.?|ext\.?|extension)[\s.-]?\d{4})?$/i;

            if (trimValueLength >= 10 || trimValueLength === 0 || !value) {
              if (trimValueLength === 0) return true;
              if (phoneRegExp.test(value.replace(/\s/g, ""))) return true;
              return ctx.createError({ message: "Invalid phone number" });
            }
            return ctx.createError({ message: "Phone number must be 10 digits" });
          }
          return true;
        },
      })
      .typeError("Invalid Phone Input"),
    phoneExt: yup
      .string()
      .default("")
      .test({
        name: "validate-phone",
        test: (value, ctx) => {
          if (value) {
            const trimValueLength = value.replace(/\D/g, "").length;
            const phoneExtRegExp = /^[0-9]{1,6}$/;
            if (trimValueLength === 0) return true;
            if (phoneExtRegExp.test(value.replace(/\s/g, ""))) return true;
            return ctx.createError({ message: "Phone extension can be up to 6 digits" });
          }
          return true;
        },
      })
      .typeError("Invalid Phone Input"),
    address: yup.string().typeError("Invalid Address Input").nullable(),
    title: yup.string().typeError("Invalid Title Input"),
    city: yup.string().typeError("Invalid City Input").nullable(),
    state: yup.string().typeError("Invalid State Input").nullable(),
    zip: yup.string().typeError("Invalid Zip Input").nullable(),
  });
};

export default contactsSchema;
