import { useEffect, useState } from "react";
import moment from "moment";
import { baseAxios } from "config";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import PropTypes from "prop-types";
import { Grid, Tooltip, InputLabel, MenuItem, FormControl } from "@mui/material";
import Select from "@mui/material/Select";
// import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";
import formatReportTime from "utils/formatReportTime";

import { useAppContextController } from "context/AppContext";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";

const DEFAULT_POSITION = "Event Staff";
const NOT_ROSTER_POSITION = "Not Roster";

const RosterPosition = ({
  id,
  row,
  currentEvent,
  eventApplicants,
  toggleReadRoster,
  isPartner,
}) => {
  const { currentLoggedUser, setCurrentEvent } = useAppContextController();
  const queryClient = useQueryClient();
  const [rosterPosition, setRosterPosition] = useState(DEFAULT_POSITION);
  const [reportTime, setReportTime] = useState(
    formatReportTime(currentEvent?.eventDate, currentEvent?.timeZone)
  );
  const [endTime, setEndTime] = useState(
    formatReportTime(currentEvent?.eventEndTime, currentEvent?.timeZone)
  );
  const [mode, setMode] = useState("display");
  const [open, setOpen] = useState(false);
  const [positions, setPositions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const rec = eventApplicants ? eventApplicants.find((item) => item.id === id) : null;
    if (!rec) setRosterPosition(NOT_ROSTER_POSITION);
    else if (rec?.primaryPosition) {
      setRosterPosition(rec.primaryPosition);
      if (rec?.reportTime) setReportTime(formatReportTime(rec.reportTime, currentEvent?.timeZone));
      if (rec?.endTime) setEndTime(formatReportTime(rec.endTime, currentEvent?.timeZone));
    } else setRosterPosition(DEFAULT_POSITION);
  }, [eventApplicants, currentEvent?.positions]);

  useEffect(() => {
    if (currentEvent?.positions) {
      setPositions(
        currentEvent.positions.map((item) => ({
          position: item?.position,
          billRate: item?.billRate,
          payRate: item?.payRate,
          reportTime: item?.reportTime,
          endTime: item?.endTime,
        }))
      );
    }
  }, []);

  const rosterMutation = useMutation(
    async (data) => {
      await baseAxios.put(`/events/url/${currentEvent?.eventUrl}/roster`, { ...data });
    },
    {
      // eslint-disable-next-line
      onSuccess: async (_, { data }) => {
        toggleReadRoster();
        setRosterPosition(data?.primaryPosition);
        enqueueSnackbar("Position saved successfully", { variant: "success" });
      },
      onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    }
  );

  const handleSelect = async (pos, repTime = null, eTime = null) => {
    setMode("display");
    setOpen(false);

    const updateObj = {
      id,
      primaryPosition: pos,
      agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
      isPartner,
    };
    if (repTime) updateObj.reportTime = repTime;
    if (eTime) updateObj.endTime = eTime;
    await rosterMutation.mutate(updateObj);
  };

  const handleModeChange = (e) => {
    e.stopPropagation();
    if (mode === "display") {
      setMode("edit");
      setOpen(true);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setMode("display");
    setOpen(false);
  };

  let tooltipText = "No additional positions available";
  if (rosterPosition === NOT_ROSTER_POSITION) tooltipText = "";
  else if (currentEvent?.positions)
    tooltipText = `Report Time: ${reportTime}. Click to select a different position`;

  return (
    <span>
      {mode !== "edit" || !currentEvent?.positions ? (
        <Tooltip title={tooltipText}>
          <MDTypography
            variant="body"
            onClick={rosterPosition === NOT_ROSTER_POSITION ? () => {} : handleModeChange}
          >
            {rosterPosition}
          </MDTypography>
        </Tooltip>
      ) : (
        <FormControl variant="standard" sx={{ m: 1 }}>
          <InputLabel id="demo-multiple-name-label">Select Position</InputLabel>
          <Select
            open={open}
            id="demo-multiple-name-label"
            value={rosterPosition}
            // onClick={handleSelect}
            label="Position"
            onClose={handleClose}
          >
            <MenuItem value={DEFAULT_POSITION} onClick={() => handleSelect(DEFAULT_POSITION)}>
              <Grid container flexDirection="row" spacing={0} sx={{ minWidth: 250 }}>
                <Grid item xs={8}>
                  <em>{DEFAULT_POSITION}</em>
                </Grid>
                {/* <Grid item xs="2.5">Pay-${pos?.payRate}</Grid>
                                    <Grid item xs="2.5">Bill-${pos?.billRate}</Grid> */}
                <Grid item xs={3}>
                  Time{" "}
                  {currentEvent?.reportTimeTBD
                    ? currentEvent.reportTimeTBD
                    : moment(currentEvent?.eventDate).format("hh:mm a")}
                </Grid>
              </Grid>
            </MenuItem>
            {currentEvent?.positions.map((pos) => {
              return (
                <MenuItem
                  value={pos.positionName}
                  onClick={() => handleSelect(pos.positionName, pos?.reportTime, pos?.endTime)}
                >
                  <Grid container flexDirection="row" spacing={0} sx={{ minWidth: 250 }}>
                    <Grid item xs={8}>
                      {pos.positionName}
                    </Grid>
                    {/* <Grid item xs="2.5">Pay-${pos?.payRate}</Grid>
                                    <Grid item xs="2.5">Bill-${pos?.billRate}</Grid> */}
                    <Grid item xs={3}>
                      Time {moment(pos?.reportTime).format("hh:mm a")}
                    </Grid>
                  </Grid>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </span>
  );
};

export default RosterPosition;

RosterPosition.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
RosterPosition.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
