import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { getDirtyFields } from "utils/helpers/formHelpers";

import { Try } from "@mui/icons-material";
import { Autocomplete, Grid, Stack, Switch, Tooltip } from "@mui/material";
import updateCompany from "api/companies/updateCompany";
import generateCustomerAIQuestions from "api/companies/generateCustomerAIQuestions";
import AIQuestionsPanel from "components/AIQuestionsPanel";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PanelActions from "components/PanelActions";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";
import { cardStyles as styles } from "layouts/pages/customers/styles";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import generateVenueAIQuestions from "layouts/pages/venues/actions/generateVenueAIQuestions";
import { useNavigate } from "react-router-dom";
import useCompanyStore from "stores/useCompanyStore";
import aiSettingsSchema from "./AISettingsSchema";

const dropdownOptions = [
  {
    label: "Use Primary Company Settings",
    value: "",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];

const defaultValues = {
  aiSettings: {
    aiInterviewsEnabled: "No",
    aiInterviewsEnabledByDefault: "",
    displayCustomerQuestions: "No",
    autoGenerateJobQuestions: "No",
    autoScreening: "No",
    questions: [],
  },
};

const CustomerAISettings = ({ isVenue = false }) => {
  const {
    currentVenue,
    setCurrentVenue,
    company,
    setCompany,
    isMobile,
    setCurrentFormDirty,
    changePanel,
    currentLoggedUser,
  } = useAppContextController();

  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const navigate = useNavigate();
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);

  const validationSchema = aiSettingsSchema();
  const resolver = useYupValidationResolver(validationSchema);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({ resolver, defaultValues });
  const {
    fields: questions,
    append: addQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "aiSettings.questions",
  });

  const disabledTooltip = useMemo(() => {
    if (company?.aiSettings?.aiInterviewsEnabled !== "Yes")
      return "AI Interviews are disabled for the primary company";
    return "";
  }, [company?.aiSettings?.aiInterviewsEnabled]);

  const isDirty = dirtyFields && !!Object.keys(dirtyFields).length;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const { mutateAsync: updateVenueMutation } = useMutation(updateVenue);
  const idName = isVenue ? "_id" : "jobId";
  const [questionIndex, setQuestionIndex] = useState(null);
  const { mutateAsync: generateQuestionsMutation, isLoading: isLoadingGenerateQuestion } =
    useMutation(isVenue ? generateVenueAIQuestions : generateCustomerAIQuestions);

  const currentResource = isVenue ? currentVenue : currentCompany;

  const aiInterviewsEnabled =
    watch("aiSettings.aiInterviewsEnabled") === "Yes" &&
    company?.aiSettings?.aiInterviewsEnabled === "Yes";

  const handlePressGenerate = () => {
    // Get locked questions
    const currentQuestions = getValues().aiSettings?.questions ?? [];
    if (currentQuestions.find((q) => q.locked)) {
      generateQuestionsMutation(
        {
          venueSlug: currentVenue?.slug,
          companySlug: currentCompany?.slug,
          questions: currentQuestions,
        },
        {
          onSuccess: async (res) => {
            setValue("aiSettings.questions", res.data.aiInterview.questions, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.aiModel", res.data.aiInterview.aiModel, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.lastGenerationDate", res.data.aiInterview.lastGenerationDate, {
              shouldDirty: true,
              shouldTouch: true,
            });

            enqueueSnackbar("New questions have been generated.", { variant: "success" });
          },
          onError: (err) =>
            enqueueSnackbar("An error has ocurred generating questions.", { variant: "error" }),
        }
      );
    } else {
      generateQuestionsMutation(
        {
          venueSlug: currentVenue?.slug,
          companySlug: currentCompany?.slug,
        },
        {
          onSuccess: async (res) => {
            setValue("aiSettings.questions", res.data.aiInterview.questions, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.aiModel", res.data.aiInterview.aiModel, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.lastGenerationDate", res.data.aiInterview.lastGenerationDate, {
              shouldDirty: true,
              shouldTouch: true,
            });

            enqueueSnackbar("New questions have been generated.", { variant: "success" });
          },
          onError: (err) =>
            enqueueSnackbar("An error has ocurred generating questions.", { variant: "error" }),
        }
      );
    }
  };

  const handleUpdateCompany = (updatedValues) => {
    updateCompanyMutation(
      { companyId: currentCompany?._id, body: updatedValues },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("companies");
          setCurrentCompany({ ...currentCompany, ...updatedValues });
          if (currentCompany?.primaryCompany) {
            setCompany({ ...company, ...updatedValues });
          }
          enqueueSnackbar("Customer has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };

  const handleUpdateVenue = (updatedValues) => {
    updateVenueMutation(
      {
        venueId: currentVenue._id,
        data: updatedValues,
      },
      {
        onError: () =>
          enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 }),
        onSuccess: async (_, { data }) => {
          await queryClient.invalidateQueries("myvenues");
          setCurrentVenue({ ...currentVenue, ...data });
          enqueueSnackbar("Venue has been updated!", { variant: "success" });
        },
      }
    );
  };

  const onSubmit = (data) => {
    const temp = getDirtyFields(data, dirtyFields);
    if (temp?.aiSettings?.questions?.length) {
      temp.aiSettings.questions = temp.aiSettings.questions.map((q) => {
        const newQ = { ...q };
        delete newQ.locked;
        return newQ;
      });
    }
    if (isVenue) handleUpdateVenue(temp);
    else handleUpdateCompany(temp);
  };

  const handleCancelChanges = () => {
    reset({ ...(currentResource?.aiSettings && { aiSettings: currentResource?.aiSettings }) });
    setQuestionIndex(null);
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  useEffect(() => {
    reset({
      ...((currentResource?.aiSettings ?? {}) && { aiSettings: currentResource?.aiSettings }),
    });
    setQuestionIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResource]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Try color="white" />}
        iconContainerSx={{ backgroundColor: "#8935f4" }}
        cardTitle={`AI Settings: ${currentResource?.name}`}
        cardSx={{ overflow: "visible !important", borderRadius: 0, height: 850 }}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        actionsSx={isMobile ? {} : { mt: -4 }}
      >
        <MDBox sx={{ ...styles.container, height: 849 }}>
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12}>
              <Tooltip title={disabledTooltip} arrow={false}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                      AI Configuration
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Enable AI Interviews</MDTypography>
                      <Controller
                        name="aiSettings.aiInterviewsEnabled"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            color="info"
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={company?.aiSettings?.aiInterviewsEnabled !== "Yes"}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">
                        Display {isVenue ? "Venue Questions" : "Customer Questions"}
                      </MDTypography>
                      <Controller
                        name="aiSettings.displayCustomerQuestions"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            color="info"
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={!aiInterviewsEnabled}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="aiSettings.aiInterviewsEnabledByDefault"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={dropdownOptions}
                          name="aiSettings.aiInterviewsEnabledByDefault"
                          autoSelect
                          getOptionLabel={(option) => option.label || option}
                          value={
                            field.value != null
                              ? dropdownOptions?.find((option) => option.value === field.value)
                              : dropdownOptions[0]
                          }
                          isOptionEqualToValue={(option, value) => option.value === value}
                          onChange={(e, v) => {
                            setValue("aiSettings.aiInterviewsEnabledByDefault", v.value, {
                              shouldDirty: true,
                            });
                          }}
                          sx={{ width: "100%" }}
                          disableClearable
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="AI Interviews Enabled by Default"
                              showError
                              errorMessage={error?.message}
                            />
                          )}
                          disabled={!aiInterviewsEnabled}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Auto-generate Job Questions</MDTypography>
                      <Controller
                        name="aiSettings.autoGenerateJobQuestions"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={!aiInterviewsEnabled}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      my={-1.5}
                      pt={0.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Auto-Screen Applicants</MDTypography>
                      <Controller
                        name="aiSettings.autoScreening"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={!aiInterviewsEnabled}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Tooltip>
              {aiInterviewsEnabled && (
                <AIQuestionsPanel
                  questions={watch("aiSettings.questions") ?? []}
                  addQuestion={addQuestion}
                  removeQuestion={removeQuestion}
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  control={control}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  title={`${isVenue ? "Venue" : "Customer"} Questions`}
                  onPressGenerate={handlePressGenerate}
                  isLoadingGenerateQuestion={isLoadingGenerateQuestion}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </CustomCard>
      <ReminderToSaveChangesModal
        open={reminderToSaveChangesModalOpen}
        setOpen={setReminderToSaveChangesModalOpen}
      />
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default CustomerAISettings;
