import axios from "axios";

import { baseAxios } from "config";

export const generateApplicantTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicants-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicants-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueStaffingPoolTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venue-staffing-pool-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueStaffingPoolChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venue-staffing-pool-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobApplicantTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/job-applicants-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobApplicantChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/job-applicants-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobStaffingPoolTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/job-staffing-pool-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobStaffingPoolChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/job-staffing-pool-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEventRosterTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/event-roster-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEventRosterChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/event-roster-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCustomerApplicantTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-applicants-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCustomerApplicantChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-applicants-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCustomerStaffingPoolTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-staffing-pool-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCustomerStaffingPoolChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-staffing-pool-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateInterviewApplicantTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/interview-applicants-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateInterviewApplicantChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/interview-applicants-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantInterviewTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-interviews-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantInterviewChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-interviews-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
