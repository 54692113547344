import * as yup from "yup";

const organizationsSchema = yup.object().shape({
  slug: yup.string().typeError("Invalid Slug Input").required(),
  userType: yup.string().typeError("Invalid User Type Input").required(),
  status: yup.string().typeError("Invalid Status Input").required(),
  primary: yup.string().typeError("Invalid Primary Input").nullable(),
});

export default organizationsSchema;
