export const splitName = (name) => {
  // Split the name by spaces
  if (!name || typeof name !== "string") return { first: "", last: "" };
  const parts = name?.split(" ");

  let first;
  let last;

  if (parts.length > 2) {
    // If there are more than 3 words, use the last word as the last name and the rest as the first name
    last = parts.pop();
    first = parts.join(" ");
  } else {
    // If there are 3 or fewer words, use the first word as the first name and the last word as the last name
    first = parts.slice(0, -1).join(" ");
    last = parts[parts.length - 1];
  }

  return { first, last };
};
