import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import fetchTaskById from "layouts/pages/tasks/actions/fetchTaskById";
import TasksTable from "layouts/pages/tasks/components/TasksTable";
import TasksFilterCards from "layouts/pages/tasks/components/TasksFilterCards";
import useSessionAuth from "hooks/useSessionAuth";
import { Alert, Snackbar } from "@mui/material";
// import TasksInformation from "layouts/pages/tasks/components/TasksInfo";

function Tasks({ mode = "edit" }) {
  const navigate = useNavigate();

  const { userType, currentTask, setCurrentTask, venues } = useAppContextController();
  const [applicant, setApplicant] = useState({});
  const { id, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [page, setPage] = useState(1);
  const { logoutAuth0User } = useSessionAuth();

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const [filters, setFilters] = useState({});

  useEffect(() => {
    const getApplicant = async () => {
      const appl = await fetchSingleApplicant({ applicantId: currentTask?.applicantId });
      if (appl) setApplicant(appl.data);
    };
    if (currentTask?.applicantId) {
      try {
        getApplicant();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [userType, currentTask?.applicantId]);

  const getTaskAndSetCurrentTask = useCallback(async () => {
    // setFilters({ _id: selectedId });
    try {
      const tasks = await fetchTaskById(selectedId);
      if (tasks && tasks?.data?.length) setCurrentTask(tasks?.data[0]);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [selectedId]);

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    if (id && !filters._id && action) {
      setFilters({ _id: id });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }

    if (selectedId && (!currentTask || currentTask._id !== selectedId)) {
      getTaskAndSetCurrentTask();
    }
  }, [selectedId, action, actionSelected, currentTask, getTaskAndSetCurrentTask]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TasksFilterCards setFilters={setFilters} setPage={setPage} />
          <TasksTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Tasks"
            page={page}
            setPage={setPage}
            setToastAlert={setToastAlert}
          />
        </Grid>
        {["taskinfo", "taskall", ""].includes(actionSelected) && (id || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <>coming soon</>
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Tasks.defaultProps = {
  mode: "edit",
};

// Typechecking props
Tasks.propTypes = {
  mode: PropTypes.string,
};
export default Tasks;
