import { useState, useEffect } from "react";
import { Autocomplete, FormHelperText } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";

const SelectHiringManager = ({
  customer,
  currentEvent,
  value,
  onChange,
  onBlur,
  errorMessage = "",
  isShrink = true,
}) => {
  const [options, setOptions] = useState([]);

  const getNameOption = (obj) => {
    return `${obj?.firstName} ${obj?.lastName}`;
  };

  useEffect(() => {
    const addAdditionalHM = () => {
      const additionalHM = customer?.additionalContacts?.map((contact) => getNameOption(contact));
      if (additionalHM) {
        setOptions([getNameOption(customer?.contact), ...additionalHM]);
      } else {
        setOptions([getNameOption(customer?.contact)]);
      }
    };

    if (customer?.contact) addAdditionalHM();
    if (!customer) setOptions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.additionalContacts, customer?.contact]);

  return (
    <Autocomplete
      key={`autocomplete_hiringManager_${currentEvent?.id}`}
      options={options || ["Main Customer Contact"]}
      autoSelect
      name="hiringManager"
      defaultValue={currentEvent?.hiringManager}
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
      renderInput={(params) => (
        <>
          <FormField
            key={`hiringManager_${currentEvent?.id}`}
            {...params}
            type="text"
            label="Hiring Manager"
            InputLabelProps={{ shrink: isShrink }}
          />
          <FormHelperText error>{errorMessage}</FormHelperText>
        </>
      )}
    />
  );
};

export default SelectHiringManager;
