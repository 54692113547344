import { useMemo, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import ActivitiesDataList from "components/Activities/ActivitiesDataList";
import { useAppContextController } from "context/AppContext";
import fetchActivities from "layouts/pages/activities/actions/fetchActivities";

import { Box } from "@mui/material";

const EventActivites = () => {
  const { currentEvent } = useAppContextController();
  const [page, setPage] = useState(1);

  const options = useMemo(() => {
    return { fetchAll: false, orderBy: "activityDate", order: "desc", limit: 25 };
  }, []);

  const fetchOptions = useMemo(() => {
    return {
      ...options,
      filters: {
        eventId: currentEvent?._id,
      },
      page,
    };
  }, [currentEvent?._id, options, page]);

  const {
    data: activities,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ["eventactivites", fetchOptions],
    ({ queryKey, pageParam }) => fetchActivities({ ...fetchOptions, page: pageParam ?? 1 }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!currentEvent?._id,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
          return undefined;
        }

        const nextPage = lastPage?.pagination?.next?.page;
        return nextPage ?? false;
      },
    }
  );

  const fullData = useMemo(
    () =>
      activities?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [activities?.pages]
  );

  return (
    <Box mt={2.5}>
      <ActivitiesDataList
        isLoading={isLoading}
        activities={activities?.pages?.[activities?.pages?.length - 1]}
        height={850}
        tableHeight={800}
        computedHeight
        name={currentEvent?.name}
        flexWrap="wrap"
        cardVariant="outlined"
        page={page}
        setPage={setPage}
        activitiesParent={currentEvent}
        fetchNextPage={fetchNextPage}
        fullData={fullData}
        isFetching={isFetching}
      />
    </Box>
  );
};

export default EventActivites;
