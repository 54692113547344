import { Button } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";


const NoTagSelectedModal = ({ open, setOpen }) => {

    const header = (
        <>
            <MDTypography variant="h4">
                No Tag Selected
            </MDTypography>
        </>
    );




    const body = (
        <MDTypography variant="body2">
            You must enter a tag search for Bulk Messaging
        </MDTypography>
    );

    const okButton = (
        <Button variant="contained" style={{ color: "white" }} onClick={() => setOpen(false)} >
            Ok
        </Button>
    )


    return (
        <>
            <GenericModal
                open={open}
                setOpen={setOpen}
                body={body}
                header={header}
                buttons={okButton}
                width="30%"
            />
        </>
    );
};

export default NoTagSelectedModal;
