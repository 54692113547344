import { Close } from "@mui/icons-material";
import { Card, CardContent, IconButton, Modal } from "@mui/material";
import colors from "assets/theme/base/colors";

const CustomModal = ({ isOpen, onClose, cardSx, children }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
        },
      }}
    >
      <Card
        sx={{
          position: "absolute",
          width: "90%",
          top: "50%",
          right: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "550px",
          maxHeight: "640px",
          ...cardSx,
        }}
      >
        <CardContent sx={{ pt: "50px" }}>
          {children}
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              "& :hover": {
                color: colors.gradients.error.main,
              },
              m: 1,
              p: 0,
            }}
          >
            <Close />
          </IconButton>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CustomModal;
