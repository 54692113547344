import * as yup from "yup";

const locationsSchema = (primaryInfo, showDetails, isLocationsDirty) => {
  const isOtherFieldsChanged = (!showDetails && primaryInfo) || isLocationsDirty;
  return yup.object().shape({
    locationName: isOtherFieldsChanged
      ? yup.string()
      : yup.string().typeError("Invalid Location Name Input").required("Location Name is Required"),
  });
};

export default locationsSchema;
