import { useState, useEffect } from "react";
import { useAppContextController } from "context/AppContext";
import { feetToMeters } from "utils/helpers/conversion";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";

import { isEmpty, isUndefined } from "lodash";

const defaultCenter = { lat: 53.54, lng: 10 };
const defaultRadius = 100;
const defaultGraceDistance = feetToMeters(100);
const defaultProfileImg =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

const useEventMapDetails = () => {
  const { currentEvent, venues, company } = useAppContextController();
  const [roster, setRoster] = useState([]);
  const [rosterIds, setRosterIds] = useState([]);
  const [center, setCenter] = useState(defaultCenter);
  const [radius, setRadius] = useState(defaultRadius);
  const [graceDistance, setGraceDistance] = useState(defaultGraceDistance);
  const IMAGE_SERVER = company?.imageUrl;

  useEffect(() => {
    if (!currentEvent) return;
    const { secondaryLocation } = currentEvent;
    const eventVenue = venues?.[currentEvent?.venueSlug];
    let _center;
    let _radius;
    let _graceDistance;
    if (secondaryLocation) {
      _center = {
        lat: secondaryLocation?.latitude,
        lng: secondaryLocation?.longitude,
      };
      _radius = secondaryLocation?.radius;
      _graceDistance = secondaryLocation?.graceDistanceFeet;
    } else if (eventVenue?.location) {
      _center = {
        lat: eventVenue?.location?.latitude || eventVenue?.location?.coordinates?.[1],
        lng: eventVenue?.location?.longitude || eventVenue?.location?.coordinates?.[0],
      };
      _radius = eventVenue?.location?.radius;
      _graceDistance = eventVenue?.location?.graceDistanceFeet;
    } else if (!isEmpty(eventVenue?.locations)) {
      _center = {
        lat: eventVenue?.locations?.[0]?.latitude,
        lng: eventVenue?.locations?.[0]?.longitude,
      };
      _radius = eventVenue?.locations?.[0]?.radius;
      _graceDistance = eventVenue?.locations?.[0]?.graceDistanceFeet;
    }
    if (isUndefined(_center?.lat) || isUndefined(_center?.lng)) _center = defaultCenter;
    if (isUndefined(_radius)) _radius = defaultRadius;
    if (isUndefined(_graceDistance)) _graceDistance = defaultGraceDistance;

    setCenter(_center);
    setRadius(_radius);
    setGraceDistance(feetToMeters(_graceDistance));
  }, [currentEvent, venues]);

  useEffect(() => {
    if (!currentEvent?.applicants || !center) return;
    setRosterIds(currentEvent.applicants?.map((ros) => ros.id));
    setRoster(currentEvent.applicants);
  }, [currentEvent?.eventUrl, currentEvent?.applicants, center]);

  useEffect(() => {
    if (!currentEvent || !currentEvent?.venueSlug || isEmpty(rosterIds)) return;
    const fetchData = async () => {
      const applicantOptions = {
        fetchAll: true,
      };
      const res = await fetchUsers(applicantOptions);
      const tempAppl = res?.data?.filter((item) => rosterIds?.includes(item.applicantId));

      const tempRoster = roster?.map((ros) => {
        const appl = tempAppl?.find((obj) => obj.applicantId === ros.id);
        const eventDate = ros?.reportTime ? ros.reportTime : currentEvent?.eventDate;
        const reportTime = new Date(eventDate).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });

        const profile = appl?.profileImg?.startsWith("http")
          ? appl?.profileImg
          : `${IMAGE_SERVER}/users/${appl?._id}/photo/${appl?.profileImg}`;
        const profileImg = appl?.profileImg ? profile : defaultProfileImg;
        return {
          ...ros,
          name: `${appl?.firstName || ""} ${appl?.lastName || ""}`,
          primaryPosition: ros.primaryPosition || "Event Staff",
          reportTime,
          profileImg,
        };
      });

      setRoster(tempRoster);
    };

    fetchData();
  }, [currentEvent?.eventUrl, currentEvent?.venueSlug, rosterIds]);

  return {
    center,
    radius,
    graceDistance,
    roster,
  };
};

export default useEventMapDetails;
