import * as yup from "yup";


const requireOneFieldForAuthorizedAlien = {
  name: "requireOneFieldForAuthorizedAlien",
  test: (value, ctx) => {
    if (value.citizenshipStatus === "Authorized Alien") {
      if (
        !value.alienRegistrationNumber &&
        !value.formI94AdmissionNumber &&
        !value.foreignPassportNumberAndCountryOfIssuance
      ) {
        return ctx.createError({ message: "You must fill at least one of the following fields: Alien Registration Number, Form I-94 Admission Number, or Foreign Passport Number and Country of Issuance" });
      }
    }
    return true;
  },
};

export const i9Schema = yup.object().shape({
  preparerOrTranslator: yup.string().required("Please choose Certification"),
  citizenshipStatus: yup.string().required("Please choose Citizenship status"),
  alienRegistrationNumber: yup
    .string()
    .default("")
    .when("citizenshipStatus", {
      is: (val) => val === "Permanent Resident",
      then: yup.string().required("Alien Registration Number is required"),
      otherwise: yup.string().notRequired(),
    }),
  formI94AdmissionNumber: yup
    .string()
    .default(""),
  foreignPassportNumberAndCountryOfIssuance: yup
    .string()
    .default(""),
  expirationDate: yup
    .mixed()
    .default(""),
  processedDate: yup
    .mixed()
}).test(requireOneFieldForAuthorizedAlien);
