import axios from "axios";

import { baseAxios } from "config";
// import createApiParams from "utils/createApiParams";

const fetchVerifiedUsers = async () => {
  try {
    const res = await baseAxios.get(`/users/ses/verified/`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchVerifiedUsers;
