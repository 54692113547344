import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardActions, CardContent, CardHeader, Link } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const VerifySuccess = ({ newEmail, parent, setChangeEmailSuccessModalOpen }) => {
    const { loginWithRedirect } = useAuth0();
    const isProfile = parent === "profile"
    return (
        <MDBox position="relative" flexDirection="row">
            <Card
                sx={{
                    display: "flex",
                    mb: 15,
                    py: 2,
                    px: 2,
                    position: isProfile ? "absolute" : "static",
                    top: isProfile ? "50%" : "auto",
                    left: isProfile ? "50%" : "auto",
                    transform: isProfile ? "translate(-50%, 50%)" : "none",
                    maxHeight: isProfile ? "calc(100vh - 100px)" : "none",
                }}
            >
                <CardContent sx={{ p: 3 }}>
                    <CardHeader title="Verify User Account Email Change" subheader="" />
                    <CardContent sx={{ p: 2 }}>
                        <MDTypography variant="body2" textAlign="center" color="success">
                            <CheckCircleOutlineIcon />  Update Email success
                        </MDTypography>
                        <MDTypography variant="body2" textAlign="center">
                            Congratulations you have successfully updated your email address to <Link color="info" sx={{ fontWeight: 'bold' }} href={`mailto:${newEmail}`}>{newEmail}</Link>
                        </MDTypography>
                    </CardContent>

                    < CardActions sx={{ justifyContent: "center", mt: 1 }}>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                                if (parent !== "profile") { loginWithRedirect() }
                                else { setChangeEmailSuccessModalOpen(false) }
                            }}
                        >
                            {parent !== "profile" ? "Sign In" : "Close"}
                        </Button>
                    </CardActions>
                </CardContent>

            </Card>
        </MDBox >
    )
}

export default VerifySuccess
