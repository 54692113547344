import { Box, Grid, Icon, styled } from "@mui/material";
import parse from "html-react-parser";
import moment from "moment";

import updateNotification from "api/notifications/updateNotification";
import CustomCard from "components/CustomCard";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNotificationContext } from "context/NotificationContext";
import { useEffect } from "react";
import { useMutation } from "react-query";

const FieldWrapper = styled(FlexWrapper)(() => ({
  flexDirection: "column",
  alignItems: "flex-start",
  rowGap: "5px",
}));

const NotificationInfoPanel = () => {
  const { notification, refetchTableData } = useNotificationContext();

  const { mutate } = useMutation(updateNotification, {
    onSuccess: () => {
      if (refetchTableData) {
        refetchTableData();
      }
    },
  });

  useEffect(() => {
    if (notification?.status === "unread") {
      mutate({ notificationId: notification._id, body: { status: "read" } });
    }
  }, [notification]);

  return (
    <CustomCard
      icon={<Icon fontSize="large">email</Icon>}
      cardTitle={`Subject: ${notification?.subject}`}
      iconContainerSx={{
        background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
        color: "white !important",
      }}
      cardSx={{ width: "100%" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <FlexWrapper flexDirection="column" rowGap="10px">
            <FieldWrapper>
              <MDTypography component="label" variant="subtitle2" fontWeight="bold">
                FROM:
              </MDTypography>
              <FlexWrapper justifyContent="flex-start" columnGap="15px" sx={{ pl: "5px" }}>
                <PictureAvatar
                  image={notification?.profileImg}
                  firstName={notification?.fromFirstName}
                  lastName={notification?.fromLastName}
                  userId={notification?.fromUserId}
                  size="md"
                />
                <MDTypography variant="subtitle1" sx={{ fontWeight: 400 }}>
                  {`${notification?.fromFirstName} ${notification?.fromLastName}`}{" "}
                </MDTypography>
              </FlexWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <MDTypography component="label" variant="subtitle2" fontWeight="bold">
                TIME SENT:
              </MDTypography>
              <MDTypography variant="subtitle1" sx={{ fontWeight: 400, pl: "5px" }}>
                {moment(notification?.sendTime).format("ddd YYYY-MM-DD hh:mm A")}
              </MDTypography>
            </FieldWrapper>
          </FlexWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          lg={9}
          sx={{
            "& .ql-editor": {
              minHeight: "100px !important",
              maxHeight: "200px",
              overflow: "hidden",
              overflowY: "auto",
              overflowX: "auto",
            },
          }}
        >
          <FieldWrapper>
            <MDTypography component="label" variant="subtitle2" fontWeight="bold">
              BODY:
            </MDTypography>
            <Box sx={{ pl: "5px", width: "100%" }}>
              {notification?.body ? parse(notification.body) : null}
            </Box>
          </FieldWrapper>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default NotificationInfoPanel;
