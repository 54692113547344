import { Typography } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import moment from "moment";

const EventDetail = ({ eventName, venueName, eventDate, eventEndTime }) => {
  return (
    <FlexWrapper flexDirection="column" alignItems="flex-start" my="10px">
      <Typography fontWeight="bold" fontSize="1rem" color="black">
        {eventName}
      </Typography>
      <Typography fontWeight="medium" fontSize="0.875rem" color="black">
        {venueName}
      </Typography>
      <Typography fontSize="0.75rem" color="grey">
        {moment(eventDate).format("ddd, MMM Do, YYYY h:mm A")}
      </Typography>
      {eventEndTime && (
        <Typography fontSize="0.75rem" color="grey">
          Estimated End Time: {moment(eventEndTime).format("h:mm A")}
        </Typography>
      )}
    </FlexWrapper>
  );
};

export default EventDetail;
