import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Controller } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MDEditor from "components/MDEditor";
import ConfirmDialog from "components/ConfirmDialog";
import createIssue from "layouts/pages/issues/actions/createIssue";
// import updateIssue from "layouts/pages/issues/actions/updateIssue";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import UserActionModal from "layouts/pages/users/components/UserActionModal";
import PictureAvatar from "components/PictureAvatar";
import BugReportIcon from "@mui/icons-material/BugReport";
import EmailIcon from "@mui/icons-material/Email";

import useDropdowns from "hooks/useDropdowns";

import { Autocomplete, Button, Card, Grid, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AttachFile } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import { getDirtyFields } from "utils/helpers/formHelpers";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import CustomTextField from "components/Form/CustomTextField";
import useSessionAuth from "hooks/useSessionAuth";
import updateIssue from "../../actions/updateIssue";
import fetchIssueById from "../../actions/fetchIssueById";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 175,
  },
  dropzone: {
    height: 370,
  },
  previewButton: {
    padding: 0,
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
    height: 850,
  },
});

function IssuesInformation({
  watch,
  setValue,
  reset,
  clearErrors,
  setPanelSelect,
  handleSubmit,
  panelSelect,
  isSubmitting,
  control,
  dirtyFields,
  toastAlert,
  setToastAlert,
  defaultValues,
  errors,
}) {
  const classes = useStyle();
  const { currentIssue, setCurrentIssue, currentLoggedUser, userType, company } =
    useAppContextController();
  const isEdit = !!currentIssue?._id;

  const { dropdowns: issuesCategories } = useDropdowns({ entity: "issuesCategories" });
  const { dropdowns: issuesTypes } = useDropdowns({ entity: "issuesTypes" });
  const { dropdowns: issuesStatuses } = useDropdowns({ entity: "issuesStatuses" });

  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: {} });
  const { issueId } = useParams();
  const [createdByUser, setCreatedByUser] = useState(null);

  const [isModified, setIsModified] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [createConfirmModal, setCreateConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const handleClick = (selection) => {
    setPanelSelect(selection);
  };
  const issueTabs = [
    {
      title: "Comments",
      icon: <CommentIcon />,
      hasShadow: panelSelect === "comments" ? "emd" : "",
      color: "info",
      ariaLabel: "timeLine",
      fn: () => handleClick("comments"),
      hidden: false,
    },
    {
      title: "Attachments",
      icon: <AttachFile />,
      hasShadow: panelSelect === "attachments" ? "emd" : "",
      color: "warning",
      ariaLabel: "attachments",
      fn: () => handleClick("attachments"),
    },
  ];

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading } = useMutation(createIssue, {
    onError: (err) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong!  ${err.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("issues");
      setToastAlert({ isShow: true, message: "Issue has been created!", status: "success" });
      reset(defaultValues);
      // setCurrentIssue({ _id:_.insertedId, ...data });
      setCreatedByUser(currentLoggedUser);
      // navigate(`/issues/${_.insertedId}`);
      navigate(`/issues`);
    },
  });

  const updateIssueMutation = useMutation(updateIssue, {
    onError: (err) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong!  ${err.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("issues");
      setCurrentIssue({ ...currentIssue, ...data });
      setToastAlert({ isShow: true, message: "Issue has been updated!", status: "success" });
    },
  });

  const createIssueHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser._id;
    data.modifiedDate = new Date();
    data.issueDate = new Date();
    data.userFirstName = currentLoggedUser.firstName;
    data.userLastName = currentLoggedUser.lastName;
    await mutateAsync(data);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  function diffObj(obj1, obj2) {
    const result = {};
    if (Object.is(obj1, obj2)) return undefined;
    if (!obj2 || typeof obj2 !== "object") return obj2;
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        let val;
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) val = obj2[key];

        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = diffObj(obj1[key], obj2[key]);
          if (value !== undefined) val = value;
        } else if (val !== undefined) {
          result[key] = val;
        }
      });
    return result;
  }

  const resetDeleteConfirmModal = () => {
    setDeleteConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };
  const resetCreateConfirmModal = () => {
    setCreateConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getIssueValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentIssue?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    reset(getIssueValues());
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes have been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentIssue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!, no Id", status: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    // eslint-disable-next-line
    const data = { ...modifiedFields, modifiedDate: new Date() };
    await updateIssueMutation.mutateAsync({
      id: currentIssue._id,
      data,
    });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  const cancelCreate = () => {
    setCreateConfirmModal({
      isOpen: true,
      title: "You are leaving create mode",
      bodyText: "Please Confirm to abandon new Issue",
      response: false,
    });
  };

  const onDelete = () => {
    setDeleteConfirmModal({
      isOpen: true,
      title: "Delete Issue",
      bodyText: "Please confirm you want to DELETE this Issue!",
      response: false,
    });
  };

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const fetchCurrentIssue = async (id) => {
      const res = await fetchIssueById(id);
      if (res) setCurrentIssue(res);
    };
    if (issueId) {
      setCreatedByUser(null);

      try {
        fetchCurrentIssue(issueId);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, []);

  useEffect(() => {
    const getCreateUser = async (id) => {
      if (!id) {
        setCreatedByUser(null);
      }
      const res = await fetchUserById(id);
      if (res) setCreatedByUser(res);
    };
    if (isEdit) {
      if (currentIssue) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentIssue[key] || "", { shouldDirty: true });
        });
        setIsModified(false);
      }

      const subscription = watch((value) => {
        if (currentIssue) {
          setIsModified(JSON.stringify(getIssueValues()) !== JSON.stringify(value));
        }
      });

      // lets fetch the user that opened the issue

      if (!createdByUser || createdByUser._id !== currentIssue?.createAgent) {
        try {
          getCreateUser(currentIssue?.createAgent);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      }

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
    return null;
  }, [watch, currentIssue, confirmModal.response]);

  useEffect(() => {
    const del = async () => {
      // const deleteIssuetitle = currentIssue?.issueTitle;
      // const res = await deleteIssue(currentIssue._id, deleteIssuetitle);
      if (!issueId) {
        setToastAlert({ isShow: true, message: "Something went wrong!, no Id", status: "error" });
        return;
      }
      const values = diffObj(getIssueValues(), watch());
      const data = { ...values, status: "Deleted", modifiedDate: new Date() };
      const res = await updateIssueMutation.mutateAsync({ id: issueId, data });

      if (res?.acknowledged) {
        setCurrentIssue(null);
        navigate(`/issues`);
      }
    };
    if (deleteConfirmModal.response) {
      del();
      resetDeleteConfirmModal();
    }
  }, [deleteConfirmModal.response]);

  useEffect(() => {
    if (createConfirmModal.response) {
      resetCreateConfirmModal();
      navigate(`/issues`);
    }
  }, [createConfirmModal.response]);

  return (
    <Card className={classes.box} key={currentIssue?.id}>
      <form onSubmit={isEdit ? handleSubmit(saveChanges) : handleSubmit(createIssueHandler)}>
        <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
          <MDBox display="grid">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              borderRadius="xl"
              mt={-3}
            >
              <Icon fontSize="large">
                <BugReportIcon />
              </Icon>
            </MDBox>
          </MDBox>

          <Grid container flexDirection="row">
            <Grid item sm={8}>
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, ml: 2 }}>
                {!isEdit ? "System Issues Submission" : `Issue: ${currentIssue?.issueTitle}`}
              </MDTypography>
            </Grid>
            <Grid item sm={4}>
              <MDBox display="flex" alignItems="center" justifyContent="flex-end" mt={1}>
                {isEdit && !isModified && userType === "Master" && (
                  <Button
                    variant="text"
                    endIcon={<DeleteIcon />}
                    onClick={onDelete}
                    style={{ color: "red" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Delete Issue
                  </Button>
                )}
                {isModified && isEdit && (
                  <>
                    <Button
                      variant="text"
                      startIcon={<CancelIcon />}
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                      Discard
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<SaveIcon />}
                      style={{ color: "white" }}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </>
                )}
                {!isEdit && (
                  <>
                    <Button
                      variant="text"
                      startIcon={<CancelIcon />}
                      onClick={cancelCreate}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<SaveIcon />}
                      style={{ color: "white" }}
                      disabled={isLoading}
                    >
                      Create
                    </Button>
                  </>
                )}
              </MDBox>
            </Grid>
            {isEdit && (
              <>
                <Grid item sm={12}>
                  <Stack direction="row" alignItems="center">
                    <MDTypography variant="h6" color="dark" sx={{ ml: 2, mr: 2 }}>
                      Reported By:
                    </MDTypography>
                    {currentIssue &&
                      createdByUser &&
                      currentIssue.createAgent === createdByUser?._id && (
                        <PictureAvatar
                          // image={(currentIssue?.createAgent === currentLoggedUser?._id && currentLoggedUser?.profileImg)
                          //   ? currentLoggedUser.profileImg : null}
                          image={createdByUser?.profileImg}
                          firstName={createdByUser?.firstName}
                          lastName={createdByUser?.lastName}
                          userId={createdByUser?._id}
                          size="sm"
                        />
                      )}
                    <MDTypography variant="h6" color="dark" mx={2}>
                      {`${currentIssue?.userFirstName} ${currentIssue?.userLastName} (${createdByUser?.emailAddress})`}
                    </MDTypography>
                    <Tooltip title="Send Message">
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={async () => {
                          // get user rec from createAgent id
                          if (isEdit && currentIssue?.createAgent) {
                            const user = await fetchUserById(currentIssue.createAgent);

                            // set it to modalInfo data and open the dialog
                            if (user) {
                              setModalInfo({ data: user, type: "usermail" });
                              setGenericModalOpen(true);
                            }
                          }
                        }}
                      >
                        <EmailIcon fontSize="large" color="error" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item sm={12}>
                  <MDTypography variant="body2" ml={2}>
                    Last Update:{" "}
                    {moment(currentIssue?.modifiedDate).format("ddd MM/DD/YYYY hh:mm a")}
                  </MDTypography>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
        <MDBox p={3} pt={5}>
          <Grid container spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} desktop={6}>
                <MDTypography component="label" variant="h5" fontWeight="bold" color="info" ml={3}>
                  Issue Information
                </MDTypography>
              </Grid>
              <Grid item xs={12} desktop={6}>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                  {issueTabs
                    .filter((tab) => !tab?.hidden)
                    .map((tab, i) => (
                      <Tooltip title={tab?.title} key={`${tab?.title}_index`}>
                        <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow}>
                          <IconButton
                            color={tab.color}
                            aria-label={tab.ariaLabel}
                            onClick={tab.fn}
                            disabled={!isEdit}
                          >
                            {tab.icon}
                          </IconButton>
                        </MDBox>
                      </Tooltip>
                    ))}
                </Stack>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="issueTitle"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    {...field}
                    key={`title_${currentIssue?._id}`}
                    type="text"
                    label="Issue Title"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="status"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    key={`autocomplete_status_${currentIssue?._id}`}
                    options={issuesStatuses || []}
                    autoSelect
                    name="Status"
                    value={field.value}
                    onChange={(e, v) => {
                      field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`status_${currentIssue?._id}`}
                        {...params}
                        type="text"
                        label="Status"
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    key={`autocomplete_type_${currentIssue?._id}`}
                    autoSelect
                    options={issuesTypes || []}
                    name="Type"
                    value={field.value}
                    onChange={(e, v) => {
                      field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`type_${currentIssue?._id}`}
                        {...params}
                        type="text"
                        label="Type"
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="category"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    key={`autocomplete_category_${currentIssue?._id}`}
                    options={issuesCategories || []}
                    autoSelect
                    name="Section/Category"
                    value={field.value}
                    onChange={(e, v) => {
                      field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`category_${currentIssue?._id}`}
                        {...params}
                        type="text"
                        label="Section/Category"
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="issueNumber"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    {...field}
                    disabled
                    key={`title_${currentIssue?._id}`}
                    type="text"
                    label="Issue Number"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="modifiedDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    {...field}
                    disabled
                    key={`title_${currentIssue?._id}`}
                    type="text"
                    label="Last Updated"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12}>
              <MDBox
                sx={{
                  "& .ql-editor": {
                    minHeight: "150px !important",
                    maxHeight: "250px",
                    overflow: "hidden",
                    overflowY: "auto",
                    overflowX: "auto",
                  },
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MDTypography component="label" variant="button" fontWeight="regular">
                    Issue Description
                  </MDTypography>
                </Stack>
                <Controller
                  name="issueDescription"
                  control={control}
                  render={({ field: { value, onChange, ...props } }) => (
                    <MDEditor
                      className={classes.editor}
                      onChange={(text) => {
                        onChange(text);
                      }}
                      value={value}
                      {...props}
                      style={{ height: "100%" }}
                    />
                  )}
                />
                {errors?.issueDescription && (
                  <MDTypography className={classes.error} color="error" mt={1}>
                    {errors?.issueDescription.message}
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </form>
      <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
      <ConfirmDialog state={deleteConfirmModal} setState={setDeleteConfirmModal} />
      <ConfirmDialog state={createConfirmModal} setState={setCreateConfirmModal} />
      <UserActionModal
        open={genericModalOpen}
        setOpen={setGenericModalOpen}
        modalInfo={modalInfo}
        setToastAlert={setToastAlert}
      />
    </Card>
  );
}

export default IssuesInformation;
