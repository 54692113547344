// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import ChatIcon from '@mui/icons-material/Chat';

const ActivitiesTableActions = ({ id, row, setCurrentActivity, setGenericModalOpen, setModalInfo }) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    setCurrentActivity(row);
    switch (action) {
      case "activitiesinfo":
        navigate(`/activities/${id}/action/${action}`);
        break;
      case "activitiesall":
        navigate(`/activities/${id}/action/${action}`);
        break;
      // case "usermail":
      //     setModalInfo({ type: action, data: row });
      //     setGenericModalOpen(true);
      //     break;
      // case "usercomments":
      //     setModalInfo({ type: action, data: row });
      //     setGenericModalOpen(true);
      //     break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("activitiesinfo", e)}
        tooltip="User Info"
      />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("activitiesall", e)}
        tooltip="All Activity Panels"
      /> */}
      &nbsp;&nbsp;
    </div>
  );
};

export default ActivitiesTableActions;

ActivitiesTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
ActivitiesTableActions.propTypes = {
  id: PropTypes.string,
};
