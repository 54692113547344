import { Grid } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";

const stateFields = {
  // SSN: Social Security Number, MI: Middle Initial
  NONE: [],
  GA: ["SSN"],
  OK: ["MI"],
  AL: ["SSN", "MI"],
  MI: ["SSN"],
  SC: ["SSN"],
  CA: ["SSN", "MI"],
};

const CommonTaxFields = ({ applicant, selectedState = "NONE" }) => {
  const isSSN = stateFields[selectedState]?.includes("SSN");
  const isMI = stateFields[selectedState]?.includes("MI");
  return (
    <>
      <Grid item xs={12} sm={isMI || isSSN ? 4 : 6}>
        <CustomTextField
          variant="outlined"
          disabled
          inputProps={{ tabIndex: -1 }}
          value={applicant?.firstName}
          label="First Name"
        />
      </Grid>
      {isMI && (
        <Grid item xs={12} sm={2}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            value={applicant?.middleInitial}
            label="Middle Initial"
          />
        </Grid>
      )}
      <Grid item xs={12} sm={isSSN ? 4 : 6}>
        <CustomTextField
          variant="outlined"
          disabled
          inputProps={{ tabIndex: -1 }}
          value={applicant?.lastName}
          label="Last Name"
        />
      </Grid>
      {isSSN && (
        <Grid item xs={12} sm={isMI ? 2 : 4}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            value={applicant?.socialSecurity}
            label="Social Security Number"
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          variant="outlined"
          disabled
          inputProps={{ tabIndex: -1 }}
          value={applicant?.address1}
          label="Address"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomTextField
          variant="outlined"
          disabled
          inputProps={{ tabIndex: -1 }}
          value={applicant?.city}
          label="City"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomTextField
          variant="outlined"
          disabled
          inputProps={{ tabIndex: -1 }}
          value={applicant?.state}
          label="State"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomTextField
          variant="outlined"
          disabled
          inputProps={{ tabIndex: -1 }}
          value={applicant?.zip}
          label="Zip Code"
        />
      </Grid>
    </>
  );
};

export default CommonTaxFields;
