import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Description } from "@mui/icons-material";

const statusColor = {
  none: "dark",
  "Time Approved": "success",
  "Approval Pending": "warning",
  "Time Rejected": "error",
};

const MyTimeclockActions = ({ appointment }) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {

  };

  return (
    <MDBox display="flex" justifyContent="end">
      <TableAction
        icon={<ThumbUpIcon fontSize="large" color={statusColor[appointment?.status]} />}
        clickAction={(e) => onClickHandler("jobaAplicants", e)}
        tooltip={appointment?.status}
      />
      &nbsp;
      <TableAction
        color="error"
        icon="mail"
        clickAction={(e) => onClickHandler("messageEmployee", e)}
        tooltip="Message Employee"
      />
      &nbsp;
      <TableAction
        color="info"
        icon={<Description />}
        clickAction={(e) => onClickHandler("timecardNotes", e)}
        tooltip="Timecard Notes"
      />
      &nbsp;
    </MDBox>
  );
};
export default MyTimeclockActions;

MyTimeclockActions.defaultProps = {
  appointment: null,
};

MyTimeclockActions.propTypes = {
  appointment: PropTypes.object,
};
