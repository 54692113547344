import { MESSAGE_VARIABLES } from "utils/constants/emailTemplate";

export const parseMessage = (message) => {
  let updatedMessage = message;
  MESSAGE_VARIABLES.forEach((item) => {
    updatedMessage = updatedMessage.replaceAll(item.key, `\${${item.variable}}`);
  });
  return updatedMessage;
};

export const parseMessageFromDB = (message) => {
  let updatedMessage = message;
  MESSAGE_VARIABLES.forEach((item) => {
    updatedMessage = updatedMessage.replaceAll(`\${${item.variable}}`, item.key);
  });
  return updatedMessage;
};
