import { useState } from "react";
import { Box, Chip, FormHelperText } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";

const ObjectFieldTags = ({ tags, setTags, setValue, arrayValue }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if ((event.key === "Enter" || event.key === " ") && inputValue) {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (!tags.includes(newTag)) {
        setTags([...tags, newTag]);
        const tempArr = arrayValue.map((obj) => {
          return { ...obj, [newTag]: "new value" };
        });
        setValue("arrayValue", tempArr, { shouldDirty: true });
      }
      setInputValue("");
    }
  };

  const handleDelete = (tagToDelete) => () => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    const tempArr = arrayValue.map((obj) => {
      const { [tagToDelete]: toRemove, ...rest } = obj;
      return rest;
    });
    setValue("arrayValue", tempArr, { shouldDirty: true });
  };

  return (
    <Box>
      <FormField
        label="Add Field Name"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        multiline
      />
      <FormHelperText>Press enter or space to add</FormHelperText>
      <Box>
        {tags.map((tag, index) => (
          <Chip
            key={tag.replace(" ", "_")}
            label={tag}
            onDelete={handleDelete(tag)}
            variant="outlined"
            sx={{ mr: 0.5, mt: 0.5 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ObjectFieldTags;
