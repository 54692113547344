import { useEffect, useReducer, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { baseAxios } from "config";
import { useSnackbar } from "notistack";
import FormField from "layouts/applications/wizard/components/FormField";
import GenericModal from "components/GenericModal";
import { PatternFormat } from "react-number-format";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";

import newApplicantReducer, { newApplicantInitialState } from "reducers/newApplicantReducer";

const Verification = () => {
  const { isMobile, company } = useAppContextController();
  const { setApplicantSteps, loadApplicantAction } = useNewApplicantContext();
  const [state, dispatch] = useReducer(newApplicantReducer, newApplicantInitialState);
  const { enqueueSnackbar } = useSnackbar();
  const phoneRegExp =
    /^(?=.*[0-9])((\+\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4})(?:\s?(?:#|x\.?|ext\.?|extension)[\s.-]?\d{4})?$/i;
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();
  const [emailPhoneField, setEmailPhoneField] = useState("");
  const [emailFound, setEmailFound] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [isEmployeeAlready, setIsEmployeeAlready] = useState(false);
  const [otp, setOtp] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [resendCount, setResendCount] = useState(1);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const [clickCount, setClickCount] = useState(0);
  const [disableResendButton, setDisableResendButton] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);

  const sendOTP = async () => {
    const result = await baseAxios.post(
      `/outside-public/applicants/verify/email/${emailPhoneField}`,
      {
        device: "Web",
        resendCount,
      }
    );
    if (result?.data?.success) {
      setOpenOTPModal(true);
      setSubmittingOTP(false);
    }

    setLoadingOTP(false);
  };

  const handleResendClick = () => {
    const delay = [30, 60, 90, 120];
    if (clickCount < delay.length) {
      setClickCount(clickCount + 1);
      setSecondsLeft(delay[clickCount]);
      setDisableResendButton(true);
    }
    sendOTP();
  };

  useEffect(() => {
    let timer;
    if (clickCount > -1 && secondsLeft > 0) {
      timer = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setDisableResendButton(false);
      setSecondsLeft(null);
    }
    return () => clearTimeout(timer);
  }, [clickCount, secondsLeft]);

  const handleEmailPhoneChange = (value) => {
    setEmailPhoneField(value);
  };

  const handleSubmit = async () => {
    if (emailRegExp.test(emailPhoneField)) {
      const filteredApplicants = await fetchApplicants({
        filters: { email: emailPhoneField },
        fetchAll: true,
        outsideMode: "public",
        projectedFields: "email",
      });

      setFieldError(null);
      if (filteredApplicants?.data !== undefined) {
        setEmailFound(true);
      } else {
        setEmailFound(false);
      }
      setVerificationModalOpen(true);
      setLoadingOTP(false);
      setSubmittingOTP(false);
    } else if (phoneRegExp.test(emailPhoneField)) {
      const filteredApplicants = await fetchApplicants({
        filters: { phone: emailPhoneField },
        fetchAll: true,
        outsideMode: "public",
        projectedFields: "email",
      });
      setFieldError(null);
      if (filteredApplicants?.data !== undefined) {
        setEmailFound(true);
      } else {
        setEmailFound(false);
      }
      setVerificationModalOpen(true);
      setLoadingOTP(false);
      setSubmittingOTP(false);
    } else {
      setFieldError("You have to input a valid phone or email");
    }
  };

  const verifyOneTimePasscode = async () => {
    let result;

    try {
      result = await baseAxios.post(`/outside-public/applicants/email/${emailPhoneField}/otp`, {
        device: "Web",
        otp,
        isNewApplicantForms: true,
      });
    } catch (error) {
      if (error.response.data.expired) {
        enqueueSnackbar("Expired one-time passcode", { variant: "error" });
      } else {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      }

      setSubmittingOTP(false);
      setOtp("");
    }

    if (result?.data?.success) {
      if (result?.data?.applicant?.acknowledged) {
        setOpenOTPModal(false);
        setVerificationModalOpen(false);
        setIsEmployeeAlready(result?.data?.applicant.status === "Employee");
        setOpenEmployeeModal(true);
      } else {
        setApplicantSteps(
          result?.data?.applicant?.status,
          result?.data?.applicant?.applicantStatus,
          result?.data?.applicant?.acknowledged
        );
        loadApplicantAction(result?.data?.applicant);
        setOpenOTPModal(false);
        setVerificationModalOpen(false);
      }
    }
  };

  const mailFoundHeader = (
    <>
      <MDTypography variant="h3">Email Found</MDTypography>
    </>
  );
  const mailNotFoundHeader = (
    <>
      <MDTypography variant="h3">Email Not Found</MDTypography>
    </>
  );
  const employeeHeader = (
    <>
      <MDTypography variant="h3">
        {isEmployeeAlready ? "Already an Employee" : "Onboarding Completed"}
      </MDTypography>
    </>
  );

  const mailFound = (
    <Box>
      <MDTypography textAlign="center">
        Success! We found your record in the system. In order to protect your information, we are
        sending One-Time-Passcode (OTP) to you email:&nbsp;
        {emailPhoneField}
      </MDTypography>
    </Box>
  );
  const newNotFound = (
    <Box>
      <MDTypography mb={3} textAlign="center">
        Oops! The email&nbsp;{emailPhoneField}&nbsp;is not in our system yet. Please make sure that
        the entered email is correct.
      </MDTypography>
      <MDTypography textAlign="center" mb={3}>
        To apply for a job, please click the button below
      </MDTypography>
      <Grid item xs={12} sm={3}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            navigate("/");
          }}
        >
          Apply Now!
        </Button>
      </Grid>
    </Box>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        color="secondary"
        onClick={() => {
          setEmailPhoneField("");
          setVerificationModalOpen(false);
          setLoadingOTP(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="error"
        disabled={loadingOTP}
        style={{ color: "white" }}
        onClick={() => {
          setLoadingOTP(true);
          sendOTP();
        }}
      >
        Send OTP
      </Button>
    </>
  );

  const verifyModalBody = (
    <>
      <Box textAlign="center">
        <MDTypography textAlign="center">
          A One-Time Passcode has been sent to {emailPhoneField}.
        </MDTypography>
        <Box mt={3} width="60%" sx={{ marginLeft: "20%" }}>
          <PatternFormat
            value={otp}
            format="# # # # # #"
            mask="-"
            allowEmptyFormatting
            onValueChange={(e) => setOtp(e.value)}
            style={{
              fontSize: "4rem",
              textAlign: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          />
        </Box>
        {clickCount === 4 ? (
          <MDTypography textAlign="center">
            <span>Can&apos;t request to resend code</span>
          </MDTypography>
        ) : (
          <MDTypography textAlign="center">
            {secondsLeft !== null ? (
              <span>Resend available in {secondsLeft} seconds</span>
            ) : (
              <span>
                Didn&apos;t receive the code?{" "}
                <Button
                  disabled={disableResendButton}
                  sx={{ fontSize: "1.2rem", color: "red" }}
                  onClick={handleResendClick}
                >
                  RESEND CODE
                </Button>
              </span>
            )}
          </MDTypography>
        )}
      </Box>
    </>
  );
  const verifyModalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        color="secondary"
        onClick={() => {
          setVerificationModalOpen(false);
          setOpenOTPModal(false);
          setSubmittingOTP(false);
          setOtp("");
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        style={{ color: "white" }}
        disabled={submittingOTP}
        color="error"
        onClick={() => {
          setSubmittingOTP(true);
          verifyOneTimePasscode();
        }}
      >
        Submit
      </Button>
    </>
  );
  const employeeModalBody = (
    <>
      <Box textAlign="center">
        <MDTypography textAlign="center">
          {isEmployeeAlready
            ? `You are already an employee. If you need to update any information please ${
                company?.onboardingSupportUsePhone === "Yes" ? "call" : "email"
              }`
            : `You have completed the onboarding. If you need to update any information please ${
                company?.onboardingSupportUsePhone === "Yes" ? "call" : "email"
              }`}
        </MDTypography>
        &nbsp;
        <div>
          <MDTypography variant="body2" fontWeight="bold" color="info">
            {company?.onboardingSupportUsePhone === "Yes" ? company?.phone : company?.supportEmail}
          </MDTypography>
        </div>
        &nbsp;
        <MDTypography>and they will be able to assist you.</MDTypography>
      </Box>
    </>
  );
  const employeeModalButtons = (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpenEmployeeModal(false);
        }}
      >
        Ok
      </Button>
    </>
  );

  return (
    <>
      <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px", alignItems: "start" }}>
        <Typography variant="body2">
          Please input your email address to start the verification process.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormField
              label="Email"
              type="text"
              value={emailPhoneField}
              onChange={(e) => {
                handleEmailPhoneChange(e.target.value?.toLowerCase());
              }}
              error={!!fieldError}
              helperText={fieldError}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleSubmit();
              }}
            >
              Begin Verification
            </Button>
          </Grid>
        </Grid>
      </FlexWrapper>
      <GenericModal
        open={verificationModalOpen}
        setOpen={setVerificationModalOpen}
        header={emailFound ? mailFoundHeader : mailNotFoundHeader}
        body={emailFound ? mailFound : newNotFound}
        buttons={emailFound && modalButtons}
        width={isMobile ? "90%" : "35%"}
      />
      <GenericModal
        open={openOTPModal}
        setOpen={setOpenOTPModal}
        header={mailFoundHeader}
        body={verifyModalBody}
        buttons={verifyModalButtons}
        width={isMobile ? "90%" : "35%"}
      />
      <GenericModal
        open={openEmployeeModal}
        setOpen={setOpenEmployeeModal}
        header={employeeHeader}
        body={employeeModalBody}
        buttons={employeeModalButtons}
        width={isMobile ? "90%" : "35%"}
      />
    </>
  );
};

export default Verification;
