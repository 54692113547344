import { Stadium } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import createActivity from "layouts/pages/activities/actions/createActivity";
import updateApplicantVenue from "layouts/pages/applicants/actions/updateApplicantVenue";
import { APPLICANT_VENUE_STATUS_ENUM } from "utils/constants/applicant";
import ConfirmationModal from "components/ConfirmationModal";
import { useInvalidateCheckForHelmAllowed } from "hooks/useInvalidateCheckForHelmAllowed";
import updatePartnerVenue from "layouts/pages/partners/actions/updatePartnerVenue";

const statusColor = {
  None: "dark",
  StaffingPool: "success",
  Pending: "warning",
  Locked: "error",
  Inactive: "error",
  Terminated: "error",
};

const VenueStatusButton = ({
  status = "None",
  venue,
  viewOnly = false,
  onUpdate,
  applicant,
  isPartner,
}) => {
  const {
    currentApplicant: currentApplicantLocal,
    setCurrentApplicant: setCurrentApplicantLocal,
    currentPartner: currentPartnerLocal,
    setCurrentPartner: setCurrentPartnerLocal,
    currentLoggedUser,
    userType,
  } = useAppContextController();

  const currentApplicant = isPartner ? currentPartnerLocal : currentApplicantLocal;
  const setCurrentApplicant = isPartner ? setCurrentPartnerLocal : setCurrentApplicantLocal;

  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const activeApplicant = applicant || currentApplicant;
  const viewOnlyStatus =
    activeApplicant?.recordLocked === "Yes" ||
    viewOnly ||
    userType === "Client" ||
    userType === "Event Admin";
  const { invalidateCheckForHelmAllowed } = useInvalidateCheckForHelmAllowed();

  const name = isPartner
    ? activeApplicant?.name
    : `${activeApplicant?.firstName} ${activeApplicant?.lastName}`;

  const { mutate: createActivityMutation } = useMutation(createActivity);

  const changeStatusMutation = useMutation(isPartner ? updatePartnerVenue : updateApplicantVenue, {
    onSuccess: async (_, { data }) => {
      if (
        currentApplicant != null &&
        (applicant == null || isEmpty(applicant) || applicant._id === currentApplicant._id)
      ) {
        setCurrentApplicant({ ...currentApplicant, venues: _?.venues?.length ? _.venues : [] });
        await invalidateCheckForHelmAllowed(
          activeApplicant?.status === "Employee",
          activeApplicant?._id
        );
      }
    },
    mutationKey: "venueStatusMutation",
  });

  const handleUpdate = useCallback(
    (newStatus) => {
      const { slug } = venue;

      const newVenue = {
        status: newStatus === APPLICANT_VENUE_STATUS_ENUM.NONE ? "Delete" : newStatus,
        venueSlug: slug,
        agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
        dateModified: new Date().toISOString(),
      };

      changeStatusMutation.mutate(
        {
          applicantId: activeApplicant._id,
          data: {
            venue: newVenue,
            createAgent: currentLoggedUser?._id,
            userId: currentLoggedUser?._id,
            agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
          },
        },
        {
          onSuccess: async () => {
            enqueueSnackbar(
              `${name} has been set to: ${newStatus} for ${venue.slug}`,
              { variant: statusColor[newStatus] }
            );
            // if (venue?._id && venue?.slug) {
            //   createActivityMutation({
            //     action: "Venue Status",
            //     description: `Set Venue Status for ${venue.slug} to ${newStatus}`,
            //     venueId: venue._id,
            //     applicantId: activeApplicant?._id,
            //   });
            // }
            onUpdate?.();
            toggleConfirmationModal(false);
          },
        }
      );
    },
    [activeApplicant, venue, changeStatusMutation, enqueueSnackbar, onUpdate, currentLoggedUser]
  );

  const options = [
    {
      text: "None",
      icon: <Stadium fontSize="medium" color={statusColor.None} />,
      handleClick: () => toggleConfirmationModal(true),
      disabled: APPLICANT_VENUE_STATUS_ENUM.NONE === status,
    },
    {
      text: "Staffing Pool",
      icon: (
        <Stadium fontSize="medium" color={statusColor[APPLICANT_VENUE_STATUS_ENUM.STAFFING_POOL]} />
      ),
      handleClick: () => handleUpdate(APPLICANT_VENUE_STATUS_ENUM.STAFFING_POOL),
      disabled:
        APPLICANT_VENUE_STATUS_ENUM.STAFFING_POOL === status ||
        (!isPartner &&
          activeApplicant &&
          (activeApplicant?.status !== "Employee" ||
            activeApplicant?.employmentStatus === "Inactive" ||
            activeApplicant?.employmentStatus === "Terminated")),
    },
    {
      text: "Pending",
      icon: <Stadium fontSize="medium" color={statusColor[APPLICANT_VENUE_STATUS_ENUM.PENDING]} />,
      handleClick: () => handleUpdate(APPLICANT_VENUE_STATUS_ENUM.PENDING),
      disabled: APPLICANT_VENUE_STATUS_ENUM.PENDING === status,
    },
    {
      text: "Locked",
      icon: <Stadium fontSize="medium" color={statusColor[APPLICANT_VENUE_STATUS_ENUM.LOCKED]} />,
      handleClick: () => handleUpdate(APPLICANT_VENUE_STATUS_ENUM.LOCKED),
      disabled: APPLICANT_VENUE_STATUS_ENUM.LOCKED === status,
    },
  ];

  return (
    <>
      <Tooltip title={`${name} is ${status === "None" ? "Not Associated" : status}`}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!viewOnlyStatus) {
              setAnchorEl(e.currentTarget);
            }
          }}
          sx={{ ...(viewOnlyStatus && { cursor: "default" }) }}
        >
          <Stadium fontSize="large" color={statusColor[status]} />
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title={`Are you sure you want to remove ${name} from ${venue.name}?`}
        onConfirm={(e) => {
          e.stopPropagation();
          handleUpdate(APPLICANT_VENUE_STATUS_ENUM.NONE);
          toggleConfirmationModal(false);
        }}
      />
    </>
  );
};

export default VenueStatusButton;
