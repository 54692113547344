import axios from "axios";

import { baseAxios } from "config";

const fetchSingleVenueFromSlug = async ({ slug }) => {
  try {
    const res = await baseAxios.get(`/venues/slug/${slug}`);

    return res?.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return null;
    console.log(err);
    return { code: err?.code, data: null, message: err?.toString() };
  }
};

export default fetchSingleVenueFromSlug;
