import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import { Grid, Card, Stack, Button, Switch, useMediaQuery, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@mui/styles";
import FormField from "layouts/applications/wizard/components/FormField";
import pxToRem from "assets/theme/functions/pxToRem";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import { useMutation } from "react-query";
import { profileDirectDepositSchema } from "data/profileDirectDeposit";
import { PatternFormat } from "react-number-format";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import ConfirmDialog from "components/ConfirmDialog";
import GenericModal from "components/GenericModal";
import directDepositUpdate from "layouts/pages/profile/actions/directDepositUpdate";

const useStyle = makeStyles({
    logoImage: {
        objectFit: "contain",
    },
    editor: {
        height: 170,
    },
    dropzone: {
        minHeight: 70,
    },
    previewButton: {
        padding: 0,
    },
    words: {
        wordWrap: "break-word",
    },
    input: {
        fontSize: `${pxToRem(16)}`,
        backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
        border: "solid 1px black",
        borderRadius: "10px",
    },
    error: {
        fontSize: "0.75rem",
    }
});

const DirectDepositRequest = ({ setBottomPanel, currentApplicant, enqueueSnackbar }) => {
    const classes = useStyle();
    const [changePhoneModalOpen, setChangePhoneModalOpen] = useState(false);
    const [newPhone, setNewPhone] = useState(null);
    const [editorValue, setEditorValue] = useState(null);

    const [isModified, setIsModified] = useState(false);
    const resolver = useYupValidationResolver(profileDirectDepositSchema);
    // If there is no current user, let's route the user to login
    const navigate = useNavigate();
    const { currentLoggedUser, setCurrentLoggedUser, isLoggedIn } = useAppContextController();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    // Confirm Modal
    const [confirmModal, setConfirmModal] = useState({
        modalFor: "",
        isOpen: false,
        title: "",
        bodyText: "",
        response: false,
    });

    const {
        handleSubmit,
        setValue,
        control,
        getValues,
        trigger,
        unregister,
        formState: { errors, isSubmitting },
    } = useForm({ resolver, mode: "onBlur" });


    const modalTitle = (
        <MDTypography
            variant="h5"
            color="dark"
            sx={{ mt: 2, mb: 1, ml: 2 }}

        >
            Update Phone
        </MDTypography>
    )

    const modalBody = (<MDTypography
        variant="body2"
    >
        Would you like to update your primary phone to {newPhone}
    </MDTypography>)

    const okButton = (<>
        <Button variant="contained" style={{ color: "white" }}
            onClick={
                () => {
                    setChangePhoneModalOpen(false)
                    setValue("updatePrimaryPhone", "Yes")
                    setValue("primaryPhone", newPhone)
                }
            } >
            Yes
        </Button>
        <Button color="error" variant="contained" style={{ color: "white" }}
            onClick={() => {
                setChangePhoneModalOpen(false)
                setValue("updatePrimaryPhone", "No")
                setValue("primaryPhone", " ")
                trigger("primaryPhone")
            }} >
            No
        </Button>

    </>)



    const handleChangeType = (e) => {
        setValue("changeType", e.target.id);
    };

    const handlePhoneChange = (e) => {
        const trimValueLength = e.target.value.replace(/_/g, "").replace(/ /g, "").replace("(", "").replace(")", "").length

        if (trimValueLength === 10) {
            setNewPhone(e.target.value)
            setChangePhoneModalOpen(true)
        }
    }

    const directDepositUpdateMutation = useMutation(directDepositUpdate, {
        onError: (error) =>
            enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
        onSuccess: (_, data) => {
            enqueueSnackbar("Direct Deposit Information has been sent to Payroll", { variant: "success" });
            setBottomPanel(null)
        },
    });

    const directDepositChange = async (values) => {
        let data;
        if (getValues("updatePrimaryPhone") === "Yes") {
            data = values;
        }
        else {

            const { primaryPhone, ...rest } = values
            data = rest;

        }
        await directDepositUpdateMutation.mutateAsync({
            id: currentLoggedUser._id,
            data
        });
    };

    const onCancel = () => {
        setConfirmModal({
            isOpen: true,
            title: "Data has changed!",
            bodyText: "Please Confirm to abandon Deposit Changes",
            response: false,
        });
    }


    useEffect(() => {
        if (confirmModal.response) {
            setBottomPanel(null)
        }
    }, [confirmModal.response])

    return (
        <>
            <Card >

                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <MDTypography variant="h5">
                        DIRECT DEPOSIT CHANGE REQUEST
                    </MDTypography>

                </MDBox>
                <MDBox p={2}>
                    <MDBox mb={2}>
                        <MDTypography variant="body2">
                            For security purposes, we do not allow direct deposit changes online, so please submit this form to request that a payroll representative contact you for changes.
                        </MDTypography>
                    </MDBox>
                    <form onSubmit={handleSubmit(directDepositChange)}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <MDTypography fontWeight="bold" variant="body2"> Primary Phone</MDTypography>
                            <MDTypography variant="body2">{currentApplicant?.phone}</MDTypography>
                        </Stack>

                        <Controller
                            name="primaryPhone"
                            control={control}
                            render={({ field }) => (
                                <PatternFormat
                                    format="(###) ### ####"
                                    allowEmptyFormatting
                                    mask="_"
                                    {...field}
                                    onChange={(e) => handlePhoneChange(e)}
                                    customInput={FormField}
                                />
                            )}
                        />

                        <Grid container spacing={2} mt={2}>
                            <Grid item sm={2}>
                                <MDTypography fontWeight="bold" variant="body2">Type of change:</MDTypography>
                            </Grid>
                            <Controller
                                name="changeType"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Grid item sm={4}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Switch
                                                    id="directDeposit"
                                                    checked={field.value === "directDeposit"}

                                                    onChange={(e, v) => handleChangeType(e)}
                                                />
                                                <MDTypography variant="body2">Direct Deposit</MDTypography>
                                            </Stack>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Switch
                                                    id="payrollDebit"
                                                    checked={field.value === "payrollDebit"}

                                                    onChange={(e, v) => handleChangeType(e)}
                                                />
                                                <MDTypography variant="body2">Payroll Debit Card</MDTypography>
                                            </Stack>
                                        </Grid>
                                    </>
                                )
                                }
                            />
                        </Grid>
                        {errors?.changeType && (
                            <MDTypography className={classes.error} color="error">
                                {errors?.changeType.message}
                            </MDTypography>
                        )}
                        <MDBox mb={6}>

                            <MDTypography fontWeight="bold" variant="body2" textTransform="capitalize">
                                Other comments or notes:
                            </MDTypography>
                            <MDEditor
                                className={classes.editor}
                                value={editorValue || ""}
                                onChange={(e) => {
                                    setEditorValue(e);
                                    setValue("comments", e);
                                }}
                            />
                        </MDBox>
                        <MDBox p={3} display="flex" justifyContent="end" mt={isSmallScreen && 12}>
                            {isSubmitting
                                ?
                                <Grid display={isSmallScreen && "flex"} justifyContent={isSmallScreen && "center"} item xs={12} sm={3} >
                                    <CircularProgress color="info" />
                                </Grid>
                                :
                                <Grid container spacing={3} justifyContent="end">
                                    <Grid item xs={12} sm={3} >
                                        <MDButton type="submit" color="info" variant="contained" fullWidth>
                                            <MDTypography variant="body2" color="white" >
                                                Send Message
                                            </MDTypography>
                                        </MDButton>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <MDButton color="error" variant="contained" fullWidth onClick={onCancel}>
                                            <MDTypography variant="body2" color="white">
                                                Cancel
                                            </MDTypography>
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            }
                        </MDBox>
                    </form>
                </MDBox>
                <GenericModal
                    open={changePhoneModalOpen}
                    setOpen={setChangePhoneModalOpen}
                    body={modalBody}
                    header={modalTitle}
                    buttons={okButton}
                    width={isSmallScreen ? "90%" : "20%"}
                />
                <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
            </Card >
        </>
    )
}

export default DirectDepositRequest