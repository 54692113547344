import axios from "axios";

import { baseAxios } from "config";

const postEntityDropdown = async (body) => {
  try {
    const res = await baseAxios.post(`/control/dropdowns/`, body);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default postEntityDropdown;
