import { Icon, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";

import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import { JOB_APPLICANTS_OPTIONS } from "components/FilterButtons/options";
import updateApplicantStage from "layouts/pages/applicants/actions/updateApplicantStatus";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import useMinStageToOnboarding from "hooks/useMinStageToOnboarding";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const ApplicantStageButton = ({
  applicant,
  job,
  viewOnly = false,
  refresh,
  onChangeStatus,
  sx,
  parent = "companyJobs",
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmModalApplicant, setConfirmModalApplicant] = useState(initialValueModal);
  const { minStage, allowedStages, disallowedStages } = useMinStageToOnboarding();

  const changeStageMutation = useMutation(updateApplicantStage, {
    onSuccess: (_, { applicantId, status }) => {
      if (refresh) {
        refresh({
          applicantId: applicant?._id,
          applicantStatus: status,
        });
      }
      if (onChangeStatus) {
        onChangeStatus({
          applicantId: applicant?._id,
          applicantStatus: status,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "applicantEmploymentStageChangeMutation",
  });

  const closeModalHandler = () => setConfirmModalApplicant(initialValueModal);

  const handleUpdate = useCallback(
    (newStatus, sendOnboardingMessage) => {
      changeStageMutation.mutate(
        {
          applicantId: applicant._id,
          status: newStatus,
          jobSlug: job?.jobSlug,
          sendOnboardingMessage,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(
              `${applicant.firstName} ${applicant.lastName} has been set to: ${newStatus} for ${job?.jobSlug}`,
              { variant: "success" }
            );
          },
        }
      );
    },
    [
      applicant._id,
      applicant.firstName,
      applicant.lastName,
      changeStageMutation,
      job?.jobSlug,
      enqueueSnackbar,
    ]
  );

  const onSubmitFunc = useCallback(
    (newStatus) => {
      const canSendOnboardingMessage =
        // Only send onboarding message if the onboarding hasn't been done yet
        !applicant?.acknowledged &&
        // Only send onboarding message when changing status to Screened (by default)
        newStatus === minStage;

      if (!canSendOnboardingMessage) {
        return handleUpdate(newStatus);
      }

      return setConfirmModalApplicant({
        isOpen: true,
        title: "Applicant stage changed",
        bodyText: `The applicant stage has been changed to ${minStage}. Do you want to send an onboarding invitation to the applicant?`,
        response: false,
        description: `The applicant stage has been changed to ${minStage}. Do you want to send an onboarding invitation to the applicant?`,
        negativeBtn: {
          label: "Cancel",
          fn: closeModalHandler,
          isShown: true,
        },
        negativeAltBtn: {
          label: "Don't Send",
          fn: async () => {
            handleUpdate(newStatus);
            closeModalHandler();
          },
          isShown: true,
        },
        positiveBtn: {
          label: "Send",
          fn: async () => {
            handleUpdate(newStatus, true);
            closeModalHandler();
          },
          isShown: true,
        },
      });
    },
    [applicant?.acknowledged, handleUpdate]
  );

  const currentStatus = useMemo(() => {
    if (parent === "companyJobs") {
      const foundJob = (applicant.jobs ?? []).find((applJob) => applJob.jobSlug === job.jobSlug);
      return foundJob?.applicantStatus ?? "New";
    }
    return applicant.applicantStatus ?? "New";
  }, [applicant.applicantStatus, applicant.jobs, job.jobSlug, parent]);

  const options = useMemo(
    () =>
      JOB_APPLICANTS_OPTIONS.filter((item) => item.value !== "all").map((item) => ({
        text: item.title,
        icon: item.icon,
        handleClick: () => onSubmitFunc(item.value),
        disabled: item.value === currentStatus,
      })),
    [currentStatus, onSubmitFunc]
  );

  const optionsIndexed = useMemo(() => {
    const result = {};
    JOB_APPLICANTS_OPTIONS.forEach((opt) => {
      result[opt.value] = opt;
    });
    return result;
  }, []);

  const applicantIcon = useMemo(() => {
    let result = optionsIndexed.New;
    result = optionsIndexed[currentStatus ?? "New"];
    return result;
  }, [currentStatus, optionsIndexed]);

  return (
    <>
      <Tooltip title="Applicant Stage Change">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!viewOnly) {
              setAnchorEl(e.currentTarget);
            }
          }}
          color={applicantIcon?.iconColor}
          sx={{ ...(viewOnly && { cursor: "default" }), ...sx }}
        >
          <Icon fontSize="large">{applicantIcon?.iconName ?? "add"}</Icon>
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
      {confirmModalApplicant?.isOpen ? (
        <ConfirmModal
          title={confirmModalApplicant.title}
          description={confirmModalApplicant.description}
          positiveBtn={confirmModalApplicant.positiveBtn}
          negativeBtn={confirmModalApplicant.negativeBtn}
          negativeAltBtn={confirmModalApplicant.negativeAltBtn}
          isOpen={confirmModalApplicant.isOpen}
          closeFn={closeModalHandler}
        />
      ) : null}
    </>
  );
};

export default ApplicantStageButton;
