import { Icon } from "@mui/material";

import CustomCard from "components/CustomCard";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import NotificationsTable from "../NotificationsTable";

const NotificationsTablePanel = () => {
  return (
    <CustomCard
      icon={<Icon fontSize="large">notifications</Icon>}
      cardTitle="Notifications"
      iconContainerSx={{
        background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
        color: "white !important",
      }}
      cardSx={{ width: "100%" }}
    >
      <FlexWrapper>
        <NotificationsTable />
      </FlexWrapper>
    </CustomCard>
  );
};

export default NotificationsTablePanel;
