import { baseAxios } from "config";

const axios = require("axios");

export default async function fetchTaskById(id) {
  try {
    const res = await baseAxios.get(`/tasks/id/${id}`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
}
