import { useMemo, useState } from "react";
import { Card, Grid } from "@mui/material";
import PollIcon from "@mui/icons-material/Poll";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import { makeStyles } from "@mui/styles";
import VenueIcon from "components/VenueIcon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import moment from "moment";
import { useQuery } from "react-query";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import { useAppContextController } from "context/AppContext";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import fetchCustomers from "api/companies/fetchCustomers";
import useVenueCache from "hooks/useVenueCache";
import ReportsTableActions from "./ReportsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 35,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});
const ReportsTable = () => {
  const classes = useStyle();
  const { company, venues, setVenues } = useAppContextController();
  const [customerData, setCustomerData] = useState({});
  const isCompany = company?.companyType === "Company";
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const columns = useMemo(
    () => [
      {
        title: "",
        field: isCompany ? "companySlug" : "logoUrl",
        customCell: (logoUrl, field, row) => {
          const cust = customerData[logoUrl] ? customerData[logoUrl] : {};
          return isCompany ? (
            <CompanyLogoSquare company={cust} defaultCompany={company} />
          ) : (
            <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />
          );
        },
      },
      {
        title: isCompany ? "Customer Name" : "Venue Name",
        field: isCompany ? "companyName" : "venueName",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Title", field: "title" },
      {
        title: "Job Posted Date",
        field: "postedDate",
        customCell: (date, field, row) => (
          <MDTypography fontSize="1rem">{moment(date).format("MM/DD/YYYY")}</MDTypography>
        ),
      },
      { title: "New", field: "new", customCell: (id, field, row) => 364 },
      { title: "atc", field: "atc", customCell: (id, field, row) => 67 },
      { title: "screened", field: "screened", customCell: (id, field, row) => 23 },
      { title: "preHire", field: "preHire", customCell: (id, field, row) => 5 },
      {
        title: "Job Actions",
        field: "_id",
        customCell: (id, field, row) => (
          <ReportsTableActions
          // id={id}
          // row={row}
          // setCurrentTemplate={setCurrentTemplate}
          // setModalInfo={setModalInfo}
          // setNavigateUrl={setNavigateUrl}
          // toggleEditModal={toggleEmailTemplateModal}
          // toggleConfirmationModal={toggleConfirmationModal}
          // setContext={setContext}
          />
        ),
      },
    ],
    [company, customerData, isCompany]
  );

  const options = {
    page: 1,
    limit: 5,
    order: "asc",
    orderBy: "title",
    filters: { status: "Active" },
    venues,
  };

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery(["jobs", options], () => fetchJobs(options), {
    enabled: !!isCompany || !!venues,
    onSuccess: async (data) => {
      if (!isCompany) return;
      const customers = {};
      try {
        // Get all company slugs
        let companySlugs = data?.data?.map((comp) => comp.companySlug);
        // Remove duplicates
        companySlugs = [...new Set(companySlugs)];

        const results = await fetchCustomers({
          filters: { slug: companySlugs.join(";") },
          page: 1,
          limit: 0,
        });

        // Do something with all fetched customer data

        // Assuming that 'results' is an array of objects and each object has a 'slug' property
        (results?.data ?? []).forEach((cstmr) => {
          customers[cstmr.slug] = cstmr;
        });
        setCustomerData(customers);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          // logoutAuth0User();
        }
      }
    },
  });

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="dark"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <PollIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Jobs Stages Report 11.17.23
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox justifyContent="end" display="flex">
        <MDBox mr={2}>
          <DownloadIcon color="info" fontSize="large" />
        </MDBox>
        <MDBox mr={2}>
          <PictureAsPdfIcon color="info" fontSize="large" />
        </MDBox>
        <MDBox mr={2}>
          <DonutSmallIcon color="info" fontSize="large" />
        </MDBox>
      </MDBox>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={{ data: jobs?.data ?? [], count: (jobs?.data ?? []).length }}
          // page={page}
          // limit={limit}
          // setPage={setPage}
          // setLimit={setLimit}
          // onRowClick={(row, e) => handleRowClick(row, e)}
          // order={order}
          // orderBy={orderBy}
          // toggleSort={toggleSort}
          // fetchAll={fetchAll}
          // isLoading={isLoading}
          // defaultSort="jobSlug"
          // idField="jobSlug"
        />
      </MDBox>
    </Card>
  );
};
export default ReportsTable;
