import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import MessageIcon from "@mui/icons-material/Message";
import TableAction from "components/TableAction";
import { useCallback } from "react";

import updateNotification from "api/notifications/updateNotification";
import MDBox from "components/MDBox";
import useSessionAuth from "hooks/useSessionAuth";

const NotificationTableActions = ({ row, refetch, toggleConfirmationModal }) => {
  const { logoutAuth0User } = useSessionAuth();

  const handleAction = useCallback(async () => {
    const body = {
      status: row?.status !== "read" ? "read" : "unread",
    };
    try {
      const response = await updateNotification({
        notificationId: row._id,
        body,
      });
      if (response.data.modifiedCount || response.data.acknowledged) {
        refetch();
      }
    } catch (err) {
      console.error(err);
      if (String(err).includes("401") || err?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [row]);

  return (
    <MDBox display="flex" columnGap="5px">
      <TableAction
        color={row?.status === "read" ? "success" : "info"}
        icon={row?.status === "read" ? <MarkUnreadChatAltIcon /> : <MessageIcon />}
        tooltip={row?.status === "unread" ? "Mark as Read" : "Mark as Unread"}
        clickAction={() => handleAction()}
      />
      <TableAction
        color="dark"
        icon="delete-forever"
        clickAction={() => toggleConfirmationModal(true)}
      />
    </MDBox>
  );
};

export default NotificationTableActions;
