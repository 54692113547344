import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  AddCircleRounded as AddCircleRoundedIcon,
  Cancel as CancelIcon,
  Save,
} from "@mui/icons-material";
import moment from "moment";
import { Autocomplete, Box, Button, Grid, IconButton, Stack } from "@mui/material";
import GenericModal from "components/GenericModal";
import FormField from "layouts/applications/wizard/components/FormField";
import DataList from "components/DataList";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CreateInterviewModal from "../CreateInterviewModal";
import fetchInterviews from "../../actions/fetchInterviews";
import InterviewModalActions from "./IntervieModalActions";

const InterviewModal = ({
  isModalOpen,
  setModalOpen,
  currentCustomer,
  currentApplicant,
  currentJob,
  onSaveStatus,
}) => {
  const options = { fetchAll: false, orderBy: "activityDate", order: "desc", limit: 100 };

  const fallbackJobOption = {
    ...(currentJob ?? {}),
    label: `${currentJob?.title} - ${currentJob?.companyName}`,
  };

  const [createInterviewModalOpen, setCreateInterviewModalOpen] = useState(false);
  const [selectedJobForm, setSelectedJobForm] = useState(null);
  const [errors, setErrors] = useState([]);

  // If currentCustomer is available, get list of jobs
  // Otherwise, try to use currentJob and lock the dropdown
  const allOptions = { ...options, filters: { companySlug: currentCustomer?.slug } };
  const { data: fetchedJobs, isLoading } = useQuery(
    ["customerJobs", allOptions],
    () => {
      return fetchCompanyJob(allOptions);
    },
    {
      select: (data) =>
        data?.data?.map((job) => ({
          ...job,
          label: `${job.title} - ${job.companyName}`,
        })),
      enabled: !!currentCustomer,
    }
  );

  function sortByDate(a, b) {
    if (a.eventDate < b.eventDate) {
      return -1;
    }
    if (a.eventDate > b.eventDate) {
      return 1;
    }
    return 0;
  }

  const selectedJob = !currentJob ? selectedJobForm : fallbackJobOption;

  const {
    data: fetchedInterviews,
    isLoading: isLoadingInterviews,
    refetch: refetchInterviews,
  } = useQuery(
    ["customerJobsInterviews", selectedJob?.jobSlug],
    () => {
      return fetchInterviews(selectedJob?.jobSlug);
    },
    {
      select: (data) => data?.data?.sort(sortByDate),
      enabled: !!selectedJob?.jobSlug,
    }
  );

  const handleChangeStatus = (action) => {
    if (action === "ADD" && onSaveStatus) onSaveStatus();
    refetchInterviews();
  };

  const handleCreateInterview = () => handleChangeStatus("ADD");

  useEffect(() => {
    setSelectedJobForm(null);
    setErrors([]);
  }, [isModalOpen]);

  const openCreateInterviewModal = () => {
    if (selectedJob !== null) {
      setCreateInterviewModalOpen(true);
    } else {
      setErrors(["noSelectedJob"]);
    }
  };

  const setNavigateUrl = useNavigate();

  const renderRow = (row, idx) => {
    const date = `${moment(row.eventDate).format("ddd, MM/DD/YYYY hh:mma")} - ${moment(
      row.eventEndTime
    ).format("hh:mma")}`;
    return (
      <Grid container spacing={2}>
        <Grid item sm={3}>
          {date}
        </Grid>
        <Grid item sm={3}>
          {currentCustomer?.name}
        </Grid>
        <Grid item sm={3}>
          {selectedJob?.title}
        </Grid>
        <Grid item sm={3}>
          <InterviewModalActions
            row={row}
            setNavigateUrl={setNavigateUrl}
            currentApplicant={currentApplicant}
            selectedJob={selectedJob}
            onChangeInterviewStatus={handleChangeStatus}
          />
        </Grid>
      </Grid>
    );
  };
  const modalBody = (
    <>
      <Button
        sx={{
          position: "absolute",
          top: "0%",
          right: "0%",
          m: 1,
          p: 0,
        }}
        onClick={() => {
          setSelectedJobForm(null);
          setErrors([]);
          setModalOpen(false);
        }}
      >
        <CancelIcon color="secondary" />
      </Button>
      <Grid container>
        <Grid item sm={3}>
          <Autocomplete
            options={currentCustomer ? fetchedJobs ?? [] : [fallbackJobOption]}
            value={selectedJob}
            onChange={(e, v) => {
              setSelectedJobForm(v);
              setErrors([]);
            }}
            disabled={!!currentJob}
            renderInput={(params) => <FormField label="Job Name" {...params} />}
          />
          <MDTypography color="error">
            {errors.includes("noSelectedJob") && "You must enter a Job Name to add an Interview"}
          </MDTypography>
        </Grid>
        {currentCustomer ? (
          <Grid item sm={3} ml={2}>
            <FormField label="Customer Name" value={currentCustomer?.name} disabled />
          </Grid>
        ) : null}
      </Grid>
      <Box mt={2}>
        <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography component="label" variant="body2">
            Group Interviews
          </MDTypography>
          <IconButton color="info" onClick={() => openCreateInterviewModal()}>
            <AddCircleRoundedIcon fontSize="large" />
          </IconButton>
        </Box>
        <DataList
          isLoading={isLoadingInterviews}
          renderRow={renderRow}
          data={fetchedInterviews ?? []}
          onRowClick={() => {}}
          greybar
          divider
          tableHeight={420}
          height={500}
          scrollY
        />
      </Box>
      {onSaveStatus && (
        <Grid item xs={12} mt={10} textAlign="end">
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              style={{ color: "white" }}
              sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
              onClick={onSaveStatus}
            >
              Change status only
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );

  const header = (
    <>
      <MDBox display="flex" py={1}>
        <MDTypography variant="h5">Interviews</MDTypography>
        <MDBox display="flex" alignItems="center" px={7}>
          <MDTypography color="info" variant="h6" pr={0.5}>
            Applicant:
          </MDTypography>
          <MDTypography variant="h6">
            {[currentApplicant?.firstName, currentApplicant?.lastName].join(" ")}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );

  return (
    <>
      <GenericModal
        open={isModalOpen}
        setOpen={setModalOpen}
        header={header}
        body={modalBody}
        width="60%"
      />

      <CreateInterviewModal
        currentCustomer={currentCustomer}
        setOpen={setCreateInterviewModalOpen}
        open={createInterviewModalOpen}
        selectedJob={selectedJob}
        applicants={[currentApplicant]}
        onCreate={handleCreateInterview}
      />
    </>
  );
};

export default InterviewModal;
