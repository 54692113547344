import { useAppContextController } from "context/AppContext";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import SalesPanel from "components/SalesPanel/SalesPanel";

const PartnerSales = () => {
    const { currentPartner, setCurrentPartner, setCurrentFormDirty } = useAppContextController();

    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const updatePartnerMutation = useMutation(updatePartner, {
        onError: (err) =>
            enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
        onSuccess: async (_, { data }) => {
            await queryClient.invalidateQueries("partners");
            if (data.status === "Deleted") {
                setCurrentPartner({});
                enqueueSnackbar("Partner was archived", { variant: "success" });
            } else {
                setCurrentPartner({ ...currentPartner, ...data, modifiedDate: new Date() });
                setCurrentFormDirty(false);
                enqueueSnackbar("Partner has been updated!", { variant: "success" });
            }
        },
    });

    const editPartnerHandler = async (values) => {
        if (!currentPartner._id) {
            enqueueSnackbar("Cannot Update Partner -- No ID Present!", { variant: "error" });
            return;
        } await updatePartnerMutation.mutateAsync({
            id: currentPartner._id,
            data: values,
        });
    };


    return <SalesPanel currentData={currentPartner} onUpdate={editPartnerHandler} page="partners" />;
};

export default PartnerSales;
