import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import generateFiltersFromString from "utils/generateFiltersFromString";
import generateFilterString from "utils/generateFilterString";

const useHandleBackButtonSubGrid = ({
  parentPage,
  parentFilters,
  setPage,
  setFilters,
  page,
  filters,
  navigateFunc,
  navigateWithBackFunc,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (parentFilters && setFilters) {
      setFilters(parentFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentFilters]);

  useEffect(() => {
    if (parentPage && setPage) {
      setTimeout(() => setPage(parentPage), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentPage]);

  const navigateWithBack = useMemo(
    () =>
      navigateWithBackFunc
        ? (url) => navigateWithBackFunc(url, page, filters)
        : (url) => navigateFunc(url),
    [filters, navigateFunc, navigateWithBackFunc, page]
  );

  return { navigateWithBack, navigateWithoutBack: navigateFunc };
};

export default useHandleBackButtonSubGrid;
