import AssessmentIcon from "@mui/icons-material/Assessment";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ArrowLeftIconSvg, ArrowRightIconSvg, FileSaveIconSvg } from "assets/svg/icons";
import colors from "assets/theme/base/colors";
import StatModal from "components/JobsReportPanel/StatModal";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PanelSelectorBox from "../PanelSelectorBox";

const stats = [
  {
    name: "New Applies",
    number: 10,
  },
  {
    name: "Submittals",
    number: 5,
  },
  {
    name: "Interviews",
    number: 2,
  },
  {
    name: "Fills",
    number: 0,
  },
];

const ProfileReport = ({ data }) => {
  const [statModalOpen, setStatModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const renderCustomYAxisTick = ({ x, y, payload }, icons) => {
    return (
      <foreignObject y={y - 20} width={150} height={40}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <p>{icons?.[payload.index]?.icon}</p>
          <p style={{ marginLeft: "15px", fontSize: "16px" }}> {icons?.[payload.index]?.name}</p>
        </div>
      </foreignObject>
    );
  };

  const CustomBarValue = ({ x, y, height, value }) => (
    <text
      x={x}
      dx={height * 0.35}
      y={y}
      dy={height * 0.7}
      fill="white"
      textAnchor="start"
      fontSize={Math.min(20, height * 0.7)}
      fontWeight="lighter"
    >
      {value}
    </text>
  );

  return (
    <PanelSelectorBox
      title="User Report Data"
      icon={<AssessmentIcon />}
      iconColor="violet"
      cardVariant="outlined"
    >
      <Box style={{ padding: " 10px 25px 20px 25px", width: "100%", height: "100%" }}>
        <Box style={{ width: "100%" }}>
          <Box style={{ display: "flex" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ color: colors.dark.focus, fontSize: "22px", fontWeight: "600px" }}
              >
                Status:
              </Typography>
              <Typography
                style={{
                  color: colors.info.main,
                  marginLeft: "10px",
                  fontWeight: "600",
                  fontSize: "22px",
                }}
              >
                Logged In
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box style={{ width: "100%", position: "relative", marginTop: "10px" }}>
          <Box
            style={{
              width: "100%",
              position: "relative",
              padding: "10px 0",
              margin: "5px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton>
              <ArrowLeftIconSvg />
            </IconButton>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 32px",
              }}
            >
              <Typography style={{ color: "#000" }}>User Weekly Report</Typography>
            </Box>
            <IconButton>
              <ArrowRightIconSvg />
            </IconButton>
            <Button
              sx={{ display: "flex", position: "absolute", right: "0", padding: "0", margin: "0" }}
            >
              <Typography style={{ display: "flex", fontSize: "16px", marginRight: "3px" }}>
                EXPORT
              </Typography>
              <FileSaveIconSvg />
            </Button>
          </Box>
        </Box>
        <Grid item xs={12} sx={{ marginTop: "5px" }}>
          <Grid container justifyContent="center" spacing={2}>
            {stats.map((stat) => (
              <Grid key={stat?.name} item xs={3}>
                <Box
                  sx={{
                    backgroundColor: colors.violet.focus,
                    borderRadius: "10px",
                    paddingLeft: "14px",
                    height: "71px",
                    paddingRight: "6px",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalTitle(stat?.name);
                    setStatModalOpen(true);
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <MDTypography
                      sx={{
                        margin: "0",
                        padding: "0",
                        color: colors.white.focus,
                        marginTop: "5px",
                      }}
                    >
                      {stat?.number}
                    </MDTypography>
                    <InfoIcon sx={{ color: colors.white.focus }} />
                  </Box>
                  <MDTypography sx={{ margin: "0", padding: "0", color: colors.white.focus }}>
                    {stat?.name}
                  </MDTypography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            background: colors.white.focus,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            padding: "20px 23px 20px 30px",
            border: "0.2px solid  #AEAEAE",
            marginTop: "25px",
            flexShrink: 0,
            borderRadius: "4px",
          }}
        >
          <ResponsiveContainer aspect={2.4}>
            <BarChart data={data} layout="vertical">
              <XAxis type="number" axisLine={false} tick={null} />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
                tick={(props) => renderCustomYAxisTick(props, data)}
                interval={0}
                width={130}
              />
              <Tooltip />
              <Bar
                dataKey="value"
                radius={[18, 18, 18, 18]}
                minPointSize={50}
                barSize={24}
                width={270}
              >
                {data.map((entry) => (
                  <>
                    <Cell key={entry?.id} fill={entry?.color} />
                    <LabelList content={<CustomBarValue />} />
                  </>
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Box>
      <StatModal
        open={statModalOpen}
        setOpen={setStatModalOpen}
        title={modalTitle}
        data={[
          {
            name: "mon",
            value: 2,
          },
          {
            name: "tue",
            value: 2,
          },
          {
            name: "wed",
            value: 0,
          },
          {
            name: "thu",
            value: 1,
          },
          {
            name: "fri",
            value: 3,
          },
          {
            name: "sat",
            value: 0,
          },
          {
            name: "sun",
            value: 2,
          },
        ]}
      />
    </PanelSelectorBox>
  );
};

export default ProfileReport;
