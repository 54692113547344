import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell, & .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "lightblue !important",
      },
      "&.Mui-selected": {
        backgroundColor: "lightblue !important",
        border: "none !important",
      },
    },
  },
});

export { useStyles };
