import { Tooltip } from "@mui/material";
// import TableCell from "@mui/material/TableCell";
import { useNavigate } from 'react-router-dom';
import { useAppContextController } from "context/AppContext";

const VenueIcon = ({ logoUrl, slug, name, imageWidth, imageHeight, title,
    goToEnabled = true, setOpenVenueModal = null }) => {
    const { userType } = useAppContextController();
    const navigate = useNavigate()
    const handleClick = (e) => {
        if (goToEnabled) e.stopPropagation();
        if (userType !== "User" && goToEnabled) navigate(`/venues/${slug}/action/venueinfo`);
        else if (userType === "User") {
            if (setOpenVenueModal) setOpenVenueModal();
        }
    }
    return (
        <Tooltip title={!goToEnabled ? name : `Go to ${name} information`}>
            <button type="button" onClick={(e) => handleClick(e)} style={{ border: "none", outline: "none", background: "none", cursor: "pointer" }} >
                <img
                    width={imageWidth || "50"}
                    height={imageHeight || "50"}
                    src={(logoUrl || "")}
                    alt={title || ""}
                />
            </button>
        </Tooltip>
    )
}

export default VenueIcon