import { baseAxios } from "config";

export const generatePartnerTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/partners-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generatePartnerChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/partners-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generatePartnerApplicantTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/partners-applicants-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generatePartnerApplicantChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/partners-applicants-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
