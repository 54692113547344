import { useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import useSort from "utils/useSort";
import { CalendarMonth } from "@mui/icons-material";
import { TableHead } from "@mui/material";
import { isAdmin } from "utils/helpers/roles";
import FilterButtons from "components/FilterButtons";
import { JOB_INTERVIEWS_OPTIONS } from "components/FilterButtons/options";
import { useAppContextController } from "context/AppContext";
import InterviewRow from "layouts/pages/companyjobs/components/CompanyJobInterviews/InterviewRow";
import fetchInterviewApplicants from "layouts/pages/applicants/actions/fetchInterviewApplicants";
import DataList from "components/DataList";

const CompanyJobInterviews = ({ jobSlug, currentCustomer }) => {
  const { venues, currentApplicant, userType, company, currentLoggedUser } =
    useAppContextController();
  const [dataFilter, setDataFilter] = useState({ status: "all" });

  const [page, setPage] = useState(1);
  const { order, orderBy, toggleSort } = useSort("applicationDate", "desc");
  const options = useMemo(() => {
    return {
      fetchAll: false,
      orderBy: "event.eventDate",
      order: "desc",
      limit: 10,
      useElemMatch: true,
      interviewJobSlug: jobSlug,
    };
  }, [jobSlug]);

  const fetchOptions = useMemo(() => {
    return {
      ...options,
      interviewStatus: dataFilter.status,
      page,
      createAgent: currentLoggedUser._id,
    };
  }, [options, dataFilter.status, page, currentLoggedUser._id]);

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
    fetchNextPage,
  } = useInfiniteQuery(
    ["jobInterviewApplicants", fetchOptions],
    ({ queryKey, pageParam }) =>
      fetchInterviewApplicants({ ...fetchOptions, page: pageParam ?? 1 }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
          return undefined;
        }

        const nextPage = lastPage?.pagination?.next?.page;
        return nextPage ?? false;
      },
    }
  );

  const fullData = useMemo(
    () =>
      applicants?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [applicants?.pages]
  );

  const header = <></>;

  const renderRow = (row) => (
    <InterviewRow row={row} refresh={refetchApplicants} currentCustomer={currentCustomer} />
  );

  const topButtonBar = (
    <TableHead
      sx={{
        height: "5rem",
        zIndex: 3,
        position: "sticky",
        top: 0,
        background: "white",
        width: "100%",
      }}
      display="flex"
      width="100%"
    >
      {isAdmin(userType) && (
        <FilterButtons
          filter={dataFilter}
          setFilter={setDataFilter}
          name="status"
          options={JOB_INTERVIEWS_OPTIONS}
          // direction="row-reverse"
        />
      )}
    </TableHead>
  );

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={fullData}
        icon={<CalendarMonth />}
        iconColor="success"
        title="Interviews"
        isLoading={isLoading}
        topButtonBar={topButtonBar}
        greybar
        divider
        header={header}
        tableHeight={450}
        height={850}
        scrollY
        computedHeight
        loadMore={fetchNextPage}
        infiniteData={applicants?.pages?.[applicants?.pages?.length - 1]}
      />
    </>
  );
};

export default CompanyJobInterviews;
