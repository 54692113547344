// @mui material components
import { useState } from "react";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import { makeStyles } from "@mui/styles";
import backgroundImage from "assets/images/BGImage1.png";
import brandWhite from "assets/images/SP-App-Store-Icon.png";

import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
// import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
// import fetchApplicantByCode from "layouts/pages/applicants/actions/fetchApplicantByCode";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
});

function VerificationMessage() {
  const { company, setCompany, setCompanyType } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const [verificationResult, setVerificationResult] = useState(null);
  //  const [email, setEmail] = useState(null);

  const classes = useStyle();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.has("email") ? params.get("email") : "";

  //   const updateVerified = async () => {
  //     // first we have to get the applicant id from the applicant code
  //     const app = await fetchApplicantByCode(applicantCode);
  //     if (app?.data) {
  //       const applicantId = app.data?._id.toString();
  //       setEmail(app.data?.email);
  //       // we need to update the database that shows this email as Verified
  //       const res = await updateApplicant({ applicantId, data: { verified: "Yes" } });
  //       setVerificationResult(res);
  //     }
  //   };

  //   useEffect(() => {
  //     if (applicantCode) updateVerified();
  //   }, [applicantCode]);

  const goToHome = () => {
    window.location.href = `https://${company?.companyUrl}`;
  };

  return (
    <MDBox position="relative" flexDirection="row">
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(rgba("#FFF", 0.3), rgba("#FFF", 0.3))}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          width: "100%",
          height: "100vh",
        }}
      >
        <Card
          sx={{
            display: "flex",
            maxWidth: "85%",
            my: 15,
            py: 2,
            px: 2,
          }}
        >
          <MDBox display="flex">
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="white"
                color="white"
                borderRadius="xl"
                mt={-4}
              >
                <img
                  src={brandWhite}
                  width="50"
                  height="50"
                  className={classes.logoImage}
                  alt={`${company?.name} Logo`}
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox p={2} alignItems="center" display="flex" justifyContent="space-between">
            {/* {verificationResult?.modifiedCount ? ( */}
            <MDBox mx={3} mt={-3}>
              <MDTypography variant="body2" fontWeight="medium" mb={4} textAlign="center">
                Account Creation Successful
              </MDTypography>
              <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                Check your email - {email || ""} - for a verification email.
              </MDTypography>
              <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                When you click on the Verify My Account link, you will be redirected to the login
                page. Thank you for creating your account!
              </MDTypography>
            </MDBox>
          </MDBox>
          <Button
            variant="contained"
            style={{ color: "white" }}
            sx={{ padding: "0.5rem 1rem", width: "20%", alignSelf: "end" }}
            onClick={() => goToHome()}
          >
            OK
          </Button>
        </Card>
      </MDBox>
    </MDBox>
  );
}

export default VerificationMessage;
