// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import ChatIcon from '@mui/icons-material/Chat';

const PartnersTableActions = ({
  id,
  slug,
  row,
  setCurrentPartner,
  setGenericModalOpen,
  setModalInfo,
  onOpenDeleteModal,
}) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    if (action === "delete") {
      onOpenDeleteModal(row);
    } else {
      setCurrentPartner(row);
      navigate(`/partners/${slug}/action/${action}`);
    }
    e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("partnersinfo", e)}
        tooltip="Partner Info"
      />
      <TableAction
        color="error"
        icon="people"
        showBadge
        badgeNumber={row?.membersCount ?? 0}
        clickAction={(e) => onClickHandler("partnersmembers", e)}
        tooltip="Members"
      />
      {/* <TableAction
        color="info"
        icon="stadium"
        clickAction={(e) => onClickHandler("partnersvenues", e)}
        tooltip="Associated Venues"
      />
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler("partnersevents", e)}
        tooltip="Associated Events"
      /> */}
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("partnersall", e)}
        tooltip="All Partner Panels"
      /> */}
      <TableAction
        color="error"
        icon="delete"
        clickAction={(e) => onClickHandler("delete", e)}
        tooltip="Delete Partner"
      />
    </div>
  );
};

export default PartnersTableActions;

PartnersTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
PartnersTableActions.propTypes = {
  id: PropTypes.string,
};
