import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PreviewIcon from "@mui/icons-material/Preview";
import { useAppContextController } from "context/AppContext";

const VenueEventsTableActions = ({
  eventUrl,
  setNavigateUrl,
  row,
  setActionSelected,
  setFilters,
  setOpen,
  setEventPreview,
  setCurrentEvent,
  getAndSetFullEvent,
}) => {
  const { userType, currentLoggedUser } = useAppContextController();

  const onClickHandler = (action, e) => {
    e.stopPropagation();

    const nodes = new URL(window.location).pathname.split("/");
    const baseRoute = nodes?.includes("venues")
      ? `/events/venue/${row?.venueSlug}/${row?.eventUrl}`
      : `/events/${row?.eventUrl}`;

    // setCurrentEvent(row);
    getAndSetFullEvent(row._id);
    setNavigateUrl(`${baseRoute}/action/${action}`);
  };

  const setPreviewOpen = (event, e) => {
    e.stopPropagation();
    setEventPreview(event);
    setOpen(true);
  };

  return (
    <span>
      {["Master", "Admin"].includes(userType) && (
        <>
          <TableAction
            color="info"
            icon="info"
            clickAction={(e) => onClickHandler("eventsinfo", e)}
            tooltip="Event Info"
          />
          &nbsp;&nbsp;
        </>
      )}
      <TableAction
        color="error"
        icon="people"
        clickAction={(e) => onClickHandler("eventsroster", e)}
        tooltip="Event Applicant Roster"
      />
      &nbsp;&nbsp;
      {["Master", "Admin", "Event Admin"].includes(userType) && (
        <>
          <TableAction
            color="secondary"
            icon="handshake"
            clickAction={(e) => onClickHandler("eventsrosterpartners", e)}
            tooltip="Event Partner Roster"
          />{" "}
          &nbsp;&nbsp;
        </>
      )}
      <TableAction
        color="info"
        icon={<PreviewIcon />}
        clickAction={(e) => setPreviewOpen(row, e)}
        tooltip="Venue Preview"
      />{" "}
      &nbsp;&nbsp;
      {/* <TableAction
                color="success"
                icon={<AccessTimeIcon color="success" />}
                clickAction={(e) => onClickHandler("eventstime", e)}
                tooltip="Event Time Roster"
            />  &nbsp;&nbsp; */}
      {/* {["Master", "Admin"].includes(userType) && (
        <>
          <TableAction
            color="dark"
            icon="public"
            clickAction={(e) => onClickHandler("eventsall", e)}
            tooltip="All Event Information"
          />
        </>
      )} */}
    </span>
  );
};

export default VenueEventsTableActions;

VenueEventsTableActions.defaultProps = {
  eventUrl: "",
};

// Typechecking props for the TableAction
VenueEventsTableActions.propTypes = {
  eventUrl: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
