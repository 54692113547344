import axios from "axios";
import { baseAxios } from "config";

const storeToken = async ({ token }) => {
  try {
    const response = await baseAxios.post(`/auth/token`, { token });

    return response;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default storeToken;
