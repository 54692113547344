import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SendMessage from "components/SendMessage";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import useSessionAuth from "hooks/useSessionAuth";
import { ApplicantsTable } from "./ApplicantsTable";

export const BulkMessagingModal = ({
  isOpen,
  toggleModal,
  currentEvent = null,
  messageContext,
  attachmentContext,
  applicants = [],
  recipientText,
  eventVenue,
  venueId,
  venueSlug,
  isLoading,
  jobId,
}) => {
  const { companyType } = useAppContextController();

  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [system, setSystem] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [allRows, setRows] = useState([]);
  const [recipient, setRecipient] = useState(null);
  const [pushNotificationSelection, setPushNotificationSelection] = useState(null);
  const getRecipientForValidity = (propVal) => {
    let tempId;
    selectedRecipients.forEach((selRec) => {
      const row = allRows.find((app) => !app[propVal] && app._id === selRec);
      if (row) tempId = row._id;
    });
    return tempId;
  };

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const getFirstRecipient = async (id) => {
      try {
        const rec = await fetchSingleApplicant({ applicantId: id });
        if (rec?.data) setRecipient(rec.data);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    };
    if (selectedRecipients?.length) {
      const rec = selectedRecipients[0];
      let emailId;
      let phoneId;
      if (email === false) emailId = getRecipientForValidity("email");
      if (text === false) phoneId = getRecipientForValidity("phone");

      if ((!emailId && phoneId) || (emailId && !phoneId)) {
        const id = emailId || phoneId;
        getFirstRecipient(id);
      }
      if (emailId && phoneId) setRecipient({ id: emailId });
      if (!emailId && !phoneId) {
        getFirstRecipient(rec);
      }
    } else setRecipient(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecipients, text, email, allRows]);

  // const filterRecipients = (recipients) => {
  //   if (email && text) return recipients.filter((item) => !!item.email || !!item.phone);
  //   if (email) return recipients.filter((item) => !!item.email);
  //   if (text) return recipients.filter((item) => !!item.phone);
  //   return [];
  // };

  const disableButton = useMemo(() => {
    if (pushNotificationSelection === "selected-individuals") {
      return !selectedRecipients.length || !recipient;
    }
    return (!selectedRecipients.length || !recipient) && !pushNotificationSelection;
  }, [selectedRecipients, recipient, pushNotificationSelection]);

  const recipientsTable = (
    <ApplicantsTable
      applicants={applicants}
      setRows={setRows}
      selectedRecipients={selectedRecipients}
      setSelectedRecipients={setSelectedRecipients}
      emailChecked={email}
      smsChecked={text}
      systemChecked={system}
      isLoading={isLoading}
    />
  );
  return (
    <>
      <GenericModal
        open={isOpen}
        setOpen={toggleModal}
        body={
          <Box>
            <SendMessage
              recipient={
                recipient || { _id: "", email: "", phone: "", firstName: "", lastName: "" }
              }
              companyType={companyType}
              setOpen={toggleModal}
              text={text}
              setText={setText}
              email={email}
              eventId={currentEvent?._id}
              setEmail={setEmail}
              system={system}
              setSystem={setSystem}
              isButtonDisabled={disableButton}
              bulk
              // selectedRecipients={filterRecipients(selectedRecipients)}
              selectedRecipients={selectedRecipients}
              messageContext={messageContext}
              venueId={venueId}
              venueSlug={venueSlug}
              pushNotificationSelection={pushNotificationSelection}
              setPushNotificationSelection={setPushNotificationSelection}
              recipientsTable={recipientsTable}
              attachmentContext={attachmentContext}
              eventVenue={eventVenue}
              jobId={jobId}
              applicants={applicants}
            />
          </Box>
        }
        header={
          <Box display="flex">
            <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
              <MDTypography variant="h5" color="dark">
                Send Bulk Message
              </MDTypography>
            </MDBox>
          </Box>
        }
      />
    </>
  );
};
