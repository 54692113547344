import axios from "axios";
import { baseAxios } from "config";

const addApplicantAttachment = async ({ applicantId, attachment, outsideMode = "" }) => {
  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;

    const res = await baseAxios.put(`/${outsideModeQuery}applicants/${applicantId}/attachment`, {
      attachment,
    });
    return res.data;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export default addApplicantAttachment;
