import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";

const PartnerApplicantsTableActions = ({
  id,
  row,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setMembersModalOpen,
  setModalInfo,
  company,
  setMemberNotesListOpen,
}) => {
  const navigate = useNavigate();
  const route = "partners";
  const actionroute = "partners";

  const canSendMessage = row?.email || row?.phone;

  const onClickHandler = (action, e) => {
    switch (action) {
      case "partnersnotes":
        e.stopPropagation();
        setCurrentApplicant(row);
        setMemberNotesListOpen(true);

        break;
      case "partnersinfo":
        e.stopPropagation();
        setCurrentApplicant(row);
        setMembersModalOpen(true);

        break;

      case "partnersmessage":
        e.stopPropagation();
        setCurrentApplicant(row);
        if (canSendMessage) setGenericModalOpen(true);

        break;

      default:
        break;
    }
  };

  return (
    <span>
      &nbsp;&nbsp;&nbsp;
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler(`${actionroute}info`, e)}
        tooltip="Applicant Info"
      />
      <TableAction
        color={canSendMessage ? "warning" : "secondary"}
        icon="email"
        clickAction={(e) => onClickHandler(`${actionroute}message`, e)}
        tooltip={
          canSendMessage
            ? `Send a message to: ${row?.firstName} ${row?.lastName}`
            : "No email associated with this member"
        }
      />
      <TableAction
        color="info"
        icon="description"
        showBadge
        badgeNumber={row?.notes?.length ?? 0}
        clickAction={(e) => onClickHandler(`${actionroute}notes`, e)}
        tooltip={`View and add note for: ${row?.firstName} ${row?.lastName}`}
      />
    </span>
  );
};

export default PartnerApplicantsTableActions;

PartnerApplicantsTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
PartnerApplicantsTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
