// Creates the API parameters from the input passed in
import generateFilterString from "./generateFilterString";

const createApiParams = ({
  page = 1,
  limit = 5,
  order,
  orderBy,
  fetchAll,
  filters,
  orderByOverride,
}) => {
  const filtersString = generateFilterString(filters);
  const paginationQuery = !fetchAll ? `page=${page}&limit=${limit}` : "";

  let sortQuery = "";
  if (orderByOverride) {
    sortQuery = `sort=${orderByOverride}`;
  } else {
    sortQuery = orderBy ? `sort=${orderBy}${order ? `:${order}` : ""}` : "";
  }
  const filterQuery = filtersString ? `filter=${filtersString}` : "";

  const queryParts = [];
  if (paginationQuery) queryParts.push(paginationQuery);
  if (filterQuery) queryParts.push(filterQuery);
  if (sortQuery) queryParts.push(sortQuery);
  const queryString = `?${queryParts.join("&")}`;

  return { paginationQuery, sortQuery, filterQuery, queryString };
};

export default createApiParams;
