export const NOTIFICATION_ACTIONS = {
  NOTIFICATION_INFO: "notificationInfo",
};

export const notificationInitialState = {
  notification: {},
  notifications: [],
  activeActions: [],
  tableFilter: {},
  refetchTableData: null,
};

const notificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION":
      return {
        ...state,
        notification: action.data,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.data,
      };
    case "SET_ACTIVE_ACTIONS":
      return {
        ...state,
        activeActions: action.data,
      };
    case "SET_TABLE_FILTER":
      return {
        ...state,
        tableFilter: action.data,
      };
    case "SET_REFETCH":
      return {
        ...state,
        refetchTableData: action.fn,
      };
    default:
      return state;
  }
};

export default notificationReducer;
