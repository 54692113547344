import { useSnackbar } from "notistack";
import { useState } from "react";
import { queryClient, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import updateCompany from "api/companies/updateCompany";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";

import { Cancel as CancelIcon, Delete as DeleteIcon, Save, Visibility } from "@mui/icons-material";
import { Button } from "@mui/material";

import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import useCompanyStore from "stores/useCompanyStore";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const CompanyFormActions = ({
  isDirty,
  dirtyFields,
  isLoading,
  isLoadingCreate,
  resetChanges,
  currentCompany,
  sourceComponent,
  onPreview,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { slug, id } = useParams();
  const { isCustomer } = useCompanyStore((state) => ({
    isCustomer: state.isCustomer,
  }));
  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useState(initialValueModal);

  const { company, setCompany, setCompanyType, setCurrentCompany } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const closeModalHandler = () => setConfirmModal(initialValueModal);

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const deleteCompanyMutation = async () => {
    updateCompanyMutation(
      { companyId: currentCompany._id, body: { status: "Deleted", modifiedDate: new Date() } },
      {
        onSuccess: async (res) => {
          if (res.acknowledged) {
            setCurrentCompany({});
            closeModalHandler();
            enqueueSnackbar("Successfully Deleted Customer!", { variant: "success" });
            navigate("/customers");
            refetchCompany();
            await queryClient.invalidateQueries("companies");
          }
        },
        onError: (error) => enqueueSnackbar("Something went wrong!", { variant: "error" }),
      }
    );
  };

  const handleCancel = () => {
    setConfirmModal({
      title: !slug ? "You are leaving create mode" : "Data has changed!",
      description: !slug
        ? "Please Confirm to abandon new customer"
        : "Please confirm you want to abandon changes",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: () => {
          // if (!copyEventId) navigate(`/events/venue/${event?.venueSlug}`);
          resetChanges();
          if (!slug) navigate("/customers");
          enqueueSnackbar(!slug ? "Create customer abandoned!" : "Changes have been abandoned!", {
            variant: "warning",
          });
          closeModalHandler();
        },
        isShown: true,
      },
      isOpen: true,
    });
  };

  const handleDelete = () => {
    setConfirmModal({
      title: "Delete Customer",
      description: "Please confirm you want to DELETE this customer!",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: deleteCompanyMutation,
        isShown: true,
      },
      isOpen: true,
    });
  };

  const companyId = slug || id;

  return (
    <>
      <FlexWrapper gap="10px" flexWrap="nowrap" width="initial" justifyContent="flex-end">
        {!companyId && (
          <>
            <Button
              variant="text"
              type="button"
              disable={isLoadingCreate}
              onClick={() => handleCancel()}
              startIcon={<CancelIcon />}
              color="error"
              style={{ color: isLoadingCreate ? "#AEAEAE" : "#F44335", fontSize: 14 }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              type="button"
              disable={isLoadingCreate}
              startIcon={<Save />}
              style={{ color: isLoadingCreate ? "#AEAEAE" : "#4CAF50", fontSize: 14 }}
              onClick={async () => {
                await document
                  .getElementById("company-form")
                  .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
              }}
            >
              Create
            </Button>
          </>
        )}
        {!isDirty && companyId && currentCompany?.status !== "Deleted" && isCustomer() && (
          <Button
            variant="text"
            color="error"
            type="button"
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            style={{ color: "#F44335", fontSize: 14 }}
          >
            Delete Customer
          </Button>
        )}
        {companyId && (
          <>
            <Button
              variant="text"
              type="button"
              startIcon={<Visibility />}
              onClick={onPreview}
              color="error"
              style={{ color: "#1A73E8", fontSize: 14, lineHeight: 1.5 }}
            >
              Preview {sourceComponent}
            </Button>
            {isDirty && !isEmpty(dirtyFields) && (
              <>
                <Button
                  variant="text"
                  type="button"
                  disable={isLoading}
                  startIcon={<DeleteIcon />}
                  onClick={() => handleCancel()}
                  color="error"
                  style={{ color: isLoading ? "#AEAEAE" : "#F44335", fontSize: 14, lineHeight: 1.5 }}
                >
                  Discard
                </Button>
                <Button
                  variant="text"
                  type="button"
                  disable={isLoading}
                  startIcon={<Save />}
                  onClick={async () => {
                    await document
                      .getElementById("company-form")
                      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                  }}
                  style={{ color: isLoading ? "#AEAEAE" : "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
                >
                  Save
                </Button>
              </>
            )}
          </>
        )}
      </FlexWrapper>
      {confirmModal?.isOpen && (
        <ConfirmModal
          title={confirmModal.title}
          description={confirmModal.description}
          positiveBtn={confirmModal.positiveBtn}
          negativeBtn={confirmModal.negativeBtn}
          isOpen={confirmModal.isOpen}
          closeFn={closeModalHandler}
        />
      )}
    </>
  );
};

export default CompanyFormActions;
