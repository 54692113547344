import { Avatar, Button, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    maxWidth: 1170,
    padding: "2rem",
  },
};

const AIInterviewChatWindowMessage = ({
  right,
  isBot,
  message,
  isResponse,
  includeLinks,
  firstName,
  lastName,
  company,
  onPressUpdateInfo,
  onPressBackToHome,
}) => {
  const avatarText = `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`.toUpperCase();

  return (
    <Grid container item justifyContent={right ? "flex-end" : "flex-start"} pt={2}>
      <Grid item container maxWidth="60%" alignItems="flex-end">
        {!right && (
          <Grid display="flex">
            <Avatar
              src={
                isBot
                  ? `${getCommonBaseImageUrl(company)}/static/aiChatbotProfilePicture.png`
                  : null
              }
              alt={`${firstName} ${lastName} picture`}
              sx={{ height: "50px", width: "50px" }}
            >
              {avatarText}
            </Avatar>
          </Grid>
        )}
        <Grid
          container
          item
          justifyContent={right ? "flex-end" : "flex-start"}
          flexShrink={1}
          flexBasis={0}
          flexGrow={1}
          pl={right ? 0 : "8px"}
          pr={right ? "8px" : 0}
        >
          <MDBox
            sx={{
              backgroundColor: (theme) =>
                isResponse ? theme.palette.light.main : theme.palette.info.main,
              padding: "6px 10px 6px 10px",
              borderRadius: right ? "16px 16px 0 16px" : "16px 16px 16px 0",
              display: "inline-block",
            }}
          >
            <MDTypography
              variant="body2"
              fontWeight="regular"
              color={isResponse ? "dark" : "white"}
              display="inline-block"
            >
              {parse(message ?? "")}
            </MDTypography>
            {includeLinks && (
              <MDBox>
                <Button
                  component="button"
                  variant="text"
                  sx={{ padding: 0 }}
                  onClick={onPressUpdateInfo}
                >
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    color={isResponse ? "dark" : "white"}
                    display="inline-block"
                  >
                    UPDATE PROFILE
                  </MDTypography>
                </Button>
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  color={isResponse ? "dark" : "white"}
                  display="inline-block"
                  pl={1}
                  pr={1}
                >
                  |
                </MDTypography>
                <Button
                  component="button"
                  variant="text"
                  sx={{ padding: 0 }}
                  onClick={onPressBackToHome}
                >
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    color={isResponse ? "dark" : "white"}
                    display="inline-block"
                  >
                    BACK TO HOME
                  </MDTypography>
                </Button>
              </MDBox>
            )}
          </MDBox>
        </Grid>
        {right && (
          <Grid display="flex">
            <Avatar
              src={
                isBot
                  ? `${getCommonBaseImageUrl(company)}/static/aiChatbotProfilePicture.png`
                  : null
              }
              alt={`${firstName} ${lastName} picture`}
              sx={{ height: "50px", width: "50px" }}
            >
              {avatarText}
            </Avatar>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

// Setting default values for the props of CalendarDayModal
AIInterviewChatWindowMessage.defaultProps = {};

// Typechecking props of the CalendarDayModal
AIInterviewChatWindowMessage.propTypes = {};

export default AIInterviewChatWindowMessage;
