import { FormControlLabel, Radio, RadioGroup, Stack, Switch } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import pxToRem from "assets/theme/functions/pxToRem";
import FormErrors from "components/Form/FormErrors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { w4Schema } from "data/w4form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import CommonTaxFields from "components/FormFields/CommonTaxFields";
import CurrencyField from "components/FormFields/CurrencyField";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  displayText: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "none!important",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },
});

const OklahomaForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(w4Schema);
  const classes = useStyle();
  const [point5, setPoint5] = useState(0);
  const [fullName, setFullName] = useState(
    `${applicant?.firstName} ${applicant?.middleInitial} ${applicant?.lastName}`
  );
  const [exempt, setExempt] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
    reset,
  } = useForm({ resolver, mode: "onBlur" });

  const allowanceForYourself = watch("allowanceForYourself");
  const allowanceForSpouse = watch("allowanceForSpouse");
  const allowanceForDependents = watch("allowanceForDependents");
  const additionalAllowances = watch("additionalAllowances");

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.value, { shouldDirty: true });
  };
  const handleExemption = (e) => {
    setValue("exemption", e.target.id, { shouldDirty: true });
  };

  const onSubmit = async (data) => {
    const parsedData = {
      stateTaxForm: { state: "oklahoma", ...data },
    };
    updateApplicantAction(applicant._id, parsedData);
    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (applicant?.stateTaxForm) {
      reset(applicant.stateTaxForm);
    }
  }, [applicant]);

  useEffect(() => {
    const value =
      Number(allowanceForYourself || 0) +
      Number(allowanceForSpouse || 0) +
      Number(allowanceForDependents || 0) +
      Number(additionalAllowances || 0);

    setPoint5(value.toFixed(2));
  }, [allowanceForYourself, allowanceForSpouse, allowanceForDependents, additionalAllowances]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} selectedState="OK" />
      </Grid>

      <RadioGroup
        aria-labelledby={`filingStatus_${applicant?.venueSlug}`}
        name="filingStatus"
        {...(applicant?.stateTaxForm?.filingStatus
          ? { defaultValue: applicant?.stateTaxForm?.filingStatus }
          : {})}
        onChange={handleFilingStatusChange}
      >
        <Grid container>
          <Grid item xs={12} sm={4} display="flex" alignItems="center">
            <FormControlLabel value="Single" control={<Radio />} sx={{ marginRight: "0" }} />
            <MDTypography fontWeight="bold" fontSize="0.875rem">
              Single{" "}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} display="flex" alignItems="center">
            <FormControlLabel value="Married" control={<Radio />} sx={{ marginRight: "0" }} />
            <MDTypography fontWeight="bold" fontSize="0.875rem">
              Married{" "}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} display="flex" alignItems="center">
            <FormControlLabel
              value=" MarriedWithholdHigherSingleRate"
              control={<Radio />}
              sx={{ marginRight: "0" }}
            />
            <MDTypography fontWeight="bold" fontSize="0.875rem">
              Married, but withhold at higher Single rate
            </MDTypography>
          </Grid>
        </Grid>
      </RadioGroup>

      <MDBox>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2"> 1. Allowance For Yourself</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="allowanceForYourself"
              control={control}
              render={({ field }) => (
                <CurrencyField
                  disabled
                  step={1}
                  max={1}
                  defaultValue={1}
                  label=""
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8} display="flex" alignItems="center">
            <MDTypography variant="body2">
              {" "}
              2. Allowance For Your Spouse: Does your spouse work? Yes/No
            </MDTypography>
            <Controller
              name="allowanceForSpouse"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value === 1}
                  onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                  inputProps={{ "aria-label": "" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CurrencyField
              disabled
              label=""
              value={watch("allowanceForSpouse")}
              defaultValue={applicant?.allowanceForSpouse || 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              3. Allowance For Dependents: Enter the number of dependents you will claim on your tax
              return. Do not claim yourself of your spouse or dependents that your spouse or
              dependents that your spouse has already claimed on his or her form OK-W-4.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="allowanceForDependents"
              control={control}
              render={({ field }) => (
                <CurrencyField
                  step={1}
                  max={25}
                  decimalScale={0}
                  label=""
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              4. Additional Allowances: You may claim additional allowances if you itemize your
              deductions or have other state tax deductions or credits that lower your tax. Enter
              the number of additional allowances you would like to claim.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="additionalAllowances"
              control={control}
              render={({ field }) => <CurrencyField
                label=""
                value={field.value}
                onChange={field.onChange} />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              5. Total Number of Allowances You Are Claiming: Add lines 1 through 4 and enter total
              here.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CurrencyField label="" disabled value={point5} />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              6. Additional Withholding: if you expect to have a balance due (as a result o interest
              income, dividends, income from a part-time job, etc.) on your tax return, you may
              request your employer to withhold an additional amount of tax from each pay period. To
              calculate te amount needed, divide te amount of the expected balance due by the number
              of pay periods in a year. Enter the additional amount to be withheld each pay period
              here.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="additionalWithholding"
              control={control}
              render={({ field }) => <CurrencyField
                label=""
                value={field.value}
                onChange={field.onChange}
              />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="body2">
              {" "}
              If any of the following exemptions apply on 7,8,9 below, please check the box to the
              right
            </MDTypography>
          </Grid>
        </Grid>

        <Controller
          name="exemption"
          control={control}
          render={({ field }) => (
            <>
              <Grid container spacing={2} direction="row" alignItems="center" mt={2}>
                <Grid item xs={12} sm={10}>
                  <MDTypography variant="body2">
                    {" "}
                    7. Exempt status: if you had a right to a refund of all of you Oklahoma income
                    tax withheld last year because you had no tax liability in this year you expect
                    a refund of all Oklahoma income tax withheld because you expect to have no tax
                    liability.
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Checkbox
                    id="noTaxLiability"
                    checked={field.value === "noTaxLiability"}
                    onChange={(e, v) => handleExemption(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    EXEMPT
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" alignItems="center" mt={2}>
                <Grid item xs={12} sm={10}>
                  <MDTypography variant="body2">
                    {" "}
                    8. If you meet the conditions set forth under the Servicemember Civil Relief
                    Act, as amended by the Military Spouses Residency Relief Act and have no
                    Oklahoma tax liability.
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Checkbox
                    id="servicememberCivilReliefAct"
                    checked={field.value === "servicememberCivilReliefAct"}
                    onChange={(e, v) => handleExemption(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    EXEMPT
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" alignItems="center" mt={2}>
                <Grid item xs={12} sm={10}>
                  <MDTypography variant="body2">
                    {" "}
                    9. If income earned as a member of any active duty component of the Armed Forces
                    of the United States is eligible for the military income deduction.
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Checkbox
                    id="armedForces"
                    checked={field.value === "armedForces"}
                    onChange={(e, v) => handleExemption(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    EXEMPT
                  </MDTypography>
                </Grid>
              </Grid>
            </>
          )}
        />
      </MDBox>

      <FormErrors errors={errors} />
    </form>
  );
};

export default OklahomaForm;
