import { useMemo, useState } from "react";
import useBadgeValues from "hooks/useBadgeValues";

import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import MessageIcon from "@mui/icons-material/Message";
import { Public } from "@mui/icons-material";
import FilterCardBar from "components/FilterCardBar";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNotificationContext } from "context/NotificationContext";

const NotificationFilterCards = () => {
  const { tableFilter, setTableFilter } = useNotificationContext();

  const initialCards = useMemo(
    () => [
      {
        color: "dark",
        icon: <Public fontSize="large" color="black" />,
        title: "All",
        tooltip: "All Notifications",
        number: 0,
        filterField: "status",
        filterValue: "",
      },
      {
        color: "info",
        icon: <MessageIcon fontSize="large" color="info" />,
        title: "Read",
        tooltip: "Read Notifications",
        number: 0,
        filterField: "status",
        filterValue: "read",
        canCombine: true,
      },
      {
        color: "success",
        icon: <MarkUnreadChatAltIcon fontSize="large" color="success" />,
        title: "Unread",
        tooltip: "Unread Notifications",
        number: 0,
        filterField: "status",
        filterValue: "unread",
        default: true,
      },
    ],
    []
  );

  const initialCardIndex = initialCards.findIndex(card => card.default);
  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({
    initialCards,
    tableFilter,
    entity: "notifications",
    refreshBadges,
  });

  const setNotificationsFilter = (filter) => {
    const { filterField, filterValue } = initialCards[initialCardIndex];
    setTableFilter({ ...filter, [filterField]: filterValue });
    setRefreshBadges((prev) => !prev);
  };


  return (
    <FlexWrapper justifyContent="flex-start">
      <FilterCardBar cards={cards} bgColor="primary" setFilters={setNotificationsFilter} initialIndex={initialCardIndex} />
    </FlexWrapper>
  );
};

export default NotificationFilterCards;
