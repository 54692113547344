import axios from "axios";

import { baseAxios } from "config";

const postNotePartner = async ({ partnerId, data }) => {
  try {
    const res = await baseAxios.post(`/partners/${partnerId}/note`, data);
    return res;
  } catch (err) {
    throw err.toString();
  }
};

export default postNotePartner;
