import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import { Stack, Button, Grid, useMediaQuery } from "@mui/material";
import DataList from "components/DataList";
import { useQuery } from "react-query";
import Searchbar from "components/Searchbar";
import PictureAvatar from "components/PictureAvatar";
import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import useSort from "utils/useSort";
import MyEmployeesActions from "../MyEmployeesActions";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const MyEmployees = ({
  fetchAll,
  setActionSelected = "info",
  title = "Title",
  filters,
  setFilters,
}) => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");

  const { order, orderBy, toggleSort } = useSort("lastName", "asc");

  const options = fetchAll ? { fetchAll } : { limit: null, order, orderBy };
  const {
    venues,
    setVenues,
    company,
    setCompany,
    companyType,
    setCompanyType,
    setCurrentApplicant,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
    // setCurrentCompany,
  });

  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const [page, setPage] = useState(1);

  const allOptions = {
    ...options,
    filters: { ...filters, status: "Employee" },
  };

  const { data: employees, isLoading } = useQuery(["employees", allOptions], () =>
    fetchEmployees(allOptions)
  );

  const columns = useMemo(() => [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Phone", field: "phone" },
  ]);

  const navigate = useNavigate();
  const addNew = () => {
    navigate("/customers/create");
  };
  const viewAll = () => navigate("/customers");

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center" key={row._id}>
        <Grid item xs={12} sm={2}>
          <PictureAvatar
            image={row?.profileImg}
            firstName={row?.firstName}
            lastName={row?.lastName}
            size="md"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {row?.lastName}
        </Grid>
        <Grid item xs={12} sm={2}>
          {row?.firstName}
        </Grid>
        <Grid item xs={12} sm={2}>
          {row?.phone}
        </Grid>
        <Grid item xs={12} sm={2}>
          {row?.referralSource}
        </Grid>
        <Grid item xs={12} sm={2}>
          <MyEmployeesActions row={row} />
        </Grid>
      </Grid>
    </>
  );

  const searchBarRowClick = (item) => {
    setCurrentApplicant(item);
    navigate(`/employees/${item._id}/action/employeeinfo`);
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2}>
        <Grid item sm={6.5}>
          <Searchbar
            // fetch={fetchEmployees}
            fetchAll={fetchAll}
            placeholder="Search Employee"
            columns={columns}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            // searchBy="title,venueSlug,venueName"
            // filterBy="venueSlug"
            onRowClick={(item) => searchBarRowClick(item)}
          />
        </Grid>
        <Grid item sm={5.5}>
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <DataList
      renderRow={renderRow}
      data={employees?.data}
      filters={filters}
      icon="people"
      iconColor="error"
      title="My Employees"
      // isLoading={isLoading}
      tableHeight={420}
      height={isLaptop ? 500 : null}
      greybar
      divider
      header={header}
      scrollY
    />
  );
};

export default MyEmployees;
