import { Button } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import Markdown from "markdown-to-jsx";

const ApplicantResumeTextModal = ({ applicantName, text, open, setOpen }) => {
  const modalTitle = (
    <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1 }}>
      {applicantName} Resume
    </MDTypography>
  );

  const modalBody = (
    <>
      <MDTypography variant="body2">
        <Markdown>{text}</Markdown>
      </MDTypography>
    </>
  );

  const modalButtons = (
    <Button
      variant="contained"
      onClick={setOpen}
      sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
    >
      Close
    </Button>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={modalTitle}
        buttons={modalButtons}
        sx={(theme) => ({
          width: "35% !important",
          maxWidth: "50% !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default ApplicantResumeTextModal;
