import { Grid, Icon, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const InvitedAttendee = (row, idx, attendeeHandler, removeAttendee) => {
    const { firstName, lastName, emailAddress } = row;
    return (
        <>
            <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                onClick={() => {
                    attendeeHandler(row, true, idx);
                }}
            >
                <Grid container>
                    <Grid item sm={10}>
                        <Grid container>
                            <Grid item sm={4}>
                                <MDTypography variant="body2">{`${firstName} ${lastName}`}</MDTypography>
                            </Grid>
                            <Grid item sm={8}>
                                <MDTypography variant="body2">{emailAddress}</MDTypography>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={2} alignSelf="center">
                        <MDBox textAlign="right" >
                            <Icon
                                color="error"
                                fontSize="lg"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeAttendee(idx);
                                }}
                            >
                                cancel
                            </Icon>
                        </MDBox>
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};

export default InvitedAttendee;
