import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Typography, IconButton, styled, } from "@mui/material";
import GenericModal from "components/GenericModal";
import CancelIcon from "@mui/icons-material/Cancel";
import MDEditor from "components/MDEditor";
import { useAppContextController } from "context/AppContext";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";

const CreateEditNoteModal = ({ open, setOpen, currentNote, noteIndex, setCurrentNote, setNoteIndex, setValue, getValues }) => {

    const { currentLoggedUser, currentApplicant } =
        useAppContextController();
    const [editorValue, setEditorValue] = useState("<p></p>")
    const [noteTitle, setNoteTitle] = useState(null)
    const [validationError, setValidationError] = useState([])

    const reset = async () => {
        setCurrentNote(null)
        setNoteIndex(null)
        setEditorValue("<p></p>");
        setNoteTitle(null);
        setValidationError([]);

    };
    const onCancel = () => {
        reset();
        setOpen(false);
    };

    const validation = () => {
        const errorArr = [];
        let newError;
        if (noteTitle === null) {
            newError = "noteTitle";
            errorArr.push(newError);
        }
        if (editorValue === "<p></p>" || editorValue === "<div><br></div>") {
            newError = "editorValue";
            errorArr.push(newError);
        }
        setValidationError(errorArr);
        return errorArr;
    }

    const saveNote = async (e) => {
        e.preventDefault();
        validation();
        if (validation().length === 0) {

            const newNote = {
                type: noteTitle,
                text: editorValue,
                userId: currentLoggedUser?._id,
                firstName: currentLoggedUser?.firstName,
                lastName: currentLoggedUser?.lastName,
                date: new Date().toISOString(),
            }


            if (noteIndex !== null) {
                const newNotesArray = currentApplicant?.notes ? [...currentApplicant?.notes] : [];
                newNotesArray[noteIndex] = newNote;
                setValue("notes", newNotesArray, { shouldDirty: true });

            }
            else {
                const newNotesArray = getValues("notes") || []
                newNotesArray.push(newNote);
                setValue("notes", newNotesArray, { shouldDirty: true });
            }
            reset();
            setOpen(false);
        }
    };

    useEffect(() => {
        if (currentNote) {
            setNoteTitle(currentNote.type);
            setEditorValue(currentNote.text);
        }
    }, [currentNote]);

    useEffect(() => {
        if (validationError.length > 0) {
            validation();
        }
    }, [editorValue, noteTitle])


    const header = (
        <>
            <Box >
                <Typography variant="h5">
                    {noteIndex === null ? "Add a Note" : "Edit Note"}
                </Typography>
            </Box>
            <CloseEditorButton onClick={() => { setOpen(false) }}>
                <CancelIcon color="secondary" />
            </CloseEditorButton>
        </>
    );


    const modalBody = (<>
        <FormField
            value={noteTitle}
            onChange={(e) => { setNoteTitle(e.target.value) }}
            label="Note Title"
        />
        <MDTypography color="error">
            {validationError.includes("noteTitle") && "You must enter a title for the note"}
        </MDTypography>
        <MDEditor
            value={editorValue}
            onChange={(e) => setEditorValue(e)}
        />
        <MDTypography color="error">
            {validationError.includes("editorValue") && "Please enter a note"}
        </MDTypography>
    </>
    );


    const buttons = (<Box>
        <Button
            variant="text"
            startIcon={<CancelIcon />}
            style={{ color: "red" }}
            onClick={() => {
                reset()
                setOpen(false);
            }}
        >
            Discard
        </Button>
        <Button

            variant="contained"
            startIcon={<SaveIcon />}
            style={{ color: "white" }}
            onClick={(e) => { saveNote(e) }}
        >
            Save
        </Button>
    </Box >);



    return (
        <>

            <GenericModal
                open={open}
                setOpen={setOpen}
                body={modalBody}
                header={header}
                buttons={buttons}
                sx={(theme) => ({
                    width: "90% !important",
                    maxWidth: "700px !important",
                    [theme.breakpoints.down("md")]: {
                        "& .MuiCardContent-root": {
                            padding: "0px",
                        },
                    },
                })}
            />
        </>
    );
};

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: "0%",
    right: "0%",
    m: 1,
    p: 0,
}));

export default CreateEditNoteModal;
