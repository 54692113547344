import DataList from "components/DataList";
import { useEffect, useState } from "react";
import AddAComment from "../AddAComment";
import IndividualComment from "../IndividualComment";

const CommentSection = ({ setValue, comments, currentIssue, currentLoggedUser, mode, dirtyFields }) => {
    const [reverseComments, setReverseComments] = useState(null)
    const renderRow = (row, idx) => <IndividualComment setValue={setValue} comments={comments} row={row} idx={idx} />

    useEffect(() => {
        if (comments) {
            setReverseComments([...comments]?.reverse());
        }
    }, [comments])

    return (
        <>
            {reverseComments ?
                <DataList
                    renderRow={renderRow}
                    data={reverseComments}
                    onRowClick={() => { }}
                    title="Comments"
                    greybar
                    divider
                    tableHeight={320}
                    height={400}
                    scrollY
                />
                :
                <div>No Comments</div>
            }
            <AddAComment
                setValue={setValue}
                comments={comments}
                currentIssue={currentIssue}
                currentLoggedUser={currentLoggedUser}
                mode={mode}
                dirtyFields={dirtyFields} />
        </>
    )

}

export default CommentSection;