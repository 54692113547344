import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import { Public, ShoppingBag, Portrait, Description, Payment, MoodBad } from "@mui/icons-material";

// const salesCycle = {
//   "0 - Prospect": { icon: <Portrait />, id: 1 },
//   "1 - Qualify": { icon: <ShoppingBag />, id: 2 },
//   "2 - Proposal": { icon: <Description />, id: 3 },
//   "3 - Closed/Win": { icon: <Payment />, id: 4 },
//   "4 - Closed/Lost": { icon: <MoodBad />, id: 5 },
// };

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

export const initialCards = [
  {
    icon: <Public {...CommonIconProps} color="dark" fontSize="large" />,
    color: "dark",
    title: "All Prospects",
    tooltip: "All Prospects",
    number: 0,
    filterField: "status",
    filterValue: "Prospect",
    default: true,
  },
  {
    icon: <Portrait {...CommonIconProps} color="secondary" fontSize="large" />,
    color: "secondary",
    title: "Prospect",
    tooltip: "Active Customer",
    number: 0,
    filterField: "sales.salesCycleStatus",
    filterValue: "0 - Prospect",
  },
  {
    icon: <ShoppingBag {...CommonIconProps} color="info" fontSize="large" />,
    color: "info",
    title: "Qualify",
    tooltip: "Qualify Prospect",
    number: 0,
    filterField: "sales.salesCycleStatus",
    filterValue: "1 - Qualify",
  },
  {
    icon: <Description {...CommonIconProps} color="warning" fontSize="large" />,
    color: "warning",
    title: "Proposal",
    tooltip: "Proposal Prospect",
    number: 0,
    filterField: "sales.salesCycleStatus",
    filterValue: "2 - Proposal",
  },
  {
    icon: <Payment {...CommonIconProps} color="success" fontSize="large" />,
    color: "success",
    title: "Closed/Win",
    tooltip: "Closed/Win Prospect",
    number: 0,
    filterField: "sales.salesCycleStatus",
    filterValue: "3 - Closed/Win",
  },
  {
    icon: <MoodBad {...CommonIconProps} color="error" fontSize="large" />,
    color: "error",
    title: "Closed/Lost",
    tooltip: "Closed/Lost Prospect",
    number: 0,
    filterField: "sales.salesCycleStatus",
    filterValue: "4 - Closed/Lost",
  },
];

const ProspectFilterCards = ({ setFilters, setPage, filters, entity = "companies" }) => {
  const { setCurrentJob } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({
    initialCards,
    filters,
    entity,
    refreshBadges,
  });

  const navigate = useNavigate();

  const setJobsFilters = (filter) => {
    setCurrentJob(null);
    const newFilters = filters;
    delete newFilters.slug;
    if (filter.status === "Prospect" && !filter["sales.salesCycleStatus"]) {
      delete newFilters["sales.salesCycleStatus"];
    }
    setFilters({ ...newFilters, ...filter });

    if (setPage) setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  const initialCardIndex = initialCards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setJobsFilters}
        setPage={setPage}
        initialIndex={initialCardIndex}
      />
    </span>
  );
};

export default ProspectFilterCards;

ProspectFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

// Typechecking props for the TableAction
ProspectFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
