import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchActivityById from "layouts/pages/activities/actions/fetchActivityById";
import ActivitiesTable from "layouts/pages/activities/components/ActivitiesTable";
import ActivitiesFilterCards from "layouts/pages/activities/components/ActivitiesFilterCards";
import useSessionAuth from "hooks/useSessionAuth";
import { Alert, Snackbar } from "@mui/material";
import ActivitiesIntegrationFilterCards from "./components/ActivitiesIntegrationFilterCards";
import ActivitiesInformation from "./components/ActivitiesInformation";
import ActivitiesDetails from "./components/ActivitiesDetails";

function Activities() {
  const navigate = useNavigate();

  const { currentActivity, setCurrentActivity, venues } = useAppContextController();
  const { id, action } = useParams();
  const [actionSelected, setActionSelected] = useState(action || "");

  const [filters, setFilters] = useState({});

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    try {
      const fetchActs = async () => {
        const activities = await fetchActivityById(id);
        if (activities && activities?.data?.length) setCurrentActivity(activities?.data[0]);
      };

      if (id && (actionSelected || action)) {
        setFilters({ _id: id });
      }
      if (action) {
        // && action !== actionSelected) {
        setActionSelected(action);
      } else {
        setActionSelected("");
      }

      if (id && (!currentActivity || currentActivity._id !== id)) {
        fetchActs();
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [id, action, currentActivity]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />

      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <ActivitiesFilterCards
            setFilters={setFilters}
            // setPage={setPage}
          />
          {filters.type === "Integration" ? (
            <ActivitiesIntegrationFilterCards
              setFilters={setFilters}
              entity="venues"
              filters={filters}
            />
          ) : null}

          <ActivitiesTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Activities"
            setToastAlert={setToastAlert}
          />
        </Grid>
        {currentActivity && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ActivitiesInformation />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ActivitiesDetails />
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default Activities;
