import axios from "axios";

import { baseAxios } from "config";

const rosterClockIn = async ({ id, applicantId, data }) => {
  try {
    const res = await baseAxios.put(`/events/id/${id}/applicant/${applicantId}/clockin`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default rosterClockIn;
