import { Box } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useState } from "react";

import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SendMessage from "components/SendMessage";

const SendEmailModal = ({
  isOpen,
  toggleModal,
  currentEvent = null,
  recipient = null,
  sendMessageProps = {},
  subject = null,
  venueId
}) => {
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [system, setSystem] = useState(false);

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key="top-center"
      >
        <GenericModal
          open={isOpen}
          setOpen={toggleModal}
          body={
            <Box>
              <SendMessage
                recipient={
                  recipient || { _id: "", email: "", phone: "", firstName: "", lastName: "" }
                }
                setOpen={toggleModal}
                text={text}
                setText={setText}
                email={email}
                eventId={currentEvent?._id}
                setEmail={setEmail}
                system={system}
                setSystem={setSystem}
                {...sendMessageProps}
                templateIn={subject ? "Custom Message" : null}
                subjectIn={subject || ""}
                messageContext="Venue"
                venueId={venueId}
              />
            </Box>
          }
          header={
            <Box display="flex">
              <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
                <MDTypography variant="h5" color="dark">
                  Send Message to: {`${recipient?.firstName} ${recipient?.lastName}`}
                </MDTypography>
              </MDBox>
            </Box>
          }
          sx={(theme) => ({
            width: "90% !important",
            maxWidth: "700px !important",
            [theme.breakpoints.down("md")]: {
              "& .MuiCardContent-root": {
                padding: "0px",
              },
            },
          })}
        />
      </SnackbarProvider>
    </>
  );
};

export default SendEmailModal;
