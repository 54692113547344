import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import omit from "lodash/omit";
import has from "lodash/has";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import { QuestionMark } from "@mui/icons-material";

const CommonIconProps = {
  width: "30px",
  height: "30px",
  color: "white",
};

export const initialSourceCards = [
  {
    icon: "web",
    title: "Website",
    color: "success",
    tooltip: "Applicants from our Website",
    number: 0,
    filterField: "referralSource",
    filterValue: "Company Website",
  },
  {
    icon: "work",
    title: "Indeed",
    color: "warning",
    tooltip: "Applicants from Indeed",
    number: 0,
    filterField: "referralSource",
    filterValue: "Indeed",
  },
  {
    icon: "facebook",
    title: "Facebook",
    color: "info",
    tooltip: "Applicants from Facebook",
    number: 0,
    filterField: "referralSource",
    filterValue: "Facebook",
  },
  {
    icon: "linkedin",
    title: "LinkedIn",
    color: "primary",
    tooltip: "Applicants from LinkedIn",
    number: 0,
    filterField: "referralSource",
    filterValue: "LinkedIn",
  },

  {
    icon: "handshake",
    title: "Handshake",
    color: "secondary",
    tooltip: "Applicants from Handshake",
    number: 0,
    filterField: "referralSource",
    filterValue: "Handshake",
    companyType: "Venue",
  },

  {
    icon: (
      <img
        alt="Zip Recruiter Logo"
        src="https://images.stadiumpeople.com/sterling/static/ZipRecruiter.png"
        height={40}
        width={40}
      />
    ),
    title: "Zip Recruiter",
    color: "secondary",
    tooltip: "Applicants from Zip Recruiter",
    number: 0,
    filterField: "referralSource",
    filterValue: "ZipRecruiter",
    companyType: "Company",
  },

  {
    icon: <QuestionMark {...CommonIconProps} color="error" fontSize="large" />,
    color: "error",
    title: "Other",
    tooltip: "Applicants from Other Sources",
    number: 0,
    filterField: "referralSource",
    filterValue: "Other",
  },
];

const ApplicantsFilterCards = ({ setFilters, setPage, filters, baseFilters }) => {
  const { setCurrentApplicant, company } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const [initialCards, setInitialCards] = useState([]);

  const { cards } = useBadgeValues({
    initialCards: initialSourceCards.filter(
      (item) => !item?.companyType || item?.companyType === company?.companyType
    ),
    filters: { ...(baseFilters ?? {}), ...filters },
    entity: "applicants",
    refreshBadges,
  });

  const navigate = useNavigate();

  const setApplicantFilters = (filter) => {
    navigate("/applicants");
    setCurrentApplicant(null);
    const newFilters = omit(
      filters,
      has(filter, "systemSource") ? "referralSource" : "systemSource"
    );
    delete newFilters._id;
    setFilters({
      ...newFilters,
      ...(filters?.tags ? { tags: filters.tags } : filter),
    });
    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  const initialIndex = cards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setApplicantFilters}
        setPage={setPage}
        initialIndex={initialIndex || 0}
      />
    </span>
  );
};

export default ApplicantsFilterCards;

ApplicantsFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

// Typechecking props for the TableAction
ApplicantsFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
