import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Box, Card, Grid, Icon, Paper, Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import backgroundImage from "assets/images/bg-mascots.png";
import fetchSingleJob from "layouts/pages/jobs/actions/fetchSingleJob";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";

const useStyle = makeStyles({
  card: {
    marginTop: 20,
    overflow: "visible",
    height: "97%!important",
    // width: "85%",
  },
  box: {
    marginTop: 20,
    overflow: "visible",
    height: "100%!important",
    // width: "85%",
  },
});

const JobInfoOutsideLogin = () => {
  const classes = useStyle();

  // const { loginWithRedirect } = useAuth0();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { venues, setVenues, company, setCompany, setCompanyType } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const [venueInfo, setVenueInfo] = useState(null);
  const [jobs, setJobs] = useState(null);
  const { jobSlug } = useParams();

  useEffect(async () => {
    const newjob = await fetchSingleJob(jobSlug);
    if (newjob?.data) setJobs(newjob.data.job);
  }, [jobSlug]);

  useEffect(() => {
    if (venues && jobs) {
      setVenueInfo(venues[jobs?.venueSlug]);
    }
  }, [venues, jobs]);

  const parsedUrl = useMemo(() => {
    if (venueInfo?.venueUrl) {
      const { venueUrl } = venueInfo;
      if (venueUrl.search("https://") === 0 || venueUrl.search("http://") === 0) {
        return venueUrl;
      }
      return `https://${venueUrl}`;
    }
    return null;
  }, [venueInfo]);

  return (
    <>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(rgba("#FFF", 0.6), rgba("#FFF", 0.6))}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Grid container spacing={4} px={2}>
        <Grid item xs={12} md={4} mt={-15}>
          <Card className={classes.card} shadow="md">
            <Grid container spacing={3} alignItems="center" py={1}>
              <Grid item>
                <MDBox display="flex" mt={0} mb={0}>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-4}
                  >
                    <Icon fontSize="large">stadium</Icon>
                  </MDBox>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                      Job Search Results
                    </MDTypography>
                  </Stack>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox p={!isSmallScreen ? 5 : 3}>
              <FlexWrapper alignItems="flex-start" flexDirection="column">
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down("sTablet")]: {
                      alignSelf: "center",
                    },
                  })}
                >
                  <img width="100" height="100" src={venueInfo?.logoUrl} alt={venueInfo?.logoUrl} />
                </Box>
                <MDTypography variant="h3" color="dark">
                  {jobs?.title}
                </MDTypography>
                <MDTypography variant="body1" color="dark" sx={{ mb: 1 }}>
                  {parsedUrl ? (
                    <a href={parsedUrl} target="_blank" rel="noopener noreferrer">
                      {venueInfo?.name}
                    </a>
                  ) : (
                    venueInfo?.name
                  )}
                </MDTypography>
                <MDTypography variant="body1" color="dark" sx={{ mb: 1 }}>
                  {venueInfo?.city}, {venueInfo?.state}
                </MDTypography>
              </FlexWrapper>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} mt={isSmallScreen ? 0 : -15}>
          <MDBox>
            <Card shadow="md" className={classes.box}>
              <MDBox p={!isSmallScreen ? 5 : 3} my={4} sx={{ height: "35rem", overflow: "auto" }}>
                <Box sx={{ typography: 'body2' }} >{jobs?.description ? parse(jobs?.description) : ""}</Box>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default JobInfoOutsideLogin;
