import moment from "moment";
import PropTypes from "prop-types";

const EventDate = ({ date, reportTimeTBD, showTime = true }) => {
  return (
    <>
      {moment(date).format("ddd, MMM Do, YYYY")}{" "}
      {showTime ? reportTimeTBD || moment(date).format("hh:mm A") : ""}
    </>
  );
};

EventDate.defaultProps = {
  date: new Date(),
  reportTimeTBD: "",
};

EventDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  date: PropTypes.string,
  reportTimeTBD: PropTypes.string,
};

export default EventDate;
