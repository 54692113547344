import { baseAxios } from "config";

const updateApplicantJobAIInterviews = ({ data, applicantId, jobSlug }) => {
  return baseAxios.post(`/applicants/${applicantId}/job/aiinterviews`, {
    jobSlug,
    data,
  });
};

export default updateApplicantJobAIInterviews;
