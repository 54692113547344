import * as yup from "yup";

export const applicantAdditionalInfoSchema = yup.object().shape({
    additional: yup.object().shape({
        minPayRate: yup.number().moreThan(-0.01, "value must be positive").default(0),

        maxPayRate: yup.number().moreThan(-0.01, "value must be positive").default(0).test({
            name: "maxHigherThanMin",
            test(value, ctx) {

                if (this.parent.minPayRate > value) {
                    return ctx.createError({ message: "Max Pay Rate must be higher than Min" });
                }
                return true;
            },
        })


    })
});
