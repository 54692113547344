import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import { Public, PersonSearch, DirectionsRun, ToggleOff } from "@mui/icons-material";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

export const initialCards = [
  {
    icon: <Public {...CommonIconProps} color="dark" fontSize="large" />,
    color: "dark",
    title: "All",
    tooltip: "All Customers",
    number: 0,
    filterField: "status",
    filterValue: "Active;Inactive;Prospect",
  },
  {
    icon: <DirectionsRun {...CommonIconProps} color="success" fontSize="large" />,
    color: "success",
    title: "Active",
    tooltip: "Active Customer",
    number: 0,
    filterField: "status",
    filterValue: "Active",
    default: true,
  },
  {
    icon: <ToggleOff {...CommonIconProps} color="error" fontSize="large" />,
    title: "Inactive",
    color: "error",
    tooltip: "Inactive Customer",
    number: 0,
    filterField: "status",
    filterValue: "Inactive",
  },
  {
    icon: <PersonSearch {...CommonIconProps} color="warning" fontSize="large" />,
    color: "warning",
    title: "Prospect",
    tooltip: "Prospect Customer",
    number: 0,
    filterField: "status",
    filterValue: "Prospect",
  },
  // ,
  // {
  //     icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
  //     title: "All",
  //     tooltip: "All Customer",
  //     number: 0,
  //     filterField: "status",
  //     filterValue: "",
  // },
];

const CustomerFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentJob } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({
    initialCards,
    filters,
    entity: "companies",
    refreshBadges,
  });

  const navigate = useNavigate();
  const { slug } = useParams();

  const setCustomersFilters = (filter) => {
    setCurrentJob(null);
    const newFilters = { ...filters };
    delete newFilters.slug;
    if (filter.status !== "Prospect") {
      delete newFilters["sales.salesCycleStatus"];
    }
    setFilters({ ...newFilters, ...filter });
    setPage(1);
    setRefreshBadges((prev) => !prev);
    if (slug) navigate(`/customers`);
  };

  const initialCardIndex = initialCards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setCustomersFilters}
        setPage={setPage}
        initialIndex={initialCardIndex}
      />
    </span>
  );
};

export default CustomerFilterCards;

CustomerFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

// Typechecking props for the TableAction
CustomerFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
