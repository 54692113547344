import { useState, useEffect } from "react";
import useDropdowns from "hooks/useDropdowns";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";

import fetchUsers from "layouts/pages/profile/actions/fetchUsers";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import { Grid, Stack, Autocomplete } from "@mui/material";

const STATUSES_ENTITY_NAME = "statuses";

const AdditionalInfo = ({
  payrollDeptContact,
  setPayrollDeptContact,
  venueContact1,
  setVenueContact1,
  venueContact2,
  setVenueContact2,
  setSettingsDirty,
}) => {
  const [filters, setFilters] = useState({});
  const { currentVenue } = useAppContextController();

  const { dropdowns: statuses } = useDropdowns({ entity: STATUSES_ENTITY_NAME });

  const options = { fetchAll: true };
  const allOptions = { ...options, filters, order: "lastName", orderBy: "lastName" };
  const filteredUsers = [];
  const { data: users } = useQuery(["filteredusers", allOptions], () => fetchUsers(allOptions));

  users?.data
    ?.filter((item) => !["Terminated", "Inactive"].includes(item.status))
    .map((user) => {
      // const obj = filteredUsers.push(`${user.firstName} ${user.lastName}`);
      filteredUsers.push({
        userId: user._id,
        applicantId: user.applicantId,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.emailAddress,
      });
      return filteredUsers;
    });

  filteredUsers.push({
    userId: "",
    applicantId: "",
    firstName: "Payroll",
    lastName: "Department",
    fullName: "Payroll Department",
    email: "payroll@stadiumpeople.com",
  });

  const sortUsers = (a, b) => {
    if (a.fullName > b.fullName) return 1;
    return -1;
  };

  filteredUsers.sort(sortUsers);

  useEffect(() => {
    if (statuses?.length) setFilters({ spStatus: "Employee" });
  }, [statuses?.length]);

  useEffect(() => {
    setVenueContact1(currentVenue?.venueContact1);
    setVenueContact2(currentVenue?.venueContact2);
    setPayrollDeptContact(currentVenue?.payrollDeptContact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVenue]);

  return (
    <MDBox my={3}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MDTypography component="label" variant="button" fontWeight="regular">
            EVENT MANAGEMENT
          </MDTypography>
          <Stack spacing={2}>
            <Autocomplete
              key={`autocomplete_venueContact1_${currentVenue?.slug}`}
              options={filteredUsers}
              getOptionLabel={(option) => `${option.fullName} `}
              name="venueContact1"
              value={venueContact1 || ""}
              onChange={(e, v) => {
                setVenueContact1(v);
                setSettingsDirty(currentVenue?.venueContact1 !== v);
              }}
              renderInput={(params) => (
                <FormField
                  key={`autocomplete_venueContact1_${currentVenue?.slug}`}
                  {...params}
                  type="text"
                  label="Select Contact"
                />
              )}
            />

            <Autocomplete
              key={`autocomplete_venueContact2_${currentVenue?.slug}`}
              options={filteredUsers}
              getOptionLabel={(option) => `${option.fullName} `}
              name="venueContact2"
              value={venueContact2 || ""}
              onChange={(e, v) => {
                setVenueContact2(v);
                setSettingsDirty(currentVenue?.venueContact2 !== v);
              }}
              renderInput={(params) => (
                <FormField
                  key={`autocomplete_venueContact2_${currentVenue?.slug}`}
                  {...params}
                  type="text"
                  label="Select Contact"
                />
              )}
            />
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <MDTypography component="label" variant="button" fontWeight="regular">
            PAYROLL CONTACT
          </MDTypography>

          <Autocomplete
            key={`autocomplete_payrollContact_${currentVenue?.slug}`}
            options={filteredUsers}
            getOptionLabel={(option) => `${option.fullName} `}
            name="payrollDeptContact"
            value={payrollDeptContact || ""}
            onChange={(e, v) => {
              setPayrollDeptContact(v);
              setSettingsDirty(currentVenue?.payrollDeptContact !== v);
            }}
            renderInput={(params) => (
              <FormField
                key={`autocomplete_payrollContact_${currentVenue?.slug}`}
                {...params}
                type="text"
                label="Select Contact"
              />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default AdditionalInfo;
