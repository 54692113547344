import { baseAxios } from "config";

const updateEvent = async ({ id, data }) => {
  try {
    const res = await baseAxios.put(`/events/id/${id}/roster`, { ...data });

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateEvent;
