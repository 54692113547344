import axios from "axios";
import { baseAxios } from "config";

const leaveRosterMessage = async ({ eventUrl, applicantId, body }) => {
  try {
    const res = await baseAxios.put(`/events/url/${eventUrl}/enroll/${applicantId}/message`, {
      ...body,
    });
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return null;
    throw new Error(err.toString());
  }
};

export default leaveRosterMessage;
