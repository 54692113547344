// import {useState} from "react";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const ReportsTableActions = ({
    id,
    row,
    setCurrentTemplate,
    toggleEditModal,
    toggleConfirmationModal,
    setContext,
}) => {
    const navigate = useNavigate();
    const onClickHandler = (action, e) => {
        // setCurrentTemplate(row);
        // switch (action) {
        //     case "templateinfo":
        //         if (row?.context) {
        //             setContext(row.context);
        //         } else {
        //             setContext(["Applicant"]);
        //         }
        //         toggleEditModal(true);
        //         break;
        //     case "templatedelete":
        //         toggleConfirmationModal(true);
        //         break;
        //     default:
        //         break;
        // }
        // e.stopPropagation();
    };
    return (
        <div style={{ textAlign: "end" }}>
            <TableAction
                color="info"
                icon="info"
                clickAction={(e) => onClickHandler("jobinfo", e)}
                tooltip="Job Info"
            />
            <TableAction
                icon={<PersonAddIcon color="warning" fontSize="large" />}
                showBadge
                color="warning"
                tooltip="Job Applicants"
                clickAction={(e) => onClickHandler("jobapplicants", e)}
            />
        </div>
    );
};
export default ReportsTableActions;
ReportsTableActions.defaultProps = {
    id: "",
};
// Typechecking props for the TableAction
ReportsTableActions.propTypes = {
    id: PropTypes.string,
};