import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat, PatternFormat } from "react-number-format";
import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { michiganStateFormSchema } from "data/michiganStateTaxForm";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import CommonTaxFields from "components/FormFields/CommonTaxFields";
import CurrencyField from "components/FormFields/CurrencyField";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },

  displayTextDiv: {
    border: "solid 1px black",
    fontSize: "1rem",
    borderRadius: "10px",
    backgroundSize: "1rem 1rem",
    backgroundColor: "rgba(0, 0, 0, 0.10)",
  },
  displayText: {
    font: "inherit",
    letterSpacing: "inherit",
    color: "currentColor",
    justifyContent: "space-between",
    boxSizing: "content-box",
    background: "none",
    height: "1.4375em",
    margin: 0,
    display: "flex",
    minWidth: 0,
    padding: "20px 12px",
    textAlign: "end",
  },
});

const MichiganForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();

  const resolver = useYupValidationResolver(michiganStateFormSchema);
  const classes = useStyle();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
    reset,
  } = useForm({
    resolver, mode: "onBlur", defaultValues: {
      personalAndDependentExemptions: 0
    }
  });

  const onSubmit = async (data) => {
    const parsedData = {
      stateTaxForm: { state: "michigan", ...data },
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  const handleClaimException = (e) => {
    setValue("claimExemption", e.target.id, { shouldDirty: true });
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (applicant?.stateTaxForm) {
      reset(applicant.stateTaxForm);
    }
  }, [applicant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} selectedState="MI" />
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <CustomTextField
            disabled
            inputProps={{ tabIndex: -1 }}
            value={new Date(applicant?.birthDate).toLocaleDateString() || ""}
            variant="outlined"
            label="Date of Birth"
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <CustomTextField
            disabled
            inputProps={{ tabIndex: -1 }}
            value={applicant?.driverLicense}
            variant="outlined"
            label="Driver License"
          />
        </Grid>
        <Grid item xs={12} sm={8} mt={2}>
          <MDTypography variant="body2">
            6. Enter the number of personal and dependent exemptions ( see instructions)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="personalAndDependentExemptions"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    type: "number",
                  },
                }}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                customInput={CustomTextField}
                label=""
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8} mt={2}>
          <MDTypography variant="body2">
            7. Additional amount you want deducted from each pay (if employer agrees)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="additionalAmountDeductedFromEachPay"
            control={control}
            render={({ field }) => <CurrencyField
              label=""
              value={field.value}
              onChange={field.onChange} />}
          />
        </Grid>
        <Grid item>
          <MDTypography variant="body2">
            8. I claim exemption from withholding because (see instructions):
          </MDTypography>

          <Grid container>
            <Grid item sm={12}>
              <Stack display="flex" direction="row" alignItems="center">
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  a.
                </MDTypography>
                <Checkbox
                  id="incomeTaxLiabilityNotExpected"
                  checked={watch("claimExemption") === "incomeTaxLiabilityNotExpected"}
                  onChange={(e, v) => {
                    handleClaimException(e);
                    setValue("permanentHomeRenaissanceZone", "");
                    setValue("wagesAreExemptExplanation", "");
                  }}
                />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  A Michigan income tax liability is not expected this year.{" "}
                </MDTypography>
              </Stack>
            </Grid>
            <Grid item sm={6} mt={3}>
              <Stack display="flex" direction="row" alignItems="center">
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  b.
                </MDTypography>
                <Checkbox
                  id="wagesAreExempt"
                  checked={watch("claimExemption") === "wagesAreExempt"}
                  onChange={(e, v) => {
                    handleClaimException(e);
                    setValue("permanentHomeRenaissanceZone", "");
                  }}
                />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Wages are exempt from withholding.{" "}
                </MDTypography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} mt={3}>
              <Controller
                name="wagesAreExemptExplanation"
                control={control}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      label="Explain"
                      disabled={watch("claimExemption") !== "wagesAreExempt"}
                      variant="outlined"
                      {...field}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={3}>
              <Stack display="flex" direction="row" alignItems="center">
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  c.
                </MDTypography>
                <Checkbox
                  id="permanentHomeIsLocatedRenaissanceZone"
                  checked={watch("claimExemption") === "permanentHomeIsLocatedRenaissanceZone"}
                  onChange={(e, v) => {
                    handleClaimException(e);
                    setValue("wagesAreExemptExplanation", "");
                  }}
                />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Permanent home (domicile) is located in the following Renaissance Zone:{" "}
                </MDTypography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} mt={3}>
              <Controller
                name="permanentHomeRenaissanceZone"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Zone"
                    disabled={watch("claimExemption") !== "permanentHomeIsLocatedRenaissanceZone"}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormErrors errors={errors} />
    </form>
  );
};

export default MichiganForm;
