/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useYupValidationResolver from "hooks/useYupValidationResolver";

// store/api/helper
import { useAppContextController } from "context/AppContext";
import { getUpdatedNotesList, sortArrayOfObj } from "utils/helpers/dataList";
import { isEmpty, isEqual } from "lodash";

// style
import { Box, Button, Stack } from "@mui/material";
import { ContactPage } from "@mui/icons-material";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";

// components
import DataListContainer from "components/DataListContainer";
import CustomCard from "components/CustomCard";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import PanelActions from "components/PanelActions";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";

import ContactsItem from "./ContactsItem";
import ContactsNotes from "./ContactsNotes";
import ContactForm from "./ContactForm";
import contactsSchema from "./ContactsSchema";

const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const contactFields = ["contact", "firstName", "lastName", "email", "phone", "title"];

const ContactsPanel = ({
  name,
  sourceComponent,
  onUpdate,
  setSelectedPanel,
  setSelectedContact,
  contacts,
  tempContacts,
  setTempContacts,
  primaryInfo,
  setPrimaryInfo,
  currentContact,
  setCurrentContact,
  currentData,
  onClickCreateUser,
  onClickDeleteUser,
}) => {
  const {
    isMobile,
    setCurrentFormDirty,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    changePanel,
  } = useAppContextController();

  const [isContactsDirty, setContactsDirty] = useState(false);
  const [contactIndex, setContactIndex] = useState(null);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const [noteIndex, setNoteIndex] = useState(null);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const isVenue = sourceComponent === "Venue";
  const isPartner = sourceComponent === "Partner";
  const isVenueOrPartner = isVenue || isPartner;
  const validationSchema = contactsSchema(primaryInfo, showDetails, isContactsDirty);
  const resolver = useYupValidationResolver(validationSchema);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
    getValues,
    formState: { dirtyFields, errors },
  } = useForm({
    resolver,
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const contactNotes = watch("notes");

  // adjusts current selected contact depending if its new or an existing data that was edited
  const handleMethod = (method, data) => {
    setContactsDirty(false);
    switch (method) {
      case "create":
        setContactIndex(tempContacts?.length);
        setCurrentContact(data[tempContacts?.length]);
        break;
      case "update":
        setContactIndex(contactIndex);
        setCurrentContact(data[contactIndex]);
        break;
      case "primary-only":
        setContactIndex(null);
        setCurrentContact(emptyValues);
        break;
      default:
        break;
    }
  };

  const handleContactSave = (data) => {
    let updatedContacts;
    let method;
    let contact = primaryInfo;
    let values = {};
    const { firstName, lastName, email, phone, phoneExt, title } = data;

    if (contactIndex !== null) {
      updatedContacts = tempContacts.map((item, index) => {
        if (index === contactIndex) {
          return { ...item, ...data, primaryContact: item.primaryContact };
        }
        return item;
      });
      const dirtyTest = Object.keys(dirtyFields).some((a) => contactFields.includes(a));
      if (updatedContacts[contactIndex]?.primaryContact === "Yes" && dirtyTest) {
        contact = {
          ...(isVenueOrPartner && { contact: `${firstName} ${lastName}` }),
          ...(!isVenueOrPartner && { firstName, lastName, title }),
          email,
          phone,
          phoneExt,
          title,
        };
      }
    } else if (!isEmpty(firstName) && !isEmpty(lastName)) {
      let val = data;
      if (isVenueOrPartner) {
        const { title: _t, ...rest } = data;
        val = rest;
      }
      method = "create";
      updatedContacts = [...tempContacts, val];
    } else {
      method = "primary-only";
      contact = primaryInfo;
      updatedContacts = tempContacts;
    }

    values = contact ? { contact, contacts: updatedContacts } : { contacts: updatedContacts };
    if (!isEmpty(values)) onUpdate(values, method, handleMethod); // put handleMethod here
  };

  const filterNecessaryData = (data) => {
    const { firstName, lastName, email, phone, phoneExt, title } = data;
    const contact = {
      ...(isVenueOrPartner && { contact: `${firstName} ${lastName}` }),
      ...(!isVenueOrPartner && { firstName, lastName, title }),
      email,
      phone,
      phoneExt,
      title,
    };

    return contact;
  };

  const handleContactEdit = (i, data) => {
    let contact;

    const updatedContacts = tempContacts.map((item, index) => {
      if (index === i) {
        return { ...item, ...data, primaryContact: item.primaryContact };
      }
      return item;
    });

    const dirtyTest = Object.keys(dirtyFields).some((a) => contactFields.includes(a));
    if (updatedContacts[i]?.primaryContact === "Yes" && dirtyTest) {
      contact = filterNecessaryData(data);
    }

    return { updatedContacts, ...(contact && { contact }) };
  };

  // temporarily save current form data when switching locations
  const handleContactTempSave = () => {
    const data = getValues();
    const details = handleContactEdit(contactIndex, data);
    if (details?.contact) setPrimaryInfo(details?.contact);
    setTempContacts(details?.updatedContacts);
  };

  // shows form details and adjusts current contact on add new contact or contact row select
  const handleContact = (idx, row) => {
    if (isDirty) handleContactTempSave();

    setShowDetails(true);
    setContactIndex(idx);
    if (idx === null) setCurrentContact(emptyValues);
    else setCurrentContact(row);
  };

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => setSelectedPanel(panel));
    } else {
      setSelectedPanel(panel);
    }
  };

  // sets current contact empty and hides form details if no contact is selected or one was just deleted
  const handleEmptyContactReset = () => {
    setContactIndex(null);
    setCurrentContact(emptyValues);
    setShowDetails(false);
  };

  // sets form values to corresponding contact
  const resetCurrentContact = () => {
    if (currentContact) reset(currentContact);
    else reset(emptyValues);
  };

  // adapts to new selected index whenever array length changes
  const handleContactsOrderChange = (contactsData) => {
    const updatedIndex = contactsData.findIndex((item) => item === currentContact);
    if (updatedIndex > -1) {
      setContactIndex(updatedIndex);
      setCurrentContact(contactsData[updatedIndex]);
    } else {
      handleEmptyContactReset();
    }
  };

  const handleRemoveContact = (idx) => {
    const temporaryContacts = tempContacts;
    const updatedContacts = temporaryContacts?.filter((_, index) => index !== idx);
    if (contactIndex !== null) handleContactsOrderChange(updatedContacts);
    setTempContacts(updatedContacts);
  };

  const handlePrimaryChange = (newIdx) => {
    const temporaryContacts = tempContacts;
    const oldIdx = tempContacts.findIndex(({ primaryContact }) => primaryContact === "Yes");
    const updatedContacts = temporaryContacts.map((item, i) => {
      if (i === oldIdx) return { ...item, primaryContact: "No" };
      if (i === newIdx) return { ...item, primaryContact: "Yes" };
      return item;
    });

    setTempContacts(updatedContacts);
    setPrimaryInfo(filterNecessaryData(updatedContacts[newIdx]));
  };

  const renderAdditionalContacts = (row, idx) => (
    // rename from CustomerContactsItem
    <ContactsItem
      row={row}
      idx={idx}
      noContact={contactIndex === idx && !watch("email") && !watch("phone")}
      onPanelChange={handlePanelChange}
      setSelectedContact={setSelectedContact}
      onEdit={handleContact}
      onRemove={handleRemoveContact}
      onPrimaryChange={handlePrimaryChange}
      isPartner={isPartner}
      onClickCreateUser={onClickCreateUser}
      onClickDeleteUser={onClickDeleteUser}
    />
  );

  const header = (
    <Stack display="flex" flexDirection="row" justifyContent="right" pr={3}>
      <Button variant="text" color="primary" onClick={() => setDisplayNotes(!displayNotes)}>
        {displayNotes ? "Collapse All" : "Expand All"}
      </Button>
    </Stack>
  );

  // adjusts current contact note on add new note or note select
  const handleNote = (idx, row) => {
    setNoteIndex(idx);
    if (idx === null) setCurrentNote({});
    else setCurrentNote(row);
    setNotesModalOpen(true);
  };

  const handleSaveNote = (data) => {
    const { text } = data;
    const updatedNotes = getUpdatedNotesList(text, noteIndex, contactNotes);
    if (!isEmpty(updatedNotes))
      setValue("notes", sortArrayOfObj({ arr: updatedNotes }), { shouldDirty: true });
  };

  const handleRemoveNote = (idx) => {
    const temporaryNotes = contactNotes;
    const updatedNotes = temporaryNotes?.filter((_, index) => index !== idx);
    setValue("notes", updatedNotes, { shouldDirty: true });
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleContactSave)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  // on current data change
  useEffect(() => {
    reset(emptyValues);
    handleEmptyContactReset();
  }, [currentData?.slug]);

  // when temporary changes on contact list is made
  useEffect(() => {
    if (isEqual(tempContacts, contacts)) setContactsDirty(false);
    else setContactsDirty(true);
  }, [tempContacts]);

  const handleCancelChanges = () => {
    resetCurrentContact();
    handleContactsOrderChange(contacts);
    setPrimaryInfo();
    setTempContacts(contacts);
    setContactsDirty(false);
  };

  useEffect(() => {
    resetCurrentContact();
    if (currentContact?.notes) setValue("notes", sortArrayOfObj({ arr: currentContact?.notes }));
    else setValue("notes", []);
  }, [currentContact]);

  useEffect(() => {
    setCurrentNote({});
    setNoteIndex(null);
  }, [contactNotes]);

  // to enable discard changes modal when switching panels
  useEffect(() => {
    setCurrentFormDirty(isDirty || isContactsDirty);
  }, [isDirty, isContactsDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(handleContactSave)}>
      <CustomCard
        icon={<ContactPage color="white" />}
        cardTitle={`Contacts: ${name}`}
        cardActions={
          (isDirty || isContactsDirty) && <PanelActions onCancel={handleCancelChanges} />
        }
        {...customPanelCard(isMobile, "warning")}
      >
        <Stack spacing={4} sx={styles.container}>
          <Box>
            <DataListContainer
              onAdd={handleContact}
              data={tempContacts}
              renderRow={renderAdditionalContacts}
              title="Contacts"
              tableHeight={265}
              height={267}
              selected={contactIndex}
            />
          </Box>
          {showDetails && (
            <>
              <Box>
                <ContactForm control={control} setValue={setValue} />
              </Box>
              <Box>
                <DataListContainer
                  onAdd={handleNote}
                  data={contactNotes}
                  renderRow={(row, idx) => (
                    // from CustomerContactsNotes
                    <ContactsNotes
                      row={row}
                      idx={idx}
                      onEdit={handleNote}
                      onRemove={handleRemoveNote}
                      displayNotes={displayNotes}
                    />
                  )}
                  title="Contact Notes"
                  header={header}
                  tableHeight={265}
                  height={267}
                  selected={noteIndex}
                />
              </Box>
            </>
          )}
        </Stack>
        <AddANoteModal
          open={isNotesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNotesModalOpen}
          saveNote={handleSaveNote}
          showType={false}
          allowEmpty={false}
          defaultValue={currentNote?.text}
          onCloseAction={() => setNoteIndex(null)}
        />
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default ContactsPanel;
