import * as yup from "yup";

const aiInterviewsSchema = () => {
  return yup.object().shape({
    aiInterviews: yup.object().shape({
      resumeQuestions: yup.array().of(
        yup.object().shape({
          text: yup
            .string()
            .ensure()
            .test({
              name: "validate-string-strip-html",
              test: (value, ctx) => {
                if (value) {
                  const strippedValue = value.replace(/<[^>]+>/g, "").trim();
                  if (strippedValue) return true;
                }
                return ctx.createError({ message: "One or more questions are empty." });
              },
            }),
        })
      ),
    }),
  });
};

export default aiInterviewsSchema;
