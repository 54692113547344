/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useAppContextController } from "context/AppContext";

import { Box, Button, Stack, IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import colors from "assets/theme/base/colors";

import GenericModal from "components/GenericModal";
import CustomMap from "components/CustomMap";
import LocalSearch from "components/LocalSearch";

const EventMapModal = ({ open, setOpen, name, center, radius, graceDistance, roster }) => {
  const { currentEvent } = useAppContextController();
  const [filter, setFilter] = useState("All");
  const [currentApp, setCurrentApp] = useState({});
  const [isInfoOpen, setInfoOpen] = useState(false);

  const searchColumns = [
    { title: "Name", field: "name" },
    { title: "Position", field: "primaryPosition" },
  ];

  const handleClose = () => {
    setOpen(false);
    setInfoOpen(false);
  };

  const handleSearch = (row) => {
    setCurrentApp(row);
    setInfoOpen(true);
  };

  const modalBody = (
    <Stack mt={-7}>
      <Box alignSelf="flex-end">
        <IconButton sx={{ p: 0, justifyContent: "flex-end" }} onClick={handleClose}>
          <Cancel color="" fontSize="medium" />
        </IconButton>
      </Box>
      <Stack
        direction="row"
        mt={4}
        mb={2}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={() => setFilter("All")}
            sx={{
              padding: "0.5rem 3rem",
              borderRadius: "4px",
              color: filter === "All" ? "white" : colors.black.main,
              bgcolor: filter === "All" ? "#0070C0!important" : colors.white.main,
              ":hover": {
                bgcolor: "#0070C0",
                color: colors.white.main,
              },
            }}
          >
            All
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilter("Clocked In")}
            sx={{
              padding: "0.5rem 2rem",
              borderRadius: "4px",
              color: filter === "Clocked In" ? "white" : colors.black.main,
              bgcolor: filter === "Clocked In" ? "#008000!important" : colors.white.main,
              ":hover": {
                bgcolor: "#008000",
                color: colors.white.main,
              },
            }}
          >
            Clocked In
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilter("Clocked Out")}
            sx={{
              padding: "0.5rem 2rem",
              borderRadius: "4px",
              color: filter === "Clocked Out" ? "white" : colors.black.main,
              bgcolor: filter === "Clocked Out" ? "#CC3300!important" : colors.white.main,
              ":hover": {
                bgcolor: "#CC3300",
                color: colors.white.main,
              },
            }}
          >
            Clocked Out
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilter("Outside Geofence")}
            sx={{
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              color: filter === "Outside Geofence" ? "white" : colors.black.main,
              bgcolor: filter === "Outside Geofence" ? "#E28700!important" : colors.white.main,
              ":hover": {
                bgcolor: "#E28700",
                color: colors.white.main,
              },
            }}
          >
            Outside Geofence
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilter("Current")}
            disabled={new Date(currentEvent?.eventDate) < new Date()}
            sx={{
              padding: "0.5rem 2rem",
              borderRadius: "4px",
              color: filter === "Current" ? "white" : colors.black.main,
              bgcolor: filter === "Current" ? "#008000!important" : colors.white.main,
              ":hover": {
                bgcolor: "#008000",
                color: colors.white.main,
              },
            }}
          >
            Current
          </Button>
        </Stack>
        <Box>
          <LocalSearch data={roster} columns={searchColumns} onSearch={handleSearch} />
        </Box>
      </Stack>

      {center && (
        <CustomMap
          initialCenter={center}
          center={center}
          radius={radius}
          graceDistance={graceDistance}
          isCenterEditable={false}
          isRadiusEditable={false}
          isGDistanceEditable={false}
          roster={roster} // temp, currentEvent?.applicants
          filter={filter}
          currentApp={currentApp}
          setCurrentApp={setCurrentApp}
          isInfoOpen={isInfoOpen}
          setInfoOpen={setInfoOpen}
        />
      )}
    </Stack>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      header={`Event Roster Map: ${name}`}
      body={modalBody}
      width="65%"
      borderRadius="4px"
    />
  );
};

export default EventMapModal;
