import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const PositionCount = ({ data }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isEmpty(data)) {
      let tempCt = 0;

      data.forEach((row) => {
        const { positionQuantity } = row;
        const result =
          positionQuantity !== undefined && positionQuantity !== null ? positionQuantity : 1;
        tempCt += result;
      });

      setCount(tempCt);
    } else setCount(0);
  }, [data]);

  return (
    <MDBox sx={{ width: "70%" }}>
      <MDTypography variant="h5" color="dark">
        Position Count: {count}
      </MDTypography>
    </MDBox>
  );
};

export default PositionCount;
