import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Icon,
  Stack,
} from "@mui/material";
import { ArrowDropDown, Hub, ContentPasteSearch, Timeline, PeopleAlt } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import CustomDataGrid from "components/CustomDataGrid";
import CustomBarChart from "components/CustomBarChart";
import {
  APPLICANT_DETAIL,
  JOB_APPLIES,
  APPLICANT_STAGE_ACTIVITY_ICONS,
  STAFFING_POOL_ICONS,
} from "./detailColumns";
import {
  APPLICANT_DETAIL_DATA,
  JOB_APPLIES_DATA,
  APPLICANT_ACTIVITY_STAGE_DATA,
  STAFFING_POOL_DATA,
} from "./detailRows"; // to be deleted

const reportSections = [
  {
    icon: (
      <Icon color="warning" fontSize="large">
        <Hub />
      </Icon>
    ),
    title: "Applicant Detail",
    content: <CustomDataGrid rows={APPLICANT_DETAIL_DATA} columns={APPLICANT_DETAIL} />,
    count: 14,
  },
  {
    icon: (
      <Icon color="warning" fontSize="large">
        <ContentPasteSearch />
      </Icon>
    ),
    title: "Job Applies",
    content: <CustomDataGrid rows={JOB_APPLIES_DATA} columns={JOB_APPLIES} />,
    count: 10,
  },
  {
    icon: (
      <Icon color="warning" fontSize="large">
        <Timeline />
      </Icon>
    ),
    title: "Applicant Stage Activity",
    content: (
      <CustomBarChart data={APPLICANT_ACTIVITY_STAGE_DATA} icons={APPLICANT_STAGE_ACTIVITY_ICONS} />
    ),
    count: 14,
  },
  {
    icon: (
      <Icon color="warning" fontSize="large">
        <PeopleAlt />
      </Icon>
    ),
    title: "Interview Activity",
    content: <Box>InterviewActivityTable</Box>,
    count: 2,
  },
  {
    icon: (
      <Icon color="warning" fontSize="large">
        <PeopleAlt />
      </Icon>
    ),
    title: "Staffing Pool Activity",
    content: <CustomBarChart data={STAFFING_POOL_DATA} icons={STAFFING_POOL_ICONS} />,
    count: 2,
  },
];

const accordionStyle = {
  borderBottom: "1px solid #DBDCDE",
  boxShadow: "none",
  borderRadius: "0 !important",
};

const ReportDetails = () => {
  return (
    <Box>
      {reportSections?.map((section) => (
        <Accordion key={section?.title} disableGutters sx={accordionStyle}>
          <AccordionSummary
            expandIcon={
              <Icon fontSize="large">
                <ArrowDropDown />
              </Icon>
            }
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Stack direction="row" spacing={4} alignItems="center">
              {section?.icon}
              <MDTypography fontWeight="medium">{section?.title}</MDTypography>
            </Stack>
            <Box sx={{ ml: "auto" }}>
              <MDTypography fontWeight="medium" sx={{ mr: 1 }}>
                {section?.count}
              </MDTypography>
            </Box>
          </AccordionSummary>
          <Divider sx={{ bgcolor: "gray", my: 0 }} />
          <AccordionDetails sx={{ py: 0, m: 0 }}>{section?.content}</AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ReportDetails;
