import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import { Public, VisibilityOff, DirectionsRun, ToggleOff } from "@mui/icons-material";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

export const initialCards = [
  {
    icon: <DirectionsRun {...CommonIconProps} color="success" fontSize="large" />,
    color: "success",
    title: "Active",
    tooltip: "Active Jobs",
    number: 0,
    filterField: "status",
    filterValue: "Active",
    default: true,
  },
  {
    icon: <ToggleOff {...CommonIconProps} color="error" fontSize="large" />,
    title: "Inactive",
    color: "error",
    tooltip: "Inactive Jobs",
    number: 0,
    filterField: "status",
    filterValue: "Inactive",
  },
  {
    icon: <VisibilityOff {...CommonIconProps} color="warning" fontSize="large" />,
    color: "warning",
    title: "Hidden",
    tooltip: "Hidden Jobs",
    number: 0,
    filterField: "hideThisJob",
    filterValue: "Yes",
  },
  {
    icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
    title: "All",
    tooltip: "All Jobs",
    number: 0,
    filterField: "status",
    filterValue: "",
  },
];

const JobsFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentJob } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({ initialCards, filters, entity: "jobs", refreshBadges });

  const navigate = useNavigate();
  const { jobSlug } = useParams();

  const setJobsFilters = (filter) => {
    if (jobSlug) navigate(`/companyjobs`);
    setCurrentJob(null);
    const newFilters = { ...filters };
    delete newFilters.jobSlug;
    setFilters({ ...newFilters, ...filter });
    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  const initialCardIndex = initialCards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setJobsFilters}
        setPage={setPage}
        initialIndex={initialCardIndex}
      />
    </span>
  );
};

export default JobsFilterCards;

JobsFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

// Typechecking props for the TableAction
JobsFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
