import * as yup from "yup";

const applicantLicenseSchema = yup.object().shape({
  licenseType: yup
    .mixed()
    .typeError("Invalid License Type Input")
    .nullable()
    .required("License Type is Required"),
  licenseStatus: yup
    .string()
    .typeError("Invalid License Status Input")
    .nullable()
    .required("License Status is Required"),
  licenseExpDate: yup
    .date()
    .typeError("Invalid Expiration Date Input")
    .nullable()
    .required("Expiration Date Required")
    .test({
      name: "test-expiry-date",
      test: (value, ctx) => {
        const status = ctx.parent.licenseStatus;

        if (status !== "Expired" && value > new Date()) {
          return true;
        }

        if (status === "Expired" && value <= new Date()) {
          return true;
        }

        return ctx.createError({ message: `Expiration date invalid for ${status} license` });
      },
    }),
  thirtyDayAlert: yup.string().typeError("Invalid Thirty-day Alert Input").nullable(),
});

export default applicantLicenseSchema;
