import { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";

const defaultLocValues = {
  address: "",
  city: "",
  label: "",
  state: "",
  zip: "",
};

const extraLocs = [
  { label: "Online - Zoom" },
  { label: "Online - Google Meet" },
  {
    label: "Online - Microsoft Teams",
  },
];

const SelectInterviewLocation = ({
  disabled = false,
  inputProps = {},
  value,
  onChange,
  isOptionEqualToValue,
  currentEventId,
  company,
  currentCompany,
  selectedCompany,
  selectedInterviewType,
}) => {
  const [interviewLocations, setInterviewLocations] = useState();
  const defaultLoc = [
    {
      ...defaultLocValues,
      label: `${company?.name} Main Location`,
      address: company?.address,
      city: company?.city,
      state: company?.state,
      zip: company?.zip,
    },
  ];

  useEffect(() => {
    const addCompanyLocs = (locs) => {
      const companyLocations = locs?.map((location) => ({
        label: location?.locationName,
        address: location?.address,
        city: location?.city,
        state: location?.state,
        zip: location?.zip,
      }));
      return companyLocations;
    };

    let newLocs = [];

    if (selectedInterviewType === `Onsite ${company?.name}`) {
      const compLocs = addCompanyLocs(company?.locations) || [];
      newLocs = [...defaultLoc, ...newLocs, ...compLocs];
    }

    if (selectedInterviewType === `Onsite ${selectedCompany}`) {
      const eventLoc = currentCompany?.name
        ? {
            label: `${selectedCompany} Main Location`,
            address: currentCompany?.address,
            city: currentCompany?.city,
            state: currentCompany?.state,
            zip: currentCompany?.zip,
          }
        : {};
      const custLocs = addCompanyLocs(currentCompany?.locations) || [];

      newLocs = [...newLocs, eventLoc, ...custLocs];
    }

    if (selectedInterviewType === "Online") {
      newLocs = [...newLocs, ...extraLocs];
    }

    setInterviewLocations(newLocs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.locations, currentCompany?.locations, selectedInterviewType, company?.name]);

  return (
    <Autocomplete
      options={interviewLocations || []}
      disabled={disabled}
      name="interviewLocation"
      value={value || null}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <FormField
          key={`interviewLocation_${currentEventId}`}
          {...params}
          type="text"
          label="Interview Location"
          InputLabelProps={inputProps}
        />
      )}
    />
  );
};

export default SelectInterviewLocation;
