import BusinessIcon from "@mui/icons-material/Business";
import { IconButton, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import createActivity from "layouts/pages/activities/actions/createActivity";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { APPLICANT_COMPANY_STATUS_ENUM } from "utils/constants/applicant";

const statusColor = {
  None: "dark",
  "On Assignment": "success",
  Pending: "warning",
  Locked: "error",
  Inactive: "secondary",
  Dnu: "error",
  Terminated: "error",
};

const CompanyStatusButton = ({ status = "None", company, onUpdate, applicant, parent }) => {
  const { currentApplicant, setCurrentApplicant, currentLoggedUser } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const activeApplicant = applicant || currentApplicant;

  const { mutate: createActivityMutation } = useMutation(createActivity);

  const changeStatusMutation = useMutation(updateApplicant, {
    onSuccess: (_, { data }) => {
      if (isEmpty(applicant)) {
        setCurrentApplicant({ ...currentApplicant, ...data });
      }
    },
    mutationKey: "companyStatusMutation",
  });

  const handleUpdate = useCallback((newStatus) => {
    // -------------------------------------------------
    // Company status cannot be changed directly anymore
    // -------------------------------------------------
    // let appCompanies = activeApplicant?.companies;
    // const { slug } = company;
    // if (appCompanies) {
    //   if (newStatus !== APPLICANT_COMPANY_STATUS_ENUM.NONE) {
    //     const [findCompany] = appCompanies.filter((ven) => ven.companySlug === slug);
    //     if (findCompany) {
    //       findCompany.status = newStatus;
    //       findCompany.createAgent = currentLoggedUser?._id;
    //       findCompany.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    //     } else {
    //       appCompanies.push({
    //         status: newStatus,
    //         companySlug: slug,
    //         dateModified: new Date(),
    //         createAgent: currentLoggedUser?._id,
    //         agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
    //       });
    //     }
    //   } else appCompanies = appCompanies.filter((item) => item.companySlug !== slug);
    // } else {
    //   appCompanies = [
    //     {
    //       status: newStatus,
    //       companySlug: slug,
    //       dateModified: new Date(),
    //       createAgent: currentLoggedUser?._id,
    //       agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
    //     },
    //   ];
    // }
    // let employmentStatus;
    // if (appCompanies.some((item) => item.status === "On Assignment"))
    //   employmentStatus = "On Assignment";
    // else if (appCompanies.some((item) => item.status === "DNU")) employmentStatus = "DNU";
    // else if (currentApplicant?.employmentStatus === "On Assignment")
    //   employmentStatus = "Inactive";
    // else if (currentApplicant?.employmentStatus)
    //   employmentStatus = currentApplicant.employmentStatus;
    // else employmentStatus = "Inactive";
    // changeStatusMutation.mutate(
    //   {
    //     applicantId: activeApplicant._id,
    //     data: { companies: appCompanies, employmentStatus },
    //   },
    //   {
    //     onSuccess: () => {
    //       enqueueSnackbar(
    //         `${activeApplicant.firstName} ${activeApplicant.lastName} has been set to: ${newStatus} for ${company.slug}`,
    //         { variant: statusColor[newStatus] }
    //       );
    //       if (company?._id && company?.slug) {
    //         createActivityMutation({
    //           action: "Company Status",
    //           description: `Set Company Status for ${company.slug} to ${newStatus}`,
    //           companyId: company._id,
    //           applicantId: activeApplicant?._id,
    //         });
    //       }
    //       onUpdate?.();
    //     },
    //   }
    // );
  }, []);

  const options = [
    {
      text: "None",
      icon: <BusinessIcon fontSize="medium" color={statusColor.None} />,
      handleClick: () => handleUpdate(APPLICANT_COMPANY_STATUS_ENUM.NONE),
      disabled: APPLICANT_COMPANY_STATUS_ENUM.NONE === status,
    },
    {
      text: "On Assignment",
      icon: (
        <BusinessIcon
          fontSize="medium"
          color={statusColor[APPLICANT_COMPANY_STATUS_ENUM.ON_ASSIGNMENT]}
        />
      ),
      handleClick: () => handleUpdate(APPLICANT_COMPANY_STATUS_ENUM.ON_ASSIGNMENT),
      disabled: APPLICANT_COMPANY_STATUS_ENUM.ON_ASSIGNMENT === status,
    },
    {
      text: "Pending",
      icon: (
        <BusinessIcon
          fontSize="medium"
          color={statusColor[APPLICANT_COMPANY_STATUS_ENUM.PENDING]}
        />
      ),
      handleClick: () => handleUpdate(APPLICANT_COMPANY_STATUS_ENUM.PENDING),
      disabled: APPLICANT_COMPANY_STATUS_ENUM.PENDING === status,
    },
    {
      text: "Inactive",
      icon: (
        <BusinessIcon
          fontSize="medium"
          color={statusColor[APPLICANT_COMPANY_STATUS_ENUM.INACTIVE]}
        />
      ),
      handleClick: () => handleUpdate(APPLICANT_COMPANY_STATUS_ENUM.INACTIVE),
      disabled: APPLICANT_COMPANY_STATUS_ENUM.INACTIVE === status,
    },
    // {
    //   text: "Dnu",
    //   icon: (
    //     <BusinessIcon fontSize="medium" color={statusColor[APPLICANT_COMPANY_STATUS_ENUM.DNU]} />
    //   ),
    //   handleClick: () => handleUpdate(APPLICANT_COMPANY_STATUS_ENUM.DNU),
    //   disabled: APPLICANT_COMPANY_STATUS_ENUM.DNU === status,
    // },
    // {
    //     text: "Terminated",
    //     icon: <BusinessIcon fontSize="medium" color={statusColor[APPLICANT_COMPANY_STATUS_ENUM.TERMINATED]} />,
    //     handleClick: () => handleUpdate(APPLICANT_COMPANY_STATUS_ENUM.TERMINATED),
    //     disabled: APPLICANT_COMPANY_STATUS_ENUM.TERMINATED === status,
    // },
  ];
  const filteredOptions =
    parent === "Applicant" ? options.filter((item) => item.text !== "On Assignment") : options;

  return (
    <>
      <Tooltip
        title={`${activeApplicant?.firstName} ${activeApplicant?.lastName} is ${
          status === "None" ? "Not Associated" : status
        }`}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{ cursor: "default" }}
          disableRipple
        >
          <BusinessIcon fontSize="large" color={statusColor[status]} />
        </IconButton>
      </Tooltip>
      <CustomMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        options={filteredOptions}
      />
    </>
  );
};

export default CompanyStatusButton;
