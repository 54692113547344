import * as yup from "yup";
// import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchUserByEmail from "layouts/pages/users/actions/fetchUserByEmail";

export const createUserSchema = (outsideMode = "") =>
  yup.object().shape({
    emailAddress: yup
      .string()
      .default("")
      // eslint-disable-next-line
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid Email Address."
      )
      .test({
        name: "emailNotRegistered",
        async test(value, ctx) {
          if (value) {
            const user = await fetchUserByEmail(value, outsideMode);
            if (!user) {
              return ctx.createError({ message: "This email isn't registered" });
            }
            return true;
          }
          return true;
        },
      })
      .test({
        name: "gmailDomain",
        async test(value, ctx) {
          const domain = value.split("@", 2);
          if (domain[1] === "gmail.com") {
            return ctx.createError({ message: "Login with Google (below)" });
          }
          return true;
        },
      })
      .test({
        name: "accountAlreadyExist",
        async test(value, ctx) {
          const { loginVerified } = await fetchUserByEmail(value, outsideMode);
          if (loginVerified === "Yes") {
            return ctx.createError({
              message: `Account Exists.  Click "Login here" to login or change password`,
            });
          }
          return true;
        },
      })
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      // eslint-disable-next-line
      .matches(
        /(?=.*[a-z])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}/,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
      ),
    passwordConfirm: yup
      .string()
      .default("")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
