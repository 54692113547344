import { useAppContextController } from "context/AppContext";
import { useMemo } from "react";

const defaultValue = {
  minStage: "Screened",
  allowedStages: ["Screened", "Pre-Hire"],
  disallowedStages: ["New", "ATC", "Declined"],
};

const allStages = ["New", "ATC", "Screened", "Pre-Hire"];

const useMinStageToOnboarding = () => {
  const { company } = useAppContextController();

  const minStage = company?.minStageToOnboarding ?? "Screened";
  const [allowedStages, disallowedStages] = useMemo(() => {
    const aStag = [];
    const dStag = ["Declined"];
    let stageFound = false;
    for (let index = 0; index < allStages.length; index += 1) {
      const stag = allStages[index];
      if (stag === minStage) {
        stageFound = true;
      }
      if (stageFound) {
        aStag.push(stag);
      } else {
        dStag.push(stag);
      }
    }
    return [aStag, dStag];
  }, [minStage]);

  return { minStage, allowedStages, disallowedStages };
};

export default useMinStageToOnboarding;
