// api to check if applicant/employee meets the requirements for helm
import { baseAxios } from "config";

const checkHelmSyncAllowed = async (id) => {
  try {
    const res = await baseAxios.get(`/helm/employee/check/${id}`);
    return res;
  } catch (err) {
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};

export default checkHelmSyncAllowed;
