import axios from "axios";

import { baseAxios } from "config";

const fetchBanners = async () => {
  try {
    const res = await baseAxios.get(`/upload/directory/applicantBanners`);

    if (res?.data?.data?.length) {
      // const retData = res.data.data.map((job) => {
      //   return { ...job, logoUrl: venues?.[job?.venueSlug]?.logoUrl, venueName: venues?.[job?.venueSlug]?.name };
      // });
      // res.data = { ...res.data, data: [...retData] };
      res.data = { ...res.data };
    }

    return res.data.files;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchBanners;
