import WorkIcon from "@mui/icons-material/Work";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import CustomMenu from "components/CustomizedMui/CustomMenu";
import { APPLICANT_JOB_STATUS_ENUM } from "utils/constants/applicant";
import ConfirmationModal from "components/ConfirmationModal";
import updateApplicantEmploymentStage from "layouts/pages/applicants/actions/updateApplicantEmploymentStage";
import ApplicantJobStatusFilledModal from "components/ApplicantJobStatusButton/ApplicantJobStatusFilledModal";
import SubmittalModal from "layouts/pages/companyjobs/components/CompanyJobsStatus/SubmittalModal";
import InterviewModal from "layouts/pages/applicants/components/InterviewModal";
import { useAppContextController } from "context/AppContext";
import fetchSingleJob from "layouts/pages/jobs/actions/fetchSingleJob";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import { Cancel } from "@mui/icons-material";

const statusColor = {
  Remove: "error",
  None: "dark",
  Submitted: "warning",
  Interview: "success",
  Declined: "error",
  Filled: "info",
  Waitlist: "violet",
  Terminated: "error",
  Completed: "primary",
};

const ApplicantJobStatusButton = ({
  status = "None",
  job,
  applicant,
  refresh,
  viewOnly = false,
  currentCustomer,
  onChangeStatus,
  isJobFull,
  sx,
}) => {
  const queryClient = useQueryClient();
  const { currentJob, setCurrentJob } = useAppContextController();

  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [isJobStatusFilledModalOpen, toggleJobStatusFilledModal] = useState(false);
  const [isSubmittalModalOpen, toggleSubmittalModal] = useState(false);
  const [isCreateInterviewModalOpen, toggleCreateInterviewModal] = useState(false);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [currentStatusForNote, setCurrentStatusForNote] = useState("");

  const changeStageMutation = useMutation(updateApplicantEmploymentStage, {
    onSuccess: (_, { applicantId, status: newStatus }) => {
      if (refresh) {
        refresh({
          applicantId: applicant?._id,
          employmentStage: newStatus,
        });
      }
      if (onChangeStatus) {
        onChangeStatus({
          applicantId,
          employmentStage: newStatus,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error?.response?.data?.message ?? error.message, { variant: "error" });
    },
    mutationKey: "applicantEmploymentStageChangeMutation",
  });

  const updateCurrentJobPositions = async () => {
    const data = await queryClient.fetchQuery({
      queryKey: ["refreshJob", currentJob?._id],
      queryFn: () => fetchSingleJob(null, currentJob?._id),
    });

    const updatedJob = data?.data?.job;
    if (updatedJob) {
      setCurrentJob({
        ...currentJob,
        positions: updatedJob?.positions
          ? updatedJob?.positions
          : [...Array(updatedJob?.positionQuantity ?? 1).keys()].map((_, index) => ({
              status: "Open",
              name: `Position ${index + 1}`,
              startDate: null,
              modifiedDate: new Date(),
            })),
        positionStatus: updatedJob?.positionStatus,
        notes: updatedJob?.notes,
      });
    }
  };

  const handleUpdateStageWithNote = (newStatus) => {
    setCurrentStatusForNote(newStatus);
    setNoteModalOpen(true);
  };

  const handleUpdateStage = (newStatus, startDate, jobNote) => {
    const { jobSlug } = job;
    const data = {
      applicantId: applicant._id,
      status: newStatus,
      jobSlug,
    };
    if (newStatus === APPLICANT_JOB_STATUS_ENUM.FILLED && startDate) {
      data.startDate = startDate;
    }
    if (jobNote) {
      data.jobNote = jobNote;
    }
    changeStageMutation.mutate(data, {
      onSuccess: () => {
        enqueueSnackbar(
          newStatus === APPLICANT_JOB_STATUS_ENUM.REMOVE
            ? `${applicant.firstName} ${applicant.lastName} has been removed from ${job.jobSlug}`
            : `${applicant.firstName} ${applicant.lastName} has been set to: ${newStatus} for ${job.jobSlug}`,
          { variant: "success" }
        );
        updateCurrentJobPositions();
        toggleConfirmationModal(false);
      },
    });
  };

  const saveNote = (noteRec) => {
    handleUpdateStage(currentStatusForNote, null, noteRec.text);
  };

  const options = [
    {
      text: "Remove",
      icon: <Cancel fontSize="medium" color={statusColor.Remove} />,
      handleClick: () => toggleConfirmationModal(true),
      disabled: false,
    },
    {
      text: "Applied",
      icon: <WorkIcon fontSize="medium" color={statusColor.None} />,
      handleClick: () => handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.NONE),
      disabled: APPLICANT_JOB_STATUS_ENUM.NONE === status,
    },
    {
      text: "Submitted",
      icon: <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.SUBMITTED]} />,
      // handleClick: () => handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.SUBMITTED),
      handleClick: () => toggleSubmittalModal(true),
      disabled: APPLICANT_JOB_STATUS_ENUM.SUBMITTED === status,
    },
    {
      text: "Interview",
      icon: <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.INTERVIEW]} />,
      // handleClick: () => handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.INTERVIEW),
      handleClick: () => toggleCreateInterviewModal(true),
      disabled: APPLICANT_JOB_STATUS_ENUM.INTERVIEW === status,
    },
    {
      text: "Declined",
      icon: <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.DECLINED]} />,
      handleClick: () => handleUpdateStageWithNote(APPLICANT_JOB_STATUS_ENUM.DECLINED),
      disabled: APPLICANT_JOB_STATUS_ENUM.DECLINED === status,
    },
    {
      text: "Filled",
      icon: <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.FILLED]} />,
      handleClick: () => toggleJobStatusFilledModal(true),
      disabled: isJobFull || APPLICANT_JOB_STATUS_ENUM.FILLED === status,
      tooltip:
        isJobFull && APPLICANT_JOB_STATUS_ENUM.FILLED !== status ? "No open positions" : null,
    },
    {
      text: "Waitlist",
      icon: <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.WAITLIST]} />,
      handleClick: () => handleUpdateStageWithNote(APPLICANT_JOB_STATUS_ENUM.WAITLIST),
      disabled: APPLICANT_JOB_STATUS_ENUM.WAITLIST === status,
    },
    {
      text: "Terminated",
      icon: (
        <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.TERMINATED]} />
      ),
      handleClick: () => handleUpdateStageWithNote(APPLICANT_JOB_STATUS_ENUM.TERMINATED),
      disabled: APPLICANT_JOB_STATUS_ENUM.TERMINATED === status,
    },
    {
      text: "Completed",
      icon: <WorkIcon fontSize="medium" color={statusColor[APPLICANT_JOB_STATUS_ENUM.COMPLETED]} />,
      handleClick: () => handleUpdateStageWithNote(APPLICANT_JOB_STATUS_ENUM.COMPLETED),
      disabled: APPLICANT_JOB_STATUS_ENUM.COMPLETED === status,
    },
  ];

  return (
    <>
      <Tooltip
        title={`${applicant?.firstName} ${applicant?.lastName} is ${
          status === "None" ? "Applied" : status
        }`}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!viewOnly) {
              setAnchorEl(e.currentTarget);
            }
          }}
          sx={{ ...(viewOnly && { cursor: "default" }), ...sx }}
        >
          <WorkIcon fontSize="large" color={statusColor[status]} />
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title={`Are you sure you want to remove ${applicant?.firstName} ${applicant?.lastName} from ${job.title}?`}
        onConfirm={(e) => {
          e.stopPropagation();
          handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.REMOVE);
          toggleConfirmationModal(false);
        }}
      />
      <ApplicantJobStatusFilledModal
        open={isJobStatusFilledModalOpen}
        setOpen={toggleJobStatusFilledModal}
        applicantName={`${applicant?.firstName} ${applicant?.lastName}`}
        handleUpdateStage={handleUpdateStage}
      />
      <SubmittalModal
        applicant={applicant}
        open={isSubmittalModalOpen}
        setOpen={toggleSubmittalModal}
        handleUpdateStage={handleUpdateStage}
      />
      <InterviewModal
        setModalOpen={toggleCreateInterviewModal}
        isModalOpen={isCreateInterviewModalOpen}
        currentCustomer={currentCustomer}
        currentJob={job}
        currentApplicant={applicant}
        onSaveStatus={() => {
          handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.INTERVIEW);
          toggleCreateInterviewModal(false);
        }}
      />
      {notesModalOpen && (
        <AddANoteModal
          currentApplicant={applicant}
          open={notesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNoteModalOpen}
          saveNote={saveNote}
          showType={false}
          defaultValue={`${applicant?.firstName} ${applicant?.lastName} status changed to ${currentStatusForNote} for job ${job?.jobSlug}`}
          onExtraAction={() => handleUpdateStage(currentStatusForNote)}
          extraActionLabel="Change Status Only"
        />
      )}
    </>
  );
};

export default ApplicantJobStatusButton;
