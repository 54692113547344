import * as yup from "yup";

import eventStatuses from "assets/eventStatuses.json";
import eventTypes from "assets/eventTypes.json";

export const eventInfoSchema = yup.object().shape({
  eventName: yup.string().required("Event name is required"),
  eventType: yup
    .string()
    .default(eventTypes[0])
    .required("Event type is required")
    .test("validEventType", "Insert a valid Event Type", (value) => {
      if (value) {
        eventTypes.includes(value);
      }
      return true;
    }),
  logoUrl: yup.string(),
  venueSlug: yup.string().required("Venue slug is required"),
  venueName: yup.string().required("Venue name is required"),
  secondaryLocation: yup.mixed(),
  address: yup.string(),
  venueCity: yup.string(),
  venueState: yup.string(),
  zip: yup.string(),
  eventId: yup.string(),
  eventUrl: yup.string().required("Event URL is required"),
  status: yup
    .string()
    .default(eventStatuses[0])
    .required("Status is required")
    .test("validEventType", "Insert a valid status", (value) => {
      if (value) {
        eventStatuses.includes(value);
      }
      return true;
    }),
  positions: yup.mixed(),
  eventDate: yup.string().required("Event Date is required").nullable(),
  eventEndTime: yup.string().required("Est. End Time is required").nullable(),
  reportTimeTBD: yup.string(),
});

export const eventSettings = yup.object().shape({
  positionsRequested: yup.string(),
  billRate: yup.string(),
  payRate: yup.string(),
  makePublicAndSendNotification: yup.string(),
  sendConfirmationToSignUps: yup.string(),
  waitListPercentage: yup.string(),
  eventLogo: yup.string(),
  jobLogo: yup.string(),
  venueBanner: yup.string(),
  googleMap: yup.string(),
  allowPartners: yup.string(),
  allowEarlyClockin: yup.string(),
  allowWaitList: yup.string(),
  allowNonHired: yup.string(),
  reminder48Hour: yup.string(),
  reminder24Hour: yup.string(),
});

export const eventContent = yup.object().shape({
  description: yup.string(),
  tags: yup.array().of(yup.string()).default([]),
});
