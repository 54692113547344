import { useMemo, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import fetchUserActivities from "api/activities/fetchUserActivities";
import ActivitiesDataList from "../ActivitiesDataList";

const UserActivities = ({ user, filters, height, tableHeight, pageSize }) => {
  const [page, setPage] = useState(1);
  const options = useMemo(() => {
    return {
      fetchAll: false,
      orderBy: "activityDate",
      order: "desc",
      limit: pageSize || 10,
      filters: { ...filters },
    };
  }, [filters, pageSize]);

  const allOptions = useMemo(() => {
    return { ...options, applicantId: user?._id, page };
  }, [page, user?._id, options]);

  const {
    data: activities,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ["userActivities", allOptions],
    ({ queryKey, pageParam }) => fetchUserActivities({ ...allOptions, page: pageParam ?? 1 }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user?._id,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
          return undefined;
        }

        const nextPage = lastPage?.pagination?.next?.page;
        return nextPage ?? false;
      },
    }
  );

  const fullData = useMemo(
    () =>
      activities?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [activities?.pages]
  );

  return (
    <ActivitiesDataList
      isLoading={isLoading}
      activities={activities?.pages?.[activities?.pages?.length - 1]}
      height={height}
      tableHeight={tableHeight}
      page={page}
      setPage={setPage}
      fetchNextPage={fetchNextPage}
      fullData={fullData}
      isFetching={isFetching}
    />
  );
};

export default UserActivities;
