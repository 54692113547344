import PollIcon from '@mui/icons-material/Poll';
import { Box, Typography } from '@mui/material';
import CustomCard from 'components/CustomCard'
import { cardStyles } from "layouts/pages/events/styles";
import { useAppContextController } from "context/AppContext";

const EventReportData = () => {

    const { currentEvent } = useAppContextController();
    console.log("currentEVent", currentEvent)
    return (
        <CustomCard
            icon={<PollIcon color="white" />}
            cardTitle="Event Report Data"
            cardSx={cardStyles.card}
            titleSx={cardStyles.title}
            actionsSx={cardStyles.actions}
            iconContainerSx={{ backgroundColor: "secondary", mt: -3, ml: "1.5rem" }}
        >
            <Box sx={cardStyles.container}>
                <Typography>
                    Event report Panel
                </Typography>
            </Box>
        </CustomCard>
    )
}

export default EventReportData