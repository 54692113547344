import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import useBadgeValues from "hooks/useBadgeValues";

import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import PublicIcon from "@mui/icons-material/Public";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BlockIcon from "@mui/icons-material/Block";

export const initialCards = [
  {
    color: "info",
    icon: <ToggleOnIcon fontSize="large" color="info" />,
    title: "Active",
    tooltip: "Active Partners",
    number: 0,
    filterField: "status",
    filterValue: "Active",
    canCombine: true,
    default: true,
  },
  {
    color: "secondary",
    icon: <ToggleOffIcon fontSize="large" color="secondary" />,
    title: "Inactive",
    tooltip: "Inactive Partners",
    number: 0,
    filterField: "status",
    filterValue: "Inactive",
  },
  {
    color: "success",
    icon: <AttachMoneyIcon fontSize="large" color="success" />,
    title: "Prospect",
    tooltip: "Prospective Partners",
    number: 0,
    filterField: "status",
    filterValue: "Prospect",
  },
  // {
  //   color: "warning",
  //   icon: <QuestionMarkIcon fontSize="large" color="warning" />,
  //   title: "Questions",
  //   tooltip: "Question Partners",
  //   number: 0,
  //   filterField: "type",
  //   filterValue: "Question",
  //   canCombine: true,
  // },
  // {
  //   color: "info",
  //   icon: <EnhancedEncryptionIcon fontSize="large" color="info" />,
  //   title: "Enhance",
  //   tooltip: "Enhancement Requests",
  //   number: 0,
  //   filterField: "type",
  //   filterValue: "Enhancement",
  //   canCombine: true,
  // },
  {
    color: "dark",
    icon: <PublicIcon fontSize="large" color="black" />,
    title: "All",
    tooltip: "All Partners",
    number: 0,
    filterField: "status",
    filterValue: "",
  },
];

const PartnersFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentPartner, currentLoggedUser } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const navigate = useNavigate();

  const { cards } = useBadgeValues({ initialCards, filters, entity: "partners", refreshBadges });

  const setPartnerFilters = (filter) => {
    navigate("/partners");
    setCurrentPartner(null);
    setFilters(filter);
    setRefreshBadges((prev) => !prev);
  };

  const initialCardIndex = initialCards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setPartnerFilters}
        setPage={setPage}
        initialIndex={initialCardIndex}
      />
    </span>
  );
};

export default PartnersFilterCards;
