import { useState } from "react";
import useBadgeValues from "hooks/useBadgeValues";
import fetchCounts from "api/counts/fetchCounts";
import { useAppContextController } from "context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import FilterCardBar from "components/FilterCardBar";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import PublicIcon from "@mui/icons-material/Public";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StadiumIcon from "@mui/icons-material/Stadium";

const VenuesFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentVenue, currentLoggedUser } = useAppContextController();
  const myVenues =
    currentLoggedUser?.venues?.length && typeof currentLoggedUser.venues !== "string"
      ? currentLoggedUser.venues.join(";")
      : "";

  const initialCards = [
    {
      color: "info",
      icon: <ToggleOnIcon fontSize="large" color="info" />,
      title: "Active",
      tooltip: "Active Venues",
      default: true,
      number: 0,
      filterField: "status",
      filterValue: "Active",
      canCombine: true,
    },
    {
      color: "success",
      icon: <AttachMoneyIcon fontSize="large" color="success" />,
      title: "Prospect",
      tooltip: "Prospect Venues",
      number: 0,
      filterField: "status",
      filterValue: "Prospect",
    },
    {
      color: "error",
      icon: <ToggleOffIcon fontSize="large" color="error" />,
      title: "Inactive",
      tooltip: "Inactive Venues",
      number: 0,
      filterField: "status",
      filterValue: "Inactive",
    },
    {
      color: "info",
      icon: <StadiumIcon fontSize="large" color="info" />,
      title: "MyVenues",
      tooltip: "My Favorite Venues",
      number: 0,
      filterField: "slug",
      filterValue: myVenues,
      canCombine: true,
    },
    {
      color: "dark",
      icon: <PublicIcon fontSize="large" color="black" />,
      title: "All",
      tooltip: "All Venues",
      number: 0,
      filterField: "status",
      filterValue: "",
    },
  ];

  const [refreshBadges, setRefreshBadges] = useState(false);

  const { cards } = useBadgeValues({
    initialCards,
    fetchCounts,
    filters,
    entity: "venues",
    refreshBadges,
  });

  const navigate = useNavigate();
  const { slug } = useParams();

  const setVenueFilters = (filter) => {
    if (slug) navigate("/venues");
    setCurrentVenue(null);
    const newFilters = { ...filters };
    delete newFilters.slug;
    setFilters({ ...newFilters, ...filter });
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setVenueFilters}
        setPage={setPage}
      />
    </span>
  );
};

export default VenuesFilterCards;

// VenuesFilterCards.defaultProps = {
//   slug: "",
//   setActionSelected: () => {},
// };

// Typechecking props for the TableAction
// VenuesFilterCards.propTypes = {
//   slug: PropTypes.string,
//   setActionSelected: PropTypes.func,
//   // children: PropTypes.node.isRequired,
// };
