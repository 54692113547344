import axios from "axios";
import { baseAxios } from "config";

const fetchInterviews = async (jobSlug) => {
  try {
    const res = await baseAxios.get(`/events/jobs/${jobSlug}`);
    return res.data;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export default fetchInterviews;
