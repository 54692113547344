import { ArrowBack, ArrowForward, Event, EventNote, ImportExport, Work } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import exportEvents from "api/events/exportEvents";
import exportShiftJobs from "api/jobs/exportShiftJobs";
import CustomModal from "components/CustomizedMui/CustomModal";
import CustomStepper from "components/CustomizedMui/CustomStepper";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import usePayrollExportStore from "stores/usePayrollExportStore";
import PayrollMainContent from "./MainContent";

const EXPORT_STEPS = (type) => [
  {
    id: 1,
    label: "Select Date Range",
    altLabel: "Personal",
    stepObject: "date",
    icon: <EventNote width="18px" height="18px" color="white" />,
  },
  {
    id: 2,
    label: type === "shiftJobs" ? "Select Jobs" : "Select Events",
    altLabel: "Personal",
    stepObject: "events",
    icon:
      type === "shiftJobs" ? (
        <Work width="18px" height="18px" color="white" />
      ) : (
        <Event width="18px" height="18px" color="white" />
      ),
  },
  {
    id: 3,
    label: "Select Export Fields",
    altLabel: "Personal",
    stepObject: "exportFields",
    icon: <ImportExport width="18px" height="18px" color="white" />,
  },
];

const StyledButton = styled(Button)(() => ({
  maxWidth: "120px",
}));

const EVENT_THRESHOLD = 200;

const PayrollModal = ({ isOpen, toggleOpen, type = "events" }) => {
  const { currentLoggedUser } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [exportType, setExportType] = useState();

  const [activeStep, setActiveStep] = useState(1);
  const { eventIds, fields, setToDate, setFromDate } = usePayrollExportStore((state) => ({
    eventIds: state.eventIds,
    fields: state.fields,
    setToDate: state.setToDate,
    setFromDate: state.setFromDate,
  }));

  const exportSteps = useMemo(() => EXPORT_STEPS(type), [type]);

  const { company } = useAppContextController();

  const { mutateAsync: exportMutation } = useMutation(
    type === "shiftJobs" ? exportShiftJobs : exportEvents
  );

  const handleNextClick = async () => {
    if (activeStep >= exportSteps.length) {
      const BODY = {
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
        exportType,
      };
      if (eventIds.length <= EVENT_THRESHOLD) {
        enqueueSnackbar("Please wait, we are preparing your CSV...", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("We will send you a notification when your CSV has been generated.", {
          variant: "success",
          autoHideDuration: 5000,
        });
      }
      exportMutation(
        type === "shiftJobs"
          ? { ...BODY, exportType, jobIds: eventIds, fields }
          : { ...BODY, exportType, eventIds, fields },
        {
          onSuccess: (data) => {
            window.open(`${company?.imageUrl}${data.data.exportUrl}`, "_blank");
            toggleOpen(false);
          },
        }
      );
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const handlePreviousClick = () => {
    if (activeStep <= 1) {
      return;
    }
    setActiveStep((prev) => prev - 1);
  };

  useEffect(() => {
    setToDate(new Date());
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 30);
    setFromDate(currentDate);
  }, []);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => toggleOpen(false)}
      cardSx={{ maxWidth: "960px", maxHeight: "800px" }}
    >
      <FlexWrapper
        sx={{
          "& .MuiStepper-root": {
            py: 0,
          },
          gap: "15px",
        }}
      >
        <CustomStepper activeStepId={activeStep} steps={exportSteps} />
        <FlexWrapper sx={{ flexDirection: "column", alignItems: "stretch", flexGrow: 1 }}>
          <FlexWrapper justifyContent="space-between" mb={2}>
            <FlexWrapper sx={{ gap: "10px" }} justifyContent="space-between">
              <StyledButton
                type="button"
                variant="contained"
                onClick={handlePreviousClick}
                color="error"
                startIcon={<ArrowBack />}
                disabled={activeStep === 1}
              >
                Previous
              </StyledButton>
              <StyledButton
                type="button"
                variant="contained"
                onClick={handleNextClick}
                color={activeStep < exportSteps.length ? "success" : "primary"}
                endIcon={<ArrowForward />}
                disabled={
                  activeStep >= exportSteps.length || (activeStep === 2 && eventIds.length === 0)
                }
              >
                Next
              </StyledButton>
            </FlexWrapper>
            <FlexWrapper sx={{ gap: "10px" }} justifyContent="end">
              <StyledButton
                type="button"
                variant="contained"
                onClick={handleNextClick}
                color="primary"
                disabled={!(activeStep >= exportSteps.length)}
              >
                Export
              </StyledButton>
            </FlexWrapper>
          </FlexWrapper>
          <PayrollMainContent
            activeStep={activeStep}
            exportType={exportType}
            setExportType={setExportType}
            type={type}
          />
        </FlexWrapper>
      </FlexWrapper>
    </CustomModal>
  );
};

export default PayrollModal;
