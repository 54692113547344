import { Box, Grid, Link } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useEffect } from "react";
import parse from "html-react-parser";

const Congratulations = () => {
  const { updateButtons } = useNewApplicantContext();
  const { company } = useAppContextController();

  useEffect(() => {
    updateButtons({
      previous: {
        show: false,
        disabled: false,
      },
      next: {
        show: false,
        disabled: false,
      },
      submit: {
        show: false,
        disabled: false,
      },
    });
  }, []);

  return (
    <>
      <Grid container p={3}>
        <Grid item xs={12}>
          <MDTypography variant="h3">Congratulations!</MDTypography>
        </Grid>
        {company?.onboardingCompletionText ? (
          <Grid item xs={12} mt={3}>
            <Box sx={{ typography: 'body2' }} >{parse(company.onboardingCompletionText)}</Box>
            <MDTypography variant="body2">
              If you have not received your email after 48 hours from completion you may contact
              your recruiter or send us a message at:&nbsp;
              <Link
                variant="body2"
                fontWeight="bold"
                href={`mailto:${company?.companyEmail}`}
                color="info"
              >
                {company?.companyEmail}
              </Link>{" "}
            </MDTypography>
          </Grid>
        ) : (
          <Grid>
            <Grid item xs={12} mt={3}>
              <MDTypography variant="body2">
                You have completed the onboarding forms and will receive an email from{" "}
                {company?.name} as soon as your background screening is complete, and you can begin
              </MDTypography>
            </Grid>
            <Grid item xs={12} mt={3}>
              <MDTypography variant="body2">
                In the meantime, we thank you for applying with our organization and look forward to
                working together.
              </MDTypography>
            </Grid>
            <Grid item xs={12} mt={3}>
              <MDTypography variant="body2">
                If you have not received your email after 48 hours from completion you may contact
                your recruiter or send us a message at:&nbsp;
                <Link
                  variant="body2"
                  fontWeight="bold"
                  href={`mailto:${company?.companyEmail}`}
                  color="info"
                >
                  {company?.companyEmail}
                </Link>{" "}
              </MDTypography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Congratulations;
