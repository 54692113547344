import { ExpandMore } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { Button, Card, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import ConfirmationModal from "components/ConfirmationModal";
import CustomAccordion from "components/CustomizedMui/CustomAccordion";
import DataTable from "components/DataTable";
// import EmailTemplateModal from "components/EmailTemplateModal";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import updateEntityDropdown from "context/actions/updateEntityDropdown";
import fetchDropdowns from "api/setup/fetchDropdowns";
import DropdownEntitiesModal from "components/DropdownEntitiesModal";
import DropdownEntitiesTableActions from "layouts/pages/setup/components/DropdownEntitiesTableActions";
import useSort from "utils/useSort";
import useSessionAuth from "hooks/useSessionAuth";
import usePreferredPageSize from "hooks/usePreferredPageSize";
// import IssuesActionModal from "../IssuesActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  // addButton: {
  //   fontSize: 40,
  // },
});

const DropdownEntitiesTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  showEventStatus = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { currentLoggedUser } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [currentDropdownList, setCurrentDropdownList] = useState(null);
  const [openDropdownEntitiesModal, setOpenDropdownEntitiesModal] = useState(false);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  // const [isTableExpanded, toggleExpand] = useState(true);
  const [modalInfo, setModalInfo] = useState({ data: currentDropdownList });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const {
    data: dropdowns,
    isLoading,
    refetch: refreshTable,
  } = useQuery(["dropdowns", allOptions], () => fetchDropdowns(allOptions));

  const columns = [
    {
      title: "Dropdown Name",
      field: "name",
      customCell: (subject) => (
        <MDTypography variant="body">
          {subject?.length > 35 ? `${subject.substring(0, 35)}...` : subject}
        </MDTypography>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Page",
      field: "page",
    },
    {
      title: "Dropdown Values",
      field: "arrayValue",
      customCell: (arrayValue) => {
        if (!arrayValue?.length) return <div> </div>;
        if (typeof arrayValue[0] === "string") {
          return (
            <MDTypography variant="body">
              {arrayValue?.length > 7
                ? `${arrayValue?.slice(0, 7).join(", ")}...`
                : arrayValue?.join(", ")}
            </MDTypography>
          );
        }
        return (
          <MDTypography variant="body">
            {arrayValue?.length > 7
              ? `${arrayValue
                  ?.map((value) => Object.values(value)[1])
                  .slice(0, 7)
                  .join(", ")}...`
              : arrayValue?.map((value) => Object.values(value)[1]).join(", ")}
          </MDTypography>
        );
      },
    },
    {
      title: "Date Created",
      field: "dateCreated",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Last Updated",
      field: "dateModified",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Dropdown Template Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <DropdownEntitiesTableActions
          id={id}
          row={row}
          setCurrentDropdownList={setCurrentDropdownList}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          toggleEditModal={setOpenDropdownEntitiesModal}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "Name", field: "name" },
    { title: "Subject", field: "subject" },
    { title: "Modified", field: "dateModified" },
  ];

  const handleRowClick = (row, e) => {
    e.stopPropagation();
    setCurrentDropdownList(row);
    setOpenDropdownEntitiesModal(true);
  };

  const { logoutAuth0User } = useSessionAuth();

  const handleDelete = useCallback(async () => {
    if (currentDropdownList) {
      try {
        // const res = await deleteDropdown(currentDropdownList);
        const res = await updateEntityDropdown(currentDropdownList._id, {
          status: "Deleted",
          dateModified: new Date(),
        });
        if (res.status === 200) {
          await queryClient.invalidateQueries("dropdown");
          enqueueSnackbar(res.message || "Dropdown deleted.", { variant: "success" });
          refreshTable();
        } else {
          enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
        }
        toggleConfirmationModal(false);
      } catch (err) {
        toggleConfirmationModal(false);
        enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
        if (String(err).includes("401") || err?.response?.status === 401) {
          logoutAuth0User();
        }
      }

      setCurrentDropdownList(null);
    }
  }, [currentDropdownList]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} px={3}>
        {/* <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <ArrowDropDownCircleIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Dropdown Entities
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={6}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid> */}
        <Grid item sm={3} display="flex" alignItems="center">
          {showSearchBar && (
            <Searchbar
              fetch={fetchDropdowns}
              placeholder="Search Dropdowns"
              columns={columnsForSearch}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name", "subject"]}
              //                filterBy="userId"
              filterByColumn="_id"
            />
          )}
        </Grid>
        <Grid item sm={3} textAlign="end">
          <Button
            startIcon={<AddCircleRoundedIcon />}
            // className={classes.addButton}
            variant="text"
            onClick={() => {
              setCurrentDropdownList(null);
              setOpenDropdownEntitiesModal(true);
            }}
          >
            Add New Entity
          </Button>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={dropdowns}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={false}
          defaultSort="issueDate:desc"
        />
      </MDBox>

      {/* <EmailTemplateModal
        isOpen={isEmailTemplateOpen}
        template={currentTemplate}
        onClose={() => {
          setCurrentTemplate(null);
          toggleEmailTemplateModal(false);
          setContext(["Applicant"]);
        }}
        context={context}
        setContext={setContext}
      /> */}
      <DropdownEntitiesModal
        allDropdowns={dropdowns?.data}
        open={openDropdownEntitiesModal}
        setOpen={setOpenDropdownEntitiesModal}
        dropdown={currentDropdownList}
        onClose={() => {
          setCurrentDropdownList(null);
          setOpenDropdownEntitiesModal(false);
        }}
        refreshTable={refreshTable}
      />
      <ConfirmationModal
        title={`Are you sure you want to delete ${
          currentDropdownList?.name ? `'${currentDropdownList?.name}'` : "this dropdown"
        }?`}
        isOpen={isConfirmationOpen}
        onClose={() => {
          toggleConfirmationModal(false);
          setCurrentDropdownList(null);
        }}
        onConfirm={() => handleDelete()}
      />
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
DropdownEntitiesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Issues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
DropdownEntitiesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default DropdownEntitiesTable;
