import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import generateFiltersFromString from "utils/generateFiltersFromString";
import generateFilterString from "utils/generateFilterString";

const useHandleBackButtonMainPage = ({ baseUrl, screenName }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const backListUrl = searchParams.get("backToList");

  const filterParam = searchParams.get("filters");
  const pageParam = searchParams.get("page");
  const subFilterParam = searchParams.get("subfilters");
  const subPageParam = searchParams.get("subpage");

  const pageFromUrl = pageParam ? parseInt(pageParam, 10) : null;
  const filtersFromUrl = filterParam ? generateFiltersFromString(filterParam) : null;
  const subPageFromUrl = subPageParam ? parseInt(subPageParam, 10) : null;
  const subFiltersFromUrl = subFilterParam ? generateFiltersFromString(subFilterParam) : null;

  // Clear query params as soon as they appear
  useEffect(() => {
    if (filterParam || pageParam || subFilterParam || subPageParam) {
      const backDataUpdateObj = { ...Object.fromEntries(searchParams) };
      delete backDataUpdateObj.page;
      delete backDataUpdateObj.filters;
      delete backDataUpdateObj.subpage;
      delete backDataUpdateObj.subfilters;
      setSearchParams({ ...backDataUpdateObj }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParam, pageParam, subFilterParam, subPageParam]);

  // Navigate to a subtable, add backToList to url
  const navigateToListWithBack = (url, p, f) => {
    let extraUrlString = "";
    const hasQuestionMark = url.includes("?");
    if (backListUrl) {
      // There's a back URL already, keep it
      extraUrlString = `${hasQuestionMark ? "&" : "?"}backToList=${encodeURIComponent(
        backListUrl
      )}`;
    } else {
      extraUrlString = `${hasQuestionMark ? "&" : "?"}backToList=${encodeURIComponent(
        `${baseUrl}?page=${p}&filters=${generateFilterString(f)}`
      )}`;
    }

    navigate(`${url}${extraUrlString}`);
  };

  // Navigate to another screen, while stacking the current backTo
  const navigateToScreenWithBack = (url, subPage, subFilter) => {
    let extraUrlString = "";
    const hasQuestionMark = url.includes("?");
    let queryString = "";
    if (subPage) queryString = `${queryString}&subpage=${subPage}`;
    if (subFilter) queryString = `${queryString}&subfilters=${generateFilterString(subFilter)}`;
    if (backListUrl) {
      // There's a back URL. Stack it into the current backTo string
      queryString = `${queryString}&backToList=${encodeURIComponent(backListUrl)}`;
    }
    const currentBaseUrl = window.location.pathname;
    const finalBackTo = `${screenName}#${currentBaseUrl}${queryString.replace("&", "?")}`;
    extraUrlString = `${hasQuestionMark ? "&" : "?"}backTo=${encodeURIComponent(finalBackTo)}`;

    navigate(`${url}${extraUrlString}`);
  };

  return {
    backListUrl,
    filterParam,
    pageParam,
    subFilterParam,
    subPageParam,
    pageFromUrl,
    filtersFromUrl,
    subPageFromUrl,
    subFiltersFromUrl,
    navigateToListWithBack,
    navigateToScreenWithBack,
  };
};

export default useHandleBackButtonMainPage;
