import { useState } from "react";
import GenericModal from "components/GenericModal";
import { Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MDBox from "components/MDBox";
import { Cancel } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { useAppContextController } from "context/AppContext";

const CopyJobURLModal = ({ jobSlug, open, setOpen }) => {
  const { company } = useAppContextController();
  const WEB_URL = window.location.origin;
  const [url, setUrl] = useState(`${WEB_URL}/jobs/apply/${jobSlug}`);

  const changeUrl = (referralSource) => {
    setUrl(`${WEB_URL}/jobs/apply/${jobSlug}?referrer=${referralSource}`);
  };

  const header = (
    <Box display="flex" justifyContent="space-between">
      <MDTypography variant="h5">Select Job Site URL</MDTypography>

      <IconButton
        onClick={() => {
          setOpen(false);
          setUrl(`${WEB_URL}/jobs/apply/${jobSlug}`);
        }}
      >
        <Cancel />
      </IconButton>
    </Box>
  );
  const modalBody = (
    <Box>
      <Stack display="flex" direction="row" justifyContent="center">
        <MDBox
          component="img"
          sx={{
            height: "4rem",
            display: "block",
            overflow: "hidden",

            borderRadius: 5,
            padding: 1,
            objectFit: "contain",
          }}
          src={`${company?.imageUrl}/company/${company?.slug}/square-logo/${company?.squareLogoUrl}`}
          onClick={() => changeUrl("Company Website")}
        />
        <MDBox
          component="img"
          sx={{
            height: "4rem",
            display: "block",
            overflow: "hidden",

            borderRadius: 5,
            padding: 1,
            objectFit: "contain",
          }}
          src="/images/png/logos/facebook_icon.png"
          onClick={() => changeUrl("Facebook")}
        />
        <MDBox
          component="img"
          sx={{
            height: "4rem",
            display: "block",
            overflow: "hidden",
            borderRadius: 5,
            padding: 1,
            objectFit: "contain",
          }}
          src="/images/png/logos/linkedin.png"
          onClick={() => changeUrl("Linkedin")}
        />
        <MDBox
          component="img"
          sx={{
            height: "4rem",
            display: "block",
            overflow: "hidden",

            borderRadius: 5,
            padding: 1,
            objectFit: "contain",
          }}
          src="/images/png/logos/indeedIcon.png"
          onClick={() => changeUrl("Indeed")}
        />
        <MDBox
          component="img"
          sx={{
            height: "4rem",
            display: "block",
            overflow: "hidden",

            borderRadius: 5,
            padding: 1,
            objectFit: "contain",
          }}
          src="/images/png/logos/handshake-logo.png"
          onClick={() => changeUrl("Handshake")}
        />
        <MDBox
          component="img"
          sx={{
            height: "4rem",
            display: "block",
            overflow: "hidden",

            borderRadius: 5,
            padding: 1,
            objectFit: "contain",
          }}
          src="/images/png/logos/zip-recruiter.png"
          onClick={() => changeUrl("ZipRecruiter")}
        />
      </Stack>
      <MDBox mr={2}>
        <Stack direction="row" textAlign="center" mt={2}>
          <MDTypography fontWeight="bold" variant="body1" >
            URL:&nbsp;
          </MDTypography>
          <MDTypography variant="body1" overflow="overlay">{url}</MDTypography>
        </Stack>
      </MDBox>
    </Box>
  );

  const buttons = (
    <>
      <Button
        variant="contained"
        endIcon={<ContentCopyIcon />}
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      >
        Copy
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        width="25%"
      />
    </>
  );
};

export default CopyJobURLModal;
