import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import { IconButton, InputBase, Paper, styled, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { searchHelper } from "utils/helpers/searchHelper";
import { isUndefined } from "lodash";

const VenueSearch = ({ data, setData, fetchedData, setPage, applicantsFilters }) => {
  const { data: tempData } = data;
  const [searched, setSearched] = useState("");

  useEffect(() => {
    if (!searched && fetchedData && data) setData(fetchedData);
  }, [searched, fetchedData, data]); 

  useEffect(() => {
    if (isUndefined(fetchedData)) return;
    setData(fetchedData);
    setSearched("");
  }, [applicantsFilters, fetchedData]); 

  const onChangeHandler = (event) => setSearched(event.target.value);

  const resetSearchHandler = (event) => {
    setSearched("");
    setPage(1);
  };

  const searchedHandler = (event) => {
    if (!tempData) return;
    const {
      data: results,
      count,
      total,
    } = searchHelper(tempData, ["firstName", "lastName", "email", "phone"], searched);
    const fetchResults = searched ? { ...fetchedData, count, total, data: results } : fetchedData;
    setData(fetchResults);
    setPage(1);
  };

  const keyPressHandler = event => {
    if(event.key === "Enter"){
      event.preventDefault();
      searchedHandler();
    }
  }

  return (
    <SearchBox>
      <SearchPaper component="form">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Staff"
          inputProps={{ "aria-label": "search Staff" }}
          onChange={onChangeHandler}
          value={searched}
          onKeyPress={keyPressHandler}
        />
        {searched && (
          <IconButton onClick={resetSearchHandler}>
            <CloseIcon />
          </IconButton>
        )}
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={searchedHandler}>
          <SearchIcon />
        </IconButton>
      </SearchPaper>
    </SearchBox>
  );
};


const SearchBox = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    width: "90%"
  },
  [theme.breakpoints.up('md')]: {
    width: "50%"
  },
  [theme.breakpoints.up('lg')]: {
    width: 400,
    marginLeft: "1rem",
  },
}));

const SearchPaper = styled(Paper)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginBottom: 16,
}));

export default VenueSearch;
