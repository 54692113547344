import GppGoodIcon from '@mui/icons-material/GppGood';

export const newApplicantInitialState = {
  applicant: {},
  registrationSteps: [{
    id: 0,
    label: "Verification",
    altLabel: "verification",
    applicantObject: "verification",
    icon: <GppGoodIcon {...{
      width: "18px",
      height: "18px",
      color: "white",
    }} />,
  },],
  activeStepId: 0,
  activeStep: "verification",
  onboardingProgressId: 1,
  error: {},
  buttonState: {
    submit: {
      show: false,
      disabled: true,
    },
    previous: {
      show: false,
      disabled: true,
    },
    next: {
      show: false,
      disabled: true,
    },
  },
  currentFormState: {},
};

const newApplicantReducer = (state, action) => {
  switch (action.type) {
    case "SET_REGISTRATION_STEP":
      return {
        ...state,
        activeStepId: action.data.activeStepId,
        activeStep: action.data.step,
      };
    case "CREATE_APPLICANT":
      return {
        ...state,
        applicant: { ...state.applicant, ...action.data },
      };
    case "CREATE_I_9_FORM":
      return {
        ...state,
        i9Form: { ...state.i9Form, ...action.data },
      };
    case "UPDATE_APPLICANT":
      return {
        ...state,
        applicant: { ...state.applicant, ...action.data },
        error: {},
      };
    case "UPDATE_APPLICANT_ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "UPDATE_BUTTONS":
      return {
        ...state,
        buttonState: { ...state.buttonState, ...action.buttonState },
      };
    case "UPDATE_FORM_STATE":
      return {
        ...state,
        currentFormState: { ...state.currentFormState, ...action.currentFormState },
      };
    case "SET_REGISTRATION_STEPS":
      return {
        ...state,
        registrationSteps: action.data,
      };
    case "SET_APPLICANT":
      return {
        ...state,
        applicant: action.data,
      };
    case "SET_ONBOARDING_PROGRESS":
      return {
        ...state,
        onboardingProgressId: action.data,
      };
    default:
      return state;
  }
};

export default newApplicantReducer;
