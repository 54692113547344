import { Container as MuiContainer, styled } from '@mui/material'

const StyledContainer = styled(MuiContainer)(({ theme }) => ({
  maxWidth: 1278,
  position: 'relative',
  margin: '0 auto',
  zIndex: 10,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}))

const ContainerWrapper = (props) => <StyledContainer maxWidth={false} {...props} />

export default ContainerWrapper
