import { Box, Card, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { i9Schema } from "data/i9";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useAppContextController } from "context/AppContext";
import SignatureModal from "./SignatureModal";

const I9Form = ({ parent }) => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(i9Schema);
  const { company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [preparerOrTranslator1, setPreparerOrTranslator1] = useState(
    applicant?.i9Form?.preparerOrTranslator || ""
  );

  const { submit } = useForm({ resolver });
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    watch,
    reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: applicant?.i9Form
      ? applicant?.i9Form
      : {
          alienRegistrationNumber: "",
          preparerOrTranslator: "",
          citizenshipStatus: "",
          signature: "",
          processedDate: new Date().toISOString(),
          formI94AdmissionNumber: "",
          expirationDate: "",
          foreignPassportNumberAndCountryOfIssuance: "",
        },
  });

  const onSubmit = async ({
    alienRegistrationNumber,
    preparerOrTranslator,
    citizenshipStatus,
    signature,
    processedDate,
    formI94AdmissionNumber,
    expirationDate,
    foreignPassportNumberAndCountryOfIssuance,
  }) => {
    const parsedData = {
      i9Form: {
        alienRegistrationNumber,
        preparerOrTranslator,
        citizenshipStatus,
        signature,
        processedDate,
        formI94AdmissionNumber,
        foreignPassportNumberAndCountryOfIssuance,
        expirationDate,
      },
    };
    updateApplicantAction(applicant._id, parsedData);

    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };
  const externalSubmit = async () => {
    const result = await handleSubmit(onSubmit)();
  };

  const handleCitizenChange = (e) => {
    setValue("citizenshipStatus", e.target.value, { shouldDirty: true });
    setValue("alienRegistrationNumber", "");
    setValue("foreignPassportNumberAndCountryOfIssuance", "");
    setValue("formI94AdmissionNumber", "");
    setValue("expirationDate", "");
  };
  const handlePreparerOrTranslatorChange = (e) => {
    setPreparerOrTranslator1(e.target.value);
    setValue("preparerOrTranslator", e.target.value, { shouldDirty: true });
  };

  const validateFormForSigning = async (e) => {
    e.preventDefault();
    const isValidForSigning = await trigger();
    if (isValidForSigning && parent !== "onboarding") {
      setSignatureModalOpen(true);
    } else if (isValidForSigning && parent === "onboarding") {
      await handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid && applicant?.i9Form?.signature) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid, applicant?.i9Form?.signature]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      // reset();
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (applicant?.i9Form) {
      reset(applicant?.i9Form, { keepErrors: true });
    }
  }, [applicant]);

  return (
    <>
      <form
        onSubmit={(e) => {
          validateFormForSigning(e);
        }}
        id="current-form"
      >
        <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.firstName}
                variant="outlined"
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.middleInitial}
                variant="outlined"
                label="Middle Initial"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.lastName}
                variant="outlined"
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.maidenName}
                variant="outlined"
                label="Maiden Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={
                  applicant?.birthDate
                    ? new Date(
                        `${applicant?.birthDate.split("T")?.[0]} 00:00:000`
                      ).toLocaleDateString()
                    : ""
                }
                variant="outlined"
                label="Date of Birth"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <PatternFormat
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.socialSecurity}
                format="###-##-####"
                allowEmptyFormatting
                mask="_"
                label="Social Security"
                variant="outlined"
                onValueChange={({ value }) =>
                  setValue("socialSecurity", value, { shouldDirty: true })
                }
                customInput={CustomTextField}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.email}
                variant="outlined"
                label="Email Address"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <PatternFormat
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.phone}
                format="+1 (###) ### ####"
                allowEmptyFormatting
                mask="_"
                variant="outlined"
                label="Mobile Number"
                onValueChange={({ value }) =>
                  setValue("mobileNumber", value, { shouldDirty: true })
                }
                customInput={CustomTextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.address1}
                variant="outlined"
                label="Address"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.city}
                variant="outlined"
                label="City"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.state}
                variant="outlined"
                label="State"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.zip}
                variant="outlined"
                label="Zip Code"
              />
            </Grid>
          </Grid>
          <MDTypography variant="h6">Citizenship Status:</MDTypography>
          <Grid container>
            <Grid item xs={12}>
              <RadioGroup
                aria-labelledby={`citizenshipStatus_${applicant?.venueSlug}`}
                name="citizenshipStatus"
                value={watch("citizenshipStatus")}
                onChange={handleCitizenChange}
              >
                <Grid container>
                  <Grid item sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="US Citizen"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        1. A citizen of the United States{" "}
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="Non-Citizen"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        2. A non-citizen of the United States{" "}
                      </MDTypography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="Permanent Resident"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        3. A lawful permanent resident: (Alien Registration Number/USCIS Number){" "}
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Stack display="flex" direction="row" alignItems="center">
                          <FormControlLabel
                            value="Authorized Alien"
                            control={<Radio />}
                            sx={{ marginRight: "0" }}
                          />
                          <MDTypography fontWeight="bold" fontSize="0.875rem">
                            4. An alien authorized to work until expiration date{" "}
                          </MDTypography>
                        </Stack>
                      </Grid>
                      {watch("citizenshipStatus") === "Authorized Alien" && (
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="expirationDate"
                            control={control}
                            defaultValue={applicant?.i9Form?.expirationDate || ""}
                            render={({ field, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="Expiration Date if Any"
                                  renderInput={(params) => (
                                    <CustomTextField variant="outlined" {...params} />
                                  )}
                                  {...field}
                                  disablePast
                                  value={field.value}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>

              {watch("citizenshipStatus") === "Permanent Resident" && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="alienRegistrationNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="USICS or Alien Registration Number"
                        variant="outlined"
                        {...field}
                        onChange={(e) => {
                          setValue("alienRegistrationNumber", e.target.value, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {watch("citizenshipStatus") === "Authorized Alien" && (
                <Box>
                  <Box border="solid 1px gray" my={3} p={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MDTypography variant="h6">Enter one of these:</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Stack display="flex" direction="row" alignItems="center" spacing={2}>
                          <Controller
                            name="alienRegistrationNumber"
                            control={control}
                            render={({ field }) => (
                              <CustomTextField
                                label="USICS or Alien Registration Number"
                                variant="outlined"
                                {...field}
                                onChange={(e) => {
                                  setValue("alienRegistrationNumber", e.target.value, {
                                    shouldDirty: true,
                                  });
                                  setValue("foreignPassportNumberAndCountryOfIssuance", "", {
                                    shouldDirty: true,
                                  });
                                  setValue("formI94AdmissionNumber", "", { shouldDirty: true });
                                }}
                              />
                            )}
                          />
                          <MDTypography variant="h6">OR</MDTypography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Stack display="flex" direction="row" alignItems="center" spacing={2}>
                          <Controller
                            name="formI94AdmissionNumber"
                            control={control}
                            render={({ field }) => (
                              <CustomTextField
                                label="Form i-94 Admission Number"
                                variant="outlined"
                                {...field}
                                onChange={(e) => {
                                  setValue("formI94AdmissionNumber", e.target.value, {
                                    shouldDirty: true,
                                  });
                                  setValue("foreignPassportNumberAndCountryOfIssuance", "", {
                                    shouldDirty: true,
                                  });
                                  setValue("alienRegistrationNumber", "", { shouldDirty: true });
                                }}
                              />
                            )}
                          />
                          <MDTypography variant="h6">OR</MDTypography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="foreignPassportNumberAndCountryOfIssuance"
                          control={control}
                          render={({ field }) => (
                            <CustomTextField
                              label="Foreign Passport Number and Country of Issuance"
                              variant="outlined"
                              {...field}
                              onChange={(e) => {
                                setValue(
                                  "foreignPassportNumberAndCountryOfIssuance",
                                  e.target.value,
                                  { shouldDirty: true }
                                );
                                setValue("formI94AdmissionNumber", "", { shouldDirty: true });
                                setValue("alienRegistrationNumber", "", { shouldDirty: true });
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <MDTypography variant="h6">
            Preparer and/or Translator Certification (check one):
          </MDTypography>
          <Grid container>
            <Grid item xs={12}>
              <RadioGroup
                aria-labelledby={`preparerOrTranslator_${applicant?.venueSlug}`}
                name="radio-buttons-group"
                value={preparerOrTranslator1}
                onChange={handlePreparerOrTranslatorChange}
              >
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel value="1" control={<Radio />} sx={{ marginRight: "0" }} />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        1. A preparer or translator was not used while filling this form{" "}
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel value="2" control={<Radio />} sx={{ marginRight: "0" }} />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        2. A preparer or translator was used while filling this form{" "}
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              {applicant?._id && applicant?.i9Form?.signature && (
                <>
                  <MDTypography fontWeight="bold" fontSize="0.875rem">
                    E-Signature
                  </MDTypography>

                  <Card style={{ width: "45%" }}>
                    <img
                      src={`${IMAGE_SERVER}/applicants/${applicant?._id}/signature/${
                        applicant?.i9Form?.signature
                      }?${Date.now()}`}
                      alt="signature"
                      style={{ width: "100%" }}
                    />
                  </Card>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled
                  label={applicant?.i9Form?.processedDate ? "Processed Date" : "Today's Date"}
                  renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                  value={watch("processedDate")}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </FlexWrapper>
        <FormErrors errors={errors} />
      </form>
      <SignatureModal
        applicant={applicant}
        setSignatureModalOpen={setSignatureModalOpen}
        signatureModalOpen={signatureModalOpen}
        updateApplicantAction={updateApplicantAction}
        setValue={setValue}
        externalSubmit={externalSubmit}
      />
    </>
  );
};

export default I9Form;
