import { getCompanyBaseImageUrl } from "utils/helpers/upload";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CompanyLogoSquare = ({
  company,
  defaultCompany,
  bigLogo = false,
  height = 60,
  width = 60,
  parent,
}) => {
  const { name, slug } = company || { settings: null, name: null, slug: null };
  let src = "";
  const companyUrl = getCompanyBaseImageUrl(company);
  const defaultUrl = getCompanyBaseImageUrl(defaultCompany);

  if (!bigLogo) {
    if (company?.squareLogoUrl) src = `${companyUrl}/square-logo/${company.squareLogoUrl}`;
    else if (defaultCompany?.squareLogoUrl)
      src = `${defaultUrl}/square-logo/${defaultCompany.squareLogoUrl}`;
    else if (defaultCompany?.logoUrl) src = `${defaultUrl}/logo/${defaultCompany.logoUrl}`;
  } else if (company?.logoUrl) {
      src = `${companyUrl}/logo/${company.logoUrl}`;
    }
    else if (defaultCompany?.logoUrl) {
      src = `${defaultUrl}/logo/${defaultCompany?.logoUrl}`
    }

  const alt = name;

  if (!src) return null;

  return (
    <Tooltip title={company?.name || defaultCompany?.name}>
      <img
        width={bigLogo ? undefined : width}
        height={bigLogo ? undefined : height}
        src={src}
        alt={alt}
      />
    </Tooltip>
  );
};

export default CompanyLogoSquare;
