import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Description, MoodBad, Payment, Portrait, ShoppingBag } from "@mui/icons-material";
import CustomTextField from "components/Form/CustomTextField";

import CustomStepper from "components/CustomizedMui/CustomStepper";
import useDropdowns from "hooks/useDropdowns";

const salesCycle = {
  "0 - Prospect": { icon: <Portrait />, id: 1 },
  "1 - Qualify": { icon: <ShoppingBag />, id: 2 },
  "2 - Proposal": { icon: <Description />, id: 3 },
  "3 - Closed/Win": { icon: <Payment />, id: 4 },
  "4 - Closed/Lost": { icon: <MoodBad />, id: 5 },
};

const SalesForm = ({ control, setValue, selectedStatus, setOpen }) => {
  const { dropdowns: salesCycleStatuses } = useDropdowns({ entity: "salesCycleStatuses" });
  const [cycleSteps, setCycleSteps] = useState([]);
  const [activeId, setActiveId] = useState(0);

  const handleStepChange = (v) => {
    if (v === "3 - Closed/Win") setOpen(true);
    setValue("salesCycleStatus", v, { shouldDirty: true });
  };

  const handleStepClick = (step) => {
    const { id } = step;
    if (activeId === id) {
      return;
    }
    setActiveId(id);
    const status = Object.keys(salesCycle).find((key) => salesCycle[key]?.id === id);
    if (status === "3 - Closed/Win") setOpen(true);
    setValue("salesCycleStatus", status, { shouldDirty: true });
  };

  useEffect(() => {
    const salesCycleList = salesCycleStatuses?.map((stat) => {
      return { id: salesCycle[stat]?.id, label: stat, icon: salesCycle[stat]?.icon };
    });
    salesCycleList?.sort((a, b) => a.id - b.id);
    setCycleSteps(salesCycleList);
  }, [salesCycleStatuses]);

  useEffect(() => {
    setActiveId(salesCycle[selectedStatus]?.id);
  }, [selectedStatus]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomStepper
          activeStepId={activeId}
          steps={cycleSteps || []}
          onStepClick={handleStepClick}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="salesCycleStatus"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={salesCycleStatuses || []}
              value={field.value || ""}
              onChange={(e, v) => {
                handleStepChange(v);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="standard"
                  type="text"
                  label="Sales Cycle Status"
                  InputLabelProps={{ shrink: true }}
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Controller
          name="lastContactDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Last Contact Date"
                value={!field.value ? null : field.value}
                onChange={(date) => {
                  field.onChange(new Date(date));
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="nextContactDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Next Contact Date"
                value={!field.value ? null : field.value}
                onChange={(date) => {
                  field.onChange(new Date(date));
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SalesForm;
