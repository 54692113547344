import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";

import moment from "moment";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import { useAppContextController } from "context/AppContext";
import MyInterviewsActions from "../../MyInterviewsActions";

const MyInterviewsRenderRow = ({ row, customerData }) => {
  const { company } = useAppContextController();
  const [applicant, setApplicant] = useState(null);
  const [companyRow, setCompanyRow] = useState(null);

  // const getApplicant = async (applicantId) => {
  //   const { data } = await fetchSingleApplicant({ applicantId });
  //   if (data) {
  //     setApplicant(data);
  //   }
  // };
  // useEffect(() => {
  //   if (row.applicantId) {
  //     const appl = getApplicant(row.applicantId);
  //     setApplicant(appl);
  //   }
  // }, [row.applicantId]);

  useEffect(() => {
    if (customerData && row?.companySlug && customerData[row.companySlug]) {
      setCompanyRow(customerData[row.companySlug]);
    }
  }, [customerData, row]);

  return (
    <>
      <Grid container alignItems="center" key={row._id}>
        <Grid item xs={12} sm={2}>
          <CompanyLogoSquare
            company={companyRow || row}
            defaultCompany={company}
            parent="MyInterviews"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {companyRow?.name || row?.companySlug?.toUpperCase()}
        </Grid>
        <Grid item xs={12} sm={4}>
          {moment(row?.eventDate).format("dddd, MMM Do, YYYY, hh:mm A")}
        </Grid>
        <Grid item xs={12} sm={3}>
          <MyInterviewsActions row={row} />
        </Grid>
      </Grid>
    </>
  );
};

export default MyInterviewsRenderRow;
