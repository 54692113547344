import { useState, useEffect } from "react";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import useDropdowns from "hooks/useDropdowns";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import fetchCustomers from "api/companies/fetchCustomers";

import { transformYupErrorsIntoObject } from "utils/helpers/formHelpers";
import organizationsSchema from "layouts/pages/profile/components/OrganizationsModal/organizationsSchema";

import VenueIcon from "components/VenueIcon";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import GenericModal from "components/GenericModal";
import Searchbar from "components/Searchbar";
import CustomTextField from "components/Form/CustomTextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Grid, Autocomplete, Switch } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { isEmpty } from "lodash";

const OrganizationsModal = ({
  isOpen,
  setOpen,
  setValue,
  organizations,
  orgIndex,
  currentOrg,
  userRecordUserType,
}) => {
  const { companyType, company } = useAppContextController();
  const isVenue = company?.companyType === "Venue";

  const { enqueueSnackbar } = useSnackbar();
  const { dropdowns: clientUserTypes } = useDropdowns({ entity: "clientUserTypes" });
  const { dropdowns: clientUserStatuses } = useDropdowns({ entity: "clientUserStatuses" });
  const { dropdowns: userAdminStatuses } = useDropdowns({ entity: "userAdminStatuses", enabled: isVenue });

  const [filters, setFilters] = useState({ status: "Active;Inactive;Prospect" });
  const [errors, setErrors] = useState({});
  const [slug, setSlug] = useState("");
  const [userType, setUserType] = useState("");
  const [status, setStatus] = useState("");
  const [primary, setPrimary] = useState("No");

  const reset = () => {
    setSlug("");
    setUserType("");
    setStatus("");
    setPrimary("No");
    setErrors({});
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleSubmit = async () => {
    const newOrg = { slug, userType, status, primary, modifiedDate: new Date().toISOString() };
    await organizationsSchema
      .validate(newOrg, { abortEarly: false })
      .then(() => {
        let tempOrgs;
        if (orgIndex !== null) {
          tempOrgs = organizations.map((item, index) => {
            if (index === orgIndex) return newOrg;
            if (primary === "Yes") return { ...item, primary: "No" };
            return item;
          });
        } else {
          tempOrgs = [
            ...organizations.map((item) => {
              if (primary === "Yes") return { ...item, primary: "No" };
              return item;
            }),
            newOrg,
          ];
        }

        setValue("clientOrgs", tempOrgs, { shouldDirty: true });
        handleClose();
      })
      .catch((error) => {
        const validationErrors = transformYupErrorsIntoObject(error);
        setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if (currentOrg && !isEmpty(currentOrg)) {
      setSlug(currentOrg?.slug);
      setUserType(currentOrg?.userType);
      setStatus(currentOrg?.status);
      setPrimary(currentOrg?.primary);
    } else reset();
  }, [currentOrg, isOpen]);

  const searchColumns = [
    ...(isVenue
      ? [
        {
          title: "Logo",
          field: "logo",
          customCell: (_, row) => (
            <VenueIcon
              logoUrl={row?.logoUrl}
              slug={row?.slug}
              name={row?.name}
              imageWidth="50"
              imageHeight="50"
            />
          ),
          width: 150,
        },
      ]
      : [
        {
          title: "Logo",
          field: "logo",
          customCell: (_, row) => <CompanyLogoSquare company={row} defaultCompany={company} />,
          width: 150,
        },
      ]),
    { title: "Slug", field: "slug", width: 200 },
    { title: "Name", field: "name", width: 200 },
    { title: "Status", field: "status", width: 200 },
  ];

  const modalBody = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Searchbar
          fetch={isVenue ? fetchVenues : fetchCustomers}
          fetchAll={!isVenue}
          placeholder="Select Organization"
          columns={searchColumns}
          queryCharacterLimit={3}
          resultsLimit={15}
          setFilters={setFilters}
          filters={filters}
          searchBy={["name", "slug"]}
          filterBy="slug"
          scrollLimit={200}
          onRowClick={(item) => {
            if (!organizations.find((org) => org.slug === item.slug)) setSlug(item.slug);
            else enqueueSnackbar("Organization is already on the list", { variant: "error" });
          }}
          dropRight
          paddingRight={0}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomTextField
          name="slug"
          value={slug}
          disabled
          label="Slug"
          InputLabelProps={{ shrink: true }}
          showError
          errorMessage={errors?.slug}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          value={userType}
          options={clientUserTypes || []}
          onChange={(e, v) => {
            setUserType(v);
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              label="User Type"
              type="text"
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={errors?.userType}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          value={status}
          options={userRecordUserType === "Client" ? clientUserStatuses : userAdminStatuses || []}
          onChange={(e, v) => {
            setStatus(v);
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              label="Status"
              type="text"
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={errors?.status}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center">
          <MDTypography variant="h6">Set as Primary</MDTypography>
          <Switch
            checked={primary === "Yes"}
            onChange={(_, value) => setPrimary(value ? "Yes" : "No")}
          />
        </MDBox>
      </Grid>
    </Grid>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClose}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </>
  );

  return (
    <GenericModal
      open={isOpen}
      setOpen={setOpen}
      header="Add Organization"
      body={modalBody}
      buttons={modalButtons}
      width="45%"
      borderRadius="4px"
    />
  );
};

export default OrganizationsModal;
