import { baseAxios } from "config";

const searchAllApplicantsRanked = async ({
  jobSlug,
  numberOfCandidates,
  jobSkills,
  geoRadius,
  geoPreference,
}) => {
  try {
    const res = await baseAxios.post(`/jobs/slug/${jobSlug}/searchall`, {
      numberOfCandidates,
      jobSkills,
      geoRadius,
      geoPreference,
    });
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default searchAllApplicantsRanked;
