import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient } from "react-query";
import { Autocomplete, Button, Grid, Stack } from "@mui/material";
import createEvent from "layouts/pages/events/actions/createEvent";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import generateSlug from "utils/generateSlug";
import isEmpty from "lodash/isEmpty";
import { useAppContextController } from "context/AppContext";
import { interviewInfoModalSchema } from "data/interview";
import FormField from "layouts/applications/wizard/components/FormField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { INTERVIEW_ENTITIES } from "utils/constants/entityOptions";
import MDEditor from "components/MDEditor";
import CancelIcon from "@mui/icons-material/Cancel";
import useDropdowns from "hooks/useDropdowns";
import SelectHiringManager from "layouts/pages/events/components/InterviewEditor/SelectHiringManager";
import SelectInterviewLocation from "layouts/pages/events/components/InterviewEditor/SelectInterviewLocation";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import { Save } from "@mui/icons-material";

const useStyle = makeStyles({
  editor: {
    height: 150,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
});

const { INTERVIEW_TYPES, STATES, INTERVIEW_STATUS } = INTERVIEW_ENTITIES;

const CreateInterviewModal = ({
  currentCustomer,
  setOpen,
  open,
  selectedJob,
  applicants,
  onCreate,
}) => {
  const { company, currentLoggedUser } = useAppContextController();
  const resolver = useYupValidationResolver(interviewInfoModalSchema);
  const queryClient = useQueryClient();
  const classes = useStyle();
  const [editorValue, setEditorValue] = useState(null);
  const interviewLocationListRef = useRef([]);
  const { enqueueSnackbar } = useSnackbar();
  const [interviewTypesList, setInterviewTypesList] = useState();

  const { dropdowns: interviewTypes } = useDropdowns({ entity: INTERVIEW_TYPES });

  const defaultValues = {
    eventName: `${selectedJob?.title ?? company?.name} Interview`,
    selectedJob: "",
    eventType: "Interview",
    interviewType: "",
    status: "Active",
    _id: null,
    companyName: null,
    interviewLocation: "",
    address: currentCustomer?.address,
    city: currentCustomer?.city,
    state: currentCustomer?.state,
    zip: currentCustomer?.zip,
    eventId: "",
    eventDate: null,
    eventEndTime: null,
    reportTimeTBD: "",
    eventLogo: "",
    eventLogos: [],
    attachments: [],
    applicants: [],
    // jobLogo: "No",
    // googleMap: "No",
    enableFeedback: "No",
    enableNotes: "No",
    companySlug: "",
    description: "",
    eventUrl: "",
    attendees: [],
    logoUrl: "",
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    setError,
    formState,
  } = useForm({ mode: "onBlur", resolver });
  const { errors, isSubmitting, dirtyFields, isDirty } = formState;
  const modalTitle = (
    <MDTypography
      variant="h5"
      color="dark"
      sx={{ mt: 2, mb: 1, ml: 2 }}
      // key={`note_title_${modalInfo.data.id}`}
    >
      Create Interview for {selectedJob?.title}
    </MDTypography>
  );

  useEffect(() => {
    interviewLocationListRef.current =
      currentCustomer?.locations &&
      currentCustomer.locations.map((location) => ({
        ...location,
        label: location.locationName,
      }));
  }, [currentCustomer]);

  useEffect(() => {
    reset(defaultValues);
    setEditorValue(null);
  }, [currentCustomer?._id, selectedJob?._id]);

  useEffect(() => {
    setInterviewTypesList(
      interviewTypes?.map((interview) => {
        if (interview === "Onsite {Customer}") {
          return interview.replace("{Customer}", `${company?.name}`);
        }
        if (interview === "Onsite {Primary Company}" && currentCustomer?.name) {
          return interview.replace("{Primary Company}", `${currentCustomer?.name}`);
        }
        if (interview === "Onsite {Primary Company}") {
          return interview.replace("{Primary Company}", "Customer");
        }
        return interview;
      })
    );
  }, [currentCustomer?.name, company, interviewTypes]);

  const createInterviewMutation = useMutation(createEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("events");
      enqueueSnackbar("Interview has been created!", { variant: "success" });
      if (onCreate) onCreate();
      setOpen(false);
    },
  });

  const dateToSlugString = (date) =>
    getValues()?.eventDate?.toString()?.split(" ")?.splice(0, 4)?.join("-")?.toLowerCase();

  const variables = {
    venueSlug: currentCustomer?.slug,
    title: `${selectedJob?.title ?? company?.name} Interview`,
    suffix: dateToSlugString(watch("eventDate")),
    slugField: "eventUrl",
    fetchFunction: fetchEvents,
  };

  const createInterviewFormHandler = async (values) => {
    const updatedValues = { ...values };

    updatedValues.createAgent = currentLoggedUser._id;
    updatedValues.eventEndTime = new Date(updatedValues.eventEndTime).toISOString();
    updatedValues.eventDate = new Date(updatedValues.eventDate).toISOString();
    updatedValues.companyName = selectedJob.companyName;
    updatedValues.selectedJob = selectedJob.title;
    updatedValues.jobId = selectedJob.jobId;
    updatedValues.jobSlug = selectedJob.jobSlug;
    updatedValues.companySlug = selectedJob.companySlug;
    updatedValues.jobName = selectedJob.title;
    updatedValues.applicants = [...applicants];
    const { slug } = await generateSlug(variables);
    updatedValues.eventUrl = slug;
    await createInterviewMutation.mutateAsync(updatedValues);
  };

  const modalBody = (
    <form onSubmit={handleSubmit(createInterviewFormHandler)}>
      <Button
        sx={{
          position: "absolute",
          top: "0%",
          right: "0%",
          m: 1,
          p: 0,
        }}
        onClick={() => {
          reset(defaultValues);
          setEditorValue("");
          setOpen(false);
        }}
      >
        <CancelIcon color="secondary" />
      </Button>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Controller
            name="eventDate"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="Start Date/Time"
                  value={!field.value ? null : field.value}
                  onChange={(date) => {
                    clearErrors("eventEndTime");
                    setValue("eventDate", new Date(date), { shouldDirty: true });
                    setValue(
                      "eventEndTime",
                      new Date(new Date(getValues().eventDate).getTime() + 1 * 60 * 60 * 1000),
                      { shouldDirty: true }
                    );
                    clearErrors("eventDate");
                  }}
                  renderInput={(params) => <FormField {...params} />}
                />
              </LocalizationProvider>
            )}
          />
          {errors?.eventDate && (
            <MDTypography className={classes.error} color="error">
              {errors?.eventDate.message}
            </MDTypography>
          )}
        </Grid>
        <Grid item sm={6}>
          <Controller
            name="eventEndTime"
            control={control}
            defaultValue={null}
            render={({ field }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="End Date/Time"
                    value={!field.value ? null : field.value}
                    onChange={(date) => {
                      setValue("eventEndTime", new Date(date), { shouldDirty: true });
                    }}
                    renderInput={(params) => <FormField {...params} />}
                  />
                </LocalizationProvider>
              );
            }}
          />
          {errors?.eventEndTime && (
            <MDTypography className={classes.error} color="error">
              {errors?.eventEndTime.message}
            </MDTypography>
          )}
        </Grid>
        <Grid item sm={4}>
          <Controller
            name="interviewType"
            control={control}
            render={({ field }) => (
              <Autocomplete
                key={`autocomplete_interviewType_${currentCustomer?._id}`}
                options={interviewTypesList || []}
                autoSelect
                freeSolo
                name="Interview Type"
                defaultValue={null}
                value={field.value || null}
                onChange={(e, v) => {
                  field.onChange(v?.charAt(0).toUpperCase() + v?.slice(1));
                  setValue("interviewLocation", null);
                }}
                onBlur={() => trigger("interviewType")}
                renderInput={(params) => (
                  <FormField
                    key={`interviewType_${currentCustomer?._id}`}
                    {...params}
                    type="text"
                    label="Interview Type"
                  />
                )}
              />
            )}
          />
          {errors?.interviewType && (
            <MDTypography className={classes.error} color="error">
              {errors?.interviewType.message}
            </MDTypography>
          )}
        </Grid>
        <Grid item sm={4}>
          <Controller
            name="interviewLocation"
            control={control}
            render={({ field }) => (
              <SelectInterviewLocation
                value={field.value || null}
                onChange={(e, v) => {
                  field.onChange(v);
                }}
                isOptionEqualToValue={(option, value) => option?.label === value?.label}
                currentEvent={null}
                company={company}
                currentCompany={currentCustomer}
                selectedCompany={currentCustomer.name}
                selectedInterviewType={watch("interviewType")}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <Controller
            name="hiringManager"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <SelectHiringManager
                customer={currentCustomer}
                value={field.value || ""}
                onChange={(e, v) => {
                  field.onChange(v);
                }}
                onBlur={() => trigger("hiringManager")}
                errorMessage={error?.message}
                isShrink={!isEmpty(getValues("hiringManager"))}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="label" variant="body2">
            Description
          </MDTypography>
          <Controller
            name="companyDescription"
            control={control}
            render={({ field }) => (
              <MDEditor
                name="description"
                className={classes.editor}
                value={editorValue || ""}
                onChange={(e) => {
                  setEditorValue(e);
                  setValue("description", e, { shouldDirty: true });
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} mt={10} textAlign="end">
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
              sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              startIcon={<Save />}
              style={{ color: "white" }}
              sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
              // onClick={() => {
              //   // onSubmit(state);
              //   setOpen(false);
              // }}
            >
              Submit
            </Button>
          </Stack>
          {/* <Button */}
          {/*    type="submit" */}
          {/*    variant="contained" */}
          {/*    startIcon={<SaveIcon />} */}
          {/*    style={{ color: "white" }}> */}
          {/*    Save */}
          {/* </Button> */}
        </Grid>
      </Grid>
    </form>
  );

  return (
    <>
      <GenericModal open={open} setOpen={setOpen} body={modalBody} header={modalTitle} />
    </>
  );
};

export default CreateInterviewModal;
