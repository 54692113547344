import { APPLICANT_VENUE_STATUS_ENUM } from "utils/constants/applicant";

export const getActiveFilterTitle = (filter) => {
  const filterList = {
    "Not Roster": "Not on Roster",
    Waitlist: "Waitlist",
    Request: "Request",
    Roster: "On Roster",
    Attended: "Attended",
    "No Show": "No Show",
    All: "All",
  };
  return filterList[filter] || null;
};

export const attended = (id, currentEvent) => {
  if (!id) return false;
  const eventApplicants = currentEvent?.applicants;
  const ndx = eventApplicants?.findIndex((item) => item.id === id);
  if (ndx === -1) return false;
  const item = eventApplicants[ndx];
  if (item.timeIn) return true;
  return false;
};

export const otherStatus = (id, currentEvent, status) => {
  if (!id) return false;
  const eventApplicants = currentEvent?.applicants;
  const ndx = eventApplicants.findIndex((item) => item.id === id);
  if (ndx === -1) return false;
  const item = eventApplicants[ndx];
  if (item.status === status) return true;
  return false;
};

export const filterApplicants = ({ applicants = [], filter, rosterIds, currentEvent }) => {
  let fas = [];
  switch (filter) {
    case "Not Roster":
      fas = applicants.filter((item) => !rosterIds.includes(item._id));
      break;
    case "Waitlist":
    case "Request":
      fas = applicants.filter(
        (app) => rosterIds.includes(app._id) && otherStatus(app._id, currentEvent, filter)
      );
      break;
    case "Attended":
      fas = applicants.filter(
        (app) => rosterIds.includes(app._id) && attended(app._id, currentEvent)
      );
      break;
    case "No Show":
      fas = applicants.filter((app) => {
        if (rosterIds.includes(app._id) && !attended(app._id, currentEvent)) return true;
        return false;
      });
      break;
    case "Roster":
      fas = applicants.filter((item) => rosterIds.includes(item._id));
      break;
    default:
      fas = applicants;
      break;
  }
  return fas;
};

// export const getApplicantImageSrc = (applicant) =>
//   encodeURI(`${IMG_SERVER_URL}/users/${applicant?.userId}/photo/${applicant?.profileImg}`);

export const getApplicantVenueState = (applicant, venues) => {
  if (applicant?.venues?.length) {
    const [appliedVenue] = applicant.venues;
    let state = null;
    if (venues?.[appliedVenue?.venueSlug]) state = venues?.[appliedVenue?.venueSlug].state;
    return state;
  }
  return null;
};

export const parseApplicantPhone = (phone) => {
  if (phone.length === 11) {
    return `(${phone.slice(1, 4)}) ${phone.slice(4, 7)} ${phone.slice(7, 11)}`;
  }
  return phone;
};

export const checkIfApplicantVenuePending = (applicantVenues, venueSlug) => {
  const activeVenue = applicantVenues?.filter(
    (item) => item?.venueSlug === venueSlug && item?.status === APPLICANT_VENUE_STATUS_ENUM.PENDING
  );
  return activeVenue?.length > 0;
};

export const getApplicantVenueStatus = (applicant, slug) => {
  if (applicant?.recordLocked === "Yes") return "Locked";
  if (applicant?.employmentStatus === "Terminated" || applicant?.employmentStatus === "Inactive") {
    return applicant?.employmentStatus;
  }
  if (applicant?.venues && slug) {
    const { venues } = applicant;
    const venue = venues.find((item) => item.venueSlug === slug);
    if (venue) {
      return venue.status;
    }
  }
  return "None";
};

export const getApplicantJobStatus = (applicant, slug) => {
  if (applicant?.jobs && slug) {
    const { jobs } = applicant;
    const job = jobs.find((item) => item.jobSlug === slug);
    if (job) {
      return job.status;
    }
  }
  return "None";
};

export const getApplicantJobApplicantStatus = (applicant, slug) => {
  if (applicant?.jobs && slug) {
    const { jobs } = applicant;
    const job = jobs.find((item) => item.jobSlug === slug);
    if (job && job.applicantStatus) {
      return job.applicantStatus;
    }
  }
  return applicant.applicantStatus ?? "New";
};

export const getApplicantJobEmploymentStage = (applicant, slug) => {
  if (applicant?.jobs && slug) {
    const { jobs } = applicant;
    const job = jobs.find((item) => item.jobSlug === slug);
    if (job && job?.employmentStage) {
      return job?.employmentStage;
    }
  }
  return "None";
};

export const getApplicantCompanyStatus = (applicant, slug) => {
  // Andres -- THIS IS WRONG!!!!

  // if (applicant?.status === "Terminated" || applicant?.status === "Inactive") {
  //   return applicant?.status;
  // }
  if (applicant?.companies && slug) {
    const { companies } = applicant;
    const company = companies.find((item) => item.companySlug === slug);
    if (company) {
      return company.status;
    }
  }
  return "None";
};
export const getApplicantCompanyOpenEndDate = (applicant, slug) => {
  let currentCompany;
  if (applicant?.companies && slug) {
    const { companies } = applicant;
    const company = companies.find((item) => item.companySlug === slug);
    if (company?.openDate) {
      currentCompany = {
        ...currentCompany,
        openDate: company.openDate,
      };
    }
    if (company?.closeDate) {
      currentCompany = {
        ...currentCompany,
        closeDate: company.closeDate,
      };
    }
  }
  return currentCompany;
};

export const getApplicantEventStatus = (applicant, event) => {
  if (event) {
    const eventApplicant = event?.applicants?.find((item) => item?.id === applicant?._id);
    return eventApplicant ? eventApplicant.status : "Not Roster";
  }
  return null;
};

export const getPartnerEventStatus = (partner, event) => {
  if (event) {
    const eventApplicant = event?.partners?.find((item) => item?.id === partner?._id);
    return eventApplicant ? eventApplicant.status : "Not Roster";
  }
  return null;
};