import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { baseAxios } from "config";

const Auth0ProviderWithHistory = ({ children }) => {
  const [authConfig, setAuthConfig] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthConfig = async () => {
      if (!authConfig) {
        try {
          const url = `/auth/client`;

          const response = await baseAxios.get(url);
          const { success, encryptedData } = response.data;

          if (success) {
            try {
              const bytes = CryptoJS.AES.decrypt(
                encryptedData,
                "30391333-f082-497c-915f-66264b5336b5"
              );
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

              setAuthConfig({
                domain: decryptedData?.auth0Domain || process.env.REACT_APP_AUTH0_DOMAIN,
                clientId: decryptedData?.clientId || process.env.REACT_APP_AUTH0_CLIENT_ID,
                clientSecret: decryptedData?.clientSecret || process.env.REACT_APP_AUTH0_SECRET,
              });
            } catch (decryptionError) {
              console.error("Decryption error: Malformed UTF-8 data", decryptionError);
            }
          } else {
            console.error("Failed to fetch auth config: success flag was false.");
          }
        } catch (error) {
          console.error("Error fetching auth config:", error);
        }
      }
    };

    fetchAuthConfig();
  }, [authConfig]);

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!authConfig) {
    return <div>Loading...</div>;
  }

  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      clientSecret={authConfig.clientSecret}
      redirectUri={window.location.origin}
      audience={`https://${authConfig?.domain || process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
