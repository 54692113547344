import { baseAxios } from "config";

const updateEventBulk = async ({ id, data }) => {
  try {
    const res = await baseAxios.put(`/events/id/${id}/roster/bulk`, data);

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateEventBulk;
