import { Card, IconButton, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";

import FormField from "layouts/applications/wizard/components/FormField";
import createJob from "layouts/pages/jobs/actions/createJob";
import MDTypography from "components/MDTypography";
import { FileDownload } from "@mui/icons-material";
import { useState } from "react";
import PayrollModal from "components/PayrollModal";

const ShiftJobsExportButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title="Shift Job Export">
        <IconButton color="info" onClick={() => setIsModalOpen(true)}>
          <FileDownload />
        </IconButton>
      </Tooltip>
      {isModalOpen && (
        <PayrollModal isOpen={isModalOpen} toggleOpen={setIsModalOpen} type="shiftJobs" />
      )}
    </>
  );
};

export default ShiftJobsExportButton;
