import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

const InterviewApplicantsTableActions = ({
  id,
  row,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setModalInfo,
}) => {
  const { company } = useAppContextController();
  const navigate = useNavigate();
  const route = row?.status === "Employee" ? "employees" : "applicants";
  const actionroute = row?.status === "Employee" ? "employee" : "applicant";

  const navigateLocal = setNavigateUrl ?? navigate;

  const onClickHandler = (action, e) => {
    if (action === `${actionroute}mail`) {
      e.stopPropagation();
      setCurrentApplicant(row);
      setModalInfo({ type: action, data: row });
      setGenericModalOpen(true);
    } else {
      e.stopPropagation();
      navigateLocal(`/${route}/${id}/action/${action}`);
    }
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler(`${actionroute}info`, e)}
        tooltip="Applicant Info"
      />
      <TableAction
        color="info"
        icon="business"
        clickAction={(e) => onClickHandler(`${actionroute}customers`, e)}
        tooltip="Applicant Customers"
      />
      <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickHandler(`${actionroute}jobs`, e)}
        tooltip="Applicant Jobs"
      />
      <TableAction
        color="error"
        icon="mail"
        clickAction={(e) => onClickHandler(`${actionroute}mail`, e)}
        tooltip="Applicant Mail"
      />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler(`${actionroute}all`, e)}
        tooltip="All Applicant Information"
      /> */}
    </span>
  );
};

export default InterviewApplicantsTableActions;

InterviewApplicantsTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
InterviewApplicantsTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
