import { Warning } from "@mui/icons-material";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import TaskFlag from "components/TaskFlag";

const EmployeeFlags = ({ flagged, row }) => (
  <Stack direction="row" container>
    {row.isDnu === "Yes" && (
      <Tooltip title="Do not use">
        <BlockIcon fontSize="large" color="error" />
      </Tooltip>
    )}
    {!row.employeeID && (
      <Tooltip title="Missing Employee ID">
        <Warning fontSize="large" color="error" />
      </Tooltip>
    )}
    {flagged === "Yes" && (
      <Tooltip title="Check Employee Notes">
        <FlagIcon fontSize="large" color="error" />
      </Tooltip>
    )}
    <TaskFlag _id={row._id} />
  </Stack>
);

export default EmployeeFlags;
