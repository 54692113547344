import {
  ConnectWithoutContact,
  PersonAdd,
  FolderShared,
  QuestionAnswer,
  ThumbDown,
  Info,
  CheckCircle,
  PersonOff,
  Cancel,
  Login,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { commonColumnAttributes } from "./styles";

const iconStatus = {
  ATC: <ConnectWithoutContact color="warning" fontSize="medium" />,
  Declined: <ThumbDown color="error" fontSize="medium" />,
  New: <PersonAdd fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Pre-Hire": <FolderShared color="success" fontSize="medium" />,
  Screened: <QuestionAnswer color="info" fontSize="medium" />,
};

const APPLICANT_DETAIL = [
  { field: "applicant", headerName: "Applicant", flex: 1 },
  {
    field: "applyDate",
    headerName: "Apply Date",
    ...commonColumnAttributes,
    valueGetter: ({ value }) =>
      new Date(value)?.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
  },
  {
    field: "source",
    headerName: "Source",
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "applicantStatus",
    headerName: "Status",
    sortable: false,
    ...commonColumnAttributes,
    renderCell: ({ value }) => {
      return iconStatus?.[value];
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    ...commonColumnAttributes,
    renderCell: () => (
      <IconButton>
        <Info color="info" fontSize="medium" />
      </IconButton>
    ),
    // only one, not dynamic. separate to actions later on
  },
];

const JOB_APPLIES = [
  { field: "source", headerName: "Source", flex: 1 },
  {
    field: "quantity",
    headerName: "Quantity",
    ...commonColumnAttributes,
  },
  {
    field: "new",
    headerName: <PersonAdd fontSize="medium" sx={{ color: "#9C27B0" }} />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "atc",
    headerName: <ConnectWithoutContact color="warning" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "screened",
    headerName: <QuestionAnswer color="info" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "prehire",
    headerName: <FolderShared color="success" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "decline",
    headerName: <ThumbDown color="error" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
];

const APPLICANT_STAGE_ACTIVITY_ICONS = {
  ATC: <ConnectWithoutContact color="warning" fontSize="medium" />,
  Declined: <ThumbDown color="error" fontSize="medium" />,
  New: <PersonAdd fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Pre-Hire": <FolderShared color="success" fontSize="medium" />,
  Screened: <QuestionAnswer color="info" fontSize="medium" />,
};

const STAFFING_POOL_ICONS = {
  All: <Info fontSize="medium" sx={{ color: "#0070C0" }} />,
  Active: <CheckCircle fontSize="medium" sx={{ color: "#008000" }} />,
  Hired: <PersonAdd fontSize="medium" sx={{ color: "#FF0066" }} />,
  Inactive: <PersonOff fontSize="medium" sx={{ color: "#E28700" }} />,
  Terminated: <Cancel fontSize="medium" sx={{ color: "#CC3300" }} />,
  "No Login": <Login fontSize="medium" sx={{ color: "#0070C0" }} />,
};

export { APPLICANT_DETAIL, JOB_APPLIES, APPLICANT_STAGE_ACTIVITY_ICONS, STAFFING_POOL_ICONS };
