import { useState } from "react";
import { useAppContextController } from "context/AppContext";
import { transformYupErrorsIntoObject } from "utils/helpers/formHelpers";
import DataListContainer from "components/DataListContainer";
import useDropdowns from "hooks/useDropdowns";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import ApplicantLicenseItem from "../ApplicantLicenseItem";
import ApplicantLicenseModal from "../ApplicantLicenseModal";
import applicantLicenseSchema from "./ApplicantLicenseSchema";

const defaultValues = {
  licenseType: { value: "", label: "" },
  licenseStatus: "Active",
  licenseExpDate: null,
  thirtyDayAlert: "No",
};

const ApplicantLicenses = ({ licenses, onLicenseChange, watch, setValue }) => {
  const { company } = useAppContextController();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isReminderModalOpen, setReminderModalOpen] = useState(false);
  const [licenseIndex, setLicenseIndex] = useState(null);
  const [currentLicense, setCurrentLicense] = useState(defaultValues);
  const [errors, setErrors] = useState();
  const isCompany = company?.companyType === "Company";

  const { dropdowns: tagOptions } = useDropdowns({ entity: "tagOptions" });

  const reset = () => {
    setCurrentLicense(defaultValues);
    setLicenseIndex(null);
    setErrors();
  };

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
    reset();
  };

  const handleChange = (name, value) => setCurrentLicense({ ...currentLicense, [name]: value });

  const handleEditLicense = (row, isOpen, posIdx) => {
    setCurrentLicense(row);
    setModalOpen(isOpen);
    setLicenseIndex(posIdx);
  };


  const hasMultipleLicensesOfType = (licensesArray, label) => {

    const filteredLicenses = licensesArray.filter(license => license.licenseType.label === label);

    return filteredLicenses.length > 1;
  };

  const handleRemoveLicense = (idx, label) => {
    const updatedLicenses = licenses.filter((_, index) => index !== idx);
    onLicenseChange(updatedLicenses);

    const hasMultiple = hasMultipleLicensesOfType(licenses, label)
    if (!hasMultiple) {
      const array = [...watch("tags")]
      setValue(
        "tags",
        array?.filter((chip) => chip !== label),
        { shouldDirty: true }
      );
    }

  }

  const handleSubmit = async () => {
    let updatedLicenses;

    await applicantLicenseSchema
      .validate(currentLicense, { abortEarly: false })
      .then(() => {
        if (licenseIndex !== null) {
          updatedLicenses = licenses.map((item, index) => {
            if (index === licenseIndex) {
              return currentLicense;
            }
            return item;
          });
        } else {
          updatedLicenses = [...licenses, currentLicense];
        }
        const correctTag = tagOptions.find((tag) => {
          return tag?.toLocaleLowerCase() === currentLicense?.licenseType?.label?.toLocaleLowerCase();
        });

        if (!(watch("tags")?.includes(currentLicense?.licenseType?.label)) && correctTag) {
          setValue("tags", [...(watch("tags") || []), correctTag], { shouldDirty: true });
        }
        onLicenseChange(updatedLicenses);
        handleClose();
        setReminderModalOpen(true);
      })
      .catch((error) => {
        const validationErrors = transformYupErrorsIntoObject(error);
        setErrors(validationErrors);
      });
  };

  const renderRow = (row, idx) => (
    <ApplicantLicenseItem
      row={row}
      idx={idx}
      onEdit={handleEditLicense}
      onRemove={handleRemoveLicense}
    />
  );

  return (
    <>
      <ReminderToSaveChangesModal open={isReminderModalOpen} setOpen={setReminderModalOpen} />
      <ApplicantLicenseModal
        errors={errors}
        currentLicense={currentLicense}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        setReminderModalOpen={setReminderModalOpen}
        onChange={handleChange}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />

      <DataListContainer
        onAdd={handleOpen}
        data={licenses}
        renderRow={renderRow}
        title="Licenses"
      />
    </>
  );
};

export default ApplicantLicenses;
