import { baseAxios } from "config";

export const updateFeature = async (feature) => {
  try {
    const res = await baseAxios.get(`/reports/update/${feature}`);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
