import { Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import FilterCard from "components/FilterCard";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: -5,
    // border: `2px solid ${theme.palette.background.paper}`,
    paddingRight: "6px",
    paddingLeft: "6px",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "0.7rem",
    opacity: 0.9,
    height: "unset",
  },
}));

const FilterButtons = ({
  filter,
  setFilter,
  options,
  name,
  isDisabled,
  fontSize,
  sx,
  itemsx,
  fullscreen,
}) => {
  if (fullscreen) {
    return (
      <Grid container spacing={2} pb={2}>
        {options.map((card, i) => {
          const selected = filter?.[name] === card.value;
          return (
            <Grid item key={`item_${card?.title}`}>
              <FilterCard
                // setSelector={parent==='Venue' ? setCurrentVenueSelector : setCurrentJobsSelector}
                bgColor="primary"
                color="dark"
                icon={card?.iconFullscreen}
                title={card?.title}
                tooltip={
                  card?.tooltip ? `${card.number ? `${card.number} ` : ""} ${card?.tooltip}` : ""
                }
                number={card?.count ? card.count : null}
                filters={{}}
                setExistingFilters={() => {}}
                setFilters={() => {}}
                filterField=""
                filterValue={card.value}
                selectedIndex={selected ? i : -1}
                index={i}
                onSelectHandler={(_, value) =>
                  value && setFilter((prevState) => ({ ...prevState, [name]: value }))
                }
                customFilterFunction={() => {}}
                filterObj={{}}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <ToggleButtonGroup
      color="secondary"
      value={filter?.[name]}
      exclusive
      onChange={(_, value) => value && setFilter((prevState) => ({ ...prevState, [name]: value }))}
      aria-label="filter-button-group"
      sx={{ display: "flex", justifyContent: "center", width: "100%", background: "white", ...sx }}
    >
      {options.map((item) => (
        <ToggleButton
          value={item.value}
          sx={{ gap: "5px", width: "100%", background: "white", ...itemsx }}
          key={item.title}
          disabled={isDisabled}
        >
          {item.icon}
          <StyledBadge badgeContent={item.count ?? 0} color="info" size="lg" max={10000}>
            <Typography fontWeight="medium" sx={{ fontSize: fontSize ?? "0.75rem" }}>
              {item.title}
            </Typography>
          </StyledBadge>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const FilterButtonsFullscreen = ({
  filter,
  setFilter,
  options,
  name,
  isDisabled,
  fontSize,
  sx,
  itemsx,
}) => {
  return (
    // <ToggleButtonGroup
    //   color="secondary"
    //   value={filter?.[name]}
    //   exclusive
    //   onChange={(_, value) => value && setFilter((prevState) => ({ ...prevState, [name]: value }))}
    //   aria-label="filter-button-group"
    //   sx={{ display: "flex", justifyContent: "center", width: "100%", background: "white", ...sx }}
    // >
    //   {options.map((item) => (
    //     <ToggleButton
    //       value={item.value}
    //       sx={{ gap: "5px", width: "100%", background: "white", ...itemsx }}
    //       key={item.title}
    //       disabled={isDisabled}
    //     >
    //       {item.icon}
    //       <StyledBadge badgeContent={item.count ?? 0} color="info" size="lg" max={10000}>
    //         <Typography fontWeight="medium" sx={{ fontSize: fontSize ?? "0.75rem" }}>
    //           {item.title}
    //         </Typography>
    //       </StyledBadge>
    //     </ToggleButton>
    //   ))}
    // </ToggleButtonGroup>
    <Grid container spacing={2} pb={2}>
      {options.map((card, i) => (
        <Grid item key={`item_${card?.title}`}>
          <FilterCard
            // setSelector={parent==='Venue' ? setCurrentVenueSelector : setCurrentJobsSelector}
            bgColor="primary"
            color={card?.color || "error"}
            icon={card?.icon}
            title={card?.title}
            tooltip={
              card?.tooltip ? `${card.number ? `${card.number} ` : ""} ${card?.tooltip}` : ""
            }
            number={card?.number ? card.number : null}
            filters={{}}
            setExistingFilters={() => {}}
            setFilters={() => {}}
            filterField=""
            filterValue=""
            selectedIndex={1}
            index={i}
            onSelectHandler={() => {}}
            customFilterFunction={() => {}}
            filterObj={{}}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FilterButtons;
