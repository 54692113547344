import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";
import { calculateInterviewOverallFeedback } from "utils/helpers/events";

const fetchInterviewApplicants = async ({
  page,
  limit,
  order,
  orderBy,
  interviewStatus,
  interviewJobSlug,
}) => {
  try {
    const { paginationQuery, sortQuery, filterQuery, queryString } = createApiParams({
      page,
      limit,
      order,
      orderBy,
    });

    const interviews = await baseAxios.get(
      `/jobs/slug/${interviewJobSlug}/interviews${queryString}`
    );

    const modifiedData = [];
    interviews.data?.data?.forEach((interview) => {
      if (interview.interview) {
        // This is a real interview
        // Calculate the feedback value
        const interviewFeedback = (interview.feedback ?? []).filter(
          (applFeed) => applFeed.eventUrl === interview.interview.eventUrl
        );
        const { overallRating, agentRating, tooltip } =
          calculateInterviewOverallFeedback(interviewFeedback);

        const interviewFeedbackStatus =
          interviewFeedback && ["None", "Hired", "Pending", "Declined"].includes(overallRating)
            ? overallRating
            : "None";
        const isStatusValid =
          interviewStatus === "all" || interviewFeedbackStatus === interviewStatus;

        if (isStatusValid) {
          modifiedData.push({
            ...interview,
            interview: {
              ...interview.interview,
              status: interviewFeedbackStatus,
              eventDate: interview.event.eventDate,
              tooltip,
              agentRating,
            },
          });
        }
      }
    });

    return { ...interviews.data, data: modifiedData };
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchInterviewApplicants;
