import { useAppContextController } from "context/AppContext";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Grid, Stack, Tooltip } from "@mui/material";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";

import fetchUserByEmail from "layouts/pages/users/actions/fetchUserByEmail";
import FilterButtons from "components/FilterButtons";
import { APPLICANT_VENUES_OPTIONS } from "components/FilterButtons/options";
import VenueStatusButton from "components/VenueStatusButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import { isAdmin } from "utils/helpers/roles";
import EmployeeStatusButton from "components/EmployeeStatusButton";

const PartnerVenues = ({ fetchAll = true, title = "Title", filters, setFilters }) => {
  const [dataFilter, setDataFilter] = useState({ status: "all" });
  const [applicantUser, setApplicantUser] = useState(null);
  const { currentPartner, venues, userType } = useAppContextController();

  const allVenues = Object.values(venues);

  const getModifiedDateFromApplVenues = (applVenues, venue) => {
    if (!applVenues || !applVenues.length) return null;
    const ndx = applVenues.findIndex((item) => item.venueSlug === venue);
    if (ndx > -1) return new Date(applVenues[ndx]?.dateModified);
    return null;
  };

  const renderRow = (row) => {
    const venueStatus = getApplicantVenueStatus(currentPartner, row.slug);
    return (
      <>
        <MDBox key={`${row.name}_${row.slug}`} display="flex" justifyContent="space-between">
          <Grid item xs={12} sm={2} lg={1.5}>
            <VenueIcon logoUrl={row.logoUrl} slug={row.slug} name={row.name} />
          </Grid>
          {/* <Tooltip
            title={`Date Modified: ${moment(
              getModifiedDateFromApplVenues(currentApplicant?.venues, row.slug)
            ).format("ddd, MMM DD, YYYY hh:mm a")}`}
          > */}
          <Grid item xs={12} sm={6} lg={8.5}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row.name}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  {row.city}, {row.state}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          {/* </Tooltip> */}
          <Grid item xs={12} sm={4} lg={2}>
            <Stack direction="row" justifyContent="right">
              {/* {(applicantUser?.userType === "Client" || applicantUser?.employeeType === "Event Admin") && venueStatus === "StaffingPool" &&
                                <EmployeeStatusButton
                                    applicantUser={applicantUser}
                                    setApplicantUser={setApplicantUser}
                                    venue={row}
                                />
                            } */}

              <VenueStatusButton status={venueStatus} venue={row} isPartner />
            </Stack>
          </Grid>
        </MDBox>
      </>
    );
  };

  const header = (
    <FlexWrapper p={2} justifyContent="flex-end">
      {isAdmin(userType) && (
        <FilterButtons
          filter={dataFilter}
          setFilter={setDataFilter}
          name="status"
          options={APPLICANT_VENUES_OPTIONS}
        />
      )}
    </FlexWrapper>
  );

  const filteredVenues = useMemo(() => {
    if (dataFilter.status !== "all") {
      return allVenues.filter(
        (item) => getApplicantVenueStatus(currentPartner, item.slug) === dataFilter.status
      );
    }
    return allVenues;
  }, [dataFilter, allVenues, currentPartner]);

  return (
    <DataList
      renderRow={renderRow}
      data={filteredVenues}
      icon="stadium"
      iconColor="info"
      title="Venues"
      isLoading={false}
      greybar
      divider
      header={header}
      tableHeight={450}
      height={850}
      scrollY
      computedHeight
    />
  );
};

export default PartnerVenues;
