import { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Switch, useMediaQuery } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Vimeo from "@u-wave/react-vimeo";

const TrainingVideoModal = ({ openTrainingVideoModal, setOpenTrainingVideoModal, instructionalVideo, setInstructionalVideo }) => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const [localStorageVideoSwitch, setLocalStorageVideoSwitch] = useState(instructionalVideo)
    const playerRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    sessionStorage.setItem("instructionalVideo", true)
    const handleFullscreenToggle = () => {
        if (!playerRef.current) return;

        const player = playerRef.current.getInternalPlayer();

        player.getFullscreen().then((isFull) => {
            player.setFullscreen(!isFull);
            setIsFullscreen(!isFull);
        });
    };

    const disableVideoLogin = () => {
        setLocalStorageVideoSwitch(!localStorageVideoSwitch)
    }

    const header = (
        <MDTypography variant="h3" color="dark" align="center">
            Watch Our Instructional Video
        </MDTypography>
    );

    const generateFullScreenButtons = () => {
        return (isFullscreen ? (
            <IconButton
                className="fullscreen-button"
                onClick={handleFullscreenToggle}
                color="inherit"
            >
                <FullscreenExitIcon />
            </IconButton>
        ) : (
            <IconButton
                className="fullscreen-button"
                onClick={handleFullscreenToggle}
                color="inherit"
            >
                <FullscreenIcon />
            </IconButton>
        ))
    }

    const body = (<>
        <MDTypography variant="h5" align="center">
            Learn how to use our new Scheduling and Time Clock System at Stadium People. You may turn off this message on future logins and access the training in a link at the top of your header bar.
        </MDTypography>
        {isSmallScreen &&
            <Box mt={2}>

                <MDTypography variant="h6" align="center">
                    For full screen mobile view, rotate device horizontally
                </MDTypography>
            </Box>
        }
        <Vimeo
            video="https://player.vimeo.com/video/804185343?h=7387e1cb8b"
            showTitle
            responsive
            progressive
            ref={playerRef}
        />
        {/* {isSmallScreen && generateFullScreenButtons()} */}
    </>
    );

    const buttons = (
        <Grid container width="100%" justifyContent="end" spacing={3}  >

            <Grid item xs={12} lg={4}>
                <Button
                    variant="contained"
                    style={{ color: "white" }}
                    onClick={() => {
                        setOpenTrainingVideoModal(false)
                    }}
                    fullWidth
                >
                    Close
                </Button>
            </Grid>

            <Grid item xs={12} lg={4} alignSelf="end" display="flex" alignItems="center">
                <MDTypography variant="body2" align="center" >
                    disable on future login
                </MDTypography>   <Switch checked={!localStorageVideoSwitch} onChange={() => {
                    disableVideoLogin()
                }} />
            </Grid>
        </Grid>
    );

    useEffect(() => {
        setInstructionalVideo(localStorageVideoSwitch)
    }, [localStorageVideoSwitch])


    return (
        <GenericModal
            open={openTrainingVideoModal}
            setOpen={setOpenTrainingVideoModal}
            header={header}
            body={body}
            buttons={buttons}
            width={isSmallScreen ? "90%" : "40%"}
            buttonLocation="center"
        />
    );
};

export default TrainingVideoModal;
