import * as yup from "yup";


export const i9EmployerSchema = yup.object().shape({
    listADocumentTitle: yup
        .string()
        .nullable(true),
    listAIssuingAuthority: yup
        .string()
        .nullable(true)
        .when("listADocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("Last A Issuing Authority is required")
            },

        }),
    listAIssuingAuthorityState: yup
        .string()
        .nullable(true)
        .when("listAIssuingAuthority", {
            is: (val) => {
                return val === "List of 50 States Below"
            },
            then: (schema) => {
                return schema.required("Last A Issuing Authority State is required")
            },

        }),
    listADocumentNumber: yup
        .string()
        .nullable(true)
        .when("listADocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("Last A Document Number is required")
            },

        }),
    listAExpirationDate: yup.string().nullable(),
    listBDocumentTitle: yup
        .string()
        .nullable(true),
    listBIssuingAuthority: yup
        .string()
        .nullable(true)
        .when("listBDocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("List B Issuing Authority is required")
            },

        }),
    listBIssuingAuthorityState: yup
        .string()
        .nullable(true)
        .when("listBIssuingAuthority", {
            is: (val) => {
                return val === "List of 50 States Below"
            },
            then: (schema) => {
                return schema.required("List B Issuing Authority State is required")
            },

        }),
    listBDocumentNumber: yup
        .string()
        .nullable(true)
        .when("listBDocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("List B Document Number is required")
            },

        }),
    listBExpirationDate: yup.string().nullable(),
    listCDocumentTitle: yup
        .string()
        .nullable(true)
        .when("listBDocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("List C fields must be filled")
            },

        }),
    listCIssuingAuthority: yup
        .string()
        .nullable(true)
        .when("listCDocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("List C Issuing Authority is required")
            },

        }),
    listCIssuingAuthorityState: yup
        .string()
        .nullable(true)
        .when("listCIssuingAuthority", {
            is: (val) => {
                return val === "List of 50 States Below"
            },
            then: (schema) => {
                return schema.required("List C Issuing Authority State is required")
            },

        }),
    listCDocumentNumber: yup
        .string()
        .nullable(true)
        .when("listCDocumentTitle", {
            is: (val) => {
                return val !== null
            },
            then: (schema) => {
                return schema.required("List C Document Number is required")
            },

        }),
    listCExpirationDate: yup.string().nullable(),
    firstDayOfEmployment: yup.date().required("First day of employment is required").nullable().default(null),

    // listBDocumentTitle: ,
    // listBIssuingAuthority: ,
    // listBIssuingAuthorityState: ,
    // listBDocumentNumber: ,
    // listBExpirationDate: ,
    // listCDocumentTitle:,
    // listCIssuingAuthority: ,
    // listCIssuingAuthorityState: ,
    // listCDocumentNumber:,
    // listCExpirationDate:,
    // firstDayOfEmployment: ,
    // reverificationOrRehire: ,


});
