import axios from "axios";
import { baseAxios } from "config";

const logout = async () => {
  try {
    const response = await baseAxios.post(`/auth/logout`, {});

    return response;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default logout;
