import { Grid, Icon, Stack } from "@mui/material";
import moment from "moment";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const AdditionalPosition = (row, idx, positionHandler, removePosition) => {
  if (!row) return <></>;
  const { positionName, numberPositions, reportTime, billRate, payRate, endTime } = row;

  return (
    <>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => positionHandler(row, true, idx)}
      >
        <Grid item sm={5}>
          <MDTypography sx={{ fontSize: "0.9rem" }}>
            <strong> {positionName}</strong>, Number Needed: <strong> {numberPositions}</strong>
          </MDTypography>

          <MDTypography sx={{ fontSize: "0.9rem" }}>Bill: ${billRate}</MDTypography>
        </Grid>
        <Grid item sm={5}>
          <MDTypography sx={{ fontSize: "0.9rem" }}>
            {moment(reportTime).format("hh:mm A")} - {moment(endTime).format("hh:mm A")}
          </MDTypography>

          <MDTypography sx={{ fontSize: "0.9rem" }}>Pay: ${payRate}</MDTypography>
        </Grid>

        <Grid item sm={2} alignSelf="center">
          <MDBox textAlign="right">
            <Icon
              color="error"
              fontSize="lg"
              onClick={(e) => {
                e.stopPropagation();
                removePosition(idx);
              }}
            >
              cancel
            </Icon>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalPosition;
