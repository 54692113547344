import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import {
  Cancel as CancelIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Send as SendIcon,
  SendOutlined as SendOutlinedIcon,
  ThumbDown as ThumbDownIcon,
} from "@mui/icons-material";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDBox from "components/MDBox";
import PictureAvatar from "components/PictureAvatar";
import CustomTextField from "components/Form/CustomTextField";
import updateApplicantStage from "layouts/pages/applicants/actions/updateApplicantStatus";
import updateApplicantJobAIInterviews from "layouts/pages/applicants/actions/updateApplicantJobAIInterviews";
import pushApplicantJobAIInterviewMessage from "layouts/pages/applicants/actions/pushApplicantJobAIInterviewMessage";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import AIInterviewChatWindow from "../AIInterviewChatWindow";

const firstMessage = "Hey there! Let us know if you're ready to start the interview.";
const lastMessage =
  "Thank you for answering Screening questions. We'll get back to you soonest. In the meantime you can update your Profile and add info.";

const styles = {
  container: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    height: "100%",
    maxWidth: 1170,
    padding: "2rem",
    // overflow: "auto",
  },
};

const ApplicantAIInterviewModal = ({
  open,
  onClose,
  applicant,
  questions,
  history,
  jobSlug,
  onAddMessage,
  interviewEndDate,
  onPressUpdateInfo,
  onPressBackToHome,
  listRef,
}) => {
  const [questionIndex, setQuestionIndex] = useState(null);
  const { currentLoggedUser, userType, company, currentJob } = useAppContextController();
  const [textValue, setTextValue] = useState("");

  const [localHistory, setLocalHistory] = useState([]);
  const [interviewFinished, setInterviewFinished] = useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const updateAiInterviewMutation = useMutation(pushApplicantJobAIInterviewMessage, {
    onSuccess: async (_, { id, data, isLast }) => {
      onAddMessage?.(data, isLast);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "pushApplicantJobAIInterviewMessageMutation",
  });

  useEffect(() => {
    if (!(history ?? []).length) {
      // If interview hasn't started yet, push the first message from the bot
      const messageData = {
        type: "other",
        index: 0,
        message: firstMessage,
        timestamp: new Date().toISOString(),
        isAnswer: false,
      };
      updateAiInterviewMutation.mutate({
        id: applicant._id,
        jobSlug,
        data: messageData,
        outsideMode: "protected",
      });
      setLocalHistory([messageData]);
    } else {
      setLocalHistory(history);
      if (interviewEndDate) {
        setInterviewFinished(true);
      }
    }
  }, []);

  const onPushNextQuestion = (messageIndex) => {
    let nextQuestion = null;
    for (let index = 0; index < questions.length; index += 1) {
      const question = questions[index];
      const foundInHistory = localHistory.find(
        (hitem) => !hitem.isAnswer && hitem.message === question.text
      );
      if (!foundInHistory) {
        nextQuestion = question;
        break;
      }
    }
    if (nextQuestion) {
      const messageData = {
        type: nextQuestion.type,
        index: messageIndex,
        message: nextQuestion.text,
        timestamp: new Date().toISOString(),
        isAnswer: false,
      };
      updateAiInterviewMutation.mutate({
        id: applicant._id,
        jobSlug,
        data: messageData,
        outsideMode: "protected",
      });
      setLocalHistory((lh) => [...lh, messageData]);
    } else {
      // No more questions, send last message and close the interview
      const messageData = {
        type: "other",
        index: messageIndex,
        message: lastMessage,
        timestamp: new Date().toISOString(),
        isAnswer: false,
      };
      updateAiInterviewMutation.mutate({
        id: applicant._id,
        jobSlug,
        data: messageData,
        outsideMode: "protected",
        isLast: true,
      });
      setLocalHistory((lh) => [...lh, messageData]);
      setInterviewFinished(true);
    }
  };

  const onPressSend = () => {
    const messageData = {
      type: "answer",
      index: localHistory.length,
      message: textValue,
      timestamp: new Date().toISOString(),
      isAnswer: true,
    };
    setLocalHistory((lh) => [...lh, messageData]);
    setTextValue("");
    updateAiInterviewMutation
      .mutateAsync({
        id: applicant._id,
        jobSlug,
        data: messageData,
        outsideMode: "protected",
      })
      .then(() => onPushNextQuestion(localHistory.length + 1));
  };

  const onPressKey = (e) => {
    if (e.keyCode === 13 && !interviewFinished) {
      onPressSend();
    }
  };

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localHistory.length]);

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card style={styles.container}>
            <Button
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                m: 2,
                p: 0,
              }}
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <CancelIcon color="secondary" />
            </Button>
            <Grid container item flexDirection="column" height="100%">
              <Grid
                item
                container
                sx={{
                  borderBottomWidth: 2,
                  borderBottomColor: "#8f9095",
                  borderBottomStyle: "solid",
                  paddingBottom: 1,
                }}
              >
                <PictureAvatar
                  image={null}
                  firstName={applicant?.firstName}
                  lastName={applicant?.lastName}
                  // userId={usr?._id?.toString()}
                  size="md"
                  disableHover
                />
                <MDBox display="flex" flexDirection="column" pl={1}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="dark">
                    {`${applicant?.firstName ?? ""} ${applicant.lastName ?? ""}`}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item container sx={{ flexBasis: 0, flexGrow: 1, overflow: "hidden" }}>
                <AIInterviewChatWindow
                  firstName={applicant?.firstName}
                  lastName={applicant?.lastName}
                  items={localHistory}
                  inverted
                  interviewFinished={interviewFinished}
                  onPressUpdateInfo={onPressUpdateInfo}
                  onPressBackToHome={onPressBackToHome}
                  listRef={listRef}
                />
              </Grid>
              <TextField
                onChange={(e) => setTextValue(e.target.value)}
                value={textValue}
                placeholder="Type a new message here"
                onKeyDown={onPressKey}
                disabled={interviewFinished}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="secondary"
                        onClick={onPressSend}
                        edge="end"
                        disabled={interviewFinished}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of CalendarDayModal
ApplicantAIInterviewModal.defaultProps = {};

// Typechecking props of the CalendarDayModal
ApplicantAIInterviewModal.propTypes = {};

export default ApplicantAIInterviewModal;
