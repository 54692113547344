import states from "assets/stateCodes.json";
import * as yup from "yup";

export const applicantSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[A-Za-z\s.,-]*$/, "First Name contains invalid characters."),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(/^[A-Za-z\s.,-]*$/, "Last Name contains invalid characters."),
  maidenName: yup
    .string()
    .default("")
    .matches(/^[A-Za-z\s.,]*$/, "Maiden Name contains invalid characters."),
  applicationDate: yup.date().default(new Date()),
  birthDate: yup.date().required("Birth date is required").nullable().default(null),
  city: yup.string().default("").required("City is required"),
  criminalHistoryDisclosure: yup.string().default(""),
  driverLicense: yup.string().default(""),
  emergencyContactName: yup.string().default(""),
  emergencyContactNumber: yup
    .string()
    .default("")
    .test({
      name: "test-emergencyContactNumber-length",
      test: (value, ctx) => {
        const trimValueLength = value
          .replace(/_/g, "")
          .replace(/ /g, "")
          .replace("(", "")
          .replace(")", "").length;
        if (trimValueLength === 10 || trimValueLength === 0) {
          return true;
        }
        return ctx.createError({ message: "Emergency Contact number must be 10 digits" });
      },
    }),
  middleInitial: yup.string().default(""),
  altPhone: yup
    .string()
    .default("")
    .test({
      name: "test-altPhone-length",
      test: (value, ctx) => {
        const trimValueLength = value.replace(/\D/g, "").length;

        if (trimValueLength === 10 || trimValueLength === 0) {
          return true;
        }
        return ctx.createError({ message: "Alternative Phone number must be 10 digits" });
      },
    }),
  phone: yup
    .string()
    .default("")
    .required("Mobile number is required")
    .test({
      name: "test-phone-length",
      test: (value, ctx) => {
        const trimValueLength = value.replace(/\D/g, "").length;

        if (trimValueLength === 10) {
          return true;
        }
        return ctx.createError({ message: "Phone number must be 10 digits" });
      },
    }),
  socialSecurity: yup
    .string()
    .default("")
    .required("Social Security is required")
    .test({
      name: "test-socialSecurity-length",
      test: (value, ctx) => {
        const trimValueLength = value.replace(/-/g, "").replace(/_/g, "").length;
        if (trimValueLength === 9) {
          return true;
        }
        return ctx.createError({ message: "Social Security number must be 9 digits" });
      },
    }),
  state: yup
    .string()
    .trim()
    .test({
      name: "state exist",
      test(value, ctx) {
        if (!states.includes(value)) {
          return ctx.createError({ message: "Insert a valid state" });
        }
        return true;
      },
    })
    .default(""),
  address1: yup.string().default("").required("Address is required"),
  zip: yup
    .string()
    .default("")
    .required("Zip code is required")
    .test({
      name: "test-zip-format",
      test: (value, ctx) => {
        const parsed = value.replace(/\D/g, "");
        if (parsed.length === 5 || parsed.length === 9) {
          return true;
        }
        return ctx.createError({ message: "Zip should only be a 5 or 9 digit format" });
      },
    }),
});
