// TEMPORARY, to be deleted

const APPLICANT_DETAIL_DATA = [
  {
    id: 1,
    applicant: "Test Data",
    applyDate: new Date("1/1/2024"),
    source: "Indeed",
    status: "New",
  },
  {
    id: 2,
    applicant: "Test Data",
    applyDate: new Date("1/2/2024"),
    source: "Indeed",
    status: "Declined",
  },
  {
    id: 3,
    applicant: "Test Data",
    applyDate: new Date("1/2/2024"),
    source: "Indeed",
    status: "Screened",
  },
  {
    id: 4,
    applicant: "Test Data",
    applyDate: new Date("1/2/2024"),
    source: "Indeed",
    status: "ATC",
  },
  {
    id: 5,
    applicant: "Test Data",
    applyDate: new Date("1/2/2024"),
    source: "Indeed",
    status: "Pre-Hire",
  },
];

const JOB_APPLIES_DATA = [
  {
    id: 1,
    source: "Indeed",
    quantity: 5,
    new: 4,
    atc: 2,
    screened: 3,
    prehire: 1,
    decline: 0,
  },
  {
    id: 2,
    source: "Website",
    quantity: 3,
    new: 0,
    atc: 2,
    screened: 5,
    prehire: 1,
    decline: 2,
  },
  {
    id: 3,
    source: "Referral",
    quantity: 1,
    new: 2,
    atc: 1,
    screened: 2,
    prehire: 0,
    decline: 3,
  },
  {
    id: 4,
    source: "LinkedIn",
    quantity: 1,
    new: 1,
    atc: 3,
    screened: 1,
    prehire: 1,
    decline: 4,
  },
];

// might have to modify since this one includes the colors
const APPLICANT_ACTIVITY_STAGE_DATA = [
  {
    name: "New",
    value: 1,
    color: "#9C27B0",
  },
  {
    name: "ATC",
    value: 4,
    color: "#E28700",
  },
  {
    name: "Screened",
    value: 6,
    color: "#0070C0",
  },
  {
    name: "Pre-Hire",
    value: 2,
    color: "#008000",
  },
  {
    name: "Declined",
    value: 1,
    color: "#CC3300",
  },
];

// might have to modify since this one includes the colors
const STAFFING_POOL_DATA = [
  {
    name: "All",
    value: 347,
    color: "#0070C0",
  },
  {
    name: "Active",
    value: 201,
    color: "#008000",
  },
  {
    name: "Hired",
    value: 6,
    color: "#FF0066",
  },
  {
    name: "Inactive",
    value: 1,
    color: "#E28700",
  },
  {
    name: "Terminated",
    value: 1,
    color: "#CC3300",
  },
  {
    name: "No Login",
    value: 13,
    color: "#0070C0",
  },
];

export {
  APPLICANT_DETAIL_DATA,
  JOB_APPLIES_DATA,
  APPLICANT_ACTIVITY_STAGE_DATA,
  STAFFING_POOL_DATA,
};
