import { Landscape } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Autocomplete, Box, Button, Card, Grid, Stack, styled, Switch } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCombinedDate } from "utils/helpers/events";

import uploadImage from "api/upload/uploadImage";
import DataList from "components/DataList";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import TagList from "components/TagList";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { eventInfoSchema } from "data/event";
import useDropdowns from "hooks/useDropdowns";
import useCompanyCache from "hooks/useCompanyCache";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";
import createEvent from "layouts/pages/events/actions/createEvent";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchSingleEvent from "layouts/pages/events/actions/fetchSingleEvent";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { EVENT_ENTITIES } from "utils/constants/entityOptions";
import generateSlug from "utils/generateSlug";
import { getDirtyFields } from "utils/helpers/formHelpers";
import useSessionAuth from "hooks/useSessionAuth";
import AddAditionalPositionModal from "../../EventsInformation/AddAdditionalPositionModal";
import Attachments from "../../EventsInformation/Attachments";
import ReminderToSaveChangesModal from "../../EventsInformation/ReminderToSaveChangesModal";
import UploadFileModal from "../../EventsInformation/UploadFileModal";
import EventActions from "./EventActions";
import AdditionalPosition from "./EventsAdditionalPosition";

const AvatarImg = styled(Box)(({ theme }) => ({ width: "100%" }));
const useStyle = makeStyles({
  editor: {
    height: 275,
  },
  dropzone: {
    height: 170,
  },
  previewButton: {
    padding: 0,
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
  },
});

const { EVENT_STATUS, EVENT_TYPES, STATES } = EVENT_ENTITIES;

const EventForm = ({
  setPreviewContent,
  setEventPreview,
  setOpen,
  getVenueField,
  setEventLogo,
  setCopyEventName,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resolver = useYupValidationResolver(eventInfoSchema);

  const { dropdowns: eventStatusOptions } = useDropdowns({ entity: EVENT_STATUS });
  const { dropdowns: eventTypeOptions } = useDropdowns({ entity: EVENT_TYPES });
  const { dropdowns: stateCodeOptions } = useDropdowns({ entity: STATES });

  const [searchParams] = useSearchParams();
  const copyEventId = searchParams.get("copyEventId");
  const { enqueueSnackbar } = useSnackbar();
  const { createVenueSlug } = useParams();
  const {
    currentEvent,
    setCurrentEvent,
    venues,
    currentLoggedUser,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const [eventToCopy, setEventToCopy] = useState(null);
  const [positionIndex, setPositionIndex] = useState(null);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [editorValue, setEditorValue] = useState(null);
  const [additionalPositionModalOpen, setAdditionalPositionModalOpen] = useState(false);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);

  const secondaryLocationListRef = useRef([]);
  const venueNamesListRef = useRef([]);

  const isEdit = !!currentEvent?._id;
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    setError,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({ mode: "onBlur", defaultValues: currentEvent, resolver });

  const defaultValues = {
    eventName: "",
    eventType: "Event",
    status: "Active",
    venueName: createVenueSlug ? getVenueField(createVenueSlug, "name") : null,
    secondaryLocation: "",
    address: createVenueSlug ? getVenueField(createVenueSlug, "address") : "",
    venueCity: createVenueSlug ? getVenueField(createVenueSlug, "city") : "",
    venueState: createVenueSlug ? getVenueField(createVenueSlug, "state") : "",
    zip: createVenueSlug ? getVenueField(createVenueSlug, "zip") : "",
    eventId: "",
    eventUrl: "",
    makePublicAndSendNotification: "Yes",
    sendConfirmationToSignUps: "No",
    waitListPercentage: "25",
    eventDate: null,
    eventEndTime: null,
    reportTimeTBD: "",
    positionsRequested: "",
    billRate: 0,
    payRate: 0,
    eventLogo: "No",
    jobLogo: "No",
    venueBanner: "No",
    googleMap: "No",
    geoFence: "Yes",
    allowPartners: "No",
    allowEarlyClockin: "No",
    enableClockInReminders: "No",
    allowWaitList: "No",
    allowNonHired: "No",
    reminder48Hour: "No",
    reminder24Hour: "No",
    notifyCallOff: "No",
    description: "",
    venueSlug: createVenueSlug || "",
    positions: [],
    attachments: [],
    applicants: [],
    tags: [],
  };

  const { logoutAuth0User } = useSessionAuth();

  const { mutate: uploadMutation } = useMutation(
    async (data) => {
      const form = new FormData();
      form.append("file", data.file);

      try {
        await uploadImage({
          uploadPath: `${currentEvent.venueSlug}/events/${currentEvent?.eventUrl}`,
          form,
        });
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }

      try {
        await updateEvent({ eventId: currentEvent._id, data: { eventImage: data.eventImage } });
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
    {
      onError: (error) => enqueueSnackbar(`Error: ${error}`, { variant: "error" }),
      onSuccess: (_, data) => {
        setCurrentEvent({ ...currentEvent, ...data });
        enqueueSnackbar(`Image been updated!`, { variant: "success" });
      },
    }
  );

  const dateToSlugString = (date) =>
    getValues()?.eventDate?.toString()?.split(" ")?.splice(0, 4)?.join("-")?.toLowerCase();

  const onVenueNameChange = () => {
    Object.keys(venues).forEach((key) => {
      const { name, logoUrl, slug, address, city, state, zip, locations } = venues[key];
      if (isEqual((name ?? "").trim(), (getValues("venueName") ?? "").trim())) {
        setValue("logoUrl", logoUrl, { shouldDirty: true });
        setValue("venueSlug", slug, { shouldDirty: true });
        setValue("address", address, { shouldDirty: true });
        setValue("venueName", name, { shouldDirty: true });
        setValue("venueCity", city, { shouldDirty: true });
        setValue("venueState", state, { shouldDirty: true });
        setValue("zip", zip, { shouldDirty: true });
        secondaryLocationListRef.current = locations?.map((location) => ({
          ...location,
          label: location.locationName,
        }));
      }
    });
  };

  const handleWaitlistChange = (value) =>
    setValue("waitListPercentage", value, { shouldDirty: true });

  const addNewAdditionalPosition = () => setAdditionalPositionModalOpen(true);

  const createEventMutation = useMutation(createEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      enqueueSnackbar("Event has been created!", { variant: "success" });
      setCurrentEvent({
        ...currentEvent,
        ...data,
        [_.id.name]: _.id?.[_.id.name],
        _id: _.result.insertedId,
      });
      await queryClient.invalidateQueries("events");
      navigate(`/events/${data.eventUrl}`);
    },
  });

  const createEventFormHandler = async (values) => {
    const updatedValues = { ...values };
    if (copyEventId) updatedValues.copyEventId = copyEventId;
    updatedValues.createAgent = currentLoggedUser._id;
    updatedValues.billRate = parseFloat(values.billRate);
    updatedValues.payRate = parseFloat(values.payRate);
    updatedValues.eventEndTime = new Date(updatedValues.eventEndTime).toISOString();
    updatedValues.eventDate = new Date(updatedValues.eventDate).toISOString();

    await createEventMutation.mutateAsync(updatedValues);
  };

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar("Event has been updated!", { variant: "success" });
      await queryClient.invalidateQueries("events");
    },
  });

  const editEventFormHandler = async (values) => {
    if (copyEventId) return;
    if (!currentEvent.venueSlug || !currentEvent.eventId) {
      enqueueSnackbar("Cannot Update Event -- No Event ID!", { variant: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    if (modifiedFields?.eventEndTime) {
      modifiedFields.eventEndTime = new Date(modifiedFields.eventEndTime).toISOString();
    }
    if (modifiedFields?.eventDate) {
      modifiedFields.eventDate = new Date(modifiedFields.eventDate).toISOString();
    }
    await updateEventMutation.mutateAsync({
      venueSlug: currentEvent.venueSlug,
      eventId: currentEvent._id,
      data: {
        ...modifiedFields,
        billRate: parseFloat(values.billRate),
        payRate: parseFloat(values.payRate),
      },
    });
  };

  const onDropImageHandler = (img) => {
    const file = img[0];
    uploadMutation({ eventImage: file.name, file });
  };

  const avatar = currentEvent?.eventImage ? (
    <Card>
      <AvatarImg
        component="img"
        src={`${company?.imageUrl}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${currentEvent?.eventImage}`}
        alt={
          currentEvent?.eventImage ? `${currentEvent.eventName} Event Image` : "No Event Image Set"
        }
      />
    </Card>
  ) : (
    <Landscape sx={{ width: "5em", height: "5em" }} />
  );
  // additional positions functions.
  const positionHandler = (row, isOpenAdditionPosition, posIdx) => {
    setCurrentPosition(row);
    setAdditionalPositionModalOpen(isOpenAdditionPosition);
    setPositionIndex(posIdx);
  };

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setEventPreview(currentEvent);
    setOpen(true);
  };

  const removePosition = (idx) => {
    const temporaryPositions = getValues()?.positions;
    temporaryPositions.splice(idx, 1);
    setValue("positions", temporaryPositions, { shouldDirty: true });
    if (isEdit) {
      setReminderToSaveChangesModalOpen(true);
    }
  };

  // initialize rendering first value for edit and create upon load.
  useEffect(() => {
    if (copyEventId) delete currentEvent?._id;

    clearErrors();
    if (!isEdit) {
      const data = copyEventId && eventToCopy ? eventToCopy : defaultValues;
      setCurrentEvent(data);
      reset(data);
    } else if (isEdit && currentEvent) {
      reset(currentEvent);
    }

    if (currentEvent) setEventLogo(getValues("logoUrl"));
    // setEditorValue(
    //   !copyEventId && !isEdit ? defaultValues?.description : currentEvent?.description
    // );
  }, [isEdit, watch, copyEventId, eventToCopy]);
  // initialize venues on dropdown field secondary venue and venue name list
  useEffect(() => {
    if (venues) {
      Object.keys(venues).forEach((key) => {
        if (isEqual((venues[key].name ?? "").trim(), (getValues("venueName") ?? "").trim())) {
          secondaryLocationListRef.current = venues[key].locations?.map((location) => ({
            ...location,
            label: location.locationName,
          }));
        }
      });
      venueNamesListRef.current = Object.keys(venues)
        .map((key) => venues[key].name)
        .sort((venueA, venueB) => (venueA > venueB ? 1 : -1));
    }
  }, [venues, isEdit, currentEvent]);
  // validate and generate event url.
  const eventNameValue = useEffect(() => {
    if (isEdit) return;
    const hasEventUrl =
      getValues("eventName") !== "" &&
      getValues("venueSlug") !== "" &&
      getValues("eventDate") !== "";
    const variables = {
      venueSlug: getValues("venueSlug"),
      title: getValues("eventName"),
      suffix: dateToSlugString(getValues("eventDate")),
      slugField: "eventUrl",
      fetchFunction: fetchEvents,
    };

    if (hasEventUrl) {
      (async () => {
        const { slug } = await generateSlug(variables);
        setValue("eventUrl", slug);
      })();
    } else setValue("eventUrl", "");
  }, [getValues("eventName"), getValues("venueSlug"), getValues("eventDate")]);
  // copy event initialize values
  useEffect(() => {
    const fetchEventToCopy = async (eid) => {
      const event = await fetchSingleEvent({ eventId: eid });
      if (event) {
        setCopyEventName(event.eventName);
        event.eventName = "";
        event.reportTime = "";
        event.eventDate = "";
        event.eventEndTime = "";
        event.eventUrl = "";
        event.eventId = "";
        event.applicants = [];
        setValue("description", event.description);
        delete event._id;
        if (!event.positions) event.positions = [];
        if (!event.attachments) event.attachments = [];
        setEventToCopy(event);
        setEditorValue(event.description);
      }
    };

    if (copyEventId) {
      try {
        fetchEventToCopy(copyEventId);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [copyEventId]);

  // reset form value if current event id has change.
  useEffect(() => {
    reset(currentEvent);
    setEventLogo(getValues("logoUrl"));
  }, [currentEvent]);

  const handlePositionChanges = () => {
    const eventDate = getValues("eventDate");
    const eventEndTime = getValues("eventEndTime");
    if (new Date(eventDate)?.getTime() && new Date(eventEndTime)?.getTime()) {
      const allPositions = getValues("positions");
      const tempPositions = allPositions?.map((pos) => {
        const reportTime = getCombinedDate(eventDate, pos?.reportTime || eventDate);
        const endTime = getCombinedDate(eventEndTime, pos?.endTime || eventEndTime);
        return { ...pos, reportTime, endTime };
      });
      setValue("positions", tempPositions, { shouldDirty: true });
    }
  };

  return (
    <>
      <form
        onSubmit={
          isEdit ? handleSubmit(editEventFormHandler) : handleSubmit(createEventFormHandler)
        }
      >
        <FlexWrapper justifyContent="end">
          <EventActions
            submitted={isSubmitting}
            hasChanges={isDirty}
            resetChanges={() => reset(currentEvent)}
            setEditorValue={setEditorValue}
            copyEventId={copyEventId}
            updateEventMutation={updateEventMutation}
            onPreview={() => openPreviewModal("all")}
          />
        </FlexWrapper>
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox mt={1}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                      Event Information
                    </MDTypography>
                  </Grid>
                  <Grid item sm={12}>
                    <Controller
                      name="eventName"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled={isEmpty(getValues("venueName"))}
                          inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                          type="text"
                          label="Event Name"
                          InputLabelProps={{ shrink: !isEmpty(getValues("eventName")) }}
                          {...field}
                        />
                      )}
                    />
                    {errors?.eventName && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.eventName.message}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item sm={6}>
                    <MDBox>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={eventStatusOptions}
                            autoSelect
                            freeSolo
                            name="status"
                            value={field.value || null}
                            onChange={(e, v) =>
                              field.onChange(v.charAt(0).toUpperCase() + v.slice(1))
                            }
                            onBlur={() => trigger("status")}
                            renderInput={(params) => (
                              <FormField {...params} type="text" label="Event Status" {...field} />
                            )}
                          />
                        )}
                      />
                      {errors?.status && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.status.message}
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="eventType"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={eventTypeOptions}
                          autoSelect
                          freeSolo
                          name="eventType"
                          value={field.value || null}
                          onChange={(e, v) =>
                            field.onChange(v.charAt(0).toUpperCase() + v.slice(1))
                          }
                          onBlur={() => trigger("eventTypes")}
                          renderInput={(params) => (
                            <FormField {...params} type="text" label="Event Type" {...field} />
                          )}
                        />
                      )}
                    />
                    {errors?.eventType && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.eventType.message}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item sm={12}>
                    <MDBox mt={1}>
                      <Controller
                        name="venueName"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={venueNamesListRef.current}
                            disabled={isEdit}
                            inputProps={isEdit ? { tabIndex: -1 } : {}}
                            name="venueName"
                            value={field.value || null}
                            onChange={(e) => {
                              field.onChange(e.target.textContent);
                              onVenueNameChange();
                              trigger("address");
                            }}
                            renderInput={(params) => (
                              <FormField {...params} type="text" label="Venue Name" />
                            )}
                          />
                        )}
                      />
                      {errors?.venueName && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.venueName.message}
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item sm={12}>
                    <MDBox mt={2}>
                      <Controller
                        control={control}
                        name="secondaryLocation"
                        render={({ field }) => (
                          <>
                            <Autocomplete
                              options={
                                !isEmpty(secondaryLocationListRef.current)
                                  ? [
                                      { label: "<Original Venue Location>" },
                                      ...secondaryLocationListRef.current,
                                    ]
                                  : []
                              }
                              disabled={isEmpty(getValues("venueName"))}
                              inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                              name="secondaryLocation"
                              value={field.value ? field.value.locationName || null : null}
                              onChange={(e, v) => {
                                if (v.label === "<Original Venue Location>") {
                                  onVenueNameChange();
                                  field.onChange(v);
                                } else {
                                  setValue("address", v.address, { shouldDirty: true });
                                  setValue("venueCity", v.city, { shouldDirty: true });
                                  setValue("venueState", v.state, { shouldDirty: true });
                                  setValue("zip", v.zip, { shouldDirty: true });
                                  field.onChange(v);
                                }
                              }}
                              renderInput={(params) => (
                                <FormField {...params} type="text" label="Secondary Location" />
                              )}
                            />
                          </>
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item sm={12}>
                    <MDBox mt={2}>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type="text"
                            label="Address"
                            InputLabelProps={{ shrink: !isEmpty(getValues("address")) }}
                            {...field}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDBox mt={2}>
                      <Controller
                        name="venueCity"
                        control={control}
                        render={({ field }) => (
                          <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type="text"
                            label="City"
                            InputLabelProps={{ shrink: !isEmpty(getValues("venueCity")) }}
                            {...field}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox mb={3} mt={2}>
                      <Controller
                        name="venueState"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            freeSolo
                            options={stateCodeOptions}
                            defaultValue={null}
                            name="venueState"
                            value={field.value || (stateCodeOptions ? stateCodeOptions?.[0] : "")}
                            onChange={(e, v) => {
                              field.onChange(v.toUpperCase());
                            }}
                            onBlur={() => trigger("state")}
                            renderInput={(params) => (
                              <FormField {...params} type="text" label="State" />
                            )}
                          />
                        )}
                      />
                      {errors?.state && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.state.message}
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox mt={2}>
                      <Controller
                        name="zip"
                        control={control}
                        render={({ field }) => (
                          <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type="text"
                            label="Zip Code"
                            InputLabelProps={{ shrink: !isEmpty(getValues("zip")) }}
                            {...field}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox mt={2}>
                      <Controller
                        name="eventId"
                        control={control}
                        render={({ field }) => (
                          <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type="text"
                            label="Event ID"
                            InputLabelProps={{ shrink: getValues("eventId") !== "" }}
                            {...field}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <MDBox mt={2}>
                      <Controller
                        name="eventUrl"
                        control={control}
                        render={({ field }) => (
                          <FormField
                            disabled={
                              isEdit ||
                              isEmpty(getValues("venueSlug")) ||
                              isEmpty(getValues("eventDate")) ||
                              isEmpty(getValues("eventName"))
                            }
                            inputProps={
                              isEdit ||
                              isEmpty(getValues("venueSlug")) ||
                              isEmpty(getValues("eventDate")) ||
                              isEmpty(getValues("eventName"))
                                ? { tabIndex: -1 }
                                : {}
                            }
                            type="text"
                            label="Event URL"
                            InputLabelProps={{ shrink: !isEmpty(getValues("eventUrl")) }}
                            {...field}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox mt={2}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <MDTypography variant="body2" textTransform="capitalize">
                          EVENT DESCRIPTION
                        </MDTypography>
                        <Button
                          className={classes.previewButton}
                          onClick={() => openPreviewModal("description")}
                        >
                          Preview
                        </Button>
                      </Stack>
                    </MDBox>
                    <MDBox mb={10}>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field: { value, onChange, ...props } }) => (
                          <MDEditor
                            className={classes.editor}
                            theme="snow"
                            onChange={(text) => {
                              onChange(text);
                            }}
                            value={value}
                            placeholder="Write Company Description"
                            {...props}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                    Event Configuration
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eventDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          disabled={isEmpty(getValues("venueName"))}
                          inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                          label="Report Time"
                          value={!field.value ? null : field.value}
                          onChange={(date) => {
                            setValue("eventDate", new Date(date), { shouldDirty: true });
                            setValue(
                              "eventEndTime",
                              new Date(
                                new Date(getValues().eventDate).getTime() + 5 * 60 * 60 * 1000
                              ),
                              { shouldDirty: true }
                            );
                            handlePositionChanges();
                            clearErrors("eventDate");
                          }}
                          renderInput={(params) => <FormField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.eventDate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventDate.message}
                    </MDTypography>
                  )}
                </Grid>

                {currentEvent?.eventDate && currentEvent?.timeZone && (
                  <Grid item xs={12} sm={12} lg={9} xl={6}>
                    <MDTypography fontSize="0.725rem" padding={0} color="secondary">
                      Time at Venue
                    </MDTypography>
                    <MDTypography fontSize="0.675rem" padding={0} fontStyle="italic">
                      {moment(getValues("eventDate") || currentEvent?.eventDate)
                        .tz(currentEvent?.timeZone)
                        .format("hh:mm A") || "-"}
                    </MDTypography>
                    <MDTypography fontSize="0.675rem" padding={0} fontStyle="italic">
                      {currentEvent?.timeZone}
                    </MDTypography>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} lg={9} xl={6}>
                  <Controller
                    name="eventEndTime"
                    control={control}
                    render={({ field }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePicker
                            disabled={isEmpty(getValues("venueName"))}
                            inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                            label="Est. End Time"
                            value={!field.value ? null : field.value}
                            onChange={(date) => {
                              setValue("eventEndTime", new Date(date), { shouldDirty: true });
                              handlePositionChanges();
                            }}
                            renderInput={(params) => <FormField {...params} />}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                  {errors?.eventEndTime && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventEndTime.message}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name="reportTimeTBD"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="TBD/Time Text"
                        InputLabelProps={{ shrink: !isEmpty(getValues("reportTimeTBD")) }}
                        onChange={(e) => {
                          const reportTimeTBDTemp = e.target.value.toUpperCase();
                          setValue("reportTimeTBD", reportTimeTBDTemp, { shouldDirty: true });
                        }}
                        type="text"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="positionsRequested"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="number"
                        label="Positions requested"
                        placeholder="# Positions requested"
                        name="positionsRequested"
                        InputLabelProps={{ shrink: !isEmpty(getValues("positionsRequested")) }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="billRate"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        InputProps={{
                          startAdornment: "$",
                          inputProps: {
                            inputMode: "numeric",
                          },
                        }}
                        label="Bill Rate"
                        decimalScale={2}
                        {...field}
                        customInput={FormField}
                      />
                    )}
                  />

                  {errors?.billRate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.billRate.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="payRate"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        InputProps={{
                          startAdornment: "$",
                          inputProps: {
                            inputMode: "numeric",
                          },
                        }}
                        label="Pay Rate"
                        decimalScale={2}
                        {...field}
                        customInput={FormField}
                      />
                    )}
                  />
                  {errors?.payRate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.payRate.message}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={-3}>
                    <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                      ADDITIONAL POSITIONS
                    </MDTypography>
                    <IconButton
                      className={classes.addButton}
                      color="info"
                      onClick={addNewAdditionalPosition}
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </MDBox>
                </Grid>
                <hr />
                <Grid item xs={12}>
                  <Controller
                    name="positions"
                    control={control}
                    render={({ field }) => (
                      <DataList
                        renderRow={(row, idx) =>
                          AdditionalPosition(row, idx, positionHandler, removePosition)
                        }
                        data={field.value || []}
                        greybar
                        divider
                        scrollY
                        tableHeight={170}
                        height={200}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDTypography variant="body2" textTransform="capitalize" fontWeight="bold">
                    Notifications
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Make Public and Send Notification
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="makePublicAndSendNotification"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Send Confirmation to Sign-Ups
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="sendConfirmationToSignUps"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Allow Partners
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="allowPartners"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Allow Early Clock-In
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="allowEarlyClockin"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Enable Clock-In Reminders
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="enableClockInReminders"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <MDTypography variant="body2" textTransform="capitalize" fontWeight="bold">
                      Waitlist
                    </MDTypography>
                  </MDBox>

                  <Controller
                    name="waitListPercentage"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Grid container>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              10% over
                            </MDTypography>
                          </Grid>

                          <Grid item xs={3} direction="row" alignItems="center">
                            <Switch
                              id="10"
                              checked={field.value === "10"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              Waitlist All
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <Switch
                              id="Infinity"
                              name="waitListPercentage"
                              checked={field.value === "Infinity"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              25% over
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <Switch
                              id="25"
                              checked={field.value === "25"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              No Waitlist
                            </MDTypography>
                          </Grid>

                          <Grid item xs={3} direction="row" alignItems="center">
                            <Switch
                              id="0"
                              name="waitListPercentage"
                              checked={field.value === "0"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              30% over
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <Switch
                              id="30"
                              checked={field.value === "30"}
                              onChange={(e, v) => handleWaitlistChange(e.target.id)}
                            />
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              Call-Off Notifications
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} direction="row" alignItems="center">
                            <Controller
                              name="notifyCallOff"
                              control={control}
                              render={({ field: field24Hour }) => (
                                <Switch
                                  checked={field24Hour.value === "Yes"}
                                  onChange={(e) =>
                                    field24Hour.onChange(e.target.checked ? "Yes" : "No")
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                  <Grid container>
                    <Grid item xs={12} direction="row" alignItems="center">
                      <MDBox mb={2}>
                        <MDTypography variant="body2" textTransform="capitalize" fontWeight="bold">
                          Event Reminders
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={3} direction="row" alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        24-Hour
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} direction="row" alignItems="center">
                      <Controller
                        name="reminder24Hour"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3} direction="row" alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        GeoFence
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} direction="row" alignItems="center">
                      <Controller
                        name="geoFence"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} direction="row" alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        48-Hour
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} direction="row" alignItems="center">
                      <Controller
                        name="reminder48Hour"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} direction="row" alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        Google Map
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} direction="row" alignItems="center">
                      <Controller
                        name="googleMap"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <TagList
                    {...field}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {isEdit && (
                <MDBox mt={3}>
                  <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                    EVENT IMAGE
                  </MDTypography>

                  <Grid
                    item
                    xs={6}
                    mt={2}
                    style={{ maxWidth: "25%", marginLeft: "auto", marginRight: "auto" }}
                  >
                    <FileDropzone
                      className={classes.dropzone}
                      onDrop={onDropImageHandler}
                      avatar={avatar}
                    />
                  </Grid>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {isEdit && (
                <MDBox mt={2}>
                  <Attachments
                    setCurrentEvent={setCurrentEvent}
                    currentEvent={currentEvent}
                    setAttachmentOpen={setAttachmentOpen}
                  />
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </form>
      <UploadFileModal
        currentEvent={currentEvent}
        attachmentOpen={attachmentOpen}
        setAttachmentOpen={setAttachmentOpen}
      />
      <AddAditionalPositionModal
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        additionalPositionModalOpen={additionalPositionModalOpen}
        setAdditionalPositionModalOpen={setAdditionalPositionModalOpen}
        currentEvent={currentEvent}
        setCurrentEvent={setCurrentEvent}
        currentPosition={currentPosition}
        setCurrentPosition={setCurrentPosition}
        positionIndex={positionIndex}
        setPositionIndex={setPositionIndex}
        setReminderToSaveChangesModalOpen={setReminderToSaveChangesModalOpen}
        isEdit={isEdit}
      />
      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}
    </>
  );
};

export default EventForm;
