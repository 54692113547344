import { Controller } from "react-hook-form";
import useDropdowns from "hooks/useDropdowns";

import { Grid, Autocomplete } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";

import { COMPANY_ENTITIES } from "utils/constants/entityOptions";
import { PatternFormat } from "react-number-format";

const { STATES_ENTITY_NAME } = COMPANY_ENTITIES;

const ContactForm = ({ control, setValue, sourceComponent }) => {
  const { dropdowns: stateCodes } = useDropdowns({ entity: STATES_ENTITY_NAME });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              name="firstName"
              onChange={(e) => setValue("firstName", e.target.value)}
              label="First Name"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              onChange={(e) => setValue("lastName", e.target.value)}
              label="Last Name"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              onChange={(e) => setValue("email", e.target.value)}
              label="Email Address"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>

            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <PatternFormat
                  format="(###) ### ####"
                  allowEmptyFormatting
                  mask="_"
                  label="Phone"
                  {...field}
                  onValueChange={({ value }) =>
                    setValue("phone", value, { shouldDirty: true })
                  }
                  showError
                  errorMessage={error?.message}
                  customInput={CustomTextField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phoneExt"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  onChange={(e) => setValue("phoneExt", e.target.value)}
                  label="Phone Extension"
                  type="number"
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  showError
                  errorMessage={error?.message}
                />

              )}
            />
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={6}>
        <Controller
          name="address"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              onChange={(e) => setValue("address", e.target.value)}
              label="Address"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="title"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              onChange={(e) => setValue("title", e.target.value)}
              label="Job Title"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={2}>
        <Controller
          name="city"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              onChange={(e) => setValue("city", e.target.value)}
              label="City"
              InputLabelProps={{ shrink: true }}
              {...field}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="state"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={stateCodes}
              {...field}
              name="state"
              autoSelect
              freeSolo
              onChange={(e, v) => {
                setValue("state", v?.toUpperCase() || "", { shouldDirty: true });
              }}
              sx={{ maxHeight: "44px", width: "100%" }}
              renderInput={(params) => (
                <CustomTextField
                  type="text"
                  label="State"
                  showError
                  errorMessage={error?.message}
                  {...params}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="zip"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              onChange={(e) => setValue("zip", e.target.value)}
              label="Zip Code"
              {...field}
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContactForm;
