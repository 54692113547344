import { useCallback, useState } from "react";

const useSort = (orderByInit = "", orderInit = "asc") => {
  const [order, setOrder] = useState(orderInit);
  const [orderBy, setOrderBy] = useState(orderByInit);

  const toggleSort = useCallback(
    (field) => {
      const attemptedSplit = field?.split(":");
      if (attemptedSplit && attemptedSplit.length === 2) {
        setOrder(attemptedSplit[1]);
        setOrderBy(attemptedSplit[0]);
      } else {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
      }
    },
    [orderBy, order]
  );

  return { order, orderBy, toggleSort };
};

export default useSort;
