import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, LabelList } from "recharts";
import { Box, IconButton } from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";

const purple = "#5757FF";

const formatXAxisLabel = (value) => value.toUpperCase();

const StatModal = ({ open, setOpen, data, title = "New Applies" }) => {
  const header = (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <IconButton
        onClick={() => {
          setOpen(false);
        }}
      >
        <Cancel />
      </IconButton>
    </Box>
  );

  const modalBody = (
    <Box px={5}>
      <Box bgcolor={purple} pt={4} borderRadius="2rem">
        <MDTypography variant="h2" color="white" textAlign="center">
          {title}
        </MDTypography>
        <ResponsiveContainer aspect={2}>
          <BarChart
            title={title}
            data={data}
            margin={{ top: 40, right: 30, left: 30, bottom: 80 }}
            style={{ backgroundColor: purple, borderRadius: "2rem" }}
          >
            <XAxis
              dataKey="name"
              type="category"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "white" }}
              tickFormatter={formatXAxisLabel}
              dy={40}
            />
            <Bar dataKey="value" fill="white" radius={[40, 40, 40, 40]} barSize={40}>
              <LabelList dataKey="value" position="bottom" fill="white" dy={10} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      header={header}
      body={modalBody}
      borderRadius={5}
      overflow="hidden"
      contentPadding={0}
      width="50%"
      maxWidth={1170}
      maxHeight={940}
    />
  );
};

export default StatModal;
