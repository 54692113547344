import UserActivities from "components/Activities/UserActivities";
import { useAppContextController } from "context/AppContext";
import { useMediaQuery } from "@mui/material";

const MyActivities = () => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const { currentLoggedUser } = useAppContextController();

  return (
    <UserActivities user={currentLoggedUser} height={isLaptop ? 500 : null} tableHeight={420} />
  );
};

export default MyActivities;
