import { Grid, Icon, Stack, Switch } from "@mui/material";
import moment from "moment";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConfirmationModal from "components/ConfirmationModal";
import { useState } from "react";
import { JOB_POSITION_STATUS_ENUM } from "utils/constants/job";
import { useAppContextController } from "context/AppContext";
import JobPositionStatusButton from "../JobPositionStatusButton";

const JobPositionItem = ({ row, idx, onEdit, onRemove }) => {
  const { status, name, startDate, modifiedDate, applicantId, isNew } = row;

  const { userType } = useAppContextController();
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [isDeletingRow, toggleDeletingRow] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();
    if (applicantId) {
      toggleDeletingRow(true);
      toggleConfirmationModal(true);
    } else {
      onRemove(idx, row?.positionType?.label);
    }
  };

  const handleUpdate = (st, clearAll) => onEdit(idx, st, clearAll);

  const handleOpenFilledSlot = (st, clearAll) => {
    toggleDeletingRow(false);
    toggleConfirmationModal(true);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    if (isDeletingRow) {
      onRemove(idx, row?.positionType?.label);
    } else {
      onEdit(idx, JOB_POSITION_STATUS_ENUM.OPEN, true);
    }
    toggleConfirmationModal(false);
  };

  return (
    <>
      <Stack display="flex" direction="row" justifyContent="space-between">
        <Grid container display="flex" alignItems="center" spacing={1}>
          <Grid item md={12} lg={3.5}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>{name}</MDTypography>
          </Grid>
          <Grid item md={12} lg={2.5}>
            <JobPositionStatusButton
              status={status}
              onUpdate={handleUpdate}
              applicantId={applicantId}
              name={name}
              onOpenFilledSlot={handleOpenFilledSlot}
            />
          </Grid>
          <Grid item md={12} lg={3}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>
              {startDate !== null ? moment(startDate).format("L") : "N/A"}
            </MDTypography>
          </Grid>

          {isNew || userType === "Master" ? (
            <Grid item md={12} lg={1} mt="1px">
              <MDBox textAlign="right">
                <Icon color="error" fontSize="small" onClick={handleDelete}>
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          ) : null}
        </Grid>
      </Stack>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title={`Are you sure you want to remove ${name}?`}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default JobPositionItem;
