import axios from "axios";
import { baseAxios } from "config";

const exportEmployees = async ({ venueSlug, body }) => {
  try {
    const res = await baseAxios.post(`/venues/${venueSlug}/staffingpool/export`, body);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default exportEmployees;
