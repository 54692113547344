export const getVideos = async (videoUrls) => {
  const videos = [];

  for (let i = 0; i < videoUrls?.length; i += 1) {
    const url = videoUrls[i];
    // eslint-disable-next-line
    const res = await fetch(`https://noembed.com/embed?url=${url}`);
    // eslint-disable-next-line
    const { title } = await res.json();
    videos.push({ url, title });
  }

  return videos;
};
