import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { isEmpty } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";

import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import JobSearchbar from "components/JobsSearchbar";
import MDBox from "components/MDBox";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import useSessionAuth from "hooks/useSessionAuth";
import fetchJobsByZip from "api/jobs/fetchJobsByZip";
import ApplyButton from "../ApplyButton";
import ResultCard from "../ResultCard";

const Results = ({ fetchAll = false, filters, setFilters, tableHeight }) => {
  const { venues, company, companyType } = useAppContextController();
  const [zipFilter, setZipFilter] = useState(null);

  const [page, setPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState(null);
  const [uniqueCompanies, setUniqueCompanies] = useState({});
  const [showMore, setShowMore] = useState(false);
  const options = fetchAll ? { fetchAll } : { page, limit: null };
  const allOptions = {
    ...options,
    filters: { ...filters, hideThisJob: "No", status: "Active" },
    venues,
  };

  const searchFilterWithDefault = useMemo(() => {
    if (searchFilter && Object.keys(searchFilter).length) {
      return searchFilter;
    }
    if (company) {
      return companyType === "Venue"
        ? { venueCity: "", venueState: "" }
        : { companyState: "", companyCity: "" };
    }
    return null;
  }, [company, companyType, searchFilter]);

  const allOptionsFiltered = {
    ...options,
    filters: { ...filters, ...searchFilterWithDefault },
    venues,
    useOr: true,
  };

  const enrichWithCustomer = async (arr) => {
    // Assuming each item in the array is an object and has a companySlug field
    const uniqueSlugs = [
      ...new Set(arr.filter((item) => !isEmpty(item?.companySlug)).map((item) => item.companySlug)),
    ];

    // Fetching each company in parallel using Promise.all
    const companies = await Promise.all(
      uniqueSlugs.map(async (slug) => {
        const customer = await fetchSingleCustomer({ slug, outsideMode: "public" });
        return customer.data;
      })
    );

    if (!companies) return;

    const uniques = {};
    uniqueSlugs.forEach((slug, index) => {
      uniques[slug] = companies[index];
    });
    setUniqueCompanies(uniques);
  };

  const { logoutAuth0User } = useSessionAuth();

  const { data: jobsAll, isLoading: isLoadingAll } = useQuery(
    ["jobresults", { ...allOptions, outsideMode: "public" }],
    () => fetchJobs({ ...allOptions, outsideMode: "public" }),
    {
      refetchOnWindowFocus: false,
      onSuccess: async ({ data }) => {
        if (data) {
          const filtered = data.filter(
            (item) =>
              item.hideThisJob !== "Yes" &&
              item.status !== "Inactive" &&
              (companyType !== "Venue" ||
                !item.venueSlug ||
                !venues ||
                !venues[item.venueSlug] ||
                !venues[item.venueSlug].settings ||
                venues[item.venueSlug].settings.ShowVenueOnWebsite !== "No")
          );
          if (company?.companyType === "Company") {
            try {
              await enrichWithCustomer(filtered);
            } catch (error) {
              if (String(error).includes("401") || error?.response?.status === 401) {
                logoutAuth0User();
              }
            }
          }
        }
      },
      enabled: company?.companyType === "Company" || !isEmpty(venues),
      cacheTime: 0,
    }
  );

  const { data: jobsFiltered, isLoading: isLoadingFiltered } = useQuery(
    ["jobresultsfiltered", { ...allOptionsFiltered, outsideMode: "public" }],
    () => fetchJobs({ ...allOptionsFiltered, outsideMode: "public" }),
    {
      refetchOnWindowFocus: false,
      enabled: searchFilterWithDefault != null,
      cacheTime: 0,
    }
  );

  const { data: jobsByZip, isLoading: isLoadingByZip } = useQuery(
    [
      "jobresultsbyzip",
      { zip: zipFilter, company: company?.companyType === "Company" ? "company" : "" },
    ],
    () =>
      fetchJobsByZip({
        zip: zipFilter,
        company: company?.companyType === "Company" ? "company" : "",
      }),
    {
      refetchOnWindowFocus: false,
      enabled: zipFilter?.length === 5,
      retry: false,
      cacheTime: 0,
    }
  );

  const jobs = zipFilter?.length === 5 ? jobsByZip?.data : jobsFiltered;
  const isLoading = zipFilter?.length === 5 ? isLoadingByZip : isLoadingFiltered;

  const columns = useMemo(
    () => [
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      // { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Start Date",
        field: "startDate",
        customCell: (date, field, row) => moment(date).format("MM/DD/YYYY"),
      },
      { title: "Status", field: "status" },
      {
        field: "row",
        customCell: (element, field) => <ApplyButton row={field} />,
      },
    ],
    []
  );

  const sortFunction = (a, b) => {
    if (a?.companySlug) {
      if (a.companySlug > b.companySlug) return 1;
      if (a.companySlug === b.companySlug) return 0;
    }
    if (a?.venueSlug) {
      if (a.venueSlug > b.venueSlug) return 1;
      if (a.venueSlug === b.venueSlug) return 0;
    }
    return -1;
  };

  const parsedJobs = useMemo(() => {
    if (jobs?.data) {
      const filtered = jobs?.data?.filter(
        (item) =>
          item.hideThisJob !== "Yes" &&
          item.status !== "Inactive" &&
          (companyType !== "Venue" ||
            !item.venueSlug ||
            !venues ||
            !venues[item.venueSlug] ||
            !venues[item.venueSlug].settings ||
            venues[item.venueSlug].settings.ShowVenueOnWebsite !== "No")
      );
      filtered.sort(sortFunction);
      return filtered;
    }
    return [];
  }, [jobs]);

  const jobsTitles = jobsAll?.data?.map((job) => job.title).filter((jobTitle) => !!jobTitle);
  const noDuplicatesJobTitles = [...new Set(jobsTitles)];
  const showLessList = parsedJobs.slice(0, 5);

  return (
    <>
      <JobSearchbar
        columns={columns.slice(0, -1)}
        handleZipFilter={(data) => setZipFilter(data)}
        handleFilter={(data) => setSearchFilter(data)}
        company={company}
        jobsTitles={noDuplicatesJobTitles}
      />
      {showMore
        ? parsedJobs?.map((result, index) => (
            <MDBox mt={3} key={`${index.toString() + result?.companySlug}_job_item`}>
              <ResultCard result={result} customers={uniqueCompanies} />
            </MDBox>
          ))
        : showLessList?.map((result, index) => (
            <MDBox mt={3} key={`${index.toString() + result?.companySlug}_job_short_list_item`}>
              <ResultCard result={result} customers={uniqueCompanies} />
            </MDBox>
          ))}
      <MDBox mt={3} display="flex" justifyContent="center">
        <Button variant="contained" color="error" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show Less" : "Show More"}
        </Button>
      </MDBox>
      {/* <DataList
          renderRow={renderRow}
          data={parsedJobs}
          onRowClick={() => { }}
          title="Jobs Search Results"
          limit={null}
          greybar
          divider
          header={header}
          borderRadius="xl"
          tableHeight={tableHeight}
          height={tableHeight + 75}
          scrollY
          noLoading
        /> */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1 /* position: "absolute" */,
        }}
        open={isLoading && parsedJobs.length === 0}
      >
        <CircularProgress
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            margin: "auto",
            top: 0,
            bottom: 0,
            zIndex: 50,
          }}
        />
      </Backdrop>
    </>
  );
};

export default Results;
