import { useEffect, useState } from "react";
import GenericModal from "components/GenericModal";
import { Autocomplete, Grid } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import SendMessage from "components/SendMessage";
import useCompanyStore from "stores/useCompanyStore";
import { isEmpty } from "lodash";
import { APPLICANT_JOB_STATUS_ENUM } from "utils/constants/applicant";
import { useAppContextController } from "context/AppContext";

const SubmittalModal = ({ open, setOpen, applicant, handleUpdateStage }) => {
  const [email, setEmail] = useState(true);
  const [system, setSystem] = useState(false);
  const [contact, setContact] = useState(null);
  const [contactsOptions, setContactsOptions] = useState([]);
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const { currentJob } = useAppContextController();

  const updateStageToSubmitted = () => {
    handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.SUBMITTED);
    setOpen(false);
  };

  useEffect(() => {
    if (currentCompany?.contacts) {
      const options = currentCompany?.contacts?.map((item) => {
        return { ...item, label: `${item.firstName} ${item.lastName}` || item.contact };
      });
      setContactsOptions(options);
    } else {
      setContactsOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany?.contacts]);

  useEffect(() => {
    if (!isEmpty(contactsOptions)) {
      setContact(contactsOptions[0]);
    } else setContact(null);
  }, [contactsOptions]);

  const header = (
    <MDBox sx={{ mt: 2, mx: 2, width: "100%" }}>
      <Grid container alignItems="center">
        <Grid item xs={2.8}>
          <MDTypography variant="h5" color="dark">
            Submit Applicant to:
          </MDTypography>
        </Grid>
        <Grid item xs={4.5} justifyContent="flex-start" ml={-1}>
          <Autocomplete
            options={contactsOptions}
            contact="contact"
            autoSelect
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionDisabled={(option) => !option.email && !option.phone}
            value={contact || { label: "" }}
            onChange={(e, v) => {
              setContact(v);
            }}
            renderInput={(params) => (
              <CustomTextField
                type="text"
                label=""
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: "1.25rem",
                    paddingLeft: 5,
                    backgroundColor: "#F0F2F5",
                    //   width,
                  },
                  disableUnderline: true,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {email && (
        <MDTypography variant="body2" color="info" fontWeight="bold">
          {`Contact e-mail: ${contact?.email ? contact?.email : "N/A"}`}
        </MDTypography>
      )}
      {applicant && (
        <MDTypography variant="body2" color="info" fontWeight="bold">
          {`Applicant: ${applicant.firstName} ${applicant.lastName}`}
        </MDTypography>
      )}
    </MDBox>
  );

  const modalBody = (
    <>
      <SendMessage
        recipient={contact}
        applicant={applicant}
        email={email}
        messageContext="Applicant"
        attachmentContext="Applicant Job Submittal"
        setEmail={setEmail}
        system={system}
        setSystem={setSystem}
        isButtonDisabled={email === false}
        setOpen={setOpen}
        noText
        templateIn="Resume Submittal"
        subjectIn={`${currentCompany?.name} - Resume Submission for ${applicant?.firstName} ${applicant?.lastName}`}
        templateSelectionDisabled
        showStatusChangeButton
        onComplete={() => updateStageToSubmitted()}
        forceCustomSubject
        substitutionRecipient={applicant}
        jobId={currentJob?._id}
      />
    </>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      header={header}
      body={modalBody}
      buttons={null}
      sx={(theme) => ({
        width: "90% !important",
        maxWidth: "900px !important",
        [theme.breakpoints.down("md")]: {
          "& .MuiCardContent-root": {
            padding: "0px",
          },
        },
      })}
    />
  );
};

export default SubmittalModal;
