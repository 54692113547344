import axios from "axios";

import { baseAxios } from "config";

const fetchModuleReports = async (module) => {
  try {
    const res = await baseAxios.get(`/reports/module/${module}`);
    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default fetchModuleReports;
