import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';

// import FiltersList from "components/FiltersList";

const ClientEventsFilterCards = ({ filters, setFilters, setPage, initialCards, baseFilters }) => {
  const { setCurrentEvent, currentLoggedUser } = useAppContextController();
  const initialIndex = initialCards.findIndex((card) => card.default);
  const [refreshBadges, setRefreshBadges] = useState(false);

  const { cards } = useBadgeValues({
    initialCards,
    filters: baseFilters ? { ...baseFilters, ...filters } : filters,
    entity: "events",
    refreshBadges,
  });
  const navigate = useNavigate();
  const { eventUrl } = useParams();
  const setEventFilters = (filter) => {
    if (eventUrl) navigate("/clientevents");
    setCurrentEvent(null);
    // if (filter?.eventType || filter?.venueSlug) {
    //   setFilters({ ...filter, timeFrame: !filters.timeFrame ? "Current" : filters.timeFrame });
    // } else
    if (filter?.timeFrame) {
      //      setFilters({ ...filter, eventType: !filters.eventType ? "Event" : filters.eventType });
      setFilters({ ...filter });
    }
    // else {
    //   setFilters({...filter});
    // }
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Events"
        bgColor="primary"
        setFilters={setEventFilters}
        setPage={setPage}
        initialIndex={initialIndex}
      />
    </span>
  );
};

export default ClientEventsFilterCards;
