import { Controller } from "react-hook-form";
import { Grid, Stack, Switch } from "@mui/material";
import MDTypography from "components/MDTypography";

const ShiftFormSwitchController = ({ control, label, name, disabled = false }) => {
  return (
    <Grid item sm={6}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <MDTypography variant="body2" textTransform="capitalize">
          {label}
        </MDTypography>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              disabled={disabled}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </Stack>
    </Grid>
  );
};

export default ShiftFormSwitchController;
