import { createContext, useContext } from "react";

import useNewApplicant from "hooks/useNewApplicant";
import { newApplicantInitialState } from "../../reducers/newApplicantReducer";

export const NewApplicantContext = createContext(newApplicantInitialState);

export const NewApplicantContextProvider = ({ outsideMode = "", children }) => {
  const newApplicantProviderState = useNewApplicant({ outsideMode });

  return (
    <NewApplicantContext.Provider value={newApplicantProviderState}>
      {children}
    </NewApplicantContext.Provider>
  );
};

export function useNewApplicantContext() {
  return useContext(NewApplicantContext);
}
