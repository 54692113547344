const cardStyles = {
  icon: {
    ml: "1.5rem",
    mt: "-1.5rem",
  },
  card: { overflow: "visible!important", p: 0, height: 850 },
  title: { mt: "1rem" },
  container: { height: 750, overflowY: "auto", p: "1.5rem", pt: 0 },
  actions: { mt: -4, pr: 4 },
};

const customPanelCard = (isMobile, backgroundColor) => {
  return {
    iconContainerSx: { backgroundColor, ...cardStyles.icon },
    cardSx: cardStyles.card,
    titleSx: cardStyles.title,
    actionsSx: isMobile ? {} : cardStyles.actions,
  };
};

const dataStyles = {
  font: {
    fontSize: "0.8rem",
  },
  centered: {
    textAlign: "center",
  },
};

export { cardStyles, dataStyles, customPanelCard };
