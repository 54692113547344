import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  venueMediaGallery: { marginTop: "16px", padding: "24px", paddingBottom: "24px", paddingTop: 0 },
  titleBar: { padding: "8px 0px", borderBottom: "2px solid #7b809a66", marginBottom: "16px" },

  // Gallery Section
  titleBar_title: { my: 1, ml: 2 },

  previewButton: {
    padding: 0,
  },

  gallerySection: {
    marginBottom: "24px",
  },

  section_images_wapper: {
    overflow: "visible",
    whiteSpace: "nowrap",
    padding: "0px",
    maxWidth: "100%",
  },
  positioner: {
    position: "absolute",
  },
  section_image_container: {
    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",

  },
  training_section_image_container: {

    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
  },

  section_image: {
    width: "6rem",
    height: "100%",

  },

  training_section_image_delete: {
    position: "relative",
    left: "1000%",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  maps_section_image_delete: {
    position: "absolute",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5
  },
  section_image_delete: {
    position: "absolute",
    right: "-8px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5
  },
  otherSection: {
    marginBottom: "24px",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15
  }
});

export default useStyles;
