import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { AvTimer } from "@mui/icons-material";

const ClockInOutBanner = ({ color, message, button }) => {
    return (
        <MDBox bgColor={color} display="flex" justifyContent="space-between" p={2}>
            <MDBox display="flex" alignItems="center" >
                <AvTimer fontSize="large" color="white" />
                <MDTypography color="white" variant="h5" ml={2}>
                    {message}
                </MDTypography>
            </MDBox>
            {button}

        </MDBox>
    )
}

export default ClockInOutBanner