// import React from "react";
import { Box, Button, Card, Fade, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import moment from "moment";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  message: {
    border: "1px solid black",
  },
});

const ActivitiesMessageModal = ({
  activity,
  messageActivityModalOpen,
  setMessageActivityModalOpen,
}) => {
  const classes = useStyle();
  const { setUnreadNotifications } = useAppContextController();
  const handleClose = () => setMessageActivityModalOpen(false);

  return (
    (activity?.msgId !== undefined || activity?.messageId !== undefined) && (
      <Modal
        open={messageActivityModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={messageActivityModalOpen}>
          <Card className={classes.container}>
            <MDBox p={4}>
              <MDTypography variant="h5">System Message</MDTypography>
              <MDTypography variant="body2" mt={2}>
                <strong>From: </strong>{" "}
                {` ${activity.detail.sender.firstName} ${activity.detail.sender.lastName} ${activity.detail.sender.fromEmail ? `<${activity.detail.sender.fromEmail}>` : ""
                  } `}
              </MDTypography>
              <MDTypography variant="body2" mt={2}>
                <strong> To:</strong>
                {` ${activity.detail.recipient.firstName} ${activity.detail.recipient.lastName} ${activity.detail.recipient.toEmail ? `<${activity.detail.recipient.toEmail}>` : ""
                  } `}
              </MDTypography>
              <MDTypography variant="body2" mt={2}>
                <strong> Send Date: </strong>{" "}
                {moment(activity.activityDate).format("ddd YYYY-MM-DD hh:mm A")}
              </MDTypography>
              <MDTypography variant="body2" my={2}>
                <strong> Subject: </strong> {activity.subject}
              </MDTypography>
              <MDTypography variant="body2" my={2}>
                <strong> Description: </strong> {activity.description}
              </MDTypography>
              {activity.detail?.messageBody && (
                <MDBox className={classes.message} p={2} mb={2}>
                  <Box sx={{ typography: 'body2' }} >{parse(activity.detail.messageBody)}</Box>
                </MDBox>
              )}
              <Button variant="contained" style={{ color: "white" }} onClick={handleClose}>
                Close
              </Button>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    )
  );
};

export default ActivitiesMessageModal;
