import TimelineIcon from "@mui/icons-material/Timeline";
import PanelSelectorBox from "../PanelSelectorBox";
import ProfileActivities from "../ProfileActivities";

const PanelSelectorActivities = ({ user, profilePanel }) => {
  return (
    <PanelSelectorBox
      title={`Activities${user ? `: ${user.firstName} ${user.lastName}` : ""}`}
      icon={<TimelineIcon />}
      iconColor="error"
      cardVariant="outlined"
      dateSelected={user?.modifiedDate}
    >
      <ProfileActivities
        pageSize={10}
        profilePanel={profilePanel}
        currentId={user?.applicantId}
        height={620}
        tableHeight={600}
        cardVariant="none"
        boxShadow
      />
    </PanelSelectorBox>
  );
};

export default PanelSelectorActivities;
