import { Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";

const useStyle = makeStyles({
  text: {
    color: "white",
    textAlign: "center",
  },
});

const SuccessfullyAddedEventModal = ({ open, setOpen }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const classes = useStyle();
  const header = <Box />;

  const buttons = (
    <>
      <Box display="flex" flexDirection="column">
        <Button
          variant="contained"
          color="white"
          sx={{
            color: "blue!important",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Box>
    </>
  );

  const modalBody = (
    <Box>
      <MDTypography variant="h5" className={classes.text}>
        You have successfully added this event. You will receive notification if you are added to the event roster.
      </MDTypography>
    </Box>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        backgroundColor="blue"
        width={isSmallScreen ? "70%" : "30%"}
        buttonLocation="center"
      />
    </>
  );
};

export default SuccessfullyAddedEventModal;
