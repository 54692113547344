import { useAppContextController } from "context/AppContext";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import useSessionAuth from "hooks/useSessionAuth";

export const useRefreshApplicant = () => {
  const { currentApplicant, setCurrentApplicant } = useAppContextController();
  const { logoutAuth0User } = useSessionAuth();

  const refreshApplicant = async () => {
    try {
      const newAppl = await fetchSingleApplicant({ applicantId: currentApplicant?._id });
      if (newAppl?.data) setCurrentApplicant(newAppl.data);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  return { refreshApplicant };
};
