import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import * as yup from "yup";

export const DEFAULT_CUSTOMER = {
  name: "",
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneExt: "",
  },
  clientId: "",
  companyContactMessage: "",
  address: "",
  city: "",
  companyUrl: "",
  description: "",
  locations: [],
  slug: "",
  state: "",
  status: "Active",
  timeClockSettings: {
    approvalReminders: "",
    clockOutReminders: "",
    overtimeWarning: "",
    workWeek: "",
  },
  zip: "",
};

const phoneTest = {
  name: "validate-phone",
  test: (value, ctx) => {
    if (value) {
      const trimValueLength = value.replace(/\D/g, "").length;
      const phoneRegExp =
        /^(?=.*[0-9])((\+\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4})(?:\s?(?:#|x\.?|ext\.?|extension)[\s.-]?\d{4})?$/i;

      if (trimValueLength >= 10 || trimValueLength === 0 || !value) {
        if (trimValueLength === 0) return true;
        if (phoneRegExp.test(value.replace(/\s/g, ""))) return true;
        return ctx.createError({ message: "Invalid phone number" });
      }
      return ctx.createError({ message: "Phone number must be 10 digits" });
    }
    return true;
  },
};

const extensionTest = {
  name: "validate-phoneExt",
  test: (value, ctx) => {
    if (value) {
      const trimValueLength = value.replace(/\D/g, "").length;
      const phoneExtRegExp = /^[0-9]{1,6}$/;
      if (trimValueLength === 0) return true;
      if (phoneExtRegExp.test(value.replace(/\s/g, ""))) return true;
      return ctx.createError({ message: "Phone extension can be up to 6 digits" });
    }
    return true;
  },
};

const emailTest = {
  name: "validate-email",
  test: (value, ctx) => {
    if (value) {
      // Regular expression for validating an email address
      const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (emailRegExp.test(value)) {
        // If the value matches the email regular expression, it is valid
        return true;
      }
      // If the value does not match, return an error
      return ctx.createError({ message: "Invalid email address" });
    }
    // If the value is not provided (empty), consider it valid to allow optional fields
    return true;
  },
};

const nameRegex = /^[a-zA-Z]+(?:[-,'.][a-zA-Z]+)*$/;

const nameTest = {
  name: "validate-phone",
  test: (value, ctx) => {
    if (value) {
      const valueLength = value.replace(/\s/g, "").length;
      if (valueLength === 0) return true;
      if (nameRegex.test(value.replace(/\s/g, ""))) return true;
      return ctx.createError({ message: "Contains invalid characters" });
    }
    return true;
  },
};

export const createCompanyInfoSchema = (companyStatus, isEdit) => {
  const isProspect = companyStatus === "Prospect";

  return yup.object().shape({
    name: yup.string().required("Name is required"),
    contact: yup.object().shape({
      firstName: isProspect
        ? yup.string().nullable().test(nameTest)
        : yup.string().required("Contact first name is required").test(nameTest),

      lastName: isProspect
        ? yup.string().nullable().test(nameTest)
        : yup.string().required("Contact last name is required").test(nameTest),

      // isProspect ?
      email: yup.string().test(emailTest), // : yup.string().required("Contact email is required"),

      phone: yup.string().test(phoneTest),
      phoneExt: yup.string().test(extensionTest),
      // : yup.string().required("Contact phone is required").test(phoneTest),
    }),
    status: yup.string("Status should be string type").default("").nullable(), // Status field is defined in the schema
    address: yup.string().default(""),
    city: yup.string().default(""),
    state: yup.string().default(""),
    zip: yup.string().default(""),
    longitude: yup.string().default(""),
    latitude: yup.string().default(""),
    radius: yup.string().default(""),
    slug: yup
      .string()
      .default("")
      .required("Slug is required")
      .test({
        name: "test-unique-slug",
        test: async (value, ctx) => {
          if (isEdit) {
            return true;
          }
          if (value) {
            const unique = await fetchSingleCustomer({ slug: value });
            if (unique.data) {
              return ctx.createError({ message: "Slug must be unique" });
            }
          }
          return true;
        },
      }),
    clientId: yup.string().default(""),
    companyUrl: yup.string().default(""),
    description: yup.string().default(""),
    companyContactMessage: yup.string().default(""),
    timeClockSettings: yup.object({
      workWeek: yup.string().default("").nullable(),
      overtimeWarning: yup.string().default("").nullable(),
      clockOutReminders: yup.string().default("").nullable(),
      approvalReminders: yup.string().default("").nullable(),
    }),
  });
};
