import axios from "axios";
import { baseAxios } from "config";

const renameFileCompanyJob = async ({ rename, original, jobSlug }) => {
  try {
    const url = new URL(`/upload/rename/${jobSlug}/jobs/other`);
    url.searchParams.append("rename", rename);
    url.searchParams.append("original", original);
    const res = await baseAxios.patch(url.toString());
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default renameFileCompanyJob;
