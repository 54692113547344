import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PreviewIcon from "@mui/icons-material/Preview";

const JobsEventsTableActions = ({
  eventUrl,
  setNavigateUrl,
  row,
  setActionSelected,
  setFilters,
  setOpen,
  setEventPreview,
  setCurrentEvent,
}) => {
  const onClickHandler = (action, e) => {
    e.stopPropagation();

    const nodes = new URL(window.location).pathname.split("/");
    const baseRoute =
      nodes?.length >= 4 && nodes[2] === "venue"
        ? `/events/venue/${row?.venueSlug}/${row?.eventUrl}`
        : `/events/${row?.eventUrl}`;

    // alert(baseRoute)
    // we need to get rid of timeFrame in the filters, because user has selected
    //    and event which could be in the past
    // if (row?.eventUrl) setFilters(prev => {
    //     const newFilters = { ...prev };
    //     // if (newFilters.timeFrame) delete newFilters.timeFrame;
    //     return { ...newFilters, eventUrl: row.eventUrl }
    // })
    setCurrentEvent(row);
    setNavigateUrl(`${baseRoute}/action/${action}`);
  };

  const setPreviewOpen = (event, e) => {
    e.stopPropagation();
    setEventPreview(event);
    setOpen(true);
  };
  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("eventsinfo", e)}
        tooltip="Interview Info"
      />
      &nbsp;&nbsp;
      <TableAction
        color="error"
        icon="people"
        clickAction={(e) => onClickHandler("eventsroster", e)}
        tooltip="Interview Roster"
      />
      &nbsp;&nbsp;
    </span>
  );
};

export default JobsEventsTableActions;

JobsEventsTableActions.defaultProps = {
  eventUrl: "",
};

// Typechecking props for the TableAction
JobsEventsTableActions.propTypes = {
  eventUrl: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
