import { Icon } from "@mui/material";


const GetIcons = ({ category }) => {

    switch (category) {
        case "Dashboard":
            return <Icon fontSize="large">dashboard</Icon>
        case "Venues":
            return <Icon fontSize="large" color="info">stadium</Icon>
        case "Jobs":
            return <Icon fontSize="large" color="warning">work</Icon>
        case "Events-Rosters":
            return <Icon fontSize="large" color="success">event-seat</Icon>
        case "Applicants-Employees":
            return <Icon fontSize="large" color="error">account_circle</Icon>
        case "MyProfile":
            return <Icon fontSize="large" color="primary">account_box_icon</Icon>
        case "Reports":
            return <Icon fontSize="large" color="success">assessment_icon</Icon>
        case "Other":
            return <Icon fontSize="large" color="warning">miscellaneous_services_icon</Icon>
        default:
            return <Icon fontSize="large" color="warning">miscellaneous_services_icon</Icon>

    }
}
export default GetIcons
