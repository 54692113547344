import { Autocomplete, Box, Grid, Stack, Switch } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import CustomCard from "components/CustomCard";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import updateCompany from "api/companies/updateCompany";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import useCompanyStore from "stores/useCompanyStore";
import PanelActions from "components/PanelActions";
import { AutoAwesome } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import CustomTextField from "components/Form/CustomTextField";
import useDropdowns from "hooks/useDropdowns";
import { getDirtyFields } from "utils/helpers/formHelpers";
import { DEFAULT_CUSTOMER } from "data/companyInfo";

const dropdownOptionsVenues = [
  {
    label: "None",
    value: "",
  },
  {
    label: "Venues",
    value: "venues",
  },
  {
    label: "Venues and Jobs",
    value: "venues,jobs",
  },
];

const dropdownOptionsCustomers = [
  {
    label: "None",
    value: "",
  },
  {
    label: "Customers",
    value: "customers",
  },
  {
    label: "Customers and Jobs",
    value: "customers,jobs",
  },
];

const CompanyAISettingsForm = () => {
  const { setCompanyFormState, companyFormState, setCurrentCompany, currentCompany } =
    useCompanyStore((state) => ({
      setCompanyFormState: state.setCompanyFormState,
      companyFormState: state.companyFormState,
      setCurrentCompany: state.setCurrentCompany,
      currentCompany: state.currentCompany,
    }));

  const { company, setCompany, companyType, isMobile } = useAppContextController();
  const isCompany = companyType !== "Venue";

  const isEdit = !!currentCompany?._id;

  const aiEnableDefaultOptions = isCompany ? dropdownOptionsCustomers : dropdownOptionsVenues;

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    setError,
    trigger,
    formState,
  } = useForm();
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful, dirtyFields } = formState;

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const updateCompanyHandler = (data) => {
    updateCompanyMutation(
      {
        companyId: currentCompany?._id,
        body: {
          ...getDirtyFields(data, dirtyFields),
        },
      },
      {
        onSuccess: () => {
          setCurrentCompany({ ...currentCompany, ...data });
          if (currentCompany?.primaryCompany) {
            setCompany({ ...company, ...data });
          }
          enqueueSnackbar("Company has been updated.", { variant: "success" });
          reset(data, { keepValues: true });
        },
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(currentCompany)) {
      delete currentCompany?.longitude;
      delete currentCompany?.latitude;
      delete currentCompany?.radius;
    }

    reset(isEdit ? currentCompany : DEFAULT_CUSTOMER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, isEdit]);

  useEffect(() => {
    setCompanyFormState({ ...companyFormState, isDirty, dirtyFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields]);

  const handleCancelChanges = () => reset(currentCompany);

  return (
    <form
      onSubmit={handleSubmit(updateCompanyHandler)}
      id="company-setting-form"
      style={{ width: "100%" }}
    >
      <CustomCard
        icon={<AutoAwesome color="white" />}
        iconContainerSx={{ backgroundColor: "violet", mx: "1.5rem", mt: "-1.5rem" }}
        cardSx={{ width: "100%", overflow: "visible !important", p: 0, height: 850 }}
        cardActions={
          isDirty && !isEmpty(dirtyFields) && <PanelActions onCancel={handleCancelChanges} />
        }
        cardTitle="Company AI Settings"
        actionsSx={isMobile ? {} : { mt: -4, mr: 4 }}
      >
        <Box sx={{ height: 750, overflowY: "auto", p: "1.5rem", pt: 1 }}>
          <FlexWrapper justifyContent="space-between">
            <Grid container alignItems="center" spacing={3} pt={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                      AI Configurations
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">AI Interviews Enabled</MDTypography>
                      <Controller
                        name="aiSettings.aiInterviewsEnabled"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            color="info"
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">{`Auto-generate ${
                        isCompany ? "Customer" : "Venue"
                      } Questions`}</MDTypography>
                      <Controller
                        name="aiSettings.autoGenerateCustomerQuestions"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="aiSettings.aiInterviewsEnabledByDefault"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={aiEnableDefaultOptions || []}
                          name="aiSettings.aiInterviewsEnabledByDefault"
                          autoSelect
                          getOptionLabel={(option) => option.label || option}
                          value={
                            field.value != null
                              ? aiEnableDefaultOptions?.find(
                                  (option) => option.value === field.value
                                )
                              : aiEnableDefaultOptions[0]
                          }
                          isOptionEqualToValue={(option, value) => option.value === value}
                          onChange={(e, v) => {
                            setValue("aiSettings.aiInterviewsEnabledByDefault", v.value, {
                              shouldDirty: true,
                            });
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              type="text"
                              label="AI Interviews Enabled by Default"
                              showError
                              errorMessage={error?.message}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FlexWrapper>
        </Box>
      </CustomCard>
    </form>
  );
};

export default CompanyAISettingsForm;
