import { useMutation, useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";
import GenericModal from "components/GenericModal";
import { useSnackbar } from "notistack";
import deleteAuth0User from "layouts/pages/profile/actions/deleteAuth0User";
import createAuth0User from "layouts/pages/profile/actions/createAuth0User";

const Auth0UserConfirmModal = ({ user, setAuth0Status, auth0Status, modalOpen, setModalOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteAuth0UserMutation = useMutation(deleteAuth0User, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async () => {
      enqueueSnackbar("User has been deleted from auth0", { variant: "success" });
      setAuth0Status(false);
    },
  });

  const createAuth0UserMutation = useMutation(createAuth0User, {
    onError: (error) => {
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    },
    onSuccess: async (_, data) => {
      enqueueSnackbar("Auth0 account created successfully for user!", { variant: "success" });
      setAuth0Status(true);
    },
  });

  const handleDelete = async () => {
    await deleteAuth0UserMutation.mutateAsync({
      id: user?._id,
    });
  };
  const handleCreate = async () => {
    await createAuth0UserMutation.mutateAsync({
      id: user?._id,
    });
  };

  const modalBody = (
    <Grid container>
      <Typography variant="body2">
        {auth0Status
          ? "Do you want to delete this user from Auth0?"
          : "Do you want to create an Auth0 account for this user?"}
      </Typography>
    </Grid>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        sx={{ padding: "0.5rem 1rem" }}
        onClick={() => {
          if (auth0Status) {
            handleDelete();
          } else {
            handleCreate();
          }
          setModalOpen(false);
        }}
      >
        Yes
      </Button>
      <Button
        variant="contained"
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem" }}
        onClick={() => {
          setModalOpen(false);
        }}
      >
        No
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={modalOpen}
        setOpen={setModalOpen}
        header={auth0Status ? "Confirm Delete Action" : "Confirm Create Action"}
        body={modalBody}
        buttons={modalButtons}
        width="45%"
      />
    </>
  );
};

export default Auth0UserConfirmModal;
