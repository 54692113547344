import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import useBadgeValues from "hooks/useBadgeValues";

// import ToggleOffIcon from '@mui/icons-material/ToggleOff';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PublicIcon from '@mui/icons-material/Public';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import StadiumIcon from '@mui/icons-material/Stadium';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BugReportIcon from '@mui/icons-material/BugReport';
import DoneIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/Pending';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

export const initialCards = [
  {
    color: "info",
    icon: <PendingIcon fontSize="large" color="info" />,
    title: "Open",
    tooltip: "Open Issues",
    number: 0,
    filterField: "status",
    filterValue: "Open",
    canCombine: true,
    default: true
  },
  {
    color: "success",
    icon: <DoneIcon fontSize="large" color="success" />,
    title: "Closed",
    tooltip: "Closed Issues",
    number: 0,
    filterField: "status",
    filterValue: "Closed"
  },
  {
    color: "error",
    icon: <BugReportIcon fontSize="large" color="error" />,
    title: "Bugs",
    tooltip: "Issues that are Bugs",
    number: 0,
    filterField: "type",
    filterValue: "Bug",
    canCombine: true,
  },
  {
    color: "warning",
    icon: <QuestionMarkIcon fontSize="large" color="warning" />,
    title: "Questions",
    tooltip: "Question Issues",
    number: 0,
    filterField: "type",
    filterValue: "Question",
    canCombine: true,
  },
  {
    color: "info",
    icon: <EnhancedEncryptionIcon fontSize="large" color="info" />,
    title: "Enhance",
    tooltip: "Enhancement Requests",
    number: 0,
    filterField: "type",
    filterValue: "Enhancement",
    canCombine: true,
  },
  {
    color: "dark",
    icon: <PublicIcon fontSize="large" color="black" />,
    title: "All",
    tooltip: "All Issues",
    number: 0,
    filterField: "status",
    filterValue: "",
  },
];

const IssuesFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentIssue, currentLoggedUser } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const navigate = useNavigate();

  const { cards } = useBadgeValues({ initialCards, filters, entity: "issues", refreshBadges })

  const setIssueFilters = (filter) => {
    navigate("/issues")
    setCurrentIssue(null);
    setFilters(filter);
    setRefreshBadges(prev => !prev);
  }

  const initialCardIndex = initialCards.findIndex(card => card.default)

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setIssueFilters}
        setPage={setPage}
        initialIndex={initialCardIndex}
      />
    </span>
  )
}

export default IssuesFilterCards;
