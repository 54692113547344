import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const CheckExWidget = ({ yes, updateValue, id }) => {
  return yes ? (
    <CheckIcon
      key={`checked_${id}`}
      color="success"
      fontSize="large"
      onClick={() => updateValue(false, id)}
    />
  ) : (
    <CancelIcon
      key={`unchecked_${id}`}
      color="error"
      fontSize="large"
      onClick={() => updateValue(true, id)}
    />
  );
};

export default CheckExWidget;
