import * as yup from "yup";

export const georgiaStateFormSchema = yup.object().shape({
  filingStatus: yup.string().required("Filing status is required"),
  dependentAllowances: yup.number(),
  additionalWithholding: yup.number(),
  federalEstimatedDeductions: yup.number(),
  georgiaStandardDeduction: yup.number(),
  allowableDeductionsToFederalAdjustedGrossIncome: yup.number(),
  estimateOfTaxableIncomeNoWithholding: yup.number(),
});
