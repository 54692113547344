import axios from "axios";

import { baseAxios } from "config";

const deletePartnerContactUser = async ({ id, contactId }) => {
  try {
    const res = await baseAxios.post(`/partners/${id}/contact/${contactId}/deleteuser`, {});
    return res.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message ?? err.toString());
  }
};

export default deletePartnerContactUser;
