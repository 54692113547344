import { Grid, Stack, IconButton, Icon } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DataList from "components/DataList";
import EventDate from "components/EventDate";
import EventStatusButton from "components/EventStatusButton";
import FilterButtons from "components/FilterButtons";
import { APPLICANT_EVENTS_OPTIONS } from "components/FilterButtons/options";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { Info as InfoIcon } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { getApplicantEventStatus, getPartnerEventStatus } from "utils/helpers/applicant";
import { isAdmin } from "utils/helpers/roles";
import moment from "moment";
import fetchCustomers from "api/companies/fetchCustomers";

const PartnerEvents = ({
    fetchAll,
    setActionSelected = "info",
    title = "Title",
    filters,
    setFilters,
}) => {
    const [dataFilter, setDataFilter] = useState({ "partners.status": "All" });

    const [customerData, setCustomerData] = useState({});
    const { currentPartner, setCurrentPartner, venues, userType, company, companies } =
        useAppContextController();
    const isCompany = company?.companyType === "Company";
    const navigate = useNavigate();
    const [partnerVenues, setPartnerVenues] = useState(
        currentPartner?.venues ? currentPartner.venues.map((item) => item.venueSlug).join(";") : ""
    );

    const allOptions = useMemo(() => {
        const result = {
          fetchAll,
          filters: isCompany
            ? {
                ...filters,
                eventUrl: currentPartner?.interviews?.map((applInt) => applInt.eventUrl).join(";"),
              }
            : {
                ...filters,
                venueSlug: partnerVenues,
                "partners.id": currentPartner?._id,
                timeFrame: "Current",
              },
          page: 1,
          limit: 200,
        };
    
        if (!isCompany && dataFilter?.["partners.status"]) {
          if (dataFilter?.["partners.status"] !== "All") {
            result.filters = { ...result.filters, ...dataFilter };
          } else {
            delete result.filters?.["partners.status"];
          }
        }
    
        return result;
      }, [
        partnerVenues,
        currentPartner?._id,
        currentPartner?.interviews,
        dataFilter,
        fetchAll,
        filters,
        isCompany,
      ]);

    const {
        data: events,
        isLoading,
        refetch,
    } = useQuery(["partnerevents", allOptions], () => fetchEvents(allOptions), {
        onSuccess: async (data) => {
            if (isCompany) {
                const customers = {};

                try {
                    // Get all company slugs
                    let companySlugs = data?.data?.map((comp) => comp.companySlug);
                    // Remove duplicates
                    companySlugs = [...new Set(companySlugs)];

                    const results = await fetchCustomers({
                        filters: { slug: companySlugs.join(";") },
                        page: 1,
                        limit: 0,
                    });

                    (results?.data ?? []).forEach((cstmr) => {
                        customers[cstmr.slug] = cstmr;
                    });
                    setCustomerData(customers);
                } catch (error) {
                    // Handle errors
                    console.log("error", error);
                }
            }
        },
        staleTime: 0,
        cacheTime: 0,
    });

    useEffect(() => {
        if (currentPartner) refetch();
    }, [currentPartner]);

    const { mutate: changeEventStatusMutation } = useMutation(updateEvent, {
        onSuccess: async (_, { data }) => setCurrentPartner({ ...currentPartner, ...data }),
        mutationKey: "eventStatusMutation",
    });

    useEffect(() => {
        if (currentPartner?.venues) {
            setPartnerVenues(currentPartner.venues.map((item) => item.venueSlug).join(";"));
        } else setPartnerVenues("");
    }, [currentPartner]);

    const renderRow = (row) => {
        const eventStatus = getPartnerEventStatus(currentPartner, row);
        return isCompany ? (
            <>
                <Grid
                    container
                    alignItems="center"
                    key={row._id}
                    onClick={() => {
                        navigate(`/events/${row?.eventUrl}/action/eventsinfo`);
                    }}
                >
                    <Grid item xs={12} sm={2}>
                        <CompanyLogoSquare
                            company={customerData[row.companySlug] ? customerData[row.companySlug] : {} || row}
                            defaultCompany={company}
                            parent="MyInterviews"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {customerData[row.companySlug]?.name || row?.companySlug?.toUpperCase()}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {moment(row?.eventDate).format("dddd, MMM Do, YYYY, hh:mm A")}
                    </Grid>
                    <Grid item xs={12} sm={3} textAlign="end">
                        <InfoIcon fontSize="large" color="info" />
                    </Grid>
                </Grid>
            </>
        ) : (
            <>
                <MDBox
                    key={`${row.eventName}_${row.eventUrl}`}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} sm={2} lg={1.5}>
                        <VenueIcon
                            logoUrl={venues[row.venueSlug]?.logoUrl}
                            slug={row.venueSlug}
                            name={row.venueName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8.5}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                    {row.eventName}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} display="flex">
                                <MDTypography variant="body2">
                                    {row.venueCity}, {row.venueState}
                                </MDTypography>
                                &nbsp;
                                <MDTypography variant="body2">
                                    - <EventDate date={row?.eventDate} reportTimeTBD={row?.reportTimeTBD} />
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                        <Stack direction="row" justifyContent="right">
                            <EventStatusButton status={eventStatus} event={row} onUpdate={() => refetch()} isPartner />
                            <IconButton
                                onClick={() => {
                                    navigate(`/events/${row?.eventUrl}/action/eventsinfo`);
                                }}
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        </Stack>
                    </Grid>
                </MDBox>
            </>
        );
    };

    const header = isCompany ? (
        <></>
    ) : (
        <FlexWrapper p={2} justifyContent="flex-end">
            {isAdmin(userType) && (
                <FilterButtons
                    filter={dataFilter}
                    setFilter={setDataFilter}
                    name="partners.status"
                    options={APPLICANT_EVENTS_OPTIONS}
                />
            )}
        </FlexWrapper>
    );

    return (
        <DataList
            renderRow={renderRow}
            data={events?.data}
            icon="event-seat"
            iconColor="success"
            title={company?.companyType === "Venue" ? "Events" : "Interviews"}
            isLoading={isLoading}
            greybar
            divider
            header={header}
            tableHeight={750}
            height={850}
            scrollY
            computedHeight
        />
    );
};

export default PartnerEvents;
