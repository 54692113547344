import { useAppContextController } from "context/AppContext";

import { AttachFile } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import { cardStyles as styles } from "layouts/pages/customers/styles";
import UploadFileModal from "../FileUploadDropzone";
import Attachments from "../CompanyJobAttachment";

const CompanyJobsAttachments = () => {
  const { currentJob } = useAppContextController();

  return (
    <CustomCard
      icon={<AttachFile color="white" />}
      iconContainerSx={{ backgroundColor: "info", ...styles.icon }}
      cardTitle={`Job Attachments: ${currentJob?.title}`}
      cardSx={styles.card}
      titleSx={styles.title}
    >
      <Box sx={styles.container}>
        {/* <PositionsDisplay /> */}
        <Box mb={0}>
          {/* <MDTypography component="label" variant="body2">
            Header Media
          </MDTypography> */}

          <Grid item xs={12} mt={3}>
            <UploadFileModal typeOfUploader="jobs" />
          </Grid>
          <MDBox mt={2}>
            <Attachments />
          </MDBox>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default CompanyJobsAttachments;
