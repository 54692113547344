import axios from "axios";

import { baseAxios } from "config";

const fetchInterviewFeedback = async ({ applicantId, eventUrl }) => {
  try {
    if (!applicantId || !eventUrl) return null;
    const res = await baseAxios.get(`/applicants/id/${applicantId}/eventUrl/${eventUrl}/feedback`);

    return res?.data?.results;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchInterviewFeedback;
