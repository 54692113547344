import axios from "axios";

import { baseAxios } from "config";

const fetchJobsByZip = async ({ zip, company }) => {
  try {
    const res = await baseAxios.get(
      `/outside-public/jobs/location/zipcode/${zip}${company ? "?company=company" : ""}`
    );
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchJobsByZip;
