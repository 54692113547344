import { Box, Button, Modal, Fade, Card, Grid, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppContextController } from "context/AppContext";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    padding: "1rem",
    maxHeight: "80%",
    maxWidth: "80%",
  },
  dropzone: {
    height: 170,
  },
});

const ImagePreviewModal = ({
  imagePreviewOpen,
  setImagePreviewOpen,
  currentEvent,
  previewFile,
  typeOfPreview,
  currentIssue
}) => {
  const classes = useStyle();
  const handleClose = () => setImagePreviewOpen(false);

  const { company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;

  let imageLink;

  if (typeOfPreview === "company") {
    imageLink = `${IMAGE_SERVER}/${currentEvent?.slug}/company/other/${previewFile?.filename}`;
  } else if (typeOfPreview === "companyjobs") {
    imageLink = `${IMAGE_SERVER}/${currentEvent?.jobSlug}/jobs/other/${previewFile?.filename}`;
  } else if (typeOfPreview === "issue") {

    imageLink = `${IMAGE_SERVER}/issues/${currentIssue.category}/${currentIssue._id}/${previewFile?.filename}`;
  }
  else {
    imageLink = `${IMAGE_SERVER}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${previewFile?.name}`;
  }

  return (
    <Modal
      open={imagePreviewOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={imagePreviewOpen}>
        <Card className={classes.container}>
          {IMAGE_SERVER && <img src={imageLink} alt="previews" />}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="text"
              onClick={() => {
                window.open(imageLink);
              }}
            >
              Open in another tab
            </Button>
            <Button
              variant="contained"
              style={{ color: "white" }}
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </Button>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};

export default ImagePreviewModal;
