const cardStyles = {
  icon: {
    ml: "1.5rem",
    mt: "0.6rem",
    "&": {
      transition: "0.2s",
    },
    "&:hover": {
      background: "#D3D3D3",
    },
  },
  card: { overflow: "visible!important", p: 0, mt: "20px", height: 850 },
  title: { mt: "1rem", alignSelf: "start" },
  container: { height: 780, overflowY: "auto", p: "1.5rem", pt: 0 },
  actions: { mt: -6, pr: 4 },
};

const panelStyles = {
  container: { height: 615, overflowY: "auto", p: 0, pt: 1 },
};

const customPanelCard = (isMobile, backgroundColor) => {
  return {
    iconContainerSx: { backgroundColor, ...cardStyles.icon },
    cardSx: cardStyles.card,
    titleSx: cardStyles.title,
    actionsSx: isMobile ? {} : cardStyles.actions,
  };
};

const dataStyles = {
  font: {
    fontSize: "0.8rem",
  },
};

export { cardStyles, panelStyles, dataStyles, customPanelCard };
