import axios from "axios";

import { baseAxios } from "config";

const fetchJobsByCoords = async ({ lat, long, radius = 160000 }) => {
  try {
    const res = await baseAxios.get(`/outside-public/jobs/location/geo/${long}/${lat}`, {
      params: {
        radius,
      },
    });
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchJobsByCoords;
