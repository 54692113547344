import { useEffect } from "react";

import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import MDTypography from "components/MDTypography";

import { makeStyles } from "@mui/styles";

import { Checkbox } from "@material-ui/core";
import MDBox from "components/MDBox";
import moment from "moment";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import colors from "assets/theme/base/colors";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const useStyle = makeStyles({
  tableStriped: {
    "& tr": {
      "&:nth-of-type(odd)": { border: 0, background: "rgba(0, 0, 0, 0.04)" },
    },
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const DataTable = ({
  data: { data, count },
  selected,
  columns,
  onRowClick,
  page,
  setPage,
  setLimit,
  limit,
  noSort,
  order,
  orderBy,
  toggleSort,
  fetchAll,
  isLoading,
  greybar,
  defaultSort,
  divider,
  idField,
  moreInfo,
  handleMoreInfo,
}) => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
  const pageCount = fetchAll ? Math.ceil(count / limit) : count;

  const classes = useStyle();
  const allData = fetchAll
    ? stableSort(data || [], getComparator(order, orderBy)).slice((page - 1) * limit, page * limit)
    : stableSort(data || [], getComparator(order, orderBy));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (defaultSort !== orderBy) toggleSort(defaultSort);
  }, [defaultSort]);

  const tableRow = (row, i) => {
    const firstField = idField || Object.keys(row)?.[0];
    const firstProp = `prop2_${i}_${row[firstField]?.toString()}`;
    // const index = i;
    const key = `row_${firstProp}_${i}`;

    return (
      <TableRow
        key={key}
        className={divider ? classes.tableRowBordered : classes.tableRow}
        data-collapse={selected?.slug === row.slug}
        style={{ backgroundColor: row.selectedRow && "#add8e6" }}
        onClick={(e) => onRowClick(row, e)}
      >
        {columns.map((column) => {
          if (column.type === "image") {
            return (
              <TableCell
                key={`${column.title}_${row[firstProp]}_${row[column.field]}`}
                onClick={column.onClick}
              >
                <img
                  width={column.imageWidth || "50"}
                  height={column.imageHeight || "50"}
                  // eslint-disable-next-line
                  src={row[column.field] || ""}
                  alt={column.title}
                />
              </TableCell>
            );
          }
          if (column.type === "icon") {
            return (
              <TableCell
                key={`${column.title}_${row[firstProp]}_${row[column.field]}`}
                onClick={column.onClick}
              >
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor={column.bgcolor}
                  color="white"
                  borderRadius="xl"
                >
                  <Icon fontSize="large">{row[column.field] || ""}</Icon>
                </MDBox>

                {/* <img
                  width={column.imageWidth || "50"}
                  height={column.imageHeight || "50"}
                  // eslint-disable-next-line
                  src={row[column.field] || ""}
                  alt={column.title}
                /> */}
              </TableCell>
            );
          }
          if (column.type === "date") {
            return (
              <TableCell
                key={`${column.title}_${row[firstProp]}_${row[column.field]}`}
                onClick={column.onClick}
              >
                {moment(row[column.field]).format("MM/DD/YYYY")}
              </TableCell>
            );
          }
          if (column.type === "selected") {
            return (
              <TableCell
                key={`${column.title}_${row[firstProp]}_${row[column.field]}`}
                onClick={column.onClick}
              >
                <Checkbox checked={row[column.field]} />
              </TableCell>
            );
          }
          if (column.type === "button" && column.field === "actions") {
            return (
              <TableCell
                key={`${column.title}_${row[firstProp]}_${row[column.field]}`}
                onClick={column.onClick}
              >
                <Button
                  variant="outline"
                  sx={{ padding: 0, color: colors.blue.main }}
                  style={{ backgroundColor: "transparent" }}
                  endIcon={!moreInfo && <ArrowRight fontSize="18px" />}
                  startIcon={moreInfo && <ArrowLeft fontSize="18px" />}
                  onClick={() => {
                    handleMoreInfo();
                  }}
                >
                  {moreInfo ? "COLLAPSE" : "EXPAND"}
                </Button>
              </TableCell>
            );
          }
          return (
            <TableCell
              key={`${column.title}_${row[firstProp]}_${row[column.field]}`}
              sx={{ ...(column?.width && { width: column?.width }) }}
              onClick={column.onClick}
            >
              {column.customCell
                ? column.customCell(row[column.field], column.field, row)
                : row[column.field]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.down("desktop")]: {
            display: "block",
            overflowX: "auto",
            whiteSpace: "nowrap",
          },
        })}
      >
        <MDBox component="thead">
          <TableRow
            key="heading_row"
            sx={({ typography: { size, fontWeightBold } }) => ({
              td: {
                fontSize: size.xs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
              },
            })}
          >
            {columns.map((column, j) => (
              <TableCell
                key={`${column.title}_${column.field}`}
                sortDirection={orderBy === column.field ? order : false}
                onClick={() => {
                  setPage(1);
                }}
                sx={{ ...(column?.width && { width: column?.width }) }}
              >
                {!column.noSort && column.type !== "image" ? (
                  <TableSortLabel
                    active={orderBy === column.field}
                    direction={orderBy === column.field ? order : "asc"}
                    onClick={() => toggleSort(column.field)}
                  >
                    {column.title}
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </MDBox>
        {isLoading && (
          <TableBody>
            {Array(limit)
              .fill(nanoid())
              .map((key, j) => {
                const val = j * 4;
                return (
                  <TableRow key={`${key}_${val}`}>
                    {columns.map((column) => (
                      <TableCell key={`cell_${column.field}_${column.title}`}>
                        <Skeleton
                          key={`skel_${column.field}_${column.title}`}
                          variant="rectangular"
                          height={20}
                          sx={{ margin: "5px 0px" }}
                          {...(column.type === "image" ? { height: 50, width: 50 } : {})}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        )}
        {!isLoading && (
          <TableBody className={greybar && classes.tableStriped}>
            {allData.length > 0 ? (
              allData.map(tableRow)
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <MDBox sx={{ p: 2 }}>
                    <h4>No data found!</h4>
                  </MDBox>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {count * limit > limit && ( // TODO: change this back to not show pagination if filter is set -- or if there are fewer than 5 rows
        <MDBox display="flex" width="100%" justifyContent="center" position="relative" mt={4}>
          <Stack
            spacing={2}
            sx={(theme) => ({
              [theme.breakpoints.down("tablet")]: { marginTop: "50px" },
            })}
          >
            <Pagination
              color="info"
              size={isTablet ? "small" : "medium"}
              count={pageCount}
              page={page}
              onChange={handleChangePage}
              siblingCount={1}
              sx={{ ml: isTablet ? -5 : "" }}
            />
          </Stack>
          <MDBox
            display="flex"
            justifyContent="space-between"
            mt={2}
            alignItems="center"
            sx={(theme) => ({
              position: "absolute",
              right: "30px",
              bottom: "0",
              marginTop: "50px",
              [theme.breakpoints.down("tablet")]: { bottom: "50px" },
            })}
          >
            <MDTypography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
              })}
            >
              View
            </MDTypography>
            <FormControl sx={{ margin: "0px 6px" }}>
              <Select
                value={limit}
                onChange={(e) => {
                  setPage(1);
                  setLimit(e.target.value);
                }}
                sx={{ padding: "6px 0px", width: "60px" }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <MDTypography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
              })}
            >
              Per page
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

DataTable.defaultProps = {
  data: { data: [], count: 0 },
  selected: null,
  columns: [],
  page: 0,
  limit: 0,
  noSort: false,
  order: "asc",
  orderBy: "",
  fetchAll: false,
  greybar: true,
  divider: false,
  toggleSort: () => {},
  setLimit: () => {},
  setPage: () => {},
  onRowClick: () => {},
};

DataTable.propTypes = {
  // data: PropTypes.shape({
  //   data: PropTypes.arrayOf(PropTypes.object),
  //   count: PropTypes.number,
  // }),
  selected: PropTypes.object,
  data: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  page: PropTypes.number,
  limit: PropTypes.number,
  noSort: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  toggleSort: PropTypes.func,
  fetchAll: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  greybar: PropTypes.bool,
  divider: PropTypes.bool,
};

export default DataTable;
