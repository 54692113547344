import { useState, useRef, useEffect } from "react";
// import moment from "moment-timezone";
import { TextField } from "@mui/material";

const MS_PER_MINUTE = 60000;

const EditTime = ({
  eventDate,
  mode,
  setMode,
  time,
  setTime,
  field,
  timeZone,
  setEdit,
  setManualTime,
}) => {
  const fieldRef = useRef(null);
  const displayFromTime = (t) => {
    if (!t) return "";
    const offset = new Date(t).getTimezoneOffset();
    const tm = new Date(new Date(t).getTime() - offset * MS_PER_MINUTE).toISOString().split("T")[1];
    const hhmm = tm.split(".")[0].substring(0, 5);
    const hrs = hhmm.substring(0, 2);
    let mns = hhmm.substring(3, 5);
    const ampm = +hrs > 11 ? "pm" : "am";

    let displayHours = +hrs > 12 ? +hrs - 12 : hrs;
    if (displayHours.length < 2) displayHours = `0${displayHours?.toString()}`;
    if (!mns || mns === "0") mns = "00";
    else if (mns.length < 2) mns = `0${mns?.toString()}`;
    return `${displayHours}:${mns} ${ampm}`;
  };
  const [displayTime, setDisplayTime] = useState(displayFromTime(time));

  // useEffect(() => {
  //   if (time) {
  //     // alert("setting displaytime");
  //     setDisplayTime(displayFromTime(time));
  //   }
  // }, [time]);

  useEffect(() => {
    // programmatically select when rendered
    fieldRef.current.select();
  }, []);

  const validateHhMm = (inputField) => {
    const isValid = /^([01]?[0-9]|2[0-3]):?[0-5][0-9]\s?(AM|PM|A|P)?$/i.test(inputField);
    return isValid;
  };

  const validHhAmPm = (inputField) => {
    const isValid = /^([01]?[0-9]|2[0-3])\s?(AM|PM|A|P)?$/i.test(inputField);
    return isValid;
  };

  const parseTime = (timeEntered) => {
    let tm = timeEntered.toString();

    if (!tm) return { hours: null, mins: null };

    if (!validateHhMm(tm) && !validHhAmPm(tm)) {
      return { hours: null, mins: null };
    }


    const pm = tm.toLowerCase().indexOf("pm") > -1 || tm.toLowerCase().indexOf("p") > -1;

    tm = tm
      .toLowerCase()
      .replace(/ /g, "")
      .replace("am", "")
      .replace("pm", "")
      .replace("a", "")
      .replace("p", "");

    if (tm.indexOf(":") === -1) {
      if (tm.length === 1 || tm.length === 2) return { hours: +tm, mins: 0 };
      if (![3, 4].includes(tm.length)) return { hours: null, mins: null };
      if (tm.length === 3) tm = `${tm.substring(0, 1)}:${tm.substring(1, 3)}`;
      else if (tm.length === 4) tm = `${tm.substring(0, 2)}:${tm.substring(2, 4)}`;
      if (!validateHhMm(tm)) return { hours: null, mins: null };
    }
    const hr = +tm.split(":")[0];
    const add12 = pm && hr < 12;
    let hours = hr;
    if (add12) hours += 12;
    //    const hours = hr + shouldBeAm ? 0 : 12;
    const mins = +tm.split(":")[1] || 0;

    return { hours, mins };
  };

  const handleBlur = (e) => {
    const tm = e.target.value;

    const { hours, mins } = parseTime(tm);

    const hrs = hours < 10 ? `0${hours?.toString()}` : hours?.toString();
    const mns = mins < 10 ? `0${mins?.toString()}` : mins?.toString();
    // alert(`${eventDate}T${hrs}:${mns}:00.000Z`);
    let newTime = new Date(`${eventDate}T${hrs}:${mns}:00.000Z`);
    const offset = newTime.getTimezoneOffset();

    newTime = new Date(newTime.getTime() + offset * MS_PER_MINUTE);

    setTime(newTime);
    setDisplayTime(displayFromTime(newTime));
    setManualTime(newTime);
    setEdit(false);
    setMode("display");
  };

  return (
    <>
      <TextField
        id="edit-time-basic"
        label={`Edit ${field}`}
        variant="outlined"
        inputRef={fieldRef}
        value={displayTime}
        onChange={(e) => setDisplayTime(e.target.value)}
        onBlur={handleBlur}
      />
    </>
  );
};

// if (time !== null && mode === "undoOrEdit") {
//   return <span key={`${field}_id_${id}`} style={{ minWidth: 1200 }}></span>;
// }
export default EditTime;
