import { CallMerge, CancelRounded, Check, DoDisturbAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useState } from "react";
import { APPLICANT_MERGE_STATUS_ENUM } from "utils/constants/applicant";
import MergeStatusIcon from "./MergeStatusIcon";

const ApplicantMergeStatus = ({ status, viewOnly = false, onUpdate, handleUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const options = [
    {
      text: APPLICANT_MERGE_STATUS_ENUM.PRIMARY,
      icon: <Check fontSize="medium" color="success" />,
      handleClick: () => handleUpdate(APPLICANT_MERGE_STATUS_ENUM.PRIMARY),
      disabled: APPLICANT_MERGE_STATUS_ENUM.PRIMARY === status,
    },
    {
      text: APPLICANT_MERGE_STATUS_ENUM.MERGE,
      icon: <CallMerge fontSize="medium" color="info" />,
      handleClick: () => handleUpdate(APPLICANT_MERGE_STATUS_ENUM.MERGE),
      disabled: APPLICANT_MERGE_STATUS_ENUM.MERGE === status,
    },
    {
      text: APPLICANT_MERGE_STATUS_ENUM.IGNORE,
      icon: <DoDisturbAlt fontSize="medium" color="error" />,
      handleClick: () => handleUpdate(APPLICANT_MERGE_STATUS_ENUM.IGNORE),
      disabled: APPLICANT_MERGE_STATUS_ENUM.IGNORE === status,
    },
    {
      text: APPLICANT_MERGE_STATUS_ENUM.REMOVE,
      icon: <CancelRounded fontSize="medium" color="error" />,
      handleClick: () => handleUpdate(APPLICANT_MERGE_STATUS_ENUM.REMOVE),
      disabled: APPLICANT_MERGE_STATUS_ENUM.REMOVE === status,
    },
  ];

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          if (!viewOnly) {
            setAnchorEl(e.currentTarget);
          }
        }}
        sx={{ ...(viewOnly && { cursor: "default" }) }}
      >
        <MergeStatusIcon status={status} />
      </IconButton>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
    </>
  );
};

export default ApplicantMergeStatus;
