import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState = {
  searchResults: [],
};

let store = (set) => ({
  ...initialState,
  setSearchResults: (results) => set({ searchResults: results }),
});

store = devtools(store);

store = persist(store, { name: "searchbarStore" });

const useSearchbarStore = create(store);

export default useSearchbarStore;
