import { useEffect, useState } from "react";

import { useAppContextController } from "context/AppContext";

import EditorBox from "components/EditorBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import VenuePreviewPanel from "layouts/pages/venues/components/VenuePreviewPanel";

import { Stack, Button } from "@mui/material";

const CustomDescription = ({
  description,
  setDescription,
  stadiumPeopleInfo,
  setStadiumPeopleInfo,
  setContentsDirty,
}) => {
  const { currentVenue } = useAppContextController();
  const [venuePreview, setVenuePreview] = useState(null);

  const [open, setOpen] = useState(false);
  const openPreviewModal = () => {
    setVenuePreview(currentVenue);
    setOpen(!open);
  };

  useEffect(() => {
    setDescription(currentVenue?.description);
    setStadiumPeopleInfo(currentVenue?.stadiumPeopleInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVenue]);

  /*
        cancelling changes
        setEditorDescriptionValue(currentVenue.description);
        setEditorStadiumPeopleValue(currentVenue.stadiumPeopleInfo);
    */

  return (
    <>
      <Stack spacing={2}>
        <EditorBox>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <MDTypography component="label" variant="button" fontWeight="regular">
              VENUE DESCRIPTION
            </MDTypography>
            <Button sx={{ p: 0 }} onClick={openPreviewModal}>
              Preview
            </Button>
          </Stack>
          <MDEditor
            value={description || ""}
            onChange={(e) => {
              setDescription(e);
              setContentsDirty(currentVenue?.description !== e);
            }}
          />
        </EditorBox>
        <EditorBox>
          <MDTypography component="label" variant="button" fontWeight="regular">
            STADIUM PEOPLE INFO
          </MDTypography>
          <MDEditor
            value={stadiumPeopleInfo || ""}
            onChange={(e) => {
              setStadiumPeopleInfo(e);
              setContentsDirty(currentVenue?.stadiumPeopleInfo !== e);
            }}
          />
        </EditorBox>
      </Stack>

      {venuePreview && (
        <VenuePreviewPanel
          open={open}
          setOpen={setOpen}
          venueId={venuePreview._id}
          //   refreshVenues={refreshVenues}
        />
      )}
    </>
  );
};

export default CustomDescription;
