import { baseAxios } from "config";

const fetchApplicantByCode = async (applicantCode, outsideMode = "") => {
  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;
    const res = await baseAxios.get(`/${outsideModeQuery}applicants/code/${applicantCode}`);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchApplicantByCode;
