import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const TagListToggle = ({ tagsToggle, onAny, onAll, onClear }) => {
  return (
    <ToggleButtonGroup value={tagsToggle} color="secondary" exclusive>
      <ToggleButton value="or" onClick={onAny}>
        Any
      </ToggleButton>
      <ToggleButton value="all" onClick={onAll}>
        All
      </ToggleButton>
      <ToggleButton value="clear" onClick={onClear}>
        Clear
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TagListToggle;
