import * as yup from "yup";

export const southCarolinaStateFormSchema = yup.object().shape({
    filingStatus: yup.string().required("Filing status is required"),
    stateOfDomicile: yup
        .string()
        .default("")
        .when("militarySpouse", {
            is: (val) => val === true,
            then: yup.string().required("State of domicile is required"),
            otherwise: yup.string().notRequired(),
        }),
});
