import GenericModal from "components/GenericModal";
import { Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const VenueInstructionsModal = ({ open, setOpen, setInstructions }) => {

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const modalHeader = (
    <Box display="flex">
      <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
        <MDTypography variant="h5" color="dark">
          Venue Instructions
        </MDTypography>
      </MDBox>
    </Box>
  );
  const modalBody = (
    <Box>
      <MDTypography variant="body2">
        Enter your zip code to see venues near you. To request access to that venue. , click the
        plus (&quot;+&quot;) in the dropdown for that venue. You will not be able to search and add
        events until you receive an approval email for that venue.
      </MDTypography>
    </Box>
  );

  const modalButtons = (
    <>
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
        <Box m={1}>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="error"
            onClick={() => {
              setInstructions(false)
              setOpen(false);
            }}
          >
            Don&apos;t show this again
          </Button>
        </Box>
        <Box m={1}>
          <Button
            variant="contained"
            style={{ color: "white" }}
            fullWidth
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </>
  );
  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={modalHeader}
        buttons={modalButtons}
        width={isSmallScreen ? "70%" : "30%"}
      />
    </>
  );
};

export default VenueInstructionsModal;
