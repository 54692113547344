import axios from "axios";
import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchNotifications = async ({
  setUnreadNotifications,
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
}) => {
  const userId = filters.userId ? filters.userId : null;
  // eslint-disable-next-line no-param-reassign
  if (userId) delete filters.userId;
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page: page || 1,
    limit: limit || 100,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {
    const url = userId
      ? `/notifications/userId/${userId}${paginationQuery}${sortQuery}${filterQuery}${
          useOr ? "&useOr=true" : ""
        }`
      : `/notifications${paginationQuery}${sortQuery}${filterQuery}${useOr ? "&useOr=true" : ""}`;

    const res = await baseAxios.get(url);

    if (setUnreadNotifications) {
      if (res?.data?.data) {
        const ur = res?.data?.data?.filter((item) => item.status === "unread");
        setUnreadNotifications([...ur]);
      } else setUnreadNotifications([]);
    }
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchNotifications;
