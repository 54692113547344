import axios from "axios";

import { baseAxios } from "config";

const fetchSingleEvent = async ({ eventId, eventUrl }) => {
  try {
    if (!eventId && !eventUrl) return null;
    const res = await baseAxios.get(
      eventId ? `/events/${eventId}` : `/events/eventUrl/${eventUrl}`
    );

    return res?.data?.event;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchSingleEvent;
