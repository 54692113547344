import { useEffect, useState, useRef } from "react";

import { Save } from "@mui/icons-material";
import { Button, Card, Grid, Snackbar, Alert } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import FormFieldsContainer from "components/FormFields/FormFieldsContainer";
import MDBox from "components/MDBox";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { NewApplicantContextProvider } from "context/NewApplicantContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import ProfileInfo from "layouts/pages/profile/components/ProfileInfo";
import useSessionAuth from "hooks/useSessionAuth";
import FederalW4Tax from "../applicants/new/components/NewApplicantForms/FederalW4Tax";
import DirectDepositRequest from "./components/DirectDepositRequest";

function NewProfile() {
  const { userType, currentLoggedUser, setCurrentLoggedUser, isLoggedIn } =
    useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [applicant, setApplicant] = useState({});
  const [bottomPanel, setBottomPanel] = useState(null);
  const panelRef = useRef("");

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const getApplicant = async () => {
      const appl = await fetchSingleApplicant({ applicantId: currentLoggedUser?.applicantId });
      if (appl) setApplicant(appl.data);
    };
    if (currentLoggedUser?.applicantId) {
      try {
        getApplicant();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [userType, currentLoggedUser?.applicantId]);

  const onSubmit = async () => {
    await document
      .getElementById("current-form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center" spacing={3}>
          {["Master", "Admin", "User"].includes(userType) && (
            <Grid item xs={12} lg={8}>
              <ProfileInfo
                userType={userType}
                currentUser={currentLoggedUser}
                setCurrentUser={setCurrentLoggedUser}
                currentApplicant={applicant}
                setCurrentApplicant={setApplicant}
                setBottomPanel={setBottomPanel}
                sourceComponent="profile"
                panelRef={panelRef}
              />
            </Grid>
          )}
          {["User"].includes(userType) && (
            <>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                key="top-center"
              >
                <NewApplicantContextProvider>
                  <FormFieldsContainer applicant={applicant}>
                    <Grid item xs={12} lg={8}>
                      {/* <ApplicantOnBoardingPanel currentApplicant={applicant} userPage /> */}
                      {bottomPanel === "changeMyW4" && (
                        <Card>
                          <MDBox p={3}>
                            <FlexWrapper sx={{ mb: "15px" }} justifyContent="flex-end">
                              <Button
                                variant="contained"
                                endIcon={<Save />}
                                sx={{ color: "white" }}
                                onClick={onSubmit}
                              >
                                Save
                              </Button>
                            </FlexWrapper>
                            <FederalW4Tax scrollToTop={false} />
                          </MDBox>
                        </Card>
                      )}
                    </Grid>
                    <div ref={panelRef} />
                    <Grid item xs={12} lg={8}>
                      {bottomPanel === "changeDirectDeposit" && (
                        <DirectDepositRequest
                          setBottomPanel={setBottomPanel}
                          currentApplicant={applicant}
                          setCurrentApplicant={setApplicant}
                          enqueueSnackbar={enqueueSnackbar}
                        />
                      )}
                    </Grid>
                  </FormFieldsContainer>
                </NewApplicantContextProvider>
              </SnackbarProvider>
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewProfile;
