import { Block, Flag, VerifiedUser } from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDTypography from "components/MDTypography";
import OnboardingStatus from "components/OnboardingStatus";
import CustomWidthTooltip from "layouts/pages/applicants/components/CustomWidthTooltip";
import JobsStatusRowActions from "layouts/pages/companyjobs/components/CompanyJobsStatus/JobStatusRow/JobsStatusRowActions";
import moment from "moment";
import InterviewRowActions from "../../CompanyJobInterviews/InterviewRow/InterviewRowActions";

const JobsStatusRow = ({
  row,
  refresh,
  isInterview,
  currentCustomer,
  onAddToJob,
  onChangeStatus,
  onOpenResumeModal,
  onTouchJob,
  isJobFull,
  isSearchingAll,
  isShowingAiScore,
  onOpenAIQuestionsModal,
  fullscreen,
}) => {
  const shouldShowAIScore = isShowingAiScore && row.aiRank != null;
  const scoreFinal = shouldShowAIScore ? row.aiRank : row.rank;

  return (
    <>
      <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
        <Grid item xs={12} sm={1}>
          <OnboardingStatus applicant={row} size="md" useAltIcons />
        </Grid>
        <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
          <CustomWidthTooltip
            title={`${row?.email} \n ${row?.phone}${
              row?.jobAgent ? ` \n Added by: ${row.jobAgent}` : ""
            }${row?.isDnu ? ` \n Do not use` : ""}${
              row.recordFlagged === "Yes" ? ` \n Check Employee Notes` : ""
            }`}
          >
            <MDTypography variant="body2">
              {[row.firstName, row.lastName].join(" ")}
              {!!row?.jobAgent && (
                <VerifiedUser
                  fontSize="small"
                  color="info"
                  sx={{
                    verticalAlign: "middle",
                    marginLeft: "3px",
                  }}
                />
              )}
              {row?.isDnu === "Yes" && (
                <Block
                  fontSize="small"
                  color="error"
                  sx={{
                    verticalAlign: "middle",
                    marginRight: "3px",
                  }}
                />
              )}
              {row.recordFlagged === "Yes" && (
                <Flag
                  fontSize="small"
                  color="error"
                  sx={{
                    verticalAlign: "middle",
                    marginRight: "3px",
                  }}
                />
              )}
            </MDTypography>
          </CustomWidthTooltip>
        </Grid>
        {fullscreen ? (
          <>
            <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
              <Tooltip title={row.phone}>
                <MDTypography variant="body2">{row.phone}</MDTypography>
              </Tooltip>
            </Grid>
            <Grid item flexGrow={2} flexBasis={0} flexShrink={1} overflow="hidden">
              <Tooltip title={row.email}>
                <MDTypography variant="body2">{row.email}</MDTypography>
              </Tooltip>
            </Grid>
            <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
              <MDTypography variant="body2">{row.systemSource}</MDTypography>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} sm={fullscreen ? 2 : 3}>
          <Tooltip
            title={`${shouldShowAIScore ? "AI Screening Score" : "Score"}: ${
              scoreFinal ? (scoreFinal * 100).toFixed(2) : 0
            }`}
          >
            <Grid sx={{ maxWidth: "250px" }}>
              <JobsStatusRankStars
                rank={scoreFinal ?? 0}
                color={shouldShowAIScore ? "#8935f4" : "#f7c501"}
              />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={fullscreen ? 1 : 2}>
          <Tooltip
            slotProps={{ tooltip: { style: { whiteSpace: "pre-line" } } }}
            title={
              isInterview
                ? "Interview Date"
                : `Last Activity${
                    row.jobModifiedAgent && row.jobModifiedAgentName
                      ? ` by ${row.jobModifiedAgentName}`
                      : " Date"
                  }${
                    row.jobApplyDate
                      ? `\nApply date: ${moment(row.jobApplyDate).format("MM/DD/YYYY")}`
                      : ""
                  }`
            }
          >
            <MDTypography variant="body2">
              {(!isInterview && row.jobModifiedDate) || !!row.interview?.eventDate
                ? moment(isInterview ? row.interview?.eventDate : row.jobModifiedDate).format(
                    "MM/DD/YYYY"
                  )
                : ""}
            </MDTypography>
          </Tooltip>
        </Grid>
        <Grid item flexBasis="auto">
          {isInterview ? (
            <InterviewRowActions
              currentApplicant={row}
              refresh={refresh}
              isJobStatus
              currentCustomer={currentCustomer}
              isJobFull={isJobFull}
            />
          ) : (
            <JobsStatusRowActions
              applicant={row}
              refresh={refresh}
              currentCustomer={currentCustomer}
              onAddToJob={onAddToJob}
              onChangeStatus={onChangeStatus}
              onOpenResumeModal={onOpenResumeModal}
              isJobFull={isJobFull}
              isSearchingAll={isSearchingAll}
              onTouchJob={onTouchJob}
              onOpenAIQuestionsModal={onOpenAIQuestionsModal}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default JobsStatusRow;
