import * as yup from "yup";
import { isEmpty } from "lodash";

export const partnersInfoSchema = (stateCodes) =>
  yup.object().shape({
    name: yup.string().required("Name is required"),
    slug: yup
      .string()
      .required("Slug is required")
      .test({
        name: "validate-slug-format",
        test: async (value, ctx) => {
          if (!value) return true;
          if (!/^[a-z0-9 -]+$/.test(value)) {
            return ctx.createError({ message: "Only lowercase letters are allowed" });
          }
          if (!/^[a-z0-9]+(?:[ -]*[a-z0-9]+)*$/.test(value)) {
            return ctx.createError({ message: "Trailing hyphens and spaces is not allowed" });
          }
          return true;
        },
      }),
    email: yup.string().nullable().default("").email("Invalid Email Address"),
    leader: yup.object({
      phone: yup
        .string()
        .nullable()
        .default("")
        .test({
          name: "test-phone-length",
          test: (value, ctx) => {
            if (!value) return true;
            const trimValueLength = value.replace(/\D/g, "").length;

            if (trimValueLength === 10) {
              return true;
            }
            return ctx.createError({ message: "Phone number must be 10 digits" });
          },
        }),
      email: yup.string().nullable().default("").email("Invalid Email Address"),
    }),
    state: yup
      .string()
      .nullable()
      .default("")
      .test({
        name: "validate-state",
        test: (value, ctx) => {
          if (!value) return true;
          if (stateCodes?.includes(value) || isEmpty(value)) return true;
          return ctx.createError({ message: "Insert a valid state" });
        },
      }),
    zip: yup
      .string()
      .nullable()
      .default("")
      .test({
        name: "test-zip-format",
        test: (value, ctx) => {
          if (!value) return true;
          const parsed = value.replace(/\D/g, "");
          if (parsed.length === 5 || parsed.length === 9) {
            return true;
          }
          return ctx.createError({ message: "Zip should only be a 5 or 9 digit format" });
        },
      }),
    status: yup.string().nullable().required("Status is required"),
  });

export const noValidations = yup.object().shape({});
