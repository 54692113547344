import { baseAxios } from "config";

const deleteAuth0User = async ({ id }) => {

    try {
        const res = await baseAxios.post(`/users/id/${id}/deleteAuth0`);

        return res.data;
    } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        throw new Error(err.toString());
    }
};

export default deleteAuth0User;
