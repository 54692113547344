import { useEffect } from "react";

import { useNewApplicantContext } from "context/NewApplicantContext";

const FormFieldsContainer = ({ applicant, children }) => {
  const { setApplicant } = useNewApplicantContext();

  useEffect(() => {
    if (applicant) {
      setApplicant(applicant);
    }
  }, [applicant]);

  return children;
};

export default FormFieldsContainer;
