import { baseAxios } from "config";

const uploadFileCompany = async ({ companySlug, uploadForm }) => {
  try {
    const res = await baseAxios.post(`/upload/${companySlug}/company/other`, uploadForm);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default uploadFileCompany;
