// import TableAction from "components/TableAction";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import {
  Public,
  Block,
  // ConnectWithoutContact,
  // PersonAdd,
  // FolderShared,
  // QuestionAnswer,
  // ThumbDown,
  // Handshake
} from "@mui/icons-material";
import VenueIcon from "components/VenueIcon";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

const allCard = [
  {
    icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
    title: "All",
    number: 0,
    tooltip: "All Employees",
    filterField: "employmentStatus",
    filterValue: "Active;On Assignment;Inactive;Terminated",
  },
];

const EmployeesFilterCards = ({ setFilters, setPage, filters, baseFilters }) => {
  const { currentLoggedUser, venues } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const navigate = useNavigate();
  const [initialCards, setInitialCards] = useState([]);

  const createCard = (venue) => {
    const card = {
      icon: (
        <VenueIcon
          logoUrl={venue?.logoUrl}
          slug={venue?.slug}
          name={venue?.name}
          imageWidth="35"
          imageHeight="35"
          goToEnabled={false}
        />
      ),
      title: venue?.slug,
      tooltip: `${venue?.name} Staffing Pool Employees`,
      number: 0,
      filterField: "venues.venueSlug",
      filterValue: venue?.slug,
    };
    return card;
  };

  const { cards } = useBadgeValues({
    initialCards,
    filters: { ...(baseFilters ?? {}), ...filters },
    entity: "applicants",
    refreshBadges,
  });

  useEffect(() => {
    let isMounted = true;

    if (venues && Object.keys(venues)?.length && isMounted) {
      const cds = currentLoggedUser?.venues
        ? [
            ...allCard,
            ...currentLoggedUser.venues.sort().map((item) => {
              const venue = venues[item];
              return createCard(venue);
            }),
          ]
        : allCard;
      setInitialCards(cds);
    } else if (isMounted) setInitialCards(allCard);
    setRefreshBadges((prev) => !prev);

    return () => {
      isMounted = false;
    };
  }, [currentLoggedUser?.venues, venues]);

  const { setCurrentApplicant } = useAppContextController();

  const setApplicantFilters = (filter) => {
    navigate("/employees");
    setCurrentApplicant(null);
    const newFilters = { ...filters };
    delete newFilters._id;
    setFilters({ ...newFilters, ...filter });
    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setApplicantFilters}
        setPage={setPage}
      />
    </span>
  );
};

export default EmployeesFilterCards;

EmployeesFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

// Typechecking props for the TableAction
EmployeesFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
