import { Modal } from "@material-ui/core";
import { Card, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import useCompanyCache from "hooks/useCompanyCache";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import CompanyLogoSquare from "components/CompanyLogoSquare";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  logoImage: {
    objectFit: "contain",
  },
});

const ThanksForApplyingModal = ({ applicantCode, open, setOpen }) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const { company, setCompany, setCompanyType } = useAppContextController();

  const handleOnboarding = useCallback(() => {
    setOpen(false);
    navigate(`/applicants/code/${applicantCode}`);
  }, [applicantCode]);

  const handleNoOnboarding = () => {
    setOpen(false);
    navigate(`/`);
  };

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "protected",
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open}>
          <Card className={classes.container}>
            <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
              <Box display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="white"
                  color="white"
                  borderRadius="xl"
                  mt={-2}
                >
                  <CompanyLogoSquare
                    company={company}
                    defaultCompany={company}
                    width="50"
                    height="50"
                  />
                </MDBox>
                <MDBox p={3}>
                  <MDTypography variant="h5" color="dark">
                    Application Successful
                  </MDTypography>
                </MDBox>
              </Box>
            </MDBox>
            <Box display="flex">
              <MDBox p={3}>
                <MDTypography variant="h5" color="dark">
                  Thank you for applying with {company?.name}. One of our Recruiters will be in
                  contact with you very soon. In the meantime, you will receive an email message
                  with a link to verify your email address.
                </MDTypography>
                {/* In the meantime, you may begin completing our Job
                  Application at any time to expedite the hiring process. */}
              </MDBox>
            </Box>
            <MDBox p={3} alignSelf="end">
              <MDButton
                color="info"
                variant="contained"
                onClick={() => {
                  // handleOnboarding();
                  setOpen(false);
                  navigate(`https://stadium-people.com`);
                }}
              >
                {/* <MDTypography variant="body2" color="white">
                  BEGIN ONBOARDING
                </MDTypography> */}
                <MDTypography variant="body2" color="white">
                  DONE
                </MDTypography>
              </MDButton>
              {/* <MDButton
                color="info"
                variant="text"
                onClick={() => {
                  handleNoOnboarding();
                }}
              >
                <MDTypography variant="body2" color="blue">
                  No thanks, I&apos;ll do it later
                </MDTypography>
              </MDButton> */}
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default ThanksForApplyingModal;
