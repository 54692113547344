import { useMemo, useState } from "react";
import { Button, Card, Fade, Grid, Modal } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import {
  Cancel as CancelIcon,
  QuestionAnswer as QuestionAnswerIcon,
  ThumbDown as ThumbDownIcon,
} from "@mui/icons-material";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDBox from "components/MDBox";
import PictureAvatar from "components/PictureAvatar";
import updateApplicantStage from "layouts/pages/applicants/actions/updateApplicantStatus";
import updateApplicantJobAIInterviews from "layouts/pages/applicants/actions/updateApplicantJobAIInterviews";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import AIInterviewChatWindow from "../AIInterviewChatWindow";

const styles = {
  container: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    height: "100%",
    maxWidth: 1170,
    padding: "2rem",
    // overflow: "auto",
  },
};

const JobsStatusAIInterviewModal = ({ open, onClose, applicant, onChangeStatus }) => {
  const [questionIndex, setQuestionIndex] = useState(null);
  const { currentLoggedUser, userType, company, currentJob } = useAppContextController();
  const isVenue = company?.companyType === "Venue";

  const applicantJob = useMemo(() => {
    return applicant?.jobs?.find((jb) => jb.jobSlug === currentJob.jobSlug);
  }, [applicant, currentJob.jobSlug]);

  const applicantJobAiInterview = applicantJob?.aiInterviews?.[0];

  const dateString = useMemo(() => {
    if (applicantJobAiInterview?.interviewStartDate || applicantJobAiInterview?.interviewEndDate) {
      const date =
        applicantJobAiInterview?.interviewEndDate ?? applicantJobAiInterview?.interviewStartDate;

      const now = moment();
      const dateMoment = moment(date);

      const days = Math.abs(dateMoment.diff(now, "days"));
      const hours = Math.abs(dateMoment.diff(now, "hours"));
      const minutes = Math.abs(dateMoment.diff(now, "minutes"));

      let unit = "day";
      let amount = 0;
      if (days < 1) {
        if (hours < 1) {
          unit = `minute${minutes > 1 ? "s" : ""}`;
          amount = minutes;
        } else {
          unit = `hour${hours > 1 ? "s" : ""}`;
          amount = hours;
        }
      } else {
        unit = `day${days > 1 ? "s" : ""}`;
        amount = days;
      }

      return `Active ${amount} ${unit} ago`;
    }
    return "";
  }, [applicantJobAiInterview?.interviewEndDate, applicantJobAiInterview?.interviewStartDate]);

  const applicantStatus = useMemo(() => {
    if (isVenue) {
      return applicant?.applicantStatus ?? "New";
    }
    return applicantJob?.applicantStatus ?? "New";
  }, [applicant?.applicantStatus, applicantJob?.applicantStatus, isVenue]);

  const { score = 0 } = applicantJobAiInterview ?? {};

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const updateAiInterviewMutation = useMutation(updateApplicantJobAIInterviews, {
    onSuccess: async (_, { applicantId, status }) => {},
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "applicantUpdateAiInterviewMutationMutation",
  });

  const changeStageMutation = useMutation(updateApplicantStage, {
    onSuccess: async (_, { applicantId, status, jobSlug }) => {
      enqueueSnackbar(
        `${applicant.firstName} ${applicant.lastName} has been set to: ${status}${
          !isVenue ? ` for ${currentJob?.jobSlug}` : ""
        }`,
        { variant: "success" }
      );
      if (onChangeStatus) {
        onChangeStatus({
          applicantId,
          applicantStatus: status,
        });
      }
      await updateAiInterviewMutation.mutateAsync({
        applicantId,
        jobSlug,
        data: [
          {
            ...(applicantJobAiInterview ?? {}),
            screeningDecision: status,
            screeningDecisionUserId: currentLoggedUser._id,
          },
        ],
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "applicantEmploymentStageChangeMutation",
  });

  const handleChangeStatus = (status) => {
    changeStageMutation.mutateAsync({
      status,
      applicantId: applicant?._id,
      jobSlug: currentJob?.jobSlug,
      sendOnboardingMessage: false,
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card style={styles.container}>
            <Button
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                m: 2,
                p: 0,
              }}
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <CancelIcon color="secondary" />
            </Button>
            <Grid container item flexDirection="column" height="100%">
              <Grid
                item
                container
                sx={{
                  borderBottomWidth: 2,
                  borderBottomColor: "#8f9095",
                  borderBottomStyle: "solid",
                  paddingBottom: 1,
                }}
              >
                <PictureAvatar
                  image={null}
                  firstName={applicant?.firstName}
                  lastName={applicant?.lastName}
                  // userId={usr?._id?.toString()}
                  size="md"
                  disableHover
                />
                <MDBox display="flex" flexDirection="column" pl={1}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="dark">
                    {`${applicant?.firstName ?? ""} ${applicant.lastName ?? ""}`}
                  </MDTypography>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="secondary"
                    sx={{ fontSize: "0.74rem" }}
                  >
                    {dateString}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item container columnSpacing={2} pt={2} pb={2}>
                <Grid xs={3}>
                  <JobsStatusRankStars rank={score} color="#8935f4" />
                </Grid>
                <Grid xs={4} item />
                <Grid xs={2.5} item>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={() => handleChangeStatus("Screened")}
                    startIcon={<QuestionAnswerIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                    disabled={applicantStatus === "Screened" || changeStageMutation.isLoading}
                  >
                    Screened
                  </Button>
                </Grid>
                <Grid xs={2.5} item>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => handleChangeStatus("Declined")}
                    startIcon={<ThumbDownIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                    disabled={applicantStatus === "Declined" || changeStageMutation.isLoading}
                  >
                    Declined
                  </Button>
                </Grid>
              </Grid>
              <Grid item container sx={{ flexBasis: 0, flexGrow: 1, overflow: "hidden" }}>
                <AIInterviewChatWindow
                  firstName={applicant?.firstName}
                  lastName={applicant?.lastName}
                  items={applicantJobAiInterview?.questionsAndAnswers}
                />
              </Grid>
            </Grid>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of CalendarDayModal
JobsStatusAIInterviewModal.defaultProps = {};

// Typechecking props of the CalendarDayModal
JobsStatusAIInterviewModal.propTypes = {};

export default JobsStatusAIInterviewModal;
