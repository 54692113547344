import { useState } from "react";

import { Grid, Icon, Stack, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  Cancel,
  Email,
  Info,
  Description,
  PersonOff,
  DoNotDisturb,
} from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { dataStyles as styles } from "./styles";

const PartnerMemberItem = ({
  row,
  idx,
  noContact,
  setSelectedContact,
  onEdit,
  onRemove,
  onAddNote,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { firstName, lastName, email, phone, tShirtSize } = row;
  const isIconMenuOpen = Boolean(anchorEl);

  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  const handleChange = (e) => {
    e.stopPropagation();
    handleIconClose();
  };

  return (
    <>
      <Stack display="flex" direction="row" justifyContent="space-between">
        <Grid container spacing={2} alignItems="center">
          <Tooltip title={row?.partnerStatus === "Inactive" ? "Inactive member" : ""}>
            <Grid container item sm={2.5} flexDirection="row" alignItems="center" gap="2px">
              {row?.partnerStatus === "Inactive" && <DoNotDisturb color="error" />}
              <MDTypography sx={styles.font}>{`${firstName} ${lastName}`}</MDTypography>
            </Grid>
          </Tooltip>
          <Grid item sm={3.5}>
            <MDTypography sx={styles.font}>{email ?? ""}</MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDTypography sx={styles.font}>{phone ?? ""}</MDTypography>
          </Grid>
          <Grid item sm={1}>
            <MDTypography sx={[styles.font, styles.centered]}>{tShirtSize ?? ""}</MDTypography>
          </Grid>
          <Grid item sm={1}>
            <MDBox textAlign="right">
              <Tooltip title="Member Info" placement="top">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(idx);
                  }}
                >
                  <Info color="info" fontSize="medium" />
                </IconButton>
              </Tooltip>
            </MDBox>
          </Grid>

          <Grid item sm={1}>
            <MDBox textAlign="right">
              <Tooltip title="Member Notes" placement="top">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddNote(idx);
                  }}
                >
                  <Description color="info" fontSize="medium" />
                </IconButton>
              </Tooltip>
            </MDBox>
          </Grid>
          <Grid item sm={1}>
            <MDBox textAlign="right">
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(row?._id);
                }}
              >
                <Cancel color="error" fontSize="medium" />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default PartnerMemberItem;
