import axios from "axios";

import { baseAxios } from "config";

const fetchEmailTemplates = async () => {
  try {
    const res = await baseAxios.get(`/control/emailTemplates`);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchEmailTemplates;
