import { useState } from "react";
import { Button, FormHelperText, InputLabel, Grid, Autocomplete, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { transformYupErrorsIntoObject } from "utils/helpers/formHelpers";
import states from "assets/stateCodes.json";
import FormField from "layouts/applications/wizard/components/FormField";
import GenericModal from "components/GenericModal";
import educationSchema from "./educationSchema";

const defaultValues = {
    city: "",
    degreeDiploma: "",
    endYear: "",
    major: "",
    minor: "",
    schoolName: "",
    startYear: "",
    state: "",
    description: ""
};

const EducationModal
    = ({
        educationList,
        currentEducation,
        setEducation,
        educationIndex,
        setEducationIndex,
        onEducationChange,
        isModalOpen,
        setModalOpen,
    }) => {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

        const [errors, setErrors] = useState();

        const reset = () => {
            setEducation(defaultValues);
            setEducationIndex(null);
            setErrors();
        };

        const handleClose = () => {
            setModalOpen(false);
            reset();
        };
        const handleChange = (name, value) => setEducation({ ...currentEducation, [name]: value });
        const handleSubmit = async () => {

            let updatedEducation;
            setErrors();

            await educationSchema
                .validate(currentEducation, { abortEarly: false })
                .then(() => {
                    if (educationList) {
                        if (educationIndex !== null) {
                            updatedEducation = educationList.map((item, index) => {
                                if (index === educationIndex) {
                                    return currentEducation;
                                }
                                return item;
                            });
                        } else {
                            updatedEducation = [...educationList, currentEducation];
                        }
                    } else {
                        updatedEducation = [currentEducation];
                    }

                    onEducationChange(updatedEducation);
                    handleClose();
                })
                .catch((error) => {
                    const validationErrors = transformYupErrorsIntoObject(error);
                    setErrors(validationErrors);
                });
        };

        const modalBody = (
            <>
                <IconButton onClick={handleClose} sx={{ position: "absolute", top: "18px", right: "21px" }}>
                    <CancelIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormField
                            value={currentEducation?.degreeDiploma}
                            onChange={(e) => handleChange("degreeDiploma", e.target.value)}
                            label="Degree Diploma"
                        />
                        <FormHelperText error>{errors?.degreeDiploma}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            value={currentEducation?.schoolName}
                            onChange={(e) => handleChange("schoolName", e.target.value)}
                            label="School Name"
                        />
                        <FormHelperText error>{errors?.schoolName}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormField
                            value={currentEducation?.city}
                            onChange={(e) => handleChange("city", e.target.value)}
                            label="City"
                        />
                        <FormHelperText error>{errors?.city}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            options={states || []}
                            value={currentEducation?.state || null}
                            onChange={(e, v) => handleChange("state", v.toUpperCase())}
                            renderInput={(params) => (
                                <>
                                    <FormField {...params} type="text" label="State" />
                                    <FormHelperText error>{errors?.state}</FormHelperText>
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            value={currentEducation?.major}
                            onChange={(e) => handleChange("major", e.target.value)}
                            label="Major"
                        />
                        <FormHelperText error>{errors?.major}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            value={currentEducation?.minor}
                            onChange={(e) => handleChange("minor", e.target.value)}
                            label="Minor"
                        />
                        <FormHelperText error>{errors?.minor}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            value={currentEducation?.startYear}
                            onChange={(e) => handleChange("startYear", e.target.value)}
                            label="Start Year"
                        />
                        <FormHelperText error>{errors?.startYear}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            value={currentEducation?.endYear}
                            onChange={(e) => handleChange("endYear", e.target.value)}
                            label="End Year"
                        />
                        <FormHelperText error>{errors?.endYear}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>Description</InputLabel>
                        <FormField
                            variant="outlined"
                            name="description"
                            label=""
                            value={currentEducation?.description || null}
                            InputLabelProps={{ shrink: false }}
                            multiline
                            rows={4}
                            onChange={(e) => handleChange("description", e.target.value)}
                        />
                        <FormHelperText error>{errors?.description}</FormHelperText>
                    </Grid>
                </Grid>
            </>
        );

        const modalButtons = (
            <>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </>
        );

        return (
            <>
                <GenericModal
                    open={isModalOpen}
                    setOpen={setModalOpen}
                    header="Add Education"
                    body={modalBody}
                    buttons={modalButtons}
                    width={isMobile ? "90%" : "50%"}
                    borderRadius="4px"
                />
            </>
        );
    };

export default EducationModal
    ;
