import { Badge, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";
import parse from "html-react-parser";
import { useQuery } from "react-query";
import fetchTasks from "layouts/pages/tasks/actions/fetchTasks";

const TaskFlag = ({ _id }) => {
  const taskOptions = { fetchAll: true };
  const {
    data: tasks,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery(["tasks", taskOptions], () => fetchTasks(taskOptions));

  if (isLoadingTasks || !tasks) return null;

  const applicantTasks = tasks?.data?.filter(
    (t) => t.applicant?._id === _id && t.status !== "Done"
  );

  if (applicantTasks?.length === 0) return null;

  const taskFlagColor = (taskStatus) => {
    switch (taskStatus) {
      case "Blocked":
        return "error";
      case "In Progress":
        return "success";
      case "To Do":
        return "warning";
      case "On Hold":
        return "info";
      default:
        return "info";
    }
  };

  const groupedTasks = applicantTasks.reduce((acc, task) => {
    if (!acc[task.status]) {
      acc[task.status] = [];
    }
    acc[task.status].push(task);
    return acc;
  }, {});

  const getTooltipContent = (taskStatus) => {
    return `${taskStatus}\n${groupedTasks[taskStatus]
      ?.map((t, index) => `${index + 1}. ${t.taskType} ${t.taskDescription}`)
      .join("\n")}`;
  };

  return (
    <Stack display="flex" flexDirection="row" gap={2.5}>
      {Object.keys(groupedTasks).map((taskStatus, taskIndex) => (
        <Tooltip
          key={taskStatus}
          title={
            <div style={{ whiteSpace: "pre-line", textAlign: "left" }}>
              {parse(getTooltipContent(taskStatus))}
            </div>
          }
        >
          <Badge
            badgeContent={groupedTasks[taskStatus]?.length?.toString()}
            max={Infinity}
            color={taskFlagColor(taskStatus)}
          >
            {taskIndex === 0 && <FlagIcon fontSize="large" color="primary" />}
          </Badge>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default TaskFlag;
