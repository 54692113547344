import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchEmployees = async (params) => {
  // const limit = rest?.limit;
  // const page = rest?.page;

  if (!params) return { data: [], count: 0 };
  const {
    limit = null,
    page = null,
    order,
    orderBy,
    fetchAll,
    filters,
    useOr = false,
    searchProjection = false,
    includeVerified = true,
    useOrTags = true,
    includeDnu,
  } = params;
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters: { status: "Employee", ...filters },
  });

  try {
    const res = await baseAxios.get(
      `/applicants${paginationQuery}${sortQuery}${filterQuery}${useOr ? "&useOr=true" : ""}${
        searchProjection ? "&searchProjection=true" : ""
      }${includeVerified ? "&includeVerified=true" : ""}&tagOper=${useOrTags ? "in" : "all"}${
        includeDnu || filters._id ? "&includeDnu=true" : ""
      }`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchEmployees;
