/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { InfoWindow, Map, Marker, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { isEmpty } from "lodash";
import "./CustomMap.css";
import RosterMarkers from "./RosterMarkers";
import RosterInformation from "./RosterInformation";

const dashedLine = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  scale: 2,
};

const getLabel = (rad) => ({
  color: "black",
  fontWeight: "bold",
  text: `${rad?.toFixed(2)} Meters`,
  fontSize: "12px",
});

const getLatLngFromPos = (pt) => {
  return {
    lat: pt?.lat(),
    lng: pt?.lng(),
  };
};

const calculateOptimalZoom = (radius) => {
  return Math.floor(16 - Math.log(radius / 400) / Math.log(2));
};

const CustomMap = ({
  initialCenter,
  center,
  radius,
  graceDistance,
  setCenter = () => {},
  setRadius = () => {},
  setGraceDistance = () => {},
  isCenterEditable,
  isRadiusEditable,
  isGDistanceEditable,
  onMarkerClick = () => {},
  onCircleClick = () => {},
  onGDClick = () => {},
  // ff. only for events
  roster,
  filter = "All",
  currentApp = () => {},
  setCurrentApp,
  isInfoOpen,
  setInfoOpen = () => {},
  disableControls = false,
  onMapButtonClick = () => {},
}) => {
  const [centerPoint, setCenterPoint] = useState(null);
  const [areaCircle, setAreaCircle] = useState(null);
  const [graceCircle, setGraceCircle] = useState(null);
  const [graceLine, setGraceLine] = useState(null);
  const [gracePoint, setGracePoint] = useState(null);
  const [graceInfo, setGraceInfo] = useState(null);
  const [radiusLine, setRadiusLine] = useState(null);
  const [radiusPoint, setRadiusPoint] = useState(null);
  const [radiusInfo, setRadiusInfo] = useState(null);
  const [isInfoClicked, setInfoClicked] = useState(false);

  const core = useMapsLibrary("core");
  const maps = useMapsLibrary("maps");
  const marker = useMapsLibrary("marker");
  const geometry = useMapsLibrary("geometry");
  const map = useMap();

  // remove if necessary
  const filteredRoster =
    roster?.filter((ros) => !!ros.clockInCoordinates || !!ros.clockOutCoordinates) || [];

  const getEastPoint = (loc, circle = areaCircle) => {
    const eastPoint = {
      lat: loc?.lat,
      lng: circle?.getBounds()?.getNorthEast()?.lng(),
    };

    return eastPoint;
  };

  const getInfoPosition = (pos, circle = areaCircle) => {
    const info = {
      lat: pos?.lat,
      lng: (circle?.getBounds()?.getNorthEast()?.lng() + pos?.lng) / 2,
    };

    return info;
  };

  const handleCenterChange = (selectedCenter) => {
    areaCircle?.setCenter(selectedCenter);
    radiusLine?.setPath([selectedCenter, getEastPoint(selectedCenter)]);
    radiusInfo?.setPosition(getInfoPosition(selectedCenter));
    graceCircle?.setCenter(selectedCenter);
    graceLine?.setPath([getEastPoint(selectedCenter), getEastPoint(selectedCenter, graceCircle)]);
    graceInfo?.setPosition(getInfoPosition(getEastPoint(selectedCenter), graceCircle));
    gracePoint?.setPosition(getEastPoint(center, graceCircle));
  };

  const handleMapClick = (e) => {
    if (!isCenterEditable) return;
    const location = e?.detail?.latLng;
    if (centerPoint) centerPoint?.setPosition(location);
    handleCenterChange(location);
    setCenter(location);
  };

  const handleMarkerDragEnd = (e) => {
    if (isCenterEditable) {
      const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      setCenter(location);
    }
  };

  const handleMarkerDrag = (e) => {
    if (isCenterEditable) {
      const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      if (centerPoint) centerPoint?.setPosition(location);
      handleCenterChange(location);
    }
  };

  const handleDragEffect = (info, line, distance, selCenter, pos, circle = areaCircle) => {
    info?.setLabel(getLabel(distance));
    info?.setPosition(getInfoPosition(selCenter, circle));
    line?.setPath([selCenter, pos]);
  };

  const getPosition = (ros) => {
    let lat = 0;
    let lng = 0;

    if (ros?.clockInCoordinates) {
      lat = ros.clockInCoordinates?.latitude;
      lng = ros.clockInCoordinates?.longitude;
    }

    if (ros?.clockOutCoordinates && filter !== "Clocked In") {
      lat = ros.clockOutCoordinates?.latitude;
      lng = ros.clockOutCoordinates?.longitude;
    }

    return {
      lat,
      lng,
    };
  };

  useEffect(() => {
    if (!map || !geometry) return;
    let optimalZoom;
    const totalRadius = graceDistance + radius;
    if (roster && !isEmpty(roster) && filter === "Outside Geofence") {
      let maxDistance = 0;
      filteredRoster.forEach((m) => {
        const distance = geometry?.spherical?.computeDistanceBetween(center, getPosition(m));
        if (distance > maxDistance) {
          maxDistance = distance;
        }
      });
      if (maxDistance > totalRadius) optimalZoom = calculateOptimalZoom(maxDistance);
      else optimalZoom = calculateOptimalZoom(totalRadius);
    } else {
      optimalZoom = calculateOptimalZoom(totalRadius);
    }
    if (optimalZoom < 6) optimalZoom = 6; // approx. 5 miles
    // on filter change center map to the center of the circle
    map.setCenter(center);
    map.setZoom(optimalZoom);
  }, [map, geometry, filter]);

  useEffect(() => {
    if (!map) return;
    if (areaCircle) areaCircle?.setMap(null);
    setAreaCircle(
      new maps.Circle({
        strokeColor: "green",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "green",
        fillOpacity: 0.35,
        map,
        center,
        radius,
        draggable: false,
        zIndex: 1,
      })
    );

    if (graceCircle) graceCircle?.setMap(null);
    setGraceCircle(
      new maps.Circle({
        strokeColor: "#F7C501",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "yellow",
        fillOpacity: 0.35,
        map,
        center,
        radius: graceDistance + radius,
        draggable: false,
        zIndex: -1,
      })
    );
  }, [map]);

  useEffect(() => {
    if (!areaCircle) return;
    if (isRadiusEditable) return;
    const listener = core?.event?.addListener(areaCircle, "click", () => {
      onCircleClick();
    });
    // eslint-disable-next-line consistent-return
    return () => core?.event?.removeListener(listener);
  }, [areaCircle]);

  useEffect(() => {
    if (!graceCircle) return;
    if (isGDistanceEditable) return;
    const listener = core?.event?.addListener(graceCircle, "click", () => {
      onGDClick();
    });
    // eslint-disable-next-line consistent-return
    return () => core?.event?.removeListener(listener);
  }, [graceCircle]);

  useEffect(() => {
    if (isCenterEditable) {
      if (!areaCircle || !marker) return;
      if (!centerPoint) {
        if (radiusLine) radiusLine.setPath([center, getEastPoint(center)]);
        setCenterPoint(
          new marker.Marker({
            position: center,
            draggable: false, // true, for draggable center point
            map,
            icon: {
              path: core?.SymbolPath?.CIRCLE,
              scale: 5,
              fillColor: "green",
              fillOpacity: 1,
              strokeWeight: 0.4,
            },
            zIndex: -1,
          })
        );
      }
    } else if (centerPoint) {
      centerPoint?.setMap(null);
      setCenterPoint(null);
    }
  }, [isCenterEditable, areaCircle]);

  // for draggable center point
  // useEffect(() => {
  //   if (!centerPoint) return;

  //   core?.event?.addListener(centerPoint, "dragend", () => {
  //     const pos = centerPoint?.getPosition();
  //     setCenter(getLatLngFromPos(pos));
  //   });

  //   core?.event?.addListener(centerPoint, "drag", () => {
  //     const pos = centerPoint?.getPosition();
  //     handleCenterChange(getLatLngFromPos(pos));
  //   });
  // }, [centerPoint]);

  useEffect(() => {
    if (isRadiusEditable) {
      if (!areaCircle || !marker) return;

      if (!radiusPoint) {
        const pointPos = radiusLine
          ? getLatLngFromPos(radiusLine?.getPath()?.getAt(1))
          : getEastPoint(center);

        setRadiusPoint(
          new marker.Marker({
            position: pointPos,
            draggable: true,
            map,
            icon: {
              path: core?.SymbolPath?.CIRCLE,
              scale: 5,
              fillColor: "white",
              fillOpacity: 1,
              strokeWeight: 0.4,
            },
          })
        );
      }
    } else {
      radiusPoint?.setMap(null);
      setRadiusPoint(null);
    }
  }, [isRadiusEditable, areaCircle]);

  useEffect(() => {
    if (isGDistanceEditable) {
      if (!areaCircle || !marker) return;

      if (!gracePoint) {
        const pointPos = graceLine
          ? getLatLngFromPos(graceLine?.getPath()?.getAt(1))
          : getEastPoint(center, graceCircle);

        setGracePoint(
          new marker.Marker({
            position: pointPos,
            draggable: true,
            map,
            icon: {
              path: core?.SymbolPath?.CIRCLE,
              scale: 5,
              fillColor: "white",
              fillOpacity: 1,
              strokeWeight: 0.4,
            },
          })
        );
      }
    } else {
      gracePoint?.setMap(null);
      setGracePoint(null);
    }
  }, [isGDistanceEditable, areaCircle]);

  useEffect(() => {
    if (!radiusPoint) return;

    const dragendListener = core?.event?.addListener(radiusPoint, "dragend", () => {
      const pos = radiusPoint?.getPosition();
      const distance = geometry?.spherical?.computeDistanceBetween(center, pos);
      setRadius(distance);
    });

    const dragListener = core?.event?.addListener(radiusPoint, "drag", () => {
      const pos = getLatLngFromPos(radiusPoint?.getPosition());
      const distance = geometry?.spherical?.computeDistanceBetween(center, pos);
      areaCircle?.setRadius(distance);
      handleDragEffect(radiusInfo, radiusLine, distance, center, pos);

      graceCircle?.setRadius(distance + graceDistance);
      handleDragEffect(
        graceInfo,
        graceLine,
        graceDistance,
        getEastPoint(center),
        getEastPoint(center, graceCircle),
        graceCircle
      );

      gracePoint?.setPosition(getEastPoint(center, graceCircle));
    });

    // eslint-disable-next-line consistent-return
    return () => {
      core?.event?.removeListener(dragendListener);
      core?.event?.removeListener(dragListener);
    };
  }, [radiusPoint]);

  useEffect(() => {
    if (!gracePoint) return;

    const dragendListener = core?.event?.addListener(gracePoint, "dragend", () => {
      const pos = gracePoint?.getPosition();
      const circleRadius = geometry?.spherical?.computeDistanceBetween(center, pos);
      const distance = geometry?.spherical?.computeDistanceBetween(getEastPoint(center), pos);

      if (radius < circleRadius) {
        setGraceDistance(distance);
      } else {
        gracePoint?.setPosition(getEastPoint(center));
      }
    });

    const dragListener = core?.event?.addListener(gracePoint, "drag", () => {
      const pos = getLatLngFromPos(gracePoint?.getPosition());
      const circleRadius = geometry?.spherical?.computeDistanceBetween(center, pos);
      const distance = circleRadius - radius;
      if (radius < circleRadius) {
        graceCircle?.setRadius(circleRadius);
        handleDragEffect(graceInfo, graceLine, distance, getEastPoint(center), pos, graceCircle);
      } else {
        gracePoint?.setPosition(getEastPoint(center));
        graceLine?.setPath([getEastPoint(center), getEastPoint(center)]);
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      core?.event?.removeListener(dragendListener);
      core?.event?.removeListener(dragListener);
    };
  }, [gracePoint]);

  useEffect(() => {
    if (areaCircle) {
      if (radiusLine) radiusLine.setMap(null);

      setRadiusLine(
        new maps.Polyline({
          path: [{ ...center }, { ...getEastPoint(center) }],
          geodesic: true,
          icons: [
            {
              icon: dashedLine,
              offset: "15px",
              repeat: "10px",
            },
          ],
          strokeOpacity: 0,
          map,
        })
      );

      if (graceLine) graceLine.setMap(null);

      setGraceLine(
        new maps.Polyline({
          path: [{ ...getEastPoint(center) }, { ...getEastPoint(center, graceCircle) }],
          geodesic: true,
          icons: [
            {
              icon: dashedLine,
              offset: "15px",
              repeat: "10px",
            },
          ],
          strokeOpacity: 0,
          map,
        })
      );
    }
  }, [areaCircle]);

  useEffect(() => {
    if (!marker || !radius || !areaCircle) return;
    if (radiusInfo) radiusInfo.setMap(null);

    setRadiusInfo(
      new marker.Marker({
        position: getInfoPosition(center),
        map,
        icon: "../res/img/empty.png",
        label: getLabel(radius),
        zIndex: 1,
      })
    );

    if (graceInfo) graceInfo.setMap(null);

    setGraceInfo(
      new marker.Marker({
        position: getInfoPosition(getEastPoint(center), graceCircle),
        map,
        icon: "../res/img/empty.png",
        label: getLabel(graceDistance),
        zIndex: 1,
      })
    );
  }, [marker, areaCircle]);

  const onAvatarIn = (ros) => {
    if (setCurrentApp) {
      setCurrentApp(ros);
      setInfoOpen(true);
    }
  };

  const onAvatarOut = () => {
    setInfoOpen(false);
    if (setCurrentApp) setCurrentApp({});
  };

  const checkVisibility = (ros) => {
    let bool;
    const isClocked = !!ros.clockInCoordinates || !!ros.clockOutCoordinates;
    switch (filter) {
      case "All": {
        bool = true;
        break;
      }
      case "Clocked In": {
        bool = !!ros.timeIn;
        break;
      }
      case "Clocked Out": {
        bool = !!ros.timeOut;
        break;
      }
      case "Outside Geofence": {
        const distance = geometry?.spherical?.computeDistanceBetween(center, getPosition(ros));
        bool = distance >= radius + graceDistance;
        break;
      }
      default:
        break;
    }

    return bool && isClocked;
  };

  return (
    <Box
      sx={{ height: "50vh" }}
      onClick={(e) => {
        e.preventDefault();
        onMapButtonClick();
      }}
    >
      <Map
        disableDefaultUI={disableControls}
        gestureHandling={disableControls ? "none" : "auto"}
        clickableIcons={false}
        defaultZoom={17}
        defaultCenter={initialCenter}
        onClick={handleMapClick}
      >
        <Marker
          draggable={isCenterEditable}
          position={center}
          onClick={() => {
            if (!isCenterEditable) onMarkerClick();
          }}
          onDrag={handleMarkerDrag}
          onDragEnd={handleMarkerDragEnd}
          zIndex={1}
        />
        {!isEmpty(filteredRoster) && (
          <RosterMarkers
            map={map}
            roster={filteredRoster}
            currentApp={currentApp}
            onAvatarIn={onAvatarIn}
            onAvatarOut={onAvatarOut}
            getPosition={getPosition}
            checkVisibility={checkVisibility}
            isInfoClicked={isInfoClicked}
            setInfoClicked={setInfoClicked}
          />
        )}
        {isInfoOpen && (
          <InfoWindow
            position={getPosition(currentApp)}
            onCloseClick={() => {
              setInfoClicked(false);
              onAvatarOut();
            }}
            pixelOffset={new core.Size(0, -100)}
          >
            <RosterInformation currentApp={currentApp} />
          </InfoWindow>
        )}
      </Map>
    </Box>
  );
};

export default CustomMap;
