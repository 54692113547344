import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, Icon } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAppContextController } from "context/AppContext";
import CustomCard from "components/CustomCard";
import logo from "assets/images/SP-App-Store-Icon.png";
import fetchCustomers from "api/companies/fetchCustomers";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import InterviewForm from "./InterviewForm";

const StyledImageBox = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  objectFit: "contain",
}));

const iconCreateContainerStyle = {
  backgroundColor: "success",
  color: "white",
};

const iconEditContainerStyle = {
  backgroundColor: "transparent",
  color: "transparent",
};

const cardStlyle = {
  width: "100%",
  overflow: "visible !important",
  mt: "50px",
};

function InterviewEditor({ setInterviewPreview, setOpen, setPreviewContent }) {
  const { currentEvent: event, company: comp } = useAppContextController();
  const { createVenueSlug } = useParams();
  const [interviewLogo, setInterviewLogo] = useState("");
  const [copyInterviewName, setCopyInterviewName] = useState("");
  const isEdit = !!event?._id;

  const options = { fetchAll: true };
  const allOptions = { ...options, filters: { primaryCompany: false } };

  const {
    data: companies,
    isLoading,
    isSuccess,
  } = useQuery(["companies", allOptions], () => fetchCustomers(allOptions), {
    refetchInterval: 300000,
  });

  const getCompanyField = (slug, field) => {
    if (!isLoading && isSuccess && companies && companies[slug]) {
      const { data } = companies;
      const companySlug = data.find((company) => company.slug === slug);
      return companySlug[field];
    }
    return null;
  };

  const createEventSuffix = () => {
    let suffix = "";
    if (copyInterviewName) suffix = ` from ${copyInterviewName}`;
    if (createVenueSlug)
      suffix = ` at ${
        getCompanyField(createVenueSlug, "name") ? getCompanyField(createVenueSlug, "name") : ""
      }`;
    return suffix;
  };

  let logoComponent = <Icon fontSize="large">event-seat</Icon>;
  if (isEdit) {
    if (event?.logoUrl) {
      logoComponent = <StyledImageBox component="img" alt="Company Logo" src={event?.logoUrl} />;
    } else {
      logoComponent = <CompanyLogoSquare company={event} defaultCompany={comp} />;
    }
  }

  return (
    <>
      <CustomCard
        icon={logoComponent}
        cardTitle={
          isEdit
            ? `Interview Information: ${event?.eventName}`
            : `Create Interview ${createEventSuffix()}`
        }
        iconContainerSx={isEdit ? iconEditContainerStyle : iconCreateContainerStyle}
        cardSx={cardStlyle}
        // cardActions={}
      >
        <InterviewForm
          getCompanyField={getCompanyField}
          setInterviewPreview={setInterviewPreview}
          setOpen={setOpen}
          setPreviewContent={setPreviewContent}
          setInterviewLogo={setInterviewLogo}
          setCopyInterviewName={setCopyInterviewName}
        />

        {/* {JSON.stringify(currentEvent)} */}
        {/* <UploadFileModal
                        currentEvent={currentEvent}
                        attachmentOpen={attachmentOpen}
                        setAttachmentOpen={setAttachmentOpen}
                    /> */}
      </CustomCard>
    </>
  );
}
export default InterviewEditor;
