import { isEqual } from "lodash";
import { useState } from "react";

import { Card, styled } from "@mui/material";

import { useSnackbar } from "notistack";

import FileDropzone from "components/Dropzone";

import uploadFileJob from "api/jobs/uploadFileJob";
import uploadFileCompanyJob from "api/jobs/uploadFileCompanyJob";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import updateCompanyJob from "../../actions/updateJob";

const initialAttachment = { file: null };

const UploadFileModal = ({
  isOpen,
  setIsOpen,
  label,
  attachmentTypes,
  typeOfUploader,
  isVenue = false,
}) => {
  const { company, setCompany, currentJob, setCurrentJob } = useAppContextController();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [attachmentDetails, setAttachmentDetails] = useState(initialAttachment);
  const { mutateAsync: uploadFileMutation } = useMutation(
    isVenue ? uploadFileJob : uploadFileCompanyJob
  );
  const { mutateAsync: updateJobMutation } = useMutation(isVenue ? updateJob : updateCompanyJob);

  const uploaderId = isEqual(typeOfUploader, "jobs") ? currentJob?._id : "";
  const slug = isEqual(typeOfUploader, "jobs") ? currentJob?.jobSlug : "";
  const title = isVenue ? "" : "Company ";
  const idName = isVenue ? "_id" : "jobId";

  const uploadFileHandler = async (form, filePath, fileExt) => {
    const newAttachment = {
      docType: fileExt,
      filename: filePath,
      uploadDate: new Date(),
    };

    if (isEqual(typeOfUploader, "jobs")) {
      const newAttachmentList = currentJob?.attachments ? [...currentJob?.attachments] : [];
      newAttachmentList.push(newAttachment);

      const updatedCompanyJob = { ...currentJob, attachments: newAttachmentList };

      uploadFileMutation(
        { jobSlug: slug, uploadForm: form, ...(isVenue && { venueSlug: currentJob?.venueSlug }) },
        {
          onSuccess: async (res) => {
            await queryClient.invalidateQueries("company_jobs_table");
            enqueueSnackbar(`${title}Job File has been uploaded.`, { variant: "success" });
          },
          onError: (err) => console.log(err),
        }
      );

      updateJobMutation(
        { [idName]: currentJob?._id, data: updatedCompanyJob },
        {
          onSuccess: async (res) => {
            await queryClient.invalidateQueries("company_jobs_table");
            setCurrentJob({ ...currentJob, ...updatedCompanyJob });
            enqueueSnackbar(`${title}Job has been updated.`, { variant: "success" });
          },
          onError: (err) => console.log(err),
        }
      );
    }
  };

  const onFileDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles.length <= 0) return;

    const formUpload = new FormData();
    const uploadedFile = acceptedFiles[0];
    const uploadedFileExtension = uploadedFile?.path?.split(".").pop();

    formUpload.append("file", uploadedFile);

    if (uploaderId) uploadFileHandler(formUpload, uploadedFile.path, uploadedFileExtension);

    setAttachmentDetails(initialAttachment);
  };

  return (
    <>
      <FileDropzone message="Drop files here to upload" onDrop={onFileDropHandler} />
    </>
  );
};

const StyledContainerModal = styled(Card)(({ theme }) => ({}));

export default UploadFileModal;
