import useCompanyStore from "stores/useCompanyStore";
import updateCompany from "api/companies/updateCompany";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import SalesPanel from "components/SalesPanel/SalesPanel";

const CustomerSales = () => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const { setCurrentFormDirty } = useAppContextController();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);

  const handleUpdateCompany = (updatedSales, message, variant = "success") => {
    updateCompanyMutation(
      { companyId: currentCompany?._id, body: updatedSales },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("companies");
          setCurrentCompany({ ...currentCompany, ...updatedSales });
          setCurrentFormDirty(false);
          enqueueSnackbar(message || "Customer Sales has been updated.", { variant });
        },
        onError: (err) => enqueueSnackbar(`An error occured: ${err}`, { variant: "error" }),
      }
    );
  };

  return <SalesPanel currentData={currentCompany} onUpdate={handleUpdateCompany} />;
};

export default CustomerSales;
