import { useAppContextController } from "context/AppContext";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";

const ConfirmPanelChanges = ({ onConfirm }) => {
  const { setCurrentFormDirty, changePanel, isPanelChangesOpen, setPanelChangesOpen } =
    useAppContextController();
  const handleCloseModal = () => setPanelChangesOpen(false);

  return (
    <ConfirmModal
      title="There are changes waiting to be saved"
      description="Do you want to save these changes?"
      negativeBtn={{
        label: "No",
        fn: () => {
          setCurrentFormDirty(false);
          handleCloseModal();
          changePanel();
        },
        isShown: true,
        isOpen: true,
      }}
      positiveBtn={{
        label: "Yes",
        fn: async () => {
          onConfirm();
          handleCloseModal();
        },
        isShown: true,
      }}
      isOpen={isPanelChangesOpen}
      closeFn={handleCloseModal}
    />
  );
};

export default ConfirmPanelChanges;
