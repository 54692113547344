import * as yup from "yup";

const issueSchema = (issuesCategories, issuesTypes, issuesStatuses) => {
  return yup.object().shape({
    issueTitle: yup.string().required("Issue Title is Required"),
    category: yup
      .string()
      .required("Issue Category is Required")
      .test({
        name: "validate-category",
        test: (value, ctx) => {
          if (!issuesCategories?.includes(value))
            return ctx.createError({
              message: value ? "Insert a valid category" : "Issue Category is Required",
            });
          return true;
        },
      }),
    type: yup
      .string()
      .required("Issue Type is Required")
      .test({
        name: "validate-type",
        test: (value, ctx) => {
          if (!issuesTypes?.includes(value))
            return ctx.createError({
              message: value ? "Insert a valid type" : "Issue Type is Required",
            });
          return true;
        },
      }),
    status: yup
      .string()
      .required("Issue Status is Required")
      .test({
        name: "validate-status",
        test: (value, ctx) => {
          if (!issuesStatuses?.includes(value))
            return ctx.createError({
              message: value ? "Insert a valid status" : "Issue Type is Required",
            });
          return true;
        },
      }),
    issueDescription: yup.string().test({
      name: "validate-description",
      test: (value, ctx) => {
        const isEmpty =
          !value ||
          value?.trim() === "" ||
          value?.trim() === "<p><br></p>" ||
          value?.trim() === "<div><br></div>";
        if (isEmpty) return ctx.createError({ message: "Description is required" });
        return true;
      },
    }),
  });
};

export default issueSchema;
