import * as yup from "yup";

import interviewStatuses from "assets/interviewStatuses.json";
import interviewTypes from "assets/interviewTypes.json";

export const interviewInfoSchema = yup.object().shape({
    eventName: yup.string().nullable().required("Interview name is required"),
    eventType: yup
        .string()
        .default(interviewTypes[1])
        .required("Interview type is required")
        .test("validInterviewType", "Insert a valid Interview Type", (value) => {
            if (value) {
                interviewTypes.includes(value);
            }
            return true;
        }),
    logoUrl: yup.string(),
    companySlug: yup.string().nullable().required("Company slug is required"),
    companyName: yup.string().nullable().required("Company name is required"),
    interviewLocation: yup.mixed(),
    address: yup.string(),
    venueCity: yup.string(),
    venueState: yup.string().nullable(),
    zip: yup.string(),
    eventId: yup.string(),
    eventUrl: yup.string().required("Interview URL is required"),
    // status: yup
    //     .string()
    //     .default(interviewStatuses[0])
    //     .required("Status is required")
    //     .test("validInterviewType", "Insert a valid status", (value) => {
    //         if (value) {
    //             interviewStatuses.includes(value);
    //         }
    //         return true;
    //     }),
    eventDate: yup.string().nullable().required("Interview Date is required"),
    eventEndTime: yup.string()
        .nullable()
        .required("Est. End Time is required")
        .test({
            name: "validEndTime",
            test: (value, ctx) => {
                if (new Date(value).getTime() > new Date(ctx.parent.eventDate).getTime()) {
                    return true;
                }
                return ctx.createError({ message: "End Time must be later than start time" });
            }
        }),
    reportTimeTBD: yup.string(),
    positionsRequested: yup.string(),
    eventLogo: yup.string(),
    jobLogo: yup.string(),
    googleMap: yup.string(),
    description: yup.string(),
    attendees: yup.mixed(),
});

export const interviewInfoModalSchema = yup.object().shape({
    eventName: yup.string().nullable().required("Interview name is required"),
    eventType: yup
        .string()
        .default(interviewTypes[1])
        .required("Interview type is required")
        .test("validInterviewType", "Insert a valid Interview Type", (value) => {
            if (value) {
                interviewTypes.includes(value);
            }
            return true;
        }),
    logoUrl: yup.string(),
    interviewLocation: yup.mixed(),
    address: yup.string(),
    venueCity: yup.string(),
    venueState: yup.string().nullable(),
    zip: yup.string(),
    eventId: yup.string(),
    eventDate: yup.string().nullable().required("Interview Date is required"),
    eventEndTime: yup.string()
        .nullable()
        .required("Est. End Time is required")
        .test({
            name: "validEndTime",
            test: (value, ctx) => {
                if (new Date(value).getTime() > new Date(ctx.parent.eventDate).getTime()) {
                    return true;
                }
                return ctx.createError({ message: "End Time must be later than start time" });
            }
        }),
    reportTimeTBD: yup.string(),
    positionsRequested: yup.string(),
    eventLogo: yup.string(),
    jobLogo: yup.string(),
    googleMap: yup.string(),
    description: yup.string(),
    attendees: yup.mixed(),
});
