import { useEffect, useState, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styled, Divider, IconButton, Grid, Icon, TextField, Tooltip, Box } from "@mui/material";
import {
  AddRounded as AddRoundedIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";

import updateCompany from "api/companies/updateCompany";
import renameFileCompany from "api/companies/renameFileCompany";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import useCompanyCache from "hooks/useCompanyCache";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import ImagePreviewModal from "../ImagePreviewModal";

const Attachments = ({ isShownLabel = true, company, setCompany }) => {
  const { currentCompany, setCompanyType } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const { mutateAsync: renameFileMutation } = useMutation(renameFileCompany);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const updateCompanyHandler = async (values, action, fileName) => {
    if (!company?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: company._id, body: values },
        {
          onSuccess: async (res) => {
            const actionStatus = action === "DELETE" ? "removed" : "renamed";
            setCompany(values);
            enqueueSnackbar(`Company Attachment file ${fileName} has been ${actionStatus}.`, {
              variant: "warning",
            });
            await queryClient.invalidateQueries("companies");
          },
        }
      );
    }
  };

  const renameCompanyHandler = async (renameVal, originalVal) => {
    if (!company?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      renameFileMutation(
        {
          rename: renameVal,
          original: originalVal,
          companySlug: company?.slug,
        },
        {
          onError: (err) =>
            enqueueSnackbar(err.toString(), { variant: "error", autoHideDuration: 3000 }),
        }
      );
    }
  };

  const deleteFileHandler = async (e, idx, fileName) => {
    const filteredFiles = files.filter((file, index) => index !== idx);
    await updateCompanyHandler({ ...company, attachments: filteredFiles }, "DELETE", fileName);
    setFiles(filteredFiles);
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];
    // // get the image extension
    const imgExt = newFiles[idx].filename.substring(newFiles[idx].filename.lastIndexOf("."));
    // // check if edited
    const removeExt = newFiles[idx].filename.substring(0, newFiles[idx].filename.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;
    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].filename;
    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].filename;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );

  const confirmFileNameChanges = async (e, idx) => {
    await renameCompanyHandler(files[idx].editedName, files[idx].filename);
    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], filename: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);
    await updateCompanyHandler(
      { ...company, attachments: newFiles },
      "UPDATE",
      newFiles[idx].filename
    );
  };

  useEffect(() => {
    if (company?.attachments?.length > 0) setFiles(company.attachments);
    else setFiles([]);
  }, [company?.attachments]);

  return (
    <>
      <MDBox width="100%">
        {isShownLabel && (
          <>
            <StyledDivider />
            <FlexWrapper justifyContent="start" width="100%">
              <MDTypography
                variant="body2"
                fontWeight="bold"
                color="info"
                fontSize={14}
                width="100%"
              >
                ADDITIONAL ATTACHMENTS
              </MDTypography>
            </FlexWrapper>
          </>
        )}

        <StyledImageContainer>
          {files.map((file, idx) => {
            const fileNameWithoutType = file?.filename?.substring(
              0,
              file?.filename?.lastIndexOf(".")
            );
            return (
              <StyledImageBox key={file.fileName}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <StyledRemoveBtn
                    color="info"
                    onClick={(e) => deleteFileHandler(e, idx, fileNameWithoutType)}
                  >
                    <Icon fontSize="small">cancel</Icon>
                  </StyledRemoveBtn>
                  <Grid>
                    {["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg"].includes(
                      file?.docType?.toLowerCase()
                    ) && (
                      <MDBox
                        onClick={() => {
                          setPreviewFile(file);
                          setImagePreviewOpen(true);
                        }}
                      >
                        <img
                          src={`${company?.imageUrl}/${company?.slug}/company/other/${file?.filename}`}
                          alt={file.filename}
                          height={100}
                          width={100}
                        />
                      </MDBox>
                    )}
                    {file?.docType?.toLowerCase() === "pdf" && (
                      <MDBox
                        onClick={() => {
                          window.open(
                            `${company?.imageUrl}/${company?.slug}/company/other/${file?.filename}`
                          );
                        }}
                      >
                        <StyledImageDocs
                          component="img"
                          src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                          alt="preview"
                        />
                      </MDBox>
                    )}
                    {file?.docType?.toLowerCase() === "docx" && (
                      <MDBox
                        onClick={() => {
                          window.open(
                            `${company?.imageUrl}/${company?.slug}/company/other/${file?.filename}`
                          );
                        }}
                      >
                        <StyledImageDocs
                          component="img"
                          src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                          alt="preview"
                        />
                      </MDBox>
                    )}
                  </Grid>
                  <MDBox display="flex">
                    <TextField
                      size="small"
                      defaultValue={fileNameWithoutType}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={files[idx].editedName?.substring(
                        0,
                        file?.editedName?.lastIndexOf(".")
                      )}
                      onInput={(e) => editFileNameHandler(e, idx)}
                    />
                    {file?.isEdited && (
                      <>
                        <Tooltip title="Confirm" placement="bottom">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={(e) => confirmFileNameChanges(e, idx)}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel" placement="bottom">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={(e) => cancelFileNameChanges(e, idx)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </MDBox>
                </Grid>
              </StyledImageBox>
            );
          })}
        </StyledImageContainer>
      </MDBox>
      {imagePreviewOpen && (
        <ImagePreviewModal
          imagePreviewOpen={imagePreviewOpen}
          setImagePreviewOpen={setImagePreviewOpen}
          currentEvent={company}
          previewFile={previewFile}
          typeOfPreview="company"
        />
      )}
    </>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  opacity: 1,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  margin: "2rem 0 0.5rem 0",
}));

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: 1,
  overflowX: "auto",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  padding: "1rem 0",
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  margin: "0px 8px",
  cursor: "pointer",
}));

const StyledRemoveBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "-15px",
  top: "-15px",
  backgroundColor: "white",
}));

const StyledImageDocs = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
}));

const StyledImage = styled(Box)(({ theme }) => ({
  width: "6rem",
  height: "100%",
}));

export default Attachments;
