import { useCallback, useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player";

import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

// @mui material components
import { Box, Button, Grid, Icon, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { Add as AddIcon, Check as CheckIcon, Close as CloseIcon, Image } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FileDropzone from "components/Dropzone";
import CustomCard from "components/CustomCard";

import { baseAxios } from "config";

// Styling
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import renameFileVenue from "layouts/pages/venues/actions/renameFileVenue";
// import deleteImage from "layouts/pages/venues/actions/deleteImage"; not working
import { cardStyles } from "layouts/pages/venues/styles";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import useStyles from "./styles";

// from VenueMediaGallery
const VenueMedia = () => {
  const styles = useStyles();
  const { currentVenue, setCurrentVenue, company, setVenues } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;

  const uploadUrl = `/upload/${currentVenue?.slug}/venues/banner`;

  const [urlInputs, setUrlInputs] = useState({
    others: "",
    video: "",
  });

  const [images, setImages] = useState([]);

  const [otherFiles, setOtherFiles] = useState([]);

  const getLastNode = (path) => {
    const pathArr = path.split("/");
    if (pathArr?.length) return pathArr[pathArr.length - 1];
    return path;
  };

  // on page load render current images from DB
  useEffect(() => {
    setImages(
      currentVenue?.imageUrls?.map((img) => ({
        name: img?.startsWith("http") ? getLastNode(img) : img,
        editedName: img?.startsWith("http") ? getLastNode(img) : img,
        isEdited: false,
      })) ?? []
    );
  }, [currentVenue?.imageUrls]);

  // on page load render current venues from DB
  useEffect(() => {
    setOtherFiles(
      currentVenue?.otherUrls?.map((img) => ({
        name: img?.startsWith("http") ? getLastNode(img) : img,
        editedName: img?.startsWith("http") ? getLastNode(img) : img,
        isEdited: false,
      })) ?? []
    );
  }, [currentVenue?.otherUrls]);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: updateVenueMutation } = useMutation(updateVenue);

  const handleUpdateVenue = async (data, onSuccess = () => null) => {
    if (!currentVenue?._id) {
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
      return null;
    }
    return updateVenueMutation(
      { venueId: currentVenue?._id, data },
      {
        onSuccess: async (res) => {
          onSuccess();
          setCurrentVenue({ ...currentVenue, ...data });
          setVenues({ [currentVenue.slug]: { ...currentVenue, ...data } });
          enqueueSnackbar("Media has been updated.", { variant: "success" });
          await queryClient.invalidateQueries(["venues"]);
          await queryClient.invalidateQueries(["myvenues"]);
        },
        onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
      }
    );
  };

  const { mutateAsync: renameImageMutation } = useMutation(
    async ({ rename, original }) => {
      const url = `/upload/rename/${currentVenue?.slug}/venues/banner?rename=${encodeURIComponent(
        rename
      )}&original=${encodeURIComponent(original)}`;
      await baseAxios.patch(url);
    },
    {
      onError: (error) => enqueueSnackbar(error.toString(), { variant: "error" }),
      onSuccess: () => {
        enqueueSnackbar("Successfully renamed", { variant: "success" });
      },
    }
  );

  const { mutateAsync: renameFileMutation } = useMutation(renameFileVenue);

  const handleRenameFile = async (rename, original) => {
    if (!currentVenue?.slug) {
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
      return null;
    }
    return renameFileMutation(
      { rename, original, venueSlug: currentVenue?.slug },
      {
        onError: (error) => enqueueSnackbar(error.toString(), { variant: "error" }),
        onSuccess: () => {
          enqueueSnackbar("Successfully renamed", { variant: "success" });
        },
      }
    );
  };

  const saveUpdate = async (values) => {
    if (!currentVenue._id) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      return;
    }
    handleUpdateVenue(values);
  };

  const onDropImages = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, { variant: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);

      await baseAxios.post(uploadUrl, form);

      const newImages =
        images.length !== 0
          ? Array.from(
              new Set([...images, { name: file.name, isEdited: false, editedName: file.name }])
            )
          : [{ name: file.name, isEdited: false, editedName: file.name }];
      setImages(newImages);

      // save img names to DB
      await saveUpdate({ imageUrls: newImages.map((img) => img.name) });
    }
  };

  const onDropOthers = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const form = new FormData();
    form.append("file", file);

    await baseAxios.post(`/upload/${currentVenue?.slug}/venues/other`, form);

    const newFiles =
      otherFiles && otherFiles.length !== 0
        ? Array.from(
            new Set([...otherFiles, { name: file.name, isEdited: false, editedName: file.name }])
          )
        : [{ name: file.name, isEdited: false, editedName: file.name }];
    setOtherFiles(newFiles);

    await saveUpdate({ otherUrls: newFiles.map((img) => img.name) });
  };

  const deleteImageHandler = async (imageId) => {
    const filteredImages = images.filter((file, index) => index !== imageId);

    if (images[imageId]?.name === getLastNode(currentVenue?.bannerUrl)) {
      handleUpdateVenue({ bannerUrl: "" });
    }

    handleUpdateVenue(
      {
        imageUrls: filteredImages.map((img) => img.name),
      },
      () => setImages(filteredImages)
    );
  };

  const handleDeleteOtherFiles = async (e, idx) => {
    const filteredFiles = otherFiles.filter((file, index) => index !== idx);

    handleUpdateVenue({ otherUrls: filteredFiles.map((img) => img.name) }, () =>
      setOtherFiles(filteredFiles)
    );
  };

  const addVideoHandler = async (url) => {
    // check if url is in videos list
    const check = (elem) => elem === url;
    if (currentVenue.videoUrls?.length && currentVenue.videoUrls.some(check)) {
      enqueueSnackbar("Can't have Duplicate Videos", { variant: "error" });
      return;
    }

    // update database
    handleUpdateVenue({
      videoUrls: currentVenue.videoUrls ? [...currentVenue?.videoUrls, url] : [url],
    });
  };

  const deleteVideoHandler = async (e, idx) => {
    const video = [...currentVenue?.videoUrls];

    if (idx !== -1) {
      video.splice(idx, 1);
    }

    // delete from DB
    handleUpdateVenue({ videoUrls: video });
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...otherFiles];

    // get the image extension
    const imgExt = newFiles[idx].name.substring(newFiles[idx].name.lastIndexOf("."));

    // check if edited
    const removeExt = newFiles[idx].name.substring(0, newFiles[idx].name.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;

    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].name;

    // set the new images state
    setOtherFiles(newFiles);
  };

  const editImgNameHandler = useCallback(
    (e, idx) => {
      const newImages = [...images];

      // get the image extension
      const imgExt = newImages[idx].name.substring(newImages[idx].name.lastIndexOf("."));

      // check if edited
      const removeExt = newImages[idx].name.substring(0, newImages[idx].name.lastIndexOf("."));
      newImages[idx].isEdited = removeExt !== e.target.value;

      // set the edited name value
      newImages[idx].editedName = newImages[idx].isEdited
        ? e.target.value + imgExt
        : newImages[idx].name;

      // set the new images state
      setImages(newImages);
    },
    [images]
  );

  const cancelImageNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...images];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setImages(newFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images]
  );

  const confirmImageNameChanges = async (e, idx) => {
    await renameImageMutation({
      original: images[idx].name,
      rename: images[idx].editedName,
    });

    const newImages = [...images];
    newImages[idx] = { ...newImages[idx], name: newImages[idx].editedName, isEdited: false };
    setImages(newImages);

    handleUpdateVenue({ imageUrls: newImages.map((img) => img.name) });
  };

  const confirmFileNameChanges = async (e, idx) => {
    await handleRenameFile(otherFiles[idx].editedName, otherFiles[idx].name);

    const newFiles = [...otherFiles];
    newFiles[idx] = { ...newFiles[idx], name: newFiles[idx].editedName, isEdited: false };
    setOtherFiles(newFiles);

    handleUpdateVenue({ otherUrls: newFiles.map((img) => img.name) });
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...otherFiles];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setOtherFiles(newFiles);
    },
    [otherFiles]
  );

  return (
    currentVenue && (
      <CustomCard
        icon={<Image color="white" />}
        iconContainerSx={{ backgroundColor: "gray", mt: -3, ml: "1.5rem" }}
        cardTitle={`Media Gallery: ${currentVenue?.name}`}
        cardSx={cardStyles.card}
        titleSx={cardStyles.title}
      >
        <Box sx={cardStyles.container}>
          <MDBox>
            <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
              <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
                Venue Media Gallery
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={12}>
                <FileDropzone
                  accept={{
                    "image/*": [],
                  }}
                  multiple={false}
                  onDrop={onDropImages}
                  // restrict={{ "image/*": [] }}
                  maxSize={1048576}
                  size="8rem"
                />
              </Grid>
              <Grid item sm={12} mt={-2}>
                <Stack direction="row" spacing={3} className={styles.section_images_wrapper}>
                  {images.map((image, idx) => (
                    <MDBox
                      className={styles.section_image_container}
                      key={`${image.name}_venue_media_gallery_${idx.toString()}`}
                    >
                      <IconButton
                        color="info"
                        className={styles.section_image_delete}
                        onClick={() => deleteImageHandler(idx)}
                      >
                        <Icon fontSize="small">cancel</Icon>
                      </IconButton>
                      <Stack>
                        <MDBox style={{ height: "100px", width: "100%" }} mb={2}>
                          {IMAGE_SERVER && (
                            <img
                              src={
                                image.name.startsWith("http")
                                  ? image.name
                                  : `${IMAGE_SERVER}/${currentVenue?.slug}/venues/banner/${image.name}`
                              }
                              alt="previews"
                              className={styles.section_image}
                            />
                          )}
                        </MDBox>
                        <TextField
                          variant="outlined"
                          placeholder="File name"
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                          // defaultValue={image.name.substring(0, image.name.lastIndexOf("."))}
                          value={images[idx].editedName?.substring(
                            0,
                            image.editedName.lastIndexOf(".")
                          )}
                          onInput={(e) => editImgNameHandler(e, idx)}
                        />
                        {image.isEdited && (
                          <MDBox sx={{ alignSelf: "center" }}>
                            <Tooltip title="Confirm" placement="bottom">
                              <CheckIcon
                                color="success"
                                onClick={(e) => confirmImageNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Cancel" placement="bottom">
                              <CloseIcon
                                onClick={(e) => cancelImageNameChanges(e, idx)}
                                color="error"
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                          </MDBox>
                        )}
                      </Stack>
                    </MDBox>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox className={styles.otherSection}>
            <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
              <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
                Venue Maps and Policies
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={12}>
                <MDBox mb={2}>
                  <FileDropzone
                    multiple={false}
                    onDrop={onDropOthers}
                    maxSize={1048576}
                    size="8rem"
                  />
                </MDBox>
              </Grid>
              <Grid item sm={12} mt={-3}>
                <Stack direction="row" spacing={3} className={styles.section_images_wrapper}>
                  {otherFiles.map((file, idx) => (
                    <MDBox
                      className={styles.section_image_container}
                      key={`${file.name}_venue_media_policies_${idx.toString()}`}
                    >
                      <IconButton
                        color="info"
                        className={styles.maps_section_image_delete}
                        onClick={(e) => handleDeleteOtherFiles(e, idx)}
                      >
                        <Icon fontSize="small">cancel</Icon>
                      </IconButton>

                      <Stack alignItems="center">
                        <MDBox
                          onClick={() => {
                            if (IMAGE_SERVER && file?.name)
                              window.open(
                                `${IMAGE_SERVER}/${currentVenue?.slug}/venues/other/${file.name}`
                              );
                          }}
                        >
                          {IMAGE_SERVER &&
                          ["png", "jpg", "jpeg", "bmp", "jfif", "pjpeg", "pjp"].includes(
                            file?.name?.split(".").pop().toLowerCase()
                          ) ? (
                            <img
                              src={
                                file.name.startsWith("http")
                                  ? file.name
                                  : `${IMAGE_SERVER}/${currentVenue?.slug}/venues/other/${file.name}`
                              }
                              alt="previews"
                              width={100}
                              height={100}
                              className={styles.image_fit}
                            />
                          ) : (
                            <>
                              {!!company?.imageUrl && !!company?.uploadUrl && (
                                <img
                                  src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                                  alt="preview"
                                  width={100}
                                  height={100}
                                />
                              )}
                            </>
                          )}
                        </MDBox>

                        <TextField
                          // defaultValue={file.name.substring(0, file.name.lastIndexOf("."))}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                          value={otherFiles[idx].editedName?.substring(
                            0,
                            file.editedName.lastIndexOf(".")
                          )}
                          onInput={(e) => editFileNameHandler(e, idx)}
                        />

                        {file.isEdited && (
                          <MDBox>
                            <Tooltip title="Confirm" placement="bottom">
                              <CheckIcon
                                color="success"
                                onClick={(e) => confirmFileNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Cancel" placement="bottom">
                              <CloseIcon
                                color="error"
                                onClick={(e) => cancelFileNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                          </MDBox>
                        )}
                      </Stack>
                    </MDBox>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox className={styles.otherSection}>
            <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
              <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
                Training Videos
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={12}>
                <MDBox>
                  <TextField
                    variant="outlined"
                    placeholder="Add URL here"
                    inputProps={{ min: 0, style: { textAlign: "center", height: "32px" } }}
                    value={urlInputs.video}
                    onChange={(e) => setUrlInputs({ ...urlInputs, video: e.target.value })}
                    fullWidth
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" justifyContent="center" mt={1}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    style={{ color: "white" }}
                    disabled={urlInputs.video === ""}
                    onClick={(e) => {
                      addVideoHandler(urlInputs.video);
                      setUrlInputs({
                        others: "",
                        video: "",
                      });
                    }}
                  >
                    Add a video
                  </Button>
                </MDBox>
              </Grid>
              <Grid item sm={12}>
                <MDBox className={styles.section_images_wrapper}>
                  {currentVenue?.videoUrls && currentVenue?.videoUrls.length > 0 ? (
                    currentVenue?.videoUrls.map((vid, idx) => (
                      <MDBox
                        className={styles.training_section_image_container}
                        key={`${vid}_venue_video_${idx.toString()}`}
                      >
                        <MDBox className={styles.positioner} key={vid}>
                          <IconButton
                            color="info"
                            className={styles.training_section_image_delete}
                            onClick={(e) => deleteVideoHandler(e, idx)}
                          >
                            <Icon fontSize="small">cancel</Icon>
                          </IconButton>
                        </MDBox>
                        {vid.toLowerCase().indexOf("vimeo.com") > -1 ? (
                          <Vimeo
                            video={vid
                              .toLowerCase()
                              .replace("https://vimeo.com/manage/videos/", "")}
                            showTitle
                            width="320px"
                            height="180px"
                          />
                        ) : (
                          <ReactPlayer url={vid} width="320px" height="180px" />
                        )}
                      </MDBox>
                    ))
                  ) : (
                    <MDTypography variant="p" color="dark" className={styles.titleBar_title} sx>
                      Please add video
                    </MDTypography>
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Box>
      </CustomCard>
    )
  );
};

export default VenueMedia;
