/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import useCompanyStore from "stores/useCompanyStore";
import updateCompany from "api/companies/updateCompany";
import CustomContentPanel from "components/CustomContentPanel";

const CustomerCustomContent = () => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);

  const handleUpdateCompany = (values, method = "update", onSuccess) => {
    updateCompanyMutation(
      { companyId: currentCompany?._id, body: values },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("companies");
          setCurrentCompany({ ...currentCompany, ...values });
          onSuccess(method, values?.content);
          enqueueSnackbar("Customer has been updated.", { variant: "success" });
        },
        onError: (err) => enqueueSnackbar(`An error occured: ${err}`, { variant: "error" }),
      }
    );
  };

  return <CustomContentPanel currentData={currentCompany} onUpdate={handleUpdateCompany} />;
};

export default CustomerCustomContent;
