import { Phone } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";

const ApplicantPhoneButton = ({ applicant }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Tooltip
      title={applicant?.phone ? `Phone number: ${applicant?.phone}` : "Phone number not available"}
    >
      <span>
        <IconButton
          sx={{ paddingLeft: 0, paddingRight: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(applicant?.phone);
            enqueueSnackbar("Phone number copied to clipboard", { variant: "success" });
          }}
          disabled={!applicant?.phone}
        >
          <Phone fontSize="large" color={applicant?.phone ? "violet" : "secondary"} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ApplicantPhoneButton;
