import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import useBadgeValues from "hooks/useBadgeValues";
import FilterCardBar from "components/FilterCardBar";
import PublicIcon from "@mui/icons-material/Public";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StadiumIcon from "@mui/icons-material/Stadium";
import MessageIcon from "@mui/icons-material/Message";
import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import BusinessIcon from "@mui/icons-material/Business";
import { LeakAdd, SettingsInputComponent } from "@mui/icons-material";
// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

const ActivitiesFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentActivity, currentLoggedUser, company } = useAppContextController();

  const initialCards = useMemo(
    () => [
      {
        color: "dark",
        icon: <PublicIcon fontSize="large" color="black" />,
        title: "All",
        tooltip: "All Activities",
        number: 0,
        filterField: "status",
        filterValue: "",
      },
      {
        color: "success",
        icon: <LoginIcon fontSize="large" color="success" />,
        title: "Login",
        tooltip: "Users Logging In",
        number: 0,
        filterField: "type",
        filterValue: "Login",
        canCombine: true,
      },
      {
        color: "warning",
        icon: <PersonAddIcon fontSize="large" color="warning" />,
        title: "Applicant",
        tooltip: "Activities related to Applicants",
        number: 0,
        filterField: "type",
        filterValue: "Applicant",
        canCombine: true,
      },
      {
        color: "success",
        icon: <CalendarMonthIcon fontSize="large" color="success" />,
        title: "Event",
        tooltip: "Activities related to Events",
        number: 0,
        filterField: "type",
        filterValue: "Event",
        canCombine: true,
      },
      ...[
        company?.companyType === "Company"
          ? {
              color: "info",
              icon: <BusinessIcon fontSize="large" color="info" />,
              title: "Customer",
              tooltip: "Activities related to Customers",
              number: 0,
              filterField: "type",
              filterValue: "Company",
              canCombine: true,
            }
          : {
              color: "info",
              icon: <StadiumIcon fontSize="large" color="info" />,
              title: "Venue",
              tooltip: "Activities related to Venues",
              number: 0,
              filterField: "type",
              filterValue: "Venue",
              canCombine: true,
            },
      ],
      {
        color: "info",
        icon: <EmailIcon fontSize="large" color="info" />,
        title: "Message",
        tooltip: "Activities related to Messages",
        number: 0,
        filterField: "type",
        filterValue: "Message",
        canCombine: true,
      },
      {
        color: "success",
        icon: <MessageIcon fontSize="large" color="success" />,
        title: "Notification",
        tooltip: "Activities related to Notifications",
        number: 0,
        filterField: "type",
        filterValue: "Notification",
        canCombine: true,
      },
      {
        color: "warning",
        icon: <WorkIcon fontSize="large" color="warning" />,
        title: "Job",
        tooltip: "Activities Related to Jobs",
        number: 0,
        filterField: "type",
        filterValue: "Job",
        canCombine: true,
      },
      {
        color: "warning",
        icon: <SettingsInputComponent fontSize="large" color="warning" />,
        title: "Integrations",
        tooltip: "Activities Related to Integrations",
        number: 0,
        filterField: "type",
        filterValue: "Integration",
        canCombine: true,
      },
    ],
    []
  );

  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({ initialCards, filters, entity: "activities", refreshBadges });
  const navigate = useNavigate();

  const setActivityFilters = (filter) => {
    navigate("/activities");
    setCurrentActivity(null);
    setFilters(filter);
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setActivityFilters}
        setPage={setPage}
        spacing={1}
      />
    </span>
  );
};

export default ActivitiesFilterCards;
