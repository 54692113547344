import { useState, useEffect } from "react";
import useBadgeValues from "hooks/useBadgeValues";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import FilterCardBar from "components/FilterCardBar";
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PublicIcon from "@mui/icons-material/Public";
import BoltIcon from "@mui/icons-material/Bolt";
import HistoryIcon from "@mui/icons-material/History";
import useSessionAuth from "hooks/useSessionAuth";
// import FiltersList from "components/FiltersList";

const InterviewsFilterCards = ({ filters, setFilters, setPage }) => {
  const { setCurrentEvent, currentLoggedUser } = useAppContextController();
  const [myCompanies, setMyCompanies] = useState("");
  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const fetchApp = async () => {
      const applicant = await fetchSingleApplicant({ applicantId: currentLoggedUser?.applicantId });
      if (applicant)
        setMyCompanies(applicant?.data?.companies?.map((item) => item.companySlug).join(";"));
      else setMyCompanies("");
    };

    try {
      fetchApp();
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [currentLoggedUser]);

  const initialCards = [
    {
      color: "success",
      icon: <BoltIcon fontSize="large" color="success" />,
      title: "Current",
      tooltip: "Interviews Today and Behind",
      number: 0,
      filterField: "timeFrame",
      filterValue: "Current",
      canCombine: true,
    },
    {
      color: "error",
      icon: <HistoryIcon fontSize="large" color="error" />,
      title: "Past",
      tooltip: "Interviews That Happened in the Past",
      number: 0,
      filterField: "timeFrame",
      filterValue: "Past",
      canCombine: true,
    },
    {
      color: "info",
      icon: <EventAvailableIcon fontSize="large" color="info" />,
      title: "My Interviews",
      tooltip: "Interviews For My Companies",
      number: 0,
      filterField: "companySlug",
      filterValue: myCompanies || "N/A",
      canCombine: true,
    },
    {
      color: "dark",
      icon: <PublicIcon fontSize="large" color="black" />,
      title: "All",
      tooltip: "All Interviews",
      number: 0,
      filterField: "eventType",
      filterValue: "Interview",
    },
  ];

  const [refreshBadges, setRefreshBadges] = useState(false);

  const { cards } = useBadgeValues({ initialCards, filters, entity: "events", refreshBadges });
  const navigate = useNavigate();
  const { eventUrl } = useParams();

  const setEventFilters = (filter) => {
    if (eventUrl) navigate("/events");
    setCurrentEvent(null);
    setFilters({ eventType: "Interview", ...filter });
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Events"
        bgColor="primary"
        setFilters={setEventFilters}
        setPage={setPage}
      />
    </span>
  );
};

export default InterviewsFilterCards;
