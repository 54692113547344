import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './globals.css'

// Soft UI Context Provider
import Providers from "components/Providers";
import App from "./App";

ReactDOM.render(
  <BrowserRouter>
    <Providers>
      <App />
    </Providers>
  </BrowserRouter>,
  document.getElementById("root")
);
