import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Card, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

const ErrorModal = ({ description, open, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleOnClose = () => {
    onClose();
    navigate("/");
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Card className={classes.paper}>
        <Typography variant="h4" color="info" gutterBottom>
          Error
        </Typography>
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
        <div className={classes.footer}>
          <Button variant="contained" color="error" backgroundColor="white" onClick={handleOnClose}>
            Close
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default ErrorModal;
