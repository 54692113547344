import { Box, Card, Checkbox, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import moment from "moment";

const Acknowledgement = () => {
  const { applicant, updateApplicantAction, onNextStep, updateButtons, buttonState } =
    useNewApplicantContext();
  const [isAcknowledged, toggleAcknowledged] = useState(false);
  const { complete, validIDs = [] } = applicant?.onboardingDocsComplete || {};

  const isComplete = complete === "Yes";
  const validUploadsMessage =
    validIDs.length > 0 ? `Valid uploads found: ${validIDs.join(", ")}` : "No valid uploads found";

  const {
    company: primaryCompany,
    setCompany: setPrimaryCompany,
    isMobile,
  } = useAppContextController();
  const IMAGE_SERVER = primaryCompany?.imageUrl;
  const signatureRef = useRef(null);
  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      submit: {
        show: true,
        disabled: true,
      },
      next: {
        show: false,
        disabled: false,
      },
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateApplicantAction(applicant._id, {
      acknowledged: {
        date: new Date().toISOString(),
        version: 1,
        signature: applicant?.i9Form?.signature,
        createdDate: applicant?.i9Form?.createdDate,
      },
    });
    onNextStep();
  };

  useEffect(() => {
    if (applicant) {
      toggleAcknowledged(!!applicant.acknowledgedDate);
    }
  }, [applicant]);

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: false,
        disabled: false,
      },
      submit: {
        show: true,
        disabled: !isAcknowledged || !isComplete,
      },
    });
  }, [isAcknowledged]);

  useEffect(() => {
    if (isAcknowledged && signatureRef.current) {
      // Scroll into view when checkbox is toggled
      signatureRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isAcknowledged]);

  return (
    <>
      <MDBox p={3} style={{ border: "solid black 1px" }}>
        <MDBox style={{ height: "30rem", overflow: "scroll" }}>
          <Box>
            {primaryCompany?.acknowledgmentText ? parse(primaryCompany?.acknowledgmentText) : ""}
          </Box>
        </MDBox>
      </MDBox>

      <MDTypography
        component="label"
        variant="button"
        fontWeight="bold"
        color={isComplete ? "success" : "error"}
      >
        {isComplete
          ? "Onboarding documents are complete."
          : "Onboarding documents are incomplete. Complete your documents to proceed."}{" "}
        {validUploadsMessage}
      </MDTypography>
      <Stack direction="row" spacing={2} alignItems="center">
        <form onSubmit={handleSubmit} id="current-form">
          <Checkbox
            disabled={!isComplete}
            checked={isAcknowledged}
            onChange={(v) => toggleAcknowledged(v.target.checked)}
          />
        </form>
        <MDTypography fontWeight="bold" variant="body2">
          I agree to all terms and conditions
        </MDTypography>
      </Stack>
      {applicant?._id && applicant?.i9Form?.signature && isAcknowledged && (
        <>
          <Card style={{ width: isMobile ? "98%" : "45%", height: isMobile ? 100 : 300 }}>
            <img
              src={`${IMAGE_SERVER}/applicants/${applicant?._id}/signature/${applicant?.i9Form?.signature}?${Date.now()}`}
              alt="signature"
              style={{ width: "98%", padding: 3 }}
            />
          </Card>
          <MDTypography fontWeight="bold" variant="body2" mt={2} ref={signatureRef}>
            Date Signed: {moment(applicant?.i9Form?.createdDate).format("MM/DD/YYYY")}
          </MDTypography>
        </>
      )}
    </>
  );
};

export default Acknowledgement;
