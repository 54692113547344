import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useVenueCache from "hooks/useVenueCache";
import useCompanyCache from "hooks/useCompanyCache";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MyEvents from "layouts/pages/dashboard/components/MyEvents";
import MyJobs from "layouts/pages/dashboard/components/MyJobs";
import MyNotifications from "layouts/pages/dashboard/components/MyNotifications";
import MyVenues from "layouts/pages/dashboard/components/MyVenues";
import MyEmployees from "./components/company/MyEmployees";
import { useAppContextController } from "../../../context/AppContext";
import CompanyMyJobs from "./components/company/CompanyMyJobs";
import MyInterviews from "./components/company/MyInterviews";
import Header from "./components/Header";
import MyActivities from "./components/MyActivities";
import MyTimeclock from "./components/MyTimeclock";
import MyCustomers from "./components/MyCustomers";
import MyTasks from "./components/MyTasks";

const useStyle = makeStyles({
  box: {
    overflow: "visible",
  },
});

function Dashboard() {
  // If there is no current user, let's route the user to login
  const navigate = useNavigate();
  const { user, isLoading } = useAuth0();

  const {
    currentLoggedUser,
    setCurrentLoggedUser,
    userType,
    companyType,
    isLoggedIn,
    venues,
    setVenues,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const classes = useStyle();
  const [filters, setFilters] = useState("");
  const setNavigateUrl = (url) => navigate(url, { replace: true });

  // Andres, we will use companyType from the App Context.  Assume that it is companyType = "Venue"

  useEffect(() => {
    if (
      currentLoggedUser &&
      currentLoggedUser?.venues &&
      currentLoggedUser.venues?.length &&
      typeof currentLoggedUser.venues !== "string"
    )
      setFilters({ venueSlug: currentLoggedUser.venues?.join(";") });
    if (
      currentLoggedUser &&
      currentLoggedUser.clientOrgs &&
      currentLoggedUser.clientOrgs?.length &&
      typeof currentLoggedUser.clientOrgs !== "string"
    ) {
      setFilters({
        venueSlug: currentLoggedUser.clientOrgs
          .map((org) => (typeof org === "string" ? org : org.slug))
          .join(";"),
      });
    }
  }, [currentLoggedUser, currentLoggedUser?.venues, user]);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <MDBox mb={5}>
        <Header />
      </MDBox>
      <Grid container columnSpacing={3} rowSpacing={8}>
        <Grid xs={12} laptop={6} item>
          <MDBox>
            {companyType === "Venue" && <MyVenues />}
            {companyType === "Company" && <MyCustomers />}
          </MDBox>
        </Grid>
        <Grid xs={12} laptop={6} item>
          <MDBox>
            {companyType === "Venue" && (
              <MyEvents filters={filters} setNavigateUrl={setNavigateUrl} fetchAll />
            )}
            {companyType === "Company" && <MyInterviews />}
          </MDBox>
        </Grid>
        {userType !== "Client" && userType !== "Event Admin" && (
          <>
            <Grid xs={12} laptop={6} item>
              <MDBox>
                {companyType === "Venue" && userType !== "User" && (
                  <MyJobs filters={filters} fetchAll />
                )}
                {companyType === "Venue" && userType === "User" && (
                  <MyActivities filters={filters} fetchAll />
                )}
                {companyType === "Company" && <CompanyMyJobs fetchAll />}
              </MDBox>
            </Grid>
            <Grid xs={12} laptop={6} item>
              <MDBox>
                {userType === "User" ? (
                  <MyNotifications currentLoggedUser={currentLoggedUser} />
                ) : (
                  <MyTasks currentLoggedUser={currentLoggedUser} />
                )}
              </MDBox>
            </Grid>{" "}
          </>
        )}
        <Grid xs={12} laptop={6} item>
          <MDBox>{companyType === "Company" && userType === "User" && <MyTimeclock />}</MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Dashboard;
