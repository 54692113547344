import { Avatar, Tooltip } from "@mui/material";
// import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { useState } from "react";

const avatarSize = (size) => {
  switch (size) {
    case "lg":
      return { height: "75px", width: "75px" };
    case "md":
      return { height: "50px", width: "50px" };
    case "sm":
      return { height: "30px", width: "30px" };
    default:
      return { height: "50px", width: "50px" };
  }
};

const PartnerIcon = ({
  logoUrl,
  slug,
  name,
  imageWidth,
  imageHeight,
  title,
  size = "md",
  goToEnabled = true,
  setOpenVenueModal = null,
}) => {
  const { userType, company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const navigate = useNavigate();
  const handleClick = (e) => {
    // if (goToEnabled) e.stopPropagation();
    // if (userType !== "User" && goToEnabled) navigate(`/venues/${slug}/action/venueinfo`);
    // else if (userType === "User") {
    //     if (setOpenVenueModal) setOpenVenueModal();
    // }
  };
  return (
    <Tooltip title={!goToEnabled ? name : `Go to ${name} information`}>
      <button
        type="button"
        onClick={(e) => handleClick(e)}
        style={{ border: "none", outline: "none", background: "none", cursor: "pointer" }}
      >
        <Avatar
          alt={name || ""}
          src={logoUrl ? `${IMAGE_SERVER}/partners/${slug}/logo/${logoUrl}` : ""}
          sx={avatarSize(size)}
        >
          {name ? name[0] : ""}
        </Avatar>
      </button>
    </Tooltip>
  );
};

export default PartnerIcon;
