import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  titleBar: { padding: "8px 0px", borderBottom: "2px solid #7b809a66", marginBottom: "16px" },

  titleBar_title: { my: 1, ml: 2 },

  section_images_wrapper: {
    overflowX: "auto",
    paddingTop: 10,
    whiteSpace: "nowrap",
  },

  positioner: {
    position: "absolute",
  },

  section_image_container: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },

  training_section_image_container: {
    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
  },

  section_image: {
    height: "100%",
  },

  training_section_image_delete: {
    position: "relative",
    left: "1000%",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  maps_section_image_delete: {
    position: "absolute",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  section_image_delete: {
    position: "absolute",
    right: "-8px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },
  image_fit: {
    objectFit: "contain",
  },
  otherSection: {
    marginBottom: "24px",
  },
});

export default useStyles;
