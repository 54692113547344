import { Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

import exportJobEmployees from "api/applicants/exportJobEmployees";
import exportEmployees from "api/applicants/exportEmployees";
import CustomModal from "components/CustomizedMui/CustomModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import generateFilterString from "utils/generateFilterString";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import ApplicantExportFields from "./ApplicantExportFields";

const StaffingPoolExportModal = ({
  venueSlug,
  jobSlug,
  isOpen,
  toggleOpen,
  filters,
  useOr = false,
  useOrTags = false,
  useElemMatch = false,
}) => {
  const { currentLoggedUser } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const filtersString = generateFilterString(filters);

  const { company } = useAppContextController();

  const [fields, setFields] = useState({});

  const { mutateAsync: exportMutation } = useMutation(exportEmployees);
  const { mutateAsync: exportJobMutation } = useMutation(exportJobEmployees);

  const handleClick = useCallback(() => {
    const body = {
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
      fields,
      useOr,
      tagOper: useOrTags,
      filter: filtersString,
    };
    if (venueSlug) {
      exportMutation(
        { venueSlug, body },
        {
          onSuccess: (data) => {
            window.open(`${company?.imageUrl}${data.data.exportUrl}`, "_blank");
            toggleOpen(false);
            enqueueSnackbar("Please wait, we are preparing your CSV...", {
              variant: "success",
              autoHideDuration: 3000,
            });
          },
        }
      )
    }
    else {

      exportJobMutation(
        { jobSlug, body },
        {
          onSuccess: (data) => {
            window.open(`${company?.imageUrl}${data.data.exportUrl}`, "_blank");
            toggleOpen(false);
            enqueueSnackbar("Please wait, we are preparing your CSV...", {
              variant: "success",
              autoHideDuration: 3000,
            });
          },
        }
      )
    };
  }
    , [currentLoggedUser, fields, venueSlug, jobSlug]);

  return (
    <CustomModal isOpen={isOpen} onClose={() => toggleOpen(false)} cardSx={{ maxWidth: "820px" }}>
      <FlexWrapper
        sx={{
          gap: "15px",
        }}
      >
        <FlexWrapper justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.5rem",
            }}
          >
            Staffing Pool Export
          </Typography>
          <Button
            sx={{
              maxWidth: "120px",
              width: "100%",
            }}
            type="button"
            variant="contained"
            onClick={handleClick}
            color="primary"
          >
            Export
          </Button>
        </FlexWrapper>
        <ApplicantExportFields fields={fields} setFields={setFields} />
      </FlexWrapper>
    </CustomModal>
  );
};

export default StaffingPoolExportModal;
