import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Card, Fade, Grid, Modal, Stack, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ExportFieldsModalFilterCards from "layouts/pages/events/components/EventsInformation/ExportFieldsModal/ExportFieldsModalFilterCards";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
  error: {
    fontSize: "0.75rem",
  },
});

const MembersExportFieldsModal = ({
  exportFieldModalOpen,
  setExportFieldModalOpen,
  applicantsData,
  currentPartner,
}) => {
  const classes = useStyle();
  const handleClose = () => setExportFieldModalOpen(false);
  // Time Sheet
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [partnerName, setPartnerName] = useState(false);
  const [partnerSlug, setPartnerSlug] = useState(false);
  const [tShirtSize, setTShirtSize] = useState(false);
  const [status, setStatus] = useState(false);

  const [exportData, setExportData] = useState([]);
  const [exportFileName, setExportFileName] = useState("");

  const csvLinkRef = useRef();

  const handleSelectAll = (e) => {
    setFirstName(e);
    setLastName(e);
    setPhone(e);
    setEmail(e);
    setPartnerName(e);
    setPartnerSlug(e);
    setTShirtSize(e);
    setStatus(e);
  };

  useEffect(() => {
    setFirstName(false);
    setLastName(false);
    setPhone(false);
    setEmail(false);
    setPartnerName(false);
    setPartnerSlug(false);
    setTShirtSize(false);
    setStatus(false);
  }, [exportFieldModalOpen]);

  const selectFields = () => {
    const fieldsSelected = {};
    if (phone) {
      fieldsSelected.phone = true;
    }
    if (email) {
      fieldsSelected.email = true;
    }
    if (firstName) {
      fieldsSelected.firstName = true;
    }
    if (lastName) {
      fieldsSelected.lastName = true;
    }
    if (partnerSlug) {
      fieldsSelected.partnerSlug = true;
    }
    if (partnerName) {
      fieldsSelected.partnerName = true;
    }
    if (tShirtSize) {
      fieldsSelected.tShirtSize = true;
    }
    if (status) {
      fieldsSelected.status = true;
    }

    return fieldsSelected;
  };

  const formatDate = (raw) => {
    if (!raw) return "";
    const fd = new Date(raw).toISOString().split("T")[0];
    return fd;
  };

  const sortData = (a, b) => {
    if (a.lastName < b.lastName) return -1;
    if (a.lastName > b.lastName) return 1;
    if (a.firstName < b.firstName) return -1;
    if (a.firstName > b.firstName) return 1;
    return 0;
  };

  const prepareData = async (fields) => {
    const filenameSuffix = "members";
    setExportFileName(`${currentPartner?.name}-${filenameSuffix}.csv`);
    const preppedData = [];
    const data = applicantsData;

    data.sort(sortData).forEach((item, i) => {
      const outputRec = {};
      outputRec.Number = (i + 1).toString();

      if (fields.email) outputRec.email = item.email;
      if (fields.phone) outputRec.phone = item.phone;
      if (fields.lastName) outputRec.lastName = item.lastName;
      if (fields.firstName) outputRec.firstName = item.firstName;

      if (fields.tShirtSize) outputRec.tShirtSize = item.tShirtSize;
      if (fields.partnerSlug) outputRec.partnerSlug = currentPartner?.slug;
      if (fields.partnerName) outputRec.partnerName = currentPartner?.name;

      if (fields.status) outputRec.status = item.partnerStatus;

      Object.keys(item).forEach((key) => {
        if (fields[key] && outputRec[key] === undefined) {
          if (key.toLowerCase().indexOf("date") > -1 && item[key]) {
            outputRec[key] = formatDate(item[key]);
          } else outputRec[key] = item[key] ? item[key] : "";
        }
      });
      preppedData.push(outputRec);
    });

    return preppedData;
  };

  return (
    <Modal
      open={exportFieldModalOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={exportFieldModalOpen}>
        <Card className={classes.container}>
          <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
            <MDTypography variant="h3">Select Export Fields</MDTypography>
            <MDBox display="flex" alignItems="center">
              <Button
                variant="text"
                startIcon={<CancelIcon />}
                onClick={handleClose}
                sx={{ padding: "0.5rem 1rem" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                endIcon={<SaveIcon />}
                style={{ color: "white" }}
                sx={{ padding: "0.5rem 1rem" }}
                onClick={(event) => {
                  const fields = selectFields();
                  prepareData(fields).then((data) => {
                    setExportData(data);
                    csvLinkRef.current.link.click();
                    handleClose();
                  });
                }}
              >
                Export
              </Button>
              <CSVLink
                data={exportData}
                style={{ color: "white" }}
                filename={exportFileName}
                ref={csvLinkRef}
              />
            </MDBox>
          </MDBox>
          <Grid container p={2}>
            <Grid item sm={5}>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={firstName} onChange={(e) => setFirstName(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}> Member First Name</MDTypography>
              </Stack>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={lastName} onChange={(e) => setLastName(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}> Member Last Name</MDTypography>
              </Stack>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={phone} onChange={(e) => setPhone(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}> Member Phone Number</MDTypography>
              </Stack>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={email} onChange={(e) => setEmail(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}> Member Email Address</MDTypography>
              </Stack>
            </Grid>

            <Grid item sm={5}>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={partnerSlug} onChange={(e) => setPartnerSlug(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}>Partner Slug</MDTypography>
              </Stack>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={partnerName} onChange={(e) => setPartnerName(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}>Partner Name</MDTypography>
              </Stack>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={tShirtSize} onChange={(e) => setTShirtSize(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}>T-Shirt Size</MDTypography>
              </Stack>
              <Stack display="flex" direction="row" alignItems="center">
                <Switch checked={status} onChange={(e) => setStatus(e.target.checked)} />
                <MDTypography style={{ fontSize: "1rem" }}>Status</MDTypography>
              </Stack>
            </Grid>
          </Grid>
          <MDBox p={2}>
            <Stack display="flex" direction="row" alignItems="center" justifyContent="end">
              <Switch onChange={(e, v) => handleSelectAll(v)} />
              <MDTypography color="info"> Select All</MDTypography>
            </Stack>
          </MDBox>
        </Card>
      </Fade>
    </Modal>
  );
};

export default MembersExportFieldsModal;
