/* eslint-disable no-console */
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomModal from "components/CustomizedMui/CustomModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import FormField from "layouts/applications/wizard/components/FormField";
import chart from "assets/images/chart.png";
import FiltersList from "components/FiltersList";
import { useEffect, useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import VenueIcon from "components/VenueIcon";
import DataTable from "components/DataTable";
import CustomTextField from "components/Form/CustomTextField";
import moment from "moment";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { reportFormSchema } from "data/reportModalForm";
import LoadingButton from "@mui/lab/LoadingButton";
import { updateFeature } from "api/reports/updateFeature";
import FilterEditorModal from "./FilterEditorModal";
import { defaultFilterEditorValues, MAX_DATE, MIN_DATE } from "./fixtures";

const ReportModal = ({
  toggleOpen,
  isOpen,
  handleGenerateReport,
  message,
  reportingType,
  chartTableSrc,
  chartSrc,
  filterCardList = [],
  subFilterCardList = [],
  type = "Staffing",
  handleExportReport,
  handleSaveReport,
  isLoadingExport,
  isLoadingSave,
  reportModalValues,
  defaultShowSubFilters = false,
  setToastAlert,
  title,
  subTables = [],
  selectedSubtables = [],
  handleSelectSubtables,
}) => {
  const { company, currentJob, setCurrentJob, venues, reportSchemas } = useAppContextController();
  const [customerData, setCustomerData] = useState({});
  const [filterEditorModalOpen, setFilterEditorModalOpen] = useState(false);
  const [chartFilterEditorModalOpen, setChartFilterEditorModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState("table");
  const [anchorEl, setAnchorEl] = useState(null);

  const isCompany = company?.companyType === "Company";

  const defaultFilters = {
    status: type === "Staffing" ? "Employee" : "Applicant",
  };

  if (type !== "Staffing") defaultFilters.applicantStatus = "New";

  const [applicantsFilters, setApplicantsFilters] = useState(defaultFilters);

  const resolver = useYupValidationResolver(reportFormSchema);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
    setError,
    formState: { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: reportModalValues,
    resolver,
    mode: "onBlur",
  });

  const filterCards = watch("filterCards") || "";
  const subFilterCards = watch("subFilterCards") || "";
  const dateRange = watch("dateRange") || "";
  const chartDateRange = watch("chartDateRange") || "";
  const tableColumns = watch("tableColumns") || [];
  const subtables = watch("selectedSubtables") || [];
  const chartFilters = watch("chartFilters");

  const [syncing, setSyncing] = useState(false);
  const [polling, setPolling] = useState(false);

  useEffect(() => {
    if (!chartFilters) {
      setValue("chartFilters", defaultFilterEditorValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartFilters]);

  const checkSyncingStatus = async () => {
    try {
      const feature = reportingType === "employees" ? "applicants" : reportingType;
      const response = await updateFeature(feature); // `updateFeature` is your API call function
      const { data } = response;

      // If still syncing, poll every 5 seconds
      if (data?.syncing) {
        setSyncing(data?.syncing);
        setTimeout(checkSyncingStatus, 5000); // Poll every 5 seconds if syncing is still true
      } else {
        setSyncing(false);
        setPolling(false); // Stop polling once syncing is complete
      }
    } catch (err) {
      console.error("Error checking syncing status:", err);
    }
  };

  useEffect(() => {
    const checkInitialSyncStatus = async () => {
      try {
        await checkSyncingStatus(); // Call the function to check if syncing is already in progress
      } catch (err) {
        console.error("Error checking syncing status:", err);
      }
    };

    checkInitialSyncStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportingType]);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const memoizedData = useMemo(() => {
    // Retrieve the main columns and chart options for the current reporting type
    const mainColumns = reportSchemas[reportingType]?.columns || [];
    const mainXAxisOptions = reportSchemas[reportingType]?.chartInfo?.xAxisOptions || [];
    const mainYAxisOptions = reportSchemas[reportingType]?.chartInfo?.yAxisOptions || [];
    const mainGroupByOptions = reportSchemas[reportingType]?.chartInfo?.groupByOptions || [];

    // Collect additional columns and chart options from the selected subtables
    const subtableData = selectedSubtables.map((subtable) => {
      const subtableColumns =
        reportSchemas[subtable.value]?.columns.map((item) => ({
          ...item,
          label: `${capitalizeFirstLetter(subtable.value)} - ${item.label}`,
          value: `${subtable.payload}.${item.value}`,
        })) || [];

      const subtableXAxisOptions =
        reportSchemas[subtable.value]?.chartInfo?.xAxisOptions.map((item) => ({
          ...item,
          label: `${capitalizeFirstLetter(subtable.value)} - ${item.label}`,
          value: `${subtable.payload}.${item.value}`,
        })) || [];

      const subtableYAxisOptions =
        reportSchemas[subtable.value]?.chartInfo?.yAxisOptions.map((item) => ({
          ...item,
          label: `${capitalizeFirstLetter(subtable.value)} - ${item.label}`,
          value: `${subtable.payload}.${item.value}`,
        })) || [];

      const subtableGroupByOptions =
        reportSchemas[subtable.value]?.chartInfo?.groupByOptions.map((item) => ({
          ...item,
          label: `${capitalizeFirstLetter(subtable.value)} - ${item.label}`,
          value: `${subtable.payload}.${item.value}`,
        })) || [];

      return {
        columns: subtableColumns,
        xAxisOptions: subtableXAxisOptions,
        yAxisOptions: subtableYAxisOptions,
        groupByOptions: subtableGroupByOptions,
      };
    });

    // Merge main and subtable data
    const mergedColumns = [...mainColumns, ...subtableData.flatMap((data) => data.columns)];

    const mergedXAxisOptions = [
      ...mainXAxisOptions,
      ...subtableData.flatMap((data) => data.xAxisOptions),
    ];

    const mergedYAxisOptions = [...mainYAxisOptions];

    const mergedGroupByOptions = [
      ...mainGroupByOptions,
      ...subtableData.flatMap((data) => data.groupByOptions),
    ];

    return {
      columns: mergedColumns,
      xAxisOptions: mergedXAxisOptions,
      yAxisOptions: mergedYAxisOptions,
      groupByOptions: mergedGroupByOptions,
    };
  }, [reportSchemas, reportingType, selectedSubtables]);

  useEffect(() => {
    if (reportModalValues) {
      reset(reportModalValues);
    }
  }, [reportModalValues, reset]);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);

    if (newValue === "table") setValue("formType", "table");
    if (newValue === "chart") setValue("formType", "chart");
  };

  const handleConfirmChanges = async (reportType = "table") => {
    if (syncing) {
      setToastAlert({
        isShow: true,
        message: "Migration is already in progress. Please wait...",
        status: "error",
      });

      return;
    }

    // Trigger migration
    setPolling(true); // Start polling
    await checkSyncingStatus();

    console.log("Setting formType:", reportType); // Debugging
    setValue("formType", reportType); // Set the form type (table or chart)

    const isValidForm = await trigger();
    console.log("Is form valid:", isValidForm); // Debugging

    if (isValidForm) {
      console.log("Form is valid, proceeding to generate report."); // Debugging
      handleSubmit(handleGenerateReport)(); // Generate the report
      handleChange(null, reportType); // Change the tab or UI to reflect the selected report type
    } else {
      console.log("Form is invalid, stopping execution."); // Debugging
    }
  };

  const formType = watch("formType");
  const currentXAxis = getValues("xAxis");
  const currentGroupBy = getValues("groupBy");
  const currentSortBy = getValues("sortBy");
  const currentOrder = getValues("order");
  const currentDateField = getValues("dateField");
  const currentDateRange = getValues("dateRange");
  const currentChartDateField = getValues("chartDateField");
  const currentChartDateRange = getValues("chartDateRange");
  const currentFilters = getValues("filters");
  const currentChartFilters = getValues("chartFilters");

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (fileType) => {
    handleExportReport(formType, fileType);
    handleClose();
  };

  const handleSave = () => {
    handleSaveReport(watch());
  };

  const handleSelectSubtable = (isSelected, subtable) => {
    const safeTableColumns = tableColumns || [];
    const safeCurrentFilters = currentFilters || defaultFilterEditorValues;
    const safeCurrentChartFilters = currentChartFilters || defaultFilterEditorValues;
    const safeXAxis = currentXAxis || "";
    const safeGroupBy = currentGroupBy || "";
    const safeSortBy = currentSortBy || "";
    const safeOrder = currentOrder || "";
    const safeDateField = currentDateField || "";
    const safeChartDateField = currentChartDateField || "";
    const previousSubtable = selectedSubtables?.[0]?.payload || ""; // Get the previous subtable payload

    if (isSelected) {
      // If toggling off (same subtable clicked), clear related fields and filters
      setValue("selectedSubtables", []); // Clear selectedSubtables

      // Clear only fields related to the current subtable being deselected
      if (safeXAxis.startsWith(`${subtable.payload}.`)) setValue("xAxis", "");
      if (safeGroupBy.startsWith(`${subtable.payload}.`)) setValue("groupBy", "");
      if (safeSortBy.startsWith(`${subtable.payload}.`)) setValue("sortBy", "");
      if (safeOrder.startsWith(`${subtable.payload}.`)) setValue("order", "");
      if (safeDateField.startsWith(`${subtable.payload}.`)) setValue("dateField", "");
      if (safeChartDateField.startsWith(`${subtable.payload}.`)) setValue("chartDateField", "");

      // Remove table columns and filters related to the subtable
      setValue(
        "tableColumns",
        safeTableColumns.filter(
          (column) => !column.startsWith(`${subtable.payload}.`) || !column.includes(".")
        )
      );
      const updatedFilterRows = safeCurrentFilters.filterEditorModalRows.filter(
        (row) => !row?.field?.startsWith(`${subtable.payload}.`)
      );

      const updatedChartFilterRows = safeCurrentChartFilters.filterEditorModalRows.filter(
        (row) => !row?.field?.startsWith(`${subtable.payload}.`)
      );
      setValue("filters", {
        ...safeCurrentFilters,
        filterEditorModalRows: updatedFilterRows,
      });

      setValue("chartFilters", {
        ...safeCurrentChartFilters,
        filterEditorModalRows: updatedChartFilterRows,
      });
    } else {
      // If toggling on, replace the selected subtable and clear fields related to the previous subtable
      setValue("selectedSubtables", [subtable]); // Replace with the new subtable

      // Clear fields related to the previous subtable
      if (previousSubtable) {
        if (safeXAxis.startsWith(`${previousSubtable}.`)) setValue("xAxis", "");
        if (safeGroupBy.startsWith(`${previousSubtable}.`)) setValue("groupBy", "");
        if (safeSortBy.startsWith(`${previousSubtable}.`)) setValue("sortBy", "");
        if (safeOrder.startsWith(`${previousSubtable}.`)) setValue("order", "");
        if (safeDateField.startsWith(`${previousSubtable}.`)) setValue("dateField", "");
        if (safeChartDateField.startsWith(`${previousSubtable}.`)) setValue("chartDateField", "");
      }

      // Filter out unrelated table columns and retain only those related to the newly selected subtable or base table
      setValue(
        "tableColumns",
        safeTableColumns.filter(
          (column) => !column.includes(".") || column.startsWith(`${subtable.payload}.`)
        )
      );

      // Retain only filter rows related to the newly selected subtable
      const updatedFilterRows = safeCurrentFilters.filterEditorModalRows.filter((row) =>
        row?.field?.startsWith(`${subtable.payload}.`)
      );

      const updatedChartFilterRows = safeCurrentChartFilters.filterEditorModalRows.filter((row) =>
        row?.field?.startsWith(`${subtable.payload}.`)
      );
      setValue("filters", {
        ...safeCurrentFilters,
        filterEditorModalRows: updatedFilterRows,
      });

      setValue("chartFilters", {
        ...safeCurrentChartFilters,
        filterEditorModalRows: updatedChartFilterRows,
      });
    }

    // Trigger any additional selection logic if needed
    handleSelectSubtables(subtable);
  };

  const showSubFilters =
    (reportingType === "venues" || reportingType === "companies") && filterCards === "Prospect";

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => toggleOpen(false)}
      cardSx={{ maxWidth: "85%", maxHeight: "90%", overflowY: "scroll", padding: 2 }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h3">{title}</Typography>
        <FlexWrapper rowGap="20px" columnGap={3}>
          {subTables?.map((subtable) => {
            const isSelected = selectedSubtables.some((item) => item.label === subtable.label);

            return (
              <Button
                variant="contained"
                color={isSelected ? "success" : "secondary"}
                onClick={() => handleSelectSubtable(isSelected, subtable)}
                style={{ display: "flex", alignItems: "center", gap: 4 }}
              >
                {isSelected ? <CheckIcon /> : <InfoIcon />}
                {subtable?.label || "--"}
              </Button>
            );
          })}
        </FlexWrapper>
      </Box>

      <FlexWrapper rowGap="20px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box border="1px solid grey" height="100%" display="flex" flexDirection="column">
              <Grid container spacing={2} px={3} pt={2} pb={3} flexGrow={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">Options</Typography>
                </Grid>

                <Grid item xs={12} sm={6} display="flex" flexDirection="column" gap={4}>
                  {/* Date Field */}

                  <Box>
                    <Controller
                      name="dateField"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={
                            memoizedData?.columns
                              ?.filter((item) => item.type === "date")
                              ?.sort((a, b) => a.label.localeCompare(b.label)) || []
                          }
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          renderInput={(params) => <FormField label="Date Field" {...params} />}
                          {...field}
                          value={
                            memoizedData?.columns?.find((opt) => opt.value === field.value) || null
                          }
                          onChange={(_, value) => {
                            field.onChange(value ? value.value : null);
                          }}
                        />
                      )}
                    />

                    {errors?.dateField?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.dateField?.message}
                      </Typography>
                    )}
                  </Box>

                  {dateRange === "Custom" && (
                    <Stack direction="row" spacing={2} mt={2} alignItems="center">
                      {/* Start Date */}
                      <Box>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="Start Date"
                                minDate={new Date(MIN_DATE)}
                                maxDate={new Date(MAX_DATE)}
                                renderInput={(params) => <CustomTextField {...params} />}
                                {...field}
                                value={watch("startDate") || null}
                                onChange={(date) =>
                                  field.onChange(date ? date.toISOString() : null)
                                }
                              />
                            </LocalizationProvider>
                          )}
                        />

                        {errors?.startDate?.message && (
                          <Typography color="error" fontSize="0.75rem">
                            {errors?.startDate?.message}
                          </Typography>
                        )}
                      </Box>

                      {/* End Date */}
                      <Box>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="End Date"
                                renderInput={(params) => <CustomTextField {...params} />}
                                {...field}
                                value={watch("endDate") || null}
                                minDate={watch("startDate") || new Date(MIN_DATE)}
                                maxDate={new Date(MAX_DATE)}
                                onChange={(date) =>
                                  field.onChange(date ? date.toISOString() : null)
                                }
                              />
                            </LocalizationProvider>
                          )}
                        />

                        {errors?.endDate?.message && (
                          <Typography color="error" fontSize="0.75rem">
                            {errors?.endDate?.message}
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  )}

                  {/* Sort By */}
                  <Box>
                    <Controller
                      name="sortBy"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={
                            memoizedData?.columns?.sort((a, b) => a.label.localeCompare(b.label)) ||
                            []
                          }
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          renderInput={(params) => <FormField label="Sort By" {...params} />}
                          {...field}
                          value={
                            memoizedData?.columns?.find((opt) => opt.value === field.value) || null
                          }
                          onChange={(_, value) => {
                            field.onChange(value ? value.value : null);
                            setValue(`${value.value}`, true);

                            if (!tableColumns.some((item) => item === value.value))
                              setValue("tableColumns", [...tableColumns, value.value]);
                          }}
                        />
                      )}
                    />

                    {errors?.sortBy?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.sortBy?.message}
                      </Typography>
                    )}
                  </Box>

                  {/* Order */}
                  <Box>
                    <Controller
                      name="order"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={["DESC", "ASC"]}
                          getOptionLabel={(option) => option}
                          isOptionEqualToValue={(option, value) => option === value}
                          renderInput={(params) => <FormField label="Order" {...params} />}
                          {...field}
                          value={watch("order") || ""}
                          onChange={(_, value) =>
                            value ? field.onChange(value) : field.onChange("")
                          }
                        />
                      )}
                    />

                    {errors?.order?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.order?.message}
                      </Typography>
                    )}
                  </Box>

                  {/* Filter Cards */}
                  {filterCardList.length > 0 && (
                    <Box>
                      <Controller
                        name="filterCards"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            options={filterCardList || []}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => <FormField label="Filters" {...params} />}
                            {...field}
                            value={watch("filterCards") || ""}
                            onChange={(_, value) => field.onChange(value)}
                          />
                        )}
                      />

                      {errors?.filterCards?.message && (
                        <Typography color="error" fontSize="0.75rem">
                          {errors?.filterCards?.message}
                        </Typography>
                      )}
                    </Box>
                  )}

                  {subFilterCardList.length > 0 && (showSubFilters || defaultShowSubFilters) && (
                    <Box>
                      <Controller
                        name="subFilterCards"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            options={subFilterCardList || []}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => <FormField label="Sub Filters" {...params} />}
                            {...field}
                            value={watch("subFilterCards") || ""}
                            onChange={(_, value) => field.onChange(value)}
                          />
                        )}
                      />

                      {errors?.subFilterCards?.message && (
                        <Typography color="error" fontSize="0.75rem">
                          {errors?.subFilterCards?.message}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>

                {/* Columns */}
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Controller
                      name="dateRange"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={[
                            "Year to date",
                            "Month to date",
                            "Last 12 months",
                            "Last 30 days",
                            "Custom",
                          ]}
                          getOptionLabel={(option) => option}
                          isOptionEqualToValue={(option, value) => option === value}
                          renderInput={(params) => <FormField label="Date Range" {...params} />}
                          {...field}
                          value={watch("dateRange") || ""}
                          onChange={(_, value) =>
                            value ? field.onChange(value) : field.onChange("")
                          }
                        />
                      )}
                    />

                    {errors?.dateRange?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.dateRange?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    border="solid 1px gray"
                    sx={{
                      height: 290,
                      overflowY: "auto",
                      padding: 2,
                      mt: 3,
                    }}
                  >
                    <Typography variant="body2" fontSize="14px">
                      Columns
                    </Typography>

                    {reportSchemas &&
                      memoizedData?.columns
                        ?.sort((a, b) => a?.label?.localeCompare(b?.label))
                        ?.map((column, index) => (
                          <Stack
                            display="flex"
                            direction="row"
                            alignItems="center"
                            key={column?.value}
                          >
                            <Controller
                              name={column?.value}
                              control={control}
                              defaultValue={false}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  checked={field.value && tableColumns.includes(column?.value)}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);

                                    setError("tableColumns", {});

                                    if (e.target.checked) {
                                      setValue("tableColumns", [...tableColumns, column?.value]);
                                    } else {
                                      setValue(
                                        "tableColumns",
                                        tableColumns.filter((item) => item !== column?.value)
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            <Typography fontWeight="bold" fontSize="12px">
                              {column?.label}
                            </Typography>
                          </Stack>
                        ))}
                  </Box>

                  {errors?.tableColumns?.message && (
                    <Typography color="error" fontSize="0.75rem">
                      {errors?.tableColumns?.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {/* Bottom Buttons */}
              <Stack
                display="flex"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 3, py: 2, mt: "auto" }}
                gap={2}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setFilterEditorModalOpen(true);
                  }}
                >
                  Filter Editor
                </Button>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  onClick={() => handleConfirmChanges()}
                  loading={formType === "table" && (isSubmitting || syncing)}
                >
                  Generate Report
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>

          {/* Chart Section */}
          <Grid item xs={12} sm={6}>
            <Box border="1px solid grey" height="100%" display="grid" gridTemplateColumns="1fr">
              <Grid container spacing={2} px={3} pt={2} pb={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Chart</Typography>
                </Grid>

                <Grid item xs={12} display="flex" flexDirection="column" gap={4}>
                  <Box>
                    <Controller
                      name="xAxis"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={memoizedData?.xAxisOptions || []}
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          renderInput={(params) => <FormField label="X-Axis" {...params} />}
                          {...field}
                          value={
                            memoizedData?.xAxisOptions?.find((opt) => opt.value === field.value) ||
                            null
                          }
                          onChange={(_, value) => field.onChange(value ? value.value : null)}
                        />
                      )}
                    />

                    {errors?.xAxis?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.xAxis?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Controller
                      name="chartType"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={reportSchemas[reportingType]?.chartInfo?.chartTypes || []}
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          renderInput={(params) => <FormField label="Chart Type" {...params} />}
                          {...field}
                          value={
                            reportSchemas[reportingType]?.chartInfo?.chartTypes?.find(
                              (opt) => opt.value === field.value
                            ) || null
                          }
                          onChange={(_, value) => field.onChange(value ? value.value : null)}
                        />
                      )}
                    />

                    {errors?.chartType?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.chartType?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Controller
                      name="yAxis"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={memoizedData?.yAxisOptions || []}
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          renderInput={(params) => <FormField label="Y-Axis" {...params} />}
                          {...field}
                          value={
                            memoizedData?.yAxisOptions?.find((opt) => opt.value === field.value) ||
                            null
                          }
                          onChange={(_, value) => field.onChange(value ? value.value : null)}
                        />
                      )}
                    />

                    {errors?.yAxis?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.yAxis?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Controller
                      name="groupBy"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={memoizedData?.groupByOptions || []}
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          renderInput={(params) => <FormField label="Group By" {...params} />}
                          {...field}
                          value={
                            memoizedData?.groupByOptions?.find(
                              (opt) => opt.value === field.value
                            ) || null
                          }
                          onChange={(_, value) => field.onChange(value ? value.value : null)}
                        />
                      )}
                    />

                    {errors?.groupBy?.message && (
                      <Typography color="error" fontSize="0.75rem">
                        {errors?.groupBy?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box display="flex" justifyContent="space-between" gap={3}>
                    <Box width="50%">
                      <Controller
                        name="chartDateField"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            options={
                              memoizedData?.columns
                                ?.filter((item) => item.type === "date")
                                ?.sort((a, b) => a.label.localeCompare(b.label)) || []
                            }
                            getOptionLabel={(option) => option?.label || ""}
                            isOptionEqualToValue={(option, value) => option.value === value?.value}
                            renderInput={(params) => <FormField label="Date Field" {...params} />}
                            {...field}
                            value={
                              memoizedData?.columns?.find((opt) => opt.value === field.value) ||
                              null
                            }
                            onChange={(_, value) => {
                              field.onChange(value ? value.value : null);
                            }}
                          />
                        )}
                      />

                      {errors?.chartDateField?.message && (
                        <Typography color="error" fontSize="0.75rem">
                          {errors?.chartDateField?.message}
                        </Typography>
                      )}
                    </Box>

                    <Box width="50%">
                      <Controller
                        name="chartDateRange"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            options={[
                              "Year to date",
                              "Month to date",
                              "Last 12 months",
                              "Last 30 days",
                              "Custom",
                            ]}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => <FormField label="Date Range" {...params} />}
                            {...field}
                            value={chartDateRange || ""}
                            onChange={(_, value) =>
                              value ? field.onChange(value) : field.onChange("")
                            }
                          />
                        )}
                      />

                      {errors?.chartDateRange?.message && (
                        <Typography color="error" fontSize="0.75rem">
                          {errors?.chartDateRange?.message}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {chartDateRange === "Custom" && (
                    <Box display="flex" justifyContent="space-between" gap={3}>
                      {/* Start Date */}
                      <Box width="50%">
                        <Controller
                          name="chartStartDate"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="Start Date"
                                minDate={new Date(MIN_DATE)}
                                maxDate={new Date(MAX_DATE)}
                                renderInput={(params) => <CustomTextField {...params} />}
                                {...field}
                                value={watch("chartStartDate") || null}
                                onChange={(date) =>
                                  field.onChange(date ? date.toISOString() : null)
                                }
                              />
                            </LocalizationProvider>
                          )}
                        />

                        {errors?.chartStartDate?.message && (
                          <Typography color="error" fontSize="0.75rem">
                            {errors?.chartStartDate?.message}
                          </Typography>
                        )}
                      </Box>

                      {/* End Date */}
                      <Box width="50%">
                        <Controller
                          name="chartEndDate"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="End Date"
                                renderInput={(params) => <CustomTextField {...params} />}
                                {...field}
                                value={watch("chartEndDate") || null}
                                minDate={watch("chartStartDate") || new Date(MIN_DATE)}
                                maxDate={new Date(MAX_DATE)}
                                onChange={(date) =>
                                  field.onChange(date ? date.toISOString() : null)
                                }
                              />
                            </LocalizationProvider>
                          )}
                        />

                        {errors?.chartEndDate?.message && (
                          <Typography color="error" fontSize="0.75rem">
                            {errors?.chartEndDate?.message}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Stack
                display="flex"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 3, py: 2, mt: "auto" }}
                gap={2}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setChartFilterEditorModalOpen(true);
                  }}
                >
                  Filter Editor
                </Button>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  onClick={() => handleConfirmChanges("chart")}
                  loading={formType === "chart" && (isSubmitting || syncing)}
                >
                  Generate Chart
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>

          {(filterCards ||
            subFilterCards ||
            (currentFilters.filterEditorModalRows.some((item) => item.field !== null) &&
              currentFilters?.filterEditorModalRows?.length > 0)) &&
            formType === "table" && (
              <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h5">Table Special Filters: </Typography>

                {filterCards && (
                  <Chip
                    color="info"
                    sx={{ mx: 0.5 }}
                    label={`Filter: ${filterCards}`}
                    size="small"
                  />
                )}

                {subFilterCards && (
                  <Chip
                    color="secondary"
                    sx={{ mx: 0.5 }}
                    label={`Subfilter: ${subFilterCards}`}
                    size="small"
                  />
                )}

                {currentFilters &&
                  currentFilters.filterEditorModalRows.some((item) => item.field !== null) &&
                  currentFilters.filterEditorModalRows.map((filter, index) => (
                    <Chip
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      color="primary"
                      sx={{ mx: 0.5 }}
                      label={`Field: ${filter.field || "N/A"} | ${filter.isOrNot} | ${
                        filter.operator
                      } | Value: ${filter.rowValue || filter.value || "N/A"}`}
                      size="small"
                    />
                  ))}
              </Grid>
            )}

          {currentChartFilters?.filterEditorModalRows?.length > 0 &&
            currentChartFilters.filterEditorModalRows.some((item) => item.field !== null) &&
            formType === "chart" && (
              <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h5">Chart Special Filters: </Typography>

                {currentChartFilters &&
                  currentChartFilters.filterEditorModalRows.some((item) => item.field !== null) &&
                  currentChartFilters.filterEditorModalRows.map((filter, index) => (
                    <Chip
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      color="primary"
                      sx={{ mx: 0.5 }}
                      label={`Field: ${filter.field || "N/A"} | ${filter.isOrNot} | ${
                        filter.operator
                      } | Value: ${filter.rowValue || filter.value || "N/A"}`}
                      size="small"
                    />
                  ))}
              </Grid>
            )}

          <TabContext value={tabValue}>
            <Grid item xs={12} sm={6}>
              <Tabs value={tabValue} onChange={handleChange} aria-label="chart-tabs">
                <Tab label="Table" value="table" />
                <Tab label="Chart" value="chart" />
              </Tabs>
            </Grid>

            <Grid item xs={12} sm={6} display="flex" justifyContent="space-between" gap={2}>
              <LoadingButton
                variant="contained"
                fullWidth
                startIcon={<SaveIcon />}
                onClick={handleSave}
                loading={isLoadingSave}
              >
                Save Report
              </LoadingButton>

              <LoadingButton
                variant="contained"
                fullWidth
                startIcon={<DownloadIcon />}
                onClick={handleClick}
                loading={isLoadingExport}
              >
                Export
              </LoadingButton>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => handleExport("csv")}>CSV</MenuItem>
                <MenuItem onClick={() => handleExport("xlsx")}>Excel</MenuItem>
              </Menu>
            </Grid>

            <Grid item xs={12}>
              <TabPanel value="table">
                {chartTableSrc && (
                  <>
                    <Typography variant="h5">Table</Typography>
                    <iframe title="unique-table" src={chartTableSrc} width="100%" height="391" />
                  </>
                )}
              </TabPanel>
            </Grid>

            <Grid item xs={12}>
              <TabPanel value="chart">
                {chartSrc && (
                  <>
                    <Typography variant="h5">Chart</Typography>
                    <iframe title="unique-chart" src={chartSrc} width="100%" height="391" />
                  </>
                )}
              </TabPanel>
            </Grid>
          </TabContext>

          <Grid item sm={4} />
          {/* <Grid item sm={8}></Grid> */}
        </Grid>
      </FlexWrapper>
      {filterEditorModalOpen && (
        <FilterEditorModal
          open={filterEditorModalOpen}
          setOpen={setFilterEditorModalOpen}
          filters={watch("filters")}
          setFilters={(list) => setValue("filters", list)}
          columns={memoizedData?.columns || []}
        />
      )}

      {chartFilterEditorModalOpen && (
        <FilterEditorModal
          open={chartFilterEditorModalOpen}
          setOpen={setChartFilterEditorModalOpen}
          filters={watch("chartFilters")}
          setFilters={(list) => setValue("chartFilters", list)}
          columns={memoizedData?.columns || []}
        />
      )}
    </CustomModal>
  );
};

export default ReportModal;
