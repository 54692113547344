import { useNotificationContext } from "context/NotificationContext";
import { NOTIFICATION_ACTIONS } from "reducers/notificationReducer";
import NotificationFilterCards from "../NotificationFilterCards";
import NotificationInfoPanel from "../NotificationInfoPanel";
import NotificationsTablePanel from "../NotificationsTablePanel";

const NotificationSection = () => {
  const { activeActions } = useNotificationContext();

  return (
    <>
      <NotificationFilterCards />
      <NotificationsTablePanel />
      {activeActions.includes(NOTIFICATION_ACTIONS.NOTIFICATION_INFO) && <NotificationInfoPanel />}
    </>
  );
};

export default NotificationSection;
