import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, Stack } from "@mui/material";
import CustomCard from "components/CustomCard";
import { Cancel as CancelIcon, Save as SaveIcon, FolderCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { cardStyles } from "layouts/pages/events/styles";
import { makeStyles } from "@mui/styles";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import { getDirtyFields } from "utils/helpers/formHelpers";
import MDBox from "components/MDBox";
import { eventContent } from "data/event";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import TagList from "components/TagList";
import updateEvent from "layouts/pages/events/actions/updateEvent";

const useStyle = makeStyles({
  editor: {
    height: 275,
  },
  previewButton: {
    padding: 0,
  },
});

const EventContent = ({ setPreviewContent, setEventPreview, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyle();
  const queryClient = useQueryClient();
  const { currentEvent, setCurrentEvent } = useAppContextController();

  const resolver = useYupValidationResolver(eventContent);
  const {
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    setError,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({ mode: "onBlur", defaultValues: currentEvent, resolver });

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setEventPreview(currentEvent);
    setOpen(true);
  };

  const cancelChangesHandler = () => {
    const url = currentEvent?.eventUrl;
    reset(currentEvent);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
  };

  useEffect(() => {
    reset(currentEvent);
  }, [currentEvent]);

  const panelActions = (
    <Box mt={2}>
      <Button
        variant="text"
        startIcon={<CancelIcon />}
        onClick={cancelChangesHandler}
        disabled={isSubmitting}
        color="error"
        sx={{ color: "#F44335", fontSize: 14 }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="text"
        endIcon={<SaveIcon />}
        sx={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Box>
  );

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar("Event has been updated!", { variant: "success" });
      await queryClient.invalidateQueries("events");
    },
  });

  const editEventFormHandler = async (values) => {
    if (!currentEvent.venueSlug || !currentEvent.eventId) {
      enqueueSnackbar("Cannot Update Event -- No Event ID!", { variant: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);

    await updateEventMutation.mutateAsync({
      venueSlug: currentEvent.venueSlug,
      eventId: currentEvent._id,
      data: {
        ...modifiedFields,
      },
    });
  };

  return (
    currentEvent && (
      <form onSubmit={handleSubmit(editEventFormHandler)}>
        <CustomCard
          icon={<FolderCopy color="white" />}
          cardTitle="Event Content"
          cardActions={isDirty && panelActions}
          cardSx={cardStyles.card}
          titleSx={cardStyles.title}
          iconContainerSx={{ backgroundColor: "primary", mt: -3, ml: "1.5rem" }}
          isStacked
        >
          <Box sx={cardStyles.container}>
            <Grid item xs={12}>
              <MDBox mt={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MDTypography variant="body2" textTransform="capitalize">
                    EVENT DESCRIPTION
                  </MDTypography>
                  <Button
                    className={classes.previewButton}
                    onClick={() => openPreviewModal("description")}
                  >
                    Preview
                  </Button>
                </Stack>
              </MDBox>
              <MDBox mb={10}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { value, onChange, ...props } }) => (
                    <MDEditor
                      className={classes.editor}
                      theme="snow"
                      onChange={(text) => {
                        onChange(text);
                      }}
                      value={value}
                      placeholder="Write Company Description"
                      {...props}
                    />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="tags"
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <TagList
                    {...props}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                )}
              />
            </Grid>
          </Box>
        </CustomCard>
      </form>
    )
  );
};

export default EventContent;
