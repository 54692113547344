import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";

const fetchPartnerApplicants = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
  includeVerified = false,
  searchProjection = false,
  useOrTags = true,
}) => {
  const { paginationQuery, sortQuery, filterQuery, queryString } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {
    const res = await baseAxios.get(
      `/applicants${queryString}${useOr ? "&useOr=true" : ""}${
        searchProjection ? "&searchProjection=true" : ""
      }`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchPartnerApplicants;
