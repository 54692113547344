import { Grid, Icon, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const EducationItem = ({ row, idx, onEdit, onRemove }) => {
    const { schoolName, city, state, startYear, endYear, degreeDiploma, major } = row;

    return (
        <>
            <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                onClick={() => {
                    onEdit(idx, row, true);
                }}
            >
                <Grid container display="flex" alignItems="center">
                    <Grid item sm={11}>
                        <Grid container justifyContent="left" alignItems="center" spacing={1}>
                            <Grid item sm={3}>
                                <MDTypography sx={{ fontSize: "0.8rem" }}>{schoolName}</MDTypography>
                            </Grid>
                            <Grid item sm={1} textAlign="center">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>{city}</MDTypography>
                            </Grid>
                            <Grid item sm={1} textAlign="center">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>{state}</MDTypography>
                            </Grid>

                            <Grid item sm={1} textAlign="end">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>
                                    {`${startYear}-${endYear}`}
                                </MDTypography>
                            </Grid>
                            <Grid item sm={3} textAlign="end">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>
                                    {degreeDiploma}
                                </MDTypography>
                            </Grid>
                            <Grid item sm={3} textAlign="end">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>{major}</MDTypography>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item sm={1} mt={0.5}>
                        <MDBox textAlign="right">
                            <Icon
                                color="error"
                                fontSize="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onRemove(idx);
                                }}
                            >
                                cancel
                            </Icon>
                        </MDBox>
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};

export default EducationItem;