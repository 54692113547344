import { useAppContextController } from "context/AppContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";
import createPartnerApplicant from "layouts/pages/partners/actions/createPartnerApplicant";
import { useQueryClient } from "react-query";

const usePartnerLeaderChecker = ({ currentPartner, setCurrentPartner }) => {
  const { currentLoggedUser } = useAppContextController();

  const [newCreatedLeader, setNewCreatedLeader] = useState(null);
  const queryClient = useQueryClient();

  const createNewLeader = useCallback(async () => {
    try {
      if (!currentPartner || currentPartner.status === "Deleted") {
        return;
      }

      // Check if partner already has an assigned partner
      const applicantsResult = await fetchApplicants({
        filters: { partnerSlug: currentPartner.slug, status: "Leader" },
        fetchAll: true,
      });

      // if (!applicantsResult?.data) {
      //   // No applicant using this partner ID. Try with email or phone
      //   if (currentPartner.leader?.email) {
      //     applicantsResult = await fetchApplicants({
      //       filters: { email: currentPartner.leader?.email, status: "Leader" },
      //       fetchAll: true,
      //     });
      //   } else if (currentPartner.leader?.phone) {
      //     applicantsResult = await fetchApplicants({
      //       filters: { phone: currentPartner.leader?.phone, status: "Leader" },
      //       fetchAll: true,
      //     });
      //   } else {
      //     return;
      //   }
      // }

      if (applicantsResult && applicantsResult.data && applicantsResult.data.length) {
        // Applicant exists
        setNewCreatedLeader(applicantsResult.data[0]);
      } else if (currentPartner.leader?.email) {
        // Applicant doesn't exist. Create one and select it
        const resultCreate = await createPartnerApplicant({ id: currentPartner?._id });
        setNewCreatedLeader(resultCreate.newApplicant);
        await queryClient.invalidateQueries(["partners"]);
        await queryClient.invalidateQueries(["getPartnerApplicantDetails"]);
        await queryClient.invalidateQueries(["getPartnerUserDetails"]);
        setCurrentPartner?.({
          ...currentPartner,
          leader: {
            ...(currentPartner.leader ?? {}),
            id: resultCreate.newApplicant._id,
          },
          modifiedDate: new Date(),
        });
      }
    } catch (e) {
      //
    }
  }, [currentPartner, queryClient, setCurrentPartner]);

  useEffect(() => {
    if (currentPartner && currentPartner.leader?.email && !currentPartner.leader?.id) {
      createNewLeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartner]);

  const currentPartnerLeader = useMemo(() => {
    if (!currentPartner || currentPartner.status === "Deleted") {
      return null;
    }
    if (currentPartner && !currentPartner.leader?.id) {
      return newCreatedLeader ? { ...newCreatedLeader } : null;
    }
    return { ...currentPartner.leader, _id: currentPartner.leader.id };
  }, [currentPartner, newCreatedLeader]);

  return {
    currentPartnerLeader,
  };
};

export default usePartnerLeaderChecker;
