import { useCallback, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import notificationReducer, {
  notificationInitialState,
  NOTIFICATION_ACTIONS,
} from "reducers/notificationReducer";

const useNotifications = () => {
  const { notificationId } = useParams();

  const [state, dispatch] = useReducer(notificationReducer, notificationInitialState);

  const setNotification = useCallback((notification) => {
    dispatch({ type: "SET_NOTIFICATION", data: notification });
  });

  const setNotifications = useCallback((notification) => {
    dispatch({ type: "SET_NOTIFICATIONS", data: notification });
  });

  const setActiveActions = useCallback((actions) => {
    dispatch({ type: "SET_ACTIVE_ACTIONS", data: actions });
  });

  const setTableFilter = useCallback((filter) => {
    dispatch({ type: "SET_TABLE_FILTER", data: filter });
  });

  const setTableRefetch = useCallback((fn) => {
    dispatch({ type: "SET_REFETCH", fn });
  });

  useEffect(() => {
    if (notificationId) {
      const notification = state.notifications?.find((item) => item._id === notificationId);
      if (notification) {
        setNotification(notification);
        setActiveActions([NOTIFICATION_ACTIONS.NOTIFICATION_INFO]);
      }
    }
  }, [notificationId, state.notifications]);

  return {
    ...state,
    setActiveActions,
    setNotification,
    setTableFilter,
    setNotifications,
    setTableRefetch,
  };
};

export default useNotifications;
