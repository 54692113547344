import { Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";

const useStyle = makeStyles({
  text: {
    color: "white",
    textAlign: "center",
  },
});

const ConflictEventModal = ({ open, setOpen, handleClose, onClick, onCancel }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const classes = useStyle();
  const header = (
    <MDTypography variant="h5" className={classes.text}>
      You have already signed up for another event during this time. Please review your selections
      and either remove from an existing event or select another day.
    </MDTypography>
  );

  const buttons = (
    <>
      <Box display="flex" flexDirection="column">
        <Button
          variant="contained"
          color="white"
          sx={{
            color: "red!important",
          }}
          onClick={onClick}
        >
          view conflicting event
        </Button>

        <Button variant="text" size="large" onClick={onCancel}>
          <MDTypography variant="bod2" color="white" fontWeight="bold">
            Cancel this sign-up
          </MDTypography>
        </Button>
      </Box>
    </>
  );

  const modalBody = (
    <Box>
      <MDTypography variant="h5" className={classes.text}>
        Contact your event manager if you have any questions or need to discuss working both events
        in the same day.
      </MDTypography>
    </Box>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        backgroundColor="red"
        width={isSmallScreen ? "70%" : "30%"}
        buttonLocation="center"
      />
    </>
  );
};

export default ConflictEventModal;
