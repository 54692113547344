import * as yup from "yup";

const educationSchema = yup.object().shape({
  degreeDiploma: yup
    .string()
    .typeError("Invalid Degree")
    .nullable()
    .required("Degree as is Required"),
  city: yup.string().typeError("Invalid City").nullable().required("City is Required"),
  endYear: yup.number().typeError("Invalid End Year").nullable().required("End Year Required"),
  mayor: yup
    .string()
    .typeError("Invalid Mayor")
    .nullable(),
  minor: yup
    .string()
    .typeError("Invalid Mayor")
    .nullable(),
  schoolName: yup
    .string()
    .typeError("Invalid School Name")
    .nullable()
    .required("School Name is Required"),
  startYear: yup.number().typeError("Invalid Start Year").nullable().required("Start Year Required"),
  state: yup.string().typeError("Invalid State").nullable().required("State is Required"),
  duties: yup
    .string()
    .nullable()
});

export default educationSchema;
