import axios from "axios";

import { baseAxios } from "config";

const deletePartner = async (partnerId) => {
  try {
    if (!partnerId) throw new Error("partnerId required");
    const res = await baseAxios.delete(`/partners/${partnerId}`);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default deletePartner;
