import { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Typography, Grid, IconButton, styled } from "@mui/material";
import GenericModal from "components/GenericModal";
import parse from "html-react-parser";
import CancelIcon from "@mui/icons-material/Cancel";
import DataList from "components/DataList";
import moment from "moment";
import { useAppContextController } from "context/AppContext";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import { useMutation, useQueryClient } from "react-query";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import AddANote from "components/AddANote";
import useDropdowns from "hooks/useDropdowns";
import postNote from "layouts/pages/applicants/actions/postNote";
import { useSnackbar } from "notistack";
import Activities from "components/Activities";
import { Close } from "@mui/icons-material";
import CreateEditNoteModal from "../../layouts/pages/partners/components/CreateEditNoteModal";

const ApplicantActivitiesList = ({ open, setOpen, currentApplicant }) => {
  const { currentLoggedUser } = useAppContextController();

  const { dropdowns: noteTypes } = useDropdowns({ entity: "noteTypes" });
  const { enqueueSnackbar } = useSnackbar();

  const header = (
    <Grid container flexDirection="row" justifyContent="space-between">
      <MDTypography
        variant="h5"
        color="dark"
        sx={{ mt: 2, mb: 1, ml: 2 }}
        key={`text_addNote_${currentApplicant?._id}`}
      >
        Activities For: {currentApplicant?.firstName} {currentApplicant?.lastName}
      </MDTypography>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
      >
        <Close fontSize="medium" color="dark" />
      </IconButton>
    </Grid>
  );

  const modalBody = (
    <Activities
      activitiesParent={currentApplicant}
      height="calc(50vh + 50px)"
      tableHeight="50vh"
      noHeader
      contentHeight="70vh"
    />
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "1100px !important",
          overflow: "hidden !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  right: "0%",
  m: 1,
  p: 0,
}));

export default ApplicantActivitiesList;
