export const searchGoogleMaps = ({ address = "", city = "", state = "", zip = "" }) => {
  const search = `${address}+${city}+${state}+${zip}`;
  window.open(`http://maps.google.com/maps?q=loc:${search}&loading=async`);
};

const addressDetails = [
  { name: "address", types: ["locality", "administrative_area_level_3"] },
  { name: "city", types: ["administrative_area_level_2"] },
  { name: "state", types: ["administrative_area_level_1"] },
  { name: "zip", types: ["postal_code"] },
];

export const breakdownAddressComponents = (comp) => {
  let newAddr;
  addressDetails.forEach((item) => {
    const value = comp.find((c) => c?.types?.some((r) => item?.types.includes(r)));
    if (value) newAddr = { ...newAddr, [item?.name]: value?.short_name };
  });
  return newAddr;
};

// TODO: fix this another time

// Function to load the Google Maps JavaScript API asynchronously
// function loadGoogleMapsAPI() {
//   // Check if the Google Maps API script is already loaded
//   if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
//     const script = document.createElement('script');
//     script.src = 'https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap';
//     script.async = true;
//     document.head.appendChild(script);
//   }
// }

// // Ensure the Google Maps API is loaded before opening the map
// export const searchGoogleMaps = ({ address = "", city = "", state = "", zip = "" }) => {
//   loadGoogleMapsAPI(); // Load Google Maps API asynchronously before opening the map
//   const search = `${address}+${city}+${state}+${zip}`;
//   window.open(`http://maps.google.com/maps?q=loc:${search}`);
// };
