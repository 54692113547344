import { useEffect, useMemo, useRef, useState } from "react";
import { Cancel as CancelIcon, Delete as DeleteIcon } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Stack,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useAppContextController } from "context/AppContext";
import { isEmpty, isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import useDropdowns from "hooks/useDropdowns";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import createJob from "api/jobs/createJob";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import CustomTextField from "components/Form/CustomTextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { companyJobsInfoSchema, isJobSlugUnique } from "data/companyJobs";

import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";
import fetchCustomers from "api/companies/fetchCustomers";
import fetchSingleJob from "layouts/pages/jobs/actions/fetchSingleJob";
import generateEmail from "utils/generateEmail";
import generateSlug from "utils/generateSlug";
import { getDirtyFields } from "utils/helpers/formHelpers";
// import CopyJobURLModal from "layouts/pages/jobs/components/CopyJobURLModal";
import useSessionAuth from "hooks/useSessionAuth";
import useCompanyStore from "stores/useCompanyStore";
import JobPositions from "layouts/pages/jobs/components/JobPositions";
import updateJob from "../../actions/updateJob";
import CompanyJobPreview from "../CompanyJobPreview";
import postNote from "../../actions/postNote";

const CompanyJobForm = ({
  setRefetchJobs,
  copyUrlHandler,
  setPreviewContent,
  previewContent,
  panelOptions,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const { currentJob, setCurrentJob, currentLoggedUser, company, userType } =
    useAppContextController();

  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const { dropdowns: jobStatuses } = useDropdowns({ entity: "jobStatuses" });
  const { dropdowns: jobTypes } = useDropdowns({ entity: "jobTypes" });

  const resolver = useYupValidationResolver(companyJobsInfoSchema);
  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    getValues,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: {
      ...currentJob,
      positions: currentJob?.positions
        ? currentJob?.positions
        : [...Array(currentJob?.positionQuantity ?? 1).keys()].map((_, index) => ({
            status: "Open",
            name: `Position ${index + 1}`,
            startDate: null,
            modifiedDate: new Date(),
          })),
    },
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [hasAddedRecipientPermission, setHasAddedRecipientPermission] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const customerLocationListRef = useRef([]);
  const [copyCompanyJobName, setCopyCompanyJobName] = useState("");
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [tempJobStatus, setTempJobStatus] = useState("");
  const copyJobSlug = searchParams.get("copyJobSlug");
  const isEdit = !!currentJob?._id;

  const { logoutAuth0User } = useSessionAuth();

  const { data: customers, isLoading } = useQuery(["customerdropdown"], () =>
    fetchCustomers({
      filters: { status: "Active" },
      fetchAll: true,
      order: "asc",
      orderBy: "name",
    })
  );

  const jobDefaultValue = {
    positionStatus: "Open",
    type: "Direct Hire",
    title: "",
    companyName: "",
    jobLocation: "",
    jobId: "",
    jobSlug: "",
    address: "",
    jobEmail: "",
    companyCity: "",
    companyState: "",
    companySlug: "",
    status: "Active",
    notificationRecipients: [],
    markupRate: 0,
    payRate: 0,
    billRate: 0,
    wCCode: "",
    positionQuantity: 1,
    approvingManager: "",
    recruiter: "",
    jobLogo: "No",
    companyThumb: "Yes",
    googleMap: "Yes",
    hideThisJob: "No",
    showCustomer: "No",
    jobAndApplicantPermissions: [],
    description: "",
    lockMarkup: "No",
    tags: [],
    attachments: [],
    positions: [...Array(currentJob?.positionQuantity ?? 1).keys()].map((_, index) => ({
      status: "Open",
      name: `Position ${index + 1}`,
      startDate: null,
      modifiedDate: new Date(),
    })),
  };

  const refreshCurerentJobPositionStatus = async (newCurrentJob) => {
    const data = await queryClient.fetchQuery({
      queryKey: ["refreshJob", currentJob?._id],
      queryFn: () => fetchSingleJob(null, currentJob?._id),
    });

    const updatedJob = data?.data?.job;
    if (updatedJob) {
      setCurrentJob({
        ...newCurrentJob,
        positionStatus: updatedJob?.positionStatus,
        notes: updatedJob?.notes,
      });
    }
  };

  const updateJobMutation = useMutation(updateJob, {
    onSuccess: async (_, { data, notesToPush, jobSlug }) => {
      await queryClient.invalidateQueries("jobs");
      if (data.status === "Deleted") {
        setCurrentJob({});
        enqueueSnackbar("Job was archived", { variant: "success" });
        navigate(`/companyjobs`);
      } else {
        setCurrentJob({ ...currentJob, ...data, modifiedDate: new Date() });
        enqueueSnackbar("Job has been updated!", { variant: "success" });

        // Push notes, if available
        if (notesToPush && notesToPush.length > 0) {
          await Promise.all(
            notesToPush.map((noteToPush) => {
              // Post note
              return postNote({ jobSlug, data: noteToPush })
                .then((response) => {
                  // enqueueSnackbar("Notes have been successfully updated", { variant: "success" });
                })
                .catch((response) => {
                  enqueueSnackbar(`Something went wrong trying to create a note`, {
                    variant: "error",
                  });
                });
            })
          );
        }

        refreshCurerentJobPositionStatus({ ...currentJob, ...data, modifiedDate: new Date() });
      }
    },
  });

  const companyListOfNames = useMemo(() => {
    if (isEmpty(customers)) return [];
    return customers?.data.map((info, index) => info.name);
  }, [customers?.data]);

  const onLocationChange = (value, field) => {
    if (!company || !customers) return;

    let jobLocation = [];
    if (isEqual(field, "jobLocation")) {
      jobLocation = customerLocationListRef?.current?.find((location) =>
        isEqual(location.locationName, value)
      );
    }

    const customerInfo = customers?.data.find((customer) =>
      isEqual(customer.name, getValues("companyName"))
    );
    setCurrentCompany(customerInfo);
    const activeLocation = isEqual(field, "customerName") ? customerInfo : jobLocation;
    if (isEqual(field, "customerName")) setValue("companySlug", customerInfo?.slug);
    // setValue("logoUrl", customerInfo[0]?.squareLogoUrl);
    setValue("address", activeLocation?.address);
    setValue("companyCity", activeLocation?.city);
    setValue("companyState", activeLocation?.state);
    setValue("zip", activeLocation?.zip);
  };

  const changePayRateHandler = (value) => {
    if (
      getValues("markupRate") !== jobDefaultValue.markupRate &&
      value !== jobDefaultValue.payRate
    ) {
      const markupRate = (parseFloat(getValues("markupRate")) / 100).toFixed(2);
      const payRate = parseFloat(value);
      const calcBillRate = payRate + payRate * markupRate;
      setValue("billRate", calcBillRate);
    }
  };
  const changeBillRateHandler = (value) => {
    if (
      value !== jobDefaultValue.billRate &&
      getValues("payRate") !== jobDefaultValue.payRate &&
      getValues("lockMarkup") === "No"
    ) {
      const billRate = parseFloat(value);
      const payRate = parseFloat(getValues("payRate"));
      const markupRate = (((billRate - payRate) * 100) / payRate).toFixed(2);
      setValue("markupRate", markupRate);
    }
  };
  const changeMarkupHandler = (value) => {
    if (
      value !== jobDefaultValue.markupRate &&
      getValues("payRate") !== jobDefaultValue.payRate &&
      getValues("lockMarkup") === "No"
    ) {
      const markupRate = (parseFloat(value) / 100).toFixed(2);
      const payRate = parseFloat(getValues("payRate"));
      const calcBillRate = payRate + payRate * markupRate;

      setValue("billRate", calcBillRate);
    }
  };

  const editCompanyJobHandler = async (values) => {
    if (!currentJob._id) {
      enqueueSnackbar("Cannot Update Job -- No ID Present!", { variant: "error" });
      return;
    }

    const modifiedValues = getDirtyFields(values, dirtyFields);
    const notesToPush = [];
    for (let index = 0; index < modifiedValues?.positions?.length ?? 0; index += 1) {
      const pos = modifiedValues.positions[index];
      // Remove isNew flag
      if (pos.isNew != null) delete modifiedValues.positions[index].isNew;
      // Remove notes
      if (pos.note != null) {
        notesToPush.push({ ...modifiedValues.positions[index].note });
        delete modifiedValues.positions[index].note;
      }
    }

    await updateJobMutation.mutateAsync({
      jobId: currentJob._id,
      data: hasAddedRecipientPermission
        ? {
            ...modifiedValues,
            payRate: parseFloat(values.payRate),
            billRate: parseFloat(values.billRate),
            markupRate: parseFloat(values.markupRate),
            notificationRecipients: getValues("notificationRecipients"),
          }
        : {
            ...modifiedValues,
            payRate: parseFloat(values.payRate),
            billRate: parseFloat(values.billRate),
            markupRate: parseFloat(values.markupRate),
          },
      notesToPush,
      jobSlug: currentJob.jobSlug,
    });
  };

  const deleteJobHandler = async () => {
    await updateJobMutation.mutateAsync({
      jobId: currentJob._id,
      data: { status: "Deleted", deleteAgent: currentLoggedUser._id },
    });
  };

  const createJobMutation = useMutation(createJob, {
    // onError: (err) => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("jobs");
      enqueueSnackbar("Job has been created!", { variant: "success" });
      navigate(`/companyjobs/${data.jobSlug}`);
      setCurrentJob({
        ...currentJob,
        ...data,
        _id: _.data.result.insertedId,
        [_.data.id.name]: _.data.id[_.data.id.name],
      });
    },
  });

  const createJobHandler = async (values) => {
    if (await isJobSlugUnique(values.jobSlug)) {
      const updatedValues = { ...values };
      if (copyJobSlug) updatedValues.copyJobSlug = copyJobSlug;
      updatedValues.billRate = parseFloat(values.billRate);
      updatedValues.payRate = parseFloat(values.payRate);
      updatedValues.markupRate = parseFloat(values.markupRate);

      // Remove isNew flag
      for (let index = 0; index < updatedValues?.positions?.length ?? 0; index += 1) {
        const pos = updatedValues.positions[index];
        if (pos.isNew != null) delete updatedValues.positions[index].isNew;
      }

      if (
        company?.aiInterviewsAvailable === "Yes" &&
        company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
        currentCompany?.aiSettings?.aiInterviewsEnabled === "Yes" &&
        (currentCompany?.aiSettings?.aiInterviewsEnabledByDefault === "Yes" ||
          (currentCompany?.aiSettings?.aiInterviewsEnabledByDefault !== "No" &&
            company?.aiSettings?.aiInterviewsEnabledByDefault.includes("jobs")))
      ) {
        updatedValues.aiSettings = {
          aiInterviewsEnabled: "Yes",
          displayCustomerQuestions: "",
          autoInvite: "No",
          autoGenerateResumeQuestions: "No",
          autoScreening: "No",
          questions: [],
        };
      }

      await createJobMutation.mutateAsync({
        data: { ...updatedValues, createAgent: currentLoggedUser._id },
      });
      setRefetchJobs((prev) => !prev);
    } else enqueueSnackbar("Job Url already exist", { variant: "error" });
  };

  const deleteModalHandler = () => setOpenDeleteModal(!openDeleteModal);
  const discardModalHandler = () => setOpenDiscardModal(!openDiscardModal);
  const cancelModalHandler = () => setOpenCancelModal(!openCancelModal);

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setOpenPreview(!openPreview);
  };

  const [jobTitle, companySlug] = watch(["title", "companySlug"]);
  const companyName = watch("companyName");

  const handlePositionChange = (positions) => {
    setValue("positionQuantity", positions.length, { shouldDirty: true });
    setValue("positions", positions, { shouldDirty: true });
  };

  useEffect(() => {
    if (!isEdit && companySlug?.length && jobTitle?.length) {
      (async () => {
        try {
          const { slug } = await generateSlug({
            venueSlug: companySlug,
            title: jobTitle.trim(),
            slugField: "jobSlug",
            fetchFunction: fetchCompanyJob,
          });
          const mail = await generateEmail({
            slug,
            venueSlug: company?.slug,
          });
          setValue("jobEmail", mail);
          setValue("jobSlug", slug);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      })();
    }
  }, [jobTitle, companySlug]); // Adding actual dependencies

  useEffect(() => {
    if (!currentJob?._id && !copyJobSlug) reset(jobDefaultValue);
    else
      reset({
        ...currentJob,
        positions: currentJob?.positions
          ? currentJob?.positions
          : [...Array(currentJob?.positionQuantity ?? 1).keys()].map((_, index) => ({
              status: "Open",
              name: `Position ${index + 1}`,
              startDate: null,
              modifiedDate: new Date(),
            })),
      });
  }, [currentJob]);

  useEffect(() => {
    if (!isEmpty(getValues("companyName"))) {
      const customerInfo = customers?.data.find(
        (customer) =>
          isEqual(customer.name, getValues("companyName")) ||
          currentJob?.companySlug?.includes(customer.slug)
      );
      setCurrentCompany(customerInfo);
    }
  }, [companyName, currentJob?.companySlug, customers?.data]);

  // useEffect(() => {
  //   if (!company?.locations || !company) return;
  //   const jobLocationsTemp = [{ label: "<Original Company Location>", id: "0" }];
  //   company.locations.map((location, index) =>
  //     jobLocationsTemp.push({ label: location.locationName, id: `${location.zip} - ${index}` })
  //   );
  //   setJobLocations(jobLocationsTemp);
  // }, [company?.locations]);

  const fetchCopyJob = async () => {
    try {
      const hasJobSlug = await fetchSingleJob(copyJobSlug);
      const companyJobSlug = hasJobSlug?.data?.job;
      if (companyJobSlug) {
        setCopyCompanyJobName(companyJobSlug?.title);
        companyJobSlug.title = "";
        companyJobSlug.jobSlug = "";
        companyJobSlug.jobAndApplicantPermissions = [];

        delete companyJobSlug?._id;

        reset(companyJobSlug);
        setCurrentJob(companyJobSlug);
        if (!companyJobSlug?.attachments) companyJobSlug.attachments = [];
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  useEffect(() => {
    if (!copyJobSlug) return;
    fetchCopyJob();
  }, [copyJobSlug]);

  useEffect(() => {
    if (!isEdit) return;
    setHasAddedRecipientPermission(
      !isEqual(getValues("notificationRecipients"), currentJob?.notificationRecipients)
    );
  }, [getValues("notificationRecipients")]);

  // useEffect(() => {
  //   const debounceBillRate = debounce(() => {
  //     const markupRate = (parseFloat(watch("markupRate")) / 100).toFixed(2);
  //     const payRate = parseFloat(watch("payRate"));
  //     const calcBillRate = payRate + payRate * markupRate;

  //     setValue("billRate", calcBillRate);
  //   }, 1000);
  //   if (
  //     watch("markupRate") !== jobDefaultValue.markupRate &&
  //     watch("payRate") !== jobDefaultValue.payRate
  //   )
  //     debounceBillRate();
  // }, [watch(["markupRate", "payRate"])]);

  useEffect(() => {
    if (customers?.data) {
      Object.keys(customers?.data).forEach((key) => {
        if (isEqual(customers?.data[key].name, getValues("companyName"))) {
          customerLocationListRef.current =
            customers?.data[key].locations &&
            customers?.data[key].locations.map((location) => ({
              ...location,
              label: location.locationName,
            }));
        }
      });
    }
  }, [customers, getValues("companyName")]);

  return (
    <>
      <form onSubmit={handleSubmit(isEdit ? editCompanyJobHandler : createJobHandler)}>
        <FlexWrapper
          maxWidth="100%"
          justifyContent="flex-end"
          alignItems="center"
          minHeight={38}
          mt={4}
          mb={1}
        >
          <MDBox px={isEdit ? 0 : 3} pt={isEdit ? 0 : 1}>
            {!isEdit && (
              <>
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={cancelModalHandler}
                  disabled={isSubmitting}
                  style={{ color: "#F44335", fontSize: 14 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white", fontSize: 14 }}
                  disabled={isSubmitting}
                >
                  Create
                </Button>
              </>
            )}
            {isEmpty(dirtyFields) && !hasAddedRecipientPermission && (
              <>
                {isEdit && isEqual(userType, "Master") && company?.primaryCompany && (
                  <Button
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={deleteModalHandler}
                    style={{ color: "#F44335", fontSize: 14 }}
                    disabled={isSubmitting}
                  >
                    Delete Job
                  </Button>
                )}
                {isEdit && (
                  <Button
                    variant="text"
                    startIcon={<ContentCopyIcon />}
                    onClick={() =>
                      navigate(`/companyjobs/create?copyJobSlug=${currentJob?.jobSlug}`)
                    }
                    disabled={isSubmitting}
                    style={{ fontSize: 14 }}
                  >
                    Copy Job
                  </Button>
                )}
              </>
            )}
            {(!isEmpty(dirtyFields) || hasAddedRecipientPermission) && (
              <>
                {isEdit && isEqual(userType, "Master") && (
                  <Button
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={discardModalHandler}
                    style={{ color: "#F44335", fontSize: 14 }}
                    disabled={isSubmitting}
                  >
                    Discard
                  </Button>
                )}
                {isEdit && (
                  <Button
                    variant="text"
                    type="submit"
                    style={{ color: "#4CAF50", fontSize: 14 }}
                    startIcon={<SaveIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              </>
            )}
          </MDBox>
        </FlexWrapper>

        <Grid container justifyContent="flex-end" alignItems="center" sx={{ px: "1.5rem", mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Stack alignItems="flex-start">
              <MDTypography sx={{ mr: 2 }} variant="h5" color="dark">
                {isEdit
                  ? `Edit Job: ${currentJob?.title}`
                  : `Create Job ${copyJobSlug ? `from Duplicate ${copyCompanyJobName}` : ""}`}
              </MDTypography>
              {isEdit && (
                <MDTypography variant="body2">
                  Last Update: {moment(currentJob?.modifiedDate).format("ddd MM/DD/YYYY hh:mm a")}
                </MDTypography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox display="flex" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" flexWrap="wrap">
                {panelOptions.map((tab) => (
                  <Tooltip title={tab?.title} key={tab?.title}>
                    <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow} m={1}>
                      <IconButton
                        color={tab.color}
                        aria-label={tab.ariaLabel}
                        onClick={tab.fn}
                        disabled={!isEdit} // || (tab.title === "Sales" && watchStatus !== "Prospect")}
                      >
                        {tab.icon}
                      </IconButton>
                    </MDBox>
                  </Tooltip>
                ))}
              </Stack>
            </MDBox>
          </Grid>
        </Grid>

        {/* </FlexWrapper> */}
        <StyledContainer px={5} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                Job Status
              </MDTypography>
              <Controller
                name="positionStatus"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={jobStatuses || []}
                    name="positionStatus"
                    disabled
                    value={field.value || null}
                    onChange={(e) => {
                      field.onChange(e.target.innerText);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`companySlug_${currentJob?.positionStatus}`}
                        {...params}
                        type="text"
                        label=" "
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                Job Type
              </MDTypography>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={jobTypes || []}
                    name="type"
                    defaultValue={null}
                    value={field.value || null}
                    onChange={(e, v) => {
                      field.onChange(e.target.innerText);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`companySlug_${currentJob?.type}`}
                        {...params}
                        type="text"
                        label=" "
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                Company Information
              </MDTypography>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Switch
                    color="success"
                    checked={field.value === "Active"}
                    onChange={(e) => field.onChange(e.target.checked ? "Active" : "Inactive")}
                  />
                )}
              />
              <MDTypography
                component="label"
                variant="h5"
                fontWeight="bold"
                color={getValues("status") === "Active" ? "success" : "error"}
              >
                {getValues("status") === "Active" ? "Active" : "Inactive"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="companyName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={companyListOfNames}
                    disabled={isEdit}
                    tabIndex="-1"
                    name="companySlug"
                    defaultValue={null}
                    value={field.value || null}
                    onChange={(e, v) => {
                      field.onChange(e.target.textContent);
                      onLocationChange(v, "customerName");
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`companySlug_${currentJob?.companySlug}`}
                        {...params}
                        type="text"
                        label="Company Name"
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="companySlug"
                control={control}
                render={({ field }) => (
                  <FormField
                    type="text"
                    disabled
                    label="Company ID"
                    InputLabelProps={{ shrink: !!getValues("companySlug") }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <FormField
                    type="text"
                    disabled
                    label="Address"
                    InputLabelProps={{ shrink: !!getValues("address") }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="companyCity"
                control={control}
                render={({ field }) => (
                  <FormField
                    type="text"
                    disabled
                    label="City"
                    InputLabelProps={{ shrink: !!getValues("companyCity") }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <Controller
                name="companyState"
                control={control}
                render={({ field }) => (
                  <FormField
                    type="text"
                    disabled
                    label="State"
                    InputLabelProps={{ shrink: !!getValues("companyState") }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                  <FormField
                    type="text"
                    disabled
                    label="Zip Code"
                    InputLabelProps={{ shrink: !!getValues("zip") }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                  Job Information
                </MDTypography>
                <Button onClick={() => openPreviewModal("all")} sx={{ padding: 0 }}>
                  PREVIEW JOB
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="title"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    label="Job Name"
                    disabled={isEmpty(watch("companyName"))}
                    showError
                    errorMessage={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="jobId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    disabled
                    key={`jobId_${currentJob?.companySlug}_${currentJob?.jobId}`}
                    type="text"
                    label="Job ID"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={11.5}>
                  <Controller
                    name="jobEmail"
                    disabled={isEdit}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        disabled
                        key={`jobEmail_${currentJob?.companySlug}_${currentJob?.jobId}`}
                        type="text"
                        label="Job Email"
                        {...field}
                        showError
                        errorMessage={error?.jobEmail}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5}>
                  <Tooltip title="Copy Email">
                    <IconButton
                      color="info"
                      onClick={() => {
                        navigator.clipboard.writeText(getValues().jobEmail);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="jobLocation"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={
                      !isEmpty(customerLocationListRef.current)
                        ? customerLocationListRef.current?.map((location) => location.locationName)
                        : []
                    }
                    disabled={isEmpty(watch("companyName"))}
                    name="jobLocation"
                    value={getValues("jobLocation") ? field.value : null}
                    onChange={(event, value) => {
                      field.onChange(event.target.textContent);
                      onLocationChange(value.label, "jobLocation");
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        key={`jobLocation_${currentCompany?.eventId}`}
                        type="text"
                        label="Location"
                        showError
                        errorMessage={error?.message}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  <Controller
                    name="jobSlug"
                    control={control}
                    disabled={isEdit}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        disabled
                        key={`jobSlug_${currentJob?.companySlug}_${currentJob?.jobId}`}
                        type="text"
                        label="URL"
                        {...field}
                        showError
                        errorMessage={error?.jobSlug}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Copy Url">
                    <IconButton
                      color="info"
                      onClick={() => {
                        navigator.clipboard.writeText(getValues().jobSlug);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="wCCode"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField type="text" label="WC Code" {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="positionQuantity"
                key={`positionQuantity_${currentCompany?.id}`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericFormat
                    InputProps={{
                      inputProps: {
                        inputMode: "numeric",
                      },
                    }}
                    type="number"
                    label="Position Qty"
                    decimalScale={0}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue > 0;
                    }}
                    showError
                    errorMessage={error?.message}
                    {...field}
                    disabled={isEdit}
                    customInput={CustomTextField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="payRate"
                key={`payRate_${currentCompany?.id}`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      inputProps: {
                        style: { textAlign: "end" },
                        inputMode: "numeric",
                      },
                    }}
                    onValueChange={(e) => {
                      changePayRateHandler(e.value);
                    }}
                    label="Pay Rate"
                    decimalScale={2}
                    showError
                    errorMessage={error?.message}
                    {...field}
                    customInput={CustomTextField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="billRate"
                key={`billRate_${currentCompany?.id}`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericFormat
                    disabled={watch("lockMarkup") === "Yes"}
                    InputProps={{
                      startAdornment: "$",
                      inputProps: {
                        style: { textAlign: "end" },
                        inputMode: "numeric",
                      },
                    }}
                    label="Bill Rate"
                    decimalScale={2}
                    onValueChange={(e) => {
                      changeBillRateHandler(e.value);
                    }}
                    showError
                    errorMessage={error?.message}
                    {...field}
                    customInput={CustomTextField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="component-helper">
                  {" "}
                  Markup Rate
                  <Controller
                    name="lockMarkup"
                    control={control}
                    render={({ field: fieldLockup }) => {
                      return (
                        <IconButton
                          onClick={() => {
                            if (fieldLockup?.value === "Yes") {
                              setValue("lockMarkup", "No");
                            } else {
                              setValue("lockMarkup", "Yes");
                            }
                          }}
                        >
                          {fieldLockup?.value === "Yes" ? (
                            <LockIcon fontSize="medium" sx={{ color: "red" }} />
                          ) : (
                            <LockOpenIcon fontSize="medium" sx={{ color: "#1A73E8" }} />
                          )}
                        </IconButton>
                      );
                    }}
                  />
                </InputLabel>

                <Controller
                  name="markupRate"
                  key={`markupRate_${currentCompany?.id}`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <NumericFormat
                        disabled={watch("lockMarkup") === "Yes"}
                        inputProps={{
                          style: { textAlign: "end", width: "100%" },
                          inputMode: "numeric",
                          width: "100%",
                        }}
                        onValueChange={(e) => {
                          changeMarkupHandler(e.value);
                        }}
                        endAdornment="%"
                        decimalScale={2}
                        // showError
                        // errorMessage={error?.message}
                        {...field}
                        customInput={Input}
                      />
                    </>
                  )}
                />
              </FormControl>
            </Grid>

            {isEdit && (
              <Grid item width="100%" mt={-1.5}>
                <JobPositions
                  positions={watch("positions") || []}
                  watch={watch}
                  setValue={setValue}
                  onPositionChange={handlePositionChange}
                  jobSlug={currentJob?.jobSlug}
                />
              </Grid>
            )}

            {/* <Grid item xs={12}>
              <MDBox>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MDTypography component="label" variant="body2">
                    Job Description
                  </MDTypography>
                  <Button onClick={() => openPreviewModal("description")} sx={{ padding: 0 }}>
                    PREVIEW
                  </Button>
                </Stack>

                <MDBox>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { value, onChange, ...props } }) => (
                      <MDEditor
                        {...props}
                        theme="snow"
                        onChange={(text) => onChange(text)}
                        value={value}
                        placeholder="Write Job Description"
                        style={{ height: isEdit ? 335 : 450 }}
                      />
                    )}
                  />
                </MDBox>
              </MDBox>
            </Grid> */}
          </Grid>
        </StyledContainer>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
          <MDTypography color="info" variant="h6">
            COPY JOB SITE URLS
          </MDTypography>
          <IconButton color="info" onClick={copyUrlHandler}>
            <ContentCopyIcon />
          </IconButton>
        </Stack> */}
      </form>
      {openDeleteModal && (
        <ConfirmModal
          title="Delete Job"
          description="Please confirm you want to DELETE this job."
          isOpen={openDeleteModal}
          negativeBtn={{
            label: "Cancel",
            fn: deleteModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deleteJobHandler,
            isShown: true,
          }}
        />
      )}
      {openDiscardModal && (
        <ConfirmModal
          title="Data has Changed"
          description="Please confirm you want to abandon changes."
          isOpen={openDiscardModal}
          negativeBtn={{
            label: "Cancel",
            fn: discardModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              reset(currentJob);
              navigate(`/companyjobs/${currentJob?.jobSlug}`);
              discardModalHandler();
            },
            isShown: true,
          }}
        />
      )}

      {openCancelModal && (
        <ConfirmModal
          title="You are leaving create mode"
          description="Please confirm you want to abandon new company job."
          isOpen={openCancelModal}
          negativeBtn={{
            label: "Cancel",
            fn: cancelModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              setCurrentJob({});
              navigate("/companyjobs");
              cancelModalHandler();
            },
            isShown: true,
          }}
        />
      )}

      {currentCompany && (
        <CompanyJobPreview
          open={openPreview}
          setOpen={setOpenPreview}
          content={previewContent}
          closeHandler={() => setOpenPreview(!openPreview)}
          currentPreview={watch()}
          customer={currentCompany}
        />
      )}
    </>
  );
};

export default CompanyJobForm;

const StyledContainer = styled(MDBox)(() => ({
  height: 660,
  overflowY: "auto",
}));
