import * as yup from "yup";



export const californiaStateFormSchema = yup.object().shape({
    filingStatus: yup.string().required("Filling status is required"),
    regularWithholdingAllowances: yup.number().typeError("You must enter a number for item 1a").moreThan(-1).lessThan(20).default(0),
    estimatedDeductionsAllowances: yup.number().typeError("You must enter a number for item 1b").moreThan(-1).lessThan(20).default(0),
    totalAllowances: yup.number().typeError("You must enter a number for item 1c").moreThan(-1).default(0),
    additionalWithholding: yup.number().typeError("You must enter a number for item 2").moreThan(-1).default(0),
    claimExemption: yup.string().nullable(),
});
