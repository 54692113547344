import { isEqual } from "lodash";
import { useState } from "react";
import { Alert, Card, Snackbar, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import FileDropzone from "components/Dropzone";
import updateIssue from "api/setup/updateIssue";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import uploadFileIssue from "../../actions/uploadFileIssue";

const initialAttachment = { file: null };

const UploadFileModal = () => {
  const { currentIssue, setCurrentIssue } = useAppContextController();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [attachmentDetails, setAttachmentDetails] = useState(initialAttachment);
  const { mutateAsync: uploadFileMutation } = useMutation(uploadFileIssue);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const { mutateAsync: updateIssueMutation } = useMutation(updateIssue);


  const uploadFileHandler = async (form, filePath, fileExt) => {
    const newAttachment = {
      docType: fileExt,
      filename: filePath,
      uploadDate: new Date(),
    };
    const newAttachmentList = currentIssue?.attachments ? [...currentIssue?.attachments] : [];
    newAttachmentList.push(newAttachment);
    const updatedIssue = { attachments: newAttachmentList };

    uploadFileMutation(
      { category: currentIssue.category, uploadForm: form, id: currentIssue._id },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("issues");
          enqueueSnackbar(`${currentIssue.issueTitle} File has been uploaded.`, { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );

    updateIssueMutation(
      { id: currentIssue?._id, data: updatedIssue },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries("issues");
          setCurrentIssue({ ...currentIssue, ...updatedIssue });
          enqueueSnackbar("Issue has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };


  const onFileDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles.length <= 0) return;

    const formUpload = new FormData();
    const uploadedFile = acceptedFiles[0];
    const uploadedFileExtension = uploadedFile?.path?.split(".").pop();

    formUpload.append("file", uploadedFile);

    if (currentIssue?._id) uploadFileHandler(formUpload, uploadedFile.path, uploadedFileExtension);

    setAttachmentDetails(initialAttachment);
  };

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <FileDropzone message="Drop files here to upload" onDrop={onFileDropHandler} />
    </>
  );
};

const StyledContainerModal = styled(Card)(({ theme }) => ({}));

export default UploadFileModal;
