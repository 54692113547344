import { Button, Grid, Box } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CustomTextField from "components/Form/CustomTextField";

const AddObjectValues = ({ tags, setValue, onAdd, value, errors }) => {
  return (
    <Grid container spacing={1} mb={2} alignItems="center">
      <Grid item xs={10}>
        <Grid container spacing={2} alignItems="center">
          {tags.map((tag) => (
            <Grid item xs={3} key={tag}>
              <CustomTextField
                type="text"
                label={tag}
                value={value?.[tag]}
                onChange={(e) => {
                  setValue({ ...value, [tag]: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onAdd();
                  }
                }}
                showError={errors?.[tag]}
                errorMessage={errors?.[tag]?.message}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {tags?.length > 0 && (
        <Grid item xs={2} textAlign="right">
          <Button variant="contained" endIcon={<AddCircleRoundedIcon />} onClick={onAdd}>
            add
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AddObjectValues;
