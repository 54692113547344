import * as yup from "yup";

const jobHistorySchema = yup.object().shape({
  companyName: yup
    .string()
    .typeError("Invalid Company Name")
    .nullable()
    .required("Company Name is Required"),
  address: yup.string().typeError("Invalid Address").nullable().required("Address is Required"),
  city: yup.string().typeError("Invalid City").nullable().required("City is Required"),
  state: yup.string().typeError("Invalid State").nullable().required("State is Required"),
  zip: yup.string().typeError("Invalid Zip Code").nullable().required("Zip Code is Required"),
  phone: yup
    .string()
    .test({
      name: "validate-phone",
      test: (value, ctx) => {
        if (value) {
          const trimValueLength = value.replace(/\D/g, "").length;

          if (trimValueLength === 10 || trimValueLength === 0 || !value) {
            return true;
          }
          return ctx.createError({ message: "Phone number must be 10 digits" });
        }
        return true;
      },
    })
    .typeError("Invalid Phone")
    .nullable()
    .required("Phone is Required"),
  supervisor: yup
    .string()
    .typeError("Invalid Supervisor")
    .nullable()
    .required("Supervisor is Required"),
  fromDate: yup.date().typeError("Invalid Start Date").nullable().required("Start Date Required"),
  toDate: yup.date().typeError("Invalid End Date").nullable().required("End Date Required"),
  startingTitle: yup
    .string()
    .typeError("Invalid Hired as")
    .nullable()
    .required("Hired as is Required"),
  lastTitle: yup
    .string()
    .typeError("Invalid Last Position")
    .nullable()
    .required("Last Position is Required"),
  fullPartTime: yup
    .string()
    .typeError("Invalid Employment Status")
    .nullable()
    .required("Employment Status is Required"),
  startingSalary: yup
    .number()
    .typeError("Invalid Starting Salary")
    .nullable()
    .required("Starting Salary is Required"),
  endingSalary: yup
    .number()
    .typeError("Invalid Ending Salary")
    .nullable()
    .required("Ending Salary is Required"),
  salaryUnit: yup
    .string()
    .typeError("Invalid Salary Unit")
    .nullable()
    .required("Salary Unit is Required"),
  duties: yup
    .string()
    .typeError("Invalid Duties & Responsibilities")
    .nullable()
    .required("Duties & Responsibilities is Required"),
  reasonForLeaving: yup
    .string()
    .typeError("Invalid Reason For Leaving")
    .nullable()
    .required("Reason For Leaving is Required"),
});

export default jobHistorySchema;
