import * as yup from "yup";
import moment from "moment";

const timeValidation = yup.object().shape({
  start: yup
    .string()
    .nullable()
    .test(
      "start-required",
      "Start time is required when there is end time",
      function isStartRequired() {
        const { start, end } = this.parent;
        return !(end && !start);
      }
    ),
  end: yup
    .string()
    .nullable()
    .test(
      "end-required",
      "End time is required when there is start time",
      function isEndRequired() {
        const { start, end } = this.parent;
        return !(start && !end);
      }
    ),
});

export const shiftsInfoSchema = yup.object().shape({
  location: yup.object().required("Location is required").typeError("Location is required"),
  startDate: yup.string().required("Start Date is required"),
  endDate: yup
    .string()
    .required("End Date is required")
    .test(
      "is-after-start-date",
      "End Date must be same or after Start Date",
      function isAfterStartDate(value) {
        const { startDate } = this.parent;
        return moment(value).isSameOrAfter(moment(startDate));
      }
    ),
  shifts: yup.array().of(
    yup.object().shape({
      shiftName: yup.string().required("Shift Name is required"),
      shiftStartDate: yup
        .string()
        .required("Shift Start Date is required")
        .test(
          "is-within-job-start-date",
          "Shift Start Date must not be before Job Start Date",
          function isWithinJobStartDate(value) {
            const { startDate } = this.from[1].value;
            return moment(value).isSameOrAfter(moment(startDate));
          }
        ),
      shiftEndDate: yup
        .string()
        .required("Shift End Date is required")
        .test(
          "is-after-start-date",
          "Shift End Date must be same or after Shift Start Date",
          function isAfterStartDate(value) {
            const { shiftStartDate } = this.parent;
            return moment(value).isSameOrAfter(moment(shiftStartDate));
          }
        )
        .test(
          "is-within-job-end-date",
          "Shift End Date must not be after Job End Date",
          function isWithinJobEndDate(value) {
            const { endDate } = this.from[1].value;
            return moment(value).isSameOrBefore(moment(endDate));
          }
        ),
      payRate: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .required("Pay Rate is required")
        .typeError("Pay Rate must be a number"),

      billRate: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .required("Bill Rate is required")
        .typeError("Bill Rate must be a number"),
      defaultSchedule: yup.object().shape({
        monday: timeValidation,
        tuesday: timeValidation,
        wednesday: timeValidation,
        thursday: timeValidation,
        friday: timeValidation,
        saturday: timeValidation,
        sunday: timeValidation,
      }),
    })
  ),
});
