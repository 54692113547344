import parse from "html-react-parser";
import { Grid, Typography, styled } from "@material-ui/core";
import { Box, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useAppContextController } from "context/AppContext";
import LanguageIcon from "@mui/icons-material/Language";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDBox from "components/MDBox";
import GenericModal from "components/GenericModal";

const CompanyJobPreview = ({ content, open, setOpen, closeHandler, customer }) => {
  const { company, currentJob } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;

  const body = (
    <>
      {content === "all" && (
        <>
          <CloseEditorButton onClick={closeHandler}>
            <CancelIcon
              color="white"
              sx={{ border: "1px white solid", borderRadius: "20px", padding: 0 }}
            />
          </CloseEditorButton>
          <Box sx={{ height: "18rem", width: "100%", zIndex: -1 }}>
            <img
              style={{
                objectPosition: "center top",
                objectFit: "cover",
                width: "100%",
                height: "18rem",
              }}
              src={`${IMAGE_SERVER}/company/${company?.slug}/backgroundImage/${company?.backgroundImageUrl}`}
              alt={`${company?.title} Job Preview`}
            />
          </Box>
        </>
      )}

      <StyledModalBody>
        <Grid container>
          {content === "all" && (
            <>
              <Grid item style={{ alignSelf: "center" }}>
                <CompanyLogoSquare
                  company={customer}
                  defaultCompany={company}
                  height={120}
                  width={120}
                />
              </Grid>
              <Grid item>
                <StyledBox>
                  <StyledCompanyName variant="h5">{currentJob?.companyName}</StyledCompanyName>
                  <StyledCompanyName variant="h3">{currentJob?.title}</StyledCompanyName>
                  <StyledHeaderBox>
                    <Stack direction="row" alignItems="center">
                      <MDBox pr={1}>
                        <LocationOnIcon color="error" />
                      </MDBox>
                      <Typography variant="body1">
                        {`${customer?.address}, ${customer?.city} ${customer?.state} ${customer?.zip}`}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <MDBox pr={1}>
                        <LanguageIcon color="info" />
                      </MDBox>
                      <Typography>
                        <a href={currentJob?.jobSlug} target="_blank" rel="noreferrer">
                          {currentJob?.jobSlug}
                        </a>
                      </Typography>
                    </Stack>
                  </StyledHeaderBox>
                </StyledBox>
              </Grid>
              <Grid item sm={12}>
                <StyledBoxDescription>
                  {currentJob?.description && <div>{parse(currentJob.description)}</div>}
                </StyledBoxDescription>
              </Grid>
            </>
          )}
          {content !== "all" && (
            <Grid item sm={12}>
              <StyledBoxDescription>
                {currentJob?.[content] && <div>{parse(currentJob[content])}</div>}
              </StyledBoxDescription>
            </Grid>
          )}
        </Grid>
      </StyledModalBody>
    </>
  );

  if (currentJob && customer)
    return (
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={body}
        borderRadius={5}
        overflow="hidden"
        contentPadding={0}
        width="90%"
        maxWidth={1170}
        maxHeight={940}
      />
    );
  return null;
};

export default CompanyJobPreview;

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "1%",
  right: "1%",
  m: 1,
  p: 0,
}));

const StyledModalBody = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: 5,
  boxShadow: 24,
  padding: "2rem",
  paddingTop: 0,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid #7c97a9`,
  padding: "2rem",
}));

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  color: "#7c97a9",
}));

const StyledBoxDescription = styled(Box)(({ theme }) => ({
  overflowY: "scroll",
  maxHeight: "25rem",
  marginTop: 2,
  padding: 5,
}));

const StyledCompanyName = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  marginBottom: 5,
}));
