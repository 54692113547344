import { styled, SvgIcon, Tooltip } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useAppContextController } from "context/AppContext";
import { ONBOARDING_OBJECTS_ENUM } from "utils/constants/applicant";
import { useTheme } from "@mui/material/styles";


const StyledButton = styled(MDButton)(() => ({
  margin: `${pxToRem(2)}`,
}));

const ButtonTabs = () => {
  const theme = useTheme();
  const { currentApplicant } = useAppContextController();
  const { setActiveStep, activeStep, registrationSteps } = useNewApplicantContext();
  const { ACKNOWLEDGEMENT, COMPLETE } = ONBOARDING_OBJECTS_ENUM;

  return (
    <FlexWrapper justifyContent="flex-start">
      {registrationSteps
        .filter((item) => ![COMPLETE, ACKNOWLEDGEMENT].includes(item.applicantObject))
        .map(({ id, altLabel, label, applicantObject }) => {
          return (
            <StyledButton
              variant="contained"
              color={activeStep === applicantObject ? "info" : "secondary"}
              onClick={() => {
                setActiveStep(id);
              }}
              endIcon={
                label === "Upload ID's" && !currentApplicant?.attachments?.length ? (
                  <Tooltip title="Missing ID's" placement="top">
                    <SvgIcon viewBox="0 0 24 24">
                      <path d="M11 7h2v6h-2zm0 8h2v2h-2z" fill="white" />
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                        fill={theme.palette.error.main}
                      />
                    </SvgIcon>
                  </Tooltip>
                ) : null
              }
            >
              {altLabel || label}
            </StyledButton>
          );
        })}
    </FlexWrapper>
  );
};

export default ButtonTabs;
