import axios from "axios";
import { baseAxios } from "config";

const exportNotes = async ({ applicant, body }) => {
  try {
    const res = await baseAxios.post(`/applicants/${applicant}/note/pdfcreate`, body);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default exportNotes;
