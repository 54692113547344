import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import MergeIcon from "@mui/icons-material/Merge";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { Box, Button, Grid, Stack } from "@mui/material";
import _, { isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Info as InfoIcon } from "@mui/icons-material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import mergeApplicants from "api/applicants/mergeApplicants";
import ApplicantMergeStatus from "components/ApplicantMergeStatus";
import DataTable from "components/DataTable";
import FormErrors from "components/Form/FormErrors";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";
import { useAppContextController } from "context/AppContext";
import { APPLICANT_MERGE_STATUS_ENUM } from "utils/constants/applicant";
import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import useSessionAuth from "hooks/useSessionAuth";
import OnboardingStatus from "components/OnboardingStatus";
import ApplicantStatus from "components/ApplicantStatus";
import MoreInfoModal from "./MoreInfoModal";

const DuplicateApplicantModal = ({ open, setOpen, mergeData, parentComponent, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { venues, currentLoggedUser, company } = useAppContextController();
  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [errors, setErrors] = useState({});
  const [moreInfo, setMoreInfo] = useState(false);

  const { mutate: mergeApplicantsMutation, isLoading } = useMutation(mergeApplicants);

  const isEmployee = parentComponent === "Employee";
  const isApplicant = parentComponent === "Applicant";

  const handleMerge = () => {
    setErrors({});
    // VALIDATION

    // If there are ignored or deleted applicants, allow the user to submit the form without merging anything
    const ignoredApplicant = applicants.find(
      (v) => v.mergeStatus === APPLICANT_MERGE_STATUS_ENUM.IGNORE
    );
    const hasIgnoredApplicant = ignoredApplicant != null;

    const deletedApplicant = applicants.find(
      (v) => v.mergeStatus === APPLICANT_MERGE_STATUS_ENUM.REMOVE
    );
    const hasDeletedApplicant = deletedApplicant != null;

    const primaryApplicant = applicants.find(
      (v) => v.mergeStatus === APPLICANT_MERGE_STATUS_ENUM.PRIMARY
    );
    if (!primaryApplicant && !hasIgnoredApplicant && !hasDeletedApplicant) {
      setErrors({
        primaryError: { message: `There should be one primary ${parentComponent.toLowerCase()}.` },
      });
    }
    const mergeApplicant = applicants.find(
      (v) => v.mergeStatus === APPLICANT_MERGE_STATUS_ENUM.MERGE
    );
    if (!mergeApplicant && !hasIgnoredApplicant && !hasDeletedApplicant) {
      setErrors({
        mergeError: {
          message: `There should be at least one merging ${parentComponent.toLowerCase()}.`,
        },
      });
    }
    if (!isEmpty(errors)) {
      return;
    }

    // Check if there's at least one applicant to merge
    const hasMergedApplicants = !!primaryApplicant && !!mergeApplicant;

    // API CALL
    const BODY = {
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      ...(isEmployee && { mergeType: "Employee" }),
      createAgent: currentLoggedUser._id,
      applicants,
    };
    mergeApplicantsMutation(BODY, {
      onSuccess: () => {
        enqueueSnackbar(
          hasMergedApplicants
            ? `${parentComponent}s have been merged.`
            : `The changes have been saved.`,
          { variant: "success" }
        );
        onSubmit();
        setOpen(false);
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleUpdate = (row, newStatus) => {
    const updatedApplicants = cloneDeep(applicants);
    if (newStatus === APPLICANT_MERGE_STATUS_ENUM.PRIMARY) {
      // Looks for the primary applicant, making sure the we would only have 1 primary applicant.
      const index = updatedApplicants.findIndex(
        (v) => v.mergeStatus === APPLICANT_MERGE_STATUS_ENUM.PRIMARY
      );
      if (index !== -1) {
        updatedApplicants[index].mergeStatus = APPLICANT_MERGE_STATUS_ENUM.MERGE;
      }
    }
    const index = updatedApplicants.findIndex((v) => v._id === row._id);
    if (index !== -1) {
      updatedApplicants[index].mergeStatus = newStatus;
    }
    setApplicants(updatedApplicants);
  };

  const [customerData, setCustomerData] = useState({});
  const { logoutAuth0User } = useSessionAuth();
  useEffect(() => {
    const fetchcustomerData = async () => {
      const compData = {};

      for (let i = 0; i < mergeData.length; i += 1) {
        const record = mergeData[i];

        if (record.companies && record.companies[0]) {
          const slug = record.companies[0].companySlug;

          if (!compData[slug]) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const comp = await fetchSingleCustomer({ slug });
              compData[slug] = comp.data;
            } catch (error) {
              if (String(error).includes("401") || error?.response?.status === 401) {
                logoutAuth0User();
              }
            }
          }
        }
      }

      setCustomerData({ ...compData });
    };

    if (mergeData?.length) {
      try {
        fetchcustomerData();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    setApplicants(mergeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeData]);

  const handleMoreInfo = () => {
    setMoreInfo((prev) => !prev);
  };

  const moreInfoColumn = [
    {
      title: "Address",
      field: "address",
      customCell: (comps, field, row) => row.address ?? row.address1 ?? row.address2,
    },
    {
      title: "City",
      field: "city",
    },
    {
      title: "State",
      field: "state",
    },
    {
      title: "Zip",
      field: "zip",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Date Of Birth",
      field: "birthDate",
      customCell: (birthDate) => (
        <span>{birthDate ? moment(birthDate).format("YYYY-MM-DD") : null}</span>
      ),
    },
    ...(isEmployee
      ? [
          {
            title: "Social Security Number",
            field: "socialSecurity",
          },
        ]
      : []),
    {
      title: "Referral Source",
      field: "referralSource",
    },
    ...(isApplicant
      ? [
          {
            title: "Onboarding Status",
            field: "onboardingStatus",
          },
        ]
      : []),
    ...(isEmployee
      ? [
          {
            title: "Employment Status",
            field: "employmentStatus",
          },
        ]
      : []),
  ];

  const columns = [
    {
      title: "Action",
      field: "mergeStatus",
      customCell: (value, field, row) => {
        return (
          <ApplicantMergeStatus
            status={value}
            handleUpdate={(newStatus) => handleUpdate(row, newStatus)}
          />
        );
      },
    },
    {
      title: "Info",
      field: "",
      customCell: (value, field, row) => {
        return (
          <InfoIcon
            fontSize="large"
            color="info"
            onClick={() => {
              setCurrentRow(row);
              setMoreInfoModalOpen(true);
            }}
          />
        );
      },
    },
    ...(company?.companyType === "Venue"
      ? [
          {
            title: "Venue",
            field: "venues",
            customCell: (vens, field, row) => {
              if (!vens || !vens.length) return "";
              const { venueSlug: vslug } = vens?.[0];
              if (!venues) return "";
              const venue = venues[vslug];

              return (
                <>
                  <VenueIcon
                    logoUrl={venue?.logoUrl}
                    slug={vslug}
                    name={venue?.name}
                    imageWidth="50"
                    imageHeight="50"
                  />
                </>
              );
            },
          },
        ]
      : [
          {
            title: "Customer",
            field: "companies",
            customCell: (comps, field, row) => {
              if (!comps || !comps.length) return "";
              const { companySlug: cslug } = comps?.[0];
              if (!cslug) return "";
              // now fetch the company record
              if (!customerData) return "";
              const cust = customerData[cslug] ? customerData[cslug] : {};
              if (cust.slug) {
                return <CompanyLogoSquare company={cust} defaultCompany={company} />;
              }
              return "";
            },
          },
        ]),
    { title: "Last Name", field: "lastName" },
    { title: "First Name", field: "firstName" },
    {
      title: "Status",
      field: "status",
      customCell: (image, field, row) => <OnboardingStatus applicant={row} size="md" />,
    },
    {
      title: "Applicant Status",
      field: "applicantStatus",
      customCell: (image, field, row) => <ApplicantStatus applicant={row} size="lg" />,
    },
    ...(isEmployee
      ? [
          {
            title: "SSN-4",
            field: "socialSecurity",
            customCell: (ssn) => {
              if (!ssn) return "";
              const numString = ssn.toString();
              const last4Digits = numString.slice(-4);
              return last4Digits;
            },
          },
        ]
      : []),
    ...(isEmployee ? [{ title: "Helm ID", field: "employeeID" }] : []),
    { title: "Phone", field: "phone" },
    { title: "Email Address", field: "email" },
    {
      title: "Apply Date",
      field: "applicationDate",
      customCell: (appDate, field, row) => {
        let date = null;
        const { applicationDate } = row;
        if (applicationDate && moment(applicationDate).isValid()) {
          if (applicationDate.length <= 16) {
            // Bad format, only includes date
            date = moment(applicationDate).add(12, "hours");
          } else {
            date = moment(applicationDate);
          }
        } else if (row.createdDate) {
          date = moment(row.createdDate);
        }
        return date ? date.format("YYYY-MM-DD") : "";
      },
    },
    ...(moreInfo ? moreInfoColumn : []),
    {
      title: "Actions",
      field: "actions",
      type: "button",
    },
  ];

  const header = (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="space-between">
        <MDTypography variant="h4">{parentComponent} Merge</MDTypography>
        <CancelIcon
          onClick={() => {
            handleCancel();
          }}
          sx={{ color: "#AEAEAE", cursor: "pointer" }}
        />
      </Stack>
      <MDTypography variant="h6" color="info" mt={2}>
        Search by {parentComponent} Name and from the results, select the primary record by email to
        make as the new master record.
      </MDTypography>
    </>
  );

  const modalBody = (
    <>
      <Box
        sx={{
          "& table": {
            display: "block",
            overflowX: "auto",
            whiteSpace: "nowrap",
          },
        }}
      >
        <DataTable
          columns={columns}
          data={{ data: applicants }}
          handleMoreInfo={handleMoreInfo}
          moreInfo={moreInfo}
        />
      </Box>
      <FormErrors errors={errors} />
      <Stack display="flex" flexDirection="row" mt={3}>
        <CancelIcon
          fontSize="large"
          color="error"
          imageWidth="50"
          imageHeight="50"
          sx={{ marginRight: "1rem" }}
        />
        <MDTypography>Select red X to delete {parentComponent.toLowerCase()}</MDTypography>
      </Stack>
      <Stack display="flex" flexDirection="row">
        <CheckIcon
          fontSize="large"
          color="success"
          imageWidth="50"
          imageHeight="50"
          sx={{ marginRight: "1rem" }}
        />
        <MDTypography>
          Select green check mark to make this {parentComponent} the Primary record
        </MDTypography>
      </Stack>
      <Stack display="flex" flexDirection="row">
        <MergeIcon
          fontSize="large"
          color="info"
          imageWidth="50"
          imageHeight="50"
          sx={{ marginRight: "1rem" }}
        />
        <MDTypography>
          {parentComponent}s with the Merge Symbol will be merged with Primary Record
        </MDTypography>
      </Stack>
      <Stack display="flex" flexDirection="row">
        <DoDisturbAltIcon
          fontSize="large"
          color="error"
          imageWidth="50"
          imageHeight="50"
          sx={{ marginRight: "1rem" }}
        />
        <MDTypography>
          {parentComponent}s with the red prohibition symbol will be ignored when checking for
          duplicates
        </MDTypography>
      </Stack>
      <Stack
        marginTop="20px"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap="15px"
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "#AEAEAE" }}
          sx={{ px: "30px", fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          startIcon={<SaveIcon />}
          sx={{ px: "30px", fontSize: "16px" }}
          onClick={() => {
            handleMerge();
          }}
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
    </>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "940px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
      {moreInfoModalOpen && (
        <MoreInfoModal
          open={moreInfoModalOpen}
          setOpen={setMoreInfoModalOpen}
          currentRow={currentRow}
          parentComponent={parentComponent}
        />
      )}
    </>
  );
};

export default DuplicateApplicantModal;
