import axios from "axios";
import { baseAxios } from "config";

const generateApplicantPresignedUrl = async ({ applicantId, body }) => {
  try {
    const res = await baseAxios.post(`/applicants/${applicantId}/generate-presigned-url`, body);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default generateApplicantPresignedUrl;
