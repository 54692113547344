import { Box, Paper, Typography, Link } from "@mui/material";
import parse from "html-react-parser";
import { useAppContextController } from "context/AppContext";

const MessageSection = ({ isAvailable }) => {
  const { company: primaryCompany, setCompany: setPrimaryCompany } = useAppContextController();

  return (
    <Paper elevation={3} sx={{ p: "1.5rem", mt: "20px", mb: !isAvailable ? "20px" : undefined }}>
      <Box display="flex" flexDirection="column" rowGap="10px">
        {isAvailable ? (
          <Box>
            {primaryCompany?.onboardingText ? parse(primaryCompany?.onboardingText) : ""}
          </Box>
        ) : (
          <>
            <Typography align="center" variant="h3">
              Content Unavailable
            </Typography>
            {primaryCompany?.companyEmail && (
              <Typography align="center" variant="body1">
                If you think this is a mistake, please send an email to
                <a href={`mailto:${primaryCompany?.companyEmail}`}>
                  {" "}
                  {primaryCompany?.companyEmail}
                </a>
              </Typography>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default MessageSection;
