import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import CustomCard from "components/CustomCard";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import updateCompany from "api/companies/updateCompany";
import FormTextEditor from "components/FormTextEditor";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { createCompanyInfoSchema, DEFAULT_CUSTOMER } from "data/companyInfo";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useCompanyStore from "stores/useCompanyStore";
import PanelActions from "components/PanelActions";
import { FolderCopy } from "@mui/icons-material";
import { getDirtyFields } from "utils/helpers/formHelpers";
import CompanyPreview from "../CompanyPreview";

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const CompanyContentForm = () => {
  const { setCompanyFormState, companyFormState, setCurrentCompany, currentCompany } =
    useCompanyStore((state) => ({
      setCompanyFormState: state.setCompanyFormState,
      companyFormState: state.companyFormState,
      setCurrentCompany: state.setCurrentCompany,
      currentCompany: state.currentCompany,
    }));

  const { company, setCompany, setCompanyType, currentLoggedUser, isMobile } =
    useAppContextController();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [companyStatus, setCompanyStatus] = useState("Active");

  const isEdit = !!currentCompany?._id;
  const validationSchema = createCompanyInfoSchema(companyStatus, isEdit);
  const resolver = useYupValidationResolver(validationSchema);

  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    setError,
    trigger,
    formState,
  } = useForm({ resolver });
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful, dirtyFields } = formState;

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const updateCompanyHandler = (data) => {
    updateCompanyMutation(
      {
        companyId: currentCompany?._id,
        body: {
          ...getDirtyFields(data, dirtyFields),
        },
      },
      {
        onSuccess: () => {
          setCurrentCompany({ ...currentCompany, ...data });
          if (currentCompany?.primaryCompany) {
            setCompany({ ...company, ...data });
          }
          enqueueSnackbar("Company has been updated.", { variant: "success" });
          reset(data, { keepValues: true });
        },
      }
    );
  };
  const watchStatus = watch("status", companyStatus); // Watching the 'status' field for changes

  useEffect(() => {
    setCompanyStatus(watchStatus); // Update the state whenever the 'status' changes
  }, [watchStatus]);

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setPreviewOpen(!isPreviewOpen);
  };

  useEffect(() => {
    if (!isEmpty(currentCompany)) {
      delete currentCompany?.longitude;
      delete currentCompany?.latitude;
      delete currentCompany?.radius;
    }

    reset(isEdit ? currentCompany : DEFAULT_CUSTOMER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, isEdit]);

  useEffect(() => {
    setCompanyFormState({ ...companyFormState, isDirty, dirtyFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields]);

  const handleCancelChanges = () => reset(currentCompany);

  return (
    <form
      onSubmit={handleSubmit(updateCompanyHandler)}
      id="company-setting-form"
      style={{ width: "100%" }}
    >
      <CustomCard
        icon={<FolderCopy color="white" />}
        iconContainerSx={{ backgroundColor: "primary", mx: "1.5rem", mt: "-1.5rem" }}
        cardSx={{ width: "100%", overflow: "visible !important", p: 0, height: 850 }}
        cardActions={
          isDirty && !isEmpty(dirtyFields) && <PanelActions onCancel={handleCancelChanges} />
        }
        cardTitle={`Company Content: ${currentCompany?.name}`}
        actionsSx={isMobile ? {} : { mt: -4, mr: 4 }}
      >
        <Box sx={{ height: 750, overflowY: "auto", p: "1.5rem", pt: 1 }}>
          <FlexWrapper justifyContent="space-between">
            <Grid container alignItems="center">
              {/* <Grid container  xs={12} sm={12} md={12} lg={6}> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FlexWrapper
                  flexWrap="wrap"
                  alignContent="flex-start"
                  justifyContent="flex-start"
                  maxWidth={{ lg: "96%", sm: "100%", xs: "100%" }}
                  height="100%"
                  flexDirection="column"
                >
                  <InputFlexWrapper maxWidth="100%" width="100%" mt={2}>
                    <FormTextEditor
                      title="Company Contact Message"
                      name="companyContactMessage"
                      onClick={() => openPreviewModal("companyContactMessage")}
                      control={control}
                      height="10rem"
                    />
                    <FormTextEditor
                      title="Company Description"
                      name="description"
                      onClick={() => openPreviewModal("description")}
                      control={control}
                      height="14.025rem"
                    />
                    <FormTextEditor
                      title="Acknowledgment"
                      name="acknowledgmentText"
                      onClick={() => openPreviewModal("acknowledgmentText")}
                      control={control}
                      height="14.025rem"
                    />

                    <FormTextEditor
                      title="Onboarding Instructions"
                      name="onboardingText"
                      onClick={() => openPreviewModal("onboardingText")}
                      control={control}
                    />
                    <FormTextEditor
                      title="Decline Job"
                      name="declineJobPageText"
                      onClick={() => openPreviewModal("declineJobPageText")}
                      control={control}
                    />
                  </InputFlexWrapper>
                </FlexWrapper>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </FlexWrapper>
        </Box>
      </CustomCard>
      {isPreviewOpen && (
        <CompanyPreview
          open={isPreviewOpen}
          onClose={() => setPreviewOpen(!isPreviewOpen)}
          content={previewContent}
        />
      )}
    </form>
  );
};

export default CompanyContentForm;
