import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { customPanelCard } from "layouts/pages/customers/styles";
import { useEffect, useState } from "react";
import CustomCard from "components/CustomCard";
import { ContactPage } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import updateCompany from "api/companies/updateCompany";
import DataListContainer from "components/DataListContainer";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { createCompanyInfoSchema, DEFAULT_CUSTOMER } from "data/companyInfo";
import useCompanyCache from "hooks/useCompanyCache";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useCompanyStore from "stores/useCompanyStore";
import AdditionalContactsItem from "../AdditionalContactsItem";
import AdditionalContactsModal from "../AdditionalContactsModal";

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const CompanyContacts = () => {
  const { setCompanyFormState, companyFormState, setCurrentCompany, currentCompany } =
    useCompanyStore((state) => ({
      setCompanyFormState: state.setCompanyFormState,
      companyFormState: state.companyFormState,
      setCurrentCompany: state.setCurrentCompany,
      currentCompany: state.currentCompany,
    }));

  const { company, setCompany, setCompanyType, currentLoggedUser, isMobile } =
    useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [companyStatus, setCompanyStatus] = useState("Active");

  // const resolver = useYupValidationResolver(companyInfoSchema);
  const isEdit = !!currentCompany?._id;
  const validationSchema = createCompanyInfoSchema(companyStatus, isEdit);
  const resolver = useYupValidationResolver(validationSchema);
  const [isContactsDirty, setContactsDirty] = useState(false);
  const sourceComponent = "Company";
  const isVenue = sourceComponent === "Venue";
  const isPartner = sourceComponent === "Partner";
  const isVenueOrPartner = isVenue || isPartner;
  const [isAdditionalContactsModalOpen, setAdditionalContactsModalOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [contactIndex, setContactIndex] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [tempContacts, setTempContacts] = useState(contacts);
  const [primaryInfo, setPrimaryInfo] = useState();

  const contactFields = ["contact", "firstName", "lastName", "email", "phone", "title"];

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    setError,
    trigger,
    formState,
  } = useForm({ resolver });
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful, dirtyFields } = formState;

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);

  const watchStatus = watch("status", companyStatus); // Watching the 'status' field for changes

  useEffect(() => {
    setCompanyStatus(watchStatus); // Update the state whenever the 'status' changes
  }, [watchStatus]);

  const saveAdditionalDetails = async (values) => {
    if (!currentCompany?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: currentCompany._id, body: values },
        {
          onSuccess: async (res) => {
            setCurrentCompany({ ...currentCompany, ...values });
            if (currentCompany?.primaryCompany) {
              setCompany({ ...company, ...values });
            }
            enqueueSnackbar("Company has been updated.", { variant: "success" });
            refetchCompany();
            await queryClient.invalidateQueries("companies");
          },
        }
      );
    }
  };

  const handleMethod = (method, data) => {
    setContactsDirty(false);
    switch (method) {
      case "create":
        setContactIndex(tempContacts?.length);
        setCurrentContact(data[tempContacts?.length]);
        break;
      case "update":
        setContactIndex(contactIndex);
        setCurrentContact(data[contactIndex]);
        break;
      case "primary-only":
        setContactIndex(null);
        setCurrentContact(null);
        break;
      default:
        break;
    }
  };
  const handleContact = (idx, row, isOpen) => {
    setContactIndex(idx);
    setCurrentContact(row);
    setAdditionalContactsModalOpen(isOpen);
  };

  const handleRemoveContact = (idx) => {
    const temporaryContacts = getValues("additionalContacts");
    const updatedContacts = temporaryContacts.filter((_, index) => index !== idx);
    setValue("contacts", updatedContacts);
    saveAdditionalDetails({ additionalContacts: updatedContacts });
    setAdditionalContactsModalOpen(false);
  };

  const renderAdditionalContacts = (row, idx) => (
    <AdditionalContactsItem
      row={row}
      idx={idx}
      onEdit={handleContact}
      onRemove={handleRemoveContact}
    />
  );

  const handleContactsChange = (contactsParam) => {
    setValue("additionalContacts", contactsParam, { shouldDirty: true });
    saveAdditionalDetails(getValues());
  };

  useEffect(() => {
    if (!isEmpty(currentCompany)) {
      delete currentCompany?.longitude;
      delete currentCompany?.latitude;
      delete currentCompany?.radius;
    }

    reset(isEdit ? currentCompany : DEFAULT_CUSTOMER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, isEdit]);

  useEffect(() => {
    setCompanyFormState({ ...companyFormState, isDirty, dirtyFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields]);

  // sets current contact empty and hides form details if no contact is selected or one was just deleted
  const handleEmptyContactReset = () => {
    setContactIndex(null);
    setCurrentContact(null);
  };

  // adapts to new selected index whenever array length changes
  const handleContactsOrderChange = (contactsData) => {
    const updatedIndex = contactsData.findIndex((item) => item === currentContact);
    if (updatedIndex > -1) {
      setContactIndex(updatedIndex);
      setCurrentContact(contactsData[updatedIndex]);
    } else {
      handleEmptyContactReset();
    }
  };

  // sets form values to corresponding contact
  const resetCurrentContact = () => {
    if (currentContact) reset(currentContact);
  };

  const handleCancelChanges = () => {
    resetCurrentContact();
    handleContactsOrderChange(contacts);
    setPrimaryInfo();
    setTempContacts(contacts);
    setContactsDirty(false);
  };

  const handleUpdateCustomer = async (values, method = "update", onSuccess) => {
    const createAgent = currentLoggedUser?._id;
    if (!currentCompany?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: currentCompany?._id, body: { ...values, createAgent } },
        {
          onSuccess: async (res) => {
            setCurrentCompany({ ...currentCompany, ...values });
            await queryClient.invalidateQueries("customers_data");
            onSuccess(method, values?.contacts);
            enqueueSnackbar("Customer contacts has been updated.", { variant: "success" });
          },
        }
      );
    }
  };

  const handleContactSave = (data) => {
    let updatedContacts;
    let method;
    let contact = primaryInfo;
    let values = {};
    const { firstName, lastName, email, phone, phoneExt, title } = data;

    if (contactIndex !== null) {
      updatedContacts = tempContacts.map((item, index) => {
        if (index === contactIndex) {
          return { ...item, ...data, primaryContact: item.primaryContact };
        }
        return item;
      });
      const dirtyTest = Object.keys(dirtyFields).some((a) => contactFields.includes(a));
      if (updatedContacts[contactIndex]?.primaryContact === "Yes" && dirtyTest) {
        contact = {
          ...(isVenueOrPartner && { contact: `${firstName} ${lastName}` }),
          ...(!isVenueOrPartner && { firstName, lastName, title }),
          email,
          phone,
          phoneExt,
          title,
        };
      }
    } else if (!isEmpty(firstName) && !isEmpty(lastName)) {
      let val = data;
      if (isVenueOrPartner) {
        const { title: _t, ...rest } = data;
        val = rest;
      }
      method = "create";
      updatedContacts = [...tempContacts, val];
    } else {
      method = "primary-only";
      contact = primaryInfo;
      updatedContacts = tempContacts;
    }

    values = contact ? { contact, contacts: updatedContacts } : { contacts: updatedContacts };
    if (!isEmpty(values)) handleUpdateCustomer(values, method, handleMethod); // put handleMethod here
  };

  // on company change or current company update
  useEffect(() => {
    setPrimaryInfo();
    if (currentCompany?.contacts) {
      setContacts(currentCompany?.contacts);
      setTempContacts(currentCompany?.contacts);
      if (currentContact && currentContact?.primaryContact === "Yes") {
        const temp = currentCompany?.contacts?.find((item) => item?.primaryContact === "Yes");
        setCurrentContact(temp);
      }
    } else {
      setContacts([]);
      setTempContacts([]);
    }
  }, [currentCompany]);

  return (
    <>
      <form
        id="company-form-company-contacts"
        style={{ width: "100%" }}
        onSubmit={handleSubmit(handleContactSave)}
      >
        <CustomCard
          icon={<ContactPage color="white" />}
          cardTitle={`Contacts: ${currentCompany?.name}`}
          // cardActions={
          //   (isDirty || isContactsDirty) && <PanelActions onCancel={handleCancelChanges} />
          // }
          {...customPanelCard(isMobile, "warning")}
        >
          <Grid container spacing={3} padding={3} paddingRight={0}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FlexWrapper
                flexWrap="wrap"
                alignContent="flex-start"
                justifyContent="flex-start"
                maxWidth={{ lg: "96%", sm: "100%", xs: "100%" }}
                height="100%"
                flexDirection="column"
              >
                <InputFlexWrapper maxWidth="100%" width="100%">
                  <DataListContainer
                    tableHeight={220}
                    height={225}
                    onAdd={handleContact}
                    data={watch("additionalContacts")}
                    renderRow={renderAdditionalContacts}
                    title="Additional Company Contacts"
                  />
                </InputFlexWrapper>
              </FlexWrapper>
            </Grid>
          </Grid>
        </CustomCard>
      </form>
      {isAdditionalContactsModalOpen && (
        <AdditionalContactsModal
          contacts={watch("additionalContacts")}
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          contactIndex={contactIndex}
          setContactIndex={setContactIndex}
          onContactsChange={handleContactsChange}
          isModalOpen={isAdditionalContactsModalOpen}
          setModalOpen={setAdditionalContactsModalOpen}
          sourceComponent="Company"
        />
      )}
    </>
  );
};

export default CompanyContacts;
