import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";

const fetchCustomers = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  venues,
  useOr = false,
  useOrTags = true,
}) => {
  const { queryString } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {
    const res = await baseAxios.get(
      `/companies/customers${queryString}&tagOper=${useOrTags ? "in" : "all"}${
        useOr ? "&useOr=true" : ""
      }`
    );

    if (res?.data?.data?.length) {
      res.data = { ...res.data };
    }

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchCustomers;
