import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Switch,
  Table,
  TableCell,
  TableRow,
  Typography,
  Icon,
  Autocomplete,
  CardActions,
  TextField,
} from "@mui/material";
import GenericModal from "components/GenericModal";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { filterEditorFormSchema } from "data/filterEditor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormField from "layouts/applications/wizard/components/FormField";
import { useAppContextController } from "context/AppContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import CustomTextField from "components/Form/CustomTextField";

const FilterEditorModal = ({ open, setOpen, setFilters, columns, filters }) => {
  const resolver = useYupValidationResolver(filterEditorFormSchema);
  const { reportSchemas } = useAppContextController();

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    register,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm({ defaultValues: filters, resolver });

  const MIN_DATE = moment("2023-01-01").toISOString();
  const MAX_DATE = moment().toISOString();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "filterEditorModalRows",
  });
  const addRow = () => {
    append({
      field: null,
      isOrNot: "Is",
      operator: "Equal",
      value: null,
    });
  };

  const handleReportFilters = (data) => {
    setFilters(data);
    setOpen(false);
  };

  const renderFieldValue = (index, type, options = []) => {
    if (type === "dropdown") {
      return (
        <Controller
          name={`filterEditorModalRows.${index}.rowValue`}
          control={control}
          render={({ field: cellField, fieldState: { error } }) => (
            <Autocomplete
              {...cellField}
              options={options || []}
              disabled={!type}
              getOptionLabel={(option) => option}
              value={watch(`filterEditorModalRows.${index}.rowValue`) || ""}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              onChange={(_, value) => cellField.onChange(value)}
            />
          )}
        />
      );
    }

    if (type === "date") {
      return (
        <Controller
          name={`filterEditorModalRows.${index}.rowValue`}
          control={control}
          render={({ field: cellField, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                {...cellField}
                minDate={new Date(MIN_DATE)}
                value={watch(`filterEditorModalRows.${index}.rowValue`) || null}
                renderInput={(params) => (
                  <CustomTextField {...params} error={!!error} variant="outlined" />
                )}
                onChange={(date) => cellField.onChange(date ? date.toISOString() : null)}
              />
            </LocalizationProvider>
          )}
        />
      );
    }

    return (
      <Controller
        name={`filterEditorModalRows.${index}.rowValue`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            onChange={(e) => setValue(`filterEditorModalRows.${index}.rowValue`, e.target.value)}
            InputLabelProps={{ shrink: true }}
            value={watch(`filterEditorModalRows.${index}.rowValue`) || ""}
            disabled={!type}
            variant="outlined"
            type={type === "number" ? "number" : "text"}
          />
        )}
      />
    );
  };

  const modalBody = (
    <>
      <form onSubmit={handleSubmit(handleReportFilters)}>
        <Stack display="flex" direction="row" alignItems="center">
          <Typography variant="h6">And</Typography>
          <Controller
            name="andOr"
            control={control}
            render={({ field }) => (
              <Switch
                checked={field.value === "Or"}
                onChange={(e) => field.onChange(e.target.checked ? "Or" : "And")}
              />
            )}
          />
          <Typography variant="h6">OR</Typography>
        </Stack>
        <Box mb={3} style={{ height: "21rem", overflow: "auto" }}>
          <Table
            sx={(theme) => ({
              [theme.breakpoints.down("desktop")]: {
                display: "block",
                overflowX: "auto",
                whiteSpace: "nowrap",
                width: "max-content",
              },
            })}
          >
            <TableRow
              key="modal_filter_titles"
              sx={({ typography: { size, fontWeightBold } }) => ({
                td: {
                  fontSize: size.xs,
                  fontWeight: fontWeightBold,
                  textTransform: "uppercase",
                },
              })}
            >
              <TableCell width="40%">Field</TableCell>
              <TableCell width="10%">Not</TableCell>
              <TableCell width="20%">Operator</TableCell>
              <TableCell width="25%">Value</TableCell>
              <TableCell width="5%" />
            </TableRow>
            {fields?.map((field, index) => {
              const currentField = watch(`filterEditorModalRows.${index}.field`);
              const foundField = columns?.find((item) => item.value === currentField);

              return (
                <TableRow key={field.id}>
                  <TableCell>
                    <Controller
                      name={`filterEditorModalRows.${index}.field`}
                      control={control}
                      render={({ field: cellField, fieldState: { error } }) => (
                        <Autocomplete
                          options={columns || []}
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => option?.value === value?.value}
                          renderInput={(params) => (
                            <FormField
                              placeholder="Select Field"
                              variant="outlined"
                              {...params}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                          value={columns?.find((opt) => opt.value === cellField.value) || null}
                          onChange={(_, newValue) => {
                            cellField.onChange(newValue?.value || "");
                            setValue(`filterEditorModalRows[${index}].isOrNot`, "Is");
                            setValue(`filterEditorModalRows[${index}].operator`, "Equal");
                            setValue(`filterEditorModalRows[${index}].rowValue`, null);
                          }}
                        />
                      )}
                    />
                    <Typography color="error" fontSize="0.75rem">
                      {errors?.[`filterEditorModalRows[${index}].field`]?.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      {...register(`filterEditorModalRows.${index}.isOrNot`, {
                        required: "Is or Not is required",
                      })}
                      options={["Not", "Is"] || []}
                      onChange={(e, v) => {
                        setValue(`filterEditorModalRows.${index}.isOrNot`, v);
                      }}
                      value={watch(`filterEditorModalRows.${index}.isOrNot`)}
                      renderInput={(params) => (
                        <FormField
                          variant="outlined"
                          {...params}
                          placeholder="Select"
                          type="text"
                        />
                      )}
                    />
                    <Typography color="error" fontSize="0.75rem">
                      {errors?.[`filterEditorModalRows[${index}].isOrNot`]?.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      {...register(`filterEditorModalRows.${index}.operator`, {
                        required: "Operator is required",
                      })}
                      options={
                        ["string", "dropdown"].some((item) => item === foundField?.type)
                          ? ["Equal"]
                          : ["Equal", "Greater Than Or Equal To", "Less Than"]
                      }
                      onChange={(e, v) => {
                        setValue(`filterEditorModalRows.${index}.operator`, v);
                      }}
                      value={watch(`filterEditorModalRows.${index}.operator`)}
                      renderInput={(params) => (
                        <FormField
                          variant="outlined"
                          {...params}
                          placeholder="Select Operator"
                          type="text"
                        />
                      )}
                    />
                    <Typography color="error" fontSize="0.75rem">
                      {errors?.[`filterEditorModalRows[${index}].operator`]?.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {renderFieldValue(index, foundField?.type, foundField?.options)}

                    <Typography color="error" fontSize="0.75rem">
                      {errors?.[`filterEditorModalRows[${index}].rowValue`]?.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <DeleteForeverIcon color="error" fontSize="medium" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
          {errors?.missingValue && (
            <Typography color="error">{errors?.missingValue.message}</Typography>
          )}
          <Button
            variant="text"
            onClick={() => {
              addRow();
            }}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Condition
          </Button>
        </Box>
        <Typography color="error" fontSize="0.75rem">
          {errors?.filterEditorModalRows?.message}
        </Typography>

        <CardActions
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Apply Filter
          </Button>
        </CardActions>
      </form>
    </>
  );

  const modalTitle = (
    <Typography
      variant="h5"
      sx={{ mt: 2, mb: 1, ml: 2 }}
      // key={`note_title_${modalInfo.data.id}`}
    >
      Filter Editor
    </Typography>
  );

  return (
    <>
      {open && (
        <GenericModal
          open={open}
          setOpen={setOpen}
          body={modalBody}
          header={modalTitle}
          width="60%"
        />
      )}
    </>
  );
};

export default FilterEditorModal;
