import axios from "axios";
import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

export default async function fetchEventMembers({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  eventUrl,
}) {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters: {},
  });

  try {
    const res = await baseAxios.get(
      `/events/url/${eventUrl}/members${paginationQuery}${sortQuery}`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
}
