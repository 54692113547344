import axios from "axios";

import { baseAxios } from "config";

export const generateEventTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/events-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEventChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/events-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueEventTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venue-events-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueEventChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venue-events-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCompanyInterviewTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-interviews-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCompanyInterviewChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-interviews-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobInterviewTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/job-interviews-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobInterviewChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/job-interviews-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
