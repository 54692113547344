import axios from "axios";

import { baseAxios } from "config";

export const generateIssueTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/issues-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateIssueChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/issues-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
