import axios from "axios";
import { baseAxios } from "config";

const enrollPartnerToEvent = async ({ eventUrl, applicantId, body }) => {
  try {
    const res = await baseAxios.put(`/events/url/${eventUrl}/enroll/partner/${applicantId}`, {
      ...body,
    });
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return null;
    throw new Error(err.toString());
  }
};

export default enrollPartnerToEvent;
