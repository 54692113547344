import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Checkbox,
  Stack,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import FiltersList from "components/FiltersList";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TagList from "components/TagList";
import { NumericFormat } from "react-number-format";
import { isEmpty } from "lodash";
import FormField from "layouts/applications/wizard/components/FormField";
import TagListSearch from "components/TagListSearch";
import useDropdowns from "hooks/useDropdowns";

const AdvancedSearchModal = ({
  advancedSearchModalOpen,
  setAdvancedSearchModalOpen,
  setFilters,
  filters,
  setSearchQuery,
  useOrTags,
  setPage,
  setUseOrTags,
  showFiltersList,
  parent,
  includeEmployees,
  setIncludeEmployees,
}) => {
  const [liftFilter, setLiftFilter] = useState(null);
  const [criminalRecordFilter, setCriminalRecordFilter] = useState(null);
  const [transportationFilter, setTransportationFilter] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);
  const [shiftFilter, setShiftFilter] = useState([]);
  const [smartFilter, setSmartFilter] = useState(null);
  const [minPayRateFilter, setMinPayRateFilter] = useState("");
  const [maxPayRateFilter, setMaxPayRateFilter] = useState("");
  const [employmentStatusFilter, setEmploymentStatusFilter] = useState(null);
  const [zipCodeFilter, setZipCodeFilter] = useState("");
  const [radiusFilter, setRadiusFilter] = useState(null);
  const [searchTagList, setSearchTagList] = useState([]);
  const [useOrTagsLocal, setUseOrTagsLocal] = useState(useOrTags);
  const [validationError, setValidationError] = useState({});
  const [newFilters, setNewFilters] = useState();
  // const { dropdowns: radiusOptions } = useDropdowns({ entity: "radiusOptions" });

  useEffect(() => {
    if (advancedSearchModalOpen) {
      setSearchTagList([]);
      setUseOrTagsLocal(useOrTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedSearchModalOpen]);

  const { dropdowns: radiusOptions } = useDropdowns({
    entity: "radiusOptions",
    companyType: "Company",
  });

  const handleFormClear = () => {
    setIncludeEmployees?.(false);
    setLiftFilter(null);
    setCriminalRecordFilter(null);
    setTransportationFilter([]);
    setLanguageFilter([]);
    setShiftFilter([]);
    setSmartFilter(null);
    setMinPayRateFilter("");
    setMaxPayRateFilter("");
    setEmploymentStatusFilter(null);
    setZipCodeFilter("");
    setRadiusFilter(null);
    setUseOrTagsLocal(useOrTags);
    setValidationError({});
  };
  const cancelFilterChanges = () => {
    setNewFilters(filters);
    setSearchTagList([]);
  };
  const clearTagsHandler = () => {
    setNewFilters({});
    setSearchTagList([]);
    setUseOrTagsLocal(useOrTags);
  };
  const allTagsHandler = () => setUseOrTags(false);
  const anyTagsHandler = () => setUseOrTags(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleTagsAction = () => {
    if (useOrTagsLocal) {
      anyTagsHandler();
    } else {
      allTagsHandler();
    }
  };

  const handleLanguageChange = (e) => {
    if (languageFilter.includes(e.target.id)) {
      languageFilter.splice(languageFilter.indexOf(e.target.id), 1);
      setLanguageFilter(languageFilter.filter((element) => element !== e.target.id));
    } else {
      setLanguageFilter([...languageFilter, e.target.id]);
    }
  };

  const validation = () => {
    let errorArr = {};
    if (Number(minPayRateFilter) < 0) {
      errorArr = { ...errorArr, minPayRate: "Min Pay Rate must be greater than 0" };
    }

    if (
      maxPayRateFilter &&
      minPayRateFilter &&
      Number(maxPayRateFilter) < Number(minPayRateFilter)
    ) {
      errorArr = { ...errorArr, maxPayRate: "Max Pay Rate must be greater than Min Rate" };
    }

    if (Number(maxPayRateFilter) < 0) {
      errorArr = { ...errorArr, maxPayRate: "Max Pay Rate must be greater than 0" };
    }

    if (zipCodeFilter && (zipCodeFilter?.length > 5 || zipCodeFilter?.length < 5)) {
      errorArr = { ...errorArr, zipCode: "Zip Code must be 5 digits" };
    }

    setValidationError(errorArr);
    return errorArr;
  };

  const handleTransportationChange = (e) => {
    if (transportationFilter.includes(e.target.id)) {
      setTransportationFilter(transportationFilter.filter((element) => element !== e.target.id));
    } else {
      setTransportationFilter([...transportationFilter, e.target.id]);
    }
  };

  const handleShiftChange = (e) => {
    if (e.target.id === "All" && shiftFilter.includes(e.target.id)) {
      setShiftFilter([]);
    } else if (e.target.id === "All" && !shiftFilter.includes(e.target.id)) {
      setShiftFilter(["1", "2", "3", "All"]);
    } else if (shiftFilter.includes(e.target.id) && !shiftFilter.includes("All")) {
      shiftFilter.splice(shiftFilter.indexOf(e.target.id), 1);
      setShiftFilter(shiftFilter.filter((element) => element !== e.target.id));
    } else if (!shiftFilter.includes("All")) {
      setShiftFilter([...shiftFilter, e.target.id]);
    }
  };

  const buildSearchQuery = () => {
    const queryStrings = {};
    if (liftFilter) {
      queryStrings.lift = liftFilter;
    }
    if (criminalRecordFilter) {
      queryStrings.criminalHistoryDisclosure = criminalRecordFilter;
    }
    if (!isEmpty(transportationFilter)) {
      queryStrings.transportation = transportationFilter;
    }
    if (!isEmpty(languageFilter)) {
      queryStrings.language = languageFilter;
    }
    if (!isEmpty(shiftFilter)) {
      queryStrings.shift = shiftFilter;
    }
    if (smartFilter) {
      queryStrings.smart = smartFilter;
    }
    if (minPayRateFilter) {
      queryStrings.minPayRate = minPayRateFilter;
    }
    if (maxPayRateFilter) {
      queryStrings.maxPayRate = maxPayRateFilter;
    }
    if (employmentStatusFilter) {
      queryStrings.employmentStatus = employmentStatusFilter;
    }
    if (zipCodeFilter) {
      queryStrings.zip = zipCodeFilter;
    }
    if (zipCodeFilter && radiusFilter) {
      queryStrings.radius = radiusFilter;
    }
    if (includeEmployees) {
      queryStrings.includeEmployees = true;
    }

    // Convert queryStrings object to query string format
    const queryString = Object.entries(queryStrings)
      .filter(([key, value]) => value || value === 0)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    return queryString;
  };
  const header = (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignContent="center">
          {!isSmallScreen && (
            <TagListSearch
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              filters={newFilters}
              setFilters={setNewFilters}
            />
          )}
          {searchTagList?.length > 0 && (
            <ToggleButtonGroup value={useOrTagsLocal ? "or" : "all"} color="secondary" exclusive>
              <ToggleButton value="or" onClick={() => setUseOrTagsLocal(true)}>
                Any
              </ToggleButton>
              <ToggleButton value="all" onClick={() => setUseOrTagsLocal(false)}>
                All
              </ToggleButton>
              <ToggleButton value="clear" onClick={clearTagsHandler}>
                Clear
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              handleFormClear();
            }}
          >
            Clear Form
          </Button>
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            onClick={() => {
              cancelFilterChanges();
              setAdvancedSearchModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{ color: "white" }}
            onClick={() => {
              const err = validation();
              if (isEmpty(err)) {
                handleTagsAction();
                const string = buildSearchQuery();
                setSearchQuery(string);
                setPage(1);
                setAdvancedSearchModalOpen(false);
                if (parent === "Applicants")
                  setFilters(
                    includeEmployees
                      ? {
                          ...newFilters,
                          status: "Applicant;Employee",
                        }
                      : { ...newFilters, status: "Applicant" }
                  );
                else setFilters({ ...newFilters, status: "Employee" });
              }
            }}
          >
            Search
          </Button>
        </Box>
      </Box>
      {showFiltersList && (
        <Box mt={2}>
          <FiltersList
            filters={newFilters}
            setFilters={setNewFilters}
            searchTagList={searchTagList}
            setSearchTagList={setSearchTagList}
            clearTagsHandler={clearTagsHandler}
            displayOtherFilters={!newFilters?.tags}
          />
        </Box>
      )}
    </>
  );

  useEffect(() => {
    if (validationError.length > 0) {
      validation();
    }
  }, [minPayRateFilter, maxPayRateFilter]);

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const body = (
    <>
      {parent === "Applicants" && (
        <Grid item sm={6}>
          <Stack direction="row" alignItems="center" display="flex">
            <MDTypography variant="h5"> Include Employees</MDTypography>
            <Checkbox
              id="Heavy"
              checked={includeEmployees}
              onChange={(e) => setIncludeEmployees?.(!includeEmployees)}
            />
          </Stack>
        </Grid>
      )}
      <Box sx={{ border: "1px solid black", borderRadius: "15px", padding: 3, paddingLeft: 5 }}>
        <Grid container spacing={3}>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Lift
                </MDTypography>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="Heavy"
                    checked={liftFilter === "Heavy"}
                    onChange={(e) => setLiftFilter("Heavy")}
                  />
                  <MDTypography variant="body2">Heavy</MDTypography>
                </Stack>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="Light"
                    checked={liftFilter === "Light"}
                    onChange={(e) => setLiftFilter("Light")}
                  />
                  <MDTypography variant="body2">Light</MDTypography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Criminal Record
                </MDTypography>
              </Grid>
              <Grid item sm={9}>
                <Grid container alignItems="center">
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Felony"
                        checked={criminalRecordFilter === "Felony"}
                        onChange={(e) => setCriminalRecordFilter("Felony")}
                      />
                      <MDTypography variant="body2">Felony</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="No Record"
                        checked={criminalRecordFilter === "No Record"}
                        onChange={(e) => setCriminalRecordFilter("No Record")}
                      />
                      <MDTypography variant="body2">No Record</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Not Checked"
                        checked={criminalRecordFilter === "Not Checked"}
                        onChange={(e) => setCriminalRecordFilter("Not Checked")}
                      />
                      <MDTypography variant="body2">Not Checked</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Misd."
                        checked={criminalRecordFilter === "Misd."}
                        onChange={(e) => setCriminalRecordFilter("Misd.")}
                      />
                      <MDTypography variant="body2">Misdemeanor</MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Transportation
                </MDTypography>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="Car"
                    checked={transportationFilter.includes("Car")}
                    onChange={(e) => handleTransportationChange(e)}
                  />
                  <MDTypography variant="body2">Car</MDTypography>
                </Stack>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="Bus"
                    checked={transportationFilter.includes("Bus")}
                    onChange={(e) => handleTransportationChange(e)}
                  />
                  <MDTypography variant="body2">Bus</MDTypography>
                </Stack>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="Other"
                    checked={transportationFilter.includes("Other")}
                    onChange={(e) => handleTransportationChange(e)}
                  />
                  <MDTypography variant="body2">Other</MDTypography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Language
                </MDTypography>
              </Grid>
              <Grid item sm={9}>
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="English"
                        checked={languageFilter.includes("English")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">English</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Spanish"
                        checked={languageFilter.includes("Spanish")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">Spanish</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Bilingual"
                        checked={languageFilter.includes("Bilingual")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">Bilingual</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="LiteEnglish"
                        checked={languageFilter.includes("LiteEnglish")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">Lite English</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Chinese"
                        checked={languageFilter.includes("Chinese")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">Chinese</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={6}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Vietnamese"
                        checked={languageFilter.includes("Vietnamese")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">Vietnamese</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Other"
                        checked={languageFilter.includes("Other")}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <MDTypography variant="body2">Other</MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Shift
                </MDTypography>
              </Grid>
              <Grid item sm={9}>
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="1"
                        checked={shiftFilter.includes("1") || shiftFilter.includes("All")}
                        onChange={(e) => handleShiftChange(e)}
                      />
                      <MDTypography variant="body2">1</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="2"
                        checked={shiftFilter.includes("2") || shiftFilter.includes("All")}
                        onChange={(e) => handleShiftChange(e)}
                      />
                      <MDTypography variant="body2">2</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="3"
                        checked={shiftFilter.includes("3") || shiftFilter.includes("All")}
                        onChange={(e) => handleShiftChange(e)}
                      />
                      <MDTypography variant="body2">3</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="All"
                        checked={shiftFilter.includes("All")}
                        onChange={(e) => handleShiftChange(e)}
                      />
                      <MDTypography variant="body2">All</MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  SMART
                </MDTypography>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="Yes"
                    checked={smartFilter === "Yes"}
                    onChange={(e) => setSmartFilter("Yes")}
                  />
                  <MDTypography variant="body2">Yes</MDTypography>
                </Stack>
              </Grid>
              <Grid item sm={3}>
                <Stack direction="row" alignItems="center" display="flex">
                  <Checkbox
                    id="No"
                    checked={smartFilter === "No"}
                    onChange={(e) => setSmartFilter("No")}
                  />
                  <MDTypography variant="body2">No</MDTypography>
                </Stack>
                <Grid item sm={3} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} mb={2} alignItems="center">
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Min Pay Rate
                </MDTypography>
              </Grid>
              <Grid item sm={3.5}>
                <NumericFormat
                  label=""
                  variant="outlined"
                  InputProps={{
                    startAdornment: "$",
                    inputProps: {
                      inputMode: "numeric",
                    },
                  }}
                  decimalScale={2}
                  value={minPayRateFilter}
                  onChange={(e) => {
                    setMinPayRateFilter(e.target.value);
                  }}
                  customInput={FormField}
                />
              </Grid>
              <Grid item sm={2}>
                <MDTypography variant="h6" color="info" textAlign="end" mr={2}>
                  Max Pay Rate
                </MDTypography>
              </Grid>
              <Grid item sm={3.5}>
                <NumericFormat
                  label=""
                  variant="outlined"
                  InputProps={{
                    startAdornment: "$",
                    inputProps: {
                      inputMode: "numeric",
                    },
                  }}
                  onChange={(e) => {
                    setMaxPayRateFilter(e.target.value);
                  }}
                  value={maxPayRateFilter}
                  decimalScale={2}
                  customInput={FormField}
                />
              </Grid>
              <Grid item sm={3} />
              <Grid item sm={5.5}>
                <MDTypography color="error" sx={{ fontSize: "0.9rem" }}>
                  {validationError?.minPayRate || ""}
                </MDTypography>
              </Grid>
              <Grid item sm={3.5}>
                <MDTypography color="error" sx={{ fontSize: "0.9rem" }}>
                  {validationError?.maxPayRate || ""}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ borderBottom: "1px solid black" }}>
            <Grid container ml={-3} alignItems="center" mb={2}>
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Employment Status
                </MDTypography>
              </Grid>
              <Grid item sm={9}>
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="OnAssignment"
                        checked={employmentStatusFilter === "On Assignment"}
                        onChange={(e) => setEmploymentStatusFilter("On Assignment")}
                      />
                      <MDTypography variant="body2">On Assignment</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Inactive"
                        checked={employmentStatusFilter === "Inactive"}
                        onChange={(e) => setEmploymentStatusFilter("Inactive")}
                      />
                      <MDTypography variant="body2">Inactive</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="Terminated"
                        checked={employmentStatusFilter === "Terminated"}
                        onChange={(e) => setEmploymentStatusFilter("Terminated")}
                      />
                      <MDTypography variant="body2">Terminated</MDTypography>
                    </Stack>
                  </Grid>
                  {/* <Grid item sm={3}>
                    <Stack direction="row" alignItems="center" display="flex">
                      <Checkbox
                        id="DNU"
                        checked={employmentStatusFilter === "DNU"}
                        onChange={(e) => setEmploymentStatusFilter("DNU")}
                      />
                      <MDTypography variant="body2">DNU</MDTypography>
                    </Stack>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container ml={-3} alignItems="center">
              <Grid item sm={3}>
                <MDTypography variant="h6" color="info">
                  Location
                </MDTypography>
              </Grid>
              <Grid item sm={1.5}>
                <MDTypography variant="body2">Zip Code</MDTypography>
              </Grid>
              <Grid item sm={3}>
                <FormField
                  variant="outlined"
                  value={zipCodeFilter}
                  type="number"
                  onChange={(e) => setZipCodeFilter(e.target.value)}
                  label=""
                />
                <MDTypography color="error" sx={{ fontSize: "0.9rem" }}>
                  {validationError?.zipCode || ""}
                </MDTypography>
              </Grid>

              <Grid item sm={1.5}>
                <MDTypography variant="body2" textAlign="end" mr={2}>
                  Radius
                </MDTypography>
              </Grid>
              <Grid item sm={3}>
                <Autocomplete
                  options={radiusOptions || []}
                  disabled={!zipCodeFilter}
                  defaultValue={radiusFilter || null}
                  onChange={(e, v) => {
                    setRadiusFilter(v);
                  }}
                  sx={{ maxHeight: "44px" }}
                  renderInput={(params) => <FormField variant="outlined" {...params} type="text" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key="top-center"
      >
        <GenericModal
          open={advancedSearchModalOpen}
          setOpen={setAdvancedSearchModalOpen}
          body={body}
          header={header}
        />
      </SnackbarProvider>
    </>
  );
};

export default AdvancedSearchModal;
