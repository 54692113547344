import { NavLink } from "react-router-dom";

import MDButton from "components/MDButton";

const ApplyButton = ({ row }) => (
  <MDButton
    component={NavLink}
    to={`/jobs/apply/id/${row._id.toString()}`}
    variant="contained"
    color="error"
    fullWidth
  >
    Apply
  </MDButton>
);

export default ApplyButton;
