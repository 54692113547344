import { Add, Check, HourglassTop } from "@mui/icons-material";

import { APPLICANT_EVENT_STATUS_ENUM } from "utils/constants/applicant";

const EventStatusIcon = ({ status, size = "large" }) => {
  const renderIcon = () => {
    switch (status) {
      case APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER:
        return <Add color="info" fontSize={size} />;
      case APPLICANT_EVENT_STATUS_ENUM.REQUEST:
        return <Check color="warning" fontSize={size} />;
      case APPLICANT_EVENT_STATUS_ENUM.ROSTER:
        return <Check color="success" fontSize={size} />;
      case APPLICANT_EVENT_STATUS_ENUM.WAITLIST:
        return <HourglassTop color="secondary" fontSize={size} />;
      default:
        return "";
    }
  };
  return renderIcon();
};

export default EventStatusIcon;
