import { useState, useEffect } from "react";
import { Box, Grid, Stack, IconButton } from "@mui/material";
import { Cancel as CancelIcon, Edit as EditIcon, Clear as ClearIcon } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import CustomTextField from "components/Form/CustomTextField";

const DropdownStringValue = ({ row, idx, onEdit, onRemove, clearErrors, errors }) => {
  const [isEdit, setEdit] = useState(false);
  const [text, setText] = useState(row);

  const handleBlur = () => {
    onEdit(row, idx, text);
    if (!errors?.[`stringValue_${idx}`]) setEdit(false);
  };

  useEffect(() => {
    setText(row);
  }, [row]);

  return (
    <Grid container alignItems="center">
      <Grid item sm={11}>
        <Stack direction="row" spacing={1} alignItems="center">
          {isEdit ? (
            <>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  setEdit(false);
                  setText(row);
                  clearErrors(`stringValue_${idx}`);
                }}
              >
                <ClearIcon fontSize="medium" />
              </IconButton>
              <Box>
                <CustomTextField
                  label=""
                  value={text}
                  autoFocus
                  onBlur={handleBlur}
                  onChange={(e) => setText(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleBlur();
                    }
                  }}
                  showError={errors?.[`stringValue_${idx}`]}
                  errorMessage={errors?.[`stringValue_${idx}`]?.message}
                />
              </Box>
            </>
          ) : (
            <>
              <IconButton sx={{ p: 0 }} onClick={() => setEdit(true)}>
                <EditIcon color="info" fontSize="medium" />
              </IconButton>
              <MDTypography sx={{ fontSize: "0.9rem" }}>{text}</MDTypography>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item sm={1}>
        <Box textAlign="right">
          <IconButton sx={{ p: 0 }} onClick={() => onRemove(idx)}>
            <CancelIcon color="error" fontSize="medium" />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DropdownStringValue;
