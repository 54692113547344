import { baseAxios } from "config";

const pushApplicantJobAIInterviewMessage = ({ data, jobSlug, outsideMode, id, isLast }) => {
  const outsideModeQuery = outsideMode ? `outside-${outsideMode}/` : "";

  return baseAxios.post(`/${outsideModeQuery}applicants/${id}/job/aiinterviews/message`, {
    jobSlug,
    data,
    isLast,
  });
};

export default pushApplicantJobAIInterviewMessage;
