import PropTypes from "prop-types";
// import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled} from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
//  height: 100,
  width: 200,
  borderRadius: "lg",
  lineHeight: "100px",
}));

const darkTheme = createTheme({ palette: { mode: "dark" } });
const lightTheme = createTheme({ palette: { mode: "light" } });

export default function PaperItem({ children, elevation }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Item key={elevation} elevation={parseInt(elevation, 10)}>
        {children}
      </Item>
    </ThemeProvider>
  );
}

PaperItem.defaultProps = {
  elevation: "4",
//  color: "info"
};

// Typechecking props for the SelectorCard
PaperItem.propTypes = {
  elevation: PropTypes.string,
//  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
