import { baseAxios } from "config";

const getAuth0Status = async (email) => {

    try {
        const res = await baseAxios.get(
            `users/auth0/email/${email}`
        );
        return res.data;
    } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        throw new Error(err.toString());
    }
};

export default getAuth0Status;
