import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Tooltip } from "@mui/material";

const SmartPhoneColumn = ({ platform, clockInPlatform, clockOutTime }) => {
  let tooltipTrailerMsg = "";
  if (["android", "ios"].includes(platform)) {
    if (clockInPlatform) {
      tooltipTrailerMsg = clockOutTime ? "Clocked in/out with " : "Clocked in with ";
    }
    return (
      <>
        {clockInPlatform ? (
          <>
            <Tooltip title={`${tooltipTrailerMsg}${platform}`}>
              <div>
                <SmartphoneIcon fontSize="large" color={platform === "android" ? "error" : "secondary"} />
                <AccessTimeFilledIcon fontSize="medium" color={clockOutTime ? "error" : "success"} />
              </div>
            </Tooltip>
          </>
        )
          :
          (<Tooltip title={`${tooltipTrailerMsg}${platform}`}>
            <SmartphoneIcon fontSize="large" color={platform === "android" ? "error" : "secondary"} />
          </Tooltip>)
        }
      </>
    );
  }
  return null;
};

export default SmartPhoneColumn;
