/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TextField } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import moment from "moment-timezone";
import { default as momentParser } from "moment";
// import input from "assets/theme/components/form/input";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import MDBox from "components/MDBox";
import Tooltip from "@mui/material/Tooltip";
import DisplayTime from "./DisplayTime";
import EditTime from "./EditTime";
import { TimePicker } from "@mui/x-date-pickers";

const InterviewAttended = ({
  id,
  field,
  clockInOut,
  currentEvent,
  eventDate,
  eventTime,
  timeZone,
  rosterObj,
  disabled,
  editThis = false,
}) => {
  const { currentLoggedUser } = useAppContextController();

  const [edit, setEdit] = useState(false);
  const [mode, setMode] = useState("display");
  const [time, setTime] = useState("");
  const [closePicker, setClosePicker] = useState(false);
  const [update, toggleUpdate] = useState(false);

  const toggleClosePicker = (e) => {
    toggleUpdate((prev) => !prev);

    setClosePicker((prev) => !prev);
    setMode("display");
  };

  useEffect(() => {
    if (rosterObj) {
      let newTime = null;
      if (rosterObj.status === "Roster") {
        // if (field === "timeIn" || (field === "timeOut" && rosterObj.timeIn)) setDisabled(false);
        if (rosterObj[field]) newTime = moment(rosterObj[field]);
      }
      setTime(newTime);
    } else setTime(null);
  }, [rosterObj]);

  useEffect(() => {
    if (time) {
      rosterObj[field] = new Date(time).toISOString();
      rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
      clockInOut(null, rosterObj);
    }
  }, [update]);

  const findPosition = (posName) => {
    const pos = currentEvent.positions.find((item) => posName === item.positionName);
    return pos;
  };
  const onClickHandler = (e) => {
    const today = new Date().toISOString().split("T")[0];
    const eventDate = new Date(currentEvent?.eventDate).toISOString().split("T")[0];
    if (eventDate > today) {
      alert("Cannot Register Attendance - Event is in the future!");
      return;
    }
    if (disabled) {
      alert("Cannot Register Attendance");
      return;
    }
    // if primaryPosition is set on the rosterObj, we need to get the check-in time
    let reportTime = eventTime;
    let fullReportTime = new Date(currentEvent.eventDate).toISOString();
    if (currentEvent?.positions?.length && rosterObj.primaryPosition) {
      const pos = findPosition(rosterObj.primaryPosition);
      if (pos) {
        reportTime = new Date(pos.reportTime).toISOString().split("T")[1];
        fullReportTime = new Date(pos.reportTime).toISOString();
      }
    }
    rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    if (!disabled && !rosterObj?.[field]) {
      // this is where we need to set the clock in time to the report time if earlier
      const currentTime = moment().toISOString().split("T")[1];
      let ISOTime;

      if (
        field === "timeIn" &&
        (new Date().toISOString() < fullReportTime ||
          moment().toISOString().split("T")[0] > eventDate)
      ) {
        ISOTime = fullReportTime;
      } else ISOTime = `${eventDate}T${currentTime}`;

      rosterObj[field] = ISOTime;
      // alert(ISOTime);
      setTime(rosterObj[field]);
      clockInOut(e, rosterObj);
      setEdit(false);
      setMode("display");
    }
  };

  const undoTime = (e) => {
    e.stopPropagation();
    rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    rosterObj[field] = "";
    clockInOut(e, rosterObj);
    setEdit(false);
    setMode("display");
  };

  if (!time) {
    return (
      <MDBox textAlign="center">
        <Tooltip title={`Click to mark attendance`}>
          <NotInterestedIcon
            color={disabled ? "disabled" : "success"}
            fontSize="large"
            onClick={onClickHandler}
          />
        </Tooltip>
      </MDBox>
    );
  }

  // if (time !== null && mode === "display") {
  //   return (
  //     <>
  //       {time && <div onClick={() => setMode("undoOrEdit")}>{moment(time)?.format("hh:mm a")}</div>}
  //     </>
  //   );
  // }

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events

  // otherwise, show the clock in/out button
  return (
    <>
      <Tooltip
        title={`Attendence was Registered at ${new Date(time).toLocaleDateString()} ${new Date(
          time
        ).toLocaleTimeString()}`}
      >
        <MDBox
          key={`${field}_button_${id}`}
          onClick={undoTime}
          bgColor={field === "timeIn" ? "success" : "error"}
          color="white"
          textAlign="center"
        >
          Attended
        </MDBox>
      </Tooltip>
    </>
  );
};

export default InterviewAttended;
