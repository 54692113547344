import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import { useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import SearchbarModal from "components/SearchbarModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import useSessionAuth from "hooks/useSessionAuth";
import { useAppContextController } from "context/AppContext";
import useSearchbarStore from "../../stores/useSearchbarStore";
import SearchbarResults from "./SearchbarResults";

const Searchbar = ({
  fetch,
  fetchAll,
  columns,
  queryCharacterLimit,
  resultsLimit,
  setFilters = () => {},
  filters,
  setPage = () => {},
  placeholder,
  searchBy,
  changedSearchByList,
  changeSearchByAtInputChar,
  useRestParams = false,
  filterBy,
  filterByColumn = null,
  scrollLimit = 0,
  sortFunction = null,
  onRowClick,
  maxWidth = undefined,
  dropRight = false,
  autoCenter = true,
  formatData,
  returnAfterClick = false,
  refetchDependency,
  filterData = null,
  paddingRight = 2,
}) => {
  const { setSearchResults } = useSearchbarStore((state) => ({
    setSearchResults: state.setSearchResults,
  }));
  const isLTablet = useMediaQuery((theme) => theme.breakpoints.down("lTablet"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.up("laptop"));
  const [allData, setAllData] = useState([]);
  const [fetchedData, setFetchedData] = useState({
    isFetched: false,
    isLoading: false,
    data: [],
  });
  const [input, setInput] = useState("");
  const [inputValue] = useDebounce(input, 1000);
  const [isModalOpen, toggleModal] = useState(false);
  const tableColumns = columns;
  const { logoutAuth0User } = useSessionAuth();
  const { company } = useAppContextController();

  const onFetchHandler = useCallback(async () => {
    const createFilters = (inp) => {
      if (useRestParams) return null;
      if (typeof searchBy === "string") return { [searchBy]: inp };
      const options = {};
      const searchFilters =
        changeSearchByAtInputChar && changedSearchByList && inp?.length >= changeSearchByAtInputChar
          ? changedSearchByList
          : searchBy;
      searchFilters.forEach((key) => {
        options[key] = inp;
      });
      return options;
    };

    const createRestParams = (inp) => {
      if (!useRestParams) return null;
      if (typeof searchBy === "string") return { [searchBy]: inp };
      const options = {};
      searchBy.forEach((key) => {
        options[key] = inp;
      });
      return options;
    };

    if (fetchedData.isLoading) return;
    if (input.length >= queryCharacterLimit) {
      setFetchedData({ ...fetchedData, isLoading: true, isFetched: true });

      let data = [];

      try {
        if (!fetchAll) {
          const res = await fetch({
            fetchAll: false,
            filters: createFilters(input),
            params: useRestParams ? createRestParams(input) : null,
            page: 1,
            limit: !scrollLimit ? resultsLimit : scrollLimit,
            useOr: true,
            searchProjection: true,
            imageUrlBase: company?.imageUrl,
            includeDnu: true,
          });
          data = res.data;
          if (filterData) {
            data = data.filter(filterData);
          }
        }

        if (fetchAll) {
          const res = await fetch({
            fetchAll: true,
            searchProjection: true,
            imageUrlBase: company?.imageUrl,
            includeDnu: true,
          });
          data = res.data;
          if (filterData) {
            data = data.filter(filterData);
          }
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }

      const filtered =
        useRestParams || !fetchAll
          ? data
          : data?.filter((item) => {
              let found = false;
              const searchArr = typeof searchBy === "string" ? searchBy.split(",") : searchBy;
              if (searchArr?.length) {
                searchArr?.forEach((field) => {
                  if (item[field]?.toLowerCase().includes(input.toLowerCase())) {
                    found = true;
                  }
                });
              }
              return found;
            }) || [];
      let parsedData = sortFunction
        ? filtered?.sort(sortFunction).slice(0, scrollLimit || resultsLimit)
        : filtered?.slice(0, scrollLimit || resultsLimit);

      if (formatData) {
        parsedData = formatData(parsedData);
      }
      setSearchResults(parsedData);
      setFetchedData({
        isFetched: true,
        data: parsedData,
        isLoading: false,
      });
      setAllData(data);
    } else {
      setFetchedData({ isFetched: false, data: [], isLoading: false });
    }
  }, [
    changeSearchByAtInputChar,
    changedSearchByList,
    company?.imageUrl,
    fetch,
    fetchAll,
    fetchedData,
    filterData,
    formatData,
    input,
    logoutAuth0User,
    queryCharacterLimit,
    resultsLimit,
    scrollLimit,
    searchBy,
    setSearchResults,
    sortFunction,
    useRestParams,
  ]);

  const resetSearch = () => {
    setInput("");
    setFetchedData({ data: [], isFetched: false, isLoading: false });
  };

  const onSelectHandler = (item, e) => {
    setInput("");
    if (onRowClick) {
      onRowClick(item, e);
      if (returnAfterClick) return;
    }

    resetSearch();
    setFilters(
      filters?.tags
        ? { [filterBy]: item[filterByColumn || filterBy], tags: filters?.tags }
        : { [filterBy]: item[filterByColumn || filterBy] }
    );
    setPage(1);
  };

  useEffect(() => {
    onFetchHandler();

    return () =>
      setFetchedData({
        isFetched: false,
        data: [],
        isLoading: false,
      });
  }, [inputValue, refetchDependency]);

  return (
    <MDBox sx={{ width: "100%", position: "relative" }} pr={paddingRight}>
      <Paper
        sx={{
          p: "2px 4px !important",
          display: "flex",
          alignItems: "center",
          width: "100%",
          background: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, p: "2px", maxWidth }}
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onClick={() => isLTablet && toggleModal(true)}
        />
        {input.length !== 0 && (
          <IconButton onClick={resetSearch}>
            <CloseIcon />
          </IconButton>
        )}

        <IconButton onClick={onFetchHandler}>
          <SearchIcon />
        </IconButton>
      </Paper>
      {isLTablet ? (
        <SearchbarModal
          isOpen={isModalOpen}
          onClose={() => toggleModal(false)}
          results={
            <FlexWrapper>
              <Paper
                sx={{
                  p: "2px 4px !important",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.04)",
                  mb: "10px",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, p: "2px", maxWidth }}
                  placeholder={placeholder}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                {input.length !== 0 && (
                  <IconButton onClick={resetSearch}>
                    <CloseIcon />
                  </IconButton>
                )}

                <IconButton onClick={onFetchHandler}>
                  <SearchIcon />
                </IconButton>
              </Paper>
              <FlexWrapper sx={{ overflow: "auto", maxHeight: "500px" }}>
                <SearchbarResults
                  rows={fetchedData?.data}
                  columns={tableColumns}
                  isLoading={fetchedData?.isLoading}
                  onRowClick={onSelectHandler}
                />
              </FlexWrapper>
            </FlexWrapper>
          }
        />
      ) : (
        fetchedData.isFetched && (
          <>
            <MDBox
              sx={{
                width: "max-content",
                position: "absolute",
                zIndex: "999",
                background: "#fff",
                top: "4rem",
                boxShadow: 3,
                ...(dropRight ? { left: 0 } : { right: 0 }),
                ...(!!scrollLimit && { maxHeight: "800px", overflowY: "auto" }),
                ...(autoCenter &&
                  !isLaptop && {
                    right: "50%",
                    left: "50%",
                    transform: "translate(-55%, -0%)",
                  }),
              }}
            >
              <SearchbarResults
                rows={fetchedData?.data}
                columns={tableColumns}
                isLoading={fetchedData?.isLoading}
                onRowClick={onSelectHandler}
              />
            </MDBox>
          </>
        )
      )}
    </MDBox>
  );
};

// Setting default values for the props
Searchbar.defaultProps = {
  // currentVenue: {},
  //  setFilters: () => { },
  columns: [],
  fetchAll: false,
  resultsLimit: 10,
  queryCharacterLimit: 3,
  placeholder: "",
  filterBy: "slug",
  onRowClick: null,
};

// Typechecking props
Searchbar.propTypes = {
  //  setFilters: PropTypes.func,
  fetchAll: PropTypes.bool,
  resultsLimit: PropTypes.number,
  queryCharacterLimit: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  //  searchBy: PropTypes.arrayOf(PropTypes.string) || PropTypes.string,
  filterBy: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default Searchbar;
