import { Button } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";

const NoApplicantsModal = ({ open, setOpen }) => {
  const header = (
    <>
      <MDTypography variant="h4">No Applicants</MDTypography>
    </>
  );

  const body = <MDTypography variant="body2">No applicants against selected filter</MDTypography>;

  const okButton = (
    <Button variant="contained" style={{ color: "white" }} onClick={() => setOpen(false)}>
      Ok
    </Button>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={body}
        header={header}
        buttons={okButton}
        width="30%"
      />
    </>
  );
};

export default NoApplicantsModal;
