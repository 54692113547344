import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Close, DateRange } from "@mui/icons-material";
import MDBox from "components/MDBox";
import DateRangePopover from "components/DateRangePopover";

const DateRangeSelector = ({ setDateRangeFilter, dateRangeFilter, text = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = (startDate, endDate) => {
    setAnchorEl(null);

    if (startDate && endDate) {
      setDateRangeFilter({
        startDate,
        endDate,
      });
    }
  };
  const clearDateRangeFilter = () => {
    setDateRangeFilter(null);
  };
  const handleOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {dateRangeFilter ? (
        <MDBox>
          <Tooltip title="Clear Date Filter">
            <IconButton color="error" onClick={clearDateRangeFilter}>
              <Close />
            </IconButton>
          </Tooltip>
        </MDBox>
      ) : null}
      <Tooltip title="Filter By Date Created">
        <Box display="flex" alignItems="center">
          <IconButton color="info" onClick={handleOpen}>
            <DateRange />
            {text && < Typography fontSize="0.75rem" fontWeight="bold" color="#1A73E8" ml={1}>
              FILTER BY DATE
            </Typography>}
          </IconButton>
        </Box>
      </Tooltip >
      <DateRangePopover
        anchorEl={anchorEl}
        onClose={handleClose}
        startDate={dateRangeFilter?.startDate}
        endDate={dateRangeFilter?.endDate}
      />
    </>
  );
};

DateRangeSelector.defaultProps = {};

DateRangeSelector.prototype = {
  setDateRangeFilter: PropTypes.func.isRequired,
  dateRangeFilter: PropTypes.object,
};

export default DateRangeSelector;
