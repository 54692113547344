import { LocationOn } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputBase, Paper, Tooltip, Autocomplete, Grid } from "@mui/material";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import fetchJobsByCoords from "api/jobs/fetchJobsByCoords";
import fetchJobsByZip from "api/jobs/fetchJobsByZip";
import { useAppContextController } from "context/AppContext";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import FormField from "layouts/applications/wizard/components/FormField";
import { isNull } from "lodash";
import useSessionAuth from "hooks/useSessionAuth";

const JobSearchbar = ({ columns, handleZipFilter, handleFilter, jobsTitles }) => {
  const { venues, companyType, isMobile, company } = useAppContextController();
  const navigate = useNavigate();
  const [title, setTitle] = useState(null);
  const [input, setInput] = useState("");
  const [zipCode, setZipCode] = useState("");

  const options = { page: 1, limit: 500 };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let newFilter = {};
      if (input?.length >= 3) {
        newFilter = {
          ...newFilter,
          ...(companyType === "Venue"
            ? { venueCity: input, venueState: input }
            : { companyState: input, companyCity: input }),
        };
      }
      if (title) {
        newFilter = {
          ...newFilter,
          ...{ title },
        };
      }
      handleFilter(newFilter);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, title, companyType]);

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        if (zipCode.length === 5) {
          handleZipFilter(zipCode);
          return;
        }
        handleZipFilter(null);
      } catch (err) {
        handleZipFilter(null);
        if (String(err).includes("401") || err?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode, companyType]);

  const onChange = async (e) => {
    setInput(e.target.value);
    setZipCode("");
    setTitle("");
  };

  const getLocation = () => {
    const opt = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = async (pos) => {
      const { latitude, longitude } = pos.coords;
      try {
        const res = await fetchJobsByCoords({ lat: latitude, long: longitude });
        if (res?.data?.coords?.zip) {
          setZipCode(res.data.coords.zip);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    };

    const failure = (err) => {
      console.error(err);
    };

    navigator.geolocation.getCurrentPosition(success, failure, opt);
  };

  return (
    <>
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={3.6}>
          <Autocomplete
            options={jobsTitles?.sort() || []}
            defaultValue={null}
            value={title}
            onChange={(e, v) => {
              setTitle(v);
              setZipCode("");
              setInput("");
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <FormField
                {...params}
                variant="outlined"
                type="text"
                label="Position"
                sx={{
                  input: { fontSize: "1.25rem" },
                  height: "100%",
                  "& .MuiInputBase-root": {
                    background: "#fff",
                    py: "2px",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3.6}>
          <Paper
            sx={(theme) => ({
              p: "2px 4px !important",
              display: "flex",
              alignItems: "center",
              width: "100%",
              background: "#fff",
            })}
          >
            <InputBase
              value={input}
              onChange={onChange}
              variant="outlined"
              type="text"
              placeholder={
                company?.companyType === "Venue" ? "Venue/City/State" : "Search City/State"
              }
              sx={{ ml: 1, flex: 1, p: "2px" }}
            />
            {input?.length !== 0 && (
              <IconButton
                onClick={() => {
                  setInput("");
                }}
              >
                <CloseIcon />
              </IconButton>
            )}

            <IconButton>
              <SearchIcon />
            </IconButton>
          </Paper>
          {/* <CustomSearchbar
            placeholder={
              company?.companyType === "Venue" ? "Venue/City/State" : "Search City/State"
            }
            columns={columns}
            results={jobs?.data || []}
            isLoading={isLoading}
            onSearch={() => refetch()}
            value={input}
            onChange={onChange}
            onRowClick={(row) => navigate(`/jobs/apply/${row.jobSlug}`)}
            showResults={false}
            background="#fff"

          /> */}
        </Grid>
        <Grid item xs={3.6}>
          <Paper
            sx={(theme) => ({
              p: "2px 4px !important",
              display: "flex",
              alignItems: "center",
              width: "100%",
              background: "#fff",
            })}
          >
            <InputBase
              value={zipCode}
              onChange={(e) => {
                setZipCode(e.target.value);
                setTitle("");
                setInput("");
              }}
              variant="outlined"
              type="text"
              placeholder="Zip Code"
              sx={{ ml: 1, flex: 1, p: "2px" }}
            />
            {zipCode?.length !== 0 && (
              <IconButton
                onClick={() => {
                  setZipCode("");
                }}
              >
                <CloseIcon />
              </IconButton>
            )}

            <IconButton>
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Use my location">
            <IconButton
              color="warning"
              onClick={() => {
                getLocation();
              }}
              sx={{
                display: "flex",
                flex: 0,
                alignSelf: "flex-start",
                ...(isMobile ? { padding: 0 } : {}),
              }}
            >
              <LocationOn fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default JobSearchbar;
