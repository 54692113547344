import MDTypography from "components/MDTypography";
import { Grid, IconButton, Stack, Box, Tooltip } from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import { dataStyles as styles } from "layouts/pages/customers/styles";
import { getDisplayText } from "utils/helpers/dataList";
import moment from "moment";
import parse from "html-react-parser";

const CustomContentItem = ({ row, idx, onEdit, onRemove }) => {
  const { modifiedDate, content, name, type } = row;

  return (
    <Box
      onClick={() => {
        onEdit(idx, row);
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <MDTypography variant="body2" sx={styles.font}>
                {name}
              </MDTypography>
            </Grid>
            <Grid item xs={1.5}>
              <MDTypography variant="body2" sx={styles.font}>
                {type}
              </MDTypography>
            </Grid>
            <Grid item xs={3.5}>
              <MDTypography variant="body2" sx={styles.font}>
                {moment(modifiedDate).format("ddd MM/DD/YYYY hh:mm A")}
              </MDTypography>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                title={parse(content)}
                key={`${content}_${modifiedDate}`}
                placement="bottom-start"
              >
                <MDTypography variant="body2" sx={styles.font}>
                  {getDisplayText(content)}
                </MDTypography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <IconButton
              sx={{ p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                onRemove(idx);
              }}
            >
              <Cancel color="error" fontSize="small" />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomContentItem;
