import { Stack, Checkbox, Grid, InputAdornment, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { w4Schema } from "data/w4form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";
import CommonTaxFields from "../CommonTaxFields";
import CurrencyField from "../CurrencyField";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },
});

const W4TaxFormFields = ({ onSubmit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyle();
  const resolver = useYupValidationResolver(w4Schema);
  const [childrensMultiplied, setChildrensMultiplied] = useState(null);
  const [dependentsMultiplied, setDependentsMultiplied] = useState(null);
  const [addedChildrensAndDependents, setAddedChildrensAndDependents] = useState(null);

  const { applicant, updateButtons, buttonState, updateCurrentFormState, currentFormState } =
    useNewApplicantContext();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: {
      multipleJobs: "No",
      numberOfChildren: 0,
      otherDependents: 0,
      otherIncome: 0,
      deductions: 0,
      extraWithholding: 0,
    },
  });

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.id, { shouldDirty: true });
  };

  const benefitField = (value) => (
    <>
      <Grid item xs={1} textAlign="center">
        x
      </Grid>
      <Grid item xs={5.5}>
        <CurrencyField label="Benefit" disabled value={value} />
      </Grid>
    </>
  );

  useEffect(() => {
    setAddedChildrensAndDependents(childrensMultiplied + dependentsMultiplied);
  }, [childrensMultiplied, dependentsMultiplied]);

  useEffect(() => {
    if (applicant?.w4Tax) {
      reset(applicant.w4Tax);
      const { numberOfChildren, otherDependents } = applicant.w4Tax;
      if (numberOfChildren) {
        setChildrensMultiplied(Math.floor(Number(numberOfChildren) * 2000));
      }
      if (otherDependents) {
        setDependentsMultiplied(Math.floor(Number(otherDependents) * 500));
      }
    }
  }, [applicant]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      // reset();
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} />
      </Grid>
      <Stack spacing={3}>
        <MDBox>
          <Controller
            name="filingStatus"
            control={control}
            render={({ field }) => (
              <>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Checkbox
                    id="Single"
                    checked={field.value === "Single"}
                    sx={{ pl: 0 }}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    Single or Married filing separately
                  </MDTypography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Checkbox
                    id="Married"
                    checked={field.value === "Married"}
                    sx={{ pl: 0 }}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    Married filing jointly (or Qualifying widow(er))
                  </MDTypography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Checkbox
                    id="Head of Household"
                    checked={field.value === "Head of Household"}
                    sx={{ pl: 0 }}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    Head of household (Check only if you&apos;re unmarried and pay more than half
                    the costs of keeping up home for yourself and a qualifying individual)
                  </MDTypography>
                </Stack>
              </>
            )}
          />
        </MDBox>
        <MDBox>
          <MDTypography variant="h6">Step 2: Multiple Jobs</MDTypography>
          <Controller
            name="multipleJobs"
            control={control}
            render={({ field }) => (
              <>
                <Stack direction="row" spacing={1} alignItems="center" ml={-1}>
                  <Checkbox
                    id="multipleJobsCheckbox"
                    checked={field.value === "Yes"}
                    onChange={(e, v) => {
                      setValue("multipleJobs", v ? "Yes" : "No", { shouldDirty: true });
                    }}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    Do you have multiple jobs, or do both you and your spouse work?
                  </MDTypography>
                </Stack>
              </>
            )}
          />
        </MDBox>

        <Grid container>
          <MDTypography variant="h6">Step 3: Claim Dependents</MDTypography>
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={10}>
              <Grid container spacing={1} alignItems="center">
                <Grid item sm={10} xs={12}>
                  <MDTypography>
                    Multiply the number of qualifying children under age 17 by $2000
                  </MDTypography>
                </Grid>
                <Grid item sm={2} xs={5.5}>
                  <Controller
                    name="numberOfChildren"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        // InputLabelProps={{ className: classes.label }}
                        InputProps={{
                          // className: classes.input,
                          inputProps: {
                            inputMode: "numeric",
                            type: "number",
                            step: 1,
                            min: 0,
                            max: 25,
                          },
                        }}
                        sx={{ input: { textAlign: "end" } }}
                        variant="outlined"
                        label="Children"
                        decimalScale={0}
                        onValueChange={(e) => {
                          setChildrensMultiplied(Math.floor(Number(e.value) * 2000));
                          setValue("numberOfChildren", e.value, { shouldDirty: true });
                        }}
                        customInput={CustomTextField}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Grid>
                {isMobile && benefitField("2000")}
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12}>
              <CurrencyField disabled label="Total Benefit" value={childrensMultiplied} />
              {errors?.numberOfChildren && (
                <MDTypography className={classes.error} color="error">
                  {errors?.numberOfChildren.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={10}>
              <Grid container spacing={1} alignItems="center">
                <Grid item sm={10} xs={12}>
                  <MDTypography> Multiply the number of other dependents by $500</MDTypography>
                </Grid>
                <Grid item sm={2} xs={5.5}>
                  <Controller
                    name="otherDependents"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        // InputLabelProps={{ className: classes.label }}
                        InputProps={{
                          // className: classes.input,
                          inputProps: {
                            inputMode: "numeric",
                            type: "number",
                            step: 1,
                            min: 0,
                            max: 25,
                          },
                        }}
                        sx={{ input: { textAlign: "end" } }}
                        variant="outlined"
                        label="Dependents"
                        decimalScale={0}
                        onValueChange={(e) => {
                          setDependentsMultiplied(Math.floor(Number(e.value) * 500));
                          setValue("otherDependents", e.value, { shouldDirty: true });
                        }}
                        customInput={FormField}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Grid>
                {isMobile && benefitField("500")}
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12}>
              <CurrencyField disabled label="Total Benefit" value={dependentsMultiplied} />
              {errors?.otherDependents && (
                <MDTypography className={classes.error} color="error">
                  {errors?.otherDependents.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={10} xs={12}>
              <MDTypography> Add the amounts above and enter the total here</MDTypography>
            </Grid>
            <Grid item sm={2} xs={12}>
              <CurrencyField disabled label="TOTAL" value={addedChildrensAndDependents} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <MDTypography variant="h6">Step 4 (optional): Other Adjustments</MDTypography>
          <Grid container alignItems="center" spacing={2}>
            <Grid item sm={10} xs={12}>
              (a) Other income (not from jobs). If you want tax withheld for other income you expect
              this year that won&lsquo;t have withholding, enter the amount of other income here.
              This may include interest, dividends, and retirement income.
            </Grid>
            <Grid item sm={2} xs={12}>
              <Controller
                name="otherIncome"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item sm={10} xs={12}>
              (b) Deductions. If you expect to claim deductions other than the standard deduction
              and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter
              the result here
            </Grid>
            <Grid item sm={2} xs={12}>
              <Controller
                name="deductions"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item sm={10} xs={12}>
              (c) Extra withholding. Enter any additional tax you want withheld each pay period.
            </Grid>
            <Grid item sm={2} xs={12}>
              <Controller
                name="extraWithholding"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <FormErrors errors={errors} />
    </form>
  );
};

export default W4TaxFormFields;
