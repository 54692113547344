import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import PropTypes from "prop-types";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    marginTop: "30px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
  background: "transparent",
  boxShadow: "none",
  "& .MuiStepLabel-iconContainer": {
    "& .MuiBox-root": {
      borderRadius: "100%",
      padding: "10px",
      zIndex: 1,
      backgroundColor: "#cac8c9",
    },
  },
  "& .Mui-active, .Mui-completed": {
    "& .MuiBox-root": {
      backgroundColor: "#ec293b",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#ec293b",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#ec293b",
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#cac8c9",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "red",
    borderRadius: 1,
  },
}));

const CustomStepper = ({ activeStepId, onStepClick, steps }) => (
  <StyledStepper activeStep={activeStepId - 1} alternativeLabel connector={<StyledConnector />}>
    {steps.map(({ label, icon, id }) => {
      const isActive = id <= activeStepId;
      return (
        <Step
          key={label}
          sx={{ width: "100%", ...(isActive && onStepClick ? { cursor: "pointer" } : {}) }}
          onClick={() => onStepClick?.({ id, isActive })}
        >
          <Typography
            align="center"
            className="MuiStepper-alternativeLabel"
            color={isActive ? "#ec293b" : "#cac8c9"}
            variant="subtitle2"
            fontSize="0.75rem"
            sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
          >
            {label}
          </Typography>
          <StepLabel StepIconComponent={() => <FlexWrapper>{icon}</FlexWrapper>} />
        </Step>
      );
    })}
  </StyledStepper>
);

CustomStepper.defaultProps = {
  onStepClick: null,
};

CustomStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStepId: PropTypes.number.isRequired,
  onStepClick: PropTypes.func,
};

export default CustomStepper;
