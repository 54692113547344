import { useEffect, useState } from "react";
import FilterCardBar from "components/FilterCardBar";
import {
  Info as InfoIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PersonAdd as PersonAddIcon,
  Person as PersonIcon,
  PersonOff as PersonOffIcon,
} from "@mui/icons-material";

const InterviewEventFilterCards = ({ setFilters, setPage, selectedCardNumber, rosterFilters }) => {
  const initialCards = [
    {
      color: "info",
      icon: <InfoIcon fontSize="large" color="info" />,
      title: "All",
      tooltip: "",
      number: 0,
      filterField: "filt",
      filterValue: "All",
    },
    {
      color: "success",
      icon: <CheckCircleIcon fontSize="large" color="success" />,
      title: "On Roster",
      tooltip: "",
      number: 0,
      filterField: "filt",
      filterValue: "Roster",
    },
    {
      color: "primary",
      icon: <PersonAddIcon fontSize="large" color="primary" />,
      title: "Attended",
      tooltip: "",
      number: 0,
      filterField: "filt",
      filterValue: "Attended",
    },
    {
      color: "primary",
      icon: <PersonIcon fontSize="large" color="error" />,
      title: "Employee",
      tooltip: "",
      number: 0,
      filterField: "filt",
      filterValue: "Employee",
    },
    {
      color: "warning",
      icon: <PersonOffIcon fontSize="large" color="warning" />,
      title: "No Show",
      tooltip: "",
      number: 0,
      filterField: "filt",
      filterValue: "No Show",
    },
    {
      color: "error",
      icon: <CancelIcon fontSize="large" color="error" />,
      title: "Not on Roster",
      tooltip: "",
      number: 0,
      filterField: "filt",
      filterValue: "Not Roster",
    },
  ];

  const [cards, setCards] = useState(initialCards);

  useEffect(() => {
    const tempCards = [...cards];
    const ndx = cards?.findIndex((item) => item.filterValue === rosterFilters.filt);

    if (ndx > -1) {
      tempCards[ndx].number = selectedCardNumber;
      setCards([...tempCards]);
    }
  }, [rosterFilters, selectedCardNumber]);

  const setTimeFilters = (filter) => {
    setFilters(filter);
    //    setRefreshBadges(prev => !prev);
  };

  return (
    <FilterCardBar
      cards={cards}
      bgColor="primary"
      setFilters={setTimeFilters}
      isBlankForAll
      setPage={setPage}
    />
  );
};

export default InterviewEventFilterCards;
