import { Box, Button, Icon, Typography, useMediaQuery } from "@mui/material";
import GenericModal from "components/GenericModal";
import { AvTimer } from "@mui/icons-material";





const SuccessClockInModal = ({ open, setOpen, inOutParent, date, time }) => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

    const header = (
        inOutParent === "in" ?
            <>
                <Typography variant="h6" textAlign="center">
                    You have successfully clocked in.
                </Typography>
                <Typography variant="h6" textAlign="center">
                    Have a great day today!
                </Typography>
            </>
            :
            <>
                <Box display="flex" justifyContent="end" mt={-7}>
                    <Icon fontSize="large" >
                        description
                    </Icon>
                    <Icon fontSize="large" color="warning">
                        mail
                    </Icon>
                </Box>
                <Typography variant="h6" textAlign="center">
                    You have successfully clocked out.
                </Typography>
                <Typography variant="h6" textAlign="center">
                    Thank you for your hard work today!
                </Typography>
            </>
    );


    const modalBody = (
        <>
            <Typography variant="body2" textAlign="center">
                {date}
            </Typography>
            <Typography variant="body2" textAlign="center">
                {time}
            </Typography>
        </>
    );

    const closeButton = (
        <Button variant="contained" onClick={() => setOpen(false)} >
            close
        </Button>
    )


    return (
        <>
            <GenericModal
                open={open}
                setOpen={setOpen}
                icon={<AvTimer />}
                iconColor="dark"
                iconTitle="Timeclock"
                width={isSmallScreen ? "95%" : "20%"}
                body={modalBody}
                header={header}
                buttons={closeButton}
                buttonLocation="center"
            />
        </>
    );
};

export default SuccessClockInModal;