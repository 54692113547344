/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

// store/api/helper
import { useAppContextController } from "context/AppContext";
import { isEmpty, isEqual, isUndefined } from "lodash";
import { formatSlug } from "utils/helpers/formHelpers";
import { metersToFeet } from "utils/helpers/conversion";

// style
import { Stack, Box, Button } from "@mui/material";
import { FmdGood, Map } from "@mui/icons-material";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";

// components
import DataListContainer from "components/DataListContainer";
import CustomCard from "components/CustomCard";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import PanelActions from "components/PanelActions";
import MapModal from "components/MapModal";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";

import LocationsItem from "./LocationsItem";
import LocationForm from "./LocationForm";
import locationsSchema from "./LocationsSchema";

const emptyValues = {
  locationName: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
  radius: 100,
  graceDistanceFeet: 100,
};

const locFields = [
  "locationName",
  "address",
  "city",
  "state",
  "zip",
  "radius",
  "latitude",
  "longitude",
  "graceDistanceFeet",
];

const LocationsPanel = ({
  name,
  onUpdate,
  locs,
  tempLocs,
  setTempLocs,
  primaryInfo,
  setPrimaryInfo,
  currentLocation,
  setCurrentLocation,
  currentData,
  isVenue = false,
}) => {
  const { isMobile, setCurrentFormDirty, changePanel } = useAppContextController();

  const [isLocationsDirty, setLocationsDirty] = useState(false);
  const [locationIndex, setLocationIndex] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isMapsOpen, setMapsOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [dltIndex, setDltIndex] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  const validationSchema = locationsSchema(primaryInfo, showDetails, isLocationsDirty);
  const resolver = useYupValidationResolver(validationSchema);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm({
    resolver,
  });

  const locName = watch("locationName");
  const locSlug = watch("locationSlug");
  const selAddress = watch("address");
  const selCity = watch("city");
  const selState = watch("state");
  const selZip = watch("zip");
  const coordinates = watch("geocoordinates.coordinates");

  const geocoding = useMapsLibrary("geocoding");

  const geocode = (request) => {
    return geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        return results;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(`Geocode error: ${e}`);
      });
  };

  const handleMapsSubmit = (data) => {
    const { center, radius, graceDistance } = data;

    if (center) {
      setValue("latitude", center.lat, { shouldDirty: true });
      setValue("longitude", center.lng, { shouldDirty: true });
      setValue("geocoordinates.coordinates", [center.lng, center.lat], { shouldDirty: true });
    }
    if (radius) {
      setValue("radius", radius, { shouldDirty: true });
      setValue("geocoordinates.geoFenceRadius", radius, { shouldDirty: true });
    }

    if (graceDistance)
      setValue("graceDistanceFeet", metersToFeet(graceDistance), { shouldDirty: true });

    setMapsOpen(false);
  };

  // adjusts current selected location depending if its new or an existing data that was edited
  const handleMethod = (method, data) => {
    setLocationsDirty(false);
    switch (method) {
      case "create":
        setLocationIndex(tempLocs?.length);
        setCurrentLocation(data[tempLocs?.length]);
        break;
      case "update":
        setLocationIndex(locationIndex);
        setCurrentLocation(data[locationIndex]);
        break;
      case "primary-only":
        setLocationIndex(null);
        setCurrentLocation(emptyValues);
        break;
      default:
        break;
    }
  };

  const filterNecessaryData = (data) => {
    const { address, city, state, zip, radius, latitude, longitude, graceDistanceFeet } = data;

    const location = {
      address,
      city,
      state,
      zip,
      radius,
      latitude,
      longitude,
      graceDistanceFeet,
      coordinates: [Number(longitude) || 0, Number(latitude) || 0],
      geoFenceRadius: radius,
    };

    return location;
  };

  const handleLocationEdit = (i, data) => {
    let location;

    const updatedLocs = tempLocs.map((item, index) => {
      if (index === i) {
        return { ...item, ...data, primaryLocation: item.primaryLocation };
      }
      return item;
    });

    const dirtyTest = Object.keys(dirtyFields).some((a) => locFields.includes(a));
    if (updatedLocs[i]?.primaryLocation === "Yes" && dirtyTest) {
      location = filterNecessaryData(data);
    }

    return { updatedLocs, ...(location && { location }) };
  };

  const handleConvertLatLng = (data) => {
    return {
      ...data,
      latitude: Number(data?.latitude || 0),
      longitude: Number(data?.longitude || 0),
    };
  };

  const handleLocationSave = (data) => {
    let updatedLocs;
    let method;
    let location = primaryInfo;
    let values = {};

    const tempData = handleConvertLatLng(data);

    if (locationIndex !== null) {
      const details = handleLocationEdit(locationIndex, tempData);
      updatedLocs = details?.updatedLocs;
      if (details?.location) location = details?.location;
    } else if (tempData?.locationName !== "") {
      method = "create";
      updatedLocs = [
        ...tempLocs,
        { ...tempData, ...(tempLocs?.length === 0 && { primaryLocation: "Yes" }) },
      ];
      if (tempLocs?.length === 0) location = tempData;
    } else {
      method = "primary-only";
      location = primaryInfo;
      updatedLocs = tempLocs;
    }

    let additionalInfo;

    if (location) {
      const { address, city, state, zip } = location;
      additionalInfo = {
        ...(!isUndefined(address) && { address }),
        ...(!isUndefined(city) && { city }),
        ...(!isUndefined(state) && { state }),
        ...(!isUndefined(zip) && { zip }),
      };
    }

    values = {
      ...(location && { location: { ...currentData?.location, ...location } }),
      ...(additionalInfo && { ...additionalInfo }),
      locations: updatedLocs,
    };

    if (!isEmpty(values)) onUpdate(values, method, handleMethod);
  };

  // temporarily save current form data when switching locations
  const handleLocTempSave = () => {
    const data = getValues();
    const tempData = handleConvertLatLng(data);
    const details = handleLocationEdit(locationIndex, tempData);
    if (details?.location) setPrimaryInfo(details?.location);
    setTempLocs(details?.updatedLocs);
  };

  // shows form details and adjusts current location on add new location or location row select
  const handleLocation = (idx, row) => {
    if (isDirty) handleLocTempSave();

    setShowDetails(true);
    setLocationIndex(idx);
    if (idx === null) setCurrentLocation(emptyValues);
    else setCurrentLocation(row);
  };

  // sets current location empty and hides form details if no location is selected or one was just deleted
  const handleEmptyLocReset = () => {
    setLocationIndex(null);
    setCurrentLocation(emptyValues);
    setShowDetails(false);
  };

  // sets form values to corresponding location
  const resetCurrentLocation = () => {
    if (currentLocation) reset(currentLocation);
    else reset(emptyValues);
  };

  // adapts to new selected index whenever array length changes
  const handleLocationsOrderChange = (locsData) => {
    const updatedIndex = locsData.findIndex((item) => item === currentLocation);
    if (updatedIndex > -1) {
      setLocationIndex(updatedIndex);
      setCurrentLocation(locsData[updatedIndex]);
    } else {
      handleEmptyLocReset();
    }
  };

  const handleRemoveLocation = () => {
    const temporaryLocs = tempLocs;
    const updatedLocs = temporaryLocs?.filter((_, index) => index !== dltIndex);
    if (locationIndex !== null) handleLocationsOrderChange(updatedLocs);
    setTempLocs(updatedLocs);
  };

  const handleRemoveModal = (idx) => {
    setDltIndex(idx);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setDltIndex(null);
    setOpen(false);
  };

  const handlePrimaryChange = (newIdx) => {
    const temporaryLocs = tempLocs;
    const oldIdx = tempLocs.findIndex(({ primaryLocation }) => primaryLocation === "Yes");
    const updatedLocs = temporaryLocs.map((item, i) => {
      if (i === oldIdx) return { ...item, primaryLocation: "No" };
      if (i === newIdx) return { ...item, primaryLocation: "Yes" };
      return item;
    });

    setTempLocs(updatedLocs);
    setPrimaryInfo(filterNecessaryData(updatedLocs[newIdx]));
  };

  // to prevent modal rerender on react query rerender
  const memoizedMapModal = useMemo(
    () => (
      <MapModal
        open={isMapsOpen}
        setOpen={setMapsOpen}
        name={currentData?.name}
        currentLocation={watch()}
        onSubmit={handleMapsSubmit}
        title={isVenue ? "Venue" : "Location"}
      />
    ),
    [isMapsOpen]
  );

  const renderAdditionalLocations = (row, idx) => (
    // rename from CustomerlocsItem
    <LocationsItem
      row={row}
      idx={idx}
      onEdit={handleLocation}
      onRemove={handleRemoveModal}
      onPrimaryChange={handlePrimaryChange}
    />
  );

  const formSlug = (loc) => formatSlug(currentData?.slug, loc);

  const handleCancelChanges = () => {
    resetCurrentLocation();
    handleLocationsOrderChange(locs);
    setPrimaryInfo();
    setTempLocs(locs);
    setLocationsDirty(false);
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleLocationSave)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  // on current data change
  useEffect(() => {
    reset(emptyValues);
    handleEmptyLocReset();
  }, [currentData?.slug]);

  // when temporary changes on location list is made
  useEffect(() => {
    if (isEqual(tempLocs, locs)) setLocationsDirty(false);
    else setLocationsDirty(true);
  }, [tempLocs]);

  useEffect(() => {
    resetCurrentLocation();
  }, [currentLocation]);

  // to enable discard changes modal when switching panels
  useEffect(() => {
    setCurrentFormDirty(isDirty || isLocationsDirty);
  }, [isDirty, isLocationsDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
  }, []);

  useEffect(() => {
    if (isEmpty(locSlug) && locName) {
      setValue("locationSlug", formSlug(locName));
    }
  }, [locName, currentData?.slug]);

  useEffect(() => {
    if (!geocoding) return;
    setGeocoder(new geocoding.Geocoder());
  }, [geocoding]);

  useEffect(() => {
    if (!geocoder) return;

    const handleGeocode = async (data) => {
      const res = await geocode(data);
      const loc = res?.[0]?.geometry?.location;
      setValue("latitude", loc.lat(), { shouldDirty: true });
      setValue("longitude", loc.lng(), { shouldDirty: true });
      setValue("geocoordinates.type", "Point");
      setValue("geocoordinates.coordinates", [loc.lng(), loc.lat()], { shouldDirty: true });
    };
    const { address, city, state, zip } = dirtyFields;
    if (address || city || state || zip) {
      const addrArr = [selAddress, selCity, selState, selZip];
      const fullAddress = addrArr.toString();
      if (!isEmpty(selAddress) && !isEmpty(selCity) && !isEmpty(selState) && !isEmpty(selZip)) {
        handleGeocode({ address: fullAddress });
      }
    }
  }, [geocoder, selAddress, selCity, selState, selZip]);

  return (
    <>
      <form onSubmit={handleSubmit(handleLocationSave)}>
        <CustomCard
          icon={<FmdGood color="white" />}
          cardTitle={`Locations: ${name}`}
          cardActions={
            (isDirty || isLocationsDirty) && <PanelActions onCancel={handleCancelChanges} />
          }
          {...customPanelCard(isMobile, "success")}
        >
          <Stack spacing={4} sx={styles.container}>
            <Box>
              <DataListContainer
                onAdd={handleLocation}
                data={tempLocs}
                renderRow={renderAdditionalLocations}
                title="Locations"
                tableHeight={245}
                height={247}
                selected={locationIndex}
              />
            </Box>
            {showDetails && (
              <Box>
                <LocationForm
                  control={control}
                  setValue={setValue}
                  formSlug={formSlug}
                  coordinates={coordinates}
                />
                <Box mt={3}>
                  <Button
                    variant="contained"
                    onClick={() => setMapsOpen(true)}
                    startIcon={<Map />}
                    sx={{ width: "32%", padding: "0.5rem 1rem" }}
                    disabled={!(watch("latitude") && watch("longitude"))}
                  >
                    Google Maps
                  </Button>
                </Box>
              </Box>
            )}
          </Stack>
        </CustomCard>
        <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
        <ConfirmModal
          title="Delete Location"
          description="Are you sure you want to delete this location?"
          negativeBtn={{
            label: "No",
            fn: handleCloseModal,
            isShown: true,
            isOpen: true,
          }}
          positiveBtn={{
            label: "Yes",
            fn: async () => {
              handleRemoveLocation();
              handleCloseModal();
            },
            isShown: true,
          }}
          isOpen={isOpen}
          closeFn={handleCloseModal}
        />
      </form>
      {memoizedMapModal}
    </>
  );
};

export default LocationsPanel;
