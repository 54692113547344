import { useState } from 'react'
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import FileDropzone from 'components/Dropzone';
import uploadImage from "api/upload/uploadImage";
import { Box, Card, Grid, styled } from '@mui/material';
import MDBox from 'components/MDBox';
import { Landscape, AttachFile } from "@mui/icons-material";
import MDTypography from 'components/MDTypography';
import { useSnackbar } from "notistack";
import { cardStyles } from "layouts/pages/events/styles";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import CustomCard from 'components/CustomCard';
import { useMutation, useQueryClient } from "react-query";
import UploadFileModal from '../EventsInformation/UploadFileModal'
import Attachments from '../EventsInformation/Attachments';

const useStyle = makeStyles({
    dropzone: {
        height: 170,
    },
});
const EventAttachments = () => {

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyle();
    const AvatarImg = styled(Box)(({ theme }) => ({ width: "100%" }));
    const {
        currentEvent,
        setCurrentEvent,
        company,
    } = useAppContextController();
    const [attachmentOpen, setAttachmentOpen] = useState(false);
    const { mutate: uploadMutation } = useMutation(
        async (data) => {

            console.log("data", data)
            const form = new FormData();
            form.append("file", data.file);

            await uploadImage({
                uploadPath: `${currentEvent.venueSlug}/events/${currentEvent?.eventUrl}`,
                form,
            });

            await updateEvent({ eventId: currentEvent._id, data: { eventImage: data.eventImage } });
        },
        {
            onError: (error) => enqueueSnackbar(`Error: ${error}`, { variant: "error" }),
            onSuccess: (_, data) => {
                setCurrentEvent({ ...currentEvent, ...data });
                enqueueSnackbar(`Image been updated!`, { variant: "success" });
            },
        }
    );

    const onDropImageHandler = (img) => {
        const file = img[0];
        uploadMutation({ eventImage: file.name, file });
    };


    const avatar = currentEvent?.eventImage ? (
        <Card>
            <AvatarImg
                component="img"
                src={`${company?.imageUrl}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${currentEvent?.eventImage}`}
                alt={
                    currentEvent?.eventImage ? `${currentEvent.eventName} Event Image` : "No Event Image Set"
                }
            />
        </Card>
    ) : (
        <Landscape sx={{ width: "5em", height: "5em" }} />
    );

    return (<>
        <CustomCard
            icon={<AttachFile color="white" />}
            cardTitle="Event Attachments"
            iconContainerSx={{ backgroundColor: "warning", mt: -3, ml: "1.5rem" }}
            cardSx={cardStyles.card}
            titleSx={cardStyles.title}
            isStacked
        >
            <Box sx={cardStyles.container}>
                <Grid item xs={12} sm={6}>

                    <MDBox mt={3}>
                        <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                            EVENT IMAGE
                        </MDTypography>

                        <Grid
                            item
                            xs={6}
                            mt={2}
                            style={{ maxWidth: "25%", marginLeft: "auto", marginRight: "auto" }}
                        >
                            <FileDropzone
                                className={classes.dropzone}
                                onDrop={onDropImageHandler}
                                avatar={avatar}
                            />
                        </Grid>
                    </MDBox>

                </Grid>
                <Grid item xs={12} sm={6}>

                    <MDBox mt={2}>
                        <Attachments
                            setCurrentEvent={setCurrentEvent}
                            currentEvent={currentEvent}
                            setAttachmentOpen={setAttachmentOpen}
                        />
                    </MDBox>

                </Grid>
                <UploadFileModal
                    currentEvent={currentEvent}
                    attachmentOpen={attachmentOpen}
                    setAttachmentOpen={setAttachmentOpen}
                />
            </Box>
        </CustomCard >
    </>
    )
}

export default EventAttachments