import axios from "axios";

import { baseAxios } from "config";

const deleteIssue = async (issueId) => {
  try {
    if (!issueId) throw new Error("issueId required");
    const res = await baseAxios.delete(`/issues/${issueId}`);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default deleteIssue;
