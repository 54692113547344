// import { useLocation, useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import { tagsParser } from "utils/helpers/tagsParser";

const FiltersList = ({
  filters,
  setFilters,
  setSearchTagList,
  searchTagList,
  clearTagsHandler,
  displayOtherFilters = false,
  onlyDisplayTags = false,
}) => {
  // const navigate = useNavigate()
  // const location = useLocation()
  const isFilters = filters ? Object.keys(filters).length > 0 : false;

  // const deleteFilter = (filter) => {
  //   const newObject = {};

  //   Object.keys(filters || {}).forEach((key) => {
  //     if (key !== filter) {
  //       newObject[key] = filters[key];
  //     }
  //   });

  //   setFilters?.(newObject);
  //   navigate(location.pathname.split('/').splice(0,3).join('/'))
  // };

  const deleteFilter = (filter) => {
    const fils = { ...filters };
    delete fils[filter];
    setFilters({ ...fils });
  };

  const deleteFilterTag = (tag) => {
    const tagsTemp = searchTagList?.filter((filterTag) => filterTag !== tag);
    setSearchTagList(tagsTemp);
    setFilters({ ...filters, tags: tagsParser(tagsTemp) });
  };

  useEffect(() => {
    if (displayOtherFilters) {
      return;
    }
    if (!filters?.tags) {
      clearTagsHandler?.();
      return;
    }
    const tagsArr = filters?.tags?.replaceAll(";", ",").split(",");
    setSearchTagList(tagsArr);
  }, [filters?.tags]);

  const displayChip = (uniqueKey, isTag, typeOfFilter, chipLabel) => {
    return (
      <Chip
        key={uniqueKey}
        color="info"
        sx={{ textTransform: "uppercase", mx: 0.5, mb: 0.5 }}
        label={isTag ? `${chipLabel}` : `${typeOfFilter}: ${chipLabel}`}
        onDelete={() => (isTag ? deleteFilterTag(chipLabel) : deleteFilter(typeOfFilter))}
        size="small"
      />
    );
  };

  return (
    <>
      {isFilters && !onlyDisplayTags && (
        <MDBox display="flex">
          <MDBox mr={2}>
            <MDTypography variant="h5">Filters:</MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
            {Object.keys(filters || {}).flatMap((key) => {
              return key !== "tags"
                ? displayChip(key, false, key, filters[key])
                : searchTagList?.map((tag) => displayChip(tag, true, tag, tag));
            })}
          </MDBox>
        </MDBox>
      )}
      {filters?.tags && onlyDisplayTags && (
        <MDBox display="flex">
          <MDBox mr={2}>
            <MDTypography variant="h5">Tags:</MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
            {searchTagList?.map((tag) => displayChip(tag, true, tag, tag))}
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

export default FiltersList;
