import { Error } from "@mui/icons-material";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";

const FormErrors = ({ errors, containerClass = "" }) => {
  if (isEmpty(errors)) {
    return null;
  }
  return (
    <Box
      sx={{
        mt: "16px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        py: "12px",
        px: "24px",
        gap: "10px",
        borderRadius: "10px",
        backgroundColor: "#ec293b36",
        border: "1px solid #ec293bbf",
      }}
    >
      <Error sx={{ color: "#ec293b" }} />
      <div>
        {Object.values(errors).map((error, index) => (
          <Box key={error?.message} sx={{ display: "flex", flexDirection: "column", color: "#000", fontSize: "14px" }}>
            {error?.message || ""}
          </Box>
        ))}
      </div>
    </Box>
  );
};

export default FormErrors;
