import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useStyles } from "./styles";
import "./CustomDataGrid.css";

const handleRowClassName = (params) => {
  return params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row";
};

const CustomDataGrid = ({ rows, columns }) => {
  const classes = useStyles();
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        className={classes.root}
        rows={rows}
        columns={columns}
        getRowClassName={handleRowClassName}
        hideFooter
        sx={{ border: "none" }}
      />
    </Box>
  );
};

export default CustomDataGrid;
