import { useState } from "react";
import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

const MyInterviewsActions = ({ row }) => {
  const navigate = useNavigate();
  const setNavigateUrl = (url) => navigate(url);

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    setNavigateUrl(`/events/${row.eventUrl}/action/eventsinfo`);
  };

  const [actions, setActions] = useState([
    {
      color: "info",
      icon: "info",
      clickAction: (e) => onClickHandler("interviewInfo", e),
      tooltip: "Interview Info",
    },
    {
      color: "warning",
      icon: <PersonAddIcon />,
      clickAction: (e) => onClickHandler("interveiwApplicants", e),
      tooltip: "Applicants",
    },
  ]);

  return (
    <MDBox display="flex" justifyContent="end">
      {actions.map((prop) => (
        <TableAction {...prop} />
      ))}
    </MDBox>
  );
};

export default MyInterviewsActions;
