import CustomMenu from "components/CustomizedMui/CustomMenu";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Card, Grid, IconButton, Popover } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import { makeStyles } from "@mui/styles";
import { PlayCircle } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

const useStyle = makeStyles({
  box: {
    padding: "2rem",
  },
  button: {
    padding: 0,
  },
});

const key = Math.round(Math.random() * 10000);

const DateRangePopover = ({ anchorEl, onClose, startDate, endDate }) => {
  const classes = useStyle();
  const [localStartDate, setLocalStartDate] = useState(moment().toDate());
  const [localEndDate, setLocalEndDate] = useState(moment().toDate());

  useEffect(() => {
    if (anchorEl) {
      if (startDate) {
        setLocalStartDate(startDate);
      } else {
        setLocalStartDate(moment().toDate());
      }
      if (endDate) {
        setLocalEndDate(endDate);
      } else {
        setLocalEndDate(moment().toDate());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const handleClose = () => {
    onClose(moment(localStartDate).startOf("day"), moment(localEndDate).endOf("day"));
  };

  return (
    <Popover
      id={`popover-${key}`}
      open={anchorEl != null}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          style: {
            padding: "0.5rem",
            backgroundColor: "#fff",
            boxShadow:
              "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          },
        },
      }}
    >
      <Grid container spacing={2} direction="row" wrap="nowrap" alignItems="center">
        <Grid item container spacing={4}>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date From"
                views={["year", "month", "day"]}
                value={localStartDate}
                onChange={(time) => {
                  setLocalStartDate(time);
                  if (moment(time).isAfter(moment(localEndDate))) {
                    setLocalEndDate(time);
                  }
                }}
                renderInput={(params) => <FormField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date To"
                views={["year", "month", "day"]}
                value={localEndDate}
                onChange={(time) => {
                  setLocalEndDate(time);
                  if (moment(time).isBefore(moment(localStartDate))) {
                    setLocalStartDate(time);
                  }
                }}
                renderInput={(params) => <FormField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton size="large" className={classes.button} color="info" onClick={handleClose}>
            <PlayCircle />
          </IconButton>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default DateRangePopover;
