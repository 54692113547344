import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MDBox from "components/MDBox";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import createCompany from "api/companies/createCompany";
import updateCompany from "api/companies/updateCompany";
import DataListContainer from "components/DataListContainer";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import MDTypography from "components/MDTypography";
import { IMG_SERVER } from "config";
import { useAppContextController } from "context/AppContext";
import { createCompanyInfoSchema, DEFAULT_CUSTOMER } from "data/companyInfo";
import useCompanyCache from "hooks/useCompanyCache";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useCompanyStore from "stores/useCompanyStore";
import { getDirtyFields } from "utils/helpers/formHelpers";
import AddCompanyLocationModal from "../CompanyInfo/AddCompanyLocationModal";
import CompanyFormActions from "./CompanyFormActions";
import CompanyLocationsItem from "../CompanyLocationsItem";
import CompanyPreview from "../CompanyPreview";
import CompanyHeader from "./CompanyHeader";
import CompanyInformationSection from "../CompanyInformationSection";

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const CompanyForm = ({ onSubmit, setRefreshCompanies, panelOptions }) => {
  const { setCompanyFormState, companyFormState, setCurrentCompany, currentCompany } =
    useCompanyStore((state) => ({
      setCompanyFormState: state.setCompanyFormState,
      companyFormState: state.companyFormState,
      setCurrentCompany: state.setCurrentCompany,
      currentCompany: state.currentCompany,
    }));

  const { company, setCompany, setCompanyType, currentLoggedUser } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [companyStatus, setCompanyStatus] = useState("Active");

  // const resolver = useYupValidationResolver(companyInfoSchema);
  const isEdit = !!currentCompany?._id;
  const validationSchema = createCompanyInfoSchema(companyStatus, isEdit);
  const resolver = useYupValidationResolver(validationSchema);

  const [addCompanyLocationModalOpen, setAddCompanyLocationModalOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationIndex, setLocationIndex] = useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    setError,
    trigger,
    formState,
  } = useForm({ resolver });
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful, dirtyFields } = formState;

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const { mutateAsync: createCompanyMutation } = useMutation(createCompany);

  const watchStatus = watch("status", companyStatus); // Watching the 'status' field for changes

  useEffect(() => {
    setCompanyStatus(watchStatus); // Update the state whenever the 'status' changes
  }, [watchStatus]);

  const saveAdditionalDetails = async (values) => {
    if (!currentCompany?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: currentCompany._id, body: values },
        {
          onSuccess: async (res) => {
            setRefreshCompanies((prev) => !prev);
            setCurrentCompany({ ...currentCompany, ...values });
            if (currentCompany?.primaryCompany) {
              setCompany({ ...company, ...values });
            }
            enqueueSnackbar("Company has been updated.", { variant: "success" });
            refetchCompany();
            await queryClient.invalidateQueries("companies");
          },
        }
      );
    }
  };

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setPreviewOpen(!isPreviewOpen);
  };

  const updateCompanyHandler = (data) => {
    updateCompanyMutation(
      {
        companyId: currentCompany?._id,
        body: {
          ...getDirtyFields(data, dirtyFields),
        },
      },
      {
        onSuccess: () => {
          setRefreshCompanies((prev) => !prev);
          setCurrentCompany({ ...currentCompany, ...data });
          if (currentCompany?.primaryCompany) {
            setCompany({ ...company, ...data });
          }
          enqueueSnackbar("Company has been updated.", { variant: "success" });
          reset(data, { keepValues: true });
        },
      }
    );
  };

  const createCompanyHandler = async (values) => {
    const data = values;
    data.createAgent = currentLoggedUser._id;
    data.imageUrl = isEmpty(company)
      ? `${IMG_SERVER}/${values.slug}`
      : `${IMG_SERVER}/${company.slug}`;

    await createCompanyMutation(data, {
      onSuccess: (res) => {
        if (res.acknowledged) {
          setRefreshCompanies((prev) => !prev);
          onSubmit?.();
          enqueueSnackbar("Customer has been created!", { variant: "success" });
          setCurrentCompany({ _id: res.insertedId, ...data });
          navigate(`/customers/${data.slug}`);
        }
      },
      onError: (err) => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    });
  };

  const handleLocation = (idx, row, isOpen) => {
    setLocationIndex(idx);
    setCurrentLocation(row);
    setAddCompanyLocationModalOpen(isOpen);
  };

  const handleRemoveLocation = (idx) => {
    const temporaryLocations = getValues("locations");
    const updatedLocations = temporaryLocations.filter((_, index) => index !== idx);
    setValue("locations", updatedLocations);
    saveAdditionalDetails({ locations: updatedLocations });
    setAddCompanyLocationModalOpen(false);
  };

  const renderCompanyLocations = (row, idx) => (
    <CompanyLocationsItem
      row={row}
      idx={idx}
      onEdit={handleLocation}
      onRemove={handleRemoveLocation}
    />
  );

  useEffect(() => {
    if (!isEmpty(currentCompany)) {
      delete currentCompany?.longitude;
      delete currentCompany?.latitude;
      delete currentCompany?.radius;
    }

    reset(isEdit ? currentCompany : DEFAULT_CUSTOMER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, isEdit]);

  useEffect(() => {
    setCompanyFormState({ ...companyFormState, isDirty, dirtyFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields]);

  return (
    <>
      <FlexWrapper justifyContent="space-between">
        <Grid container sx={{ px: "1.5rem" }} alignItems="center">
          <Grid item xs={12} sm={7}>
            <CompanyHeader sourceComponent="Company" />
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDBox display="flex" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" flexWrap="wrap">
                {panelOptions.map((tab) => (
                  <Tooltip title={tab?.title} key={tab?.title}>
                    <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow} m={1}>
                      <IconButton
                        color={tab.color}
                        aria-label={tab.ariaLabel}
                        onClick={tab.fn}
                        disabled={!isEdit} // || (tab.title === "Sales" && watchStatus !== "Prospect")}
                      >
                        {tab.icon}
                      </IconButton>
                    </MDBox>
                  </Tooltip>
                ))}
              </Stack>
            </MDBox>
          </Grid>
        </Grid>
        <CompanyFormActions
          sourceComponent="Company"
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          resetChanges={() => {
            reset(currentCompany);
          }}
          currentCompany={currentCompany}
          onPreview={() => openPreviewModal("all")}
        />
      </FlexWrapper>
      <Box sx={{ height: 660, overflowY: "auto", pt: 0.5 }}>
        <form
          onSubmit={handleSubmit(isEdit ? updateCompanyHandler : createCompanyHandler)}
          id="company-form"
          style={{ width: "100%" }}
        >
          <Grid container spacing={3} padding={3} paddingRight={0}>
            {/* <Grid container xs={12} sm={12} md={12} lg={6}> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FlexWrapper
                flexWrap="wrap"
                alignContent="flex-start"
                justifyContent="flex-start"
                maxWidth={{ lg: "96%", sm: "100%", xs: "100%" }}
                height="100%"
                flexDirection="column"
              >
                <InputFlexWrapper
                  justifyContent="flex-start"
                  alignSelf="flex-start"
                  maxWidth="100%"
                  minHeight={38}
                >
                  <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
                    Primary Company Information
                  </MDTypography>
                </InputFlexWrapper>

                <CompanyInformationSection
                  control={control}
                  setValue={setValue}
                  isEdit={isEdit}
                  selectedStatus={watch("status")}
                  isCompany
                />
                <InputFlexWrapper maxWidth="100%" width="100%" mt={2}>
                  <DataListContainer
                    tableHeight={220}
                    height={225}
                    onAdd={handleLocation}
                    data={watch("locations")}
                    renderRow={renderCompanyLocations}
                    title="Company Locations"
                  />
                </InputFlexWrapper>
              </FlexWrapper>
            </Grid>
          </Grid>
        </form>
      </Box>
      {addCompanyLocationModalOpen && (
        <AddCompanyLocationModal
          setValue={setValue}
          getValues={getValues}
          saveChanges={saveAdditionalDetails}
          addCompanyLocationModalOpen={addCompanyLocationModalOpen}
          setAddCompanyLocationModalOpen={setAddCompanyLocationModalOpen}
          locations={currentCompany?.locations}
          currentCompany={currentCompany}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          locationIndex={locationIndex}
          setLocationIndex={setLocationIndex}
          isEdit={isEdit}
        />
      )}
      {isPreviewOpen && (
        <CompanyPreview
          open={isPreviewOpen}
          onClose={() => setPreviewOpen(!isPreviewOpen)}
          content={previewContent}
        />
      )}
    </>
  );
};

export default CompanyForm;
