import { useState } from "react";
import { Stack, Button } from "@mui/material";
import { Cancel, Save } from "@mui/icons-material";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";

const PanelActions = ({ onCancel, onSave }) => {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleCloseModal = () => setConfirmModalOpen(false);
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          variant="text"
          type="button"
          onClick={() => setConfirmModalOpen(true)}
          startIcon={<Cancel />}
          color="error"
          sx={{ color: "#F44335", fontSize: 14, padding: "0" }}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          startIcon={<Save />}
          type="submit"
          onClick={onSave}
          color="success"
          sx={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5, padding: "0" }}
        >
          Save
        </Button>
      </Stack>
      {isConfirmModalOpen && (
        <ConfirmModal
          title="Discarding changes"
          description="Are you sure you want to discard changes?"
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              onCancel();
              handleCloseModal();
            },
            isShown: true,
            isOpen: true,
          }}
          negativeBtn={{
            label: "Cancel",
            fn: handleCloseModal,
            isShown: true,
          }}
          isOpen={isConfirmModalOpen}
          closeFn={handleCloseModal}
        />
      )}
    </>
  );
};

export default PanelActions;
