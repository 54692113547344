import { ArrowBack, ArrowForward, Save } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import CustomCard from "components/CustomCard";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import NewApplicantForms from "layouts/pages/applicants/new/components/NewApplicantForms";
import UnsavedChangesModal from "layouts/pages/applicants/new/components/NewApplicantForms/UnsavedChangesModal";
import { useMemo, useState } from "react";
import { ONBOARDING_OBJECTS_ENUM } from "utils/constants/applicant";

const FormContainer = () => {
  const { onNextStep, onPreviousStep, getActiveRegistrationStep, buttonState, currentFormState } =
    useNewApplicantContext();

  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [clickDirection, setClickDirection] = useState(null);

  const handleOnNext = () => {
    if (currentFormState?.isDirty) {
      setClickDirection("next");
      setOpenUnsavedChangesModal(true);
    } else {
      onNextStep();
    }
  };
  const handleOnPrevious = () => {
    if (currentFormState?.isDirty) {
      setClickDirection("previous");
      setOpenUnsavedChangesModal(true);
    } else {
      onPreviousStep();
    }
  };

  const activeStep = useMemo(() => getActiveRegistrationStep(), [getActiveRegistrationStep]);

  return (
    <>
      <CustomCard
        icon={activeStep?.icon}
        cardSx={{ mt: "35px", overflow: "visible!important" }}
        cardTitle={activeStep?.label}
        cardActions={
          <FlexWrapper justifyContent="end">
            {buttonState?.submit?.show && (
              <Button
                form="current-form"
                type="submit"
                variant="contained"
                color="primary"
                disabled={buttonState?.submit?.disabled}
                sx={(theme) => ({
                  mr: "15px",
                  [theme.breakpoints.down("lg")]: {
                    display: "none",
                  },
                })}
                endIcon={<Save />}
              >
                {activeStep?.applicantObject === ONBOARDING_OBJECTS_ENUM.ACKNOWLEDGEMENT &&
                  "Sign and Submit Application"}
                {activeStep?.applicantObject === ONBOARDING_OBJECTS_ENUM.I9_FORM && "Sign and Save"}
                {activeStep?.applicantObject !== ONBOARDING_OBJECTS_ENUM.I9_FORM &&
                  activeStep?.applicantObject !== ONBOARDING_OBJECTS_ENUM.ACKNOWLEDGEMENT &&
                  "Save"}
              </Button>
            )}
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("lg")]: {
                  mt: "20px",
                  display: "flex",
                  width: "100%",
                },
              })}
            >
              {buttonState?.previous?.show && (
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  sx={(theme) => ({
                    mr: "5px",
                    [theme.breakpoints.down("lg")]: {
                      display: "flex",
                      flex: 1,
                    },
                  })}
                  startIcon={<ArrowBack />}
                  onClick={() => handleOnPrevious()}
                  disabled={buttonState?.previous?.disabled}
                >
                  Previous
                </Button>
              )}
              {buttonState?.next?.show && (
                <Button
                  id="next-btn"
                  type="button"
                  variant="contained"
                  color="success"
                  endIcon={<ArrowForward />}
                  onClick={() => handleOnNext()}
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      display: "flex",
                      flex: 1,
                    },
                  })}
                  disabled={buttonState?.next?.disabled}
                >
                  Next
                </Button>
              )}
            </Box>
          </FlexWrapper>
        }
      >
        <Box sx={{ border: "1.5px solid black", p: "10px", position: "relative" }}>
          <NewApplicantForms />
          <Button
            form="current-form"
            type="submit"
            variant="contained"
            color="primary"
            sx={(theme) => ({
              mt: "20px",
              float: "right",
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
              [theme.breakpoints.down("md")]: {
                display: "flex",
                width: "100%",
              },
            })}
            endIcon={<Save />}
          >
            Save
          </Button>
        </Box>
      </CustomCard>
      <UnsavedChangesModal
        clickDirection={clickDirection}
        openUnsavedChangesModal={openUnsavedChangesModal}
        setOpenUnsavedChangesModal={setOpenUnsavedChangesModal}
      />
    </>
  );
};

export default FormContainer;
