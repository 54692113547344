import axios from "axios";
import { baseAxios } from "config";

const mergeApplicants = async (body) => {
  try {
    const res = await baseAxios.post(`/applicants/merge`, body);
    return res;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default mergeApplicants;
