import * as yup from "yup";

export const alabamaStateFormSchema = yup.object().shape({
    filingStatus: yup.string().required("filling status is required"),
    noPersonalExemption: yup.number(),
    singleOrMarriedFillingSeparately: yup.string(),
    marriedOrSingleClaimingHeadOfFamily: yup.string(),
    dependents: yup.number(),
    additionalAmountDeducted: yup.number(),
    totalExemptions: yup.string(),
});
