import { baseAxios } from "config";

const axios = require("axios");

export default async function fetchSingleStaffUser(filter) {
  try {
    let filterString = "filter=spStatus:Employee,";
    filterString += Object.keys(filter)
      .map((item) => `${item}:${filter[item]}`)
      .join(",");
    const res = await baseAxios.get(`/users?${filterString}`);
    return res.data.data?.[0];
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
}
