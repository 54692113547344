/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useState, useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

// store/api/helper
import useCompanyStore from "stores/useCompanyStore";
import updateCompany from "api/companies/updateCompany";
import LocationsPanel from "components/LocationsPanel";

const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const CustomerLocations = () => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const [locs, setLocs] = useState([]);
  const [tempLocs, setTempLocs] = useState(locs);
  const [primaryInfo, setPrimaryInfo] = useState();
  const [currentLocation, setCurrentLocation] = useState(emptyValues);

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCompany = async (values, method = "update", onSuccess) => {
    if (!currentCompany?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: currentCompany?._id, body: values },
        {
          onSuccess: async (res) => {
            setCurrentCompany({ ...currentCompany, ...values });
            onSuccess(method, values?.locations);
            enqueueSnackbar("Customer locations has been updated.", { variant: "success" });
            await queryClient.invalidateQueries("venues");
          },
        }
      );
    }
  };

  // on company change or current company update
  useEffect(() => {
    setPrimaryInfo();
    if (currentCompany?.locations) {
      setLocs(currentCompany?.locations);
      setTempLocs(currentCompany?.locations);
      if (currentLocation && currentLocation?.primaryLocation === "Yes") {
        const temp = currentCompany?.locations?.find((item) => item?.primaryLocation === "Yes");
        setCurrentLocation(temp);
      }
    } else {
      setLocs([]);
      setTempLocs([]);
    }
  }, [currentCompany]);

  return (
    <LocationsPanel
      name={currentCompany?.name}
      onUpdate={handleUpdateCompany}
      locs={locs}
      tempLocs={tempLocs}
      setTempLocs={setTempLocs}
      primaryInfo={primaryInfo}
      setPrimaryInfo={setPrimaryInfo}
      currentLocation={currentLocation}
      setCurrentLocation={setCurrentLocation}
      currentData={currentCompany}
    />
  );
};

export default CustomerLocations;
