import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import updateInterviewFeedback from "layouts/pages/events/actions/updateInterviewFeedback";

import { Stack, Tooltip, Menu, MenuItem, IconButton } from "@mui/material";
import {
  Info as InfoIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Description as DescriptionIcon,
  Email as EmailIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";

import MDTypography from "components/MDTypography";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import { calculateInterviewOverallFeedback } from "utils/helpers/events";

const thumbUpOptions = [
  {
    label: "None",
    color: "action",
  },
  {
    label: "Hired",
    color: "success",
  },
  {
    label: "Pending",
    color: "warning",
  },
  {
    label: "Declined",
    color: "error",
  },
];

const SelectedApplicantActions = ({ id, parentComponent, row, onRemove, onChangeFeedback }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentLoggedUser, currentEvent } = useAppContextController();
  const [isMailModalOpen, setMailModalOpen] = useState(false);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isThumbsUpOpen = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateInterviewFeedbackMutation = useMutation(updateInterviewFeedback, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      enqueueSnackbar("Interview feedback has been updated!", { variant: "success" });
    },
  });

  const handleUpdateFeedback = async (data) => {
    await updateInterviewFeedbackMutation.mutateAsync({
      applicantId: row?.id || row?._id,
      eventUrl: currentEvent?.eventUrl,
      data,
    });
    if (onChangeFeedback) onChangeFeedback(data.feedback.rating);
  };

  const handleFeedback = (label, color) => {
    const newFeedback = {
      createAgent: currentLoggedUser?._id,
      agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      eventUrl: currentEvent?.eventUrl,
      modifiedDate: new Date().toISOString(),
      rating: label,
    };
    handleUpdateFeedback({ feedback: newFeedback });
    handleClose();
  };

  const handleSaveNote = (noteRec) => {
    const newNote = {
      type: "Interview Feedback",
      eventUrl: currentEvent?.eventUrl,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      createAgent: currentLoggedUser?._id,
      date: new Date().toISOString(),
    };

    return handleUpdateFeedback({ note: newNote });
  };

  const thumbIcon = useMemo(() => {
    const { overallRating, agentRating, tooltip } = calculateInterviewOverallFeedback(
      row?.feedback
    );
    const result = thumbUpOptions.find((option) => {
      return option.label === overallRating;
    });
    if (result) return { rating: overallRating, color: result?.color, tooltip, agentRating };
    return { rating: "None", color: "action", tooltip: "[ Overall feedback: None ]" };
  }, [row?.feedback]);

  const tableActions = [
    {
      label: `${parentComponent} Info`,
      icon: (
        <IconButton
          sx={{ p: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `/${parentComponent.toLowerCase()}s/${id}/action/${parentComponent.toLowerCase()}info`
            );
          }}
        >
          <InfoIcon fontSize="medium" color="info" />
        </IconButton>
      ),
    },
    {
      label: `${parentComponent} Feedback`,
      noTooltip: true,
      icon: (
        <>
          <Tooltip title={thumbIcon.tooltip} placement="top">
            <IconButton
              sx={{ p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              {thumbIcon.rating !== "Declined" ? (
                <ThumbUpIcon fontSize="medium" color={thumbIcon.color} />
              ) : (
                <ThumbDownIcon fontSize="medium" color={thumbIcon.color} />
              )}
            </IconButton>
          </Tooltip>
          <Menu anchorEl={anchorEl} open={isThumbsUpOpen} onClose={handleClose}>
            {thumbUpOptions.map((option) =>
              option.label === "Pending" ? null : (
                <MenuItem
                  key={option.label}
                  onClick={() => handleFeedback(option.label, option.color)}
                  disableRipple
                  disabled={option.label === thumbIcon.agentRating}
                >
                  {option.label !== "Declined" ? (
                    <ThumbUpIcon fontSize="medium" color={option.color} />
                  ) : (
                    <ThumbDownIcon fontSize="medium" color={option.color} />
                  )}
                  <MDTypography variant="h6" sx={{ pl: 2 }}>
                    {option.label}
                  </MDTypography>
                </MenuItem>
              )
            )}
          </Menu>
        </>
      ),
    },
    {
      label: "Send Message",
      icon: (
        <IconButton
          sx={{ p: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            setMailModalOpen(true);
          }}
        >
          <EmailIcon fontSize="medium" sx={{ color: "#9c27b0" }} />
        </IconButton>
      ),
    },
    {
      label: `${parentComponent} Notes`,
      icon: (
        <IconButton
          sx={{ p: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            setNotesModalOpen(true);
          }}
        >
          <DescriptionIcon fontSize="medium" color="info" />
        </IconButton>
      ),
    },
    {
      label: `Remove ${parentComponent}`,
      noTooltip: true,
      icon: (
        <IconButton
          sx={{ p: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <CancelIcon color="error" fontSize="medium" />
        </IconButton>
      ),
    },
  ];

  return (
    <Stack display="flex" direction="row" justifyContent="space-between" spacing={2}>
      {tableActions.map((action) => (
        <Stack key={action.label}>
          {action.noTooltip ? (
            action.icon
          ) : (
            <Tooltip title={action.label} placement="top">
              {action.icon}
            </Tooltip>
          )}
        </Stack>
      ))}
      <ApplicantActionModal
        open={isMailModalOpen}
        setOpen={setMailModalOpen}
        modalInfo={{ data: row, type: `${parentComponent.toLowerCase()}mail` }}
        setToastAlert={setToastAlert}
        currentApplicant={row}
      />
      <AddANoteModal
        currentApplicant={row}
        open={isNotesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNotesModalOpen}
        saveNote={handleSaveNote}
        showType={false}
      />
    </Stack>
  );
};

export default SelectedApplicantActions;
