import { useEffect, useState } from "react";
import { Check, Close, Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import enrollUserToEvent from "api/events/enrollUserToEvent";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import { MEMBER_PARTNER_STATUS_ENUM } from "utils/constants/applicant";
import MemberPartnerStatusIcon from "./MemberParnterStatusIcon";

const statusColor = {
  "Add as Member": "info",
  Active: "success",
  Inactive: "warning",
  "Not a Member": "error",
};

const MemberPartnerStatus = ({
  status = null,
  setToastAlert,
  queryClient,
  member,
  source,
  viewOnly = false,
  refetchPartnerApplicants,
}) => {
  const { currentLoggedUser, currentPartner } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [newMemberStatus, setNewMemberStatus] = useState(null)
  const [useNewStatus, setUseNewStatus] = useState(null)


  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { data }) => {
      queryClient.invalidateQueries(["partnersapplicants"]);
      queryClient.invalidateQueries(["members"]);
      enqueueSnackbar("Member has been updated!", { variant: "success" });
      setUseNewStatus(true)
    },
  });

  const handleUpdate = async (newStatus) => {

    let body;
    if (!member?._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    const memberPartners = member?.partners ? member.partners : [];
    const index = memberPartners?.findIndex(
      (partner) => partner.partnerSlug === currentPartner?.slug
    );
    if (newStatus === "Not a Member") {
      member?.partners?.splice(index, 1);
      body = {
        partners: [...member?.partners],
      };
    } else if (index === -1) {
      setNewMemberStatus(newStatus)
      body = {
        partners: memberPartners === [] ? [
          {
            status: newStatus,
            agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
            createAgent: currentLoggedUser._id,
            partnerSlug: currentPartner.slug,
            dateModified: new Date(),
          }]
          :
          [...memberPartners,
          {
            status: newStatus,
            agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
            createAgent: currentLoggedUser._id,
            partnerSlug: currentPartner.slug,
            dateModified: new Date(),
          },
          ],
      };
    } else {
      setNewMemberStatus(newStatus)
      memberPartners[index] = {
        ...memberPartners[index],
        status: newStatus,
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
        partnerSlug: currentPartner.slug,
        dateModified: new Date(),
      };
      body = {
        partners: memberPartners,
      };
    }

    await updateApplicantMutation.mutateAsync(
      {
        applicantId: member?._id,
        data: body,
      },
      {
        onSuccess: () => {
          refetchPartnerApplicants();
        },
      }
    );
  };

  const defaultIconProps = (iconStatus) => ({
    fontSize: "medium",
    color: statusColor[iconStatus],
  });

  const options =

    (source === "searchBar") ?
      [
        {
          text: MEMBER_PARTNER_STATUS_ENUM.ACTIVE,
          icon: <Check {...defaultIconProps(MEMBER_PARTNER_STATUS_ENUM.ACTIVE)} />,
          handleClick: () => handleUpdate(MEMBER_PARTNER_STATUS_ENUM.ACTIVE),
          disabled: MEMBER_PARTNER_STATUS_ENUM.ACTIVE === status,
        },
        {
          text: MEMBER_PARTNER_STATUS_ENUM.INACTIVE,
          icon: <Check {...defaultIconProps(MEMBER_PARTNER_STATUS_ENUM.INACTIVE)} />,
          handleClick: () => handleUpdate(MEMBER_PARTNER_STATUS_ENUM.INACTIVE),
          disabled: MEMBER_PARTNER_STATUS_ENUM.INACTIVE === status,
        },
      ]
      :
      [
        {
          text: MEMBER_PARTNER_STATUS_ENUM.ACTIVE,
          icon: <Check {...defaultIconProps(MEMBER_PARTNER_STATUS_ENUM.ACTIVE)} />,
          handleClick: () => handleUpdate(MEMBER_PARTNER_STATUS_ENUM.ACTIVE),
          disabled: MEMBER_PARTNER_STATUS_ENUM.ACTIVE === status,
        },
        {
          text: MEMBER_PARTNER_STATUS_ENUM.INACTIVE,
          icon: <Check {...defaultIconProps(MEMBER_PARTNER_STATUS_ENUM.INACTIVE)} />,
          handleClick: () => handleUpdate(MEMBER_PARTNER_STATUS_ENUM.INACTIVE),
          disabled: MEMBER_PARTNER_STATUS_ENUM.INACTIVE === status,
        },
        {
          text: MEMBER_PARTNER_STATUS_ENUM.NOT_A_MEMBER,
          icon: <Close {...defaultIconProps(MEMBER_PARTNER_STATUS_ENUM.NOT_A_MEMBER)} />,
          handleClick: () => handleUpdate(MEMBER_PARTNER_STATUS_ENUM.NOT_A_MEMBER),
          disabled: MEMBER_PARTNER_STATUS_ENUM.NOT_A_MEMBER === status,
        },
      ]



  const tooltip = () => {
    if (status !== null) {
      return `${member?.firstName} ${member?.lastName} is ${status === MEMBER_PARTNER_STATUS_ENUM.INACTIVE ? `not active.` : `${status}`
        }`
    }
    return `${member?.firstName} ${member?.lastName} is not a member of ${currentPartner?.name}`
  }

  return (
    <>
      <Tooltip
        title={tooltip()}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!viewOnly) {
              setAnchorEl(e.currentTarget);
            }
          }}
          sx={{ ...(viewOnly && { cursor: "default" }) }}
        >
          <MemberPartnerStatusIcon status={(newMemberStatus && useNewStatus) ? newMemberStatus : status} />
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
    </>
  );
};

export default MemberPartnerStatus;
