import { useState } from "react";
import { isEmpty } from "lodash";

import { useMediaQuery, IconButton, InputBase, Paper } from "@mui/material";
import { Close, Search } from "@mui/icons-material";

import MDBox from "components/MDBox";
import SearchbarModal from "components/SearchbarModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import SearchbarResults from "components/Searchbar/SearchbarResults";

const LocalSearch = ({ data, columns, onSearch, placeholder = "Search" }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState();
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);

  const isLTablet = useMediaQuery((theme) => theme.breakpoints.down("lTablet"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.up("laptop"));

  const handleSearch = () => {
    const colFields = columns?.map((col) => col.field);

    let filteredData = [];

    colFields?.forEach((field) => {
      const filteredDataIds = filteredData?.map((dt) => dt.id) || [];
      const filtered = data?.filter(
        (item) =>
          item?.[field]?.toLowerCase()?.includes(searchTerm.toLowerCase()) &&
          !filteredDataIds.includes(item.id)
      );
      filteredData = [...filteredData, ...filtered];
    });

    setSearchData(filteredData);
  };

  const resetSearch = () => {
    setSearchTerm("");
    setSearchData([]);
  };

  const handleClick = (row) => {
    resetSearch();
    onSearch(row);
  };

  return (
    <MDBox sx={{ width: "100%", position: "relative" }}>
      <Paper
        sx={{
          p: "2px 4px !important",
          display: "flex",
          alignItems: "center",
          width: "100%",
          background: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, p: "2px" }}
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={() => {
            if (isLTablet) setSearchModalOpen(true);
          }}
        />
        {searchTerm.length !== 0 && (
          <IconButton onClick={resetSearch}>
            <Close />
          </IconButton>
        )}

        <IconButton onClick={handleSearch}>
          <Search />
        </IconButton>
      </Paper>
      {isLTablet ? (
        <SearchbarModal
          isOpen={isSearchModalOpen}
          onClose={() => setSearchModalOpen(false)}
          results={
            <FlexWrapper>
              <Paper
                sx={{
                  p: "2px 4px !important",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.04)",
                  mb: "10px",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, p: "2px" }}
                  placeholder={placeholder}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm.length !== 0 && (
                  <IconButton onClick={resetSearch}>
                    <Close />
                  </IconButton>
                )}

                <IconButton onClick={handleSearch}>
                  <Search />
                </IconButton>
              </Paper>
              <FlexWrapper sx={{ overflow: "auto", maxHeight: "500px" }}>
                <SearchbarResults rows={searchData} columns={columns} onRowClick={handleClick} />
              </FlexWrapper>
            </FlexWrapper>
          }
        />
      ) : (
        !isEmpty(searchData) && (
          <>
            <MDBox
              sx={{
                width: "max-content",
                position: "absolute",
                zIndex: "999",
                background: "#fff",
                top: "4rem",
                boxShadow: 3,
                maxHeight: "800px",
                overflowY: "auto",
                ...(!isLaptop && {
                  right: "50%",
                  left: "50%",
                  transform: "translate(-55%, -0%)",
                }),
              }}
            >
              <SearchbarResults rows={searchData} columns={columns} onRowClick={handleClick} />
            </MDBox>
          </>
        )
      )}
    </MDBox>
  );
};

export default LocalSearch;
