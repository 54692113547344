import * as yup from "yup";

export const taskSchema = yup.object().shape({
  taskName: yup.string().required("Task name is required"),
  assignedByName: yup.object().required("Assigned by is required"),
  status: yup.string().required("Status is required"),
  taskType: yup.string().required("Task type is required"),
  taskDescription: yup.string().test({
    name: "validate-task-description",
    test: (value, ctx) => {
      const isEmpty =
        !value ||
        value?.trim() === "" ||
        value?.trim() === "<p><br></p>" ||
        value?.trim() === "<div><br></div>" ||
        !value?.replace(/<(.|\n)*?>/g, "")?.trim();
      if (isEmpty) return ctx.createError({ message: "Task description is required" });
      return true;
    },
  }),
  createdDate: yup.date().required("Created date is required"),
  dueDate: yup.date().nullable(),
});
