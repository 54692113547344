import { isEmpty } from "lodash";
import { removeHtmlTags } from "utils/string";

const getUpdatedNotesList = (content, index, allData) => {
  if (content !== "" || !isEmpty(content)) {
    let updatedValues;
    if (index !== null) {
      updatedValues = allData.map((item, idx) => {
        if (index === idx)
          return { ...item, text: content, modifiedDate: new Date().toISOString() };
        return item;
      });
      return updatedValues;
    }
    return [
      ...allData,
      {
        createdDate: new Date().toISOString(),
        text: content,
        modifiedDate: new Date().toISOString(),
      },
    ];
  }
  return {};
};

const getDisplayText = (text, limit = 20) => {
  const content = removeHtmlTags(text);
  if (content.length > limit) {
    return `${content.substring(0, limit)}...`;
  }
  return content;
};

const transformToComparable = (item, sortBy) => {
  if (sortBy.toLowerCase().includes("date")) {
    return new Date(item);
  }
  return item;
};

const sortArrayOfObj = ({ arr, sortBy = "createdDate", sortOrder = "desc" }) => {
  let newArr = [...arr];

  if (sortOrder === "desc") {
    newArr = newArr.sort(
      (a, b) =>
        transformToComparable(b?.[sortBy], sortBy) - transformToComparable(a?.[sortBy], sortBy)
    );
  }

  if (sortOrder === "asc") {
    newArr = newArr.sort(
      (a, b) =>
        transformToComparable(a?.[sortBy], sortBy) - transformToComparable(b?.[sortBy], sortBy)
    );
  }

  return newArr;
};

export { getUpdatedNotesList, getDisplayText, sortArrayOfObj };
