import { createContext, useContext } from "react";

import useEvent from "hooks/useEvent";
import { eventInitialState } from "reducers/eventReducer";

export const EventContext = createContext(eventInitialState);

export const EventContextProvider = ({ children }) => {
  const eventProviderState = useEvent();

  return <EventContext.Provider value={eventProviderState}>{children}</EventContext.Provider>;
};

export function useEventContext() {
  return useContext(EventContext);
}
