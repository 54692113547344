import { Box, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useParams } from "react-router-dom";

import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import EventForm from "./EventForm";

const StyledImageBox = styled(Box)(({ theme }) => ({
    width: 50,
    height: 50,
    objectFit: "contain",
}));

const iconCreateContainerStyle = {
    backgroundColor: "success",
    color: "white",
};

const iconEditContainerStyle = {
    backgroundColor: "transparent",
    color: "transparent",
};

const cardStlyle = {
    width: "100%",
    overflow: "visible !important",
    mt: "20px",
};

const NewEventInfoPanel = ({ setEventPreview, setOpen, setPreviewContent, selectedPanel, onPanelSelect }) => {
    const { currentEvent: event, venues, isMobile } = useAppContextController();
    const { createVenueSlug } = useParams();
    const [eventLogo, setEventLogo] = useState("");
    const [copyEventName, setCopyEventName] = useState("");
    const isEdit = !!event?._id;

    const getVenueField = (slug, field) => {
        if (venues && venues[slug]) return venues[slug][field];
        return null;
    };

    const createEventSuffix = () => {
        let suffix = "";
        if (copyEventName) suffix = ` from ${copyEventName}`;
        if (createVenueSlug)
            suffix = ` at ${getVenueField(createVenueSlug, "name") ? getVenueField(createVenueSlug, "name") : ""
                }`;
        return suffix;
    };

    return (
        <CustomCard
            cardTitle={
                isEdit ? `Event Information: ${event?.eventName}` : `Create Event ${createEventSuffix()}`
            }
            cardSx={cardStlyle}
            isStacked
        >

            <Box mt={isMobile ? 0 : -5}>
                <EventForm
                    getVenueField={getVenueField}
                    setEventPreview={setEventPreview}
                    setPreviewContent={setPreviewContent}
                    setOpen={setOpen}
                    setEventLogo={setEventLogo}
                    setCopyEventName={setCopyEventName}
                    selectedPanel={selectedPanel}
                    onPanelSelect={onPanelSelect}
                />
            </Box>
        </CustomCard>
    );
};

export default NewEventInfoPanel;
