// import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import useSessionAuth from "hooks/useSessionAuth";
import { useAppContextController } from "../../../context/AppContext";

const Logout = async () => {
  const { logout: auth0Logout } = useAuth0();
  const { setCurrentLoggedUser } = useAppContextController();
  const { logoutSession } = useSessionAuth();

  const basePath = new URL(window.location);

  setCurrentLoggedUser(null);
  await logoutSession();
  auth0Logout({ returnTo: basePath.origin });
  return null;
};

export default Logout;
