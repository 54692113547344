import { useState } from "react";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AttachFile, // Movie
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Box, Grid } from "@mui/material";
import CustomCard from "components/CustomCard";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import FileDropzone from "components/Dropzone";
import uploadImage from "api/upload/uploadImage";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import Attachments from "../PartnersInformation/Attachments";
import { cardStyles as styles } from "../../styles";

const PartnersAttachment = () => {
  const {
    currentPartner,
    setCurrentPartner,
    currentLoggedUser,
    company,
    setCompany,
    setCompanyType,
    userType,
  } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: uploadMutation } = useMutation(
    async ({ type, data }) => {
      const form = new FormData();
      form.append("file", data.file);

      if (type === "attachment") {
        await uploadImage({
          uploadPath: `partners/${currentPartner?.slug}/attachment`,
          form,
        });
        await updatePartner({
          id: currentPartner._id,
          data: {
            ...(currentPartner?.attachments
              ? { attachments: [...currentPartner?.attachments, data.attachment] }
              : { attachments: [data.attachment] }),
          },
        });
      } else {
        await uploadImage({
          uploadPath: `partners/${currentPartner?.slug}/logo`,
          form,
        });
        await updatePartner({
          id: currentPartner._id,
          data: {
            ...(currentPartner?.partnerLogos
              ? {
                  partnerLogos: [...currentPartner?.partnerLogos, data.partnerImage],
                  partnerLogo: data.partnerImage,
                }
              : {
                  partnerLogos: [data.partnerImage],
                  partnerLogo: data.partnerImage,
                }),
          },
        });
      }
    },
    {
      onError: (error) => {
        enqueueSnackbar(`Error: ${error}`, { variant: "error" });
      },
      onSuccess: (_, data) => {
        if (data.type === "attachment") {
          setCurrentPartner({
            ...currentPartner,
            ...(currentPartner?.attachments
              ? { attachments: [...currentPartner?.attachments, data.data.attachment] }
              : { attachments: [data.data.attachment] }),
          });
        } else {
          setCurrentPartner({
            ...currentPartner,
            ...(currentPartner?.partnerLogos
              ? {
                  partnerLogos: [...currentPartner?.partnerLogos, data.data.partnerImage],
                  partnerLogo: data.data.partnerImage,
                }
              : {
                  partnerLogos: [data.data.partnerImage],
                  partnerLogo: data.data.partnerImage,
                }),
          });
        }
        enqueueSnackbar(`Image been updated!`, { variant: "success" });
      },
    }
  );

  const onDropAttachmentHandler = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles.length <= 0) return;
    const file = acceptedFiles[0];
    const fileExt = file?.path?.split(".").pop();
    const filePath = file.path;

    uploadMutation({
      type: "attachment",
      data: {
        attachment: {
          docType: fileExt,
          filename: filePath,
          uploadDate: new Date(),
        },
        file,
      },
    });
  };

  const onDropLogoHandler = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length > 0) {
      enqueueSnackbar(rejectedFiles[0]?.errors[0]?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles.length <= 0) return;
    const file = acceptedFiles[0];
    uploadMutation({ type: "logo", data: { partnerImage: file?.name, file } });
  };

  return (
    <CustomCard
      icon={<AttachFile color="white" />}
      iconContainerSx={{ backgroundColor: "#F44335", ...styles.icon }}
      cardTitle={`Partner Attachments: ${currentPartner?.name}`}
      cardSx={styles.card}
      titleSx={styles.title}
    >
      <MDBox display="flex" justifyContent="space-between" sx={styles.titleBar}>
        <MDTypography variant="h6" color="dark" sx={styles.titleBar_title}>
          Partner Logo
        </MDTypography>
      </MDBox>
      <Box sx={styles.container}>
        <Box mb={0}>
          <Grid item xs={12}>
            <FileDropzone message="Drop files here to upload" onDrop={onDropLogoHandler} />
          </Grid>
          <MDBox mt={2}>
            <Attachments fileLocation="logos" />
          </MDBox>
        </Box>
      </Box>
      <MDBox display="flex" justifyContent="space-between" sx={styles.titleBar}>
        <MDTypography variant="h6" color="dark" sx={styles.titleBar_title}>
          Partner Attachments
        </MDTypography>
      </MDBox>
      <Box sx={styles.container}>
        <Box mb={0}>
          <Grid item xs={12}>
            <FileDropzone message="Drop files here to upload" onDrop={onDropAttachmentHandler} />
          </Grid>
          <MDBox mt={2}>
            <Attachments fileLocation="attachments" />
          </MDBox>
        </Box>
      </Box>
    </CustomCard>
  );
};
export default PartnersAttachment;
