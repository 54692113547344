import { QueryClient } from "react-query";
import useSessionAuth from "./useSessionAuth";

export const useCreateQueryClient = () => {
  const { logoutAuth0User } = useSessionAuth();

  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: (error) => {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        },
      },
      mutations: {
        onError: (error) => {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        },
      },
    },
  });
};
