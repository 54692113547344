import moment from "moment";
import queryString from "query-string";

import { removeHtmlTags } from "utils/string";

export const addToGoogleCalendar = ({
  startDate,
  endDate,
  details = "",
  location = "",
  title = "",
}) => {
  const googleLink = "https://calendar.google.com";
  const parsedStartDate = moment(startDate).utc();
  const parsedEndDate = moment(endDate).utc();
  const start = `${parsedStartDate.format("YYYYMMDDTHHmmss")}Z`;
  const end = `${parsedEndDate.format("YYYYMMDDTHHmmss")}Z`;

  const url = queryString.stringifyUrl({
    url: `${googleLink}/calendar/render`,
    query: {
      location,
      text: title,
      details,
      dates: `${start}/${end}`,
      action: "TEMPLATE",
    },
  });

  window.open(url, "_blank");
};

export const addToOutlookCalendar = ({
  startDate,
  endDate,
  title = "",
  location = "",
  details = "",
}) => {
  const outlookLink = "https://outlook.live.com";
  const url = queryString.stringifyUrl({
    url: `${outlookLink}/calendar/0/deeplink/compose`,
    query: {
      body: details,
      location,
      subject: title,
      enddt: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      startdt: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      path: "/calendar/action/compose",
      rru: "addevent",
    },
  });

  window.open(url, "_blank");
};

// Seems like the same as above method.
export const addToOffice365Calendar = ({
  startDate,
  endDate,
  title = "",
  location = "",
  details = "",
}) => {
  const officeLink = "https://outlook.office.com";
  const parsedStartDate = encodeURIComponent(moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"));
  const parsedEndDate = encodeURIComponent(moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"));

  const url = `${officeLink}/calendar/0/deeplink/compose?body=${encodeURIComponent(
    details
  )}&enddt=${parsedEndDate}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${parsedStartDate}&subject=${encodeURIComponent(
    title
  )}`;
  window.open(url, "_blank");
};

export const addToYahooCalendar = ({
  startDate,
  endDate,
  title = "",
  location = "",
  details = "",
}) => {
  const yahooLink = "https://calendar.yahoo.com";
  const url = queryString.stringifyUrl({
    url: yahooLink,
    query: {
      et: `${moment(endDate).format("YYYYMMDDTHHmmss")}Z`,
      st: `${moment(startDate).format("YYYYMMDDTHHmmss")}Z`,
      desc: removeHtmlTags(details),
      in_loc: location,
      title,
      v: 60,
    },
  });

  window.open(url, "_blank");
};

function saveAs(uri, filename) {
  const link = document.createElement("a");
  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;

    // Firefox requires the link to be in the body
    document.body.appendChild(link);

    // simulate click
    link.click();

    // remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}

// Generate ICS
export const addToAppleCalendar = ({
  startDate,
  endDate,
  title = "",
  location = "",
  details = "",
}) => {
  const parsedStartDate = moment(startDate).utc();
  const parsedEndDate = moment(endDate).utc();
  const start = `${parsedStartDate.format("YYYYMMDDTHHmmss")}Z`;
  const end = `${parsedEndDate.format("YYYYMMDDTHHmmss")}Z`;
  const url = `data:text/calendar;charset=utf-8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART:${start}%0ADTEND:${end}%0ASUMMARY:${encodeURIComponent(
    title
  )}%0ALOCATION:${encodeURIComponent(location)}%0ADESCRIPTION:${encodeURIComponent(
    details
  )}%0AEND:VEVENT%0AEND:VCALENDAR`;
  saveAs(url, "stadium-people-event.ics");
};
