const textColor = "#7c97a9";

const styles = {
  modalContainer: (theme) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    py: 2,
    px: 2,
    pt: 7,
    background: "#ffffff",
    border: "2px solid #000",
    maxWidth: "1281px",
  }),
  longBgImageContainer: {
    width: "100%",
    height: "300px",
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
  },
  mainContentContainer: {
    borderRadius: 5,
    mt: 5,
  },
  leftContent: {
    padding: "30px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    borderBottom: `1px solid ${textColor}`,
    height: "100%",
    pb: 1,
  },
  rightContent: {
    padding: "20px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    height: "100%",
  },
  middleContent: {
    padding: "40px 20px 20px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    height: "100%",
  },
  bottomContent: {
    padding: "20px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    height: "100%",
  },
  longBgImage: { width: "100%", height: "100%", objectFit: "cover", objectPosition: "top" },
  descriptionContainer: { overflowY: "auto", maxHeight: "20vh", mt: 4 },
};

export default styles;
