import { Grid, Icon, Stack } from "@mui/material";

import { useAppContextController } from "context/AppContext";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const AdditionalContactsItem = ({ row, idx, onEdit, onRemove }) => {
  const { firstName, lastName, title, departmentLocation, email, phone } = row;
  const { userType } = useAppContextController();

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          onEdit(idx, row, true);
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={1}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>{firstName}</MDTypography>
          </Grid>
          <Grid item sm={1} display="flex" justifyContent="center" >
            <MDTypography sx={{ fontSize: "0.8rem" }}>{lastName}</MDTypography>
          </Grid>
          <Grid item sm={2} display="flex" justifyContent="center" >
            <MDTypography sx={{ fontSize: "0.8rem" }}>{title}</MDTypography>
          </Grid>
          <Grid item sm={3}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>{departmentLocation}</MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>{email}</MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDTypography sx={{ fontSize: "0.8rem" }}>{phone}</MDTypography>
          </Grid>

          {userType === "Master" && (
            <Grid item sm={1}>
              <MDBox textAlign="right">
                <Icon
                  color="error"
                  fontSize="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(idx);
                  }}
                >
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default AdditionalContactsItem;
