import { APPLICANT_EVENT_STATUS_ENUM } from "utils/constants/applicant";

// Only for Employee Users
export const filterApplicantEvents = (applicant, events) => {
  return events?.filter((event) => {
    if (event?.makePublicAndSendNotification === "No") {
      const eventApplicant = event?.applicants?.find((item) => item?.id === applicant?._id);
      // Will only show Roster and Waitlist events if public.
      if (
        [APPLICANT_EVENT_STATUS_ENUM.ROSTER, APPLICANT_EVENT_STATUS_ENUM.WAITLIST].includes(
          eventApplicant?.status
        )
      ) {
        return true;
      }
      return false;
    }
    return true;
  });
};

export const formatInterviewFeedbacks = (results, applicants, agentId) => {
  let feedbacks = [];
  results.forEach((obj) => {
    const noteArr = obj?.notes?.map((note) => {
      const applicantId = obj.id;
      const appl = applicants.find((applicant) => {
        const applId = applicant._id || applicant.id;
        return applId === applicantId;
      });
      const rating =
        note.type === "Interview Feedback Rating"
          ? note.text.split(":")[1].split("<")[0].replace(/ /g, "")
          : undefined;
      return {
        ...note,
        firstName: appl.firstName,
        lastName: appl.lastName,
        agentName: `${note.firstName} ${note.lastName}`,
        rating,
      };
    });
    feedbacks = [...feedbacks, ...noteArr];
  });
  return feedbacks;
};

export const getReportDateTime = (applicants, date, applId, userType, type = "date") => {
  const userEventDetails = applicants?.find((obj) => obj.id === applId);
  let eventDate;

  if (userType === "User" && userEventDetails && userEventDetails?.reportTime) {
    eventDate = userEventDetails?.reportTime;
  } else {
    eventDate = date;
  }

  if (type === "time") {
    const reportTime = new Date(eventDate).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return reportTime;
  }

  return eventDate;
};

export const getPositionEndTime = (applicants, date, applId, userType, type = "date") => {
  const userEventDetails = applicants?.find((obj) => obj.id === applId);

  let eventEndDate;

  if (userType === "User" && userEventDetails && userEventDetails?.endTime) {
    eventEndDate = userEventDetails?.endTime;
  } else {
    eventEndDate = date;
  }

  if (type === "time") {
    const endTime = new Date(eventEndDate).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return endTime;
  }

  return eventEndDate;
};

export const getCombinedDate = (date, time) => {
  const newDate = new Date(date);
  const newTime = new Date(time);

  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const day = newDate.getDate();

  const hours = newTime.getHours();
  const minutes = newTime.getMinutes();
  const seconds = newTime.getSeconds();

  const combinedDateTime = new Date(year, month, day, hours, minutes, seconds);
  return combinedDateTime;
};

export const getPositionName = (applicants, date, applId, userType, type = "date") => {
  const userEventDetails = applicants?.find((obj) => obj.id === applId);

  let positionName;

  if (userType === "User" && userEventDetails && userEventDetails?.primaryPosition) {
    positionName = userEventDetails?.primaryPosition;
  } else {
    positionName = "Event Staff";
  }

  return positionName;
};

export const calculateInterviewOverallFeedback = (feedback, agentId) => {
  const allFeedback = feedback ?? [];
  let overallRating = "None";
  let agentRating = "None";
  let tooltip = "";
  for (let index = 0; index < allFeedback.length; index += 1) {
    const feedb = allFeedback[index];
    const { rating, agent, currentAgent } = feedb;

    if (agentId === currentAgent) agentRating = rating;

    // Pending feedback is ignored
    if (rating !== "Pending") {
      if (overallRating === "None") overallRating = rating;
      else if (overallRating !== rating) overallRating = "Pending";
    }
    tooltip = `${tooltip}${agent}: ${rating}\n`;
  }
  const overallRatingValue = overallRating === "Pending" ? "Mixed" : overallRating;

  tooltip = `[ Overall feedback: ${overallRatingValue} ]\n${tooltip}`;
  return { overallRating, agentRating, tooltip };
};
