import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import useDropdowns from "hooks/useDropdowns";

// import licenseTypes from "assets/licenseTypes.json";
import { APPLICANT_EMPLOYEE_ENTITIES } from "utils/constants/entityOptions";

import { Button, Grid, Autocomplete, Switch, FormHelperText, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import FormField from "layouts/applications/wizard/components/FormField";

import MDBox from "components/MDBox";
import GenericModal from "components/GenericModal";

const { LICENSE_STATUS_ENTITY, LICENSE_TYPES_ENTITY } = APPLICANT_EMPLOYEE_ENTITIES;

const ApplicantLicenseModal = ({
  errors,
  currentLicense,
  isModalOpen,
  setModalOpen,
  onChange,
  onClose,
  onSubmit,
}) => {
  const { dropdowns: licenseStatuses } = useDropdowns({ entity: LICENSE_STATUS_ENTITY });
  const { dropdowns: licenseTypes } = useDropdowns({ entity: LICENSE_TYPES_ENTITY });

  const modalBody = (
    <>
      <IconButton onClick={onClose} sx={{ position: "absolute", top: "18px", right: "21px" }}>
        <CancelIcon />
      </IconButton>
      <MDBox mb={3}>
        <Autocomplete
          options={licenseTypes || []}
          value={currentLicense?.licenseType?.label}
          onChange={(e, value) => onChange("licenseType", value)}
          renderInput={(params) => (
            <>
              <FormField {...params} type="text" label="License Type" />
              <FormHelperText error>{errors?.licenseType}</FormHelperText>
            </>
          )}
        />
      </MDBox>

      <MDBox mb={3}>
        <Autocomplete
          options={licenseStatuses || []}
          value={currentLicense?.licenseStatus}
          onChange={(e, value) => onChange("licenseStatus", value)}
          renderInput={(params) => (
            <>
              <FormField {...params} type="text" label="Status" />
              <FormHelperText error>{errors?.licenseStatus}</FormHelperText>
            </>
          )}
        />
      </MDBox>

      <MDBox mb={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Expiration"
            value={currentLicense.licenseExpDate}
            maxDate={currentLicense?.licenseStatus === "Expired" ? new Date() : undefined}
            minDate={
              currentLicense?.licenseStatus !== "Expired"
                ? new Date().setDate(new Date().getDate() + 1)
                : undefined
            }
            onChange={(date) => onChange("licenseExpDate", new Date(date))}
            renderInput={(params) => (
              <>
                <FormField {...params} />
                <FormHelperText error>{errors?.licenseExpDate}</FormHelperText>
              </>
            )}
          />
        </LocalizationProvider>
      </MDBox>

      <Grid
        container
        sx={{ fontSize: "0.75rem", lineHeight: "1.5" }}
        justify="flex-end"
        alignItems="center"
      >
        {/* eslint-disable-next-line */}
        <label>30-Day Alert</label>
        <MDBox>
          <Switch
            checked={currentLicense.thirtyDayAlert === "Yes"}
            onChange={(e, value) => onChange("thirtyDayAlert", value ? "Yes" : "No")}
            inputProps={{ "aria-label": "" }}
          />
          <FormHelperText error>{errors?.thirtyDayAlert}</FormHelperText>
        </MDBox>
      </Grid>
    </>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
        onClick={onSubmit}
      >
        Save
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={isModalOpen}
        setOpen={setModalOpen}
        header="Add License"
        body={modalBody}
        buttons={modalButtons}
        width="428px"
        borderRadius="4px"
      />
    </>
  );
};

export default ApplicantLicenseModal;
