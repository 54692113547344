const imageUrlFixer = (data, currentData, IMAGE_SERVER) => {
  const newData = {
    ...data,
    logoUrl:
      data.logoUrl && IMAGE_SERVER
        ? `${IMAGE_SERVER}/${currentData.slug}/venues/logo/${data?.logoUrl}`
        : currentData?.logoUrl,
    logoUrls:
      data.logoUrls && IMAGE_SERVER
        ? data.logoUrls.map((logo) => `${IMAGE_SERVER}/${currentData.slug}/venues/logo/${logo}`)
        : currentData.logoUrls,
    bannerUrl:
      data.bannerUrl && IMAGE_SERVER
        ? `${IMAGE_SERVER}/${currentData?.slug}/venues/banner/${data.bannerUrl}`
        : currentData?.bannerUrl,
    imageUrls:
      data.imageUrls && IMAGE_SERVER
        ? data.imageUrls.map(
            (banner) => `${IMAGE_SERVER}/${currentData.slug}/venues/banner/${banner}`
          )
        : currentData.imageUrls,
  };

  return newData;
};

export { imageUrlFixer };
