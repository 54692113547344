import { SnackbarProvider } from "notistack";

import FlexWrapper from "components/Wrapper/FlexWrapper";
import { NotificationContextProvider } from "context/NotificationContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NotificationSection from "./components/NotificationSection";

function Notifications() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FlexWrapper rowGap="2rem">
        <NotificationContextProvider>
          <SnackbarProvider
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <NotificationSection />
          </SnackbarProvider>
        </NotificationContextProvider>
      </FlexWrapper>
    </DashboardLayout>
  );
}

export default Notifications;
