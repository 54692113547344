import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Autocomplete, Box, Button, Card, Grid, Snackbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "assets/theme/base/colors";
import DataList from "components/DataList";
import CustomTextField from "components/Form/CustomTextField";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import useDropdowns from "hooks/useDropdowns";
import parser from "html-react-parser";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import { useAppContextController } from "context/AppContext";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import PanelSelectorBox from "../PanelSelectorBox";
import ProfileNoteRow from "./ProfileNoteRow";

const emptyValues = {
  type: "",
  date: "",
  text: "",
  firstName: "",
  lastName: "",
};
const useStyle = makeStyles({
  addButton: {
    fontSize: "25px",
    marginLeft: "15px",
  },
  editor: {
    height: 100,
  },
});
const ProfileNotes = ({
  saveNote,
  updateNotes,
  toastAlert,
  setToastAlert,
  setNoteIndex,
  noteIndex,
  currentApplicant,
}) => {
  const {
    setValue,
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({ mode: "onBlur" });
  const classes = useStyle();
  const [notes, setNotes] = useState([]);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [createValue, setCreateValue] = useState("");
  const [editorOpen, setEditorOpen] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [dltIndex, setDltIndex] = useState(null);
  const [noteDetailOpen, setNoteDetailOpen] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [userNoteType, setUserNoteType] = useState("");
  const { dropdowns: userNoteTypes } = useDropdowns({ entity: "userNoteTypes" });
  const [noteRow, setNoteRow] = useState(emptyValues);

  const handleNoteSelect = (visibility, index, row) => {
    setNoteDetailOpen(visibility);
    setEditorOpen(false);
    setNoteIndex(index);
    setUserNoteType(row?.type);
    setNoteRow(row);
  };

  useEffect(() => {
    if (currentApplicant?.notes?.length) {
      setNotes(
        currentApplicant?.notes
          ?.sort((a, b) => (a?.date < b?.date ? 1 : -1))
          ?.map((note) => {
            const { type, date, text, firstName, lastName } = note;
            return { type, date, text, firstName, lastName };
          })
      );
    } else {
      setNotes([]);
    }
  }, [currentApplicant]);

  const handleRemoveNote = () => {
    const temporaryNotes = notes;
    const updatedNotes = temporaryNotes?.filter((_, index) => index !== dltIndex);
    if (noteIndex !== null) setNoteIndex(null);
    setNotes(updatedNotes);
    updateNotes(updatedNotes);
    setIsSaveButtonDisabled(false);
  };

  const handleRemoveModal = (index) => {
    setDltIndex(index);
    setRemoveModal(true);
  };

  const renderRow = (row, idx) => (
    <ProfileNoteRow idx={idx} row={row} onEdit={handleNoteSelect} onRemove={handleRemoveModal} />
  );

  const modalTitle = (
    <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
      Notes for {currentApplicant?.firstName} {currentApplicant?.lastName}
    </MDTypography>
  );
  const renderText = notes?.map((note) => {
    return (
      <>
        <Grid container key={note?.id}>
          <Grid item sm={3}>
            <MDTypography fontWeight="bold">
              {note?.firstName} {note?.lastName}
            </MDTypography>
          </Grid>
          <Grid item sm={4}>
            {moment(note?.date)?.format("ddd MM/DD/yyyy hh:mm A")}
          </Grid>
          <Grid item sm={4}>
            {note?.type}
          </Grid>
        </Grid>
        <p>{parser(note?.text || "")}</p>
        <hr />
      </>
    );
  });

  const modalBody = (
    <Box mb={3} style={{ height: "21rem", overflow: "auto" }}>
      {renderText}
    </Box>
  );

  const okButton = (
    <Button variant="contained" style={{ color: "white" }} onClick={() => setNotesModalOpen(false)}>
      Ok
    </Button>
  );

  const addNew = () => {
    setNoteIndex(null);
    setEditorOpen(true);
    setNoteDetailOpen(false);
    setCreateValue("");
    setUserNoteType("");
  };
  const viewAll = () => {
    setNotesModalOpen(true);
  };

  const handleInputNoteTypeChange = (e) => {
    setUserNoteType(e);
    setIsSaveButtonDisabled(true);
  };

  useEffect(() => {
    if (noteRow) {
      setValue("text", noteRow?.text);
    }
  }, [noteRow]);

  const noteText = watch("text");

  useEffect(() => {
    if (editorValue) {
      console.log("noteText", noteText);
      console.log("editorValue", editorValue);
      setIsSaveButtonDisabled(JSON.stringify(editorValue) !== JSON.stringify(noteText));
    }
  }, [noteText]);

  const cancelChanges = () => {
    setEditorOpen(false);
    setEditorValue("");
    setIsSaveButtonDisabled(false);
    setNoteDetailOpen(false);
  };

  const addOrEditModal = useCallback(() => {
    const initialValue = notes[noteIndex]?.type || "";
    return (
      <>
        <Grid sx={{ width: "100%", borderTop: "1px solid #7B809A", paddingTop: "20px" }} container>
          <Grid item xs={12} sm={4}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  options={userNoteTypes || []}
                  autoSelect
                  name="type"
                  value={field.value || initialValue}
                  onChange={(e, v) => {
                    handleInputNoteTypeChange(v);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      type="text"
                      label="Note Topic"
                      showError
                      errorMessage={error?.message}
                      {...params}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  classes={{ input: classes.status }}
                />
              )}
            />
          </Grid>
        </Grid>
        <MDBox
          sx={{
            maxHeight: "190px",
            height: "190px",
            marginTop: "30px",
          }}
        >
          <Controller
            name="text"
            control={control}
            render={({ field: { value, onChange, ...props } }) => (
              <MDEditor
                flex="1 0 auto"
                onChange={(val) => {
                  onChange(val);
                  setEditorValue(val);
                }}
                className={classes.editor}
                value={value}
                {...props}
              />
            )}
          />
        </MDBox>
      </>
    );
  }, [noteIndex, notes]);

  const Header = () => {
    return (
      <>
        {isSaveButtonDisabled ? (
          <>
            <Button startIcon={<CancelIcon />} onClick={cancelChanges}>
              Cancel
            </Button>
            <Button
              sx={{ padding: "0", marginRight: "20px" }}
              onClick={async () => {
                const noteRec = {
                  text: editorValue,
                  type: userNoteType,
                };
                await saveNote(noteRec);
                setEditorOpen(false);
                setEditorValue("");
                setIsSaveButtonDisabled(false);
              }}
            >
              <SaveIcon
                sx={{
                  color: colors?.success?.main,
                  marginRight: "3px",
                  width: "15px",
                  height: "15px",
                }}
              />
              <Typography sx={{ fontSize: "15px", color: colors?.success?.main }}> Save</Typography>
            </Button>
          </>
        ) : null}
      </>
    );
  };

  return (
    <form onSubmit={() => { }}>
      <PanelSelectorBox
        title="User Notes"
        icon="description"
        iconColor="warning"
        cardVariant="outlined"
        header={<Header />}
      >
        <Grid container style={{ width: "100%", overflow: "hidden" }}>
          <Grid
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 20px",
            }}
          >
            <Grid>
              <Button
                variant="text"
                color="primary"
                onClick={viewAll}
                sx={{ padding: "0", marginRight: "5px" }}
              >
                View All
              </Button>
              |
              <Button
                variant="text"
                color="primary"
                onClick={addNew}
                sx={{ padding: "0", margin: "0", marginLeft: "5px" }}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ width: "100%", height: "600px", overflow: "auto" }}>
            <Card cardVariant="outlined" sx={{ margin: "20px " }}>
              <DataList
                renderRow={renderRow}
                data={notes || []}
                isLoading={false}
                greybar
                divider
                tableHeight={250}
                height={240}
                scrollY
                cardVariant="none"
                computedHeight
                boxShadow
                selected={noteIndex}
                boxPadding={0}
                padding={0}
                headerStyle={() => ({ display: "none" })}
              />
            </Card>
            <GenericModal
              open={notesModalOpen}
              setOpen={setNotesModalOpen}
              body={modalBody}
              header={modalTitle}
              buttons={okButton}
            />
            <Snackbar
              open={toastAlert.isShow}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
              key="top-center"
            >
              <Alert
                onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
                variant="filled"
                sx={{ width: "100%" }}
                style={{
                  backgroundColor: toastAlert.status === "success" ? "#4caf4f" : "#f44336",
                }}
              >
                {toastAlert?.message}
              </Alert>
            </Snackbar>

            <MDBox sx={{ padding: "10px 25px" }}>
              {noteDetailOpen && noteIndex !== null ? addOrEditModal() : null}
            </MDBox>

            {editorOpen && (
              <MDBox>
                <Grid sx={{ boprder: "1px solid red", width: "100%", padding: "0 20px" }} container>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={userNoteTypes}
                      name="noteTopic"
                      autoSelect
                      freeSolo
                      value={userNoteType}
                      sx={{ maxHeight: "44px", width: "100%" }}
                      onChange={(e, v) => {
                        setUserNoteType(v);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          type="text"
                          label="Note Topic"
                          showError
                          {...params}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <MDBox p={3} mb={3}>
                  <MDEditor
                    className={classes.editor}
                    value={createValue}
                    onChange={(e) => setCreateValue(e)}
                  />
                </MDBox>
                <MDBox p={3} mt={7}>
                  <Grid container spacing={3}>
                    <Grid item sm={6} />
                    <Grid item sm={3}>
                      <Button
                        color="error"
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setEditorOpen(false);
                          setCreateValue("");
                        }}
                      >
                        <MDTypography variant="body2" color="white">
                          Cancel
                        </MDTypography>
                      </Button>
                    </Grid>
                    <Grid item sm={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={createValue.replace(/(<([^>]+)>)/gi, "") === ""}
                        onClick={async () => {
                          const noteRec = {
                            text: createValue,
                            type: userNoteType,
                          };
                          await saveNote(noteRec);
                          setEditorOpen(false);
                          setCreateValue("");
                        }}
                      >
                        <MDTypography variant="body2" color="white">
                          Save
                        </MDTypography>
                      </Button>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
            <ConfirmModal
              title="Delete Note"
              description="Are you sure you want to delete this note?"
              negativeBtn={{
                label: "No",
                fn: () => setRemoveModal(false),
                isShown: true,
                isOpen: true,
              }}
              positiveBtn={{
                label: "Yes",
                fn: async () => {
                  handleRemoveNote();
                  setRemoveModal(false);
                },
                isShown: true,
              }}
              isOpen={removeModal}
              closeFn={() => setRemoveModal(false)}
            />
          </Grid>
        </Grid>
      </PanelSelectorBox>
    </form>
  );
};

export default ProfileNotes;
