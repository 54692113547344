import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, IconButton, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";
import moment from "moment";

const ProfileNoteRow = ({ row, idx, onEdit, onRemove }) => {
  // eslint-disable-next-line prefer-template
  // const description = row?.text?.length > 50 ? row.text.substring(0, 50) + '...' : row?.text;

  return (
    <Stack onClick={() => onEdit(true, idx, row)} sx={{ padding: "0" }}>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Grid item xs={10} sm={10}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={5} sm={5}>
              <MDTypography variant="h6">{row?.type}</MDTypography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <MDTypography variant="body2">
                {moment(row?.date).format("ddd MM/DD/YYYY hh:mm A")}
              </MDTypography>
            </Grid>
            {/*     
            {isInterview && (
              <Grid item xs={2} sm={2}>
                {row.rating && renderIcon(row)}
              </Grid>
            )} */}
            {/* {viewDetail ? (
              <Grid item xs={12} sm={12} sx={{ "text-wrap": "balance", wordBreak: "break-word" }}>
                <hr />
                {row.text ? parse(row.text) : null}
              </Grid>
            ) : null} */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
        >
          <IconButton>
            <InfoIcon sx={{ color: "#1A73E8" }} />
          </IconButton>
          <IconButton onClick={() => onRemove(idx)}>
            <CancelIcon sx={{ color: "#F44335" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProfileNoteRow;
