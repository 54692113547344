import { ContentCopy } from "@mui/icons-material";
import { Button, IconButton, styled } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  display: "flex",
  gap: "5px",
  "& svg": {
    fill: theme.mode === "light" ? "#9E9E9E" : theme.palette.common.lightgreen,
  },
  "& > span": {
    display: "flex",
    alignItems: "center",
  },
}));

const CopyButton = ({
  textToCopy,
  children,
  placement = "top",
  type = "icon",
  withIcon = true,
}) => {
  const [copyToolTip, setCopyToolTip] = useState("Copy to clipboard");
  const copyToClipboard = (
    <CopyToClipboard text={textToCopy}>
      <CopyIcon>
        <span>{children}</span>
        {withIcon && (
          <span>
            <ContentCopy fontSize="small" />
          </span>
        )}
      </CopyIcon>
    </CopyToClipboard>
  );
  return (
    <Tooltip
      arrow
      placement={placement}
      title={copyToolTip}
      onClose={() => setTimeout(() => setCopyToolTip("Copy to clipboard"), 100)}
      componentsProps={{
        tooltip: {
          sx: ({ mode, palette }) =>
            mode === "dark"
              ? {
                  backgroundColor: palette.common.darkgreen,
                  "& span:before": {
                    backgroundColor: palette.common.darkgreen,
                  },
                }
              : {},
        },
      }}
    >
      {type === "icon" ? (
        <IconButton
          sx={{ marginLeft: 2, color: "black !important" }}
          onClick={() => setCopyToolTip("Copied")}
        >
          {copyToClipboard}
        </IconButton>
      ) : (
        <Button
          sx={(theme) => ({
            marginLeft: 2,
          })}
          onClick={() => setCopyToolTip("Copied")}
        >
          {copyToClipboard}
        </Button>
      )}
    </Tooltip>
  );
};

export default CopyButton;
