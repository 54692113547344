import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";

const fetchApplicants = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
  includeVerified = false,
  searchProjection = false,
  useOrTags = true,
  outsideMode = "",
  projectedFields = "",
  useElemMatch = false,
  includeDnu,
  orderByOverride,
  availableForJobSlug,
}) => {
  const { paginationQuery, sortQuery, filterQuery, queryString } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
    orderByOverride,
  });

  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;

    const res = await baseAxios.get(
      `/${outsideModeQuery}applicants${queryString}${useOr ? "&useOr=true" : ""}${
        includeVerified ? "&includeVerified=true" : ""
      }${searchProjection ? "&searchProjection=true" : ""}&tagOper=${useOrTags ? "in" : "all"}${
        outsideModeQuery || projectedFields ? `&projectedFields=${projectedFields}` : ""
      }${useElemMatch ? "&useElemMatch=true" : ""}${
        includeDnu || filters._id ? "&includeDnu=true" : ""
      }${availableForJobSlug ? `&availableForJobSlug=${availableForJobSlug}` : ""}`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchApplicants;
