import axios from "axios";
import { baseAxios } from "config";

const generateCustomerAIQuestions = async ({
  companySlug,
  questions,
  numberOfQuestions,
  saveToDb,
}) => {
  try {
    const res = await baseAxios.post(`/companies/${companySlug}/ai/screening/generate/`, {
      questions,
      numberOfQuestions,
      saveToDb,
    });
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default generateCustomerAIQuestions;
