import { NumericFormat } from "react-number-format";
import CustomTextField from "components/Form/CustomTextField";

const CurrencyField = (props) => {
  const { disabled, step, max } = props;
  return (
    <NumericFormat
      InputProps={{
        startAdornment: "$",
        inputProps: {
          tabIndex: disabled ? "-1" : "0",
          inputMode: "numeric",
          type: "number",
          // defaultValue: 0,
          step: step || 0.01,
          min: 0,
          max,
        },
      }}
      sx={{ input: { textAlign: "end" } }}
      variant="outlined"
      customInput={CustomTextField}
      decimalScale={2}
      {...props}
    />
  );
};

export default CurrencyField;
