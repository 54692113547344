import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState = {
  eventIds: [],
  fields: {},
  exportType: "",
  fromDate: new Date(),
  toDate: new Date(),
};

let store = (set) => ({
  ...initialState,
  setFields: (fields) => set({ fields }),
  setEventIds: (eventIds) => set({ eventIds }),
  setExportType: (exportType) => set({ exportType }),
  setToDate: (toDate) => set({ toDate }),
  setFromDate: (fromDate) => set({ fromDate }),
});

store = devtools(store);

store = persist(store, { name: "payrollExportStore" });

const usePayrollExportStore = create(store);

export default usePayrollExportStore;
