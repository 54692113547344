import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import fetchEventApplicants from "layouts/pages/events/actions/fetchEventApplicants";

export const useRosterApplicants = ({ eventUrl: eventUrlProp, options = {} }) => {
  let eventUrlParams = eventUrlProp;
  const { eventUrl } = useParams();

  if (!eventUrlParams) {
    eventUrlParams = eventUrl;
  }

  return useQuery(
    ["rosterApplicants", eventUrl || eventUrlParams],
    () => fetchEventApplicants(eventUrl || eventUrlParams),
    options
  );
};
