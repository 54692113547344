import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import TagListToggle from "components/TagListToggle";
import TagListSearch from "components/TagListSearch";

const TagSearchAndToggle = ({
  filters,
  setFilters,
  searchTagList,
  setSearchTagList,
  useOrTags,
  setUseOrTags,
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const clearTagsHandler = () => {
    const tempFilters = { ...filters };
    delete tempFilters.tags;
    setFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(true);
  };
  const allTagsHandler = () => setUseOrTags(false);
  const anyTagsHandler = () => setUseOrTags(true);

  return (
    <>
      {searchTagList?.length > 0 && (
        <TagListToggle
          tagsToggle={useOrTags ? "or" : "all"}
          onAny={anyTagsHandler}
          onAll={allTagsHandler}
          onClear={clearTagsHandler}
        />
      )}
      {!isSmallScreen && (
        <TagListSearch
          searchTagList={searchTagList}
          setSearchTagList={setSearchTagList}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </>
  );
};

export default TagSearchAndToggle;
