import { useQuery } from "react-query";

import fetchPrimaryCompany from "api/companies/fetchPrimaryCompany";
// import { useLocation } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { IMG_SERVER } from "config";

export const useOutsideCompanyCache = ({
  company,
  setCompany,
  setCompanyType,
  outsideMode = "",
}) => {
  const options = { fetchAll: true };
  const coFilters = { ...options, primaryCompany: true };
  const allCoOptions = { coFilters, order: "asc", orderBy: "name" };
  //  const { isLoggedIn, currentLoggedUser } = useAppContextController();

  const { isLoading: isLoadingCompany, refetch: refetchCompany } = useQuery(
    `outsideCompanyCache${outsideMode}`,
    () => fetchPrimaryCompany({ ...allCoOptions, outsideMode }),
    {
      enabled: !company,
      onSuccess: (data) => {
        if (setCompany) {
          setCompany({ ...data, imageUrl: `${IMG_SERVER}/${data?.uploadPath}` });
          setCompanyType(data?.companyType || data?.settings?.companyType);
        }
      },
    }
  );

  return { isLoadingCompany, refetchCompany };
};

function useCompanyCache({ company, setCompany, setCompanyType }) {
  const options = { fetchAll: true };
  const coFilters = { ...options, primaryCompany: true };
  const allCoOptions = { coFilters, order: "asc", orderBy: "name" };
  const { currentLoggedUser, setImageUrl } = useAppContextController();

  const { isLoading: isLoadingCompany, refetch: refetchCompany } = useQuery(
    "companyCache",
    () => fetchPrimaryCompany({ allCoOptions }),
    {
      enabled: !company && !!currentLoggedUser,
      onSuccess: (data) => {
        if (setCompany) {
          setCompany({ ...data, imageUrl: `${IMG_SERVER}/${data?.uploadPath}` });
          setCompanyType(data?.companyType || data?.settings?.companyType);
        }
      },
    }
  );

  return { isLoadingCompany, refetchCompany };
}

export default useCompanyCache;
