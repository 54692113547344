import { createContext, useContext } from "react";

import useNotifications from "hooks/useNotifications";
import { notificationInitialState } from "reducers/notificationReducer";

export const NotificationContext = createContext(notificationInitialState);

export const NotificationContextProvider = ({ children }) => {
  const notificationProviderState = useNotifications();

  return (
    <NotificationContext.Provider value={notificationProviderState}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotificationContext() {
  return useContext(NotificationContext);
}
