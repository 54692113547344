import { useAuth0 } from "@auth0/auth0-react";
import logout from "api/auth/logout";
import storeToken from "api/auth/storeToken";

const useSessionAuth = () => {
  const { logout: auth0Logout, getAccessTokenSilently, user } = useAuth0();

  const handleStoreToken = async () => {
    const options = {
      authorizationParams: { audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/` },
    };

    try {
      const token = await getAccessTokenSilently(options);

      if (token) {
        const response = await storeToken({ token });
        // console.log("token: ", token);

        if (response.status === 200) return true;
      }

      return false;
    } catch (error) {
      console.error("Error: ", error);
      return false;
    }
  };

  const logoutSession = async () => {
    try {
      const response = await logout();

      if (response.status === 200) return true;

      return false;
    } catch (error) {
      console.error("Error: ", error);
      return false;
    }
  };

  const logoutAuth0User = async () => {
    if (user) {
      const basePath = new URL(window.location);

      await logoutSession();
      auth0Logout({ returnTo: basePath.origin });
    }
  };

  return { handleStoreToken, logoutSession, logoutAuth0User };
};

export default useSessionAuth;
