import { useCallback, useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player";
import axios from "axios";

import { useAppContextController } from "context/AppContext";

import { useMutation, useQueryClient } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Alert, Snackbar, Button, Icon, IconButton, TextField, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FileDropzone from "components/Dropzone";
import AddIcon from "@mui/icons-material/Add";

import { baseAxios } from "config";

// Styiling
import useStyles from "layouts/pages/venues/components/VenueMediaGallery/styles";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import deleteImage from "layouts/pages/venues/actions/deleteImage";
import { getCommonBaseImageUrl } from "utils/helpers/upload";

const VenueMediaGallery = ({ title }) => {
  const styles = useStyles();
  const { currentVenue, setCurrentVenue, company, setVenues } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;

  const uploadUrl = `/upload/${currentVenue?.slug}/venues/banner`;

  const [urlInputs, setUrlInputs] = useState({
    others: "",
    video: "",
  });

  const [images, setImages] = useState([]);

  const [otherFiles, setOtherFiles] = useState([]);
  const [deleteImgErr, setDeleteImgErr] = useState(false);
  const [deletePolicyErr, setDeletePolicyErr] = useState(false);

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const getLastNode = (path) => {
    const pathArr = path.split("/");
    if (pathArr?.length) return pathArr[pathArr.length - 1];
    return path;
  };

  // on page load render current images from DB
  useEffect(() => {
    setImages(
      currentVenue?.imageUrls?.map((img) => ({
        name: img?.startsWith("http") ? getLastNode(img) : img,
        editedName: img?.startsWith("http") ? getLastNode(img) : img,
        isEdited: false,
      })) ?? []
    );
  }, [currentVenue?.imageUrls]);

  // on page load render current venues from DB
  useEffect(() => {
    setOtherFiles(
      currentVenue?.otherUrls?.map((img) => ({
        name: img?.startsWith("http") ? getLastNode(img) : img,
        editedName: img?.startsWith("http") ? getLastNode(img) : img,
        isEdited: false,
      })) ?? []
    );
  }, [currentVenue?.otherUrls]);

  const queryClient = useQueryClient();

  const updateVenueMutation = useMutation(updateVenue, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries(["venues"]);
      await queryClient.invalidateQueries(["myvenues"]);
      setCurrentVenue({ ...currentVenue, ...data });
      setVenues({ [currentVenue.slug]: { ...currentVenue, ...data } });
      setToastAlert({ isShow: true, message: "Media has been updated!", status: "success" });
    },
  });

  const deleteImageMutation = useMutation(deleteImage, {
    onError: () => {
      setDeleteImgErr(true);
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(["venues"]);
      await queryClient.invalidateQueries(["myvenues"]);
      setToastAlert({ isShow: true, message: "Image has been deleted!", status: "success" });
    },
  });

  const renameImageMutation = useMutation(
    async ({ rename, original }) => {
      const url = `/upload/rename/${currentVenue?.slug}/venues/banner?rename=${encodeURIComponent(
        rename
      )}&original=${encodeURIComponent(original)}`;
      await baseAxios.patch(url);
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: () => {
        setToastAlert({ isShow: true, message: "Successfully renamed", status: "success" });
      },
    }
  );

  const renameFileMutation = useMutation(
    async ({ rename, original }) => {
      const url = `/upload/rename/${currentVenue?.slug}/venues/other?rename=${encodeURIComponent(
        rename
      )}&original=${encodeURIComponent(original)}`;
      const res = await baseAxios.patch(url);
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: () => {
        setToastAlert({ isShow: true, message: "Successfully renamed", status: "success" });
      },
    }
  );

  const deletePolicyMutation = useMutation(deleteImage, {
    onError: (e) => {
      setDeletePolicyErr(true);
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries("venues");
      setCurrentVenue({ ...currentVenue, ...data });
      setToastAlert({ isShow: true, message: "File has been deleted!", status: "success" });
    },
  });

  const saveUpdate = async (values) => {
    if (!currentVenue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: values,
    });
  };

  const onDropImages = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);

      await baseAxios.post(uploadUrl, form);

      const newImages =
        images.length !== 0
          ? Array.from(new Set([...images, { name: file.name, isEdited: false }]))
          : [{ name: file.name, isEdited: false }];
      setImages(newImages);

      // save img names to DB
      await saveUpdate({ imageUrls: newImages.map((img) => img.name) });
    }
  };

  const onDropOthers = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const form = new FormData();
    form.append("file", file);

    await baseAxios.post(`/upload/${currentVenue?.slug}/venues/other`, form);

    const newFiles =
      otherFiles && otherFiles.length !== 0
        ? Array.from(new Set([...otherFiles, { name: file.name, isEdited: false }]))
        : [{ name: file.name, isEdited: false }];
    setOtherFiles(newFiles);

    await saveUpdate({ otherUrls: newFiles.map((img) => img.name) });
  };

  const deleteImageHandler = async (imageId) => {
    const filteredImages = images.filter((file, index) => index !== imageId);

    if (images[imageId]?.name === getLastNode(currentVenue?.bannerUrl)) {
      await updateVenueMutation.mutateAsync({
        venueId: currentVenue._id,
        data: { bannerUrl: "" },
      });
    }

    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: {
        imageUrls: filteredImages.map((img) => img.name),
      },
    });

    setImages(filteredImages);
  };

  const deleteMapAndPolicyHandler = async (e, idx) => {
    const filteredFiles = otherFiles.filter((file, index) => index !== idx);

    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { otherUrls: filteredFiles.map((img) => img.name) },
    });

    setOtherFiles(filteredFiles);
  };

  const addVideoHandler = async (url) => {
    // check if url is in videos list
    const check = (elem) => elem === url;
    if (currentVenue.videoUrls?.length && currentVenue.videoUrls.some(check)) {
      setToastAlert({ isShow: true, message: "Can't have Duplicate Videos", status: "error" });
      return;
    }

    // update database
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { videoUrls: currentVenue.videoUrls ? [...currentVenue?.videoUrls, url] : [url] },
    });
  };

  const deleteVideoHandler = async (e, idx) => {
    const video = [...currentVenue?.videoUrls];

    if (idx !== -1) {
      video.splice(idx, 1);
    }

    // delete from DB
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { videoUrls: video },
    });
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...otherFiles];

    // get the image extension
    const imgExt = newFiles[idx].name.substring(newFiles[idx].name.lastIndexOf("."));

    // check if edited
    const removeExt = newFiles[idx].name.substring(0, newFiles[idx].name.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;

    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].name;

    // set the new images state
    setOtherFiles(newFiles);
  };

  const editImgNameHandler = useCallback(
    (e, idx) => {
      const newImages = [...images];

      // get the image extension
      const imgExt = newImages[idx].name.substring(newImages[idx].name.lastIndexOf("."));

      // check if edited
      const removeExt = newImages[idx].name.substring(0, newImages[idx].name.lastIndexOf("."));
      newImages[idx].isEdited = removeExt !== e.target.value;

      // set the edited name value
      newImages[idx].editedName = newImages[idx].isEdited
        ? e.target.value + imgExt
        : newImages[idx].name;

      // set the new images state
      setImages(newImages);
    },
    [images]
  );
  const cancelImageNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...otherFiles];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setOtherFiles(newFiles);
    },
    [images]
  );

  const confirmImageNameChanges = async (e, idx) => {
    await renameImageMutation.mutateAsync({
      original: images[idx].name,
      rename: images[idx].editedName,
    });

    const newImages = [...images];
    newImages[idx] = { ...newImages[idx], name: newImages[idx].editedName, isEdited: false };
    setImages(newImages);

    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { imageUrls: newImages.map((img) => img.name) },
    });
  };

  const confirmFileNameChanges = async (e, idx) => {
    await renameFileMutation.mutateAsync({
      original: otherFiles[idx].name,
      rename: otherFiles[idx].editedName,
    });

    const newFiles = [...otherFiles];
    newFiles[idx] = { ...newFiles[idx], name: newFiles[idx].editedName, isEdited: false };
    setOtherFiles(newFiles);

    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { otherUrls: newFiles.map((img) => img.name) },
    });
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...otherFiles];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setOtherFiles(newFiles);
    },
    [otherFiles]
  );

  return (
    currentVenue && (
      <Card className={styles.venueMediaGallery}>
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            severity={toastAlert.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>

        <MDBox display="flex" justifyContent="space-between" py={1}>
          <MDBox display="flex" mb={2}>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              mt={-3}
            >
              <Icon fontSize="large">movie_filter</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ my: 1, ml: 2 }}>
              {title || "TITLE"}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox className={styles.gallerySection}>
          <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
            <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
              Venue Media Gallery
            </MDTypography>
          </MDBox>
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={1.5}>
              <FileDropzone
                onDrop={onDropImages}
                restrict={{ "image/*": [] }}
                maxSize={1048576}
                size="15rem"
              />
            </Grid>
            <Grid item sm={10.5}>
              <MDBox className={styles.section_images_wapper}>
                {images.map((image, idx) => (
                  <MDBox className={styles.section_image_container} key={image.name}>
                    <IconButton
                      color="info"
                      className={styles.section_image_delete}
                      onClick={() => deleteImageHandler(idx)}
                    >
                      <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                    <MDBox style={{ height: "200px" }}>
                      {IMAGE_SERVER && (
                        <img
                          src={
                            image.name.startsWith("http")
                              ? image.name
                              : `${IMAGE_SERVER}/${currentVenue?.slug}/venues/banner/${image.name}`
                          }
                          alt="previews"
                          className={styles.section_image}
                        />
                      )}
                    </MDBox>
                    <TextField
                      variant="outlined"
                      placeholder="File name"
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      defaultValue={image.name.substring(0, image.name.lastIndexOf("."))}
                      value={images[idx].editedName?.substring(
                        0,
                        image.editedName.lastIndexOf(".")
                      )}
                      onInput={(e) => editImgNameHandler(e, idx)}
                    />
                    {image.isEdited && (
                      <>
                        <Tooltip title="Confirm" placement="bottom">
                          <CheckIcon
                            color="success"
                            onClick={(e) => confirmImageNameChanges(e, idx)}
                            fontSize="large"
                            sx={{
                              "&": { transition: "0.1s" },
                              "&:hover": { background: "#D3D3D3" },
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Cancel" placement="bottom">
                          <CloseIcon
                            onClick={(e) => cancelImageNameChanges(e, idx)}
                            color="error"
                            fontSize="large"
                            sx={{
                              "&": { transition: "0.1s" },
                              "&:hover": { background: "#D3D3D3" },
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </MDBox>
                ))}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox className={styles.otherSection}>
          <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
            <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
              Venue Maps and Policies
            </MDTypography>
          </MDBox>
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={1.5}>
              <MDBox mb={2}>
                <FileDropzone onDrop={onDropOthers} maxSize={1048576} size="15rem" />
              </MDBox>
            </Grid>
            <Grid item sm={10.5}>
              <MDBox className={styles.section_images_wapper}>
                {otherFiles.map((file, idx) => (
                  <MDBox className={styles.section_image_container} key={file.name}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Grid>
                        <MDBox style={{ position: "absolute", left: "0%" }}>
                          <IconButton
                            color="info"
                            className={styles.maps_section_image_delete}
                            onClick={(e) => deleteMapAndPolicyHandler(e, idx)}
                          >
                            <Icon fontSize="small">cancel</Icon>
                          </IconButton>
                        </MDBox>
                      </Grid>

                      <Grid>
                        <MDBox
                          onClick={() => {
                            if (IMAGE_SERVER && file?.name)
                              window.open(
                                `${IMAGE_SERVER}/${currentVenue?.slug}/venues/other/${file.name}`
                              );
                          }}
                        >
                          {IMAGE_SERVER &&
                          ["png", "jpg", "jpeg", "bmp", "jfif", "pjpeg", "pjp"].includes(
                            file?.name?.split(".").pop().toLowerCase()
                          ) ? (
                            <img
                              src={
                                file.name.startsWith("http")
                                  ? file.name
                                  : `${IMAGE_SERVER}/${currentVenue?.slug}/venues/other/${file.name}`
                              }
                              alt="previews"
                              width={100}
                              height={100}
                              className={styles.image_fit}
                            />
                          ) : (
                            <>
                              {!!company?.imageUrl && !!company?.uploadUrl && (
                                <img
                                  src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                                  alt="preview"
                                  width={100}
                                  height={100}
                                />
                              )}
                            </>
                          )}
                        </MDBox>
                      </Grid>

                      <Grid>
                        <TextField
                          defaultValue={file.name.substring(0, file.name.lastIndexOf("."))}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                          value={otherFiles[idx].editedName?.substring(
                            0,
                            file.editedName.lastIndexOf(".")
                          )}
                          onInput={(e) => editFileNameHandler(e, idx)}
                        />

                        {file.isEdited && (
                          <>
                            <Tooltip title="Confirm" placement="bottom">
                              <CheckIcon
                                color="success"
                                onClick={(e) => confirmFileNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Cancel" placement="bottom">
                              <CloseIcon
                                color="error"
                                onClick={(e) => cancelFileNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                ))}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox className={styles.otherSection}>
          <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
            <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
              Training Videos
            </MDTypography>
          </MDBox>
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={1.5}>
              <MDBox>
                <TextField
                  variant="outlined"
                  placeholder="Add URL here"
                  inputProps={{ min: 0, style: { textAlign: "center", height: "32px" } }}
                  value={urlInputs.video}
                  onChange={(e) => setUrlInputs({ ...urlInputs, video: e.target.value })}
                  fullWidth
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="center" mt={1}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  style={{ color: "white" }}
                  disabled={urlInputs.video === ""}
                  onClick={(e) => {
                    addVideoHandler(urlInputs.video);
                    setUrlInputs({
                      others: "",
                      video: "",
                    });
                  }}
                >
                  Add a video
                </Button>
              </MDBox>
            </Grid>
            <Grid item sm={10.5}>
              <MDBox className={styles.section_images_wapper}>
                {currentVenue?.videoUrls && currentVenue?.videoUrls.length > 0 ? (
                  currentVenue?.videoUrls.map((vid, idx) => (
                    <MDBox className={styles.training_section_image_container} key={vid}>
                      <MDBox className={styles.positioner} key={vid}>
                        <IconButton
                          color="info"
                          className={styles.training_section_image_delete}
                          onClick={(e) => deleteVideoHandler(e, idx)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                      </MDBox>
                      {vid.toLowerCase().indexOf("vimeo.com") > -1 ? (
                        <Vimeo
                          video={vid.toLowerCase().replace("https://vimeo.com/manage/videos/", "")}
                          showTitle
                          width="320px"
                          height="180px"
                        />
                      ) : (
                        <ReactPlayer url={vid} width="320px" height="180px" />
                      )}
                    </MDBox>
                  ))
                ) : (
                  <MDTypography variant="p" color="dark" className={styles.titleBar_title} sx>
                    Please add video
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    )
  );
};

export default VenueMediaGallery;
