import axios from "axios";
import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchCompanyJob = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
  includeExtraCounts = false,
  startDate = "",
  endDate = "",
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  let queryString = `${paginationQuery}${sortQuery}${filterQuery}${useOr ? "&useOr=true" : ""}${
    includeExtraCounts ? "&includeExtraCounts=true" : ""
  }${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`;

  if (queryString.includes("&") && !queryString.includes("?")) {
    queryString = queryString.replace("&", "?");
  }

  try {
    const res = await baseAxios.get(`/jobs${queryString}`);

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchCompanyJob;
