import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { baseAxios } from "config";
import {
  Icon,
  Grid,
  IconButton,
  TextField,
  Button,
  Stack,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Save } from "@mui/icons-material";
import useStyles from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments/styles";
import GenericModal from "components/GenericModal";
import FileDropzone from "components/Dropzone";
import useCompanyStore from "stores/useCompanyStore";
import { useAppContextController } from "context/AppContext";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import updateCompany from "api/companies/updateCompany";
import { getCommonBaseImageUrl } from "utils/helpers/upload";

const AddMessageAttachmentModal = ({
  open,
  setOpen,
  messageContext,
  attachmentSelection,
  setAttachmentSelection,
  applicantId,
}) => {
  const {
    company,
    currentVenue,
    setCurrentVenue,
    currentEvent,
    setCurrentEvent,
    currentApplicant,
    setCurrentApplicant,
  } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const size = isMobile ? 70 : 100;
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const [files, setFiles] = useState([]);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const onFileDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, { variant: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);

      if (messageContext === "Venue") {
        await baseAxios.post(`/upload/${currentVenue?.slug}/venues/other`, form);

        setFiles([
          ...files,
          {
            entity: messageContext,
            filename: file.name,
            venueSlug: currentVenue.slug,
            docType: file?.path?.split(".").pop(),
            uploadDate: new Date(),
          },
        ]);
      }
      if (messageContext === "Event") {
        await baseAxios.post(
          `/upload/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/`,
          form
        );

        setFiles([
          ...files,
          {
            entity: messageContext,
            filename: file.name,
            eventUrl: currentEvent.eventUrl,
            venueSlug: currentEvent.venueSlug,
            docType: file?.path?.split(".").pop(),
            type: "Other",
            uploadDate: new Date(),
          },
        ]);
      }
      if (messageContext === "Customer") {
        await baseAxios.post(`/upload/${currentCompany?.slug}/company/other`, form);

        setFiles([
          ...files,
          {
            entity: messageContext,
            filename: file.name,
            companySlug: currentCompany?.slug,
            type: "Other",
            docType: file?.path?.split(".").pop(),
            uploadDate: new Date(),
          },
        ]);
      }
      if (messageContext === "Applicant") {
        await baseAxios.post(`/upload/applicants/${applicantId}/Other`, form);

        setFiles([
          ...files,
          {
            entity: messageContext,
            filename: file.name,
            applicantId,
            type: "Other",
            docType: file?.path?.split(".").pop(),
            uploadDate: new Date(),
          },
        ]);
      }
    }
  };

  const deleteFileHandler = async (e, idx) => {
    const tempAttachments = [...files];
    const updatedAttachments = tempAttachments.filter((_, i) => i !== idx);
    setFiles(updatedAttachments);
  };

  const updateVenueMutation = useMutation(updateVenue, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("venues");
      setCurrentVenue({ ...currentVenue, ...data });
      enqueueSnackbar(`Files have been saved on ${currentVenue?.name}`, { variant: "success" });
      setAttachmentSelection([...attachmentSelection, ...files]);
      setFiles([]);
      await queryClient.invalidateQueries("venues");
    },
  });

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentApplicant({ ...currentApplicant, ...data });
      enqueueSnackbar(
        `Files have been saved on ${currentApplicant?.firstName} ${currentApplicant?.lastName} profile`,
        { variant: "success" }
      );
      setAttachmentSelection([...attachmentSelection, ...files]);
      setFiles([]);
      await queryClient.invalidateQueries("applicants");
    },
  });

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar(`Files have been saved on ${currentEvent?.eventName}`, {
        variant: "success",
      });
      setAttachmentSelection([...attachmentSelection, ...files]);
      setFiles([]);
      await queryClient.invalidateQueries("events");
    },
  });
  const updateCompanyMutation = useMutation(updateCompany, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { body }) => {
      setCurrentCompany({ ...currentCompany, ...body });
      enqueueSnackbar(`Files have been saved on ${currentCompany?.name}`, { variant: "success" });
      setAttachmentSelection([...attachmentSelection, ...files]);
      setFiles([]);
      await queryClient.invalidateQueries("companies");
    },
  });

  const handleMutation = async () => {
    if (messageContext === "Applicant") {
      if (!currentApplicant?._id) {
        enqueueSnackbar("Cannot Update Applicant -- No Applicant ID!", { variant: "error" });
        return;
      }
      await updateApplicantMutation.mutateAsync({
        applicantId: currentApplicant?._id,
        data: {
          attachments: [
            ...(currentApplicant?.attachments?.length ? [...currentApplicant.attachments] : []),
            ...files.map((attachment) => {
              return {
                docType: attachment.docType,
                filename: attachment.filename,
                type: attachment.type,
                uploadDate: attachment.uploadDate,
              };
            }),
          ],
        },
      });
    }
    if (messageContext === "Venue") {
      if (!currentVenue._id) {
        enqueueSnackbar("Cannot Update Venue -- No Venue ID!", { variant: "error" });
        return;
      }
      await updateVenueMutation.mutateAsync({
        venueId: currentVenue._id,
        data: {
          otherUrls: [
            ...currentVenue?.otherUrls,
            ...files.map((attachment) => attachment.filename),
          ],
        },
      });
    }
    if (messageContext === "Event") {
      if (!currentEvent.venueSlug || !currentEvent.eventId) {
        enqueueSnackbar("Cannot Update Event -- No Event ID!", { variant: "error" });
        return;
      }
      await updateEventMutation.mutateAsync({
        venueSlug: currentEvent.venueSlug,
        eventId: currentEvent._id,
        data: {
          attachments: [
            ...currentEvent.attachments,
            ...files.map((attachment) => {
              return {
                docType: attachment.docType,
                filename: attachment.filename,
                type: attachment.type,
                uploadDate: attachment.uploadDate,
              };
            }),
          ],
        },
      });
    }
    if (messageContext === "Customer") {
      if (!company._id) {
        enqueueSnackbar("Cannot Update Customer -- No Customer ID!", { variant: "error" });
        return;
      }
      await updateCompanyMutation.mutateAsync({
        companyId: currentCompany._id,
        body: {
          attachments: [
            ...(currentCompany?.attachments ?? []),
            ...files.map((attachment) => {
              return {
                docType: attachment.docType,
                filename: attachment.filename,
                uploadDate: attachment.uploadDate,
              };
            }),
          ],
        },
      });
    }

    setOpen(false);
  };

  // const editFileNameHandler = (e, idx) => {
  //   const newFiles = [...files];
  //   const imgExt = newFiles[idx].filename?.substring(newFiles[idx].filename.lastIndexOf("."));
  //   const removeExt = newFiles[idx].filename?.substring(0, newFiles[idx].filename.lastIndexOf("."));
  //   newFiles[idx].isEdited = removeExt !== e.target.value;
  //   newFiles[idx].editedName = newFiles[idx].isEdited
  //     ? e.target.value + imgExt
  //     : newFiles[idx].filename;
  //   setFiles(newFiles);
  // };

  // const confirmFileNameChanges = async (e, idx) => {
  //   const newFiles = [...files];
  //   newFiles[idx] = { ...newFiles[idx], filename: newFiles[idx].editedName, isEdited: false };
  //   setFiles(newFiles);
  // };

  // const cancelFileNameChanges = useCallback(
  //   (e, idx) => {
  //     const newFiles = [...files];
  //     const originalName = newFiles[idx].filename;
  //     newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
  //     setFiles(newFiles);
  //   },
  //   [files]
  // );

  const modalBody = (
    <>
      <FileDropzone message="Drop files here to upload" onDrop={onFileDropHandler} />
      <MDBox className={styles.section_images_wapper}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={isMobile ? "center" : "flex-start"}
        >
          {files.map((file, idx) => {
            return (
              <Grid item className={styles.section_image_container} key={file.filename}>
                <Stack container justifyContent="space-evenly" alignItems="center" mb={3}>
                  <MDBox
                    style={{
                      position: "absolute",
                      right: "-10px",
                      top: "-10px",
                      backgroundColor: "white",
                      padding: 1,
                      borderRadius: "25px",
                    }}
                  >
                    <IconButton
                      color="info"
                      // className={styles.maps_section_image_delete}
                      onClick={(e) => deleteFileHandler(e, idx)}
                    >
                      <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                  </MDBox>

                  {messageContext === "Applicant" &&
                    IMAGE_SERVER &&
                    ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg", "webp"].includes(
                      file?.docType?.toLowerCase()
                    ) && (
                      <MDBox
                        onClick={() => {
                          if (IMAGE_SERVER)
                            window.open(
                              `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.filename}`
                            );
                        }}
                      >
                        <img
                          src={`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.filename}`}
                          alt="previews"
                          // className={styles.section_image}
                          height={size}
                        />
                      </MDBox>
                    )}
                  {messageContext === "Venue" &&
                    IMAGE_SERVER &&
                    ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg", "webp"].includes(
                      file?.docType?.toLowerCase()
                    ) && (
                      <MDBox
                        onClick={() => {
                          if (IMAGE_SERVER)
                            window.open(
                              `${IMAGE_SERVER}/${currentVenue?.slug}/venues/other/${file.filename}`
                            );
                        }}
                      >
                        <img
                          src={`${IMAGE_SERVER}/${currentVenue?.slug}/venues/other/${file.filename}`}
                          alt="previews"
                          // className={styles.section_image}
                          height={size}
                        />
                      </MDBox>
                    )}
                  {messageContext === "Event" &&
                    IMAGE_SERVER &&
                    ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg", "webp"].includes(
                      file?.docType?.toLowerCase()
                    ) && (
                      <MDBox
                        onClick={() => {
                          if (IMAGE_SERVER)
                            window.open(
                              `${IMAGE_SERVER}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${file.filename}`
                            );
                        }}
                      >
                        <img
                          src={`${IMAGE_SERVER}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${file.filename}`}
                          alt="previews"
                          // className={styles.section_image}
                          height={size}
                        />
                      </MDBox>
                    )}
                  {messageContext === "Customer" &&
                    IMAGE_SERVER &&
                    ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg", "webp"].includes(
                      file?.docType?.toLowerCase()
                    ) && (
                      <MDBox
                        onClick={() => {
                          if (IMAGE_SERVER)
                            window.open(
                              `${IMAGE_SERVER}/${currentCompany?.slug}/company/other/${file.filename}`
                            );
                        }}
                      >
                        <img
                          src={`${IMAGE_SERVER}/${currentCompany?.slug}/company/other/${file.filename}`}
                          alt="previews"
                          // className={styles.section_image}
                          height={size}
                        />
                      </MDBox>
                    )}
                  {!!company?.imageUrl &&
                    !!company?.uploadUrl &&
                    file?.docType?.toLowerCase() === "pdf" && (
                      <img
                        src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                        alt="PDF Preview"
                        width={size}
                        height={size}
                      />
                    )}
                  {!!company?.imageUrl &&
                    !!company?.uploadUrl &&
                    file?.docType.toLowerCase() === "docx" && (
                      <img
                        src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                        alt="preview"
                        width={size}
                        height={size}
                      />
                    )}

                  <Box>
                    <MDTypography variant="h6" textAlign="center">
                      {files[idx].type}
                    </MDTypography>
                    <TextField
                      disabled
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={files[idx].filename}
                    />
                  </Box>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    </>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(false)}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<Save />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
        onClick={() => {
          handleMutation();
        }}
      >
        Save
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        header="Upload Attachment for Mail"
        body={modalBody}
        buttons={modalButtons}
        width="40%"
        borderRadius="4px"
      />
    </>
  );
};

export default AddMessageAttachmentModal;
