import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  styled,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import useDropdowns from "hooks/useDropdowns";
import updateUser from "api/users/updateUser";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import resetPassword from "layouts/pages/users/actions/resetPassword";

import getBrowserAndOS from "utils/getBrowserAndOS";
import { isAdmin } from "utils/helpers/roles";
import getAuth0Status from "api/users/getAuth0Status";
import ProfileActivities from "layouts/pages/profile/components/ProfileActivities";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import { makeStyles } from "@mui/styles";
import Auth0UserConfirmModal from "layouts/pages/users/components/Auth0UserConfirmModal";
import { useAppContextController } from "context/AppContext";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { userPanelFormSchema } from "data/userPanelForm";

const initialModalValues = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};
const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 170,
  },
  dropzone: {
    minHeight: 70,
  },
  previewButton: {
    padding: 0,
  },
  words: {
    wordWrap: "break-word",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  switchBase: {
    "&.Mui-checked": {
      "& .MuiSwitch-thumb": {
        border: "1px solid #1A73E8 !important",
      },
      "& + .MuiSwitch-track": {
        opacity: "1 !important",
        backgroundColor: "#1A73E8 !important",
        borderColor: "#1A73E8 !important",
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: "0.3 !important",
      backgroundColor: "grey",
      borderColor: "grey",
    },
  },
  thumb: {
    color: "white !important",
    border: "1px solid grey !important",
  },
});

const PartnerUserForm = ({
  currentApplicant,
  defaultValues,
  user,
  title,
  hasDeleteButton = false,
  showVerifiedFlags = false,
  userIdentityVerification,
  identityVerificationColor,
  toggleConfirmationModal,
  handleUserIdentityUpdate,
}) => {
  const { currentLoggedUser, userType, company } = useAppContextController();
  const classes = useStyle();
  const [isModified, setIsModified] = useState(false);
  const [confirmModal, setConfirmModal] = useState(initialModalValues);
  const { enqueueSnackbar } = useSnackbar();
  const [auth0UserConfirmModalOpen, setAuth0UserConfirmModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [auth0Status, setAuth0Status] = useState(false);
  const isCompany = company?.companyType === "Company";
  const isVenue = company?.companyType === "Venue";
  const { dropdowns: employeeTypes } = useDropdowns({ entity: "employeeType", enabled: isVenue });
  const { dropdowns: userStatusField } = useDropdowns({ entity: "userStatusField" });

  const validationSchema = userPanelFormSchema(isCompany);
  const resolver = useYupValidationResolver(validationSchema);

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm({ mode: "onBlur", defaultValues, resolver });

  useEffect(() => {
    const getStatus = async () => {
      const status = await getAuth0Status(user?.emailAddress);
      setAuth0Status(status?.users?.length > 0);
    };
    if (user?.emailAddress) {
      try {
        getStatus();
      } catch (error) {
        console.log("error:", error);
      }
    }
  }, [user]);

  const updateUserInfoMutation = useMutation(updateUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries(["users"]);
      await queryClient.invalidateQueries(["getPartnerApplicantDetails"]);
      await queryClient.invalidateQueries(["getPartnerUserDetails"]);
      enqueueSnackbar("User has been deleted!", { variant: "success" });
    },
  });

  const updateUserMutation = useMutation(updateProfile, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries(["applicants"]);
      await queryClient.invalidateQueries(["users"]);
      await queryClient.invalidateQueries(["getPartnerApplicantDetails"]);
      await queryClient.invalidateQueries(["getPartnerUserDetails"]);
      enqueueSnackbar("User has been updated!", { variant: "success" });
    },
  });

  const resetPasswordMutation = useMutation(resetPassword, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Email to reset password has been sent!", { variant: "success" });
    },
  });

  const handleCloseModal = () => setConfirmModal(initialModalValues);

  const handleDelete = async () => {
    await updateUserInfoMutation.mutateAsync({
      userId: user?._id,
      data: { status: "Deleted" },
    });
  };

  const handleDeleteModal = () => {
    setConfirmModal({
      isOpen: true,
      title: `Delete User`,
      bodyText: `Please confirm you want to DELETE this User!`,
      response: false,
      description: `Please confirm you want to DELETE this User!`,
      negativeBtn: {
        label: "Cancel",
        fn: handleCloseModal,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          handleDelete();
          handleCloseModal();
        },
        isShown: true,
      },
    });
  };

  const handleChanges = async () => {
    if (!user?.userId) {
      enqueueSnackbar("Something went wrong!, no Id", { variant: "error" });
      return;
    }
    let values = watch();
    if (dirtyFields?.emailAddress) {
      values = {
        ...values,
        oldEmail: user?.emailAddress,
        newEmail: watch("emailAddress"),
      };
    }

    await updateUserMutation.mutateAsync({
      userId: user?.userId,
      data: values,
    });
    setIsModified(false);
  };

  const handleCancelChanges = () => {
    if (!defaultValues) return;
    reset(defaultValues);
    enqueueSnackbar("Changes has been abandoned", { variant: "info" });
  };

  const handleCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
      description: "Please Confirm to abandon Changes",
      negativeBtn: {
        label: "Cancel",
        fn: handleCloseModal,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          handleCancelChanges();
          handleCloseModal();
        },
        isShown: true,
      },
    });
  };

  const getSendData = () => {
    const { os, browser } = getBrowserAndOS();
    const { applicantId, _id } = user;
    const details = {
      device: os,
      browser,
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser?._id,
      userId: _id,
    };
    return {
      applicantId,
      data: details,
    };
  };

  const handlePasswordReset = () => resetPasswordMutation.mutateAsync(getSendData());

  useEffect(() => {
    const subscription = watch(() => {
      if (defaultValues) {
        setIsModified(JSON.stringify(watch()) !== JSON.stringify(defaultValues));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultValues]);

  return (
    <>
      <form onSubmit={handleSubmit(handleChanges)}>
        <Stack direction="row" container alignItems="center" p="0.5rem 2.5rem 1rem">
          <Stack item>
            <MDTypography color="blue" fontWeight="bold" variant="body2">
              {title}{" "}
              <Tooltip
                title={auth0Status ? "Auth0 Account Exist" : "Auth0 Account Does Not Exist"}
                placement="bottom"
              >
                <Button
                  onClick={() => {
                    setAuth0UserConfirmModalOpen(true);
                  }}
                  sx={{ p: 1, minWidth: 0 }}
                >
                  <MDBox
                    variant="gradient"
                    borderRadius="50%"
                    shadow="emd"
                    width="25px"
                    height="25px"
                  >
                    <img
                      alt="Auth0 logo"
                      width="25px"
                      src="/images/png/logos/Auth0.png"
                      style={{ opacity: auth0Status ? 1 : 0.3 }}
                    />
                  </MDBox>
                </Button>
              </Tooltip>
            </MDTypography>
          </Stack>
          <Stack item flexGrow={1} />
          <Stack item>
            {hasDeleteButton && isAdmin(userType) && (
              <Button
                variant="text"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteModal}
                style={{ color: "red" }}
                disabled={isSubmitting}
                sx={{ padding: "0.5rem 1rem" }}
              >
                Delete User
              </Button>
            )}
          </Stack>
          <Stack item>
            <MDBox display="flex" alignItems="center">
              {isModified && isAdmin(userType) && (
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  disabled={isSubmitting}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Cancel
                </Button>
              )}
              {isModified && isAdmin(userType) && (
                <Button
                  type="submit"
                  variant="text"
                  startIcon={<SaveIcon />}
                  style={{ color: "#4CAF50" }}
                  disabled={isSubmitting}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Save
                </Button>
              )}
            </MDBox>
          </Stack>
        </Stack>
        <StyledContainer px={5}>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Autocomplete
                options={userStatusField || []}
                value={getValues("status") || ""}
                onChange={(e, value) => setValue("status", value, { shouldDirty: true })}
                renderInput={(params) => (
                  <>
                    <FormField
                      label="Status"
                      key={`status_${currentApplicant?._id}`}
                      {...params}
                      type="text"
                    />
                  </>
                )}
              />
              {errors?.status && (
                <StyledTypographyError> {errors?.status.message}</StyledTypographyError>
              )}
            </Grid>
            <Grid item sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date Created"
                  value={getValues("createdDate") || ""}
                  readOnly
                  onChange={(data) => {
                    setValue("createdDate", new Date(data), { shouldDirty: true });
                  }}
                  renderInput={(params) => <FormField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Effective Start Date"
                  value={getValues("startDate") || ""}
                  onChange={(date) => {
                    setValue("startDate", new Date(date), { shouldDirty: true });
                  }}
                  renderInput={(params) => <FormField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Effective End Date"
                  value={getValues("endDate") || ""}
                  onChange={(date) => {
                    setValue("endDate", new Date(date), { shouldDirty: true });
                  }}
                  renderInput={(params) => <FormField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={6}>
              <FormField
                name="emailAddress"
                label="Update Email Address"
                type="text"
                value={getValues("emailAddress")}
                onChange={(e) => setValue("emailAddress", e.target.value, { shouldDirty: true })}
              />
              {errors?.emailAddress && (
                <StyledTypographyError> {errors?.emailAddress.message}</StyledTypographyError>
              )}
            </Grid>
            <Grid item sm={6}>
              {!isCompany && (
                <>
                  <Autocomplete
                    options={employeeTypes || []}
                    value={getValues("employeeType") || ""}
                    onChange={(e, value) => setValue("employeeType", value, { shouldDirty: true })}
                    renderInput={(params) => (
                      <>
                        <FormField
                          label="Employee Type"
                          key={`employeeType_${currentApplicant?._id}`}
                          {...params}
                          type="text"
                        />
                      </>
                    )}
                  />
                  {errors?.employeeType && (
                    <StyledTypographyError> {errors?.employeeType.message}</StyledTypographyError>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {showVerifiedFlags && (
            <Grid container spacing={8} py={3}>
              <Grid item sm={4} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="body2">Email Verified</MDTypography>
                <Switch
                  disabled
                  checked={watch("emailVerified") === "Yes"}
                  onChange={(e) => setValue("emailVerified", e.target.checked ? "Yes" : "No")}
                  inputProps={{ tabIndex: -1, "aria-label": "" }}
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                  }}
                />
              </Grid>
              <Grid item sm={4} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="body2">Login Verified</MDTypography>
                <Switch
                  disabled
                  checked={watch("loginVerified") === "Yes"}
                  onChange={(e) => setValue("loginVerified", e.target.checked ? "Yes" : "No")}
                  inputProps={{ tabIndex: -1, "aria-label": "" }}
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                  }}
                />
              </Grid>
              {currentLoggedUser?.userType === "Master" && user?.userType !== "User" && (
                <Grid item sm={2} display="flex" alignItems="center">
                  <Tooltip
                    title={`Email ${user?.emailAddress} is ${userIdentityVerification} for SES Email`}
                  >
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography
                        variant="body2"
                        fontWeight={
                          userIdentityVerification === "verified" ||
                          userIdentityVerification === "submitted"
                            ? "bold"
                            : "light"
                        }
                        color={identityVerificationColor}
                      >
                        Identity Verified
                      </MDTypography>
                      <Switch
                        classes={{
                          switchBase: classes.switchBase,
                          thumb: classes.thumb,
                        }}
                        color={identityVerificationColor}
                        checked={
                          userIdentityVerification === "verified" ||
                          userIdentityVerification === "submitted"
                        }
                        onChange={(e) => {
                          if (
                            userIdentityVerification === "verified" ||
                            userIdentityVerification === "submitted"
                          )
                            toggleConfirmationModal(true);
                          else {
                            handleUserIdentityUpdate();
                          }
                        }}
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}
          <Grid container spacing={4} py={3}>
            <Grid item xs={6}>
              <Button variant="contained" fullWidth onClick={handlePasswordReset}>
                Reset Password
              </Button>
            </Grid>
          </Grid>
          <Grid mb={5}>
            <MDTypography color="blue" fontWeight="bold" variant="body2" sx={{ pb: 3 }}>
              Login History
            </MDTypography>
            <ProfileActivities
              currentId={user?.applicantId}
              filter="User Login"
              height={220}
              tableHeight={200}
            />
          </Grid>
        </StyledContainer>
      </form>
      <ConfirmModal
        title={confirmModal.title}
        description={confirmModal.description}
        positiveBtn={confirmModal.positiveBtn}
        negativeBtn={confirmModal.negativeBtn}
        isOpen={confirmModal.isOpen}
        closeFn={handleCloseModal}
      />
      {auth0UserConfirmModalOpen && (
        <Auth0UserConfirmModal
          user={user}
          setAuth0Status={setAuth0Status}
          auth0Status={auth0Status}
          modalOpen={auth0UserConfirmModalOpen}
          setModalOpen={setAuth0UserConfirmModalOpen}
        />
      )}
    </>
  );
};

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

const StyledContainer = styled(MDBox)(() => ({
  height: 560,
  overflowY: "auto",
}));

export default PartnerUserForm;
