import { useEffect, useState } from "react";
import moment from "moment";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import useSessionAuth from "hooks/useSessionAuth";
import PictureAvatar from "components/PictureAvatar";
import MyTasksActions from "./MyTasksActions";

const TaskRow = ({
  row,
  setCurrentTask,
  setTaskModalOpen,
  setTaskMode,
  setMessageModalOpen,
  setMessageModalInfo,
}) => {
  const [user, setUser] = useState(null);
  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const getUser = async (id) => {
      const fetchedUser = await fetchUserById(id);
      if (fetchedUser) {
        setUser(fetchedUser);
      }
    };
    if (row?.assignedByName?.userId) {
      try {
        getUser(row?.assignedByName?.userId);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [row?.assignedByName?.userId]);

  return (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap">
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={12} md={7} sx={{ display: "flex", gap: "10px" }}>
          <PictureAvatar
            image={row?.assignedByName?.profileImg}
            firstName={row?.assignedByName?.firstName}
            lastName={row?.assignedByName?.lastName}
            userId={row?.assignedByName?.userId}
            size="md"
          />
          <Grid container alignItems="center" key={row?.eventUrl}>
            <Grid item xs={5}>
              <MDTypography variant="subtitle2">
                {" "}
                {row?.assignedByName?.firstName} {row?.assignedByName?.lastName}
              </MDTypography>
            </Grid>
            <Grid item xs={7}>
              <MDTypography variant="body2">
                {moment(row.createDate).format("ddd MM/DD/YYYY")}
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body2">{row.taskName}</MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Grid container flexDirection="column" alignItems="flex-end">
            <Grid item>
              <MyTasksActions
                row={row}
                setCurrentTask={setCurrentTask}
                setTaskModalOpen={setTaskModalOpen}
                setTaskMode={setTaskMode}
                setMessageModalOpen={setMessageModalOpen}
                setMessageModalInfo={setMessageModalInfo}
                assignedBy={user}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default TaskRow;
