// hooks
import { useState } from "react";
import { useForm } from "react-hook-form";

// store/api/helper
import { useAppContextController } from "context/AppContext";

// style
import { Box, Divider, IconButton, Stack } from "@mui/material";
import { AddCircleRounded, Assessment, PlayCircle, Save } from "@mui/icons-material";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";

// components
import MDTypography from "components/MDTypography";
import CustomCard from "components/CustomCard";
import PanelActions from "components/PanelActions";
import ReportDetails from "./ReportDetails";
import StatModal from "./StatModal";

const daysOpen = 30;
const title = "This Week's Activity";
const purple = "#5757FF";
const stats = [
  {
    name: "New Applies",
    number: 10,
  },
  {
    name: "Submittals",
    number: 5,
  },
  {
    name: "Interviews",
    number: 2,
  },
  {
    name: "Fills",
    number: 0,
  },
];

const JobsReportPanel = ({ name, displayPosition = false }) => {
  const { isMobile } = useAppContextController();
  const [statModalOpen, setStatModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm({
    // resolver,
  });

  const onSubmit = () => console.log("submit");

  const handleCancelChanges = () => {
    // temp, lacking
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Assessment color="white" />}
        cardTitle={`Job Report Data: ${name}`}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "info")}
      >
        <Stack spacing={2} sx={styles.container}>
          <Stack
            direction="row"
            justifyContent={displayPosition ? "space-between" : "flex-end"}
            alignItems="center"
          >
            <MDTypography variant="h5" textAlign="right">
              <span style={{ fontSize: "1.75rem", color: "#E28700" }}>{daysOpen}</span> Days Open
            </MDTypography>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            <IconButton color="success" onClick={() => null} sx={{ padding: 0 }}>
              <Save />
            </IconButton>
            <IconButton color="info" onClick={() => null} sx={{ padding: 0 }}>
              <AddCircleRounded />
            </IconButton>
          </Stack>
          <Divider sx={{ bgcolor: "gray" }} />
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => null} sx={{ color: purple, padding: 0 }}>
              <PlayCircle sx={{ transform: "scaleX(-1)" }} />
            </IconButton>
            <MDTypography textAlign="center" fontWeight="medium">
              {title}
            </MDTypography>
            <IconButton onClick={() => null} sx={{ color: purple, padding: 0 }}>
              <PlayCircle />
            </IconButton>
          </Stack>
          <Divider sx={{ bgcolor: "gray" }} />
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            {stats?.map((stat) => (
              <Box
                key={stat?.name}
                width={200}
                px={2}
                py={1}
                borderRadius={2}
                sx={{
                  backgroundColor: purple,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModalTitle(stat?.name);
                  // setModalData(); then change below data
                  setStatModalOpen(true);
                }}
              >
                <MDTypography color="white" fontWeight="medium">
                  {stat?.number}
                </MDTypography>
                <MDTypography color="white" fontSize="1rem">
                  {stat?.name}
                </MDTypography>
              </Box>
            ))}
          </Stack>
          <ReportDetails />
        </Stack>
      </CustomCard>
      <StatModal
        open={statModalOpen}
        setOpen={setStatModalOpen}
        title={modalTitle}
        data={[
          {
            name: "mon",
            value: 2,
          },
          {
            name: "tue",
            value: 2,
          },
          {
            name: "wed",
            value: 0,
          },
          {
            name: "thu",
            value: 1,
          },
          {
            name: "fri",
            value: 3,
          },
          {
            name: "sat",
            value: 0,
          },
          {
            name: "sun",
            value: 2,
          },
        ]}
      />
    </form>
  );
};

export default JobsReportPanel;
