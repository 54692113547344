import { useState, useEffect } from "react";
import useCompanyStore from "stores/useCompanyStore";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import useAttachmentUpload from "hooks/useAttachmentUpload";
import updateCompany from "api/companies/updateCompany";

import {
  Image, // Movie
} from "@mui/icons-material";
import { Box } from "@mui/material";
import CustomCard from "components/CustomCard";
import MDTypography from "components/MDTypography";
import FileDropzone from "components/Dropzone";
import ImageUploadDropzone from "layouts/pages/companies/components/ImageUploadDropzone";
import Attachments from "layouts/pages/companies/components/CompanyAttachment";
import { cardStyles as styles } from "layouts/pages/customers/styles";

const CustomerMedia = () => {
  const { currentCompany: company, setCurrentCompany: setCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const [attachmentsData, setAttachmentsData] = useState([]);
  const queryClient = useQueryClient();
  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCompany = (updatedValues) => {
    updateCompanyMutation(
      { companyId: company?._id, body: updatedValues },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("companies");
          setCompany({ ...company, ...updatedValues });
          enqueueSnackbar("Customer has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };

  const { onFileDropHandler } = useAttachmentUpload({
    data: attachmentsData,
    name: "attachments",
    onUpdate: handleUpdateCompany,
    currentData: company,
  });

  const refetchCustomers = async () => {
    await queryClient.invalidateQueries("customers_data");
  };

  const url = `/upload/company/${company?.slug}/`;

  const mediaSections = [
    {
      title: "Customer Banner",
      listName: "bannerUrls",
      attribute: "bannerUrl",
      selectedImg: company?.bannerUrl, // company?.images?.bannerUrl
      typeOfUpload: "banner",
    },
    {
      title: "Customer Background",
      listName: "backgroundImageUrls",
      attribute: "backgroundImageUrl",
      selectedImg: company?.backgroundImageUrl, // company?.images?.backgroundImageUrl
      typeOfUpload: "backgroundImage",
    },
    {
      title: "Customer Logo",
      listName: "logoUrls",
      attribute: "logoUrl",
      selectedImg: company?.logoUrl, // company?.images?.logoUrl
      typeOfUpload: "logo",
    },
    {
      title: "Customer Square Logo",
      listName: "squareLogoUrls",
      attribute: "squareLogoUrl",
      selectedImg: company?.squareLogoUrl, // company?.images?.squareLogoUrl
      typeOfUpload: "square-logo",
    },
  ];

  useEffect(() => {
    if (company?.attachments) {
      setAttachmentsData(company?.attachments);
    } else {
      setAttachmentsData([]);
    }
  }, [company]);

  return (
    <CustomCard
      icon={<Image color="white" />}
      iconContainerSx={{ backgroundColor: "gray", ...styles.icon }}
      cardTitle={`Image Gallery: ${company?.name}`}
      cardSx={styles.card}
      titleSx={styles.title}
    >
      <Box sx={styles.container}>
        {mediaSections.map((section) => (
          <Box key={`${section.attribute}_${section.listName}`}>
            <ImageUploadDropzone
              company={company}
              setCompany={setCompany}
              refetchCompany={refetchCustomers}
              title={section.title}
              listName={section.listName}
              selectedImg={section.selectedImg}
              typeOfUpload={section.typeOfUpload}
              attribute={section.attribute}
              componentType="company"
              apiUrl={`${url}${section.typeOfUpload}`}
              isPanel
            />
          </Box>
        ))}
        <Box mb={0}>
          <MDTypography variant="h6" color="dark" sx={{ my: 1 }}>
            Attachments
          </MDTypography>
          <FileDropzone message="Drop files here to upload" onDrop={onFileDropHandler} />
          {attachmentsData && (
            <Attachments isShownLabel={false} company={company} setCompany={setCompany} />
          )}
        </Box>
      </Box>
    </CustomCard>
  );
};

export default CustomerMedia;
