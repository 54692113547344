import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import generateFiltersFromString from "utils/generateFiltersFromString";
import generateFilterString from "utils/generateFilterString";

const useGetBackButton = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const backParam = searchParams.get("backTo");
  const backToTitle = backParam ? backParam.split("#")[0] : "";
  const backToUrl = backParam ? backParam.split("#")[1] : "";

  const backButton = useMemo(
    () => (
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          navigate(backToUrl);
        }}
      >
        {`Back to ${backToTitle}`}
      </Button>
    ),
    [backToTitle, backToUrl, navigate]
  );

  return {
    backParam,
    backToTitle,
    backToUrl,
    backButton,
  };
};

export default useGetBackButton;
