import { Typography } from "@mui/material";
import CustomModal from "components/CustomizedMui/CustomModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";

const MessageModal = ({ toggleOpen, isOpen, message, sx }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => toggleOpen(false)}
      cardSx={{ maxWidth: "500px", ...sx }}
    >
      <FlexWrapper rowGap="20px">
        <Typography variant="h4" textAlign="center">
          {message}
        </Typography>
      </FlexWrapper>
    </CustomModal>
  );
};

export default MessageModal;
