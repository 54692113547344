import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import { useAppContextController } from "context/AppContext";
import PropTypes from "prop-types";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import ProfileInfo from "layouts/pages/profile/components/ProfileInfo";
import UsersFilterCards, { initialCards } from "layouts/pages/users/components/UsersFilterCards";
import UsersTable from "layouts/pages/users/components/UsersTable";
// import UsersInformation from "layouts/pages/users/components/UsersInfo";
import useSessionAuth from "hooks/useSessionAuth";
import { useQuery } from "react-query";
import { Alert, Snackbar } from "@mui/material";

function Users({ mode = "edit" }) {
  const navigate = useNavigate();

  const { userType, currentUser, setCurrentUser, currentApplicant, setCurrentApplicant, company } =
    useAppContextController();
  const { id, action } = useParams();
  const [actionSelected, setActionSelected] = useState(action || "");
  const [page, setPage] = useState(1);
  const { logoutAuth0User } = useSessionAuth();

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const [filters, setFilters] = useState({});

  const { isLoading: isUserLoading, isSuccess: isUserSuccess } = useQuery(
    ["getUserById", id],
    () => fetchUserById(id),
    {
      enabled: !!id,
      onSuccess: async (data) => {
        try {
          setCurrentUser(data);
          setCurrentApplicant(data?.applicant);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      },
    }
  );

  useEffect(() => {
    if (id && !filters._id && action) {
      setFilters({ _id: id });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }
  }, [id, action, actionSelected, currentUser]);

  // Default Filter Card
  useEffect(() => {
    if ((isEmpty(filters) || (!id && filters._id)) && company?.companyType) {
      const initialCardsLocal = cloneDeep(initialCards);
      if (company?.companyType === "Company") {
        initialCardsLocal[2].default = false;
        initialCardsLocal[4].default = true;
      }
      const { filterField, filterValue } = initialCardsLocal.find((card) => card.default);
      if (filterField && filterValue) {
        setFilters(() => ({ [filterField]: filterValue }));
      }
    }
  }, [filters, id, company?.companyType]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <UsersFilterCards setFilters={setFilters} setPage={setPage} />
          <UsersTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Users"
            page={page}
            setPage={setPage}
            setToastAlert={setToastAlert}
          />
        </Grid>
        {["userinfo", "userall", ""].includes(actionSelected) && (id || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <ProfileInfo
              mode={mode}
              userType={userType}
              currentApplicant={currentApplicant}
              setCurrentApplicant={setCurrentApplicant}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              sourceComponent="user"
              setFilters={setFilters}
            />
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Users.defaultProps = {
  mode: "edit",
};

// Typechecking props
Users.propTypes = {
  mode: PropTypes.string,
};
export default Users;
