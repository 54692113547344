
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import updateUser from "layouts/pages/users/actions/updateUser";
import { EMPLOYEE_STATUS_ENUM, CLIENT_STATUS_ENUM } from "utils/constants/applicant";


const statusColor = {
    "Event Staff": "dark",
    "Event Admin": "info",
    "Inactive": "dark",
    "Active": "info",
};

const EmployeeStatusButton = ({ applicantUser, setApplicantUser, venue, onUpdate }) => {
    const { currentApplicant } = useAppContextController();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const organization = applicantUser?.clientOrgs?.find((org) => org.slug === venue.slug)


    const clientOrgStatus = useMutation(updateUser, {
        onSuccess: async (_, { data }) => {
            const newApplicantUser = { ...applicantUser };
            newApplicantUser.clientOrgs = data?.clientOrgs
            setApplicantUser(newApplicantUser)
        },

    });


    const handleUpdate = useCallback(
        (newStatus) => {

            const { slug } = venue;
            const newCLientOrg = {
                slug,
                userType: "Admin",
                status: newStatus,
                primary: "Yes",
                dateModified: new Date().toISOString(),
            }


            const orgIndex = applicantUser?.clientOrgs.findIndex((clientOrg) => clientOrg?.slug === slug)
            const newClientOrgs = [...applicantUser?.clientOrgs]
            if (orgIndex !== -1) {
                newClientOrgs[orgIndex] = newCLientOrg
            }
            else {
                newClientOrgs.push(newCLientOrg)
            }

            clientOrgStatus.mutate(
                {
                    userId: applicantUser.userId, data: { clientOrgs: newClientOrgs }
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar(
                            applicantUser === "Client" ?
                                `${currentApplicant.firstName} ${currentApplicant.lastName} has been set to: ${newStatus === "Event Admin" ? "Active" : "Inactive"} for ${venue.slug}` :
                                `${currentApplicant.firstName} ${currentApplicant.lastName} has been set to: ${newStatus} for ${venue.slug}`,
                            { variant: statusColor[newStatus] }
                        );

                        onUpdate?.();
                    },
                }
            );
        },
        [applicantUser, venue, clientOrgStatus, enqueueSnackbar, onUpdate, currentApplicant.firstName, currentApplicant.lastName]
    );

    const options = [
        {
            text: `${applicantUser?.userType === "Client" ? "Inactive" : "Event Staff"
                }`,
            icon: <SupervisorAccountIcon fontSize="medium" color={statusColor[applicantUser?.userType === "Client" ? CLIENT_STATUS_ENUM.INACTIVE : EMPLOYEE_STATUS_ENUM.EVENT_STAFF]} />,
            handleClick: () => handleUpdate(applicantUser?.userType === "Client" ? CLIENT_STATUS_ENUM.INACTIVE : EMPLOYEE_STATUS_ENUM.EVENT_STAFF),
            disabled: (applicantUser?.userType === "Client" ? CLIENT_STATUS_ENUM.INACTIVE : EMPLOYEE_STATUS_ENUM.EVENT_STAFF) === organization?.status,
        },
        {
            text: `${applicantUser?.userType === "Client" ? "Active" : "Event Admin"
                }`,
            icon: (
                <SupervisorAccountIcon fontSize="medium" color={statusColor[applicantUser?.userType === "Client" ? CLIENT_STATUS_ENUM.ACTIVE : EMPLOYEE_STATUS_ENUM.EVENT_ADMIN]} />
            ),
            handleClick: () => handleUpdate(applicantUser?.userType === "Client" ? CLIENT_STATUS_ENUM.ACTIVE : EMPLOYEE_STATUS_ENUM.EVENT_ADMIN),
            disabled: (applicantUser?.userType === "Client" ? CLIENT_STATUS_ENUM.ACTIVE : EMPLOYEE_STATUS_ENUM.EVENT_ADMIN) === organization?.status,
        },

    ];

    return (
        <>
            <Tooltip
                title={`${currentApplicant?.firstName} ${currentApplicant?.lastName} is ${organization?.status} `}
            >
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);

                    }}

                >
                    <SupervisorAccountIcon fontSize="large" color={statusColor[organization?.status]} />
                </IconButton>
            </Tooltip>
            <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
        </>
    );
};

export default EmployeeStatusButton;
