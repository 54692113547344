import { Tooltip } from "@mui/material";

import {
  Person,
  AccountBalance,
  AccountCircle,
  Badge,
  CheckCircle,
  PersonAdd,
  RequestPage,
  ThumbUp,
  Group,
} from "@mui/icons-material";

const CommonIconProps = {
  fontSize: "large",
  color: "warning",
};

const OnboardingStatus = ({ applicant, size = "md", useAltIcons }) => {
  const ONBOARDING_STEPS = [
    { id: 1, label: "Job Applicant", icon: <PersonAdd {...CommonIconProps} /> },
    {
      id: 2,
      label: "U.S. I-9 Form",
      applicantObject: "i9Form",
      icon: <AccountCircle {...CommonIconProps} />,
    },
    {
      id: 3,
      label: `Upload ID's`,
      applicantObject: "attachments",
      icon: <Badge {...CommonIconProps} />,
    },
    {
      id: 4,
      label: "W-4 Tax Forms",
      applicantObject: "w4Tax",
      icon: <RequestPage {...CommonIconProps} />,
    },
    {
      id: 5,
      label: "Direct Deposit",
      applicantObject: "directDeposit",
      icon: <AccountBalance {...CommonIconProps} />,
    },
    {
      id: 6,
      label: "Acknowledgement",
      applicantObject: "acknowledged",
      icon: <ThumbUp {...CommonIconProps} />,
    },
    {
      id: 7,
      label: "Complete!",
      icon: <CheckCircle {...CommonIconProps} />,
    },
  ];

  let iconImage = null;
  let label = null;

  if (applicant?.status === "Employee") {
    iconImage = useAltIcons ? (
      <Group fontSize="large" color="success" />
    ) : (
      <Person color="error" fontSize="large" />
    );
    label = "Employee";
  } else {
    for (let i = ONBOARDING_STEPS.length - 1; i > -1; i -= 1) {
      const item = ONBOARDING_STEPS[i];
      if (
        item.applicantObject &&
        applicant?.[item.applicantObject] &&
        (applicant[item.applicantObject].length ||
          Object.keys(applicant[item.applicantObject])?.length)
      ) {
        if (
          item?.applicantObject &&
          item?.applicantObject === "attachments" &&
          applicant?.i9Form &&
          Object.keys(applicant.i9Form)?.length
        ) {
          iconImage = item.icon;
          label = item.label;
          break;
        } else if (item?.applicantObject !== "attachments") {
          iconImage = item.icon;
          label = item.label;
          break;
        }
      }
    }
  }

  if (!iconImage) {
    iconImage = ONBOARDING_STEPS[0].icon;
    label = ONBOARDING_STEPS[0].label;
  }

  return <Tooltip title={label}>{iconImage}</Tooltip>;
};

export default OnboardingStatus;
