// Filter String Generator according to API requirement
const generateFilterString = (filtersObject) => {
  const filtersArray = [];
  if (filtersObject) {
    Object.keys(filtersObject).forEach((key) => {
      let filterString = `${key}:${filtersObject[key]}`;
      if (key === "tags") {
        filterString = `${key}:${filtersObject[key].replaceAll(":", "_")}`;
      }
      filtersArray.push(filterString);
    });
    return filtersArray.join(",");
  }
  return null;
};

export default generateFilterString;
