import { useEffect, useState, useRef } from "react";

import { useMapsLibrary, Marker } from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import "./CustomMap.css";

const RosterMarkers = ({
  map,
  roster,
  currentApp,
  onAvatarIn,
  onAvatarOut,
  getPosition,
  checkVisibility,
  isInfoClicked,
  setInfoClicked,
}) => {
  const [markers, setMarkers] = useState({});
  const core = useMapsLibrary("core");
  const clusterer = useRef(null);

  const getColor = (ros) => {
    let color = "";
    if (ros?.timeIn) color = "_green";
    if (ros?.timeOut) color = "_red";
    return color;
  };

  // Initialize MarkerClusterer
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update markers
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      }
      const newMarkers = { ...prev };
      delete newMarkers[key];
      return newMarkers;
    });
  };

  return (
    <>
      {roster.map((ros) => (
        <Marker
          key={ros?.name}
          ref={(marker) => setMarkerRef(marker, ros.name)}
          visible={checkVisibility(ros)}
          draggable={false}
          position={getPosition(ros)}
          onMouseOver={() => onAvatarIn(ros)}
          onMouseOut={() => {
            if (!isInfoClicked) onAvatarOut();
          }}
          onClick={() => {
            setInfoClicked(true);
            onAvatarIn(ros);
          }}
          icon={{
            url: `${ros?.profileImg}#custom_marker${getColor(ros)}`,
            scaledSize:
              ros?.id === currentApp?.id ? new core.Size(100, 100) : new core.Size(50, 50),
          }}
          zIndex={ros?.id === currentApp?.id ? 3 : 2}
        />
      ))}
    </>
  );
};

export default RosterMarkers;
