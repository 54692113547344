import { FormControlLabel, Switch, Typography } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { cloneDeep, range } from "lodash";
import { useEffect, useMemo, useState } from "react";

const ApplicantExportFields = ({ fields, setFields }) => {
  const [selectAll, toggleSelectAll] = useState(true);

  const { userType } = useAppContextController();
  const isNotClient = userType !== "Client" && userType !== "Event Admin";
  const applicantFields = useMemo(
    () => [
      ...(isNotClient
        ? [
            {
              name: "employeeID",
              label: "Employee ID",
            },
          ]
        : []),
      {
        name: "employmentStatus",
        label: "Employment Status",
      },
      {
        name: "primaryPosition",
        label: "Position",
      },
      {
        name: "address1",
        label: "Address 1",
      },
      {
        name: "notes",
        label: "Notes",
      },
      {
        name: "birthDate",
        label: "Birth Date",
      },
      {
        name: "city",
        label: "City",
      },
      {
        name: "firstName",
        label: "Employee First Name",
      },
      {
        name: "lastName",
        label: "Employee Last Name",
      },
      {
        name: "phone",
        label: "Employee Phone Number",
      },
      {
        name: "email",
        label: "Employee Email Address",
      },
      ...(isNotClient
        ? [
            {
              name: "rank",
              label: "Employee Rank",
            },
          ]
        : []),
      {
        name: "hireDate",
        label: "Hire Date",
      },
      {
        name: "birthDate",
        label: "Birth Date",
      },
    ],
    [isNotClient]
  );

  const handleChange = (e, name) => {
    toggleSelectAll(Object.values({ ...fields, [name]: e.target.checked }).every((v) => v));
    setFields({
      ...fields,
      [name]: e.target.checked,
    });
  };

  useEffect(() => {
    if (fields) {
      toggleSelectAll(Object.values(fields).every((v) => v));
    }
  }, [fields]);

  useEffect(() => {
    setFields(applicantFields.reduce((a, v) => ({ ...a, [v.name]: true }), {}));
  }, [setFields]);

  return (
    <FlexWrapper justifyContent="flex-start" flexDirection="column" alignItems="start" gap="10px">
      <Typography sx={{ fontSize: "1rem" }}>Select Export Fields</Typography>
      <FlexWrapper flexWrap="nowrap">
        {range(3).map((v) => {
          return (
            <FlexWrapper flexDirection="column" alignItems="start" justifyContent="start">
              {applicantFields.slice(v * 4, v * 4 + 4).map((value) => {
                return (
                  <FormControlLabel
                    key={`label_${value?.name}`}
                    control={
                      <Switch
                        checked={!!fields?.[value.name]}
                        onChange={(e) => handleChange(e, value.name)}
                      />
                    }
                    label={value.label}
                  />
                );
              })}
            </FlexWrapper>
          );
        })}
      </FlexWrapper>
      <FormControlLabel
        control={
          <Switch
            checked={selectAll}
            onChange={(e) => {
              const updatedFields = cloneDeep(fields);
              Object.keys(fields).forEach((item) => {
                updatedFields[item] = e.target.checked;
              });
              setFields({ ...fields, ...updatedFields });
              toggleSelectAll(e.target.checked);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Select All"
        sx={{ alignSelf: "end" }}
      />
    </FlexWrapper>
  );
};

export default ApplicantExportFields;
