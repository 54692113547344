import { Grid, Button } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";

import createUserAccount from "layouts/pages/users/actions/createUserAccount";
import getBrowserAndOS from "utils/getBrowserAndOS";

const UserPanelCreate = ({ currentApplicant }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLoggedUser } = useAppContextController();

  const createUserAccountMutation = useMutation(createUserAccount, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Login invite has been sent!", { variant: "success" });
    },
  });

  const getSendData = () => {
    const { os, browser } = getBrowserAndOS();
    const { _id } = currentApplicant;
    const agentId = currentLoggedUser?._id;

    const details = {
      device: os,
      browser,
      createAgent: agentId,
      userId: agentId,
    };

    return {
      applicantId: _id,
      data: details,
    };
  };

  const handleCreate = () => createUserAccountMutation.mutateAsync(getSendData());

  return (
    <MDBox px={5}>
      <MDTypography color="blue" fontWeight="bold" variant="body2" py={3}>
        User Account Information
      </MDTypography>
      <Grid container spacing={8} pb={5}>
        <Grid item xs={6}>
          <Button variant="contained" onClick={handleCreate} fullWidth>
            Create User
          </Button>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default UserPanelCreate;
