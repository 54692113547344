import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import ImgsViewer from "react-images-viewer";
import MDBox from "components/MDBox";
import Slider from "react-slick";
import { Pagination, Stack } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function VenuePhotoGallery({ imageUrls }) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [page, setPage] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const theme = useTheme();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    nav1.slickGoTo(newPage);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 750);
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const images = imageUrls?.map((image) => ({ src: image }));

  if (!images) {
    return (
      <MDBox p={3}>
        <h3 style={{ lineHeight: 1, marginBottom: 5, textAlign: "center", marginTop: "25%" }}>
          Load photos to see them here
        </h3>
      </MDBox>
    );
  }

  return (
    <MDBox>
      <ImgsViewer
        imgs={[...images]}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />
      <MDBox p={3}>
        <h3 style={{ lineHeight: 1, marginBottom: 5, textAlign: "center" }}>Venue Photo Gallery</h3>
      </MDBox>
      <Slider {...settings} asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
        {imageUrls?.map((step, i) => {
          const index = i;
          return (
            <div key={`div_${step?.label}_${index}`}>
              <MDBox
                component="img"
                sx={{
                  height: 255,
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  borderRadius: 5,
                  padding: 1,
                  objectFit: "contain",
                }}
                src={step}
                onClick={openImgsViewer}
              />
            </div>
          );
        })}
      </Slider>
      <Stack spacing={2} alignItems="center">
        <Pagination
          color="info"
          count={imageUrls?.length}
          page={page}
          disabled={disabled}
          onChange={handleChangePage}
        />
      </Stack>
    </MDBox>
  );
}

export default VenuePhotoGallery;
