import { useEffect, useRef } from "react"

const useIsMountedReferenced = () => {
    const ref = useRef(false);

    useEffect(() => {
        ref.current = true;

        return () => {
            ref.current = false;
        }
    }, []);

    return ref;
}

export default useIsMountedReferenced;