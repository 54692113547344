export const EVENT_ENTITIES = {
  EVENT_STATUS: "eventStatuses",
  EVENT_TYPES: "eventTypes",
  STATES: "stateCodes",
};
export const INTERVIEW_ENTITIES = {
  INTERVIEW_TYPES: "interviewTypes",
  INTERVIEW_STATUS: "eventStatuses",
  STATES: "stateCodes",
};

export const COMPANY_ENTITIES = {
  STATES_ENTITY_NAME: "stateCodes",
  STATUSES_ENTITY_NAME: "statuses",
  WORKWEEK_ENTITY_NAME: "workweek",
  OVERTIME_WARNINGS_ENTITY_NAME: "overtimeWarnings",
  CLOCKOUT_REMINDERS_ENTITY_NAME: "clockoutReminders",
  APPROVAL_REMINDERS_ENTITY_NAME: "approvalReminders",
  GEO_PREFERENCE_ENTITY_NAME: "geoPreference",
  PAY_FREQUENCY: "payFrequency",
  GROUP_IDS: "groupIds",
  WC_CODES: "wcCodes"
};
export const PARTNERS_ENTITIES = {
  STATES_ENTITY_NAME: "stateCodes",
  PARTNER_STATUS_ENTITY_NAME: "statuses",
};

export const APPLICANT_EMPLOYEE_ENTITIES = {
  APPLICANT_STATUS_ENTITY: "applicantStatusField",
  EMPLOYEE_STATUS_ENTITY: "employeeStatusField",
  APPLICANT_SOURCE_ENTITY: "referralSources",
  RANK_ENTITY: "ranks",
  PRIMARY_POSITION_ENTITY: "primaryPositions",
  EMPLOYMENT_STATUS_ENTITY: "employmentStatusesField",
  APPLICANT_STAGE_ENTITY: "applicantStageField",
  LICENSE_STATUS_ENTITY: "licenseStatuses",
  LICENSE_TYPES_ENTITY: "licenseTypes",
  STATES_ENTITY: "stateCodes",
  T_SHIRTS_SIZE_ENTITY: "tShirtSizes",
  COMPANY_EMPLOYMENT_STATUS_ENTITY: "companyEmploymentStatus",
  PAY_FREQUENCY: "payFrequency"
};
export const PARTNER_MEMBER_ENTITIES = {
  MEMBER_STATUS_ENTITY: "memberStatus",
  T_SHIRTS_SIZE_ENTITY: "tShirtSizes",
};
