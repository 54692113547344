import { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import DataList from "components/DataList";
import parse from "html-react-parser";
import GenericModal from "components/GenericModal";
import NotesRow from "layouts/pages/applicants/components/ApplicantNotes/components/NotesRow";
import MDTypography from "components/MDTypography";
import moment from "moment";

const useStyle = makeStyles({
  editor: {
    height: 150,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
});

const PartnerNotes = ({ currentPartner, setOpen }) => {
  const classes = useStyle();
  const [notes, setNotes] = useState([]);
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  useEffect(() => {
    if (currentPartner?.notes?.length) {
      setNotes(
        currentPartner?.notes
          .sort((a, b) => (a.date < b.date ? 1 : -1))
          .map((note) => {
            const { type, date, text, firstName, lastName } = note;
            return { type, date, text, firstName, lastName };
          })
      );
    } else setNotes([]);
  }, [currentPartner]);

  const addNew = (e) => {
    setOpen(true);
  };

  const viewAll = () => {
    setNotesModalOpen(true);
  };

  const header = (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="right" pr={3}>
        <Grid>
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>
        </Grid>
      </Stack>
    </>
  );
  const renderRow = (row) => <NotesRow row={row} />;

  const modalTitle = (
    <MDTypography
      variant="h5"
      color="dark"
      sx={{ mt: 2, mb: 1, ml: 2 }}
      // key={`note_title_${modalInfo.data.id}`}
    >
      Notes for {currentPartner?.name}
    </MDTypography>
  );

  const renderText = notes.map((note, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        <Grid container>
          <Grid item sm={3}>
            <MDTypography fontWeight="bold">
              {note.firstName} {note.lastName}
            </MDTypography>
          </Grid>
          <Grid item sm={4}>
            {moment(note.date).format("ddd MM/DD/yyyy hh:mm A")}
          </Grid>
          <Grid item sm={4}>
            {note.type}
          </Grid>
        </Grid>
        <p>{parse(note.text || "")}</p>
        <hr />
      </Fragment>
    );
  });

  const modalBody = (
    <Box mb={3} style={{ height: "21rem", overflow: "auto" }}>
      {renderText}
    </Box>
  );

  const okButton = (
    <Button variant="contained" style={{ color: "white" }} onClick={() => setNotesModalOpen(false)}>
      Ok
    </Button>
  );

  return (
    <>
      <Box mt={2.5}>
        <DataList
          renderRow={renderRow}
          data={notes}
          onRowClick={() => {}}
          icon="description"
          iconColor="secondary"
          title="Notes"
          isLoading={false}
          greybar
          divider
          header={header}
          tableHeight={800}
          height={860}
          scrollY
          computedHeight
        />
        <GenericModal
          open={notesModalOpen}
          setOpen={setNotesModalOpen}
          body={modalBody}
          header={modalTitle}
          buttons={okButton}
        />
      </Box>
    </>
  );
};

export default PartnerNotes;
