import axios from "axios";

const getApiUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return process.env.REACT_APP_API;
    case "local":
      return process.env.REACT_APP_API_LOCAL;
    case "staging":
      return process.env.REACT_APP_API_STAGING;
    case "development":
      return process.env.REACT_APP_API_DEVELOP;
    default:
      return process.env.REACT_APP_API;
  }
};

const getImageUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return process.env.REACT_APP_IMAGE_SERVER;
    case "local":
      return process.env.REACT_APP_IMAGE_SERVER_LOCAL;
    case "staging":
      return process.env.REACT_APP_IMAGE_SERVER_STAGING;
    case "development":
      return process.env.REACT_APP_IMAGE_SERVER_DEVELOP;
    default:
      return process.env.REACT_APP_IMAGE_SERVER;
  }
};

export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

export const API_URL = getApiUrl();

// export const WEB_URL = getWebUrl();

export const IMG_SERVER = getImageUrl();

const baseAxios = axios.create({
  baseURL: API_URL,
  // headers: { origin: "https://jobs.stadiumpeople.com" }, // TODO:  fix this up to be able to handle sterling
  withCredentials: true,
});

// baseAxios.interceptors.request.use(
//   (config) => {
//     console.log("Request sent to: ", config.url);
//     console.log("Request data: ", config.data);
//     return config;
//   },
//   (error) => {
//     console.error("Request error: ", error);
//     return Promise.reject(error);
//   }
// );

baseAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Response error: ", error);

    if (error.response) {
      console.log("Error data:", error.response.data);
      console.log("Error status:", error.response.status);
      if (error.response.status === 401) {
        // redirect user to login screen
        // window.location.href = "/";
      }
      console.log("Error headers:", error.response.headers);
    } else if (error.request) {
      console.log("Error request:", error.request);
    } else {
      console.log("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);

export { baseAxios };
