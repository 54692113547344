import { baseAxios } from "config";

const applicantEnableMerge = async ({
  id,
  agent,
  createAgent,
}) => {
  try {
    const res = await baseAxios.post(`/applicants/${id}/enableMerge`, {
      createAgent,
      agent,
    });
    return res;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default applicantEnableMerge;
