import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import moment from "moment";
import InterviewRowActions from "./InterviewRowActions";

const InterviewRow = ({ row, refresh, currentCustomer, showImage = true }) => {
  return (
    <>
      <Grid container spacing={3} alignItems="center" flexWrap="nowrap">
        {showImage && (
          <Grid item xs={12} sm={1}>
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.firstName}
              lastName={row?.lastName}
              userId={row?._id}
              size="md"
            />
          </Grid>
        )}
        <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
          <MDTypography variant="body2">
            {row.firstName} {row.lastName}
          </MDTypography>
        </Grid>
        <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
          <MDTypography variant="body2">
            {moment(row.interview?.eventDate).format("ddd MM/DD/YYYY hh:mm A")}
          </MDTypography>
        </Grid>
        <Grid item flexBasis="auto">
          <InterviewRowActions
            currentApplicant={row}
            refresh={refresh}
            currentCustomer={currentCustomer}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InterviewRow;
