import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Button, Checkbox, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GenericModal from "components/GenericModal";
import { Cancel, Save as SaveIcon } from "@mui/icons-material";

const WEEKDAYS_ORDER = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const WeeklyScheduleConfigurationModal = ({
  selectedShiftIndex,
  getValues,
  setValue,
  isWeeklyScheduleModalOpen,
  setWeeklyScheduleModalOpen,
  saveChanges,
}) => {
  const defaultSchedule = getValues(`shifts[${selectedShiftIndex}].defaultSchedule`) || {};
  const shiftRoster = getValues(`shifts[${selectedShiftIndex}].shiftRoster`) || [];

  // Filter valid schedule entries
  const validDays = Object.entries(defaultSchedule)
    .filter(([, { start, end }]) => start && end)
    .sort(([dayA], [dayB]) => WEEKDAYS_ORDER.indexOf(dayA) - WEEKDAYS_ORDER.indexOf(dayB));

  // State for rows
  const [rows, setRows] = useState([]);

  // Initialize rows on mount or when shiftRoster/defaultSchedule changes
  useEffect(() => {
    const newRows = shiftRoster.map((row) => {
      const rowData = { id: row._id, fullName: row.fullName }; // Use `_id` as the unique row ID
      validDays.forEach(([day]) => {
        const currentRoster =
          getValues(`shifts[${selectedShiftIndex}].defaultSchedule.${day}.roster`) || [];
        rowData[day] = currentRoster.includes(row._id);
      });
      return rowData;
    });
    setRows(newRows);
  }, [shiftRoster, defaultSchedule, selectedShiftIndex, getValues]);

  // If there are no valid days, hide the DataGrid
  if (validDays.length === 0) {
    return null;
  }

  // Helper to capitalize day names
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  // Checkbox handler
  const handleCheckboxChange = (day, userId, isChecked) => {
    const currentRoster =
      getValues(`shifts[${selectedShiftIndex}].defaultSchedule.${day}.roster`) || [];
    const updatedRoster = isChecked
      ? [...currentRoster, userId]
      : currentRoster.filter((rosterUser) => rosterUser !== userId);

    // Update form values
    setValue(`shifts[${selectedShiftIndex}].defaultSchedule.${day}.roster`, updatedRoster, {
      shouldDirty: true,
    });

    // Update local state for real-time updates
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === userId ? { ...row, [day]: isChecked } : row))
    );
  };

  // Columns for DataGrid
  const columns = [
    {
      field: "fullName",
      headerName: "Applicants",
      flex: 1,
      renderHeader: () => <Typography variant="h6">Applicants</Typography>,
    },
    ...validDays.map(([day, { start, end }]) => ({
      field: day,
      headerName: day, // Still needed for accessibility
      flex: 1,
      renderHeader: () => (
        <Box textAlign="center">
          <Typography variant="h6">{capitalizeFirstLetter(day.substring(0, 3))}</Typography>
          <Typography variant="body2">{moment(start).format("hh:mm A")}</Typography>
          <Typography variant="body2">{moment(end).format("hh:mm A")}</Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isChecked = params.value;
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Checkbox
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(day, params.row.id, e.target.checked)}
            />
          </Box>
        );
      },
    })),
  ];

  const header = (
    <Box display="flex" sx={{ mt: 2, mb: 1, ml: 2 }} justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" color="dark">
          Weekly Schedule Configuration for
        </Typography>
        &nbsp;
        <Typography variant="h5" color="info.main">
          {`${getValues(`shifts[${selectedShiftIndex}].shiftName`)} (${moment(
            getValues(`shifts[${selectedShiftIndex}].shiftStartDate`)
          ).format("MM/DD/YYYY")} - ${moment(
            getValues(`shifts[${selectedShiftIndex}].shiftEndDate`)
          ).format("MM/DD/YYYY")})`}
        </Typography>
      </Stack>
      <IconButton
        onClick={() => {
          setWeeklyScheduleModalOpen(false);
        }}
      >
        <Cancel />
      </IconButton>
    </Box>
  );

  const modalBody = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            headerHeight={80}
            sx={{
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f5f5",
              },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={9} />
      <Grid item sm={3}>
        <Grid container spacing={3} display="flex">
          <Grid item sm={6}>
            <Button
              type="button"
              variant="contained"
              color="error"
              style={{ color: "error" }}
              fullWidth
              onClick={() => setWeeklyScheduleModalOpen(false)}
            >
              Close
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              type="button"
              variant="contained"
              endIcon={<SaveIcon />}
              style={{ color: "white" }}
              sx={{ padding: "0.5rem 1rem" }}
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                saveChanges();
                setWeeklyScheduleModalOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <GenericModal
      open={isWeeklyScheduleModalOpen}
      setOpen={setWeeklyScheduleModalOpen}
      header={header}
      body={modalBody}
      width="60%"
      overflow="auto"
    />
  );
};

export default WeeklyScheduleConfigurationModal;
