import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  titleBar: { marginBottom: "15px" },

  titleBar_title: { my: 1, ml: 2 },

  section_images_wrapper: {
    overflowX: "auto",
    paddingTop: 10,
    whiteSpace: "nowrap",
  },

  positioner: {
    position: "absolute",
  },

  section_image_container: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },
  section_image_container2: {
    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
  },

  training_section_image_container: {
    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
  },

  section_image: {
    height: "100px",
  },

  training_section_image_delete: {
    position: "relative",
    left: "1000%",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  maps_section_image_delete: {
    position: "absolute",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  section_image_delete: {
    position: "absolute",
    right: "-9px",
    top: "-9px",
    backgroundColor: "#fff",
    width: "15px",
    height: "15px",
  },

  otherSection: {
    marginBottom: "24px",
  },
  section_images_wapper: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    padding: "24px 0px 24px 0px",
    maxWidth: "100%",
  },
});

export default useStyles;
