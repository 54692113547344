import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "300px",
  },
  list: {
    width: "70%",
    height: "280px",
    overflow: "scroll",
  },
  button: {
    margin: "8px",
  },
}));

const InviteAdditionalAttendeeModal = ({
  title,
  open,
  setOpen,
  selectedArray,
  setValue,
  objectField,
  setReminderToSaveChangesModalOpen,
  isEdit,
  filteredList
}) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const options = { fetchAll: true };
  const allOptions = { ...options, filters, order: "lastName", orderBy: "lastName" };
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [selectedLeftItem, setSelectedLeftItem] = useState(null);
  const [selectedRightItem, setSelectedRightItem] = useState(null);


  const sortUsers = (a, b) => {
    if (a.firstName > b.firstName) return 1;
    return -1;
  };
  filteredList.sort(sortUsers);

  const moveItem = (index, sourceList, targetList, direction) => {
    const source = [...sourceList];
    const target = [...targetList];
    const item = source.splice(index, 1);
    target.push(...item);
    source.sort();
    target.sort();
    if (direction === "left") {
      setLeftList(source);
      setRightList(target);
    } else {
      setRightList(source);
      setLeftList(target);
    }
  };

  const handleLeftItemClick = (index) => {
    setSelectedLeftItem(index);
    setSelectedRightItem(null);
  };

  const handleRightItemClick = (index) => {
    setSelectedRightItem(index);
    setSelectedLeftItem(null);
  };

  const handleButtonClick = (direction) => {
    if (direction === "left") {
      if (selectedLeftItem !== null) {
        moveItem(selectedLeftItem, leftList, rightList, direction);
        setSelectedLeftItem(null);
      }
    } else if (direction === "right") {
      if (selectedRightItem !== null) {
        moveItem(selectedRightItem, rightList, leftList, direction);
        setSelectedRightItem(null);
      }
    }
  };

  useEffect(() => {
    setRightList(selectedArray);
    const currentList = filteredList.filter((item) => {
      return !selectedArray?.find((e) => e.userId === item.userId);
    });
    setLeftList(currentList);
  }, [filteredList, selectedArray]);

  const header = (
    <>
      <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
        <MDTypography>{title || ""}</MDTypography>
        <MDBox display="flex" alignItems="center">
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            onClick={() => setOpen(false)}
            sx={{ padding: "0.5rem 1rem" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SaveIcon />}
            style={{ color: "white" }}
            sx={{ padding: "0.5rem 1rem" }}
            onClick={() => {
              if (!(JSON.stringify(selectedArray) === JSON.stringify(rightList))) {
                setValue(objectField, rightList, { shouldDirty: true });
                if(isEdit) setReminderToSaveChangesModalOpen(true);
              }
              setOpen(false);
            }}
          >
            OK
          </Button>
        </MDBox>
      </MDBox>
    </>
  );

  const modalBody = (
    <Box className={classes.root}>
      <Grid container>
        <Grid item sm={5}>
          <List className={classes.list}>
            {leftList?.map((item, index) => (
              <ListItemButton
                key={item?.userId}
                selected={index === selectedLeftItem}
                onClick={() => handleLeftItemClick(index)}
              >
                <ListItemText primary={`${item?.firstName} ${item?.lastName}`} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item sm={2} display="flex" p={3}>
          <Grid container direction="column">
            <Button
              className={classes.button}
              variant="contained"
              color="success"
              onClick={() => handleButtonClick("left")}
            >
              &gt;
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="error"
              onClick={() => handleButtonClick("right")}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={5}>
          <List className={classes.list}>
            {rightList?.map((item, index) => (
              <ListItemButton
                key={item?.userId}
                selected={index === selectedRightItem}
                onClick={() => handleRightItemClick(index)}
              >
                <ListItemText primary={`${item?.firstName} ${item?.lastName}`} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );

  const buttons = <></>;

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        sx={(theme) => ({
          width: "50% !important",
          maxWidth: "70% !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default InviteAdditionalAttendeeModal;
