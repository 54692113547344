import { baseAxios } from "config";

const updateApplicantJobStatus = async ({ applicantId, data }) => {
  try {
    const res = await baseAxios.put(`/applicants/${applicantId}/job/status`, data);
    return res.data;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export default updateApplicantJobStatus;
