import { Add, Check, HourglassTop } from "@mui/icons-material";

import { MEMBER_PARTNER_STATUS_ENUM } from "utils/constants/applicant";

const MemberPartnerStatusIcon = ({ status, size = "large" }) => {
  const renderIcon = () => {
    switch (status) {
      case MEMBER_PARTNER_STATUS_ENUM.ACTIVE:
        return <Check color="success" fontSize={size} />;
      case MEMBER_PARTNER_STATUS_ENUM.INACTIVE:
        return <Check color="warning" fontSize={size} />;
      default:
        return <Add color="info" fontSize={size} />;
    }
  };
  return renderIcon();
};

export default MemberPartnerStatusIcon;
