import { useState, useEffect } from "react";
import { useAppContextController } from "context/AppContext";
import { useAuth0 } from "@auth0/auth0-react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import { Card, Grid, Avatar, Box } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import colors from "assets/theme/base/colors";
// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/bg-mascots.png";
import MDAvatar from "components/MDAvatar";
import FileDropzone from "components/Dropzone";
import { makeStyles } from "@mui/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const { black } = colors;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "6px",
    backgroundColor: "rgba(48, 48, 48, 0.6)",
    padding: "5px 15px",
    cursor: "pointer",
  },
  text: { color: "white", fontSize: "0.875rem!important" },
});

const Header = ({
  children,
  currentUser,
  userBanner,
  onProfileImageChange,
  onCoverPhotoChange,
  onOpenBannerMenu,
  renderUserBanner,
}) => {
  const { userType, currentLoggedUser, company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const { user } = useAuth0();
  const [banner, setBanner] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  function stringAvatar(userName) {
    return {
      children: `${userName?.firstName[0]}${userName?.lastName[0]}`,
    };
  }

  // const avatar =
  //   currentLoggedUser?.profileImg && currentLoggedUser?._id ? (
  //     <MDAvatar
  //       src={`${IMAGE_SERVER}/users/${currentLoggedUser?._id}/photo/${currentLoggedUser?.profileImg}`}
  //       alt="profile-image"
  //       size="xxl"
  //     />
  //   ) : (
  //     <Avatar sx={{ bgcolor: black.light, width: 75, height: 75 }} {...stringAvatar(currentLoggedUser)} />
  //   );

  const customStyles = useStyles();

  useEffect(() => {
    if (IMAGE_SERVER && userBanner !== null) {
      if (userBanner?.startsWith("http")) {
        setBanner(userBanner);
      } else {
        setBanner(`${IMAGE_SERVER}/users/${currentUser?._id}/banner/${userBanner}`);
      }
    } else {
      setBanner(backgroundImage);
    }
  }, [userBanner, currentUser?._id, IMAGE_SERVER]);

  useEffect(() => {
    if (IMAGE_SERVER && currentUser?.profileImg !== null) {
      if (currentUser?.profileImg?.startsWith("http")) {
        setProfileImg(currentUser?.profileImg);
      } else {
        setProfileImg(`${IMAGE_SERVER}/users/${currentUser?._id}/photo/${currentUser?.profileImg}`);
      }
    } else {
      const image =
        user?.picture && user.email.indexOf("@gmail.com") > -1 ? user.picture : backgroundImage;
      setProfileImg(image);
    }
  }, [currentUser?.profileImg, currentUser?._id, IMAGE_SERVER, user.picture, user.email]);

  return (
    <MDBox position="relative">
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.1),
              rgba(gradients.info.state, 0.1)
            )}, url("${banner}")`, // url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "relative",
          mt: -13,
          px: 3,
        }}
      >
        <Grid item>
          <MDBox height="100%" mt={0.5} bgColor="white" borderRadius="100%" sx={{ padding: "4px" }}>
            <MDAvatar src={profileImg} alt="profile" size="xxl" />
          </MDBox>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={0.8}>
            <Grid item>
              <FileDropzone
                message="Edit Profile Photo"
                customStyles={customStyles}
                onDrop={onProfileImageChange}
                icon={
                  <CameraAltIcon
                    fontSize="small"
                    sx={{ color: "white!important", marginRight: 0.8 }}
                  />
                }
              />
            </Grid>
            <Grid item>
              {currentLoggedUser?.userType !== "User" ? (
                <FileDropzone
                  message="Edit Cover Photo"
                  customStyles={customStyles}
                  onDrop={onCoverPhotoChange}
                  icon={
                    <CameraAltIcon
                      fontSize="small"
                      sx={{ color: "white!important", marginRight: 0.8 }}
                    />
                  }
                />
              ) : (
                <>
                  <Box className={customStyles.container} onClick={onOpenBannerMenu}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}

                    <CameraAltIcon
                      fontSize="small"
                      sx={{ color: "white!important", marginRight: 0.8 }}
                    />

                    <MDTypography variant="p" className={customStyles.text}>
                      Edit Cover Photo
                    </MDTypography>
                  </Box>
                  {renderUserBanner()}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={6} lg={2} sx={{ ml: "auto" }}>
            <Tab
              label="Settings"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  settings
                </Icon>
              }
            />
          </Grid> */}
      </Grid>
    </MDBox>
  );
};

// Setting default props for the Header
// Header.defaultProps = {
//   children: "",
// };

// // Typechecking props for the Header
// Header.propTypes = {
//   children: PropTypes.node,
// };

export default Header;
