import { useState, useEffect, useMemo } from "react";

import axios from "axios";
import { baseAxios } from "config";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import Info from "@mui/icons-material/Info";
import Cancel from "@mui/icons-material/Cancel";

const InterviewModalActions = ({
  row,
  setNavigateUrl,
  selectedJob,
  onChangeInterviewStatus,
  currentApplicant,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLoggedUser } = useAppContextController();

  const applicantOnInterview = useMemo(
    () =>
      row?.applicants?.find((applicant) => {
        return applicant.id === currentApplicant._id;
      }),
    [currentApplicant._id, row?.applicants]
  );

  const addApplicant = async (eventUrl) => {
    const body = {
      action: "ADD",
      applicantIds: [currentApplicant?._id],
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };

    try {
      const res = await baseAxios.put(`/events/interview/eventUrl/${eventUrl}/applicants`, body);
      enqueueSnackbar("Applicant added to interview.", { variant: "success" });

      if (onChangeInterviewStatus) onChangeInterviewStatus("ADD");
      return res.data;
    } catch (err) {
      if (err.message === "Request failed with status code 404") return [];
      enqueueSnackbar("Error Encountered.", { variant: "error" });
      throw new Error(err.toString());
    }
  };
  const removeApplicant = async (eventUrl) => {
    const body = {
      action: "DELETE",
      applicantIds: [currentApplicant?._id],
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };

    try {
      const res = await baseAxios.put(`/events/interview/eventUrl/${eventUrl}/applicants`, body);
      enqueueSnackbar("Applicant removed from interview.", { variant: "success" });

      if (onChangeInterviewStatus) onChangeInterviewStatus("DELETE");
      return res.data;
    } catch (err) {
      if (err.message === "Request failed with status code 404") return [];
      enqueueSnackbar("Error Encountered.", { variant: "error" });
      throw new Error(err.toString());
    }
  };

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    switch (action) {
      case "addtointerview":
        addApplicant(row.eventUrl);
        break;
      case "interviewinfo":
        setNavigateUrl(`/events/${row.eventUrl}/action/eventsinfo`);
        break;
      case "removefrominterview":
        removeApplicant(row.eventUrl);
        break;
      default:
        break;
    }
    // TODO: add modal for 'usermail' and also 'usercomments'
  };

  return (
    <div style={{ textAlign: "end" }}>
      <IconButton
        color="info"
        disabled={!!applicantOnInterview}
        onClick={(e) => onClickHandler("addtointerview", e)}
        tooltip="Add Applicant to Interview"
      >
        <Add />
      </IconButton>
      &nbsp;&nbsp;
      <IconButton
        color="info"
        onClick={(e) => onClickHandler("interviewinfo", e)}
        tooltip="Interview Info"
      >
        <Info />
      </IconButton>
      &nbsp;&nbsp;
      <IconButton
        color="error"
        disabled={!applicantOnInterview}
        onClick={(e) => onClickHandler("removefrominterview", e)}
        tooltip="Remove Applicant from Interview"
      >
        <Cancel />
      </IconButton>
    </div>
  );
};

export default InterviewModalActions;
