import { useState } from "react";
import { Button, TextareaAutosize, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";

const useStyle = makeStyles({
  text: {
    color: "white",
    textAlign: "center",
  },
});

const EventRemovalReasonModal = ({ open, setOpen, onClick, isUnder48Hours }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [reason, setReason] = useState("");
  const classes = useStyle();
  // const [emptyReason, setEmptyReason] = useState(false)
  const header = (
    <MDTypography variant="h5" className={classes.text}>
      Send a message to the Event Manager with more information regarding call off:
    </MDTypography>
  );

  // const checkEmptyReason = () => {
  //     if (reason === "") {
  //         return true
  //     }
  //     return false
  // }

  const buttons = (
    <>
      <Button
        variant="contained"
        color="white"
        onClick={() => {
          // if (checkEmptyReason()) {
          //     setEmptyReason(true)
          // }
          // else {
          // setEmptyReason(false)
          onClick(!isUnder48Hours, reason);
          setOpen(false);
          // }
        }}
        sx={{
          textTransform: "none",
          color: "blue!important",
        }}
      >
        Submit message
      </Button>
      <Button
        variant="text"
        onClick={() => {
          onClick(!isUnder48Hours, "");
          setOpen(false);
        }}
        sx={{
          color: "white!important",
          textTransform: "none",
        }}
      >
        No thanks, continue
      </Button>
    </>
  );

  const modalBody = (
    <>
      <TextareaAutosize
        style={{ width: "100%", padding: "1rem" }}
        minRows={3}
        placeholder="[Send a personal note to your Event Manager]"
        value={reason || ""}
        onChange={(e) => {
          setReason(e.target.value);
        }}
      />
      {/* {emptyReason && <MDTypography className={classes.error} color="error" sx={{ fontSize: "0.75rem!important", }}>
                You have not written any message
            </MDTypography>} */}
    </>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        backgroundColor="blue"
        width={isSmallScreen ? "80%" : "30%"}
        buttonLocation="center"
        closableByClickingAway={false}
      />
    </>
  );
};

export default EventRemovalReasonModal;
