import { FmdGood } from "@mui/icons-material";
import { Box, Grid, Button } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import CustomCard from "components/CustomCard";
import { cardStyles } from "layouts/pages/events/styles";
import { metersToFeet } from "utils/helpers/conversion";
import { useAppContextController } from "context/AppContext";
import useEventMapDetails from "hooks/useEventMapDetails";
import { useMemo } from "react";
import EventMap from "../EventMap";

const EventGeofencing = ({ getVenueField }) => {
  const { currentEvent, setCurrentEvent } = useAppContextController();
  const { center, radius, graceDistance, roster } = useEventMapDetails();

  const locationFields = useMemo(() => {
    const {secondaryLocation} = currentEvent
    if (secondaryLocation && secondaryLocation.locationName) {
      return {
        name: secondaryLocation.locationName,
        address: secondaryLocation.address,
        city: secondaryLocation.city,
        state: secondaryLocation.state,
        zip: secondaryLocation.zip,
      }
    }
    return {
      name: getVenueField(currentEvent?.venueSlug, "name"),
      address: getVenueField(currentEvent?.venueSlug, "address"),
      city: getVenueField(currentEvent?.venueSlug, "city"),
      state: getVenueField(currentEvent?.venueSlug, "state"),
      zip: getVenueField(currentEvent?.venueSlug, "zip"),
    }
  }, [currentEvent, getVenueField])

  return (
    <CustomCard
      icon={<FmdGood color="white" />}
      cardTitle="Geofencing"
      cardSx={cardStyles.card}
      titleSx={cardStyles.title}
      actionsSx={cardStyles.actions}
      iconContainerSx={{ backgroundColor: "error", mt: -3, ml: "1.5rem" }}
      isStacked
    >
      <Box sx={cardStyles.container}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={locationFields.name}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Location"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={locationFields.address}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Address"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={locationFields.city}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="city"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={locationFields.state}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="State"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={locationFields.zip}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Zip Code"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={center?.lng}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Longitude"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={center?.lat}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Latitude"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={radius}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Radius"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              // defaultValue={dropdown?.name}
              // disabled={dropdown}
              // placeholder="enter entity value"
              type="text"
              value={metersToFeet(graceDistance)}
              // onChange={(e) => {
              //     setEntityName(e.target.value);
              //     const tempChanged = { ...changed, name: e.target.value };
              //     setChanged(tempChanged);
              // }}
              label="Grace Distance (in Feet)"
            />
          </Grid>
        </Grid>
        <EventMap buttonType="map" key={`${center?.lng}-${center?.lat}`} />
      </Box>
    </CustomCard>
  );
};

export default EventGeofencing;
