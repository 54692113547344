import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import useBadgeValues from "hooks/useBadgeValues";

// import ToggleOffIcon from '@mui/icons-material/ToggleOff';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PublicIcon from "@mui/icons-material/Public";
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import StadiumIcon from '@mui/icons-material/Stadium';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BugReportIcon from "@mui/icons-material/BugReport";
import DoneIcon from "@mui/icons-material/Done";
import PendingIcon from "@mui/icons-material/Pending";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";

export const initialCards = [
  {
    color: "info",
    icon: <PendingIcon fontSize="large" color="info" />,
    title: "To Do",
    tooltip: "To Do Tasks",
    number: 0,
    filterField: "status",
    filterValue: "To Do",
    canCombine: true,
    default: true,
  },
  {
    color: "success",
    icon: <DoneIcon fontSize="large" color="success" />,
    title: "In Progress",
    tooltip: "In-Progress Tasks",
    number: 0,
    filterField: "status",
    filterValue: "In Progress",
  },
  {
    color: "error",
    icon: <BugReportIcon fontSize="large" color="error" />,
    title: "Blocked",
    tooltip: "Blocked Tasks",
    number: 0,
    filterField: "status",
    filterValue: "Blocked",
    canCombine: true,
  },
  {
    color: "warning",
    icon: <QuestionMarkIcon fontSize="large" color="warning" />,
    title: "Done",
    tooltip: "Done Tasks",
    number: 0,
    filterField: "status",
    filterValue: "Done",
    canCombine: true,
  },

  {
    color: "dark",
    icon: <PublicIcon fontSize="large" color="black" />,
    title: "All",
    tooltip: "All Tasks",
    number: 0,
    filterField: "status",
    filterValue: "",
  },
];

const TasksFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentTask, currentLoggedUser } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const navigate = useNavigate();

  const { cards } = useBadgeValues({ initialCards, filters, entity: "tasks", refreshBadges });

  const setTaskFilters = (filter) => {
    navigate("/tasks");
    setCurrentTask(null);
    setFilters(filter);
    setRefreshBadges((prev) => !prev);
  };

  const initialCardIndex = initialCards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setTaskFilters}
        setPage={setPage}
        initialIndex={initialCardIndex}
      />
    </span>
  );
};

export default TasksFilterCards;
