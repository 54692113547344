import axios from "axios";

import { baseAxios } from "config";

const deleteEvent = async (eventId) => {
  if (!eventId) throw new Error("eventId required");
  const res = await baseAxios.delete(`/events/${eventId}`);
  return res;
};

export default deleteEvent;
