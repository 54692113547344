const moment = require("moment-timezone");

const formatReportTime = (dateString, timeZone) => {
  // Check if dateString or timeZone is not provided
  if (!dateString || !timeZone) {
    return null;
  }

  try {
    // Parse the ISO date string
    const date = moment(dateString);

    // Convert it to the specified time zone
    // const tzDate = date.tz(timeZone);

    // Format the time as HH:mm A and return it
    // return tzDate.format("hh:mm A");
    return date.format("hh:mm A");
  } catch (error) {
    // Log and rethrow the error
    console.error("Error formatting report time:", error);
    throw error;
  }
};

export default formatReportTime;
