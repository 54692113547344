import axios from "axios";

import { baseAxios } from "config";

export const generateDatasource = async () => {
  try {
    const res = await baseAxios.post(`/tableMetadata/datasource`, null);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
