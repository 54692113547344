import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import GenericModal from "components/GenericModal";
import { AvTimer } from "@mui/icons-material";
import PictureAvatar from "components/PictureAvatar";
import VenueIcon from "components/VenueIcon";

const ClockInOutModal = ({ open, setOpen, inOutParent, setSuccessClockInOutModalOpen }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const hardCodedUser = {
    photo:
      "https://images.stadiumpeople.com/sp/users/634cf28484f10c02712d1f46/photo/1578316863043.jpg",
    firstName: "David",
    lastName: "Smith",
    positionName: "Position Name",
    currentTime: "7:45 AM",
    hoursThisWeek: "9.5",
  };

  const hardCodedEvent = {
    logoUrl: "https://images.stadiumpeople.com/sp/bensonhill/venues/logo/bensonhill-venuelogo.png",
    vslug: "bsl",
    venueName: "Benson Hill",
    date: " Tues May 9th, 2023",
    time: "08:00 AM",
    estimatedEndTime: "05:00 PM",
  };

  const header = (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item sm={6} pr={2}>
              <PictureAvatar
                image={hardCodedUser?.photo}
                firstName={hardCodedUser?.firstName}
                lastName={hardCodedUser?.lastName}
                size="md"
              />
            </Grid>
            <Grid item sm={6}>
              <Typography variant="h4">
                {`${hardCodedUser.firstName} ${hardCodedUser.lastName}`}
              </Typography>
              <Typography variant="body2">{hardCodedUser.positionName}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ justifySelf: "end" }} textAlign="center">
          <Typography variant="body2">Timeclock</Typography>
          <Typography variant="h4">{hardCodedUser.currentTime}</Typography>
          <Typography variant="body2">Hours this week</Typography>
          <Typography variant="h4">{hardCodedUser.hoursThisWeek}</Typography>
        </Grid>
      </Grid>
      <hr />
    </>
  );

  const modalBody = (
    <Grid container mb={3}>
      <Grid item sm={3}>
        <VenueIcon
          logoUrl={hardCodedEvent?.logoUrl}
          slug={hardCodedEvent.vslug}
          name={hardCodedEvent?.name}
          imageWidth="50"
          imageHeight="50"
        />
      </Grid>
      <Grid item sm={9}>
        <Typography variant="body2">
          <strong>Date:</strong> {hardCodedEvent.date}
        </Typography>
        <Typography variant="body2">
          <strong>Time: </strong>:{hardCodedEvent.time}
        </Typography>
        <Typography variant="body2">Est. Time: {hardCodedEvent.estimatedEndTime}</Typography>
      </Grid>
    </Grid>
  );

  const inOutButton =
    inOutParent === "in" ? (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          setOpen(false);
          setSuccessClockInOutModalOpen(true);
        }}
      >
        CLOCK IN
      </Button>
    ) : (
      <Button
        variant="contained"
        color="error"
        onClick={() => {
          setOpen(false);
          setSuccessClockInOutModalOpen(true);
        }}
      >
        CLOCK OUT
      </Button>
    );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        icon={<AvTimer />}
        iconColor="dark"
        iconTitle="Timeclock"
        width={isSmallScreen ? "95%" : "35%"}
        body={modalBody}
        header={header}
        buttons={inOutButton}
        buttonLocation="center"
      />
    </>
  );
};

export default ClockInOutModal;
