import { useState } from "react";
import { useSnackbar } from "notistack";
import { Box, Button, Grid, IconButton, Switch } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Cancel as CancelIcon, Save as SaveIcon } from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/Work";
import { cardStyles } from "layouts/pages/events/styles";
import { useAppContextController } from "context/AppContext";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { eventInfoSchema } from "data/event";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import DataList from "components/DataList";
import { useMutation, useQueryClient } from "react-query";
import { getDirtyFields } from "utils/helpers/formHelpers";
import AddAditionalPositionModal from "../EventsInformation/AddAdditionalPositionModal";
import ReminderToSaveChangesModal from "../EventsInformation/ReminderToSaveChangesModal";
import AdditionalPosition from "../EventInfoPanel/EventForm/EventsAdditionalPosition";

const useStyle = makeStyles({
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
});

const EventAdditionalPositions = () => {
  const { currentEvent, setCurrentEvent } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [additionalPositionModalOpen, setAdditionalPositionModalOpen] = useState(false);
  const [positionIndex, setPositionIndex] = useState(null);
  const classes = useStyle();
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const isEdit = !!currentEvent?._id;
  const resolver = useYupValidationResolver(eventInfoSchema);
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    setError,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({ mode: "onBlur", defaultValues: currentEvent, resolver });

  const addNewAdditionalPosition = () => {
    setAdditionalPositionModalOpen(true);
  };

  const positionHandler = (row, isOpenAdditionPosition, posIdx) => {
    setCurrentPosition(row);
    setAdditionalPositionModalOpen(isOpenAdditionPosition);
    setPositionIndex(posIdx);
  };

  const removePosition = (idx) => {
    const temporaryPositions = getValues()?.positions;
    temporaryPositions.splice(idx, 1);
    setValue("positions", temporaryPositions, { shouldDirty: true });
    if (isEdit) {
      setReminderToSaveChangesModalOpen(true);
    }
  };

  const cancelChangesHandler = () => {
    const url = currentEvent?.eventUrl;
    reset(currentEvent);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
  };

  const panelActions = (
    <Box mt={2}>
      <Button
        variant="text"
        startIcon={<CancelIcon />}
        onClick={cancelChangesHandler}
        disabled={isSubmitting}
        color="error"
        sx={{ color: "#F44335", fontSize: 14 }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="text"
        endIcon={<SaveIcon />}
        sx={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Box>
  );

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar("Event has been updated!", { variant: "success" });
      await queryClient.invalidateQueries("events");
    },
  });

  const editEventFormHandler = async (values) => {
    if (!currentEvent.venueSlug || !currentEvent.eventId) {
      enqueueSnackbar("Cannot Update Event -- No Event ID!", { variant: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);

    await updateEventMutation.mutateAsync({
      venueSlug: currentEvent.venueSlug,
      eventId: currentEvent._id,
      data: {
        ...modifiedFields,
        billRate: parseFloat(values.billRate),
        payRate: parseFloat(values.payRate),
      },
    });
  };

  return (
    currentEvent && (
      <form onSubmit={handleSubmit(editEventFormHandler)}>
        <CustomCard
          icon={<WorkIcon color="white" />}
          cardTitle="Additional Positions"
          cardActions={isDirty && panelActions}
          cardSx={cardStyles.card}
          titleSx={cardStyles.title}
          actionsSx={cardStyles.actions}
          iconContainerSx={{ backgroundColor: "warning", mt: -3, ml: "1.5rem" }}
          isStacked
        >
          <Box sx={cardStyles.container}>
            <Grid item xs={12} mb={3}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={-3}>
                <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                  ADDITIONAL POSITIONS
                </MDTypography>
                <IconButton
                  className={classes.addButton}
                  color="info"
                  onClick={addNewAdditionalPosition}
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </MDBox>
            </Grid>

            <hr />
            <Grid item xs={12}>
              <Controller
                name="positions"
                control={control}
                render={({ field }) => (
                  <DataList
                    renderRow={(row, idx) =>
                      AdditionalPosition(row, idx, positionHandler, removePosition)
                    }
                    data={field.value || []}
                    greybar
                    divider
                    scrollY
                    tableHeight={170}
                    height={200}
                    isLoading={false}
                  />
                )}
              />
            </Grid>
            <AddAditionalPositionModal
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              additionalPositionModalOpen={additionalPositionModalOpen}
              setAdditionalPositionModalOpen={setAdditionalPositionModalOpen}
              currentEvent={currentEvent}
              setCurrentEvent={setCurrentEvent}
              currentPosition={currentPosition}
              setCurrentPosition={setCurrentPosition}
              positionIndex={positionIndex}
              setPositionIndex={setPositionIndex}
              setReminderToSaveChangesModalOpen={setReminderToSaveChangesModalOpen}
              isEdit={isEdit}
            />
            {isEdit && (
              <ReminderToSaveChangesModal
                open={reminderToSaveChangesModalOpen}
                setOpen={setReminderToSaveChangesModalOpen}
              />
            )}
          </Box>
        </CustomCard>
      </form>
    )
  );
};

export default EventAdditionalPositions;
