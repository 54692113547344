import { useState, useEffect, useMemo } from "react";
// hooks
import useDropdowns from "hooks/useDropdowns";
// constants
import { APPLICANT_EMPLOYEE_ENTITIES } from "utils/constants/entityOptions";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { styled } from "@mui/material/styles";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilterModal from "components/FilterModal";
// import MDBadge from "components/MDBadge";
import useIsMountedReferenced from "hooks/useIsMountedReferenced";
import PaperItem from "./Paper";

const { LICENSE_TYPES_ENTITY } = APPLICANT_EMPLOYEE_ENTITIES;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -15,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "8px",
  },
}));

function FilterCard({
  color = "info",
  bgColor,
  icon,
  title,
  tooltip,
  number,
  selectedIndex,
  index,
  filters,
  setExistingFilters,
  setFilters,
  filterField,
  filterValue,
  onSelectHandler,
  customFilterFunction,
  parentComponent,
  filterObj,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const elevation = selectedIndex === index ? "16" : "2";
  const titleFont = title?.length < 10 ? "h6" : "body1";
  const [isOpen, setIsOpen] = useState(false);
  const [modalFilters, setModalFilters] = useState({});
  const { dropdowns: licenseTypes } = useDropdowns({ entity: LICENSE_TYPES_ENTITY });

  // Add a mounted state
  const isMountedRef = useIsMountedReferenced();

  const [modalTitle, modalData] = useMemo(() => {
    if (title === "Licenses") {
      return ["License Type", licenseTypes];
    }
    return ["", null];
  }, [licenseTypes, title]);

  function formatBadgeContent(value) {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(0)}m`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(0)}k`;
    }
    return value;
  }

  const handleClick = async () => {
    if (title === "Licenses") {
      if (filters["licenses.licenseType.value"]) {
        const licenseFilters = filters["licenses.licenseType.value"].split(";");
        const obj = {};
        licenseFilters.forEach((element) => {
          obj[element] = true;
        });
        setModalFilters(obj);
      }
      if (isMountedRef.current) {
        setIsOpen(true);
      }
    } else {
      onSelectHandler(filterField, filterValue, index, customFilterFunction, filterObj);
    }
  };

  const handleClear = () => {
    const fils = { ...filters };
    delete fils["licenses.licenseType.value"];
    setExistingFilters({ ...fils });
  };

  const handleSubmit = (values) => {
    const newFilterVal = Object.entries(values)
      .filter(([_, v]) => v)
      .map(([k]) => k)
      .join(";");

    if (!newFilterVal) handleClear();
    if (newFilterVal) onSelectHandler(filterField, newFilterVal, index, customFilterFunction);
  };

  return (
    <>
      <FilterModal
        title={modalTitle}
        data={modalData}
        currentFilters={modalFilters}
        isModalOpen={isOpen}
        setModalOpen={setIsOpen}
        onSubmit={handleSubmit}
      />
      <PaperItem elevation={elevation} key={`paperitem_${title}`}>
        <Tooltip title={tooltip} placement="top">
          <Card sx={{ overflow: "hidden" }} onClick={handleClick}>
            <MDBox
              sx={({ palette: { background } }) => ({
                background: darkMode && background.card,
              })}
            >
              <Grid container alignItems="center">
                <Grid item xs={4} mr={-1}>
                  <MDBox
                    margin={1}
                    padding={2}
                    bgColor="white"
                    width="3rem"
                    height="3rem"
                    borderRadius="lg"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon fontSize="large" color={color}>
                      {icon}
                    </Icon>
                  </MDBox>
                </Grid>
                <Grid item xs={8}>
                  <MDBox ml={0} mr={-3} lineHeight={1} textAlign="left">
                    <StyledBadge
                      badgeContent={formatBadgeContent(number)}
                      color={color === "dark" ? "info" : color}
                      size="lg"
                      max={10000}
                    >
                      <MDTypography
                        variant={titleFont}
                        color="dark"
                        opacity={0.7}
                        textTransform="capitalize"
                        fontWeight="bold"
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {title?.length < 14 ? title : `${title?.substring(0, 12)}...`}
                      </MDTypography>
                    </StyledBadge>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Tooltip>
      </PaperItem>
    </>
  );
}

// Setting default values for the props of FilterCard
FilterCard.defaultProps = {
  color: "white",
  bgColor: "info",
  selectedIndex: 0,
  number: null,
  onSelectHandler: () => {},
  filterField: "",
  filterValue: "",
};

// Typechecking props for the FilterCard
FilterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "white",
  ]),
  bgColor: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.node.isRequired,
  number: PropTypes.number,
  selectedIndex: PropTypes.number,
  filterField: PropTypes.string,
  filterValue: PropTypes.string,
  onSelectHandler: PropTypes.func,
};

export default FilterCard;
