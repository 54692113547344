import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useAppContextController } from "context/AppContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { Box, Button, styled } from "@mui/material";
import MDBox from "components/MDBox";
import { getCompanyBaseImageUrl } from "utils/helpers/upload";
import { useOutsideCompanyCache } from "hooks/useCompanyCache";
import MDTypography from "components/MDTypography";
import HomeHeader from "../components/HomeHeader";
import VerifyCard from "./VerifyCard";
import VerifySuccess from "./VerifySuccess";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
    width: "85%",
  },
});
const TableContainer = styled(FlexWrapper)(({ theme }) => ({
  "& #table-results": {
    "& .MuiBox-root": {
      [theme.breakpoints.down("lTablet")]: {
        padding: "10px",
      },
    },
  },
  "& .MuiPaper-root .MuiBox-root .MuiPaper-root": {
    paddingBottom: "20px",
    "& > div:last-child": {
      height: "100%",
      maxHeight: "450px",
      [theme.breakpoints.up(1680)]: {
        maxHeight: "calc(100vh - 550px)",
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme, url }) => ({
  backgroundImage: `url(${url})`,
  justifyContent: "center",
  alignItems: "center",
  backgroundSize: "100%",
  backgroundRepeat: "no-repeat",
  position: "relative",
  width: "100%",
  height: "100%",
  minHeight: "85vh",
  [theme.breakpoints.down("laptop")]: {
    height: "100%",
  },
}));

function ChangeEmailVerification() {
  const { company, setCompany, setCompanyType, isMobile } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "protected",
  });
  const [emailChangeVerified, setEmailChangeVerified] = useState(false);
  const [newEmail, setNewEmail] = useState(null);
  const [oldEmail, setOldEmail] = useState(null);
  const { id, otp } = useParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setOldEmail(queryParams.get("old_email"));
    setNewEmail(queryParams.get("new_email"));
  }, []);

  return (
    <>
      <Box sx={{ backgroundColor: "#303030" }}>
        <HomeHeader company={company} />
        <StyledBox
          url={`${getCompanyBaseImageUrl(company)}/backgroundImage/${company?.backgroundImageUrl}`}
        >
          <Button component={NavLink} to="/" variant="text" sx={{ color: "#FFF" }}>
            <ArrowBackIosIcon /> Back to Job Results
          </Button>
          {company?.companyType === "Venue" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                margin: isMobile || "0 25%",
              }}
            >
              <MDTypography
                fontWeight="bold"
                fontSize={!isMobile ? "5rem" : "3rem"}
                color="white"
                textAlign="center"
                mt={isMobile ? 5 : 10}
              >
                BE A PART OF OUR
                <span style={{ color: "red" }}>&nbsp;MISSION</span>
              </MDTypography>
              <MDTypography
                variant={!isMobile ? "h4" : "h6"}
                color="white"
                textAlign="center"
                mb={isMobile ? 0 : 10}
              >
                Join our events team today and experience the magic of the crowd management industry
                & event staff jobs, making extra money, and meeting new people.
              </MDTypography>
            </Box>
          )}

          <FlexWrapper px={{ xs: 0, sm: 5, sDesktop: 0 }} mt={{ xs: "0px", sDesktop: "30px" }}>
            <TableContainer px={{ sDesktop: 10 }}>
              {emailChangeVerified ? (
                <VerifySuccess newEmail={newEmail} />
              ) : (
                <VerifyCard
                  newEmail={newEmail}
                  oldEmail={oldEmail}
                  id={id}
                  otp={otp}
                  setEmailChangeVerified={setEmailChangeVerified}
                  parent="emailVerification"
                />
              )}
            </TableContainer>
          </FlexWrapper>
        </StyledBox>
        <MDBox
          sx={{ py: "15px", px: "20px" }}
          bgColor="#222"
          display="flex"
          justifyContent="center"
        >
          <img
            src="/images/png/logos/powered-by-gignology.png"
            alt="powered by gig-nology"
            width="205.8px"
            height="82.8px"
          />
        </MDBox>
      </Box>
    </>
  );
}

export default ChangeEmailVerification;
