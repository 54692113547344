import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import AvTimerIcon from "@mui/icons-material/AvTimer";
import { useAppContextController } from "context/AppContext";

const EventsTimeActions = ({
  id,
  row,
  currentEvent,
  setCurrentEvent,
  setGenericModalOpen,
  setModalInfo,
}) => {
  const { userType } = useAppContextController();
  const getNotesThisEvent = (arr) => {
    let numNotes = 0;
    arr.forEach((item) => {
      if (item?.eventUrl === currentEvent?.eventUrl) numNotes += 1;
    });
    return numNotes;
  };
  const numberEventNotes =
    currentEvent?.eventUrl && row?.notes?.length ? getNotesThisEvent(row.notes) : 0;
  const onClickHandler = (action, e) => {
    // setCurrentEvent(row);
    switch (action) {
      case "applicantMail":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      case "applicantComment":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <span>
      {userType !== "Client" && userType !== "Event Admin" && (
        <>
          <TableAction
            color="primary"
            icon="mail"
            tooltip={`Send a Message to ${row?.email}`}
            clickAction={(e) => onClickHandler("applicantMail", e)}
          />
          &nbsp;&nbsp;
        </>
      )}
      <TableAction
        color="info"
        icon="description"
        showBadge
        badgeNumber={numberEventNotes}
        tooltip="Add a Note"
        clickAction={(e) => onClickHandler("applicantComment", e)}
      />
    </span>
  );
};
export default EventsTimeActions;

EventsTimeActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
EventsTimeActions.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
