import axios from "axios";

import { baseAxios } from "config";

const postNote = async ({ applicantId, data }) => {
  try {
    const res = await baseAxios.post(`/applicants/${applicantId}/note`, data);
    return res;
  } catch (err) {
    throw err.toString();
  }
};

export default postNote;
