import { Grid, Button, Tooltip } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";

import createUserAccount from "layouts/pages/users/actions/createUserAccount";
import getBrowserAndOS from "utils/getBrowserAndOS";
import createPartnerUser from "../../actions/createPartnerUser";

const PartnerUserCreate = ({ currentPartner }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLoggedUser } = useAppContextController();
  const queryClient = useQueryClient();

  const createUserAccountMutation = useMutation(createPartnerUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Login invite has been sent!", { variant: "success" });
      await queryClient.invalidateQueries(["getPartnerApplicantDetails"]);
      await queryClient.invalidateQueries(["getPartnerUserDetails"]);
    },
  });

  const handleCreate = () => createUserAccountMutation.mutateAsync({ id: currentPartner?._id });
  const cannotCreateUser =
    !currentPartner?.leader?.email ||
    !currentPartner?.leader?.firstName ||
    !currentPartner?.leader?.lastName;

  return (
    <MDBox px={5}>
      <MDTypography color="blue" fontWeight="bold" variant="body2" py={3}>
        User Account Information
      </MDTypography>
      <Grid container spacing={8} pb={5}>
        <Grid item xs={6}>
          <Tooltip
            title={cannotCreateUser ? "Leader email, first name and last name required" : ""}
          >
            <span>
              <Button
                variant="contained"
                onClick={handleCreate}
                fullWidth
                disabled={cannotCreateUser}
              >
                Create User
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default PartnerUserCreate;
