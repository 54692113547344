import { useCallback, useState } from "react";
import { Box, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import parse from "html-react-parser";
import ConfirmationModal from "components/ConfirmationModal";
import MyNotificationsActions from "layouts/pages/dashboard/components/MyNotifications/components/MyNotificationsActions";
import deleteNotification from "api/notifications/deleteNotification";
import updateNotification from "api/notifications/updateNotification";
import moment from "moment";
import useSessionAuth from "hooks/useSessionAuth";

const Notification = ({ row, refetch }) => {
  const [showBody, setShowBody] = useState(false);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const handleRowOnClick = (e) => {
    e.stopPropagation();
    setShowBody((prev) => !prev);
    // eslint-disable-next-line no-param-reassign
    row.status = row.status === "read" ? "unread" : "read";
  };

  const { logoutAuth0User } = useSessionAuth();

  const handleDelete = useCallback(async () => {
    try {
      // const response = await deleteNotification({
      //   notificationId: row._id,
      // });
      const body = {
        status: "Deleted",
      };

      const response = await updateNotification({ notificationId: row._id, body });
      if (response.data.modifiedCount || response.data.acknowledged) {
        toggleConfirmationModal(false);
        refetch();
      }
    } catch (err) {
      console.error(err);
      if (String(err).includes("401") || err?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [row, refetch]);

  return (
    <Grid container alignItems="center" onClick={handleRowOnClick}>
      <Grid item xs={12} sm={9}>
        <Grid container flexDirection="row" spacing={3}>
          <Grid item xs={3} sm={2}>
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.fromFirstName}
              lastName={row?.fromLastName}
              userId={row?.fromUserId}
              size="md"
            />
          </Grid>
          <Grid item xs={9} sm={10}>
            <Grid container flexDirection="column">
              <Grid item xs={12}>
                <MDTypography
                  variant="h6"
                  sx={row.status === "unread" ? { fontStyle: "italic" } : {}}
                >
                  {moment(row.sendTime).format("ddd YYYY-MM-DD hh:mm A")}
                </MDTypography>
                <Grid item xs={12} sm={6}>
                  <MDTypography
                    variant="h5"
                    sx={row.status === "unread" ? { fontStyle: "italic" } : {}}
                  >
                    {row.fromFirstName} {row.fromLastName}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
            {row.subject && (
              <Grid item xs={12}>
                <MDTypography
                  variant="h6"
                  sx={row.status === "unread" ? { fontStyle: "italic" } : {}}
                >
                  {row.subject}
                </MDTypography>
              </Grid>
            )}
            {showBody && (
              <Grid item xs={12} pt={1}>
                <hr />
                <Box sx={{ typography: 'body2' }} pt={1}>
                  {row?.body ? parse(row.body) : ""}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <MyNotificationsActions
          row={row}
          refetch={refetch}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      </Grid>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title="Are you sure you want to delete this notification?"
        onConfirm={() => {
          handleDelete();
        }}
      />
    </Grid>
  );
};

export default Notification;
