import parse from "html-react-parser";
import { Card, Grid, Modal, Typography, styled } from "@material-ui/core";
import { Box, Fade, IconButton, Stack, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccessTime from "@mui/icons-material/AccessTime";
import { useAppContextController } from "context/AppContext";
import LanguageIcon from "@mui/icons-material/Language";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { Close } from "@mui/icons-material";
import MDBox from "components/MDBox";
import moment from "moment";
import FormField from "layouts/applications/wizard/components/FormField";
import DataList from "components/DataList";
import MDTypography from "components/MDTypography";
import AddToCalendarButton from "components/AddToCalendarButton";
import { useCallback, useEffect, useState } from "react";
import fetchSingleEvent from "../../actions/fetchSingleEvent";


const InterviewPreviewModal = ({ content, open, setOpen, interviewPreview }) => {
    const { company } = useAppContextController();
    const [tableEvent, setTableEvent] = useState(null)
    const IMAGE_SERVER = company?.imageUrl;


    const handleClose = () => setOpen(false);

    const getAndSetFullEvent = useCallback(async (eid) => {

        const ev = await fetchSingleEvent({ eventId: eid });
        if (ev) setTableEvent(ev);
    }, []);

    useEffect(() => {
        if (content === "tableAll") { getAndSetFullEvent(interviewPreview._id); }
        else setTableEvent(interviewPreview)
    }, [getAndSetFullEvent, content, interviewPreview]);

    const listRow = (row) => (
        <Stack
            display="flex"
            direction="row"
            justifyContent="space-between"
        >
            <Grid container>
                <Grid item sm={4}>
                    <MDTypography variant="body2">{`${row.firstName} ${row.lastName}`}</MDTypography>
                </Grid>
                <Grid item sm={8}>
                    <MDTypography variant="body2">{row.emailAddress ? row.emailAddress : row.email}</MDTypography>

                </Grid>
            </Grid>

        </Stack>
    )



    if (tableEvent)
        return (
            <div>
                <Modal
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={open}>
                        <Card>
                            {(content === "all" || content === "tableAll") && (
                                <>
                                    <StyledContainer mt={-30}>
                                        <CloseEditorButton onClick={() => handleClose()}>
                                            <CancelIcon color="white" fontSize="large" />
                                        </CloseEditorButton>
                                        <Box mt={-20} sx={{ height: "40rem", width: "100%", zIndex: -1 }}>
                                            <img
                                                style={{ objectFit: "cover", width: "100%" }}
                                                src={`${IMAGE_SERVER}/company/${company?.slug}/backgroundImage/${company?.backgroundImageUrl}`}
                                                alt={`${company?.title} Job Preview`}
                                            />
                                        </Box>
                                    </StyledContainer>
                                </>
                            )}
                            <StyledContainer>
                                <StyledModalContainer>
                                    <Box p={3}>
                                        <Grid container spacing={3} >
                                            {(content === "all" || content === "tableAll") && (
                                                <>
                                                    <Grid item style={{ alignSelf: "center" }}>
                                                        <CompanyLogoSquare
                                                            company={{ name: tableEvent?.companyName }}
                                                            defaultCompany={company}
                                                            height={120}
                                                            width={120}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <StyledBox>
                                                            <StyledCompanyName variant="h6">
                                                                {tableEvent?.eventName}
                                                            </StyledCompanyName>
                                                            <StyledCompanyName variant="h4">{tableEvent?.companyName}</StyledCompanyName>
                                                            <StyledHeaderBox>
                                                                <Stack direction="row" alignItems="center">
                                                                    <MDBox pr={1}>
                                                                        <LocationOnIcon color="error" />
                                                                    </MDBox>
                                                                    <Typography variant="body1">
                                                                        {`${tableEvent?.address}, ${tableEvent?.venueCity} ${tableEvent?.venueState} ${tableEvent?.zip}`}
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction="row" alignItems="center">
                                                                    <MDBox pr={1}>
                                                                        <AccessTime color="info" />
                                                                    </MDBox>
                                                                    <Typography variant="body1">
                                                                        {`${moment(tableEvent?.eventDate).format("ddd, MM/DD/YYYY hh:mma")} - ${moment(tableEvent?.eventEndTime).format("hh:mma")}`}
                                                                    </Typography>
                                                                </Stack>
                                                                <MDBox ml={-2}>
                                                                    <AddToCalendarButton event={tableEvent} />
                                                                </MDBox>
                                                            </StyledHeaderBox>
                                                        </StyledBox>
                                                    </Grid>
                                                    <Grid item xs={12} />
                                                    <Grid item sm={3}>
                                                        <FormField
                                                            value={tableEvent?.recruiter}
                                                            label="Recruiter"
                                                        />

                                                    </Grid>
                                                    <Grid item sm={3}>
                                                        <FormField
                                                            value={tableEvent?.hiringManager}
                                                            label="Hiring Manager"
                                                        />

                                                    </Grid>
                                                    <Grid item sm={5}>
                                                        <FormField
                                                            value={tableEvent?.eventUrl}
                                                            label="Interview Url"
                                                        />

                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Tooltip title="Copy Url">
                                                            <IconButton
                                                                color="info"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(tableEvent?.eventUrl);
                                                                }}
                                                            >
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <MDTypography component="label" variant="body2">
                                                            Applicant Attendees
                                                        </MDTypography>
                                                        <DataList
                                                            renderRow={(row, idx) =>
                                                                listRow(row)
                                                            }
                                                            data={(tableEvent?.applicants) || []}
                                                            greybar
                                                            divider
                                                            scrollY
                                                            tableHeight={220}
                                                            height={230}
                                                            isLoading={false}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <MDTypography component="label" variant="body2">
                                                            interview Members
                                                        </MDTypography>
                                                        <DataList
                                                            renderRow={(row, idx) =>
                                                                listRow(row)
                                                            }
                                                            data={(tableEvent?.attendees) || []}
                                                            greybar
                                                            divider
                                                            scrollY
                                                            tableHeight={220}
                                                            height={230}
                                                            isLoading={false}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12}>
                                                        <StyledBoxDescription>
                                                            {(tableEvent?.description && parse(tableEvent?.description))}

                                                        </StyledBoxDescription>
                                                    </Grid>
                                                </>
                                            )}
                                            {(content !== "all" && content !== "tableAll") && (
                                                <Grid item sm={12}>
                                                    <StyledBoxDescription>
                                                        {interviewPreview?.[content] && <div>{parse(interviewPreview[content])}</div>}

                                                    </StyledBoxDescription>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </StyledModalContainer>
                            </StyledContainer>
                        </Card>
                    </Fade>
                </Modal>
            </div>
        );
    return null;
};

export default InterviewPreviewModal;


const CloseEditorButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: "0%",
    right: "5%",
    m: 1,
    p: 0,
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 1170,
    backgroundColor: "none",
    overflow: "hidden",
    borderRadius: 5,
    padding: "6rem 4rem",
}));

const StyledModalContainer = styled(Card)(({ theme }) => ({
    backgroundColor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    padding: "1rem",
}));

const StyledBox = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid #7c97a9`,
}));

const StyledHeaderBox = styled(Box)(({ theme }) => ({
    color: "#7c97a9",
}));

const StyledBoxDescription = styled(Box)(({ theme }) => ({
    overflowY: "scroll",
    maxHeight: "40vh",
    padding: 5,
    border: "1px solid gray"
}));

const StyledCompanyName = styled(Typography)(({ theme }) => ({
    lineHeight: 1,
    marginBottom: 5,
}));

const StyledCompanyLogo = styled(Box)(({ theme }) => ({
    height: 86,
}));
