import CustomMenu from "components/CustomizedMui/CustomMenu";
import moment from "moment";
import { useMemo } from "react";

// const ConfirmDialog = ({ title = "Data has changed!", bodyText, open, setOpen, setResponse }) => {
const MonthSelectionMenu = ({
  anchorEl,
  handleClose,
  handleClick,
  startingDate,
  currentDate,
  pastMonths = 2,
  futureMonths = 15,
}) => {
  const dropdownOptions = useMemo(() => {
    // const calendarApi = calendarRef.current?.getApi();
    if (!currentDate || !startingDate) return [];
    const current = startingDate;
    const currentDateCopy = moment(currentDate);
    const minDate = moment(current).add(-pastMonths, "months");
    const result = [];
    for (let index = 0; index < futureMonths; index += 1) {
      const goToDate = moment(minDate).add(index, "months");
      result.push({
        text: `${goToDate.format("YYYY")} - ${goToDate.format("MMMM")}`,
        handleClick: () => handleClick(goToDate),
        selected:
          goToDate.month() === currentDateCopy.month() &&
          goToDate.year() === currentDateCopy.year(),
      });
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  return (
    <CustomMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      options={dropdownOptions}
      slotProps={{
        paper: {
          style: {
            maxHeight: "300px",
          },
        },
      }}
    />
  );
};

export default MonthSelectionMenu;
