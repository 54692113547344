import { useEffect } from "react";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import useSessionAuth from "./useSessionAuth";

function useVenueCache({ venues, setVenues, company, outsideMode = "" }) {
  const { logoutAuth0User } = useSessionAuth();
  useEffect(() => {
    const options = { fetchAll: true };
    const filters = { status: "Active" };
    let allOptions = {
      ...options,
      filters,
      order: "asc",
      orderBy: "slug",
      imageUrlBase: company?.imageUrl,
    };

    if (outsideMode) {
      allOptions = { ...allOptions, outsideMode };
    }

    if (!venues && !!company && company?.companyType === "Venue") {
      const fetchData = async () => {
        try {
          const data = await fetchVenues(allOptions);
          const objs = {};
          data.data.forEach((item) => {
            objs[item.slug] = item;
          });
          setVenues(objs);
        } catch (error) {
          console.error("Error fetching venues:", error);
          if (!outsideMode) {
            if (String(error).includes("401") || error?.response?.status === 401) {
              logoutAuth0User();
            }
          }
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venues, setVenues, company]);

  return { isLoadingVenues: false, refetchVenues: () => {} };
}

export default useVenueCache;
