import { Card } from "@material-ui/core";
import { Box, Icon, Stack, TableCell, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import moment from "moment";

const useStyle = makeStyles({
  box: {
    overflow: "visible!important",
  },
});
const PanelSelectorBox = ({
  dateSelected,
  icon,
  iconColor,
  title,
  header,
  cardVariant,
  children,
  nodata,
}) => {
  const classes = useStyle();
  return (
    <Card className={classes.box} style={{ height: "700px", width: "100%" }} variant={cardVariant}>
      <Box alignSelf="start" style={{ display: "flex" }}>
        <Box sx={{ display: "flex" }}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor={iconColor}
            color="white"
            borderRadius="xl"
            ml={3}
            mt={icon ? -3 : -2}
          >
            <Icon fontSize="large">{icon}</Icon>
          </MDBox>
          {dateSelected && !nodata ? (
            <Stack>
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, ml: 2 }}>
                {title || ""}
              </MDTypography>
              <MDTypography variant="body2" sx={{ mb: 1, ml: 2 }}>
                Last Update:
                {moment(dateSelected).format("ddd MM/DD/YYYY hh:mm a")}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {title || ""}
            </MDTypography>
          )}
        </Box>
        {header ? (
          <MDBox
            sx={(theme) => ({
              width: "60%",
              textAlign: "end",
              marginRight: "0px",
              marginTop: "20px",
              [theme.breakpoints.down("lTablet")]: {
                width: "100%",
              },
              flexGrow: 1,
            })}
          >
            {header}
          </MDBox>
        ) : null}
      </Box>
      <MDBox style={{ height: "620px", boxShadow: "none" }}>
        {nodata ? (
          <MDBox style={{ width: "100%" }}>
            <MDTypography align="center">No Data</MDTypography>
          </MDBox>
        ) : (
          children
        )}
      </MDBox>
    </Card>
  );
};

export default PanelSelectorBox;
