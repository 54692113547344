import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import PictureAvatar from "components/PictureAvatar";
import fetchCustomers from "api/companies/fetchCustomers";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import TagListSearch from "components/TagListSearch";
import TagListToggle from "components/TagListToggle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import { useAppContextController } from "context/AppContext";
import useTableFunctions from "hooks/useTableFunctions";
import CustomerTableActions from "layouts/pages/customers/components/CustomerTableActions";
import useCompanyStore from "stores/useCompanyStore";
import { isEqual } from "lodash";
import { splitName } from "utils/helpers/splitName";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import useSessionAuth from "hooks/useSessionAuth";
import {
  generateApplicantCompanyChartReport,
  generateApplicantCompanyTableReport,
  generateCompanyChartReport,
  generateCompanyTableReport,
} from "api/reports/companyReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ReportModal from "components/ReportModal";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import {
  generateEmployeeCompanyChartReport,
  generateEmployeeCompanyTableReport,
} from "api/reports/employeeReport";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomersTable = ({
  fetchAll = false,
  setActionSelected,
  parent,
  filters,
  setFilters,
  showFiltersList = false,
  refreshCompanies,
  showSearchBar = false,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setToastAlert,
  useOrTags,
  setUseOrTags,
  isApplicantCustomer,
  isEmployeeCustomer,
  currentApplicant,
  name,
  parentPage,
  parentNavigateWithBack,
}) => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const { company, userType } = useAppContextController();

  const isCompany = company?.companyType === "Company";

  const navigate = useNavigate();
  const classes = useStyle();

  const { action } = useParams();
  const { page, setPage, limit, setLimit, order, orderBy, toggleSort, pageLimitConfirmationModal } =
    useTableFunctions({
      defaultLimit: 5,
    });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentCompany });
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };

  // Handle back button from parent screens
  const { navigateWithBack } = useHandleBackButtonSubGrid({
    parentPage,
    setPage,
    page,
    filters,
    navigateFunc: navigate,
    navigateWithBackFunc: parentNavigateWithBack,
  });

  const allOptions = {
    ...options,
    filters: { ...filters, primaryCompany: false },
    useOrTags,
  };
  const {
    data: companies,
    isLoading,
    refetch,
  } = useQuery(["customers_data", allOptions], () => fetchCustomers(allOptions), {
    refetchInterval: 300000,
  });
  const [searchTagList, setSearchTagList] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [tagsToggle, setTagsToggle] = useState("or");

  // useEffect(() => {
  //   if(!companies?.count) return
  //   setCurrentCompany(companies.data[0])
  // }, [companies])

  useEffect(() => {
    if ((action && filters) || filters) setPage(1);
  }, [action, filters]);

  useEffect(() => {
    refetch();
  }, [refreshCompanies]);

  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);
  const [selectedSubtables, setSelectedSubtables] = useState([]);

  const { logoutAuth0User } = useSessionAuth();

  const { mutateAsync: createApplicantCompanyTableReport } = useMutation(
    generateApplicantCompanyTableReport
  );
  const { mutateAsync: createApplicantCompanyChartReport } = useMutation(
    generateApplicantCompanyChartReport
  );
  const { mutateAsync: createEmployeeCompanyTableReport } = useMutation(
    generateEmployeeCompanyTableReport
  );
  const { mutateAsync: createEmployeeCompanyChartReport } = useMutation(
    generateEmployeeCompanyChartReport
  );
  const { mutateAsync: createCompanyTableReport } = useMutation(generateCompanyTableReport);
  const { mutateAsync: createCompanyChartReport } = useMutation(generateCompanyChartReport);
  const { mutateAsync: exportCompanyReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveCompanyReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialCompanyReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        let reportModule;

        if (isApplicantCustomer) {
          reportModule = `applicant-customers:${currentApplicant?._id}`;
        } else if (isEmployeeCustomer) {
          reportModule = `employee-customers:${currentApplicant?._id}`;
        } else {
          reportModule = `companies`;
        }

        const response = await fetchModuleReports(reportModule);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);

          if (response.tableReportData.criteria?.selectedSubtables) {
            setSelectedSubtables(response.tableReportData.criteria?.selectedSubtables);
          }
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);

          if (response.chartReportData.criteria?.selectedSubtables) {
            setSelectedSubtables(response.chartReportData.criteria?.selectedSubtables);
          }
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialCompanyReport();
  }, [handleLoadInitialCompanyReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        let reportModule;

        if (isApplicantCustomer) {
          reportModule = `Applicant Customer`;
        } else if (isEmployeeCustomer) {
          reportModule = `Employee Customer`;
        } else {
          reportModule = `Customer`;
        }

        await exportCompanyReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `${reportModule} ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [
      chartQueryDetails?.id,
      exportCompanyReport,
      isApplicantCustomer,
      isEmployeeCustomer,
      setToastAlert,
      tableQueryDetails?.id,
    ]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      if (values?.selectedSubtables?.length > 0) {
        reportPayload = { ...reportPayload, selectedSubtables: values?.selectedSubtables };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        if (isApplicantCustomer) {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createApplicantCompanyTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Applicant Customer Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (isEmployeeCustomer) {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createEmployeeCompanyTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Employee Customer Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else {
          await createCompanyTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Customer Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
        selectedSubtables: values?.selectedSubtables || [],
      };

      if (values?.chartFilters) {
        reportPayload = {
          ...reportPayload,
          chartFilters: values?.chartFilters,
        };
      }

      if (values?.chartDateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.chartStartDate, // ISO formatted date already handled
            end: values?.chartEdDate, // ISO formatted date already handled
            field: values?.chartDateField,
            range: values?.chartDateRange,
          },
        };
      }

      try {
        if (isApplicantCustomer) {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createApplicantCompanyChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Applicant Customer Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (isEmployeeCustomer) {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createEmployeeCompanyChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Employee Customer Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else {
          await createCompanyChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Customer Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveCompanyReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        if (isApplicantCustomer) {
          _tableReportData = {
            ..._tableReportData,
            feature: `applicant-customers:${currentApplicant?._id}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `applicant-customers:${currentApplicant?._id}`,
          };
        } else if (isEmployeeCustomer) {
          _tableReportData = {
            ..._tableReportData,
            feature: `employee-customers:${currentApplicant?._id}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `employee-customers:${currentApplicant?._id}`,
          };
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveCompanyReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            let reportModule;

            if (isApplicantCustomer) {
              reportModule = `Applicant Customer`;
            } else if (isEmployeeCustomer) {
              reportModule = `Employee Customer`;
            } else {
              reportModule = `Customer`;
            }

            setToastAlert({
              isShow: true,
              message: `${reportModule} ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving job report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveCompanyReport, tableReportData, isApplicantCustomer]
  );

  const columns = [
    {
      title: "Logo",
      field: "name",
      customCell: (_name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    {
      title: "Company ID",
      field: "slug",
      customCell: (slug) => <div>{slug?.toUpperCase()}</div>,
    },
    { title: "Company Name", field: "name" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    {
      title: "Phone",
      field: "phone",
      customCell: (phone, field, row) => <div>{phone || row?.contact?.phone}</div>,
    },
    { title: "Client ID", field: "clientId" },
    {
      title: "Acct Mgr",
      field: "accountManager",
      customCell: (accountManager, field, row) => {
        if (!accountManager) return null;
        const { first, last } = splitName(accountManager);

        return (
          <PictureAvatar
            image={null}
            firstName={first}
            lastName={last}
            // userId={usr?._id?.toString()}
            size="md"
          />
        );
      },
    },

    {
      title: "Last Modified In",
      field: "modifiedDate",
      customCell: (date) =>
        date ? <span>{moment(date).format("ddd YYYY-MM-DD hh:mm A")}</span> : "",
    },
    {
      title: "Contact Email",
      field: "contactEmail",
      customCell: (email) => <div style={{ paddingLeft: "2rem" }}>{email}</div>,
    },
    {
      title: "Company Actions",
      field: "_id",
      customCell: (slug, field, row, yotro) => {
        return (
          <CustomerTableActions
            slug={row.slug}
            row={row}
            setCurrentCompany={setCurrentCompany}
            setModalInfo={setModalInfo}
            setNavigateUrl={navigateWithBack}
            setGenericModalOpen={setGenericModalOpen}
          />
        );
      },
    },
  ];
  const columnsForSearch = [
    {
      title: "Logo",
      field: "name",
      width: 40,
      customCell: (_name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    { title: "Company Slug", field: "slug", width: 100 },
    { title: "Customer Name", field: "name", width: 150 },
    { title: "Status", field: "status", width: 75 },
    { title: "Client ID", field: "clientId" },
    {
      title: "City/State",
      field: "city",
      width: 100,
      customCell: (city, row, field) =>
        city ? (
          <span>
            {city} {row?.state}
          </span>
        ) : (
          ""
        ),
    },
  ];

  const handleRowClick = (comp, e) => {
    e.stopPropagation();
    setCurrentCompany({ ...comp });
    const navigateFunc = parent === "Customers" ? navigateWithBack : navigateWithBack;
    navigateFunc(`/customers/${comp.slug}`);
  };

  const addNew = (e) => {
    setCurrentCompany({});
    const navigateFunc = parent === "Customers" ? navigateWithBack : navigateWithBack;
    navigateFunc("/customers/create");
    e.stopPropagation();
  };

  const handleClearTags = () => {
    const tempFilters = filters;
    delete tempFilters?.tags;
    setFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(false);
  };

  const handleAllTags = () => setUseOrTags(false);
  const handleAnyTags = () => setUseOrTags(true);

  const { slug } = useParams();

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
      setSelectedSubtables([]);
    }
  }, [openReportMessageModal]);

  const renderTitle = () => {
    let reportModule;

    if (isApplicantCustomer) {
      reportModule = `Applicant Customer Report for ${name}`;
    } else if (isEmployeeCustomer) {
      reportModule = `Employee Customer Report ${name}`;
    } else {
      reportModule = `Customer Report`;
    }

    return reportModule;
  };

  const getSubtables = () => {
    if (!isApplicantCustomer && !isEmployeeCustomer) {
      return [
        {
          label: "Contacts",
          value: "applicants",
          payload: "customer_contacts",
          identifier: "customerSlug",
        },
        {
          label: "Locations",
          value: "locations",
          payload: "customer_locations",
          identifier: "customerSlug",
        },
      ];
    }

    return [];
  };

  const handleSelectSubtable = (subtable) => {
    if (getSubtables()) {
      setSelectedSubtables((prev) => {
        // If the subtable is already selected, deselect it
        if (prev.length > 0 && prev[0].label === subtable.label) {
          return []; // Deselect the subtable by clearing the selection
        }

        // Otherwise, select the new subtable and replace any existing selection
        return [subtable];
      });
    }
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <AccountCircleIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {company?.name} Customers
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center" marginLeft={2}>
          {searchTagList?.length > 0 && (
            <TagListToggle
              tagsToggle={tagsToggle}
              setTagsToggle={setTagsToggle}
              onAny={handleAnyTags}
              onAll={handleAllTags}
              onClear={handleClearTags}
            />
          )}
          {!isSmallScreen && (
            <TagListSearch
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          {showSearchBar && (
            <Searchbar
              fetch={fetchCustomers}
              fetchAll
              placeholder="Search Customers"
              columns={columnsForSearch}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name", "city", "slug"]}
              filterBy="slug"
              onRowClick={(comp, e) => {
                setCurrentCompany(comp);
                navigateWithBack(`/customers/${comp?.slug}/action/customerinfo`);
                e.stopPropagation();
              }}
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>

          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <MDBox ml={3} flexWrap="wrap">
        {showFiltersList && (
          <Grid item xs={6}>
            <FiltersList
              filters={filters}
              setFilters={setFilters}
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              clearTagsHadler={handleClearTags}
            />
          </Grid>
        )}
      </MDBox>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={companies}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          defaultSort="name"
        />
      </MDBox>
      {/* <CompanyActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="companies"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          title={renderTitle()}
          filterCardList={
            isApplicantCustomer || isEmployeeCustomer
              ? []
              : ["Active", "Inactive", "Prospect", "All"]
          }
          subFilterCardList={
            isApplicantCustomer || isEmployeeCustomer
              ? []
              : [
                  "All Prospects",
                  "Prospect",
                  "Qualify",
                  "Proposal",
                  "Closed/Win",
                  "Closed/Lost",
                  "All",
                ]
          }
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveCompanyReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
          subTables={getSubtables()}
          selectedSubtables={selectedSubtables}
          handleSelectSubtables={handleSelectSubtable}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
CustomersTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  parent: null,
  filters: null,
  showFiltersList: true,
};

// Typechecking props
CustomersTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  parent: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default CustomersTable;
