export const eventInitialState = {
  event: {},
  events: [],
  eventsTableFilter: [],
  eventRoster: [],
  refetchEventsTable: () => "",
};

const eventReducer = (state, action) => {
  switch (action.type) {
    case "SET_EVENT":
      return {
        ...state,
        event: action.data,
      };
    case "SET_EVENT_ROSTER":
      return {
        ...state,
        eventRoster: action.data,
      };
    case "SET_REFETCH_EVENTS_TABLE":
      return {
        ...state,
        refetchEventsTable: action.data,
      };
    case "SET_EVENTS":
      return {
        ...state,
        events: action.data,
      };
    case "SET_EVENTS_TABLE_FILTERS":
      return {
        ...state,
        eventsTableFilter: action.data,
      };
    default:
      return state;
  }
};

export default eventReducer;
