import { useState, useEffect } from "react";
import _ from "lodash";
import DataList from "components/DataList";

const InfiniteScrollDataList = ({
  infiniteData,
  renderRow,
  onRowClick,
  dateSelected,
  icon,
  iconColor,
  title,
  limit,
  isLoading,
  greybar,
  divider,
  header,
  topButtonBar,
  tableHeight,
  height,
  width,
  img,
  keyField,
  scrollY,
  boxPadding,
  cardVariant,
  renderDataView,
  dataViewComponent,
  computedHeight,
  headerStyle,
  noLoading,
  noIcon,
  selected,
  tableHead,
  flexWrap,
  setPage,
  page,
  user,
  boxShadow,
  additionalRender,
  dataFilter
}) => {
  const [data, setData] = useState([]);

  const loadMore = _.debounce(
    () => {
      setPage(page + 1);
    },
    500,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    setData([]);
    if (setPage) setPage(1);
  }, [user, setPage,dataFilter]);

  useEffect(() => {
    if (infiniteData?.data) {
      setData((prevData) => [...prevData, ...infiniteData?.data]);
    }
  }, [infiniteData]);

  return (
    <DataList
      renderRow={renderRow}
      data={data}
      onRowClick={onRowClick}
      icon={icon}
      iconColor={iconColor}
      title={title}
      isLoading={isLoading}
      greybar={greybar}
      divider={divider}
      header={header}
      cardVariant={cardVariant}
      tableHeight={tableHeight}
      height={height}
      scrollY={scrollY}
      flexWrap={flexWrap}
      loadMore={loadMore}
      infiniteData={infiniteData}
      dateSelected={dateSelected}
      limit={limit}
      topButtonBar={topButtonBar}
      width={width}
      img={img}
      keyField={keyField}
      boxPadding={boxPadding}
      renderDataView={renderDataView}
      dataViewComponent={dataViewComponent}
      computedHeight={computedHeight}
      headerStyle={headerStyle}
      noLoading={noLoading}
      noIcon={noIcon}
      selected={selected}
      tableHead={tableHead}
      additionalRender={additionalRender}
      boxShadow={boxShadow}
    />
  );
};
export default InfiniteScrollDataList

