import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchApplicantsOnly = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
  searchProjection = false,
  includeDnu,
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters: { ...filters },
  });

  try {
    const res = await baseAxios.get(
      `/applicants${paginationQuery}${sortQuery}${filterQuery}${
        useOr ? "&useOr=true" : ""
      }&only=Applicant${searchProjection ? "&searchProjection=true" : ""}${
        includeDnu ? "&includeDnu=true" : ""
      }`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchApplicantsOnly;
