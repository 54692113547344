import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AvTimer } from "@mui/icons-material";
import { Box, Button, Grid, Stack, useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DataList from "components/DataList";
import MDTypography from "components/MDTypography";

import DateRangeIcon from "@mui/icons-material/DateRange";
import { MY_TIMECLOCK_OPTIONS } from "components/FilterButtons/options";
import FilterButtons from "components/FilterButtons";
import ListIcon from "@mui/icons-material/List";
import MyTimeclockActions from "../MyTimeclockActions";
import ClockInOutBanner from "../ClockInOutBanner";
import ClockInOutModal from "../ClockInOutModal";
import SuccessClockInOutModal from "../SuccessClockInOutModal";

const MyTimeclock = () => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [calendarListView, setCalendarListView] = useState("List");
  const [clockModalOpen, setClockModalOpen] = useState(false);
  const [successClockInModalOpen, setSuccessClockInOutModalOpen] = useState(false);
  const [inOutParent, setInOutParent] = useState(null);

  const timeclockhardcode = [
    {
      date: "Thursday - Jan 26, 2023",
      status: "Approval Pending",
      timeIn: "8:00 AM",
      timeOut: "5:00 PM",
      totalHours: "9",
      week: "36.5",
    },
    {
      date: "Wednesday - Jan 25, 2023",
      status: "Approval Pending",
      timeIn: "8:00 AM",
      timeOut: "5:30 PM",
      totalHours: "9.5",
      week: "27.5",
    },
    {
      date: "Tuesday - Jan 24, 2023",
      status: "Time Approved",
      timeIn: "8:15 AM",
      timeOut: "5:15 PM",
      totalHours: "9",
      week: "18",
    },
    {
      date: "Monday - Jan 23, 2023",
      status: "Time Rejected",
      timeIn: "7.45 AM",
      timeOut: "4.45 PM",
      totalHours: "9",
      week: "9",
    },
  ];

  const navigate = useNavigate();
  const viewAll = () => navigate("/timeclock");
  const viewEvent = () => console.log("viewEvent");
  const handleViewClick = () =>
    setCalendarListView((prev) => (prev === "List" ? "Calendar" : "List"));

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center" key={row._id}>
        <Grid item xs={12} sm={1.5}>
          <AvTimer fontSize="large" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={12} display="flex" alignItems="center">
              <MDTypography
                variant="subtitle2"
                sx={{ fontWeight: "bold" }}
                key={`${row._id}_${row.jobSlug}`}
              >
                {row.date}
              </MDTypography>
              <Button variant="text" color="primary" onClick={viewEvent}>
                View
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="body2" key={`${row._id}_${row.venueName}`}>
                    {`In: ${row.timeIn}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="body2" key={`${row._id}_${row.venueName}`}>
                    {`Out: ${row.timeOut}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="body2" key={`${row._id}_${row.venueName}`}>
                    {`Total Hours: ${row.totalHours}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="body2" key={`${row._id}_${row.venueName}`}>
                    {`Week: ${row.week}`}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Box sx={{ textAlign: "right" }} pr={2}>
            <MyTimeclockActions appointment={row} />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const header = (
    <Stack
      display="flex"
      flexDirection={!isSmallScreen && "row"}
      justifyContent="space-between"
      alignItems="center"
      m={1}
    >
      <FilterButtons
        options={MY_TIMECLOCK_OPTIONS}
        // filter={dataFilter}
        // setFilter={setDataFilter}
        // name="rosterStatus"
      />

      <Grid container onClick={handleViewClick} justifyContent="center" flexWrap="nowrap">
        <Grid item>
          {calendarListView === "Calendar" ? (
            <ListIcon mt={1} color="primary" fontSize="large" />
          ) : (
            <DateRangeIcon color="success" fontSize="large" />
          )}
        </Grid>
        <Grid item>
          <Tooltip title="Click to Toggle Calendar/List View" justifyContent="right">
            <MDTypography variant="h5" color="dark" sx={{ ml: 1, mt: 1 }} whiteSpace="nowrap">
              {`Show ${calendarListView === "Calendar" ? "List" : "Calendar"} View`}
            </MDTypography>
          </Tooltip>
        </Grid>
      </Grid>

      <Button variant="text" color="primary" onClick={viewAll}>
        View All
      </Button>
    </Stack>
  );

  const bannerInButton = (
    <Button
      variant="text"
      style={{
        color: "#4caf4f",
        backgroundColor: "#FFF",
      }}
      onClick={() => {
        setClockModalOpen(true);
        setInOutParent("in");
      }}
    >
      CLOCK IN NOW
    </Button>
  );

  const bannerOutButton = (
    <Button
      variant="text"
      style={{
        backgroundColor: "#FFF",
      }}
      onClick={() => {
        setClockModalOpen(true);
        setInOutParent("out");
      }}
    >
      CLOCK OUT NOW
    </Button>
  );

  return (
    <>
      <ClockInOutBanner
        color="success"
        message="You are scheduled for an upcoming shift today at 2:00PM"
        button={bannerInButton}
      />
      <ClockInOutBanner
        color="info"
        message="You are currently in for a shift"
        button={bannerOutButton}
      />
      <DataList
        renderRow={renderRow}
        data={timeclockhardcode}
        // filters={filters}
        icon={<AvTimer />}
        title="My Timeclock"
        // isLoading={isLoading}
        tableHeight={360}
        height={isLaptop ? 500 : null}
        greybar
        divider
        header={header}
        scrollY
      />
      <ClockInOutModal
        open={clockModalOpen}
        setOpen={setClockModalOpen}
        inOutParent={inOutParent}
        setSuccessClockInOutModalOpen={setSuccessClockInOutModalOpen}
      />
      <SuccessClockInOutModal
        open={successClockInModalOpen}
        setOpen={setSuccessClockInOutModalOpen}
        inOutParent={inOutParent}
        date={timeclockhardcode[0].date}
        time={timeclockhardcode[0].timeIn}
      />
    </>
  );
};

export default MyTimeclock;
