// import {useState} from "react";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// import ChatIcon from '@mui/icons-material/Chat';

const DropdownEntitiesTableActions = ({
  id,
  row,
  setCurrentDropdownList,
  toggleEditModal,
  toggleConfirmationModal,
}) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    setCurrentDropdownList(row);
    switch (action) {
      case "editDropdown":
        toggleEditModal(true);
        break;
      case "deleteDropdown":
        toggleConfirmationModal(true);
        break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="warning"
        icon="edit"
        clickAction={(e) => onClickHandler("editDropdown", e)}
        tooltip="Edit This Dropdown List"
      />
      <TableAction
        color="error"
        icon="delete"
        clickAction={(e) => onClickHandler("deleteDropdown", e)}
        tooltip="Delete Dropdown List"
      />
    </div>
  );
};

export default DropdownEntitiesTableActions;

DropdownEntitiesTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
DropdownEntitiesTableActions.propTypes = {
  id: PropTypes.string,
};
