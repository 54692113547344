import {
  Box,
  IconButton,
  InputBase,
  Paper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";

import VenueIcon from "components/VenueIcon";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import usePayrollExportStore from "stores/usePayrollExportStore";
import { useAppContextController } from "context/AppContext";
import MDBox from "components/MDBox";
import { Close, Search } from "@mui/icons-material";
import { useDebounce } from "use-debounce";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";

const Table = styled(DataGrid)(() => ({
  "& .MuiDataGrid-renderingZone": {
    minHeight: "75px !important",
    height: "auto",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "unset !important",
    minHeight: "75px !important",
    height: "auto",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-row": {
    minHeight: "75px !important",
    height: "auto",
  },
}));

const ShiftJobsDataGrid = () => {
  const { venues } = useAppContextController();
  const { setEventIds, toDate, fromDate } = usePayrollExportStore((state) => ({
    toDate: state.toDate,
    fromDate: state.fromDate,
    setEventIds: state.setEventIds,
  }));

  const payload = {
    shiftJobStartDate: moment(fromDate).startOf("day").toISOString(),
    shiftJobEndDate: moment(toDate).endOf("day").toISOString(),
    filters: { shiftJob: "Yes" },
    fetchAll: true,
  };

  const {
    data: jobsData,
    isLoading,
    refetch: refetchJobs,
  } = useQuery(
    ["payrollShiftJobs", payload],
    () => {
      return fetchJobs({ ...payload, venues });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo(
    () => [
      {
        headerName: "Venue",
        field: "logoUrl",
        width: 250,
        renderCell: ({ value, row }) => (
          <FlexWrapper flexWrap="nowrap" justifyContent="start" sx={{ gap: "10px" }}>
            <VenueIcon logoUrl={value} slug={row?.venueSlug} name={row?.venueName} />
            <Typography
              sx={{
                fontSize: "16px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {row?.venueSlug?.toUpperCase()}
            </Typography>
          </FlexWrapper>
        ),
      },
      {
        headerName: "Title",
        field: "title",
        width: 300,
      },
      {
        headerName: "Date Created",
        field: "createdDate",
        width: 290,
        renderCell: ({ value, row }) => (value ? moment(value).format("YYYY-MM-DD") : ""),
      },
      {
        headerName: "Start Date",
        field: "startDate",
        width: 290,
        renderCell: ({ value, row }) => (value ? moment(value).format("YYYY-MM-DD") : ""),
      },
      {
        headerName: "End Date",
        field: "endDate",
        width: 290,
        renderCell: ({ value, row }) => (value ? moment(value).format("YYYY-MM-DD") : ""),
      },
      { headerName: "Status", field: "status" },
      {
        headerName: "Location",
        field: "venueCity",
        align: "center",
        width: 150,
        renderCell: ({ value, row }) => (
          <span>
            {row.venueState}, {value}
          </span>
        ),
      },
    ],
    []
  );

  const [input, setInput] = useState("");
  const [inputValue] = useDebounce(input, 1000);

  const parsedRows = useMemo(() => {
    let { data } = jobsData ?? {};
    if (!data?.length) return [];
    if (inputValue) {
      const regex = new RegExp(inputValue, "i");
      data = data.filter(
        (item) =>
          (item.title && regex.test(item.title)) ||
          (item.jobSlug && regex.test(item.jobSlug)) ||
          (item.venueSlug && regex.test(item.venueSlug)) ||
          (item.venueName && regex.test(item.venueName))
      );
    }
    return data.map((item) => ({
      ...item,
      id: item._id,
    }));
  }, [jobsData, inputValue]);

  return (
    <Box
      sx={{
        height: "400px",
        width: "100%",
      }}
    >
      <MDBox sx={{ width: "100%", position: "relative", marginBottom: 2 }}>
        <Paper
          sx={{
            p: "2px 4px !important",
            display: "flex",
            alignItems: "center",
            width: "100%",
            background: "rgba(0, 0, 0, 0.04)",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, p: "2px" }}
            placeholder="Search"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          {input.length !== 0 && (
            <IconButton onClick={(e) => setInput("")}>
              <Close />
            </IconButton>
          )}
        </Paper>
      </MDBox>
      <Box
        sx={{
          height: "350px",
          width: "100%",
        }}
      >
        <Table
          rows={parsedRows}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(val) => setEventIds(val)}
          disableSelectionOnClick
          getRowHeight={() => "auto"}
          disableRowSelectionOnClick
          disableColumnFilter
          keepNonExistentRowsSelected
        />
      </Box>
    </Box>
  );
};

export default ShiftJobsDataGrid;
