import { baseAxios } from "config";

const setApplicantsBulkStatus = async (body) => {
  try {
    const res = await baseAxios.post(`/applicants/bulk`, body);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default setApplicantsBulkStatus;
