import { styled } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import deleteNotification from "api/notifications/deleteNotification";
import ConfirmationModal from "components/ConfirmationModal";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useNotificationContext } from "context/NotificationContext";
import useTableFunctions from "hooks/useTableFunctions";
import fetchNotifications from "layouts/pages/dashboard/actions/fetchNotifications";
import { NOTIFICATION_ACTIONS } from "reducers/notificationReducer";
import { removeHtmlTags } from "utils/string";
import updateNotification from "api/notifications/updateNotification";
import useSessionAuth from "hooks/useSessionAuth";
import NotificationTableActions from "./TableActions";

// const StyledTypography = styled(MDTypography)(({ isRead }) => ({
//   ...(isRead && {
//     fontStyle: "italic",
//     fontWeight: 700,
//   }),
// }));

const StyledTypography = styled(({ isRead, ...otherProps }) => <MDTypography {...otherProps} />)(
  ({ isRead }) => ({
    ...(isRead && {
      fontStyle: "italic",
      fontWeight: 700,
    }),
  })
);

const NotificationsTable = () => {
  const { currentLoggedUser } = useAppContextController();

  const { page, setPage, limit, setLimit, order, orderBy, toggleSort, pageLimitConfirmationModal } =
    useTableFunctions({
      defaultLimit: 10,
    });
  const {
    setNotification,
    setActiveActions,
    tableFilter,
    notification,
    setNotifications,
    setTableRefetch,
  } = useNotificationContext();
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);

  const options = { orderBy, order, page, limit };

  const allOptions = {
    ...options,
    filters: { userId: currentLoggedUser?._id, ...tableFilter },
  };
  const {
    data: notifications = { data: [] },
    isLoading,
    refetch,
  } = useQuery(["notificationsTable", allOptions], () => fetchNotifications(allOptions), {});

  const handleRowClick = (row, e) => {
    setNotification(row);
    setActiveActions([NOTIFICATION_ACTIONS.NOTIFICATION_INFO]);
  };

  const { logoutAuth0User } = useSessionAuth();

  const handleDelete = useCallback(async () => {
    try {
      // const response = await deleteNotification({
      //   notificationId: notification._id,
      // });
      const body = {
        status: "Deleted",
      };

      const response = await updateNotification({ notificationId: notification._id, body });
      if (response.data.modifiedCount || response.data.acknowledged) {
        refetch();
      }
    } catch (err) {
      console.error(err);
      if (String(err).includes("401") || err?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [notification, refetch]);

  useEffect(() => {
    setPage(1);
  }, [tableFilter]);

  useEffect(() => {
    setNotifications(notifications?.data);
  }, [notifications]);

  useEffect(() => {
    setTableRefetch(refetch);
  }, [refetch]);

  const columns = useMemo(
    () => [
      {
        title: "From",
        field: "fromUser",
        customCell: (id, field, row) => (
          <FlexWrapper flexWrap="nowrap" columnGap="10px" justifyContent="flex-start">
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.fromFirstName}
              lastName={row?.fromLastName}
              userId={row?.fromUserId}
              size="md"
            />
            <StyledTypography
              variant="body"
              isRead={row.status === "unread"}
              sx={{ whiteSpace: "nowrap" }}
            >
              {row.fromFirstName} {row.fromLastName}
            </StyledTypography>
          </FlexWrapper>
        ),
      },
      {
        title: "Subject",
        field: "subject",
        customCell: (value, field, row) => (
          <StyledTypography
            variant="body"
            isRead={row.status === "unread"}
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {value}
          </StyledTypography>
        ),
      },
      {
        title: "Body",
        field: "body",
        customCell: (value, field, row) => (
          <StyledTypography
            variant="body"
            isRead={row.status === "unread"}
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {value ? removeHtmlTags(value) : ""}
          </StyledTypography>
        ),
        width: "25%",
      },
      {
        title: "Date",
        field: "sendTime",
        customCell: (value, field, row) => (
          <StyledTypography variant="body" isRead={row.status === "unread"}>
            {moment(value).format("ddd YYYY-MM-DD hh:mm A")}
          </StyledTypography>
        ),
        width: "15%",
      },
      {
        title: "Actions",
        field: "actions",
        customCell: (value, field, row) => (
          <NotificationTableActions
            row={row}
            refetch={refetch}
            toggleConfirmationModal={toggleConfirmationModal}
          />
        ),
        width: "10%",
      },
    ],
    [refetch]
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={notifications}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        isLoading={isLoading}
        onRowClick={(row, e) => handleRowClick(row, e)}
        order={order}
        orderBy={orderBy}
        toggleSort={toggleSort}
        fetchAll={false}
        defaultSort="sendTime:desc"
      />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title="Are you sure you want to delete this notification?"
        onConfirm={() => {
          handleDelete();
          toggleConfirmationModal(false);
          setActiveActions([]);
          setNotification({});
        }}
      />
      {pageLimitConfirmationModal}
    </>
  );
};

export default NotificationsTable;
