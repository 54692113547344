import axios from "axios";
import { baseAxios } from "config";

const uploadFileCompanyJob = async ({ jobSlug, uploadForm }) => {
  try {
    const res = await baseAxios.post(`/upload/${jobSlug}/jobs/other`, uploadForm);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default uploadFileCompanyJob;
