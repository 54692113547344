import { forwardRef } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";

// import { useMaterialUIController } from "context";

const TableAction = forwardRef(
  (
    {
      id,
      color,
      icon,
      IconComponent,
      size,
      tooltip,
      showBadge,
      badgeNumber = 0,
      clickAction,
      disabled,
      condensed,
      ...rest
    },
    ref
  ) => {
    // const [controller] = useMaterialUIController();
    // const { darkMode } = controller;

    const childIcon = icon && (
      <>
        <Icon
          sx={{ marginLeft: "5px", marginRight: showBadge ? "3px" : "5px", opacity: disabled ? '0.5' : '1' }}
          fontSize={size}
          color={disabled ? "secondary" : color}
          // ownerState={{ color, size, darkMode }}
          onClick={(e) => {
            if (disabled) return;
            clickAction(e, id);
          }}
          key={`action_icon_${id}`}
        >
          {icon}
        </Icon>
      </>
    );

    return (
      <Tooltip title={tooltip} placement="top">
        <MDBadge
          badgeContent={badgeNumber}
          color="info"
          size={condensed ? "xs" : "sm"}
          sx={{ marginLeft: "5px", marginRight: showBadge ? "10px" : "5px" }}
          invisible={!showBadge || !badgeNumber}
          // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          slotProps={condensed ? {
            badge: {
              sx: { marginTop: "5px" }
            }
          } : undefined}
          circular
        >
          {childIcon}
        </MDBadge>
      </Tooltip>
    );
  }
);

// Setting default values for the props of TableAction
TableAction.defaultProps = {
  id: "",
  size: "large",
  icon: "",
  tooltip: "",
  showBadge: false,
  badgeNumber: 0,
  clickAction: () => {},
  // variant: "contained",
  color: "primary",
};

// Typechecking props for the TableAction
TableAction.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltip: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeNumber: PropTypes.number,
  clickAction: PropTypes.func,
  // variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "danger",
    "error",
    "light",
    "dark",
  ]),
  // children: PropTypes.node.isRequired,
};

export default TableAction;
