import {
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomTextField from "components/Form/CustomTextField";
import ConditionalWrapper from "components/Wrapper/ConditionalWrapper";
import { Controller } from "react-hook-form";

const AvailabilityField = ({
  applicantId,
  availability,
  control,
  setValue,
  setAvailability,
  padding = 3,
}) => {
  const handleAvailabilityChange = (e) => {
    const currentDate = new Date();

    setAvailability(e.target.value);
    if (e.target.value === "enterDate") {
      setValue("availability", null, { shouldDirty: true });
    }
    if (e.target.value === "Immediate") {
      setValue("availability", currentDate.toISOString(), { shouldDirty: true });
    }
    if (e.target.value === "WithinTwoWeeks") {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 15);
      setValue("availability", newDate.toISOString(), { shouldDirty: true });
    }
    if (e.target.value === "Within30Days") {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 30);
      setValue("availability", newDate.toISOString(), { shouldDirty: true });
    }
  };

  return (
    <>
      <Box px={padding} sx={{ width: "100%" }}>
        <Typography variant="h6" textAlign="start">
          Availability{" "}
        </Typography>
      </Box>
      <Box px={padding} sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby={`availability_${applicantId}`}
          name="availability"
          value={availability}
          onChange={handleAvailabilityChange}
        >
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel value="Immediate" control={<Radio />} sx={{ marginRight: "0" }} />
                <Typography fontWeight="bold" fontSize="0.875rem">
                  Immediate
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel
                  value="WithinTwoWeeks"
                  control={<Radio />}
                  sx={{ marginRight: "0" }}
                />
                <Typography fontWeight="bold" fontSize="0.875rem">
                  Within the next two weeks
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel
                  value="Within30Days"
                  control={<Radio />}
                  sx={{ marginRight: "0" }}
                />
                <Typography fontWeight="bold" fontSize="0.875rem">
                  Within the next 30 days
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel value="enterDate" control={<Radio />} sx={{ marginRight: "0" }} />
                <Typography fontWeight="bold" fontSize="0.875rem">
                  Enter Date
                </Typography>
                <Controller
                  name="enterDate"
                  control={control}
                  disabled={availability !== "enterDate"}
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        {...field}
                        label="Enter Date"
                        renderInput={(params) => (
                          <CustomTextField variant="outlined" noErrorIcon {...params} />
                        )}
                        onChange={(...props) => {
                          const [value, textValue] = props;
                          if (value && value.isValid && value.isValid()) {
                            setValue("availability", value.toISOString(), {
                              shouldDirty: true,
                            });
                          } else {
                            setValue("availability", null, { shouldDirty: true });
                          }
                          field.onChange(...props);
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>
    </>
  );
};

export default AvailabilityField;
