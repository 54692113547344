import axios from "axios";
import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchPrimaryCompany = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
  outsideMode = "",
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters: { primaryCompany: true },
  });

  try {
    const outsideModeQuery = outsideMode ? `outside-${outsideMode}/` : "";

    const res = await baseAxios.get(`/${outsideModeQuery}companies/primary`);

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchPrimaryCompany;
