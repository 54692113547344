import { useCallback, useEffect, useState, useRef } from "react";
import {
  AddCircleRounded as AddCircleRoundedIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import { useAppContextController } from "context/AppContext";
import { isEmpty, isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import DataList from "components/DataList";
import CustomTextField from "components/Form/CustomTextField";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import TagList from "components/TagList";
import { noValidations, partnersInfoSchema } from "data/partnerInfo";
import { makeStyles } from "@mui/styles";
import useDropdowns from "hooks/useDropdowns";
import useCompanyCache from "hooks/useCompanyCache";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import createPartner from "layouts/pages/partners/actions/createPartner";
import { PARTNERS_ENTITIES } from "utils/constants/entityOptions";
// import CompanyLogoSquare from "components/CompanyLogoSquare";
import generateSlug from "utils/generateSlug";
import { getDirtyFields } from "utils/helpers/formHelpers";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";
import CircularLoader from "components/CircularLoader";
import Attachments from "../Attachments";
import PartnerPreview from "../../PartnerPreview";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 175,
  },
  dropzone: {
    height: 170,
  },
  previewButton: {
    padding: 0,
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
  },
});

const { STATES_ENTITY_NAME, PARTNER_STATUS_ENTITY_NAME } = PARTNERS_ENTITIES;

const PartnerForm = ({ setRefetchPartners, refreshPartner }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const classes = useStyle();

  const {
    currentPartner,
    setCurrentPartner,
    currentLoggedUser,
    company,
    setCompany,
    setCompanyType,
    userType,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const isEdit = !!currentPartner?._id;

  const { dropdowns: stateCodes } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const { dropdowns: statusOptions } = useDropdowns({ entity: PARTNER_STATUS_ENTITY_NAME });
  const resolver = useYupValidationResolver(
    isEdit ? noValidations : partnersInfoSchema(stateCodes)
  ); // No validation schema if in edit mode
  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({ resolver, mode: "onBlur", defaultValues: currentPartner });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [notificationRecipientsModalOpen, setNotificationRecipientsModalOpen] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [hasAddedRecipientPermission, setHasAddedRecipientPermission] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const customerLocationListRef = useRef([]);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentMessageApplicant, setCurrentMessageApplicant] = useState(null);
  const [loadingMessageApplicant, setLoadingMessageApplicant] = useState(null);
  const defaultValues = {
    address: "",
    city: "",
    state: null,
    zip: "",
    createDate: "",
    description: "<p></p>",
    email: "",
    forProfit: "No",
    leader: { firstName: "", lastName: "", email: "", phone: "" },
    name: "",
    slug: "",
    status: "Active",
    type: "",
    partnerId: currentPartner?.partnerId || "",
    payrollFirstName: "",
    payrollLastName: "",
    fein: "",
  };

  const handleOpenMessageModal = async (value) => {
    try {
      if (value) {
        setLoadingMessageApplicant(true);
        setCurrentMessageApplicant(null);
        const email = currentPartner?.leader?.email;
        const applicantsResult = await fetchApplicants({
          filters: { email, status: "Partner" },
          fetchAll: true,
        });
        if (applicantsResult && applicantsResult.data && applicantsResult.data.length) {
          // Applicant exists
          setCurrentMessageApplicant(applicantsResult.data[0]);
        } else {
          // Applicant doesn't exist. Create one and select it
          const data = {
            email: currentPartner?.leader?.email,
            phone: currentPartner?.leader.phone,
            address: currentPartner?.address,
            city: currentPartner?.city,
            state: currentPartner?.state,
            zip: currentPartner?.zip,
            firstName: currentPartner?.leader.firstName,
            lastName: currentPartner?.leader.lastName,
            status: "Partner",
            modifiedDate: new Date(),
            createdDate: new Date(),
            recordLocked: "No",
            payrollFirstName: currentPartner?.payrollFirstName,
            payrollLastName: currentPartner?.payrollLastName,
            fein: currentPartner?.fein,
          };
          data.jobs = [];
          data.events = [];
          data.venues = [];
          data.createAgent = currentLoggedUser._id;
          const resultCreate = await createApplicant(data);
          setCurrentMessageApplicant({ ...data, _id: resultCreate.insertedId });
        }
        setOpenMessageModal(true);
        setLoadingMessageApplicant(false);
      } else {
        setOpenMessageModal(value);
      }
    } catch (e) {
      setLoadingMessageApplicant(false);
    }
  };

  const updatePartnerMutation = useMutation(updatePartner, {
    onError: (err) =>
      enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries(["partners"]);
      await queryClient.invalidateQueries(["getPartnerApplicantDetails"]);
      await queryClient.invalidateQueries(["getPartnerUserDetails"]);
      if (data.status === "Deleted") {
        setCurrentPartner({});
        enqueueSnackbar("Partner was archived", { variant: "success" });
        navigate(`/partners`);
      } else {
        setCurrentPartner({ ...currentPartner, ...data, modifiedDate: new Date() });
        refreshPartner?.();
        enqueueSnackbar("Partner has been updated!", { variant: "success" });
      }
    },
  });

  const editPartnerHandler = async (values) => {
    if (!currentPartner._id) {
      enqueueSnackbar("Cannot Update Partner -- No ID Present!", { variant: "error" });
      return;
    }

    const data = getDirtyFields(values, dirtyFields);

    if (data.partnerId) {
      data.partnerId = +data.partnerId;
    }

    await updatePartnerMutation.mutateAsync({
      id: currentPartner._id,
      data,
    });
  };

  const createPartnerMutation = useMutation(createPartner, {
    onError: (err) => {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("partners");
      enqueueSnackbar("Partner has been created!", { variant: "success" });
      navigate(`/partners/${data.slug}`);
      setCurrentPartner({
        ...currentPartner,
        ...data,
        _id: _.insertedId,
      });
      refreshPartner?.(_.insertedId);
    },
  });

  const createPartnerHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser._id;

    if (data.partnerId) {
      data.partnerId = +data.partnerId;
    }

    await createPartnerMutation.mutateAsync(data);
  };

  const deleteModalHandler = () => setOpenDeleteModal(!openDeleteModal);
  const discardModalHandler = () => setOpenDiscardModal(!openDiscardModal);
  const cancelModalHandler = () => setOpenCancelModal(!openCancelModal);
  const addNotificationRecipients = () => setNotificationRecipientsModalOpen(true);
  const previewModalHandler = () => setOpenPreview(!openPreview);

  const deletePartnerHandler = async (values) => {
    await updatePartnerMutation.mutateAsync({
      id: currentPartner._id,
      data: { status: "Deleted" },
    });
    deleteModalHandler();
  };

  useEffect(() => {
    if (!currentPartner?._id) reset(defaultValues);
    else reset({ ...defaultValues, ...currentPartner });
  }, [currentPartner]);

  return (
    <>
      <form onSubmit={handleSubmit(isEdit ? editPartnerHandler : createPartnerHandler)}>
        <MDBox display="flex" alignItems="center" justifyContent="flex-end" mt={4}>
          {!isEdit && (
            <>
              <Button
                variant="text"
                startIcon={<CancelIcon />}
                onClick={cancelModalHandler}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                endIcon={<SaveIcon />}
                style={{ color: "white" }}
                disabled={isSubmitting}
              >
                Create
              </Button>
            </>
          )}
          {/* {isEmpty(dirtyFields) && !hasAddedRecipientPermission && (
                    <MDBox display="flex" alignItems="center" justifyContent="flex-end" py={1} px={3} mt={4}>
                        {isEdit && isEqual(userType, "Master") && company?.primaryCompany && (
                            <Button
                                variant="text"
                                endIcon={<DeleteIcon />}
                                onClick={deleteModalHandler}
                                style={{ color: "red" }}
                                disabled={isSubmitting}
                                sx={{ padding: "0.5rem 1rem" }}
                            >
                                Delete Partner
                            </Button>
                        )}

                    </MDBox>
                )} */}
          {!isEmpty(dirtyFields) || hasAddedRecipientPermission ? (
            <>
              {isEdit && isEqual(userType, "Master") && (
                <Button
                  variant="text"
                  startIcon={<DeleteIcon />}
                  onClick={discardModalHandler}
                  style={{ color: "red", fontSize: "0.85rem" }}
                  disabled={isSubmitting}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Discard
                </Button>
              )}
              {isEdit && (
                <MDBox display="flex" alignItems="center">
                  <Button
                    variant="text"
                    type="submit"
                    style={{ color: "green" }}
                    startIcon={<SaveIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </MDBox>
              )}
            </>
          ) : (
            <>
              {/* {isEdit ? (
                <Button
                  onClick={handleOpenMessageModal}
                  disabled={loadingMessageApplicant || !currentPartner?.leader?.email}
                  // sx={{ padding: 0 }}
                >
                  <CircularLoader
                    hasBackdrop={false}
                    isLoading={loadingMessageApplicant}
                    color="info"
                    size={20}
                  />
                  <EmailIcon color="warning" />
                  <MDTypography variant="body" ml={1} color="warning">
                    {" "}
                    SEND MESSAGE
                  </MDTypography>
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  console.log("preview");
                }}
                // sx={{ padding: 0 }}
              >
                <VisibilityIcon />
                <MDTypography variant="body" ml={1} color="info">
                  {" "}
                  PREVIEW PARTNER
                </MDTypography>
              </Button> */}
              {isEdit ? (
                <Button
                  variant="text"
                  onClick={deleteModalHandler}
                  style={{ color: "red" }}
                  disabled={isSubmitting}
                >
                  <DeleteIcon />
                  <MDTypography variant="body" ml={1} color="error">
                    DELETE PARTNER
                  </MDTypography>
                </Button>
              ) : null}
            </>
          )}
        </MDBox>
        {/* </FlexWrapper> */}
        <MDBox px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                Partner Information
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Partner"
                        value={field.value}
                        InputLabelProps={{ shrink: !!getValues("name") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.name && (
                    <StyledTypographyError>{errors?.name.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={statusOptions || []}
                        autoSelect
                        freeSolo={false}
                        name="status"
                        value={field.value ?? ""}
                        onChange={(e, v) => {
                          field.onChange(v);
                        }}
                        onBlur={() => trigger("status")}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            type="text"
                            label="Status"
                            InputLabelProps={{ shrink: getValues("status") }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.status && (
                    <StyledTypographyError>{errors?.status.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="partnerId"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        {...field}
                        disabled={isEdit && !!currentPartner.partnerId}
                        inputProps={{ tabIndex: -1 }}
                        value={field.value}
                        key={`_id${currentPartner?._id}`}
                        type="text"
                        InputLabelProps={{ shrink: !!getValues("partnerId") }}
                        label=" Partner ID"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        value={field.value}
                        label="email"
                        InputLabelProps={{ shrink: !!getValues("email") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.email && (
                    <StyledTypographyError>{errors?.email.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="slug"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        {...field}
                        disabled={isEdit}
                        inputProps={isEdit && { tabIndex: -1 }}
                        type="text"
                        label="Slug"
                        value={field.value}
                        InputLabelProps={{ shrink: !!getValues("slug") }}
                      />
                    )}
                  />
                  {errors?.slug && (
                    <StyledTypographyError>{errors?.slug.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="payrollFirstName"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        {...field}
                        disabled={isEdit && !!currentPartner.payrollFirstName}
                        inputProps={isEdit && { tabIndex: -1 }}
                        type="text"
                        label="Payroll First Name"
                        value={field.value}
                        InputLabelProps={{ shrink: !!getValues("payrollFirstName") }}
                      />
                    )}
                  />
                  {errors?.payrollFirstName && (
                    <StyledTypographyError>
                      {errors?.payrollFirstName.message}
                    </StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="payrollLastName"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        {...field}
                        disabled={isEdit && !!currentPartner.payrollLastName}
                        inputProps={isEdit && { tabIndex: -1 }}
                        type="text"
                        label="Payroll Last Name"
                        value={field.value}
                        InputLabelProps={{ shrink: !!getValues("payrollLastName") }}
                      />
                    )}
                  />
                  {errors?.payrollLastName && (
                    <StyledTypographyError>{errors?.payrollLastName.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="fein"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        {...field}
                        disabled={isEdit && !!currentPartner.fein}
                        inputProps={isEdit && { tabIndex: -1 }}
                        type="text"
                        label="FEIN"
                        value={field.value}
                        InputLabelProps={{ shrink: !!getValues("fein") }}
                      />
                    )}
                  />
                  {errors?.fein && (
                    <StyledTypographyError>{errors?.fein.message}</StyledTypographyError>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="leader.firstName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        label="Leader First Name"
                        showError
                        value={field.value}
                        errorMessage={error?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="leader.lastName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        type="text"
                        label="Leader Last Name"
                        showError
                        value={field.value}
                        errorMessage={error?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="leader.email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        type="text"
                        label="Leader Email"
                        showError
                        value={field.value}
                        errorMessage={error?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="leader.phone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        mask="_"
                        label="Leader Phone"
                        {...field}
                        onValueChange={({ value }) => field.onChange(value)}
                        customInput={FormField}
                      />
                    )}
                  />
                  {errors?.leader?.phone && (
                    <StyledTypographyError>{errors?.leader?.phone?.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Address"
                        value={field.value || ""}
                        InputLabelProps={{ shrink: !!getValues("address") }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        value={field.value}
                        label="City"
                        InputLabelProps={{ shrink: !!getValues("city") }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={stateCodes || []}
                        autoSelect
                        freeSolo
                        name="state"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value.toUpperCase());
                        }}
                        onBlur={() => trigger("state")}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            type="text"
                            label="State"
                            InputLabelProps={{ shrink: getValues("state") }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.state && (
                    <StyledTypographyError>{errors?.state.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="zip"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        value={field.value}
                        label="Zip Code"
                        InputLabelProps={{ shrink: !!getValues("zip") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.zip && (
                    <StyledTypographyError>{errors?.zip.message}</StyledTypographyError>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MDTypography component="label" variant="body2">
                    Description
                  </MDTypography>
                  <Button onClick={previewModalHandler} sx={{ padding: 0 }}>
                    PREVIEW
                  </Button>
                </Stack>

                <MDBox mb={2}>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue="<p></p>"
                    render={({ field }) => (
                      <MDEditor
                        {...field}
                        theme="snow"
                        placeholder="Write Partner Description"
                        style={{ height: isEdit ? 120 : 185 }}
                      />
                    )}
                  />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </form>
      {openDeleteModal && (
        <ConfirmModal
          title="Delete Partner"
          description="Please confirm you want to DELETE this partner."
          isOpen={openDeleteModal}
          negativeBtn={{
            label: "Cancel",
            fn: deleteModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deletePartnerHandler,
            isShown: true,
          }}
        />
      )}
      {openDiscardModal && (
        <ConfirmModal
          title="Data has Changed"
          description="Please confirm you want to abandon changes."
          isOpen={openDiscardModal}
          negativeBtn={{
            label: "Cancel",
            fn: discardModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              reset(currentPartner);
              navigate(`/partners/${currentPartner?._id}`);
              discardModalHandler();
            },
            isShown: true,
          }}
        />
      )}

      {openCancelModal && (
        <ConfirmModal
          title="You are leaving create mode"
          description="Please confirm you want to abandon new partner."
          isOpen={openCancelModal}
          negativeBtn={{
            label: "Cancel",
            fn: cancelModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              setCurrentPartner({});
              navigate("/partners");
              cancelModalHandler();
            },
            isShown: true,
          }}
        />
      )}

      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}
      {openPreview && (
        <PartnerPreview
          open={openPreview}
          closeHandler={previewModalHandler}
          currentPreview={watch()}
          customer={currentCompany}
        />
      )}

      {openMessageModal && currentMessageApplicant ? (
        <ApplicantActionModal
          open={openMessageModal}
          setOpen={handleOpenMessageModal}
          modalInfo={{ type: "applicantmail", data: currentMessageApplicant }}
          setToastAlert={enqueueSnackbar}
          currentApplicant={currentMessageApplicant}
          messageContext="Applicant"
          attachmentContext="Applicant"
        />
      ) : null}
    </>
  );
};

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

export default PartnerForm;
