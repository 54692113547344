import { useEffect, useState } from "react";
import { Card, Grid, TextareaAutosize } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import { useAppContextController } from "context/AppContext";
import GetIcons from "components/Activities/GetIcons";
import EntityName from "components/Activities/EntityName";
import MDBox from "components/MDBox";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import MDEditor from "components/MDEditor";
import fetchCustomers from "api/companies/fetchCustomers";

const ActivitiesInformation = () => {
  const { currentActivity, company } = useAppContextController();
  const [activityTypeValue, setActivityTypeValue] = useState(null);
  const [activityApplicant, setActivityApplicant] = useState(null);
  const [activityJob, setActivityJob] = useState(null);
  const [activityVenue, setActivityVenue] = useState(null);
  const [activityCustomer, setActivityCustomer] = useState(null);
  const [activityTemplate, setActivityTemplate] = useState(null);
  const [activityEvent, setActivityEvent] = useState(null);

  useEffect(() => {
    if (currentActivity?.integration === "Indeed") {
      setActivityTypeValue("Internet");
    }
    if (currentActivity?.applicantId) {
      fetchApplicants({ filters: { _id: currentActivity?.applicantId } }).then((applicant) => {
        setActivityApplicant(applicant.data[0]);
        setActivityTypeValue(`${applicant.data[0]?.firstName} ${applicant.data[0]?.lastName}`);
      });
    } else {
      setActivityApplicant(null);
    }
    if (currentActivity?.jobId) {
      fetchJobs({ filters: { _id: currentActivity?.jobId } }).then((job) => {
        setActivityJob(job.data[0]);
        setActivityTypeValue(job.data[0]?.title);
      });
    } else {
      setActivityJob(null);
    }
    if (currentActivity?.venueId) {
      fetchVenues({
        filters: { _id: currentActivity?.venueId },
        imageUrlBase: company?.imageUrl,
      }).then((venue) => {
        setActivityVenue(venue.data[0]);
        setActivityTypeValue(venue.data[0].name);
      });
    } else {
      setActivityVenue(null);
    }
    if (currentActivity?.companyId) {
      fetchCustomers({
        filters: { _id: currentActivity?.companyId },
      }).then((customer) => {
        setActivityCustomer(customer.data[0]);
        setActivityTypeValue(customer.data[0].name);
      });
    } else {
      setActivityCustomer(null);
    }
    if (currentActivity?.templateId) {
      // to be modified when having a fetchTemplate
      setActivityTemplate(`${currentActivity?.userFirstName} ${currentActivity?.userLastName}`);
      setActivityTypeValue(`${currentActivity?.userFirstName} ${currentActivity?.userLastName}`);
    } else {
      setActivityTemplate(null);
    }
    if (currentActivity?.eventId) {
      if (currentActivity?.action !== "Delete Event")
        fetchEvents({ filters: { _id: currentActivity?.eventId } }).then((event) => {
          setActivityEvent(event.data[0]);
          setActivityTypeValue(event.data[0]?.eventName);
        });
      else {
        setActivityEvent({ ...currentActivity?.detail });
        setActivityTypeValue(currentActivity?.eventName);
      }
    } else {
      setActivityEvent(null);
    }
    if (currentActivity?.type === "Message") {
      setActivityTypeValue("Message");
    }
  }, [currentActivity]);

  return (
    <Card key={`card_${currentActivity?._id}`}>
      <MDBox sx={{ p: "1.5rem", pt: 0, height: 660 }}>
        <MDBox display="flex" justifyContent="space-between" px={3}>
          <MDBox display="flex" pt={2}>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              color="info"
              borderRadius="xl"
            >
              <GetIcons action={currentActivity?.action} activity={currentActivity} />
            </MDBox>
            {/* {JSON.stringify(activityEvent)} */}
            <MDBox>
              <EntityName
                activity={currentActivity}
                action={currentActivity?.action}
                activityApplicant={activityApplicant}
                activityJob={activityJob}
                activityEvent={activityEvent}
                activityVenue={activityVenue}
                activityCustomer={activityCustomer}
                activityTemplate={activityTemplate}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <Grid container spacing={2} p={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              disabled
              inputProps={{ tabIndex: -1 }}
              label="Activity Date"
              value={currentActivity?.activityDate || ""}
              InputLabelProps={{ shrink: currentActivity?.activityDate }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              disabled
              inputProps={{ tabIndex: -1 }}
              label="User"
              value={`${currentActivity?.userFirstName ?? ""} ${
                currentActivity?.userLastName ?? ""
              }`}
              InputLabelProps={{ shrink: currentActivity?.user }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              disabled
              inputProps={{ tabIndex: -1 }}
              label="Type"
              value={currentActivity?.type || ""}
              InputLabelProps={{ shrink: currentActivity?.type }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              disabled
              inputProps={{ tabIndex: -1 }}
              label={currentActivity?.type || ""}
              value={activityTypeValue || currentActivity.userId}
              InputLabelProps={{ shrink: currentActivity?.action }}
            />
          </Grid>

          <Grid item xs={12}>
            <MDEditor value={currentActivity?.description} disabled style={{ height: "100%" }} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default ActivitiesInformation;
