import { Box, Button, Modal, Fade, Card, Grid, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppContextController } from "context/AppContext";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    padding: "1rem",
  },
  dropzone: {
    height: 170,
  },
});

const ImagePreviewModal = ({
  imagePreviewOpen,
  setImagePreviewOpen,
  currentInterview,
  previewFile,
}) => {
  const { company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const classes = useStyle();
  const handleClose = () => setImagePreviewOpen(false);

  return (
    <Modal
      open={imagePreviewOpen}
      onClose={(interview, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={imagePreviewOpen}>
        <Card className={classes.container}>
          {IMAGE_SERVER && (
            <img
              src={`${IMAGE_SERVER}/${currentInterview?.companySlug}/interviews/${currentInterview?.interviewUrl}/${previewFile?.name}`}
              alt="previews"
            />
          )}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="text"
              onClick={() => {
                if (IMAGE_SERVER)
                  window.open(
                    `${IMAGE_SERVER}/${currentInterview?.companySlug}/interviews/${currentInterview?.interviewUrl}/${previewFile?.name}`
                  );
              }}
            >
              Open in another tab
            </Button>
            <Button
              variant="contained"
              style={{ color: "white" }}
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </Button>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};

export default ImagePreviewModal;
