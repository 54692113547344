import padStart from "lodash/padStart";
import { useEffect, useState } from "react";
import ConfirmationModal from "components/ConfirmationModal";
import DirectDepositFormFields from "components/FormFields/DirectDepositFormFields";
import { useNewApplicantContext } from "context/NewApplicantContext";

const DirectDeposit = () => {
  const { applicant, updateApplicantAction, updateButtons } = useNewApplicantContext();
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [pendingData, setPendingData] = useState({});

  const onSubmit = async ({ routing1, routing2, ...data }) => {
    const parsedData = {
      directDeposit: {
        ...(routing1 && { routing1: padStart(routing1, 9, "0") }),
        ...(routing2 && { routing2: padStart(routing2, 9, "0") }),
        ...data,
      },
    };
    if (
      data.paymentType !== "DirectDeposit" &&
      data?.account1 &&
      data?.bankName1 &&
      data?.accountType1 &&
      routing1
    ) {
      toggleConfirmationModal(true);
      setPendingData(parsedData);
    } else {
      updateApplicantAction(applicant._id, parsedData);
      setTimeout(
        () =>
          document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
        1000
      );
    }
  };

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  return (
    <>
      <DirectDepositFormFields onSubmit={onSubmit} />
      <ConfirmationModal
        title="You have entered banking information. Are you sure you want Money Network/Paper Check and not Direct Deposit?"
        isOpen={isConfirmationOpen}
        onClose={() => {
          toggleConfirmationModal(false);
        }}
        onConfirm={() => {
          updateApplicantAction(applicant._id, pendingData);
          toggleConfirmationModal(false);
        }}
      />
    </>
  );
};

export default DirectDeposit;
