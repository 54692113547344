import { useState } from "react";
import { Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomTextField from "components/Form/CustomTextField";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import usePayrollExportStore from "stores/usePayrollExportStore";
import EventsDataGrid from "./EventsDataGrid";
import ExportFields from "./ExportFields";
import ShiftJobsDataGrid from "./ShiftJobsDataGrid";

const PayrollMainContent = ({ activeStep, exportType, setExportType, type }) => {
  const { toDate, fromDate, setToDate, setFromDate } = usePayrollExportStore((state) => ({
    toDate: state.toDate,
    fromDate: state.fromDate,
    setToDate: state.setToDate,
    setFromDate: state.setFromDate,
  }));
  const [error, setError] = useState(null);

  if (activeStep === 2) {
    return (
      <FlexWrapper>{type === "shiftJobs" ? <ShiftJobsDataGrid /> : <EventsDataGrid />}</FlexWrapper>
    );
  }
  if (activeStep === 3) {
    return <ExportFields exportType={exportType} setExportType={setExportType} type={type} />;
  }

  return (
    <FlexWrapper justifyContent="flex-start">
      <Typography>Select Date Range</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Start Date"
          value={fromDate}
          onChange={(date) => {
            setFromDate(new Date(date));
          }}
          renderInput={(params) => <CustomTextField {...params} />}
        />
        <DatePicker
          label="End Date"
          onError={(newError) => {
            setError(newError);
          }}
          value={toDate}
          minDate={fromDate}
          onChange={(date) => {
            setToDate(new Date(date));
          }}
          renderInput={(params) => <CustomTextField {...params} />}
        />
      </LocalizationProvider>
      {error && (
        <MDTypography color="error">
          The End Date can&apos;t be earlier than the Start Date
        </MDTypography>
      )}
    </FlexWrapper>
  );
};

export default PayrollMainContent;
