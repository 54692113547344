import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import AvTimerIcon from "@mui/icons-material/AvTimer";

const PartnerTableActions = ({ id, setActionSelected, setFilters }) => (
  <span>
    <TableAction color="info" icon="info" clickAction={() => setActionSelected("info")} />
    &nbsp;&nbsp;
    <TableAction color="warning" icon="mail" clickAction={() => setActionSelected("mail")} />
    &nbsp;&nbsp;
    <TableAction
      color="error"
      icon="clear"
      iconComponent={AvTimerIcon}
      clickAction={() => setActionSelected("clear")}
    />
    &nbsp;&nbsp;
    <TableAction
      color="info"
      icon="add_circle"
      iconComponent={AvTimerIcon}
      clickAction={() => setActionSelected("AddCircleRoundedIcon")}
    />
  </span>
);

export default PartnerTableActions;

PartnerTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
PartnerTableActions.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
