import { useEffect, useState, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import useCompanyCache from "hooks/useCompanyCache";
import AttachmentFiles from "components/AttachmentFiles";
import updateIssue from "../../actions/updateIssue";
import renameFileIssue from "../../actions/renameFileIssue";

const Attachments = () => {
  const { currentIssue, setCurrentIssue, company, setCompany, setCompanyType, } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { mutateAsync: updateCompanyMutation } = useMutation(updateIssue);
  const { mutateAsync: renameFileMutation } = useMutation(renameFileIssue);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);


  const updateIssueHandler = async (values, action, fileName) => {
    if (!currentIssue?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { id: currentIssue._id, data: values },
        {
          onSuccess: async (res) => {
            const actionStatus = action === "DELETE" ? "removed" : "renamed";
            setCurrentIssue({ ...currentIssue, ...values });
            setFiles(values.attachments);
            enqueueSnackbar(`${currentIssue.issueTitle} Attachment file ${fileName} has been ${actionStatus}.`, {
              variant: "success",
            });
            await queryClient.invalidateQueries("issues");
          },
        }
      );
    }
  };

  const renameFileHandler = async (renameVal, originalVal) => {
    if (!currentIssue?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      renameFileMutation(
        {
          rename: renameVal,
          original: originalVal,
          category: currentIssue?.category,
          id: currentIssue._id
        },
        {
          onError: (err) =>
            enqueueSnackbar(err.toString(), { variant: "error", autoHideDuration: 3000 }),
        }
      );
    }
  };

  const deleteFileHandler = async (e, idx, fileName) => {
    const filteredFiles = files.filter((file, index) => index !== idx);
    await updateIssueHandler({ ...currentIssue, attachments: filteredFiles }, "DELETE", fileName);
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];
    // // get the image extension
    const imgExt = newFiles[idx].filename.substring(newFiles[idx].filename.lastIndexOf("."));
    // // check if edited
    const removeExt = newFiles[idx].filename.substring(0, newFiles[idx].filename.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;
    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].filename;
    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].filename;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );

  const confirmFileNameChanges = async (e, idx) => {
    await renameFileHandler(files[idx].editedName, files[idx].filename);
    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], filename: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);
    await updateIssueHandler(
      { ...currentIssue, attachments: newFiles },
      "UPDATE",
      newFiles[idx].filename
    );
  };

  useEffect(() => {
    if (currentIssue?.attachments?.length > 0) setFiles(currentIssue.attachments);
    else setFiles([]);
  }, [currentIssue?.attachments]);

  return (
    <AttachmentFiles
      files={files}
      company={company}
      onDelete={deleteFileHandler}
      onRename={editFileNameHandler}
      onConfirmRename={confirmFileNameChanges}
      onCancelRename={cancelFileNameChanges}
      isIssue
    />
  );
};

export default Attachments;
