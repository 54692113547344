import {
  Badge,
  ConnectWithoutContact,
  PersonAdd,
  FolderShared,
  QuestionAnswer,
  ThumbUp,
} from "@mui/icons-material";

const CommonIconProps = {
  width: "18px",
  height: "18px",
  color: "white",
};

export const APPLICANT_STEPS = [
  { id: 1, label: "New Applicant", icon: <PersonAdd {...CommonIconProps} /> },
  {
    id: 2,
    label: "ATC",
    icon: <ConnectWithoutContact {...CommonIconProps} />,
  },
  {
    id: 3,
    label: `Screened`,
    icon: <QuestionAnswer {...CommonIconProps} />,
  },
  {
    id: 4,
    label: "Pre-Hire",
    icon: <FolderShared {...CommonIconProps} />,
  },
  {
    id: 5,
    label: "Employee",
    icon: <ThumbUp {...CommonIconProps} />,
  },
];
