import { baseAxios } from "config";

const createAuth0User = async ({ id }) => {

    try {
        const res = await baseAxios.post(`/users/id/${id}/createAuth0`);
        return res.data;
    } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        throw new Error(err.response?.data?.message?.toString());
    }
};

export default createAuth0User;
