import { useState, useEffect } from "react";
import { Avatar, Box, Paper, Step, StepLabel, Stepper, styled, Typography } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { APPLICANT_STEPS } from "utils/constants/applicantSteps";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    marginTop: "30px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
  background: "transparent",
  boxShadow: "none",
  "& .MuiStepLabel-iconContainer": {
    "& .MuiBox-root": {
      borderRadius: "100%",
      padding: "10px",
      zIndex: 1,
      backgroundColor: "#cac8c9",
    },
  },
  "& .Mui-active, .Mui-completed": {
    "& .MuiBox-root": {
      backgroundColor: "#ec293b",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#ec293b",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#ec293b",
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#cac8c9",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "red",
    borderRadius: 1,
  },
}));

const ApplicantProcessStepper = ({ applicant }) => {
  const [activeStepId, setActiveStepId] = useState(0);

  useEffect(() => {
    if (applicant.status === "Employee") {
      setActiveStepId(5);
      return;
    }
    if (applicant?.applicantStatus) {
      switch (applicant.applicantStatus) {
        case "New":
          setActiveStepId(1);
          break;
        case "ATC":
          setActiveStepId(2);
          break;
        case "Screened":
          setActiveStepId(3);
          break;
        case "Pre-Hire":
          setActiveStepId(4);
          break;
        default:
          setActiveStepId(0);
          break;
      }
    }
  }, [applicant]);

  return (
    <Box
      maxWidth="100%"
      sx={(theme) => ({
        overflowY: "auto",
        flex: 0.7,
        display: "flex",
        [theme.breakpoints.down("lg")]: {
          flex: 1,
        },
      })}
    >
      <StyledStepper activeStep={activeStepId - 1} alternativeLabel connector={<StyledConnector />}>
        {APPLICANT_STEPS.map(({ label, icon, id }) => {
          const isActive = id <= activeStepId;
          return (
            <Step
              key={label}
              sx={{ width: "100%", ...(isActive && { cursor: "pointer" }) }}
              onClick={() => isActive && setActiveStepId(id)}
            >
              <Typography
                align="center"
                className="MuiStepper-alternativeLabel"
                color={isActive ? "#ec293b" : "#cac8c9"}
                variant="subtitle2"
                fontSize="0.75rem"
                sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
              >
                {label}
              </Typography>
              <StepLabel StepIconComponent={() => <FlexWrapper>{icon}</FlexWrapper>} />
            </Step>
          );
        })}
      </StyledStepper>
    </Box>
  );
};

export default ApplicantProcessStepper;
