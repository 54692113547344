import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import Auth0ProviderWithHistory from "auth/auth0Provider";
import { MaterialUIControllerProvider } from "context";
import { AppContextProvider } from "context/AppContext";
import { SnackbarProvider } from "notistack";
import { APIProvider } from "@vis.gl/react-google-maps";

import { MAPS_API_KEY } from "config";
import { useAuth0 } from "@auth0/auth0-react";
import useSessionAuth from "hooks/useSessionAuth";
import { useCreateQueryClient } from "hooks/useCreateClientQuery";

const QueryClientProviderContainer = ({ children }) => {
  const queryClient = useCreateQueryClient();

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const Providers = ({ children }) => {
  return (
    <MaterialUIControllerProvider>
      <Auth0ProviderWithHistory>
        <AppContextProvider>
          <QueryClientProviderContainer>
            <APIProvider apiKey={MAPS_API_KEY}>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                key="top-center"
              >
                {children}
              </SnackbarProvider>
            </APIProvider>
            {window.location.href.indexOf("localhost") > -1 ? <ReactQueryDevtools /> : null}
          </QueryClientProviderContainer>
        </AppContextProvider>
      </Auth0ProviderWithHistory>
    </MaterialUIControllerProvider>
  );
};

export default Providers;
