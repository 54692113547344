import { useState } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import { Box, Button, CircularProgress, Paper, styled, Typography } from "@mui/material";
import { useOutsideCompanyCache } from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import parse from "html-react-parser";
import { useMutation } from "react-query";
import declineJob from "layouts/pages/web/actions/declineJob";
import { useParams, useSearchParams } from "react-router-dom";

const StyledImg = styled("img")(() => ({
  borderRadius: "15px",
}));

const JobDecline = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { jobSlug } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [isDeclined, setIsDeclined] = useState(false);

  const { venues, setVenues, company, setCompany, setCompanyType } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "public",
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({
    venues,
    setVenues,
    company,
    outsideMode: "public",
  });

  const declineJobMutation = useMutation(declineJob, {
    onError: (err) => {
      enqueueSnackbar(err.toString(), { variant: "error" });
    },
    onSuccess: async (res) => {
      enqueueSnackbar(res.message, { variant: "success" });
      setIsDeclined(true);
    },
  });

  const declineJobHandler = async () => {
    await declineJobMutation.mutateAsync({
      jobSlug,
      data: { token },
    });
  };

  if (isLoadingCompany || isLoadingVenues) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="info" />
      </Box>
    );
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <ContainerWrapper sx={{ px: "20px", pt: "15px" }} position="relative">
        <Box position="relative">
          <a href="/">
            <StyledImg
              src={`${company?.imageUrl}/company/${company?.slug}/banner/${company?.bannerUrl}`}
              alt=""
              width="100%"
            />
          </a>
        </Box>
        <Paper elevation={3} sx={{ p: "1.5rem", mt: "20px" }}>
          <Box display="flex" flexDirection="column" rowGap="10px">
            <Box>{company?.declineJobPageText ? parse(company?.declineJobPageText) : ""}</Box>
            <Box display="flex" justifyContent="center">
              {!isDeclined ? (
                <Button
                  variant="contained"
                  sx={{ width: "200px" }}
                  color="error"
                  onClick={() => declineJobHandler()}
                  disabled={declineJobMutation.isLoading}
                >
                  {declineJobMutation.isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Decline Job"
                  )}
                </Button>
              ) : (
                <Typography variant="h5" color="success.main">
                  Job Declined Successfully
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
      </ContainerWrapper>
    </SnackbarProvider>
  );
};

export default JobDecline;
