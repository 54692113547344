import { CallMerge, CancelRounded, Check, DoDisturbAlt } from "@mui/icons-material";

import { APPLICANT_MERGE_STATUS_ENUM } from "utils/constants/applicant";

const MergeStatusIcon = ({ status, size = "large" }) => {
  const renderIcon = () => {
    switch (status) {
      case APPLICANT_MERGE_STATUS_ENUM.MERGE:
        return <CallMerge color="info" fontSize={size} />;
      case APPLICANT_MERGE_STATUS_ENUM.PRIMARY:
        return <Check color="success" fontSize={size} />;
      case APPLICANT_MERGE_STATUS_ENUM.IGNORE:
        return <DoDisturbAlt color="error" fontSize={size} />;
      case APPLICANT_MERGE_STATUS_ENUM.REMOVE:
        return <CancelRounded color="error" fontSize={size} />;
      default:
        return "";
    }
  };
  return renderIcon();
};

export default MergeStatusIcon;
