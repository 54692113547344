/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import CustomContentPanel from "components/CustomContentPanel";

const VenueCustomContent = () => {
  const { currentVenue, setCurrentVenue } = useAppContextController();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateVenueMutation } = useMutation(updateVenue);

  const handleUpdateVenue = (values, method = "update", onSuccess) => {
    updateVenueMutation(
      { venueId: currentVenue?._id, data: values },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("venues");
          setCurrentVenue({ ...currentVenue, ...values });
          onSuccess(method, values?.content);
          enqueueSnackbar("Venue has been updated.", { variant: "success" });
        },
        onError: (err) => enqueueSnackbar(`An error occured: ${err}`, { variant: "error" }),
      }
    );
  };

  return <CustomContentPanel currentData={currentVenue} onUpdate={handleUpdateVenue} isVenue />;
};

export default VenueCustomContent;
