import { useCallback, useMemo, useState } from "react";

import { Box, Tooltip, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "./PartnerMembersRosterList.css";
import { useAppContextController } from "context/AppContext";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import { Check } from "@mui/icons-material";
import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import { useQueryClient } from "react-query";
import theme from "assets/theme";
import TaskFlag from "components/TaskFlag";
import EventActionModal from "../../EventActionModal";
import TimeInOut from "../../TimeInOut";

export const PartnerMembersRosterList = ({
  applicants = [],
  rosterApplicants = [],
  rosterApplicantsIndexed = {},
  isLoading,
  eventDate,
  eventTime,
  selectedApplicants,
  setSelectedApplicants,
  clockInOut,
  clockInOutAtEventTime,
  isPartnerInRoster,
  eventDay,
}) => {
  const [modalInfo, setModalInfo] = useState({ data: {} });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const { company, currentEvent, currentVenue } = useAppContextController();
  const queryClient = useQueryClient();

  const isOnRoster = useCallback(
    (applId, i) => {
      if (rosterApplicantsIndexed) {
        return !!rosterApplicantsIndexed[applId];
      }
      return false;
    },
    [rosterApplicantsIndexed]
  );

  const handleRefreshRoster = async () => {
    await queryClient.invalidateQueries(["members"]);
  };

  // const clockInRosterMutation = useMutation(rosterClockIn, {
  //   // eslint-disable-next-line
  //   onSuccess: async (_, { data }) => {
  //     // await queryClient.invalidateQueries("rosterApplicants")
  //     const newApps = currentEvent?.applicants;
  //     if (newApps?.length) {
  //       const ndx = newApps.findIndex((item) => item.id === data.id);
  //       if (ndx > -1) {
  //         newApps[ndx] = data;
  //         setCurrentEvent({ ...currentEvent, applicants: newApps });
  //       }
  //     }
  //     toggleReadRoster();
  //   },
  //   onError: (error) => alert(error.toString()),
  // });

  // const insertRosterMutation = useMutation(updateEventRoster, {
  //   // eslint-disable-next-line
  //   onSuccess: async (_, { data }) => {
  //     // await queryClient.invalidateQueries("rosterApplicants")
  //     const newApps = currentEvent?.applicants;
  //     if (newApps?.length) {
  //       const ndx = newApps.findIndex((item) => item.id === data.id);
  //       if (ndx > -1) {
  //         newApps[ndx] = data;
  //         setCurrentEvent({ ...currentEvent, applicants: newApps });
  //       }
  //     }
  //     toggleReadRoster();
  //   },
  //   onError: (error) => alert(error.toString()),
  // });

  const getNotesThisEvent = useCallback(
    (arr) => {
      let numNotes = 0;
      arr.forEach((item) => {
        if (item?.eventUrl === currentEvent?.eventUrl) numNotes += 1;
      });
      return numNotes;
    },
    [currentEvent?.eventUrl]
  );

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns = useMemo(
    () => [
      {
        headerName: "First Name",
        field: "firstName",
        flex: 1,
      },
      {
        headerName: "Last Name",
        field: "lastName",
        flex: 1,
      },
      {
        headerName: "Flag",
        field: "_id",
        flex: 0.5,
        align: "center",
        renderCell: ({ value, row }) => <TaskFlag _id={row?._id} />,
      },
      {
        headerName: "Phone",
        field: "phone",
        flex: 1,
      },
      {
        headerName: "T-Shirt Size",
        field: "tShirtSize",
        flex: 1,
        align: "center",
      },
      ...(currentEvent?.eventType === "Event" && eventDay
        ? [
            {
              headerName: "Clock-In",
              field: "timeIn",
              flex: 1,
              align: "center",
              renderCell: ({ value, row }) => (
                <>
                  <MDBox width="7rem">
                    <TimeInOut
                      id={row?._id}
                      field="timeIn"
                      currentEvent={currentEvent}
                      eventDate={eventDate}
                      eventTime={eventTime}
                      rosterObj={
                        // !rosterLoading &&
                        rosterApplicants?.find((item) => item.id === row._id)
                      }
                      disabled={!isOnRoster(row._id)}
                      timeZone={currentEvent?.timeZone}
                      clockInOut={(e, rosterObj) => clockInOut(e, row._id, "timeIn", rosterObj)}
                      clockInOutAtEventTime={(e, rosterObj) =>
                        clockInOutAtEventTime(e, row._id, "timeIn", rosterObj)
                      }
                    />
                  </MDBox>
                </>
              ),
            },
            {
              headerName: "Clock-Out",
              field: "timeOut",
              flex: 1,
              align: "center",
              renderCell: ({ value, row }) => (
                <>
                  <MDBox width="7rem">
                    {/* <Tooltip title="Clock Out"> */}
                    <TimeInOut
                      id={row?._id}
                      field="timeOut"
                      currentEvent={currentEvent}
                      eventDate={eventDate}
                      eventTime={eventTime}
                      rosterObj={
                        // !rosterLoading &&
                        rosterApplicants?.find((item) => item.id === row._id)
                      }
                      // checks if there is a time in value before enabling
                      disabled={
                        !(
                          isOnRoster(row._id) &&
                          rosterApplicants?.find((item) => item.id === row._id)?.timeIn
                        )
                      }
                      timeZone={currentEvent?.timeZone}
                      eventEndTime={currentEvent?.eventEndTime}
                      clockInOut={(e, rosterObj) => clockInOut(e, row._id, "timeOut", rosterObj)}
                    />
                    {/* </Tooltip> */}
                  </MDBox>
                </>
              ),
            },
          ]
        : []),
      {
        headerName: "Roster",
        field: "roster",
        flex: 1,
        align: "center",
        renderCell: ({ value, row }) => {
          if (isOnRoster(row._id)) {
            if (row.partnerStatus === "Inactive") {
              return (
                <Tooltip title="Inactive member">
                  <Check fontSize="medium" style={{ color: "#FB8C00" }} />
                </Tooltip>
              );
            }
            return <Check fontSize="medium" style={{ color: "green" }} />;
          }
          return null;
        },
      },
      {
        headerName: "Notes",
        field: "notes",
        flex: 1,
        align: "center",
        renderCell: ({ value, row }) => (
          <TableAction
            color="info"
            icon="description"
            showBadge
            badgeNumber={row?.notes?.length ?? 0}
            tooltip="Add a Note"
            clickAction={(e) => {
              setModalInfo({ type: "applicantComment", data: row, showAll: true });
              setGenericModalOpen(true);
            }}
            condensed
          />
        ),
      },
    ],
    [
      clockInOut,
      clockInOutAtEventTime,
      currentEvent,
      eventDate,
      eventDay,
      eventTime,
      isOnRoster,
      rosterApplicants,
    ]
  );

  const visibleColumns = useMemo(() => {
    return {
      firstName: true,
      lastName: true,
      phone: matches,
      tShirtSize: matches,
      timeIn: true,
      timeOut: true,
      roster: true,
      notes: true,
    };
  }, [matches]);

  const parsedRows = useMemo(
    () =>
      applicants.map((item) => {
        const venueSlug = currentEvent?.venueSlug || currentVenue?.slug;
        const venueStatus = getApplicantVenueStatus(item, venueSlug);

        return {
          ...item,
          id: item._id,
          venueStatus,
          enabled: true,
        };
      }),
    [applicants, currentEvent?.venueSlug, currentVenue?.slug]
  );

  // useEffect(() => {
  //   setRows(eventRoster);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [eventRoster]);

  return (
    <Box
      sx={{
        minHeight: "100px",
        display: "flex",
        flexGrow: 1,
        overflow: "scroll",
        width: "100%",
      }}
    >
      <DataGrid
        rows={parsedRows}
        columns={columns}
        columnVisibilityModel={visibleColumns}
        checkboxSelection={isPartnerInRoster}
        selectionModel={selectedApplicants}
        onSelectionModelChange={setSelectedApplicants}
        disableSelectionOnClick
        disableColumnSelector
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        }}
        // isRowSelectable={(params) => false}
        getRowClassName={(params) => {
          if (selectedApplicants.includes(params?.row?._id)) {
            return "row-selected";
          }
          // if (
          //   (params?.row?.recordLocked && params?.row?.recordLocked === "Yes") ||
          //   (params?.row?.venueStatus && params?.row?.venueStatus === "Locked")
          // )
          //   return "row-locked";
          // if (params?.row?.email && params?.row?.phone) return "";
          // // if (params?.row?.email && emailChecked) return "row-emailonly";
          // // if (params?.row?.phone && smsChecked) return "row-phoneonly";
          return "";
        }}
        loading={isLoading}
      />
      <EventActionModal
        open={genericModalOpen}
        setOpen={setGenericModalOpen}
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        setToastAlert={setToastAlert}
        toggleReadApps={handleRefreshRoster}
        isCreateTaskAllowed
      />
    </Box>
  );
};
