import { useCallback, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import MergeIcon from "@mui/icons-material/Merge";
import { Box, Button, Grid, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { Info as InfoIcon } from "@mui/icons-material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import mergeApplicants from "api/applicants/mergeApplicants";
import ApplicantMergeStatus from "components/ApplicantMergeStatus";
import DataTable from "components/DataTable";
import FormErrors from "components/Form/FormErrors";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";

const MoreInfoModal = ({ open, setOpen, currentRow, parentComponent }) => {
  const header = (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="space-between">
        <MDTypography variant="h4">{`${currentRow?.firstName} ${currentRow?.lastName} Information`}</MDTypography>
      </Stack>
    </>
  );

  const modalBody = (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} fontWeight="bold">
          Name:
        </Grid>
        <Grid item xs={12} sm={6}>
          {`${currentRow?.firstName} ${currentRow?.lastName}`}
        </Grid>
        {currentRow?.primaryPosition && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Primary Position:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.primaryPosition}
            </Grid>
          </>
        )}
        {currentRow?.rank && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Rank:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.rank}
            </Grid>
          </>
        )}
        {currentRow?.phone && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Phone:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.phone}
            </Grid>
          </>
        )}
        {currentRow?.altPhone && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Alternate Phone:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.altPhone}
            </Grid>
          </>
        )}
        {currentRow?.address1 && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Address:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.address1}
            </Grid>
          </>
        )}
        {currentRow?.state && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              State:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.state}
            </Grid>
          </>
        )}
        {currentRow?.city && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              City:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.city}
            </Grid>
          </>
        )}
        {currentRow?.zip && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Zip:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.zip}
            </Grid>
          </>
        )}
        {currentRow?.email && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Email:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.email}
            </Grid>
          </>
        )}
        {currentRow?.altEmail && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Alternate Email:
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.altEmail}
            </Grid>
          </>
        )}
        {currentRow?.birthDate && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Date of Birth
            </Grid>
            <Grid item xs={12} sm={6}>
              {moment(currentRow?.birthDate).format("YYYY-MM-DD")}
            </Grid>
          </>
        )}
        {parentComponent === "Employee" && currentRow?.socialSecurity && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Social Security Number
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.socialSecurity}
            </Grid>
          </>
        )}
        {currentRow?.referralSource && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Referral Source
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.referralSource}
            </Grid>
          </>
        )}
        {parentComponent === "Applicant" && currentRow?.onboardingStatus && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Onboarding Status
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.onboardingStatus}
            </Grid>
          </>
        )}
        {parentComponent === "Employee" && currentRow?.employmentStatus && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Employment Status
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.employmentStatus}
            </Grid>
          </>
        )}
        {parentComponent === "Applicant" && currentRow?.applicantStatus && (
          <>
            <Grid item xs={12} sm={6} fontWeight="bold">
              Applicant Status
            </Grid>
            <Grid item xs={12} sm={6}>
              {currentRow?.applicantStatus}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );

  const okButton = (
    <Button variant="contained" style={{ color: "white" }} onClick={() => setOpen(false)}>
      Ok
    </Button>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "940px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
        buttons={okButton}
      />
    </>
  );
};

export default MoreInfoModal;
