import { useQuery } from "react-query";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { useMemo } from "react";

const filters = {
  spStatus: "Employee",
  userType: "Admin;Master",
  status: "Active",
};

const useGetUsersForTaskAssignemnt = () => {
  const options = { fetchAll: true };
  const allOptions = { ...options, filters, order: "lastName", orderBy: "lastName" };

  const { data: users } = useQuery(["users", allOptions], () => fetchUsers(allOptions));

  const filteredUsers = useMemo(() => {
    if (!users?.data) return [[], []];
    const fUsers = users?.data?.map((user) => {
      return {
        userId: user._id,
        applicantId: user.applicantId,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.emailAddress,
        label: `${user.firstName} ${user.lastName}`,
        profileImg: user.profileImg,
      };
    });

    const sortUsers = (a, b) => {
      if (a.fullName > b.fullName) return 1;
      return -1;
    };

    fUsers.sort(sortUsers);

    return fUsers;
  }, [users?.data]);

  return filteredUsers;
};

export default useGetUsersForTaskAssignemnt;
