import { useState } from "react";

import { Grid, Icon, Stack, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  Cancel,
  Email,
  AccountCircle,
  AccountCircleOutlined,
  Edit,
  Lock,
} from "@mui/icons-material";

import { dataStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";

const AIQuestionsItem = ({ row, idx, onEdit, onRemove, onToggleLocked, readOnly }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { text } = row;
  const isIconMenuOpen = Boolean(anchorEl);

  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        // onClick={() => {
        //   onEdit(idx, row, true);
        // }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={9}>
            <MDTypography sx={styles.font}>{parse(text ?? "")}</MDTypography>
          </Grid>
          {!readOnly && (
            <>
              <Grid item sm={1} textAlign="right">
                <Tooltip title="Edit" placement="top">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(idx, row, true);
                    }}
                  >
                    <Edit color="info" fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item sm={1} textAlign="right">
                <Tooltip title="Lock" placement="top">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleLocked(idx);
                    }}
                  >
                    <Lock color={row.locked ? "info" : "disabled"} fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item sm={1}>
                <MDBox textAlign="right">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(idx);
                    }}
                  >
                    <Cancel color="error" fontSize="medium" />
                  </IconButton>
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default AIQuestionsItem;
