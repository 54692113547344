import WorkIcon from "@mui/icons-material/Work";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import CustomMenu from "components/CustomizedMui/CustomMenu";
import { JOB_POSITION_STATUS_ENUM } from "utils/constants/job";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const POSITION_STATUS_COLOR = {
  Open: "success",
  Filled: "info",
  "On-Hold": "warning",
  Canceled: "error",
};

const JobPositionStatusButton = ({
  status = "Open",
  onUpdate,
  applicantId,
  name,
  onOpenFilledSlot,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUpdate = useCallback(
    (newStatus) => {
      if (newStatus === JOB_POSITION_STATUS_ENUM.OPEN && applicantId) {
        onOpenFilledSlot(true);
      } else {
        onUpdate?.(newStatus, false);
      }
    },
    [applicantId, onOpenFilledSlot, onUpdate]
  );

  const options = [
    {
      text: "Open",
      icon: (
        <WorkIcon fontSize="medium" color={POSITION_STATUS_COLOR[JOB_POSITION_STATUS_ENUM.OPEN]} />
      ),
      handleClick: () => handleUpdate(JOB_POSITION_STATUS_ENUM.OPEN),
      disabled: JOB_POSITION_STATUS_ENUM.OPEN === status,
    },
    {
      text: "Filled",
      icon: (
        <WorkIcon
          fontSize="medium"
          color={POSITION_STATUS_COLOR[JOB_POSITION_STATUS_ENUM.FILLED]}
        />
      ),
      handleClick: () => handleUpdate(JOB_POSITION_STATUS_ENUM.FILLED),
      disabled: JOB_POSITION_STATUS_ENUM.FILLED === status || !applicantId,
    },
    {
      text: "On-Hold",
      icon: (
        <WorkIcon
          fontSize="medium"
          color={POSITION_STATUS_COLOR[JOB_POSITION_STATUS_ENUM.ON_HOLD]}
        />
      ),
      handleClick: () => handleUpdate(JOB_POSITION_STATUS_ENUM.ON_HOLD),
      disabled: JOB_POSITION_STATUS_ENUM.ON_HOLD === status,
    },
    {
      text: "Canceled",
      icon: (
        <WorkIcon
          fontSize="medium"
          color={POSITION_STATUS_COLOR[JOB_POSITION_STATUS_ENUM.CANCELED]}
        />
      ),
      handleClick: () => handleUpdate(JOB_POSITION_STATUS_ENUM.CANCELED),
      disabled: JOB_POSITION_STATUS_ENUM.CANCELED === status,
    },
  ];

  return (
    <>
      <Tooltip title={`${name} is ${status}`}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MDBox textAlign="center" bgColor={POSITION_STATUS_COLOR[status]} borderRadius="10px">
            <MDTypography
              color="white"
              sx={{ fontSize: "0.8rem", marginLeft: "10px", marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
            >
              {status}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
    </>
  );
};

export default JobPositionStatusButton;
