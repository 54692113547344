import PropTypes from "prop-types";
import { useState } from "react";
import findIndex from "lodash/findIndex";
// import { useAppContextController } from "context/AppContext";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import FilterCard from "components/FilterCard";
import MDBox from "components/MDBox";
import { isEmpty } from "lodash";

function FilterCardBar({
  cards,
  parent,
  bgColor,
  color,
  filters,
  setExistingFilters,
  setFilters,
  setPage,
  setCards,
  spacing = 2,
  parentComponent,
  initialIndex = 0,
  isBlankForAll,
}) {
  // const { currentVenueSelector, currentJobsSelector, setStateVar } = useAppContextController();
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  const onSelectHandler = (filterBy, filterValue, index, custom, filterObj) => {
    if (filterObj) setFilters(filterObj);
    else if (!filterValue && !isBlankForAll) setFilters({});
    else
      setFilters({
        [filterBy]: filterValue,
      });
    setSelectedIndex(index);
    if (setPage) setPage(1);
  };

  return (
    <>
      {!isEmpty(cards) && (
        <MDBox>
          <Grid container spacing={spacing} pb={2}>
            {cards.map((card, i) => (
              <Grid item key={`item_${card?.title}`}>
                <FilterCard
                  // setSelector={parent==='Venue' ? setCurrentVenueSelector : setCurrentJobsSelector}
                  bgColor={bgColor}
                  color={card?.color || color}
                  icon={card?.icon}
                  title={card?.title}
                  tooltip={
                    card?.tooltip ? `${card.number ? `${card.number} ` : ""} ${card?.tooltip}` : ""
                  }
                  number={card?.number ? card.number : null}
                  filters={filters}
                  setExistingFilters={setExistingFilters}
                  setFilters={setFilters}
                  filterField={card?.filterField}
                  filterValue={card?.filterValue}
                  selectedIndex={selectedIndex}
                  index={i}
                  onSelectHandler={onSelectHandler}
                  customFilterFunction={card?.customFilterFunction}
                  parentComponent={parentComponent}
                  filterObj={card?.filters}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
    </>
  );
}

// Setting default values for the props of FilterCard
FilterCardBar.defaultProps = {
  //  number: null,
  parent: "Venue",
  bgColor: "info",
  color: "white",
  isBlankForAll: false,
};

// Typechecking props for the FilterCard
FilterCardBar.propTypes = {
  // cards: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     icon: PropTypes.oneOfType([
  //       PropTypes.string,
  //       PropTypes.elementType, // This covers React components
  //     ]).isRequired,
  //     title: PropTypes.string.isRequired,
  //     number: PropTypes.number,
  //   })
  // ).isRequired,
  parent: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  isBlankForAll: PropTypes.bool,
};
export default FilterCardBar;
