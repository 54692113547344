import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import { Grid, IconButton, Stack, Box, Tooltip } from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import { dataStyles as styles } from "layouts/pages/customers/styles";
import { getDisplayText } from "utils/helpers/dataList";

import moment from "moment";
import parse from "html-react-parser";

const SalesNotes = ({ row, idx, onEdit, onRemove, displayNotes }) => {
  const [viewDetail, setViewDetail] = useState(false);

  const { createdDate, text } = row;

  useEffect(() => {
    setViewDetail(displayNotes);
  }, [displayNotes]);

  return (
    <Box
      onClick={() => {
        onEdit(idx, row);
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <MDTypography variant="body2" sx={styles.font}>
                {moment(createdDate).format("ddd MM/DD/YYYY hh:mm A")}
              </MDTypography>
            </Grid>
            <Grid item xs={8}>
              <Tooltip title={parse(text)} key={`${text}_${createdDate}`} placement="bottom-start">
                <MDTypography variant="body2" sx={styles.font}>
                  {getDisplayText(text, 50)}
                </MDTypography>
              </Tooltip>
            </Grid>
            {viewDetail ? (
              <Grid item xs={12} sm={12}>
                <hr />
                {text ? parse(text) : null}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <IconButton
              sx={{ p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                onRemove(idx);
              }}
            >
              <Cancel color="error" fontSize="small" />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesNotes;
