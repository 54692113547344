import updateUser from "api/users/updateUser";
import ConfirmationModal from "components/ConfirmationModal";
import { useAppContextController } from "context/AppContext";
import { useState } from "react";

const usePreferredPageSize = ({ defaultPageSize = 5 }) => {
  const { currentLoggedUser, setCurrentLoggedUser } = useAppContextController();
  const [limit, setLimit] = useState(currentLoggedUser?.preferredPageSize ?? defaultPageSize);
  const [showModal, setShowModal] = useState(false);

  const setLimitAndAsk = (newLimit) => {
    setLimit(newLimit);
    setShowModal(true);
  };

  const handleUpdatePreferredPageSize = async (pageSize) => {
    setShowModal(false);
    if (currentLoggedUser && currentLoggedUser._id) {
      setCurrentLoggedUser({
        ...currentLoggedUser,
        preferredPageSize: pageSize,
      });
      const res = await updateUser({
        data: {
          preferredPageSize: pageSize,
        },
        userId: currentLoggedUser._id,
      });
    }
  };

  const pageLimitConfirmationModal = (
    <>
      {showModal && (
        <ConfirmationModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title={`Do you want to set ${limit} as the default page size for all tables?`}
          onConfirm={(e) => {
            e.stopPropagation();
            handleUpdatePreferredPageSize(limit);
          }}
        />
      )}
    </>
  );

  return {
    limit,
    setLimit: setLimitAndAsk,
    pageLimitConfirmationModal,
  };
};

export default usePreferredPageSize;
