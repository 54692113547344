import { baseAxios } from "config";

const syncDataWithHelm = async ({ id, slug, isManual }) => {
  try {
    // Initialize the body object with common properties
    const body = {};

    // Conditionally add properties if they have values
    if (slug && isManual) {
      body.slug = slug;
      body.isManual = isManual;
    }
    const res = await baseAxios.post(`/helm/employee/id/${id}/datasync`, body);
    return res.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};

export default syncDataWithHelm;
