import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const SearchbarResults = ({ columns, rows = [], isLoading = false, onRowClick }) => {
  return (
    <Table sx={{ display: "block" }}>
      <TableHead sx={{ display: "table-header-group" }}>
        <TableRow
          sx={({ typography: { size, fontWeightBold } }) => ({
            td: {
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            },
          })}
        >
          {columns.map((col) => (
            <TableCell
              key={`${col.title}_${col.field}`}
              sx={{ whiteSpace: "nowrap", width: col?.width || "100%" }}
            >
              {col.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading &&
          rows.length > 0 &&
          rows.map((item) => (
            <TableRow
              onClick={(e) => onRowClick(item, e)}
              key={item._id || item.id}
              sx={{
                cursor: "pointer",
                "& td, & th": { border: 0 },
                "&:hover": { background: "rgba(0, 0, 0, 0.04)" },
              }}
            >
              {columns.map((col) => (
                <TableCell
                  key={`${col.title}_${col.field}`}
                  sx={{
                    whiteSpaces: "break-spaces",
                    maxWidth: "250px",
                    width: col?.width || "100%",
                  }}
                >
                  {col.customCell ? col.customCell(item[col.field], item) : item[col.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        {!isLoading && rows.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={columns.length}>
              No results found!
            </TableCell>
          </TableRow>
        )}
        {isLoading && (
          <TableRow>
            <TableCell align="center" colSpan={columns.length}>
              Searching...
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SearchbarResults;
