import axios from "axios";

import { baseAxios } from "config";

const fetchCounts = async ({ entity, urlList, size }) => {
  try {
    const res = await baseAxios.post(`/${entity}/filtercounts`, { urlList });

    if (res?.data?.countArray !== undefined) return res.data.countArray;
    const arrayOfZeros = new Array(size).fill(0);
    return arrayOfZeros;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return null;
    // eslint-disable-next-line no-console
    console.log("fetchCounts error: ", entity, err.message);
    throw new Error(err.toString());
  }
};

export default fetchCounts;
