import { Alert, Autocomplete, Grid, Snackbar } from "@mui/material";
import isEmpty from "lodash/isEmpty";
// import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import moment from "moment";
import states from "assets/stateCodes.json";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";

import useYupValidationResolver from "hooks/useYupValidationResolver";
import { parseApplicantPhone } from "utils/helpers/applicant";
import MDTypography from "components/MDTypography";
import { onboardApplicantSchema } from "data/onboardApplicant";

const ApplicantContactForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(onboardApplicantSchema);
  const { companyType } = useAppContextController();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const isCompany = companyType === "Company";
  const defaultValues = isCompany
    ? {
        applicationDate: applicant?.applicationDate
          ? moment(applicant?.applicationDate).format("L")
          : moment().format("L"),
        firstName: applicant?.firstName || "",
        middleInitial: applicant?.middleInitial || "",
        lastName: applicant?.lastName || "",
        address1: applicant?.address1 || "",
        city: applicant?.city || "",
        state: applicant?.state || "",
        zip: applicant?.zip || "",
        phone: applicant?.phone || "",
        altPhone: applicant?.altPhone || "",
      }
    : {
        applicationDate: applicant?.applicationDate
          ? moment(applicant?.applicationDate).format("L")
          : moment().format("L"),
        firstName: applicant?.firstName || "",
        middleInitial: applicant?.middleInitial || "",
        lastName: applicant?.lastName || "",
        address1: applicant?.address1 || "",
        city: applicant?.city || "",
        state: applicant?.state || "",
        zip: applicant?.zip || "",
        phone: applicant?.phone ? parseApplicantPhone(applicant?.phone) : "",
        altPhone: applicant?.altPhone || "",
      };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
  } = useForm({ resolver });

  const onSubmit = async ({
    phone,
    altPhone,

    zip,
    ...data
  }) => {
    const parsedData = {
      phone: phone || "",
      altPhone: altPhone || "",
      firstName: data.firstName,
      lastName: data.lastName,
      middleInitial: data.middleInitial,
      address1: data.address1,
      city: data.city,
      state: data.state,
      zip: zip ? zip.replace(/\D/g, "") : "",
    };

    updateApplicantAction(applicant._id, parsedData);

    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: true,
      },
      next: {
        show: true,
        disabled: false,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    reset(defaultValues);
  }, [applicant]);

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)} id="current-form">
        <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="body2">
                Please review your email address, first and last name for accuracy before
                proceeding.
              </MDTypography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <CustomTextField
                    disabled
                    inputProps={{ tabIndex: -1 }}
                    value={applicant?.email}
                    variant="outlined"
                    label="Email Address"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="applicationDate"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Application Date"
                        {...field}
                        disabled
                        inputProps={{ tabIndex: -1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="First Name"
                        showError={!isEmpty(errors.firstName)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="middleInitial"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Middle Initial"
                        showError={!isEmpty(errors.middleInitial)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Last Name"
                        showError={!isEmpty(errors.lastName)}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={6} xs={12}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        variant="outlined"
                        label="Mobile Number"
                        value={field.value}
                        onChange={field.onChange}
                        onValueChange={({ value }) => setValue("phone", value)}
                        customInput={CustomTextField}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="address1"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Address (include Unit #)"
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={4} xs={12} md={6}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="City"
                        showError={!isEmpty(errors.city)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} xs={12} md={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={states}
                        name="state"
                        autoSelect
                        freeSolo
                        defaultValue={null}
                        value={field.value || ""}
                        onChange={(e, v) => {
                          setValue("state", v?.toUpperCase());
                        }}
                        sx={{ maxHeight: "44px" }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="outlined"
                            type="text"
                            label="State"
                            sx={{
                              "& .MuiInputBase-root": {
                                py: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} xs={12} md={6}>
                  <Controller
                    name="zip"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField variant="outlined" label="Zip Code" {...field} />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="altPhone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        variant="outlined"
                        label="Alternate Mobile Number"
                        value={field.value}
                        onChange={field.onChange}
                        onValueChange={({ value }) => setValue("altPhone", value)}
                        customInput={CustomTextField}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FlexWrapper>
        <FormErrors errors={errors} />
      </form>
    </>
  );
};

export default ApplicantContactForm;
