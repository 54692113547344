import axios from "axios";
import { baseAxios } from "config";

const updateNotification = async ({ notificationId, body }) => {
  try {
    const res = await baseAxios.put(`/notifications/msgId/${notificationId}`, {
      ...body,
    });
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateNotification;
