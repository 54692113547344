import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
// import CheckIcon from "@mui/icons-material/Check";
import { PersonAdd } from "@mui/icons-material";
import { useAppContextController } from "context/AppContext";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const MyCustomersActions = ({ slug, isFavorite, setFavorite }) => {
  const navigate = useNavigate();
  const { userType } = useAppContextController();

  const onClickHandler = useCallback(
    (action, e) => {
      switch (action) {
        case "info":
          navigate(`/customers/${slug}/action/customerinfo`);
          break;
        case "applicants":
          navigate(`/customers/${slug}/action/customerapplicants`);
          break;
        case "employees":
          navigate(`/customers/${slug}/action/customeremployees`);
          break;
        case "interviews":
          navigate(`/customers/${slug}/action/customerinterviews`);
          break;
        default:
          break;
      }
    },
    [navigate, slug]
  );

  const actions = useMemo(
    () => [
      {
        name: "infoCustomer",
        color: "info",
        icon: "edit",
        clickAction: (e) => onClickHandler("info", e),
        tooltip: "View/Edit Customer",
      },
      {
        name: "customerApplicants",
        color: "warning",
        icon: <PersonAdd color="warning" fontSize="large" />,
        showBadge: true,
        clickAction: (e) => onClickHandler("applicants", e),
        tooltip: "Applicants",
      },
      {
        name: "customerEmployees",
        color: "error",
        icon: "people",
        showBadge: true,
        clickAction: (e) => onClickHandler("employees", e),
        tooltip: "Employee Pool",
      },
      {
        name: "customerInterviews",
        color: "success",
        icon: "event-seat",
        clickAction: (e) => onClickHandler("interviews", e),
        tooltip: "Interviews",
      },
      {
        name: "favorite",
        color: isFavorite() ? "warning" : "light",
        icon: "star",
        clickAction: () => setFavorite(slug),
        tooltip: "Add/Remove From My Customers",
      },
    ],
    [onClickHandler, setFavorite, isFavorite, slug]
  );

  // const filteredActions = useMemo(() => {
  //     const updatedActions = [...actions];
  //     switch (status) {
  //         case "Pending":
  //             updatedActions.unshift({
  //                 name: "customerStatus",
  //                 color: "warning",
  //                 icon: <CheckIcon fontSize="large" />,
  //                 clickAction: () => "",
  //                 tooltip: `Customer Status: ${status}`,
  //             });
  //             break;
  //         default:
  //             break;
  //     }
  //     if (userType === "User") {
  //         const userActions = ["viewCustomer", "customerStatus", "customerEvents"];
  //         return updatedActions.filter((action) => userActions.includes(action.name));
  //     }
  //     return actions;
  // }, [userType, actions]);

  return (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap">
      {actions.map((prop) => (
        <TableAction {...prop} />
      ))}
    </MDBox>
  );
};
export default MyCustomersActions;

MyCustomersActions.defaultProps = {
  slug: "",
  isFavorite: false,
};

MyCustomersActions.propTypes = {
  slug: PropTypes.string,
  isFavorite: PropTypes.bool,
};
