
import { Card, IconButton, Modal } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { styled } from '@mui/material/styles';
import { Close } from "@mui/icons-material";

const EditorPreviewModal = ({ toggleOpen, isOpen, message }) => {
  return (
    <EditorMessageModal open={isOpen} onClose={toggleOpen}>
      <FlexWrapper rowGap="20px">
        <EditorMessageModalBody>
          <div dangerouslySetInnerHTML={{ __html: message }} />
          <CloseEditorButton onClick={toggleOpen}><Close /></CloseEditorButton>
        </EditorMessageModalBody>
      </FlexWrapper>
    </EditorMessageModal>
  );
};

const EditorMessageModal = styled(Modal)(({ theme }) => ({
  borderRadius: "12px",
  minWidth: 700
}));

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "10px",
  right: "10px",
  m: 1,
  p: 0,
}));

const EditorMessageModalBody = styled(Card)(({ theme }) => ({
  position: "absolute",
  width: "90%",
  top: "50%",
  right: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  maxHeight: "640px",
  padding: "2rem",
  overflow: "auto",
}))

export default EditorPreviewModal;