import { DateRange } from "@mui/icons-material";
import { Button } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";

import leaveRosterMessage from "api/events/leaveRosterMessage";
import enrollUserToEvent from "api/events/enrollUserToEvent";
import colors from "assets/theme/base/colors";
import MessageModal from "components/MessageModal";
import { useAppContextController } from "context/AppContext";
import { useEventContext } from "context/EventContext";
import fetchEventApplicants from "layouts/pages/events/actions/fetchEventApplicants";
import WaitListModal from "layouts/pages/events/components/EventsPreviewModal/WaitlistModal";
import { APPLICANT_EVENT_STATUS_ENUM } from "utils/constants/applicant";
import useSessionAuth from "hooks/useSessionAuth";
import moment from "moment";
import CustomNotification from "components/CustomNotification";
import AddEventModal from "../AddEventModal";
import ConflictEventModal from "../ConflictEventModal";
import RemoveFromEventModal from "../RemoveFromEventModal";
import SuccessfullyAddedEventModal from "../SuccessfullyAddedEventModal";

const EventSignupButton = ({
  event,
  setEventPreview,
  refetchEvents,
  refetchEvent,
  toggleEventModal,
  isWaitlist = false,
  filteredPositions,
  isAllowed,
  eventCheckData,
  selectedRow,
  setEventAdded,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLoggedUser } = useAppContextController();
  const { refetchEventsTable } = useEventContext();

  const { data, isLoading, refetch } = useQuery(
    ["eventApplicants"],
    () => fetchEventApplicants(event?.eventUrl),
    {
      enabled: !!event?.eventUrl, // Do not fetch until there is an event
      onSettled: (res, err) => {
        if (res?.result) {
          return res.result;
        }
        return [];
      },
    }
  );

  const [overlappingEvent, setOverlappingEvent] = useState({});
  const [isCloseActive, toggleCloseButton] = useState(false);
  const [isAddModalOpen, toggleAddModal] = useState(false);
  const [isAddSuccessModalOpen, toggleAddSuccessModal] = useState(false);
  const [isRemoveModalOpen, toggleRemoveModal] = useState(false);
  const [isWaitListModalOpen, toggleWaitListModal] = useState(false);
  const [isMessageModalOpen, toggleMessageModal] = useState(false);

  const { type, allowed } = eventCheckData ?? { type: "", allowed: "" };

  const isRegistered = type === APPLICANT_EVENT_STATUS_ENUM.ROSTER;

  const text = useMemo(() => {
    if (isCloseActive) {
      return "Close";
    }
    if (type === APPLICANT_EVENT_STATUS_ENUM.WAITLIST || isRegistered) {
      return `Remove me from the ${type}`;
    }
    if (isWaitlist) {
      return "Add me to the Waitlist";
    }
    return "Register For This Event";
  }, [isCloseActive, isWaitlist, type, isRegistered]);

  const handleClick = () => {
    if (!isAllowed) {
      toggleMessageModal(true);
      return;
    }
    if (isCloseActive) {
      toggleEventModal(false);
      return;
    }
    if (isRegistered || type === APPLICANT_EVENT_STATUS_ENUM.WAITLIST) {
      toggleRemoveModal(true);
      return;
    }
    toggleAddModal(true);
  };

  const { logoutAuth0User } = useSessionAuth();

  const handleUnder48Hours = useCallback(
    async (msg) => {
      const body = {
        message: msg,
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
      };
      try {
        const response = await leaveRosterMessage({
          eventUrl: event.eventUrl,
          applicantId: currentLoggedUser.applicantId,
          body,
        });
        if (response.data.success) {
          const {
            data: { message = "", status = "", type: responseType = "" },
          } = response;
          refetch();
          if (message) {
            enqueueSnackbar(message, { variant: status.toLowerCase() });
          }
        }
      } catch (err) {
        console.error(err);
        if (String(err).includes("401") || err?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
    [currentLoggedUser, event]
  );

  const handleAction = useCallback(
    async (reason) => {
      if (isCloseActive) {
        toggleCloseButton(false);
        return;
      }
      let requestType =
        isRegistered || type === APPLICANT_EVENT_STATUS_ENUM.WAITLIST ? "Not Roster" : "Roster";
      if (isWaitlist) {
        requestType = "Request";
      }
      let body;
      if (requestType === "Not Roster") {
        body = {
          requestType,
          reason,
          agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
          createAgent: currentLoggedUser._id,
        };
      } else {
        body = {
          requestType,
          agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
          createAgent: currentLoggedUser._id,
          ...(selectedRow?.positionName &&
            selectedRow?.positionName !== "Event Staff" && {
              positionName: selectedRow.positionName,
            }),
        };
      }
      if (isWaitlist && !isRegistered) {
        body.requestType = "Waitlist";
      }
      try {
        const response = await enrollUserToEvent({
          eventUrl: event.eventUrl,
          applicantId: currentLoggedUser.applicantId,
          body,
        });
        if (response?.data?.overlappingEvent) {
          toggleAddSuccessModal(false);
          setOverlappingEvent(response.data.overlappingEvent);
        }
        if (response.data.success) {
          const {
            data: { message = "", status = "", type: responseType = "", removalResult },
          } = response;
          if (responseType === "WaitList") {
            toggleAddSuccessModal(false);
            toggleWaitListModal(true);
          }
          if (message && removalResult && removalResult.length > 0) {
            const eventBlock = removalResult.map((res) => {
              const ol = res.otherWaitlist
              return (
                <>
                  <br />
                  <br />
                  Event: {ol.eventName}
                  <br />
                  Venue: {ol.venueName}
                  <br />
                  Date/Time: ${moment(ol.eventDate).format("dddd MMM Do hh:mm a")}
                </>
              )
            })
            const noteMessage = (
              <>
                {message}
                <br />
                <br />
                NOTE, the following waitlisted events have been removed:
                {eventBlock}
              </>
            );

            const copyMessageSection = removalResult.map((res) => {
              const ol = res.otherWaitlist
              return `
              Event: ${ol.eventName}
              Venue: ${ol.venueName}
              Date/Time: ${moment(ol.eventDate).format("dddd MMM Do hh:mm a")}
              `
            })
            
            const copyMessage = `
            ${message}
           
            NOTE, the following waitlisted events have been removed:
           
            ${copyMessageSection.join('\n\n')}
            `;

            enqueueSnackbar(
              '', {
              variant: status.toLowerCase(),
              autoHideDuration: 15000,
              persist: true,
              action: (key) => (
                <CustomNotification message={noteMessage} key={key} copyMessage={copyMessage} />
              ),
            }
  
            );
          }
          else if (message) {
            enqueueSnackbar(message, { variant: status.toLowerCase() });
          }
          refetch();
          refetchEvents?.();
          refetchEvent();
          toggleCloseButton(true);
          setEventAdded(true);
        }
        refetchEventsTable?.();
      } catch (err) {
        console.error(err);
      }
    },
    [
      isCloseActive,
      isRegistered,
      type,
      isWaitlist,
      currentLoggedUser.firstName,
      currentLoggedUser.lastName,
      currentLoggedUser._id,
      currentLoggedUser.applicantId,
      selectedRow?.positionName,
      event.eventUrl,
      refetchEventsTable,
      refetch,
      refetchEvents,
      enqueueSnackbar,
    ]
  );

  const buttonColor = () => {
    if (isRegistered || isCloseActive) {
      return `${colors.error.main} !important`;
    }
    if (type === APPLICANT_EVENT_STATUS_ENUM.WAITLIST) {
      return `${colors.warning.main} !important`;
    }
    return "";
  };

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        endIcon={!isCloseActive ? <DateRange /> : ""}
        onClick={handleClick}
        sx={{
          maxWidth: "300px",
          backgroundColor: buttonColor(),
        }}
      >
        {text}
      </Button>
      <AddEventModal
        positionName={selectedRow?.positionName}
        event={event}
        positions={filteredPositions}
        open={isAddModalOpen}
        setOpen={toggleAddModal}
        onClick={() => {
          handleAction();
          toggleAddModal(false);
          toggleAddSuccessModal(true);
        }}
      />
      <RemoveFromEventModal
        open={isRemoveModalOpen}
        event={event}
        setOpen={toggleRemoveModal}
        onClick={(callOffAllowed, reason) => {
          if (callOffAllowed) handleAction(reason);
          else handleUnder48Hours(reason);
          toggleRemoveModal(false);
        }}
      />
      <SuccessfullyAddedEventModal open={isAddSuccessModalOpen} setOpen={toggleAddSuccessModal} />
      <ConflictEventModal
        open={!isEmpty(overlappingEvent)}
        setOpen={() => overlappingEvent({})}
        handleClose={() => overlappingEvent({})}
        onClick={() => {
          setEventPreview(overlappingEvent);
          setOverlappingEvent({});
        }}
        onCancel={() => {
          handleAction();
          setOverlappingEvent({});
        }}
      />
      <WaitListModal
        open={isWaitListModalOpen}
        setOpen={toggleWaitListModal}
        onCancel={() => {
          handleAction();
          toggleWaitListModal(false);
        }}
      />
      <MessageModal
        message={eventCheckData?.message || "You are unable to sign up for this event"}
        isOpen={isMessageModalOpen}
        toggleOpen={toggleMessageModal}
      />
    </>
  );
};

export default EventSignupButton;
