import { Autocomplete, Grid, Switch } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";

const OrganizationForm = ({ userTypeOptions, statusOptions, control, setValue }) => {
  return (
    <Grid container spacing={3} sx={{ padding: "25px" }}>
      <Grid item xs={4}>
        <Controller
          name="slug"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              {...field}
              name="slug"
              onChange={(e) => {
                setValue("slug", e.target.value, { shouldDirty: true });
              }}
              label="Slug"
              InputLabelProps={{ shrink: true }}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="userType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={userTypeOptions}
              {...field}
              name="userType"
              autoSelect
              freeSolo
              onChange={(e, v) => {
                setValue("userType", v || "", { shouldDirty: true });
              }}
              sx={{ maxHeight: "44px", width: "100%" }}
              renderInput={(params) => (
                <CustomTextField
                  type="text"
                  label="User Type"
                  showError
                  errorMessage={error?.message}
                  {...params}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="status"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={statusOptions}
              {...field}
              name="status"
              autoSelect
              freeSolo
              onChange={(e, v) => {
                setValue("status", v || "", { shouldDirty: true });
              }}
              sx={{ maxHeight: "44px", width: "100%" }}
              renderInput={(params) => (
                <CustomTextField
                  type="text"
                  label="Status"
                  showError
                  errorMessage={error?.message}
                  {...params}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center">
          <MDTypography variant="h6">Set as Primary</MDTypography>
          <Controller
            name="primary"
            control={control}
            render={({ field }) => (
              <Switch
                checked={field?.value === "Yes"}
                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
              />
            )}
          />
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default OrganizationForm;
