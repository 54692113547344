import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  venueMediaGallery: { marginTop: "16px", padding: "24px", paddingBottom: "24px", paddingTop: 0 },
  titleBar: { padding: "8px 0px", borderBottom: "2px solid #7b809a66", marginBottom: "16px" },

  // Gallery Section
  titleBar_title: { my: 1, ml: 2 },

  previewButton: {
    padding: 0,
  },

  gallerySection: {
    marginBottom: "24px",
  },

  section_images_wapper: {
    display: "flex",
    overflowX: "auto",
    padding: "24px 0px 24px 0px",
  },

  section_image_container: {
    position: "relative",
    margin: "0px",
    cursor: "pointer",
  },

  section_image: {
    width: "100%",
    height: "100%",
  },

  section_image_delete: {
    position: "absolute",
    right: "-8px",
    top: "-30px",
  },

  otherSection: {
    marginBottom: "24px",
  },

  dropzone: {
    height: 50,
  },
});

export default useStyles;
