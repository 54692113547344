import Activities from "components/Activities";
import { useAppContextController } from "context/AppContext";

const ApplicantActivities = () => {
  const { currentApplicant } = useAppContextController();

  return <Activities activitiesParent={currentApplicant} height={850} tableHeight={750} />;
};

export default ApplicantActivities;
