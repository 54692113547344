import axios from "axios";
import { baseAxios } from "config";

const exportJobEmployees = async ({ jobSlug, body }) => {
    try {
        const res = await baseAxios.post(`/jobs/${jobSlug}/applicants/export`, body);
        return res;
    } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        throw new Error(err.toString());
    }
};

export default exportJobEmployees;
