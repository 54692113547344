import { useEffect, useState } from "react";
import { Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import moment from "moment";

const useStyle = makeStyles({
  text: {
    color: "white",
    textAlign: "center",
  },
});

const AddEventModal = ({ positionName, event, positions, open, setOpen, onClick }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const classes = useStyle();
  const header = (
    <MDTypography variant="h5" className={classes.text}>
      Adding this event will allow us to schedule you. We will send you a confirmation email to let
      you know you are added to the event and all shift details.
    </MDTypography>
  );

  const [reportTime, setReportTime] = useState(event?.eventDate);
  const [endTime, setEndTime] = useState(new Date(event?.eventEndTime));
  useEffect(() => {
    const getReportTime = (posName) => {

      const eventDate = event?.eventDate;
      const eventEndTime = new Date(event?.eventEndTime);
      if (!positions?.length || !posName) return { reportTime: eventDate, endTime: eventEndTime };
      const ndx = positions?.findIndex((item) => item.positionName === posName);
      const rosterRec = ndx > -1 ? positions[ndx] : null;
      if (!rosterRec) return { reportTime: null, endTime: null };
      return {
        reportTime: rosterRec.reportTime || event?.eventDate,
        endTime: rosterRec.endTime || new Date(event?.eventEndTime),
        positionName: positionName || "Event Staff",
      };
    };

    const temp = getReportTime(positionName || "Event Staff");
    setReportTime(temp.reportTime);
    setEndTime(temp.endTime);

  }, [event, positions, open]);

  const buttons = (
    <>
      <Box display="flex" flexDirection="column">
        <Button
          variant="contained"
          color="white"
          sx={{
            color: "blue!important",
          }}
          onClick={onClick}
        >
          Add Event
        </Button>

        <Button
          variant="text"
          size="large"
          onClick={() => {
            setOpen(false);
          }}
        >
          <MDTypography variant="bod2" color="white" fontWeight="bold">
            Cancel
          </MDTypography>
        </Button>
      </Box>
    </>
  );

  const modalBody = (
    <Box>
      <MDTypography variant="h5" className={classes.text}>
        {event?.eventName || ""}
      </MDTypography>
      <MDTypography variant="h5" className={classes.text}>
        Position: {positionName || "Event Staff"}
      </MDTypography>
      <MDTypography variant="h5" className={classes.text}>
        {moment(reportTime).format("ddd, MMM Do, YYYY hh:mm A")} -{" "}
        {moment(endTime).format("hh:mm A")}
      </MDTypography>
    </Box>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        backgroundColor="blue"
        width={isSmallScreen ? "70%" : "30%"}
        buttonLocation="center"
      />
    </>
  );
};

export default AddEventModal;
