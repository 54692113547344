import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Alert, Snackbar } from "@mui/material";
import { isEmpty, isEqual } from "lodash";
import deletePartner from "layouts/pages/partners/actions/deletePartner";
// import fetchSinglePartner from "layouts/pages/partners/actions/fetchSinglePartner";
import PartnersFilterCards, {
  initialCards,
} from "layouts/pages/partners/components/PartnersFilterCards";
import MessagingPanel from "components/MessagingPanel";
import { useSnackbar } from "notistack";
import PartnersTable from "layouts/pages/partners/components/PartnersTable";
import PartnersInformation from "layouts/pages/partners/components/PartnersInformation";
import useSessionAuth from "hooks/useSessionAuth";
import PartnerApplicantsTable from "./components/PartnerApplicantsTable";
import fetchPartners from "./actions/fetchPartners";
import PartnerActivities from "./components/PartnerActivities";
import PartnerSales from "./components/PartnerSales";
import PartnerMembers from "./components/PartnerMembers";
import PartnerVenues from "./components/PartnersVenues";
import PartnerEvents from "./components/PartnerEvents";
import AddANoteModal from "../applicants/components/AddANoteModal";
import updatePartner from "./actions/updatePartner";
import PartnerNotes from "./components/PartnerNotes";
import UserPanel from "../applicants/components/UserPanel";
import PartnerSendMessagePanel from "./components/PartnersSendMessagePanel";
import PartnerAttachments from "./components/PartnerAttachments";

import PartnerContacts from "./components/PartnerContacts/PartnerContacts";
import postNotePartner from "./actions/postNotePartner";
import fetchSinglePartner from "./actions/fetchSinglePartner";
import PartnerUser from "./components/PartnerUser";

function Partners({ mode = "edit" }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { currentPartner, setCurrentPartner, currentLoggedUser } = useAppContextController();
  const { slug, action } = useParams();
  // const [selectedId, setSelectedId] = useState(partnerId);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [panelSelect, setPanelSelect] = useState("activities");
  const [filters, setFilters] = useState({});
  const [partnersPage, setPartnersPage] = useState(1);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  useEffect(() => {
    setSelectedContact();
  }, [currentPartner?.slug]);

  const { logoutAuth0User } = useSessionAuth();
  const refreshPartner = async (id) => {
    try {
      const newAppl = await fetchSinglePartner(id ?? currentPartner?._id);
      if (newAppl) setCurrentPartner(newAppl);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const deletePartnerHandler = async (deletePartnerId, partnerTitle) => {
    try {
      const res = await deletePartner(deletePartnerId);

      if (res?.status === 200) {
        await queryClient.invalidateQueries("partners");

        setToastAlert({
          isShow: true,
          message: `Partner ${partnerTitle} has been deleted`,
          status: "success",
        });
        return { success: true };
      }
    } catch (error) {
      setToastAlert({
        isShow: true,
        message: `Something went wrong`,
        status: "error",
      });

      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }

      return { success: false };
    }

    return { success: false };
  };

  const updatePartnerMutation = useMutation(updatePartner, {
    onError: (err) =>
      enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("partners");
      setCurrentPartner({ ...currentPartner, ...data, modifiedDate: new Date() });
      enqueueSnackbar("Partner has been updated!", { variant: "success" });
    },
  });

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };
    const updateNewNote = async () => {
      let res;
      await postNotePartner({ partnerId: currentPartner?._id, data: newNote })
        .then((response) => {
          res = response;
          enqueueSnackbar("Notes Have Been Successfully Updated", { variant: "success" });
        })
        .catch((response) => {
          res = response;
          enqueueSnackbar(`Something went wrong! ${response}`, { variant: "error" });
        });
      if (res?.data?.modifiedCount) {
        await refreshPartner();
      }

      return res;
    };

    return updateNewNote();
  };

  const { data: partners, isLoading } = useQuery(["customerdropdown"], () =>
    fetchPartners({
      filters: { status: "Active" },
      fetchAll: true,
      order: "asc",
      orderBy: "name",
    })
  );

  useEffect(() => {
    if (slug && (actionSelected || action)) setFilters({ slug });
    if (action) setActionSelected(action);
    else setActionSelected("");
    if (slug && (!currentPartner || currentPartner.slug !== slug)) {
      const partner = partners?.data?.find((customer) => customer.slug === slug);
      if (partner) {
        setCurrentPartner(partner);
      }
    }
  }, [action, actionSelected, slug, partners]);

  useEffect(() => {
    if (isEmpty(filters) && !isEqual(location.pathname, "/partners/create") && !action)
      navigate("/partners");
  }, [filters]);

  // useEffect(() => {
  //   setSelectedId(partnerId);
  // }, [partnerId]);

  // useEffect(() => {
  //   const getAndSetPartner = async () => {
  //     const partners = await fetchSinglePartner(selectedId);
  //     if (partners && partners?.data?.length) setCurrentPartner(partners?.data[0]);
  //   };
  //   if (partnerId && (actionSelected || action)) {
  //     setFilters({ _id: selectedId });
  //   }
  //   if (action) {
  //     // && action !== actionSelected) {
  //     setActionSelected(action);
  //   } else {
  //     setActionSelected("");
  //     // setFilters({ status: "Open" });
  //   }

  //   if (selectedId && currentPartner?._id !== selectedId) {
  //     getAndSetPartner();
  //   }
  // }, [selectedId, action, currentPartner?._id, partnerId, actionSelected]);
  // Default Filter Card
  // useEffect(() => {
  //   const { filterField, filterValue } = initialCards.find((card) => card.default);
  //   if (filterField && filterValue) {
  //     setFilters((currentFilters) => ({ ...currentFilters, [filterField]: filterValue }));
  //   }
  // }, []);

  function panelSelector(selection) {
    switch (selection) {
      case "activities":
        return <PartnerActivities />;
      case "contacts":
        return (
          <PartnerContacts
            setSelectedContact={setSelectedContact}
            setSelectedPanel={setPanelSelect}
            refreshPartner={refreshPartner}
          />
        );
      case "sales":
        return <PartnerSales />;
      case "members":
        return <PartnerMembers />;
      case "venues":
        return <PartnerVenues />;
      case "events":
        return <PartnerEvents />;
      case "notes":
        return <PartnerNotes setOpen={setNoteModalOpen} currentPartner={currentPartner} />;
      case "user":
        return (
          <PartnerUser currentPartner={currentPartner} setCurrentPartner={setCurrentPartner} />
        );
      case "message":
        return <PartnerSendMessagePanel selectedContact={selectedContact} />;
      case "attachments":
        return <PartnerAttachments />;
      default:
        return null;
    }
  }

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PartnersFilterCards
            setFilters={setFilters}
            // setPage={setPage}
          />
          <PartnersTable
            slug={slug}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Partners"
            setToastAlert={setToastAlert}
          />
        </Grid>
        {["partnersinfo", "partnersall", ""].includes(actionSelected) &&
          (slug || mode === "create") && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <PartnersInformation
                    setPanelSelect={setPanelSelect}
                    panelSelect={panelSelect}
                    mode={mode}
                    deletePartner={deletePartnerHandler}
                    refreshPartner={refreshPartner}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {panelSelector(panelSelect)}
                </Grid>
              </Grid>
            </Grid>
          )}

        {["partnersmembers", "partnersall"].includes(actionSelected) && currentPartner && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <PartnerApplicantsTable
                type="Staffing"
                showFiltersList={false}
                showSearchBar
                fetchAll
                filters={slug ? { "partners.partnerSlug": slug } : {}}
                title={`Members at: ${currentPartner?.slug?.toUpperCase()}`}
                navigateToAction="employeeinfo"
                defaultSort="lastName:asc, firstName:asc"
                partnerSlug={slug}
                showEventStatus
                pageSize={50}
                page={partnersPage}
                setPage={setPartnersPage}
                setToastAlert={setToastAlert}
                messageContext="Partner"
                venueId={currentPartner?._id}
                staffingPoolName={currentPartner?.name}
              />
            </Grid>
          </>
        )}
      </Grid>
      <AddANoteModal
        currentApplicant={currentPartner}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        saveNote={saveNote}
      />
    </DashboardLayout>
  );
}

export default Partners;
