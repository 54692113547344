export const removeHtmlTags = (string) => {
  const regex = /(<([^>]+)>)/gi;
  return string.replace(regex, "");
};

const tagsToRemove = ["strong", "span", "em", "u", "s", "a"];
const defaultOpening = ['<span class="ql-size-large">'];

const removeStyleAttribute = (htmlString) => {
  // Use regular expression to remove style attribute
  return htmlString.replace(/(<[^>]+) style=".*?"/gi, "$1");
};

export const removeStylingTags = (input, checkString = "${") => {
  // Recursively remove nested tags
  let result = input;
  tagsToRemove.forEach((tagName) => {
    /* eslint-disable-next-line */
    const regex = new RegExp(`<${tagName}[^>]*>(.*?)<\/${tagName}>`, "gi");
    result = result?.replace(regex, (match) => {
      const openingTag = match?.match(/<[^>]+>/g)?.[0];
      const content = removeHtmlTags(match)?.trim();
      if (!defaultOpening?.includes(openingTag)) {
        if (content.includes(checkString)) {
          if (content.startsWith(checkString)) {
            return `<span class="ql-size-large">${removeHtmlTags(match)}</span>`;
          }
          if (tagName === "a") {
            return removeStyleAttribute(match);
          }
          const stringRegex = checkString === "${" ? /\${(.*?)}/g : /{{(.*?)}}/g;
          const replacedString = match.replace(stringRegex, (inner) => {
            return `<p class="ql-size-large" style="display: inline; font-weight: normal; text-decoration: none; font-style: normal;">${inner.trim()}</p>`;
          });
          return replacedString;
        }
      }
      return match;
    });
  });

  return result;
};

export const getFullName = (contact = "") => {
  if (typeof contact === "string") return contact;
  if (contact?.fullName) {
    return contact?.fullName;
  }
  const { firstName, lastName } = contact;
  return `${firstName} ${lastName}`;
};

export const getNameInitials = (name) => {
  if (typeof name !== "string") return null;
  const nameArr = name.split(" ").map((item) => item[0]);
  return nameArr.join("").slice(0, 2).toUpperCase();
};
