import { useAppContextController } from "context/AppContext";
import { useState } from "react";
import useSort from "utils/useSort";
import usePreferredPageSize from "./usePreferredPageSize";

const useTableFunctions = ({
  defaultPage = 1,
  defaultLimit = 5,
  defaultOrderBy = "",
  defaultOrder = "asc",
}) => {
  const { currentLoggedUser } = useAppContextController();
  const [page, setPage] = useState(defaultPage);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(defaultLimit);
  const { order, orderBy, toggleSort } = useSort(defaultOrderBy, defaultOrder);

  return {
    page,
    setPage,
    limit,
    setLimit,
    order,
    orderBy,
    toggleSort,
    pageLimitConfirmationModal,
  };
};

export default useTableFunctions;
