import { Button, Tooltip } from "@mui/material";
import { CheckCircle, Error, Sync } from "@mui/icons-material";

const getButtonColor = (helmData) => {
  // helm will be inactive only when helmActive is false if key does not exist it will be active
  if (helmData?.helmActive === false) return "secondary";
  if (helmData?.isOnHelm) return "success";
  if (!helmData?.isValid) return "error";
  return undefined;
};

const getButtonIcon = (helmData, employeeID) => {
  // helm will be inactive only when helmActive is false if key does not exist it will be active
  if (helmData?.helmActive === false) return undefined;
  if (!helmData?.isValid) return <Error />;
  if (helmData?.isOnHelm && employeeID) return <CheckCircle />;
  return <Sync />;
};

// Helper function to get the tooltip message
const getTooltipMessage = (helmData, employeeID) => {
  // helm will be inactive only when helmActive is false if key does not exist it will be active
  if (helmData?.helmActive === false) return "Helm sync is not available at the moment.";
  if (helmData?.isOnHelm && !helmData?.isValid)
    return `Helm already synced but Following are missing requirements: ${helmData?.missingRequirements?.join(
      ", "
    )}`;
  if (!helmData?.isValid)
    return `Following are missing requirements: ${helmData?.missingRequirements?.join(", ")}`;
  if (helmData?.isOnHelm && employeeID) return "Helm already synced";
  return "Sync Helm";
};

const HelmSyncButton = ({ helmData, employeeID, syncHelmHandler, isLoading }) => {
  // helm data receiving here is modified from what was received from API
  const buttonColor = helmData ? getButtonColor(helmData) : undefined;

  return (
    <Tooltip title={helmData ? getTooltipMessage(helmData, employeeID) : ""}>
      <Button
        variant="contained"
        size="small"
        disabled={isLoading}
        {...(buttonColor && { color: buttonColor })}
        startIcon={helmData ? getButtonIcon(helmData, employeeID) : undefined}
        onClick={() => syncHelmHandler()}
      >
        Helm
      </Button>
    </Tooltip>
  );
};

export default HelmSyncButton;
