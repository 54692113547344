import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import PictureAvatar from "components/PictureAvatar";
import fetchPrimaryCompany from "api/companies/fetchPrimaryCompany";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { useAppContextController } from "context/AppContext";
import CompanyTableActions from "layouts/pages/companies/components/CompanyTableActions";
import useCompanyStore from "stores/useCompanyStore";
import useSort from "utils/useSort";
import usePreferredPageSize from "hooks/usePreferredPageSize";
// import UserActionModal from "../UserActionModal"

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CompanyTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = false,
  showSearchBar = false,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  page,
  setPage,
}) => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const { company } = useAppContextController();

  const navigate = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(10); // Default pageSize was 10. Use preferred pageSize
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentCompany });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters: { ...filters, primaryCompany: true } };
  const [toastAlert, setToastAlert] = useState(false);
  const { data: companies, isLoading } = useQuery(
    ["companyCache", allOptions],
    () => fetchPrimaryCompany(allOptions),
    { refetchInterval: 300000 }
  );

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const columns = [
    {
      title: "Avatar",
      field: "name",
      customCell: (name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    // {
    //   title: "Email/Name ID",
    //   field: "contactEmail",
    //   customCell: (email) => <div style={{ paddingLeft: "2rem" }}>{email}</div>,
    // },
    { title: "Company Name", field: "name" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    {
      title: "Last Modified In",
      field: "modifiedDate",
      customCell: (date) =>
        date ? <span>{moment(date).format("ddd YYYY-MM-DD hh:mm A")}</span> : "",
    },
    {
      title: "Company Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <CompanyTableActions
          id={id}
          row={row}
          setCurrentCompany={setCurrentCompany}
          setModalInfo={setModalInfo}
          setNavigateUrl={navigate}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "Company Name", field: "name" },
    // { title: "Last Name", field: "lastName" },
    // { title: "Email/Name ID", field: "emailAddress" },
    { title: "Company Type", field: "settings.companyType" },
  ];

  const handleRowClick = (comp, e) => {
    e.stopPropagation();
    setCurrentCompany({ ...comp });
    if (comp?._id) navigate(`/companies/${comp?._id}`);
  };

  const addNew = (e) => {
    setCurrentCompany({});
    navigate("/companies/create");
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <AccountCircleIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {company?.name} Company Settings
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={6}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {/* {showSearchBar && (
            <Searchbar
              fetch={fetchCompanies}
              fetchAll
              placeholder="Search Companies"
              columns={columnsForSearch}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name"]}
              filterBy="_id"
              onRowClick={(comp, e) => {
                setCurrentCompany(comp);
                navigate(`/companies/${comp._id}/action/companyinfo`);
                e.stopPropagation();
              }}
            />
          )} */}
          <MDBox>
            {/* There should be no add button for primary company */}
            {/* <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton> */}
          </MDBox>
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={{ data: [companies], length: 1 }}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          defaultSort="name:asc"
          autoSelectOne={false}
        />
      </MDBox>
      {pageLimitConfirmationModal}
      {/* <CompanyActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
    </Card>
  );
};

// Setting default values for the props
CompanyTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Company Settings",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
CompanyTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default CompanyTable;
