import { Controller } from "react-hook-form";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Cancel } from "@mui/icons-material";

const ShiftFormDayTimeController = ({ control, label, day, index, setValue }) => {
  const clearFields = () => {
    setValue(`shifts[${index}].defaultSchedule.${day}.start`, "", { shouldDirty: true });
    setValue(`shifts[${index}].defaultSchedule.${day}.end`, "", { shouldDirty: true });
  };
  return (
    <Grid item sm={1.5}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" py={0.5}>
          {label}
        </Typography>
        <IconButton color="error" onClick={() => clearFields()}>
          <Cancel fontSize="small" />
        </IconButton>
      </Stack>

      <Controller
        name={`shifts[${index}].defaultSchedule.${day}.start`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              value={!field.value ? null : field.value}
              onChange={(date) => {
                field.onChange(new Date(date));
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  sx={{ border: 1, borderRadius: 2, padding: 0.5, mb: 1 }}
                  autoComplete="off"
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
      <Controller
        name={`shifts[${index}].defaultSchedule.${day}.end`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              value={!field.value ? null : field.value}
              onChange={(date) => {
                field.onChange(new Date(date));
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  sx={{ border: 1, borderRadius: 2, padding: 0.5, mb: 1 }}
                  autoComplete="off"
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </Grid>
  );
};

export default ShiftFormDayTimeController;
