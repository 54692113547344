import TimelineIcon from "@mui/icons-material/Timeline";
import { Button, Grid } from "@mui/material";
import ActivitiesMessageModal from "components/Activities/ActivitiesMessageModal";
import GetIcons from "components/Activities/GetIcons";
import InfiniteScrollDataList from "components/InfiniteScrollDataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataList from "components/DataList";
import ActivitiesDetailModal from "../ActivitiesDetailModal";

const ActivitiesDataList = ({
  isLoading,
  activities,
  height,
  tableHeight,
  name,
  flexWrap,
  cardVariant = "outlined",
  activitiesParent,
  page,
  setPage,
  additionalRender,
  fetchNextPage,
  fullData,
  isFetching,
  noHeader,
}) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState(null);
  const [messageActivityModalOpen, setMessageActivityModalOpen] = useState(false);
  const [detailActivityModalOpen, setDetailActivityModalOpen] = useState(false);

  const renderRow = (row) => {
    return (
      <>
        <Grid
          container
          alignItems="center"
          onClick={() => {
            setActivity(row);
            setDetailActivityModalOpen(true);
          }}
        >
          <Grid item xs={12} sm={2}>
            <GetIcons action={row.action} activity={row} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="h6" key={`button_${row.action}`}>
                  {row.action}
                  {(row?.msgId !== undefined || row?.messageId !== undefined) && (
                    <Button
                      onClick={() => {
                        setMessageActivityModalOpen(true);
                        setActivity(row);
                      }}
                    >
                      view
                    </Button>
                  )}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2" key={`date_${row.action}`}>
                  {moment(row.activityDate).format("ddd YYYY-MM-DD hh:mm A")}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2" key={`position_${row.action}`}>
                  {row.description}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1} sx={{ textAlign: "end" }}>
            <MDBox>
              <Button
                onClick={() => {
                  setActivity(row);
                  setDetailActivityModalOpen(true);
                }}
              >
                View
              </Button>
            </MDBox>
          </Grid>
        </Grid>
      </>
    );
  };

  const header = <></>;

  return (
    <>
      <DataList
        renderRow={renderRow}
        infiniteData={activities}
        onRowClick={() => {}}
        icon={<TimelineIcon />}
        iconColor="error"
        title={noHeader ? undefined : `Activities${name ? `: ${name}` : ""}`}
        isLoading={isLoading}
        greybar
        divider
        header={header}
        cardVariant={cardVariant}
        tableHeight={tableHeight}
        height={height}
        scrollY
        flexWrap={flexWrap}
        additionalRender={additionalRender}
        data={fullData}
        isFetching={isFetching}
        loadMore={fetchNextPage}
        noIcon={noHeader}
      />
      <ActivitiesMessageModal
        activity={activity}
        messageActivityModalOpen={messageActivityModalOpen}
        setMessageActivityModalOpen={setMessageActivityModalOpen}
      />
      <ActivitiesDetailModal
        activity={activity}
        detailActivityModalOpen={detailActivityModalOpen}
        setDetailActivityModalOpen={setDetailActivityModalOpen}
      />
    </>
  );
};

export default ActivitiesDataList;
