/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useAppContextController } from "context/AppContext";
import useEventMapDetails from "hooks/useEventMapDetails";
import { Button, IconButton } from "@mui/material";
import { FmdGood } from "@mui/icons-material";

import CustomMap from "components/CustomMap";
import EventMapModal from "../EventMapModal";

const EventMap = ({ buttonType = "button" }) => {
  const { currentEvent } = useAppContextController();
  const { center, radius, graceDistance, roster } = useEventMapDetails();
  const [isEventMapOpen, setEventMapOpen] = useState(false);

  return (
    <>
      {buttonType === "map" && !isEventMapOpen ? (
        <CustomMap
          initialCenter={center}
          center={center}
          radius={radius}
          graceDistance={graceDistance}
          isCenterEditable={false}
          isRadiusEditable={false}
          isGDistanceEditable={false}
          roster={roster}
          disableControls
          onMapButtonClick={() => setEventMapOpen(true)}
        />
      ) : (
        <IconButton
          onClick={() => setEventMapOpen(true)}
          size="large"
          color="error"         
        >
          <FmdGood />
        </IconButton>
      )}
      <EventMapModal
        open={isEventMapOpen}
        setOpen={setEventMapOpen}
        name={currentEvent?.eventName}
        center={center}
        radius={radius}
        graceDistance={graceDistance}
        roster={roster}
      />
    </>
  );
};

export default EventMap;
