import * as yup from "yup";

const dropdownEntitiesSchema = (dropdown, allDropdowns) =>
  yup.object().shape({
    page: yup.string().typeError("Invalid page input").required("You must select a page"),
    entityType: yup
      .string()
      .typeError("Invalid entity type input")
      .required("You must select an entity type"),
    name: yup
      .string()
      .typeError("Invalid entity name input")
      .required("You must enter a value name for the entity")
      .test({
        name: "test-entityName-uniqueness",
        test: (value, ctx) => {
          const repeatedEntity = allDropdowns.find(
            (element) =>
              value?.toLowerCase().trim() === element?.name?.toLowerCase() &&
              dropdown?._id !== element?._id
          );

          if (!repeatedEntity) {
            return true;
          }
          return ctx.createError({ message: "Entity name is already in use" });
        },
      }),
    arrayValue: yup
      .array()
      .min(1, "You must enter at least one value")
      .required("You must enter at least one value"),
  });

export default dropdownEntitiesSchema;
