import axios from "axios";

import { baseAxios } from "config";

const fetchSingleVenue = async ({ venueId }) => {
  try {
    const res = await baseAxios.get(`/venues/${venueId}`);

    return res?.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchSingleVenue;
