import { styled, FormHelperText } from "@mui/material";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import FormField from "layouts/applications/wizard/components/FormField";
import { forwardRef } from "react";

const StyledInput = styled(FormField)(() => ({
  "& fieldset": {
    // borderColor: "#000",
  },
}));

const CustomTextField = forwardRef(({ showError = false, value, errorMessage = "", ...props }, ref) => {
  return (
    <>
      <StyledInput {...props} value={value || ""} ref={ref} />
      {showError && errorMessage && (
        <FormHelperText error sx={{ fontSize: "0.75rem" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
});

export default CustomTextField;


CustomTextField.defaultProps = {
  showError: false,
  value: "",
  errorMessage: "",
};

// Typechecking props for the TableAction
CustomTextField.propTypes = {
  showError: PropTypes.bool,
  value: PropTypes.string,
  errorMessage: PropTypes.string,


};