import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchUserActivities = async ({
  applicantId,
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {
    const res = await baseAxios.get(
      `/activities/user/${applicantId}${paginationQuery}${sortQuery}${filterQuery}${
        useOr ? "&useOr=true" : ""
      }`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchUserActivities;
