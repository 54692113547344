// Filter String Generator according to API requirement
const generateFiltersFromString = (filterString) => {
  const filterObj = {};
  const filtersArray = filterString.split(",");
  for (let index = 0; index < filtersArray.length; index += 1) {
    const filter = filtersArray[index];
    const filterSplit = filter.split(":");
    const key = filterSplit[0];
    let value = filterSplit[1];
    if (key === "tags") {
      value = value?.replaceAll("_", ":");
    }
    filterObj[key] = value;
  }

  return filterObj;
};

export default generateFiltersFromString;
