import { Autocomplete, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomTextField from "components/Form/CustomTextField";
import useDropdowns from "hooks/useDropdowns";

const DropdownEntitiesFields = ({ dropdown, control, setValue, clearErrors, errors }) => {
  const { dropdowns: dropDownEntityPages } = useDropdowns({ entity: "dropDownEntityPages" });
  const { dropdowns: entityTypes } = useDropdowns({ entity: "entityTypes" });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <CustomTextField
              {...field}
              disabled={!!dropdown}
              type="text"
              value={field.value}
              onChange={(e) => {
                clearErrors("name");
                setValue("name", e.target.value);
              }}
              label="Entity Name"
              showError={errors?.name}
              errorMessage={errors?.name?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name="entityType"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={!!dropdown}
              options={entityTypes || []}
              value={field.value}
              autoSelect
              freeSolo
              onChange={(e, v) => {
                clearErrors("entityType");
                setValue("entityType", v);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  type="text"
                  label="Entity Type"
                  showError={errors?.entityType}
                  errorMessage={errors?.entityType?.message}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Controller
          name="page"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={dropDownEntityPages?.sort() || []}
              value={field.value}
              autoSelect
              freeSolo
              onChange={(e, v) => {
                clearErrors("page");
                setValue("page", v, { shouldDirty: true });
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  type="text"
                  label="Page"
                  showError={errors?.page}
                  errorMessage={errors?.page?.message}
                />
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default DropdownEntitiesFields;
