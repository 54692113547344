import { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import PlusIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import StadiumIcon from "@mui/icons-material/Stadium";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

// import EventIcon from '@mui/icons-material/Event';
import enrollUserToEvent from "api/events/enrollUserToEvent";
import ConditionalWrapper from "components/Wrapper/ConditionalWrapper";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import { checkIfApplicantVenuePending } from "utils/helpers/applicant";

const eventStatusColors = {
  StaffingPool: "success",
  Pending: "warning",
  Locked: "error",
};

const ApplicantEventStatus = ({
  id,
  row,
  venue,
  slugField,
  invalidQuery,
  setRequery,
  currentEvent,
  blockUpdate,
  mode = "event",
  showTooltip = true,
  refetchEvents,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLoggedUser, currentApplicant } = useAppContextController();
  const queryClient = useQueryClient();
  const [eventStatus, setEventStatus] = useState(row?.rosterStatus);
  const [color, setColor] = useState("dark");

  useEffect(() => {
    if (eventStatus) {
      setColor(eventStatusColors[eventStatus]);
    } else setColor("dark");
  }, [eventStatus]);

  const { mutate: enrollEventMutation } = useMutation(enrollUserToEvent, {
    onSuccess: async ({ data }) => {
      if (data?.success) {
        const { message = "", status = "", type: responseType = "" } = data;
        if (message) {
          enqueueSnackbar(message, { variant: status.toLowerCase() });
        }
        refetchEvents?.();
      }
    },
    mutationKey: "enrollMutation",
  });

  const handlePendingApplicant = (e) => {
    e.stopPropagation();
    const body = {
      requestType: "Request",
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };
    enrollEventMutation({
      eventUrl: row?.eventUrl,
      applicantId: currentLoggedUser.applicantId,
      body,
    });
  };

  const isApplicantEventVenuePending = useMemo(() => {
    const eventVenueSlug = row?.venueSlug;
    const applicantVenues = currentApplicant?.venues;
    return checkIfApplicantVenuePending(applicantVenues, eventVenueSlug);
  }, [row, currentApplicant]);

  const renderCheckXHourglass = () => {
    if (["Roster", "Request"].includes(eventStatus)) {
      return (
        <Tooltip
          title={`${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName} is on ${eventStatus}`}
        >
          <CheckIcon
            key={`eventStatus_${id}`}
            color={eventStatus === "Roster" ? "success" : "warning"}
            fontSize="large"
          />
        </Tooltip>
      );
    }
    if (eventStatus === "Waitlist") {
      return (
        <Tooltip
          title={`${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName} is Waitlisted`}
        >
          <HourglassTopIcon key={`eventStatus_${id}`} color="secondary" fontSize="large" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={`${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName} is not on the Roster. Click Event Preview to Request`}
      >
        <PlusIcon
          onClick={(e) => {
            if (isApplicantEventVenuePending) {
              handlePendingApplicant(e);
            }
            // if (!eventStatus) handleUpdate(e, "Roster");
          }}
          key={`eventStatus_${id}`}
          color={isApplicantEventVenuePending ? "warning" : "info"}
          fontSize="large"
          sx={{ cursor: "pointer" }}
        />
      </Tooltip>
    );
  };

  return (
    <span>
      <ConditionalWrapper
        condition={showTooltip}
        wrap={(children) => (
          <Tooltip
            title={`${row?.firstName} ${row?.lastName} is ${eventStatus || "Not Associated"}`}
          >
            {children}
          </Tooltip>
        )}
      >
        {mode === "stadium" ? (
          <StadiumIcon
            onClick={(e) => {
              // no action here yet
            }}
            key={`eventStatus_${id}`}
            color={color}
            fontSize="large"
          />
        ) : (
          renderCheckXHourglass()
        )}
      </ConditionalWrapper>
    </span>
  );
};

export default ApplicantEventStatus;

ApplicantEventStatus.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
ApplicantEventStatus.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
