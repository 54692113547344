import { baseAxios } from "config";

const addApplicantToJob = async ({ applicantId, jobSlug, score }) => {
  try {
    const res = await baseAxios.put(`/applicants/${applicantId}/job`, {
      jobSlug,
      score,
    });

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default addApplicantToJob;
