import { useEffect, useState } from "react";
// import TableAction from "components/TableAction";
import axios from "axios";
import { baseAxios } from "config";
import { useMutation, useQueryClient } from "react-query";

import PlusIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import StadiumIcon from "@mui/icons-material/Stadium";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

import EventIcon from "@mui/icons-material/Event";
import { useAppContextController } from "context/AppContext";

const venueStatusColors = {
  StaffingPool: "success",
  Pending: "warning",
  Locked: "error",
};

const ApplicantVenueStatus = ({
  id,
  row,
  venue,
  slugField,
  invalidQuery,
  setRequery,
  blockUpdate,
  mode = "stadium",
  refetchVenues,
}) => {
  const { currentLoggedUser, setCurrentApplicant, currentApplicant } = useAppContextController();
  const queryClient = useQueryClient();
  const [venueStatus, setVenueStatus] = useState("");
  const [color, setColor] = useState("dark");

  useEffect(() => {
    if (row?.venues?.length) {
      const ndx = row.venues?.findIndex((item) => {
        return item[slugField] === venue;
      });

      let venueRec;
      if (ndx > -1) venueRec = row.venues[ndx];
      if (venueRec) setVenueStatus(venueRec.status);
      else setVenueStatus("");
    } else setVenueStatus("");
  }, [row?.venues, row]);

  useEffect(() => {
    if (venueStatus) {
      setColor(venueStatusColors[venueStatus]);
    } else setColor("dark");
  }, [venueStatus]);

  const rosterMutation = useMutation(
    async (data) => {
      // eslint-disable-next-line no-unused-expressions
      await baseAxios.put(`/applicants/${data.id}`, { ...data });
    },
    {
      // eslint-disable-next-line no-return-await
      onSuccess: async (_, { venues }) => {
        if (invalidQuery) await queryClient.invalidateQueries(invalidQuery);
        if (setRequery) setRequery();
        setCurrentApplicant({ ...currentApplicant, venues });
      },
      onError: (error) => alert(error.toString()),
    }
  );

  const handleUpdate = async (e, newStat) => {
    e.stopPropagation();
    let newStatus;
    if (newStat) newStatus = newStat;
    else if (!venueStatus) newStatus = "StaffingPool";
    else if (venueStatus === "StaffingPool") newStatus = "Pending";
    else if (venueStatus === "Pending") newStatus = "Locked";
    else if (venueStatus === "Locked") newStatus = "";

    setVenueStatus(newStatus);
    // await updateStatus(row?._id, "venues", newStatus);
    // now let's compute the new venues array for the applicant
    let newVenues = [...row.venues];
    if (!newStatus) newVenues = row.venues?.filter((item) => item[slugField] !== venue);
    else {
      const ndx = row.venues?.findIndex((item) => item[slugField] === venue);
      const venueRec = {
        venueSlug: venue,
        ...newVenues[ndx],
        status: newStatus,
        dateModified: new Date().toISOString(),
        agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
      };
      if (ndx > -1) {
        newVenues[ndx] = venueRec;
      } else newVenues.push(venueRec);
    }

    rosterMutation.mutate({
      id: row?._id,
      venues: [...newVenues],
      dateModified: new Date(),
      agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
    });
  };

  const renderCheckOrX = () => {
    if (venueStatus) {
      return (
        <CheckIcon
          key={`venuestatus_${id}`}
          color={venueStatusColors[venueStatus]}
          fontSize="large"
        />
      );
    }
    return (
      <PlusIcon
        onClick={(e) => {
          if (!venueStatus) handleUpdate(e, "Pending");
        }}
        key={`venuestatus_${id}`}
        color="info"
        fontSize="large"
      />
    );
  };

  if (slugField?.includes("venue")) {
    return (
      <span>
        <Tooltip title={`${row?.firstName} ${row?.lastName} is ${venueStatus || "Not Associated"}`}>
          {mode === "stadium" ? (
            <StadiumIcon
              onClick={(e) => {
                if (!blockUpdate) handleUpdate(e);
              }}
              key={`venuestatus_${id}`}
              color={color}
              fontSize="large"
            />
          ) : (
            renderCheckOrX()
          )}
        </Tooltip>
      </span>
    );
  }
  return (
    <span>
      <Tooltip title={`Click to request this event for ${row?.firstName} ${row?.lastName}`}>
        <EventIcon key={`eventstatus_${id}`} color={color} fontSize="large" />
      </Tooltip>
    </span>
  );
};

export default ApplicantVenueStatus;

ApplicantVenueStatus.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
ApplicantVenueStatus.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
