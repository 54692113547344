import { baseAxios } from "config";

export const exportReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/export`, data, {
      responseType: "blob",
    });

    const blob = new Blob([res.data], { type: res.headers["content-type"] });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    const fileType = data.fileType === "csv" ? "csv" : "xlsx";
    link.setAttribute("download", `report.${fileType}`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);
  } catch (err) {
    throw new Error(err.toString());
  }
};
