import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchPartnerActivites = async ({
  partnerId,
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });
  try {
    const res = await baseAxios.get(
      `/activities/partner/${partnerId}${paginationQuery}${sortQuery}${filterQuery}${
        useOr ? "&useOr=true" : ""
      }`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchPartnerActivites;
