import { useEffect, useState } from "react";
import useBadgeValues from "hooks/useBadgeValues";
import FilterCardBar from "components/FilterCardBar";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { useParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";

const TimeRosterFilterCards = ({
  setFilters,
  setPage,
  selectedCardNumber,
  rosterFilters,
  setRosterStatus,
  refreshBadges = false,
}) => {
  const { userType } = useAppContextController();

  const { eventUrl } = useParams();

  const initialCards = [
    {
      color: "info",
      icon: <InfoIcon fontSize="large" color="info" />,
      title: "All",
      tooltip: "",
      number: 0,
      filterField: "status",
      filterValue: "",
    },
    {
      color: "success",
      icon: <CheckCircleIcon fontSize="large" color="success" />,
      title: "On Roster",
      tooltip: "",
      number: 0,
      filterField: "status",
      filterValue: "Roster",
    },
    {
      color: "warning",
      icon: <CheckCircleIcon fontSize="large" color="warning" />,
      title: "Request",
      tooltip: "",
      number: 0,
      filterField: "status",
      filterValue: "Request",
    },
    {
      color: "secondary",
      icon: <HourglassTopIcon fontSize="large" color="secondary" />,
      title: "Waitlist",
      tooltip: "",
      number: 0,
      filterField: "status",
      filterValue: "Waitlist",
    },
    {
      color: "error",
      icon: <CancelIcon fontSize="large" color="error" />,
      title: "Not on Roster",
      tooltip: "",
      number: 0,
      filterField: "status",
      filterValue: "Not Roster",
    },
    ...(userType !== "Client" && userType !== "Event Admin"
      ? [
          {
            color: "secondary",
            icon: <HandshakeIcon fontSize="large" color="secondary" />,
            title: "Partners",
            tooltip: "",
            number: 0,
            filterField: "status",
            filterValue: "Partner",
          },
          {
            color: "warning",
            icon: <Diversity3Icon fontSize="large" color="warning" />,
            title: "Members",
            tooltip: "",
            number: 0,
            entity: `events/url/${eventUrl}/members`,
            filterField: "status",
            filterValue: "Member",
          },
        ]
      : []),
  ];

  // const [cards, setCards] = useState(initialCards)
  const { cards } = useBadgeValues({
    initialCards,
    filters: rosterFilters,
    entity: `events/url/${eventUrl}/roster`,
    refreshBadges,
  });

  const setTimeFilters = (filter) => {
    setFilters(filter);
    setRosterStatus(filter?.status || "");
    // setRefreshBadges(prev => !prev);
  };

  return (
    <FilterCardBar cards={cards} bgColor="primary" setFilters={setTimeFilters} isBlankForAll />
  );
};

export default TimeRosterFilterCards;
