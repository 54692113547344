import axios from "axios";

import { baseAxios } from "config";

export const generateVenueTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venues-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venues-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantVenueTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-venues-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantVenueChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-venues-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEmployeeVenueTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/employee-venues-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEmployeeVenueChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/employee-venues-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
