import { Close } from "@mui/icons-material";
import { Card, CardContent, IconButton, Modal } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";

const SearchbarModal = ({ isOpen, onClose, results }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          width: "90%",
          top: "50%",
          right: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "550px",
          maxHeight: "640px",
        }}
      >
        <CardContent sx={{ pt: "50px" }}>
          <FlexWrapper>{results}</FlexWrapper>
          <IconButton onClick={onClose} sx={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </IconButton>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default SearchbarModal;
