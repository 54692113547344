import axios from "axios";

import { baseAxios } from "config";

const createCompany = async (data) => {
  try {
    const res = await baseAxios.post(`/companies`, data);
    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default createCompany;
