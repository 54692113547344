import axios from "axios";

import { baseAxios } from "config";

export const generateJobTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/jobs-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateJobChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/jobs-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueJobTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venue-jobs-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateVenueJobChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/venue-jobs-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantJobTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-jobs-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantJobChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicant-jobs-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEmployeeJobTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/employee-jobs-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateEmployeeJobChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/employee-jobs-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCompanyJobTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-jobs-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateCompanyJobChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/company-jobs-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
