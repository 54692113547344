import * as yup from "yup";
import { every, isEmpty } from "lodash";

const transformYupErrorsIntoObject = (errors) => {
  let validationErrors = {};

  errors?.inner?.forEach((error) => {
    if (error.path !== undefined) {
      validationErrors = { ...validationErrors, [error.path]: error.errors[0] };
    }
  });

  return validationErrors;
};

const getDirtyFields = (formValues, dirtyFields) => {
  const dataChanged = {};

  Object.keys(formValues).forEach((key) => {
    if (dirtyFields?.[key]) dataChanged[key] = formValues[key];
  });

  return dataChanged;
};

const integratePrimaryToContacts = (data, contact, contacts) => {
  const existingContacts = contacts || [];
  const primaryIdx = existingContacts.findIndex(({ primaryContact }) => primaryContact === "Yes");

  let updatedContacts;

  // checks if all fields are empty, then delete contact
  const allEmpty = every(contact, isEmpty);

  if (primaryIdx !== -1) {
    updatedContacts = existingContacts.map((item, i) => {
      if (i === primaryIdx) return { ...item, ...contact };
      return item;
    });
    if (allEmpty) updatedContacts = existingContacts.filter((_, i) => i !== primaryIdx);
  } else {
    updatedContacts = [...existingContacts, { ...contact, primaryContact: "Yes" }];
  }
  if (data.contact)
    return { ...data, contact: updatedContacts[primaryIdx], contacts: updatedContacts };
  return { ...data, contacts: updatedContacts };
};

const integratePrimaryToLocations = (
  data,
  tempLoc,
  defaultNewLoc,
  currentLoc,
  coordinates,
  locations
) => {
  const existingLocations = locations || [];
  const primaryIdx = existingLocations.findIndex(
    ({ primaryLocation }) => primaryLocation === "Yes"
  );

  let location = { ...currentLoc, ...tempLoc, coordinates: coordinates || currentLoc?.coordinates };

  let updatedLocations;

  const allEmpty = every(tempLoc, isEmpty);

  if (primaryIdx !== -1) {
    updatedLocations = existingLocations.map((item, i) => {
      if (i === primaryIdx)
        return {
          ...item,
          ...tempLoc,
          ...(coordinates && {
            geocoordinates: { type: "Point", ...item.geocoordinates, coordinates },
          }),
        };
      return item;
    });
    if (allEmpty) {
      location = {
        ...location,
        coordinates: [0, 0],
        geoFenceRadius: 100,
        graceDistanceFeet: 100,
        latitude: 0,
        longitude: 0,
        radius: 100,
      };
      updatedLocations = existingLocations.filter((_, i) => i !== primaryIdx);
    }
  } else {
    updatedLocations = [...existingLocations, { ...tempLoc, ...defaultNewLoc }];
  }

  return {
    ...data,
    location,
    locations: updatedLocations,
  };
};

export const formatTitle = (loc) =>
  loc
    .trimStart()
    .toLowerCase()
    .replaceAll("  ", " ")
    .replaceAll(" ", "-")
    .replace(/&/g, "-and-")
    .replace(/\./g, " ")
    .replace(/-/g, " ")
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/--/g, "-")
    .replace(/ {2}/g, " ")
    .replace(/ /g, "-")
    .replace(/--/g, "-")
    .replace(/-$/, "");

const formatSlug = (slug, loc) => `${slug}-${formatTitle(loc)}`;

export {
  getDirtyFields,
  transformYupErrorsIntoObject,
  integratePrimaryToContacts,
  integratePrimaryToLocations,
  formatSlug,
};
