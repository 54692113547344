import { Grid, Stack, IconButton, Icon } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DataList from "components/DataList";
import EventDate from "components/EventDate";
import EventStatusButton from "components/EventStatusButton";
import FilterButtons from "components/FilterButtons";
import { APPLICANT_EVENTS_OPTIONS } from "components/FilterButtons/options";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { Info as InfoIcon } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { getApplicantEventStatus } from "utils/helpers/applicant";
import { isAdmin } from "utils/helpers/roles";
import moment from "moment";
import fetchCustomers from "api/companies/fetchCustomers";

const ApplicantOnboardingEvents = ({
  fetchAll,
  setActionSelected = "info",
  title = "Title",
  filters,
  setFilters,
}) => {
  const [dataFilter, setDataFilter] = useState({ "applicants.status": "All" });

  const [customerData, setCustomerData] = useState({});
  const { currentApplicant, setCurrentApplicant, venues, userType, company, companies } =
    useAppContextController();
  const isCompany = company?.companyType === "Company";
  const navigate = useNavigate();
  const [applicantVenues, setApplicantVenues] = useState(
    currentApplicant?.venues ? currentApplicant.venues.map((item) => item.venueSlug).join(";") : ""
  );

  const allOptions = useMemo(() => {
    const result = {
      fetchAll,
      filters: isCompany
        ? {
            ...filters,
            eventUrl: currentApplicant?.interviews?.map((applInt) => applInt.eventUrl).join(";"),
          }
        : {
            ...filters,
            venueSlug: applicantVenues,
            "applicants.id": currentApplicant?._id,
            timeFrame: "Current",
          },
      page: 1,
      limit: 200,
      applicantId: currentApplicant?._id,
    };

    if (!isCompany && dataFilter?.["applicants.status"]) {
      if (dataFilter?.["applicants.status"] !== "All") {
        result.filters = { ...result.filters, ...dataFilter };
      } else {
        delete result.filters?.["applicants.status"];
      }
    }

    return result;
  }, [
    applicantVenues,
    currentApplicant?._id,
    currentApplicant?.interviews,
    dataFilter,
    fetchAll,
    filters,
    isCompany,
  ]);

  const {
    data: events,
    isLoading,
    refetch,
  } = useQuery(["applicantevents", currentApplicant, allOptions], () => fetchEvents(allOptions), {
    onSuccess: async (data) => {
      if (isCompany) {
        const customers = {};

        try {
          // Get all company slugs
          let companySlugs = data?.data?.map((comp) => comp.companySlug);
          // Remove duplicates
          companySlugs = [...new Set(companySlugs)];

          const results = await fetchCustomers({
            filters: { slug: companySlugs.join(";") },
            page: 1,
            limit: 0,
          });

          (results?.data ?? []).forEach((cstmr) => {
            customers[cstmr.slug] = cstmr;
          });
          setCustomerData(customers);
        } catch (error) {
          // Handle errors
          console.log("error", error);
        }
      }
    },
  });

  useEffect(() => {
    if (currentApplicant) refetch();
  }, [currentApplicant]);

  const { mutate: changeEventStatusMutation } = useMutation(updateEvent, {
    onSuccess: async (_, { data }) => setCurrentApplicant({ ...currentApplicant, ...data }),
    mutationKey: "eventStatusMutation",
  });

  const [allEvents, setAllEvents] = useState([]);

  const sortByEventDate = (a, b) => {
    if (a.eventDate > b.eventDate) return 1;
    if (a.eventDate < b.eventDate) return -1;
    return 0;
  };

  useEffect(() => {
    if (!isLoading) {
      setAllEvents(events?.data?.sort(sortByEventDate));
    }
  }, [isLoading]);

  useEffect(() => {
    if (currentApplicant?.venues) {
      setApplicantVenues(currentApplicant.venues.map((item) => item.venueSlug).join(";"));
    } else setApplicantVenues("");
  }, [currentApplicant]);

  // const searchBarColumns = [
  //     {
  //         title: "Roster", field: "venueSlug",
  //         customCell: (vslug, field, row) => <ApplicantEventActions
  //             setStatusRef={setAndRequery}
  //             slug={row?.eventUrl}
  //             startStatus={(slug) => getEventStatus(slug)}
  //         />
  //     },
  //     { title: "Event URL", field: "eventUrl", customCell: (slug) => <span>{slug?.toUpperCase()}</span> },
  //     { title: "Event Name", field: "eventName" },
  //     { title: "City", field: "venueCity" },
  //     { title: "State", field: "venueState" },
  // ];

  const renderRow = (row) => {
    const eventStatus = getApplicantEventStatus(currentApplicant, row);
    return isCompany ? (
      <>
        <Grid
          container
          alignItems="center"
          key={row._id}
          onClick={() => {
            navigate(`/events/${row?.eventUrl}/action/eventsinfo`);
          }}
        >
          <Grid item xs={12} sm={2}>
            <CompanyLogoSquare
              company={customerData[row.companySlug] ? customerData[row.companySlug] : {} || row}
              defaultCompany={company}
              parent="MyInterviews"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {customerData[row.companySlug]?.name || row?.companySlug?.toUpperCase()}
          </Grid>
          <Grid item xs={12} sm={4}>
            {moment(row?.eventDate).format("dddd, MMM Do, YYYY, hh:mm A")}
          </Grid>
          <Grid item xs={12} sm={3} textAlign="end">
            <InfoIcon fontSize="large" color="info" />
          </Grid>
        </Grid>
      </>
    ) : (
      <>
        <MDBox
          key={`${row.eventName}_${row.eventUrl}`}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={2} lg={1.5}>
            <VenueIcon
              logoUrl={venues[row.venueSlug]?.logoUrl}
              slug={row.venueSlug}
              name={row.venueName}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={8.5}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row.eventName}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} display="flex">
                <MDTypography variant="body2">
                  {row.venueCity}, {row.venueState}
                </MDTypography>
                &nbsp;
                <MDTypography variant="body2">
                  - <EventDate date={row?.eventDate} reportTimeTBD={row?.reportTimeTBD} />
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Stack direction="row" justifyContent="right">
              <EventStatusButton status={eventStatus} event={row} onUpdate={() => refetch()} />
              <IconButton
                onClick={() => {
                  navigate(`/events/${row?.eventUrl}/action/eventsinfo`);
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Stack>
          </Grid>
        </MDBox>
      </>
    );
  };

  const header = isCompany ? (
    <></>
  ) : (
    <FlexWrapper p={2} justifyContent="flex-end">
      {isAdmin(userType) && (
        <FilterButtons
          filter={dataFilter}
          setFilter={setDataFilter}
          name="applicants.status"
          options={APPLICANT_EVENTS_OPTIONS}
        />
      )}
    </FlexWrapper>
  );

  return (
    <DataList
      renderRow={renderRow}
      data={events?.data}
      icon="event-seat"
      iconColor="success"
      title={company?.companyType === "Venue" ? "Events" : "Interviews"}
      isLoading={false}
      greybar
      divider
      header={header}
      tableHeight={750}
      height={850}
      scrollY
      computedHeight
    />
  );
};

export default ApplicantOnboardingEvents;
