import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import { Button, Modal, Fade, Card, Grid, Autocomplete, Alert } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MDBox from "components/MDBox";
import AttachFile from "@mui/icons-material/AttachFile";
import { makeStyles } from "@mui/styles";
import useDropdowns from "hooks/useDropdowns";
import MDTypography from "components/MDTypography";
import { baseAxios } from "config";
import FormField from "layouts/applications/wizard/components/FormField";
import axios from "axios";
import FileDropzone from "components/Dropzone";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { Snackbar } from "@material-ui/core";
import useSessionAuth from "hooks/useSessionAuth";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
});

const UploadFileModal = ({ attachmentOpen, setAttachmentOpen }) => {
  const classes = useStyle();
  const { currentEvent, setCurrentEvent } = useAppContextController();
  const handleClose = () => setAttachmentOpen(false);
  const [attachmentTitle, setAttachmentTitle] = useState(null);
  const [attachmentType, setAttachmentType] = useState(null);
  const [missingMeta, setMissingMeta] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const { logoutAuth0User } = useSessionAuth();

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const { dropdowns: eventAttachmentTypes } = useDropdowns({ entity: "eventAttachmentTypes" });
  const queryClient = useQueryClient();
  const handleInputChange = (e) => {
    e.preventDefault();
    setAttachmentTitle(e.target.value);
  };
  const updateEventMutation = useMutation(updateEvent, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("events");
      setCurrentEvent({ ...currentEvent, ...data });
      setToastAlert({ isShow: true, message: "Attachment has been uploaded!", status: "success" });
    },
  });

  const onDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const uploadFile = async (form, fileName, fileType) => {
        // alert(`/upload/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/`);
        await baseAxios.post(
          `/upload/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/`,
          form
        );
        const newAtt = {
          title: attachmentTitle || fileName,
          type: attachmentType,
          docType: fileType,
          filename: fileName,
          uploadDate: new Date(),
        };
        // if we find this attachment type then overwrite the current
        const newAttachmentsArr = currentEvent.attachments ? [...currentEvent.attachments] : [];
        if (
          newAttachmentsArr.length &&
          attachmentType !== "Other" &&
          newAttachmentsArr.findIndex((item) => item.type === attachmentType) > -1
        ) {
          const ndx = currentEvent.attachments.findIndex((item) => item.type === attachmentType);
          newAttachmentsArr[ndx] = newAtt;
        } else newAttachmentsArr.push(newAtt);

        await updateEventMutation.mutateAsync({
          eventId: currentEvent._id,
          data: {
            attachments: [...newAttachmentsArr],
          },
        });
        // , data: {
        //     // ...currentEvent,
        //     attachments: [...newAttachmentsArr]
      };
      const file = acceptedFiles[0];
      // const newFile = { ...file, path: file?.path?.replaceAll(" ", "_") };
      const fileType = file?.path?.split(".").pop();
      const form = new FormData();
      form.append("file", file);
      if (currentEvent._id) {
        try {
          setIsUploading(true);
          await uploadFile(form, file.path, fileType);
          setIsUploading(false);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      }
      setAttachmentTitle(null);
      setAttachmentType(null);
      setAttachmentOpen(false);
    }
  };

  useEffect(() => {
    if (attachmentType !== null) {
      setMissingMeta(false);
    }
    if (attachmentType === null) {
      setMissingMeta(true);
    }
  }, [attachmentType]);
  return (
    <>
      <Snackbar
        attachmentOpen={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={attachmentOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={attachmentOpen}>
          <Card className={classes.container}>
            <MDBox p={4}>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography>
                  <AttachFile color="error" /> Upload File
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                  <Button
                    variant="text"
                    disabled={isUploading}
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setAttachmentTitle(null);
                      setAttachmentType(null);
                      setAttachmentOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </MDBox>
              </MDBox>
              <Grid item lg={8}>
                <Autocomplete
                  options={eventAttachmentTypes || []}
                  disabled={isUploading}
                  onChange={(e, v) => {
                    setAttachmentType(v);
                  }}
                  renderInput={(params) => (
                    <FormField label="Attachment Type" {...params} type="text" />
                  )}
                />
              </Grid>
              <Grid item lg={8}>
                <FormField
                  variant="standard"
                  label="Name or Title"
                  value={attachmentTitle || ""}
                  disabled={isUploading}
                  onChange={handleInputChange}
                />
              </Grid>
              <MDBox mt={2}>
                <MDTypography variant="h5" color="info">
                  Upload File
                </MDTypography>
                <MDBox
                  onClick={() => {
                    if (missingMeta)
                      setToastAlert({
                        isShow: true,
                        message: "You need fill an Attachment",
                        status: "error",
                      });
                  }}
                >
                  <FileDropzone
                    disabled={missingMeta || isUploading}
                    multiple={false}
                    message={
                      missingMeta
                        ? "You must fill Attachment Type"
                        : "Drop files here to upload"
                    }
                    className={classes.dropzone}
                    onDrop={onDropHandler}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default UploadFileModal;
