import {
    Cancel as CancelIcon,
    ContentCopy as ContentCopyIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    Visibility
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import ConfirmDialog from "components/ConfirmDialog/ConfirmModal";
import { useAppContextController } from "context/AppContext";
import deleteEvent from "layouts/pages/events/actions/deleteEvent";

const initialValueModal = {
    title: null,
    description: null,
    negativeBtn: null,
    positiveBnt: null,
    isOpen: false,
};

const InterviewFormActions = ({ submitted, hasChanges, resetChanges, setEditorValue, updateInterviewMutation, onPreview, }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { currentEvent: interview, setCurrentEvent } = useAppContextController();
    const isEdit = !!interview?._id;
    const [searchParams] = useSearchParams();
    const copyInterviewId = searchParams.get("copyInterviewId");
    const [confirmModal, setConfirmModal] = useState(initialValueModal);

    const closeModalHandler = () => setConfirmModal(initialValueModal);

    const copyInterviewHandler = () => navigate(`/events/create?copyInterviewId=${interview._id}`);

    const deleteEventVenue = useMutation(deleteEvent, {
        onSuccess: async () => {
            enqueueSnackbar(`${interview?.eventName} has been deleted`, { variant: "success" });
            setCurrentEvent({});
            navigate(`/events/venue/${interview?.venueSlug}`);
        },
        onError: (error) => {
            enqueueSnackbar(`Something went wrong -- ${error}`, { variant: "error" });
        },
    });

    const cancelChangesHandler = () => {
        const url = interview?.eventUrl;
        resetChanges();
        setEditorValue(interview.description);
        enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
        navigate(`/events/${url}`);
    };

    const confirmInfo = {
        cancelCreate: {
            title: "You are leaving create mode",
            description: "Please Confirm to abandon new Interview.",
            negativeBtn: {
                label: "Cancel",
                fn: closeModalHandler,
                isShown: true,
            },
            positiveBtn: {
                label: "Confirm",
                fn: () => {
                    setConfirmModal(initialValueModal);
                    navigate(`/events`);
                },
                isShown: true,
            },
            isOpen: true,
        },
        deleteInterview: {
            isOpen: true,
            title: "Delete Interview",
            bodyText: "Please confirm you want to DELETE this interview!",
            response: false,
            description: "Please confirm you want to DELETE this interview!",
            negativeBtn: {
                label: "Cancel",
                fn: closeModalHandler,
                isShown: true,
            },
            positiveBtn: {
                label: "Confirm",
                fn: async () => {
                    // await deleteEventVenue.mutateAsync(event._id, event.name);
                    await updateInterviewMutation.mutateAsync({
                        companySlug: interview.companySlug,
                        eventId: interview._id,
                        data: { status: "Deleted" },
                    });
                    closeModalHandler();
                    navigate(`/events`);
                },
                isShown: true,
            },
        },
        cancelModified: {
            title: copyInterviewId ? "Copy Interview" : "Data has changed!",
            description: copyInterviewId
                ? "Abandon Copy Interview?"
                : "Please confirm you want to abandon changes",
            negativeBtn: {
                label: "Cancel",
                fn: closeModalHandler,
                isShown: true,
            },
            positiveBtn: {
                label: "Confirm",
                fn: () => {
                    if (!copyInterviewId) navigate(`/events/venue/${interview?.companySlug}`);
                    closeModalHandler();
                    cancelChangesHandler();
                    setConfirmModal(initialValueModal);
                },
                isShown: true,
            },
            isOpen: true,
        },
    };

    const confirmModalHandler = (action) => {
        setConfirmModal(confirmInfo[action]);
    }

    const formActionBtnInfo = [
        {
            label: "Cancel",
            variant: "text",
            iconInfo: {
                placement: "start",
                icon: <CancelIcon />,
            },
            func: confirmModalHandler,
            isDisabled: submitted,
            type: "button",
            forEdit: "",
            forModified: true,
            style: {},
            action: isEdit ? "cancelModified" : "cancelCreate",
        },
        {
            label: "Create",
            variant: "contained",
            iconInfo: {
                placement: "end",
                icon: <SaveIcon />,
            },
            isDisabled: submitted,
            type: "submit",
            forEdit: false,
            forModified: true,
            style: {},
            action: "create",
        },
        {
            label: "Preview Interview",
            variant: "text",
            iconInfo: {
                placement: "start",
                icon: <Visibility />,
            },

            isDisabled: submitted,
            type: "button",
            func: onPreview,
            forEdit: true,
            forModified: false,
            style: {},
            action: "onPreview",
        },
        {
            label: "Delete Interview",
            variant: "text",
            iconInfo: {
                placement: "end",
                icon: <DeleteIcon />,
            },
            func: confirmModalHandler,
            isDisabled: submitted,
            type: "button",
            forEdit: true,
            forModified: false,
            style: { color: "red" },
            action: "deleteInterview",
        },
        {
            label: "Copy Interview",
            variant: "contained",
            iconInfo: {
                placement: "end",
                icon: <ContentCopyIcon />,
            },
            func: copyInterviewHandler,
            isDisabled: submitted,
            type: "button",
            forEdit: true,
            forModified: false,
            style: {},
            action: "copyInterview",
        },
        {
            label: "Save",
            variant: "contained",
            iconInfo: {
                placement: "end",
                icon: <SaveIcon />,
            },
            isDisabled: submitted,
            type: "submit",
            forEdit: true,
            forModified: true,
            style: {},
            action: "save",
        },
    ];
    const formBtnHandler = useCallback(() => {
        if (!isEdit || copyInterviewId) {
            return [formActionBtnInfo[2], formActionBtnInfo[0], formActionBtnInfo[1]];
        }
        if (hasChanges) {
            return [formActionBtnInfo[0], formActionBtnInfo[5]];
        }
        return [formActionBtnInfo[2], formActionBtnInfo[3], formActionBtnInfo[4]];
    }, [submitted, hasChanges, cancelChangesHandler, copyInterviewId]);

    return formBtnHandler().map((btnInfo, index) => {
        const { variant, iconInfo, style, func, label, isDisabled, action } = btnInfo;

        return (
            <div key={`form-button-${label}`}>
                <StyledFormBtn
                    type={btnInfo.type}
                    variant={variant}
                    endIcon={isEqual(iconInfo.placement, "end") && iconInfo.icon}
                    startIcon={isEqual(iconInfo.placement, "start") && iconInfo.icon}
                    disabled={isDisabled}
                    style={style}
                    onClick={() => !isEqual(label, "Create") && func?.(action)}
                >
                    {label}
                </StyledFormBtn>
                {confirmModal?.isOpen && (
                    <ConfirmDialog
                        title={confirmModal.title}
                        description={confirmModal.description}
                        positiveBtn={confirmModal.positiveBtn}
                        negativeBtn={confirmModal.negativeBtn}
                        isOpen={confirmModal.isOpen}
                        closeFn={closeModalHandler}
                    />
                )}
            </div>
        );
    });
};

export default InterviewFormActions;

const StyledFormBtn = styled(Button)(({ theme }) => ({
    padding: "0.5rem 1rem",
}));
