import { useState } from "react";
import { Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CompanyStatusButton from "components/CompanyStatusButton";
import GroupsIcon from "@mui/icons-material/Groups";
import { useQuery } from "react-query";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import { getApplicantCompanyStatus } from "utils/helpers/applicant";

const ApplicantCompaniesActions = ({
  row,
  currentApplicant,
  setOpenInterviewModal,
  setCurrentCustomer,
  parent,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Tooltip
          title={`Add ${currentApplicant?.firstName} ${currentApplicant?.lastName} to Interview`}
        >
          <IconButton
            onClick={() => {
              setOpenInterviewModal(true);
              setCurrentCustomer(row);
            }}
          >
            {getApplicantCompanyStatus(currentApplicant, row.slug) === "Pending" && (
              <GroupsIcon fontSize="large" color="success" />
            )}
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item sm={6}>
        <CompanyStatusButton
          status={getApplicantCompanyStatus(currentApplicant, row.slug)}
          company={row}
          parent={parent}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicantCompaniesActions;
