// Creates an optionally unique slug for jobs, events, etc.
const generateEmail = async ({
    slug,
    venueSlug = "",
}) => {


    const slugToTry = `${slug
        .trimStart()
        .toLowerCase()
        .replaceAll("  ", " ")
        .replaceAll(" ", "-")}@${venueSlug ? `${venueSlug.toLowerCase()}` : ""}.gignology.biz`;

    const mail = slugToTry
        .replace(/&/g, "-and-")
        .replace(/-/g, " ")
        .replace(/[^a-zA-Z0-9@. ]/g, "")
        .replace(/--/g, "-")
        .replace(/ {2}/g, " ")
        .replace(/ /g, "-")
        .replace(/--/g, "-")
        ;

    return mail
};

export default generateEmail;
