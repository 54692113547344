import { Box, Button, Icon, Typography } from "@mui/material";
import React from "react";

const ActiveNumbersSquare = ({ data }) => {
  const { color, icon, title, tooltip, number } = data;

  return (
    <Box
      style={{
        width: 250,
        height: 250,
        backgroundColor: color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2, // Adjust the padding as needed
      }}
    >
      <Typography
        variant="h5"
        style={{ textAlign: "center", color: "white", display: "flex", fontWeight: "normal" }}
      >
        {icon} &nbsp; {title}
      </Typography>
      <Typography variant="h1" style={{ textAlign: "center", color: "white", fontSize: "5rem" }}>
        {number}
      </Typography>
      <Button
        variant="text"
        style={{ color: "white" }}
        onClick={() => console.log("More info clicked")}
      >
        View More info
      </Button>
    </Box>
  );
};

export default ActiveNumbersSquare;
