import { Box, Card, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import moment from "moment";

const CustomCard = ({
  children,
  cardSx,
  cardTitle = "",
  titleSx,
  cardActions,
  actionsSx,
  icon,
  iconContainerSx,
  currentData,
  isStacked = false,
}) => {
  const { currentEvent: event } = useAppContextController();
  const updateTime = currentData?.modifiedDate || event?.modifiedDate;
  const isEdit = !!event?._id || !!currentData?._id;

  return (
    <Card
      sx={{
        px: "1.5rem",
        pt: "1rem",
        pb: "2rem",
        ...cardSx,
      }}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        mb="10px"
        flexDirection="column"
        bgColor="white"
      >
        <FlexWrapper
          justifyContent="initial"
          alignItems={isEdit ? "initial" : "center"}
          columnGap="1rem"
        >
          {icon && (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              borderRadius="xl"
              bgColor={iconContainerSx?.backgroundColor}
              color={iconContainerSx?.color}
              mt={-5}
              sx={{
                backgroundColor: "#ec293b",
                "& svg": {
                  width: "40px",
                  height: "40px",
                },
                overflow: "hidden",
                ...iconContainerSx,
              }}
            >
              {icon}
            </MDBox>
          )}

          {isStacked ? (
            <Stack>
              <MDTypography variant="h5" color="dark" sx={titleSx} alignSelf="center">
                {cardTitle}
              </MDTypography>
              {isEdit && (
                <MDTypography variant="body2">
                  Last Update: {moment(updateTime).format("ddd MM/DD/YYYY hh:mm a")}
                </MDTypography>
              )}
            </Stack>
          ) : (
            <>
              <Box>

                <MDTypography variant="h5" color="dark" sx={titleSx}>
                  {cardTitle}
                </MDTypography>
                {isEdit && (
                  <MDTypography variant="body2">
                    Last Update: {moment(updateTime).format("ddd MM/DD/YYYY hh:mm a")}
                  </MDTypography>
                )}
              </Box>
            </>
          )}
        </FlexWrapper>
        <MDBox>
          {cardActions && (
            <FlexWrapper justifyContent="end" sx={actionsSx}>
              {cardActions}
            </FlexWrapper>
          )}
        </MDBox>
      </MDBox>
      {children}
    </Card>
  );
};

export default CustomCard;
