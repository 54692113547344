import { Card, Grid, Modal, Typography } from "@material-ui/core";
import { Close } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Fade, IconButton, Stack, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import SPLogo from "assets/images/SP-App-Store-Icon.png";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SendEmailModal from "components/SendEmailModal";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import UpcomingEvents from "layouts/pages/venues/components/UpcomingEvents";
import VenueMapsAndPolicies from "layouts/pages/venues/components/VenueMapsAndPolicies";
import VenuePhotoGallery from "layouts/pages/venues/components/VenuePhotoGallery";
import VenueTrainingVideos from "layouts/pages/venues/components/VenueTrainingVideos";
import { searchGoogleMaps } from "utils/helpers/googleMaps";
import { getFullName, getNameInitials } from "utils/string";

import styles from "./styles";
import fetchSingleVenue from "../../actions/fetchSingleVenue";

const { black } = colors;
const textColor = "#7c97a9";

const VenuePreviewPanel = ({ open, setOpen, venueId, refreshVenues }) => {
  const { venues, setVenues, currentLoggedUser, company, setCompany, setCompanyType, userType } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const [venue, setVenue] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState({ data: [], count: 0 });

  useEffect(() => {
    if (!venues || !Object.keys(venues)?.length) {
      refetchVenues();
    }
  }, [venues]);

  const options = { fetchAll: true };
  const allOptions = {
    ...options,
    filters: {
      venueSlug: venue?.slug,
    },
    // the following is to fetch the applicants for Employee users
    applicantId:
      userType === "User" && currentLoggedUser?.applicantId ? currentLoggedUser?.applicantId : null,
    venues,
  };
  const { data: events, isLoading } = useQuery(["events", allOptions], () =>
    fetchEvents(allOptions)
  );

  useEffect(() => {
    const appIsSignedUp = (thisApp, applicants) => {
      if (!applicants || !applicants?.length) return false;
      let signedUp = false;
      for (let i = 0; i < applicants.length; i += 1) {
        const item = applicants[i];
        if (item.id === thisApp && item.status === "Roster") {
          signedUp = true;
          break;
        }
      }
      return signedUp;
    };

    if (!isLoading && userType === "User") {
      const tempEvents = events?.data.filter((item) => {
        return (
          item.makePublicAndSendNotification !== "No" ||
          appIsSignedUp(currentLoggedUser?.applicantId, item?.applicants)
        );
      });
      setFilteredEvents({
        data: tempEvents?.length ? [...tempEvents] : [],
        count: tempEvents?.length || 0,
      });
    } else if (!isLoading && events?.data?.length) setFilteredEvents(events);
  }, [events, isLoading]);

  const imageUrlBase = company?.imageUrl;

  const handleClose = () => setOpen(false);

  // const venueBanner = venue?.bannerUrl || (venue?.imageUrls?.length ? venue?.imageUrls[0] : null);

  const [showMore, setShowMore] = useState(false);
  const [isEmailModalOpen, toggleEmailModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [recipient, setRecipient] = useState({
    _id: "",
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  const url = () => {
    if (showMore || venue?.venueUrl.length < 20) {
      return venue?.venueUrl;
    }
    return `${venue?.venueUrl?.substring(0, 20)}...`;
  };

  const banner = useMemo(() => {
    if (venue?.bannerUrl) {
      return `${imageUrlBase}/${venue?.slug}/venues/banner/${venue?.bannerUrl}`;
    }
    return venue?.imageUrls?.length ? venue?.imageUrls[0] : null;
  }, [venue]);

  const logo = useMemo(() => {
    return `${imageUrlBase}/${venue?.slug}/venues/logo/${venue?.logoUrl}`;
  }, [venue]);

  const images = useMemo(() => {
    const array = [];

    venue?.imageUrls?.forEach((element) => {
      array.push(`${imageUrlBase}/${venue?.slug}/venues/banner/${element}`);
    });
    return array;
  }, [venue]);

  useEffect(() => {
    const getVenue = async (ven) => {
      const tempVenue = await fetchSingleVenue({ venueId: ven });
      if (tempVenue) setVenue(tempVenue);
    };
    if (venueId) getVenue(venueId);
  }, [venueId, refreshVenues]);

  const getSubjectFromSender = () => {
    const subj = `Question for Venue: ${venue?.name} from ${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName} <${currentLoggedUser?.emailAddress}>`;
    return subj;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card>
            <Box sx={(theme) => styles.modalContainer(theme)}>
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: "5px", right: "5px", color: "inherit" }}
                size="large"
              >
                <Close />
              </IconButton>
              <Box
                sx={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box style={styles.longBgImageContainer}>
                  <img style={styles.longBgImage} src={banner} alt="Event Staff" />
                </Box>
                <Box sx={styles.mainContentContainer}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      <Box style={styles.leftContent}>
                        <Box justifyContent="space-between">
                          <Grid
                            container
                            spacing={3}
                            alignItems="center"
                            justifyContent="center"
                          // md="initial"
                          >
                            <Grid item sm={2}>
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="4rem"
                                height="4rem"
                                variant="gradient"
                                bgColor="white"
                                color="white"
                                borderRadius="xl"
                                mt={-2}
                              >
                                <img alt="logo" width="100%" src={logo} />
                              </MDBox>
                            </Grid>
                            <Grid item sm={6}>
                              <Box>
                                <h3 style={{ lineHeight: 1, marginBottom: 5 }}>{venue?.name}</h3>
                                <Box style={{ color: textColor }}>
                                  <Typography>{venue?.address}</Typography>
                                  <Typography>
                                    {venue?.city}, {venue?.state} {venue?.zip}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item sm={4} sx={{ textAlign: "right" }}>
                              <Box
                                width="100%"
                                style={{
                                  borderBottom: `1px solid hsl(0deg 0 % 80 %)`,
                                  marginBottom: 4,
                                }}
                              >
                                <Grid
                                  container
                                  spacing={3}
                                  alignItems="center"
                                  justifyContent="space-around"
                                >
                                  <Grid item>
                                    <Box
                                      onClick={() =>
                                        searchGoogleMaps({
                                          address: venue?.address,
                                          city: venue?.city,
                                          state: venue?.state,
                                          zip: venue?.zip,
                                        })
                                      }
                                    >
                                      <img
                                        alt="logo"
                                        width="30px"
                                        src="/images/png/logos/google.png"
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box>
                                      <img alt="logo" width="30px" src={logo} />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box style={{ textAlign: "center" }}>
                                {venue?.venueUrl ? (
                                  <Tooltip title={venue?.venueUrl}>
                                    <a href={venue?.venueUrl} target="_blank" rel="noreferrer">
                                      <Typography
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                      >
                                        {venue?.venueUrl}
                                      </Typography>
                                    </a>
                                  </Tooltip>
                                ) : (
                                  " "
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={styles.descriptionContainer}>
                          <Box style={{ color: textColor }}
                            id="modal-modal-description"
                            sx={{ mt: 2 }} >
                            {venue?.description ? parse(venue?.description) : ""}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box style={styles.rightContent}>
                        <MDBox display="flex">
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="4rem"
                            height="4rem"
                            variant="gradient"
                            bgColor="white"
                            color="white"
                            borderRadius="xl"
                            mt={-2}
                          >
                            <img src={SPLogo} width="50" height="50" alt="SPLogo" />
                          </MDBox>
                          <Box mb={1} px={2}>
                            <h4>Working With Stadium People At Your Favorite Venues</h4>
                          </Box>
                        </MDBox>
                        <Box>
                          <Box align="left" sx={{ typography: 'body2' }}>
                            {venue?.stadiumPeopleInfo ? parse(venue?.stadiumPeopleInfo) : ""}
                          </Box>
                        </Box>
                        {venue?.venueContact1 && (
                          <Stack
                            pt={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs={12} sm={2}>
                              <Avatar sx={{ bgcolor: black.light }}>
                                {getNameInitials(getFullName(venue?.venueContact1))}
                              </Avatar>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <MDTypography variant="body2">
                                {getFullName(venue?.venueContact1)}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Box alignSelf="center">
                                {typeof venueContact1 !== "string" && (
                                  <IconButton
                                    color="warning"
                                    onClick={() => {
                                      setRecipient({
                                        _id: venue?.venueContact1?.applicantId,
                                        email: venue?.venueContact1?.email,
                                        firstName: venue?.venueContact1?.firstName,
                                        lastName: venue?.venueContact1?.lastName,
                                        phone: venue?.venueContact1?.phone,
                                      });
                                      setSubject(getSubjectFromSender());
                                      toggleEmailModal(true);
                                    }}
                                  >
                                    <EmailIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Grid>
                          </Stack>
                        )}
                        {venue?.venueContact2 && (
                          <Stack
                            pt={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs={12} sm={2}>
                              <Avatar sx={{ bgcolor: black.light }}>
                                {getNameInitials(getFullName(venue?.venueContact2))}
                              </Avatar>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <MDTypography variant="body2">
                                {getFullName(venue?.venueContact2)}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Box alignSelf="center">
                                {typeof venueContact2 !== "string" && (
                                  <IconButton
                                    color="warning"
                                    onClick={() => {
                                      setRecipient({
                                        _id: venue?.venueContact2?.applicantId,
                                        email: venue?.venueContact2?.email,
                                        firstName: venue?.venueContact2?.firstName,
                                        lastName: venue?.venueContact2?.lastName,
                                        phone: venue?.venueContact2?.phone,
                                      });
                                      setSubject(getSubjectFromSender());
                                      toggleEmailModal(true);
                                    }}
                                  >
                                    <EmailIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Grid>
                          </Stack>
                        )}

                        {venue?.payrollDeptContact && (
                          <Stack
                            pt={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs={12} sm={2}>
                              <Avatar sx={{ bgcolor: black.light }}>
                                {getNameInitials(getFullName(venue?.payrollDeptContact))}
                              </Avatar>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <MDTypography variant="body2">
                                {getFullName(venue?.payrollDeptContact)}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Box alignSelf="center">
                                {typeof payrollDeptContact !== "string" && (
                                  <IconButton
                                    color="warning"
                                    onClick={() => {
                                      setRecipient({
                                        _id: venue?.payrollDeptContact?.applicantId,
                                        email: venue?.payrollDeptContact?.email,
                                        firstName: venue?.payrollDeptContact?.firstName,
                                        lastName: venue?.payrollDeptContact?.lastName,
                                        phone: venue?.payrollDeptContact?.phone,
                                      });
                                      setSubject(getSubjectFromSender());
                                      toggleEmailModal(true);
                                    }}
                                  >
                                    <EmailIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Grid>
                          </Stack>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox style={styles.middleContent}>
                        <UpcomingEvents events={filteredEvents} isLoading={isLoading} />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <MDBox style={styles.bottomContent}>
                            <VenuePhotoGallery imageUrls={images} />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <MDBox style={styles.bottomContent}>
                            <VenueMapsAndPolicies
                              address={venue?.address}
                              city={venue?.city}
                              state={venue?.state}
                              zip={venue?.zip}
                              slug={venue?.slug}
                              otherUrls={venue?.otherUrls}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <MDBox style={styles.bottomContent}>
                            <VenueTrainingVideos videoUrls={venue?.videoUrls} />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Card>
        </Fade>
      </Modal>
      <SendEmailModal
        recipient={recipient}
        isOpen={isEmailModalOpen}
        toggleModal={toggleEmailModal}
        sendMessageProps={{
          hideMessageSelection: true,
          hideMessageType: true,
        }}
        venueId={venueId}
        subject={subject}
      />
    </>
  );
};

// Setting default values for the props of VenuePreviewPanel
VenuePreviewPanel.defaultProps = {
  open: false,
  setOpen: () => { },
};

// Typechecking props of the VenuePreviewPanel
VenuePreviewPanel.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  // currentJob: PropTypes.shape({
  //   title: PropTypes.string,
  //   description: PropTypes.string,
  //   venueState: PropTypes.string,
  //   venueCity: PropTypes.string,
  //   googleMap: PropTypes.string,
  //   venueName: PropTypes.string,
  //   venueHelmID: PropTypes.string,
  //   venueLogoUrl: PropTypes.string,
  // }),
};

export default VenuePreviewPanel;
