export const getCompanyBaseImageUrl = (company, defaultCompany) => {
  if (company?.imageUrl && company?.slug) {
    return `${company?.imageUrl}/company/${company?.slug}`;
  }
  if (defaultCompany?.imageUrl && defaultCompany?.slug) {
    return `${defaultCompany?.imageUrl}/company/${defaultCompany?.slug}`;
  }
  return null;
};

export const getCommonBaseImageUrl = (company, defaultCompany) => {
  if (company?.imageUrl && company?.uploadUrl) {
    return company.imageUrl.replace(`/${company.uploadUrl}`, "/common");
  }
  if (defaultCompany?.imageUrl && defaultCompany?.uploadUrl) {
    return defaultCompany.imageUrl.replace(`/${defaultCompany.uploadUrl}`, "/common");
  }
  return null;
};
