import { Box, Grid, styled } from "@mui/material";
import { useState } from "react";

import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import HeaderItem from "./HeaderItem";

const HeaderText = styled(MDTypography)(({ theme }) => ({
  fontWeight: "500",
  letterSpacing: "0.05em",
  [theme.breakpoints.down("sDesktop")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sTablet")]: {
    textAlign: "center",
  },
}));

const LogoTitle = ({ company }) => {
  const HEADER_ITEM_LIST = [
    { id: 1, text: "Find a job at a venue below & apply online" },
    { id: 2, text: "Talk to a recruiter & attend a group interview" },
    { id: 3, text: "Start searching events & sign up for gigs online" },
  ];

  return (
    <Box sx={{ alignSelf: "flex-start", display: "flex", flexDirection: "column" }}>
      <FlexWrapper
        justifyContent="flex-start"
        flexWrap="nowrap"
        position="relative"
        gap="15px"
        flexDirection={{ xs: "column", sTablet: "row" }}
        maxWidth={{ sDesktop: "1000px" }}
      >
        {/* <MDBox
          component="img"
          src={StadiumPeopleLogo}
          alt="Brand"
          width="64px"
          sx={(theme) => ({
            width: "64px",
            [theme.breakpoints.down("sTablet")]: {
              width: "128px",
            },
          })}
        /> */}
        <CompanyLogoSquare company={company} bigLogo />
        <HeaderText component="span" variant="h2" color="white">
          Scoring your next gig is as easy as 1-2-3!
        </HeaderText>
      </FlexWrapper>
      <Grid
        container
        sx={{ my: "2.5rem" }}
        direction={{ sDesktop: "row", xs: "column" }}
        alignItems={{ xs: "flex-start", sDesktop: "center" }}
        gap={{ xs: "30px", sDesktop: "0px" }}
      >
        {HEADER_ITEM_LIST.map((item, index) => (
          <Grid item sm={4} key={`${index.toString()}_logo_title_item_${item.id}`}>
            <HeaderItem index={index + 1} text={item.text} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LogoTitle;
