import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";

const SnackbarActionsHelmSync = ({ snackbarId, handleSyncNow, timeOutSec, autoSync = true }) => {
  const { closeSnackbar } = useSnackbar();

  const [timer, setTimer] = useState(timeOutSec);
  const timerRef = useRef(timer);
  timerRef.current = timer;

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerRef.current < 1) {
        if (autoSync) handleSyncNow();
        closeSnackbar(snackbarId);
        return;
      }
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [snackbarId]);

  const syncNow = () => {
    handleSyncNow();
    closeSnackbar(snackbarId);
  };

  return (
    <>
      <Typography fontSize="small" fontWeight="bold" sx={{ ml: -1.5, mr: 2 }}>
        {timer} sec
      </Typography>
      <Typography
        fontSize="small"
        fontWeight="bold"
        color="success.main"
        sx={{ mx: 1, "&:hover": { cursor: "pointer" } }}
        onClick={() => {
          syncNow();
        }}
      >
        Sync Now
      </Typography>
      <Typography
        fontSize="small"
        fontWeight="bold"
        color="error"
        sx={{ mx: 1, "&:hover": { cursor: "pointer" } }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        Cancel Sync
      </Typography>
    </>
  );
};

export default SnackbarActionsHelmSync;
