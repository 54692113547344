import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import MDBox from "components/MDBox";
import axios from "axios";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { getCompanyBaseImageUrl } from "utils/helpers/upload";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import storeToken from "api/auth/storeToken";
import ErrorModal from "../ErrorModal";

const HomeHeader = ({ company }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    logout,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const navigate = useNavigate();
  const [loginButtonPressed, setLoginButtonPressed] = useState(false);
  const [description, setDescription] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();

  function triggerVerificationEmail(userId) {
    const apiUrl = `https://dev-m3kmliv9.us.auth0.com/api/v2/jobs/verification-email`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const data = {
      user_id: userId,
    };

    return getAccessTokenSilently({ audience: `https://your-auth0-domain/api/v2/` })
      .then((accessToken) => {
        config.headers.Authorization = `Bearer ${accessToken}`;
        alert(accessToken);
        return axios
          .post(apiUrl, data, config)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            console.error(err);
            throw err;
          });
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("error")) {
      const errDesc = params.get("error_description");
      if (errDesc.includes("verify")) {
        const nodes = errDesc.split(":");
        const usrId = nodes[1].trim();
        triggerVerificationEmail(usrId);
        setDescription(
          `${nodes[0]}\n\nCheck your email for a verification email from ${company?.companyEmail}`
        );
      } else setDescription(errDesc);

      setModalOpen(true);
    }
  }, [location.search]);

  const handleLogin = async () => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/dashboards/analytics");
      } else {
        logout();
        loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        }).catch((err) => {
          alert("error state");
          navigate("/");
        });
      }
    }
  };

  const image = getCompanyBaseImageUrl(company)
    ? `${getCompanyBaseImageUrl(company)}/logo/${company?.logoUrl}`
    : "";

  function getEmailAddress(inputString) {
    const verifyEmailPhrase = "needs to verify email";
    if (!inputString.includes(verifyEmailPhrase)) {
      return null;
    }
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const emailMatch = inputString.match(emailRegex);
    if (!emailMatch) {
      return null;
    }
    return emailMatch[0];
  }

  const handleCloseModal = async () => {
    setModalOpen(false);
    logout();
  };

  const renderErrorModal = () => (
    <div>
      <ErrorModal description={description} open={modalOpen} onClose={handleCloseModal} />
    </div>
  );

  return (
    <>
      <MDBox
        sx={{ py: "15px", px: "20px" }}
        bgColor="#000"
        display="flex"
        justifyContent="space-between"
      >
        {image ? <MDBox component="img" src={image} alt="Brand" width="5%" /> : <div />}
        <MDBox sx={{ py: "15px", px: "20px" }} display="flex" alignItems="center">
          {company && company?.companyType !== "Company" ? (
            <MDBox mr={3}>
              <Button
                variant="contained"
                color="error"
                pr={3}
                sx={{ display: "inline", height: "100%", top: "50%", alignSelf: "center" }}
                onClick={() => {
                  navigate("/applicants");
                }}
              >
                Update your info
              </Button>
            </MDBox>
          ) : null}
          <MDBox>
            <Button
              variant="contained"
              color="error"
              pr={3}
              sx={{ display: "inline", height: "100%", top: "50%", alignSelf: "center" }}
              onClick={handleLogin}
            >
              Sign In
            </Button>
          </MDBox>
        </MDBox>
      </MDBox>
      {modalOpen && renderErrorModal()}
    </>
  );
};

export default HomeHeader;
