import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import moment from "moment";
import { Grid, TableHead, Tooltip } from "@mui/material";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useSort from "utils/useSort";
import FilterButtons from "components/FilterButtons";
import { APPLICANT_COMPANIES_OPTIONS } from "components/FilterButtons/options";
import { getApplicantCompanyOpenEndDate, getApplicantCompanyStatus } from "utils/helpers/applicant";
import { isAdmin } from "utils/helpers/roles";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { useQuery } from "react-query";
import fetchCustomers from "api/companies/fetchCustomers";
import ApplicantCompaniesActions from "./ApplicantCompaniesActions";
import InterviewModal from "../InterviewModal";

const ApplicantCompanies = ({ fetchAll = true, filters, page, limit, parent = "Applicant" }) => {
  const { currentApplicant, userType, company } = useAppContextController();
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [dataFilter, setDataFilter] = useState({ status: "all" });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const [openInterviewModal, setOpenInterviewModal] = useState(false);
  // const includedCompaniesArr = currentApplicant?.companies
  //   ?.filter((item) => item.status !== "Active" && item.companySlug)
  //   .map((item) => item.companySlug);
  // const includedCompanies = includedCompaniesArr?.length ? includedCompaniesArr.join(";") : "";
  const allOptions = {
    ...options,
    filters: {
      ...filters,
      status: "Active",
    },
    useOr: true,
  };

  // if (includedCompaniesArr?.length) allOptions.filters.slug = includedCompanies;
  const [toastAlert, setToastAlert] = useState(false);
  const { data: companies, isLoading } = useQuery(
    ["customers", allOptions],
    () => fetchCustomers(allOptions),
    { refetchInterval: 300000 }
  );

  const allCompanies = Object.values(companies?.data || []);

  const getModifiedDateFromApplCompanies = (applCompanies, companySlug) => {
    if (!applCompanies || !applCompanies.length) return null;
    const ndx = applCompanies.findIndex((item) => item.companySlug === companySlug);
    if (ndx > -1) return new Date(applCompanies[ndx]?.dateModified);
    return null;
  };

  const renderRow = (row) => {
    const openEndDate = getApplicantCompanyOpenEndDate(currentApplicant, row.slug);
    return (
      <>
        <MDBox key={`${row.name}_${row.slug}`} display="flex" justifyContent="space-between">
          <Grid item xs={12} sm={2} lg={1.5}>
            <CompanyLogoSquare company={row} defaultCompany={company} />
          </Grid>
          <Tooltip
            title={`Date Modified: ${moment(
              getModifiedDateFromApplCompanies(currentApplicant?.companies, row.slug)
            ).format("ddd, MMM DD, YYYY hh:mm a")}`}
          >
            <Grid item xs={12} sm={6} lg={8.5}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    {row.name}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDTypography variant="body2">
                    {row.city}, {row.state}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {openEndDate?.openDate && (
                    <MDTypography variant="body2">
                      {`Open Date:  ${moment(openEndDate?.openDate).format("MM/DD/YYYY")}`}
                    </MDTypography>
                  )}
                  {openEndDate?.closeDate && (
                    <MDTypography variant="body2">
                      {`  Close Date:  ${moment(openEndDate?.closeDate).format("MM/DD/YYYY")}`}
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Tooltip>
          <Grid item xs={12} sm={4} lg={2}>
            <ApplicantCompaniesActions
              row={row}
              currentApplicant={currentApplicant}
              setOpenInterviewModal={setOpenInterviewModal}
              setCurrentCustomer={setCurrentCustomer}
              parent={parent}
            />
          </Grid>
        </MDBox>
      </>
    );
  };

  const header = <></>;
  const topButtonBar = (
    <TableHead
      sx={{
        height: "5rem",
        zIndex: 3,
        position: "sticky",
        top: 0,
        background: "white",
        width: "100%",
      }}
      display="flex"
      width="100%"
    >
      {isAdmin(userType) && (
        <FilterButtons
          filter={dataFilter}
          setFilter={setDataFilter}
          name="status"
          options={APPLICANT_COMPANIES_OPTIONS}
        />
      )}
    </TableHead>
  );

  const filteredCompanies = useMemo(() => {
    if (dataFilter.status !== "all") {
      return allCompanies.filter(
        (item) => getApplicantCompanyStatus(currentApplicant, item.slug) === dataFilter.status
      );
    }
    return allCompanies;
  }, [dataFilter, allCompanies, currentApplicant]);

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={filteredCompanies?.sort((a, b) => (a.name > b.name ? 1 : -1))}
        icon="business"
        iconColor="info"
        title="Customers"
        isLoading={false}
        greybar
        divider
        header={header}
        topButtonBar={topButtonBar}
        tableHeight={50}
        height={850}
        scrollY
        computedHeight
      />
      <InterviewModal
        setModalOpen={setOpenInterviewModal}
        isModalOpen={openInterviewModal}
        currentCustomer={currentCustomer}
        currentApplicant={currentApplicant}
      />
    </>
  );
};

export default ApplicantCompanies;
