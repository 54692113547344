/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useEffect, useMemo, useState } from "react";

// store/api/helper
import { useAppContextController } from "context/AppContext";

// style
import { Box, Button, Grid, Stack } from "@mui/material";
import { AutoAwesome, ContactPage, Edit } from "@mui/icons-material";

// components
import DataListContainer from "components/DataListContainer";
import MDTypography from "components/MDTypography";
import CircularLoader from "components/CircularLoader";
import MDBox from "components/MDBox";

import AIQuestionsForm from "./AIQuestionsForm";
import AIQuestionsItem from "./AIQuestionsItem";

const AIQuestionsPanel = ({
  questions,
  questionKey = "aiSettings.questions",
  addQuestion,
  removeQuestion,
  questionIndex,
  setQuestionIndex,
  control,
  watch,
  errors,
  setValue,
  isReadOnly,
  title,
  onAdd,
  noHeader,
  noTopPadding,
  onPressGenerate,
  isLoadingGenerateQuestion,
}) => {
  const { companyType } = useAppContextController();

  const handleAddQuestion = () => {
    addQuestion({
      text: "",
      locked: false,
    });
    setQuestionIndex(questions.length);
  };

  const handleQuestion = (idx, row) => {
    setQuestionIndex(idx);
  };

  const handleEmptyQuestionReset = () => {
    setQuestionIndex(null);
  };

  const handleRemoveQuestion = (idx) => {
    removeQuestion(idx);
    if (idx === questionIndex) {
      setQuestionIndex(null);
    } else if (questionIndex != null && idx < questionIndex) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const handleToggleLocked = (idx) => {
    const currentValue = !!watch(`${questionKey}.${idx}.locked`);
    setValue(`${questionKey}.${idx}.locked`, !currentValue);
  };

  const renderQuestions = (row, idx) => (
    <AIQuestionsItem
      row={row}
      idx={idx}
      onEdit={handleQuestion}
      onRemove={handleRemoveQuestion}
      onToggleLocked={handleToggleLocked}
      readOnly={isReadOnly}
    />
  );

  const header = useMemo(() => {
    if (noHeader || isReadOnly) return undefined;
    return (
      <Button disableRipple disabled={isLoadingGenerateQuestion} onClick={onPressGenerate}>
        {isLoadingGenerateQuestion && (
          <CircularLoader size="2rem" color="info" hasBackdrop={false} isLoading />
        )}
        <Grid container columnGap={1} alignItems="center">
          <AutoAwesome sx={{ color: "#8935f4" }} />
          <MDTypography
            component="label"
            variant="button"
            fontWeight="medium"
            color="violet"
            sx={{ fontSize: "0.8rem", color: "#8935f4", cursor: "inherit" }}
          >
            Generate Questions
          </MDTypography>
        </Grid>
      </Button>
    );
  }, [noHeader, isReadOnly, isLoadingGenerateQuestion]);

  const addIcon = useMemo(() => {
    if (noHeader) return undefined;
    if (isReadOnly) return <Edit />;
    return undefined;
  }, [noHeader, isReadOnly]);

  return (
    <>
      <Stack spacing={1} sx={{ p: 0 }}>
        <Box pt={noHeader || noTopPadding ? 0 : 2}>
          <DataListContainer
            onAdd={onAdd ?? handleAddQuestion}
            data={questions}
            renderRow={renderQuestions}
            title={noHeader ? null : title}
            tableHeight={265}
            height={267}
            selected={questionIndex}
            header={header}
            AddIcon={addIcon}
            noCreate={noHeader}
          />
        </Box>
        <MDTypography color="error">{(Object.values(errors ?? {})[0] ?? {}).message}</MDTypography>
        {questionIndex != null && (
          <Box>
            <AIQuestionsForm
              control={control}
              watch={watch}
              index={questionIndex}
              questionKey={questionKey}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default AIQuestionsPanel;
