import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import setApplicantsBulkStatus from "layouts/pages/events/actions/setApplicantsBulkStatus";

import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import useDropdowns from "hooks/useDropdowns";
import {
  ConnectWithoutContact,
  FolderShared,
  Handshake,
  PersonAdd,
  QuestionAnswer,
  ThumbDown,
} from "@mui/icons-material";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import useMinStageToOnboarding from "hooks/useMinStageToOnboarding";
import { ApplicantsTable } from "./ApplicantsTable";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const APPLICANT_STAGE_CHANGE_OPTIONS = [
  {
    label: "New",
    icon: <PersonAdd color="violet" fontSize="medium" />,
  },
  {
    label: "ATC",
    icon: <ConnectWithoutContact color="error" fontSize="medium" />,
  },
  {
    label: "Screened",
    icon: <QuestionAnswer color="info" fontSize="medium" />,
  },
  {
    label: "Pre-Hire",
    icon: <FolderShared color="success" fontSize="medium" />,
  },
  {
    label: "Declined",
    icon: <ThumbDown color="error" fontSize="medium" />,
  },
];

export const BulkApplicantStageChangeModal = ({
  isOpen,
  toggleModal,
  applicants = [],
  isLoading,
  status,
  jobSlug,
  onChange,
}) => {
  const { dropdowns: notesTypesBulkChange } = useDropdowns({ entity: "notesTypesBulkChange" });
  const [selectedOption, setSelectedOption] = useState(null);
  const [noteType, setNoteType] = useState(null);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [confirmModalApplicant, setConfirmModalApplicant] = useState(initialValueModal);
  const { minStage, allowedStages, disallowedStages } = useMinStageToOnboarding();

  const closeModalHandler = () => setConfirmModalApplicant(initialValueModal);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!status) return;
    const defaultOption = APPLICANT_STAGE_CHANGE_OPTIONS.find((option) => option.label === status);
    setSelectedOption(defaultOption); // Set the default option as the selected option
  }, [status]);

  const handleClose = () => {
    toggleModal();
  };

  const canSendOnboardingMessage =
    // Only send onboarding message when changing status to Screened (by default)
    selectedOption?.label === minStage;

  const { mutateAsync: createApplicantsBulkStatusMutation, isLoading: isChangingStatus } =
    useMutation(setApplicantsBulkStatus);

  const createApplicantsBulkStatusHandler = async (sendOnboardingMessage) => {
    await createApplicantsBulkStatusMutation(
      {
        status: selectedOption?.label,
        applicantIdList: selectedRecipients,
        noteType,
        jobSlug,
        sendOnboardingMessage,
      },
      {
        onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
        onSuccess: async (_, data) => {
          handleClose();
          enqueueSnackbar("Applicants stage changed successfully", { variant: "success" });
          if (onChange) onChange(data?.status, data?.applicantIdList);
          queryClient.invalidateQueries("jobsapplicants");
          queryClient.invalidateQueries("useBadgeValues");
        },
      }
    );
  };

  const onSubmitFunc = (...params) => {
    if (!canSendOnboardingMessage) {
      return createApplicantsBulkStatusHandler();
    }

    return setConfirmModalApplicant({
      isOpen: true,
      title: "Applicant stage change",
      bodyText: `The selected stage is ${minStage}. Do you want to send an onboarding invitation to the applicants with pending onboarding?`,
      response: false,
      description: `The selected stage is ${minStage}. Do you want to send an onboarding invitation to the applicants with pending onboarding?`,
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      negativeAltBtn: {
        label: "Don't Send",
        fn: async () => {
          createApplicantsBulkStatusHandler();
          closeModalHandler();
        },
        isShown: true,
      },
      positiveBtn: {
        label: "Send",
        fn: async () => {
          createApplicantsBulkStatusHandler(true);
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };

  return (
    <>
      <GenericModal
        borderRadius="10px"
        open={isOpen}
        setOpen={toggleModal}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        body={
          <Box>
            <Grid container alignItems="center" mb={4}>
              <Grid item sx={12} sm={4}>
                <MDTypography variant="h6" color="info">
                  Select a Note Type
                </MDTypography>
              </Grid>
              <Grid item sx={12} sm={8}>
                <Autocomplete
                  id="noteType"
                  options={notesTypesBulkChange || []}
                  autoHighlight
                  value={noteType} // Use selectedOption as the value
                  onChange={(event, value) => setNoteType(value)}
                  renderInput={(params) => {
                    return (
                      <TextField
                        id="noteType-text-field"
                        {...params}
                        placeholder="Select a Note Type"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <ApplicantsTable
              applicants={applicants}
              selectedRecipients={selectedRecipients}
              setSelectedRecipients={setSelectedRecipients}
              isLoading={isLoading}
              jobSlug={jobSlug}
            />
          </Box>
        }
        header={
          <Box display="flex" justifyContent="space-between">
            <MDBox sx={{ mt: 3, mb: 1, ml: 2 }}>
              <MDTypography variant="h5" color="dark">
                Applicant Stage Change
              </MDTypography>
            </MDBox>
            <MDBox sx={{ mt: 2, mb: 1, ml: 6, mr: 6 }}>
              <MDTypography variant="h5" color="dark">
                <Autocomplete
                  id="icon-select-demo"
                  sx={{ width: 270 }}
                  options={APPLICANT_STAGE_CHANGE_OPTIONS}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  autoHighlight
                  value={selectedOption} // Use selectedOption as the value
                  getOptionLabel={(option) => (option ? option.label : "")}
                  onChange={(event, newValue) => setSelectedOption(newValue)}
                  renderOption={(props, option) => (
                    <MDBox
                      component="li"
                      sx={{
                        pr: 2,
                        py: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={option.label}
                      {...props}
                    >
                      <MDBox sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                        {option.icon}
                      </MDBox>
                      {option.label}
                    </MDBox>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField
                        id="icon-text-field"
                        {...params}
                        placeholder="Select an option"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: <>{selectedOption && selectedOption.icon}</>,
                        }}
                      />
                    );
                  }}
                />
              </MDTypography>
            </MDBox>
            <MDBox sx={{ mt: 2, mb: 1, display: "flex", flexDirection: "row", gap: 2 }}>
              <Button
                color="white"
                variant="contained"
                startIcon={<CancelIcon />}
                fullWidth
                disabled={isChangingStatus}
                onClick={handleClose}
              >
                <MDTypography variant="body2" color="blue">
                  Cancel
                </MDTypography>
              </Button>
              <Button
                variant="contained"
                fullWidth
                endIcon={<SaveIcon />}
                disabled={!selectedRecipients.length || !selectedOption || isChangingStatus}
                onClick={onSubmitFunc}
              >
                <MDTypography
                  variant="body2"
                  color={
                    !selectedRecipients.length || !selectedOption || isChangingStatus
                      ? "black"
                      : "white"
                  }
                >
                  Save
                </MDTypography>
              </Button>
            </MDBox>
          </Box>
        }
      />
      <ConfirmModal
        title={confirmModalApplicant.title}
        description={confirmModalApplicant.description}
        positiveBtn={confirmModalApplicant.positiveBtn}
        negativeBtn={confirmModalApplicant.negativeBtn}
        negativeAltBtn={confirmModalApplicant.negativeAltBtn}
        isOpen={confirmModalApplicant.isOpen}
        closeFn={closeModalHandler}
      />
    </>
  );
};
