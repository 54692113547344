import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/applications/wizard/components/FormField";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import {
  Autocomplete,
  Icon,
  Button,
  Switch,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import SendMessage from "components/SendMessage";
import { useAppContextController } from "context/AppContext";
import { Controller } from "react-hook-form";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";
import usePartnerLeaderChecker from "hooks/usePartnerLeaderChecker";
import MessagingPanel from "components/MessagingPanel";

const useStyle = makeStyles({
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
    height: 850,
  },
  switchBase: {
    "&.Mui-checked": {
      "& .MuiSwitch-thumb": {
        border: "1px solid #1A73E8 !important",
      },
      "& + .MuiSwitch-track": {
        opacity: "1 !important",
        backgroundColor: "#1A73E8 !important",
        borderColor: "#1A73E8 !important",
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: "0.3 !important",
      backgroundColor: "grey",
      borderColor: "grey",
    },
  },
  thumb: {
    color: "white !important",
    border: "1px solid grey !important",
  },
});

const PartnerSendMessagePanel = ({
  profilePanel,
  loginVerified,
  control,
  emailVerified = "No",
  selectedContact,
}) => {
  const { currentPartner, setCurrentPartner, setStateVar, currentLoggedUser } =
    useAppContextController();
  const classes = useStyle();
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [system, setSystem] = useState(false);
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [toastAlert, setToastAlert] = useState(false);

  const { currentPartnerLeader } = usePartnerLeaderChecker({ currentPartner, setCurrentPartner });

  // Add the leader to the list of options
  const [currentData, hasPartnerLeader] = useMemo(() => {
    if (currentPartner && currentPartnerLeader) {
      return [
        {
          ...currentPartner,
          contacts: [
            {
              id: currentPartnerLeader._id,
              email: currentPartnerLeader.email,
              firstName: currentPartnerLeader.firstName,
              lastName: currentPartnerLeader.lastName,
              phone: currentPartnerLeader.phone,
            },
            ...(currentPartner.contacts ?? []),
          ],
        },
        true,
      ];
    }
    return [currentPartner, false];
  }, [currentPartner, currentPartnerLeader]);

  return (
    <MessagingPanel
      selectedContact={selectedContact}
      currentData={currentData}
      parent="Partner"
      messageContext="Partner"
      attachmentContext="Partner"
    />
  );
};

export default PartnerSendMessagePanel;
