import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider, useSnackbar } from "notistack";
import PartnersTableActions from "layouts/pages/partners/components/PartnersTableActions";
import fetchPartners from "layouts/pages/partners/actions/fetchPartners";
import { Handshake } from "@mui/icons-material";
import useVenueCache from "hooks/useVenueCache";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import { generatePartnerChartReport, generatePartnerTableReport } from "api/reports/partnerReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import useSessionAuth from "hooks/useSessionAuth";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ReportModal from "components/ReportModal";
import updatePartner from "../../actions/updatePartner";
// import PartnersActionModal from "../PartnersActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const PartnersTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setToastAlert,
}) => {
  const {
    setCurrentPartner,
    currentPartner,
    userType,
    currentLoggedUser,
    venues,
    setVenues,
    company,
  } = useAppContextController();
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const navigate = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentPartner });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, includeExtraCounts: true, filters };
  const { data: partners, isLoading } = useQuery(["partners", allOptions], () =>
    fetchPartners(allOptions)
  );

  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [selectedSubtables, setSelectedSubtables] = useState([]);

  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { mutateAsync: createPartnerTableReport } = useMutation(generatePartnerTableReport);
  const { mutateAsync: createPartnerChartReport } = useMutation(generatePartnerChartReport);
  const { mutateAsync: exportPartnerReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: savePartnerReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const { logoutAuth0User } = useSessionAuth();

  // DELETE BUTTON
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [partnerToDelete, setPartnerToDelete] = useState();
  const updatePartnerMutation = useMutation(updatePartner, {
    onError: (err) =>
      enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("partners");
      if (data.status === "Deleted") {
        setCurrentPartner({});
        enqueueSnackbar("Partner was archived", { variant: "success" });
        navigate(`/partners`);
      } else {
        setCurrentPartner({ ...currentPartner, ...data, modifiedDate: new Date() });
        enqueueSnackbar("Partner has been updated!", { variant: "success" });
      }
    },
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (row) => {
    setPartnerToDelete(row);
    setOpenDeleteModal(!openDeleteModal);
  };

  const deletePartnerHandler = async (values) => {
    await updatePartnerMutation.mutateAsync({
      id: partnerToDelete._id,
      data: { status: "Deleted" },
    });
    handleOpenDeleteModal();
  };
  // ------------------

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialPartnerReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const response = await fetchModuleReports("partners");

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);

          if (response.tableReportData.criteria?.selectedSubtables) {
            setSelectedSubtables(response.tableReportData.criteria?.selectedSubtables);
          }
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);

          if (response.chartReportData.criteria?.selectedSubtables) {
            setSelectedSubtables(response.chartReportData.criteria?.selectedSubtables);
          }
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialPartnerReport();
  }, [handleLoadInitialPartnerReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportPartnerReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Partner ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportPartnerReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      if (values?.selectedSubtables?.length > 0) {
        reportPayload = { ...reportPayload, selectedSubtables: values?.selectedSubtables };
      }

      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate,
            end: values?.endDate,
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        await createPartnerTableReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartTableSrc(response?.iframe_url);
              setTableQueryDetails(response?.queryDetails);
              setTableReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Partner Table Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
        selectedSubtables: values?.selectedSubtables || [],
      };

      if (values?.chartFilters) {
        reportPayload = {
          ...reportPayload,
          chartFilters: values?.chartFilters,
        };
      }

      if (values?.chartDateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.chartStartDate, // ISO formatted date already handled
            end: values?.chartEdDate, // ISO formatted date already handled
            field: values?.chartDateField,
            range: values?.chartDateRange,
          },
        };
      }

      try {
        await createPartnerChartReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Partner Chart Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSavePartnerReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData, formType: "chart" };
        }

        await savePartnerReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Partner ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving task report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, savePartnerReport, tableReportData]
  );

  const columns = [
    // {
    //   title: "Icon",
    //   field: "type",
    //   customCell: (image, field, row) => <GetIcons category={row?.category} />,
    // },
    {
      title: "Slug",
      field: "slug",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "City",
      field: "city",
    },
    {
      title: "State",
      field: "state",
    },
    {
      title: "Modified",
      field: "modifiedDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    // {
    //   title: "Avatar",
    //   field: "createAgent",
    //   customCell: (createAgent, field, row) => (
    //     <>
    //       {createAgent}
    //       <PictureAvatar
    //         image={null}
    //         firstName={row?.userFirstName}
    //         lastName={row?.userLastName}
    //         userId={createAgent}
    //         size="md"
    //       />
    //     </>
    //   ),
    // },
    // {
    //   title: "Created By",
    //   field: "userFirstName",
    //   customCell: (id, field, row) => (
    //     <MDTypography variant="body">
    //       {row.userFirstName} {row.userLastName}
    //     </MDTypography>
    //   ),
    // },
    {
      title: "Partner ID",
      field: "partnerId",
    },
    {
      title: "Partners Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <PartnersTableActions
          id={id}
          slug={row.slug}
          row={row}
          setCurrentPartner={setCurrentPartner}
          setModalInfo={setModalInfo}
          setNavigateUrl={navigate}
          setGenericModalOpen={setGenericModalOpen}
          onOpenDeleteModal={handleOpenDeleteModal}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "Slug", field: "slug" },
    { title: "Name", field: "name" },
    { title: "City", field: "city" },
    { title: "Leader First", field: "leader.firstName" },
    { title: "Leader Last", field: "leader.lastName" },
  ];

  // const columnsForPartners = [
  //   { title: "First Name", field: "userFirstName" },
  //   { title: "Last Name", field: "userLastName" },
  //   { title: "Title", field: "title" },
  //   { title: "Status", field: "status" },
  // ];

  const handleRowClick = (partner, e) => {
    e.stopPropagation();
    setCurrentPartner({ ...partner });
    navigate(`/partners/${partner.slug}`);
  };

  const addNew = (e) => {
    setCurrentPartner({});

    navigate("/partners/create");
    e.stopPropagation();
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
      setSelectedSubtables([]);
    }
  }, [openReportMessageModal]);

  const subtables = [
    {
      label: "Notes",
      value: "notes",
      payload: "partners_notes",
      identifier: "partner_id",
    },
    {
      label: "Venues",
      value: "venues",
      payload: "partners_venues",
      identifier: "partner_id",
    },
  ];

  const handleSelectSubtable = (subtable) => {
    if (subtables) {
      setSelectedSubtables((prev) => {
        // If the subtable is already selected, deselect it
        if (prev.length > 0 && prev[0].label === subtable.label) {
          return []; // Deselect the subtable by clearing the selection
        }

        // Otherwise, select the new subtable and replace any existing selection
        return [subtable];
      });
    }
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} pr={2}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="secondary"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-6}
            >
              <Handshake fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Partners
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={6}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {showSearchBar && (
            <Searchbar
              fetch={fetchPartners}
              fetchAll={false}
              placeholder="Search Partners"
              columns={columnsForSearch}
              queryCharacterLimit={2}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              onRowClick={(item) => navigate(`/partners/${item.slug}`)}
              searchBy={["name", "slug", "city"]}
              filterBy="slug"
              idField="slug"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>

          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={partners}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={false}
          defaultSort="slug:asc"
        />
      </MDBox>
      {/* <PartnersActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
      {openDeleteModal && (
        <ConfirmModal
          title="Delete Partner"
          description="Please confirm you want to DELETE this partner."
          isOpen={openDeleteModal}
          negativeBtn={{
            label: "Cancel",
            fn: handleOpenDeleteModal,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deletePartnerHandler,
            isShown: true,
          }}
        />
      )}
      {pageLimitConfirmationModal}
      {openReportMessageModal && (
        <ReportModal
          reportingType="partners"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          title="Partner Report"
          chartSrc={chartSrc}
          filterCardList={["Active", "Inactive", "Prospect", "All"]}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSavePartnerReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
          setToastAlert={setToastAlert}
          subTables={subtables}
          selectedSubtables={selectedSubtables}
          handleSelectSubtables={handleSelectSubtable}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
PartnersTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Partners",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
PartnersTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default PartnersTable;
