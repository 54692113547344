import { useMemo, useEffect } from "react";

import { Box, Typography, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { useEventContext } from "context/EventContext";
import "./ApplicantsTable.css";
import { useAppContextController } from "context/AppContext";
import { getApplicantVenueStatus } from "utils/helpers/applicant";

export const ApplicantsTable = ({
  selectedRecipients,
  setSelectedRecipients,
  applicants = [],
  emailChecked = true,
  smsChecked = false,
  systemChecked = false,
  setRows,
  isLoading,
}) => {
  let { eventRoster } = useEventContext();
  const { company, currentEvent, currentVenue } = useAppContextController();

  if (applicants.length) {
    eventRoster = applicants;
  }

  const columns = useMemo(
    () => [
      {
        headerName: "First Name",
        field: "firstName",
        flex: 1,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "Last Name",
        field: "lastName",
        flex: 1,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      { headerName: "City", field: "city", flex: 1 },
      { headerName: "State", field: "state", flex: 1 },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        renderCell: ({ value }) =>
          value && value !== "" ? (
            <Tooltip title={value}>
              <CheckCircle fontSize="medium" style={{ color: "green" }} />
            </Tooltip>
          ) : null,
      },
      {
        headerName: "Phone",
        field: "phone",
        flex: 1,
        renderCell: ({ value }) =>
          value && value !== "" ? (
            <Tooltip title={value}>
              <CheckCircle fontSize="medium" style={{ color: "green" }} />
            </Tooltip>
          ) : null,
      },
      ...(company?.companyType === "Venue"
        ? [
            {
              headerName: "Position",
              field: "primaryPosition",
              flex: 1,
              customCell: ({ value }) => (
                <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
                  {value}
                </Typography>
              ),
            },
            { headerName: "Verified", field: "loginVerified", flex: 1 },
          ]
        : []),
    ],
    [company?.companyType]
  );

  const parsedRows = useMemo(
    () =>
      eventRoster.map((item) => {
        const venueSlug = currentEvent?.venueSlug || currentVenue?.slug;
        const venueStatus = getApplicantVenueStatus(item, venueSlug);

        return {
          ...item,
          id: item._id,
          venueStatus,
          enabled:
            // Adding the disabled property based on the presence of email
            (emailChecked || smsChecked || systemChecked) &&
            (((!emailChecked || !!item.email) && (!smsChecked || !!item.phone)) ||
              (emailChecked && smsChecked)) &&
            item.recordLocked !== "Yes" &&
            ((company?.companyType === "Venue" && venueStatus !== "Locked") ||
              company?.companyType === "Company"),
        };
      }),
    [
      eventRoster,
      currentEvent?.venueSlug,
      currentVenue?.slug,
      emailChecked,
      smsChecked,
      systemChecked,
      company?.companyType,
    ]
  );

  useEffect(() => {
    setRows(eventRoster);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventRoster]);

  return (
    <Box
      sx={{
        maxHeight: "350px",
        height: "350px",
        overflow: "scroll",
        width: "100%",
      }}
    >
      <DataGrid
        rows={parsedRows}
        columns={columns}
        checkboxSelection
        selectionModel={selectedRecipients}
        onSelectionModelChange={setSelectedRecipients}
        disableSelectionOnClick
        isRowSelectable={(params) => {
          return params?.row?.enabled;
        }} // Check if params.data is defined
        getRowClassName={(params) => {
          if (selectedRecipients.includes(params?.row?._id)) {
            return "row-selected";
          }
          if (
            (params?.row?.recordLocked && params?.row?.recordLocked === "Yes") ||
            (params?.row?.venueStatus && params?.row?.venueStatus === "Locked")
          )
            return "row-locked";
          if (params?.row?.email && params?.row?.phone) return "";
          if (params?.row?.email && emailChecked) return "row-emailonly";
          if (params?.row?.phone && smsChecked) return "row-phoneonly";
          return "row-disabled";
        }}
        loading={isLoading}
      />
    </Box>
  );
};
