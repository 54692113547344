import { Grid, Icon, Stack } from "@mui/material";

import { useAppContextController } from "context/AppContext";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const CompanyLocationsItem = ({ row, idx, onEdit, onRemove }) => {
  const { locationName, address } = row;
  const { userType } = useAppContextController();

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          onEdit(idx, row, true);
        }}
      >
        <Grid container display="flex">
          <Grid item sm={11}>
            <Grid container justifyContent="left" alignItems="center">
              <Grid item sm={5.5}>
                <MDTypography sx={{ fontSize: "0.8rem" }}>{locationName}</MDTypography>
              </Grid>

              <Grid item sm={5.5}>
                <MDTypography sx={{ fontSize: "0.8rem" }}>{address}</MDTypography>
              </Grid>
            </Grid>
          </Grid>

          {userType === "Master" && (
            <Grid item sm={1}>
              <Grid container justifyContent="right" alignItems="center">
                <Grid item>
                  <MDBox textAlign="right">
                    <Icon
                      color="error"
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemove(idx);
                      }}
                    >
                      cancel
                    </Icon>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default CompanyLocationsItem;
