import { useState } from "react";
import { Box, Grid, Stack, IconButton, FormHelperText } from "@mui/material";
import { Cancel as CancelIcon, Edit as EditIcon, Clear as ClearIcon } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import CustomTextField from "components/Form/CustomTextField";

const DropdownObjectValue = ({ row, idx, onEdit, onRemove, clearErrors, errors, tags }) => {
  const [valToEdit, setValToEdit] = useState({});
  const [objectVal, setObjectVal] = useState("");

  const keys = Object.keys(row);
  const values = Object.values(row);

  const handleBlur = () => {
    onEdit(row?.[valToEdit?.key], idx, valToEdit?.key, objectVal);
    if (!errors?.[`${valToEdit?.key}_${idx}`]) setValToEdit({});
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item sm={10}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ ...(values?.length > 3 && { width: 300 + values?.length * 100 }) }}
        >
          {values?.map((value, i) => (
            <Grid key={`${values.toString()}_${keys[i]}_${value}`} item sm={12 / tags?.length}>
              <Stack direction="row" spacing={1} alignItems="center">
                {valToEdit?.idx === idx && valToEdit.key === keys[i] ? (
                  <>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        setValToEdit({});
                        setObjectVal("");
                        clearErrors(`${keys[i]}_${idx}`);
                      }}
                    >
                      <ClearIcon fontSize="medium" />
                    </IconButton>
                    <Box>
                      <CustomTextField
                        label=""
                        value={objectVal}
                        autoFocus
                        onChange={(e) => setObjectVal(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleBlur();
                          }
                        }}
                        showError={errors?.[`${keys[i]}_${idx}`]}
                        errorMessage={errors?.[`${keys[i]}_${idx}`]?.message}
                      />
                      <FormHelperText>Enter to save</FormHelperText>
                    </Box>
                  </>
                ) : (
                  <>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        setValToEdit({ idx, key: keys[i] });
                        setObjectVal(value);
                      }}
                    >
                      <EditIcon color="info" fontSize="medium" />
                    </IconButton>
                    <MDTypography sx={{ fontSize: "0.9rem" }}>{value}</MDTypography>
                  </>
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item sm={2}>
        <Box textAlign="right">
          <IconButton sx={{ p: 0 }} onClick={() => onRemove(idx)}>
            <CancelIcon color="error" fontSize="medium" />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DropdownObjectValue;
