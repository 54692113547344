import { Grid, Autocomplete, Box } from "@mui/material";

import { PatternFormat } from "react-number-format";
import FormTextEditor from "components/FormTextEditor";
import { useState } from "react";
import GenericModal from "components/GenericModal";
import parse from "html-react-parser";

const AIQuestionsForm = ({ control, watch, index, questionKey }) => {
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  return (
    <Grid container>
      <Box>
        <FormTextEditor
          title="Question"
          name={`${questionKey}.${index}.text`}
          key={`${questionKey}.${index}.text`}
          onClick={() => setPreviewOpen(true)}
          control={control}
          height="10rem"
          preserveWhitespace
        />
      </Box>
      {isPreviewOpen && (
        <GenericModal
          open={isPreviewOpen}
          setOpen={setPreviewOpen}
          body={<Box p={3}>{parse(watch(`${questionKey}.${index}.text`))}</Box>}
          borderRadius={5}
          overflow="hidden"
          contentPadding={0}
          width="90%"
          maxWidth={1170}
          maxHeight={940}
        />
      )}
    </Grid>
  );
};

export default AIQuestionsForm;
