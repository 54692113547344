import { useState } from "react";
import PanelActions from "components/PanelActions";
import MDBox from "components/MDBox";
import CommentIcon from '@mui/icons-material/Comment';
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { Icon } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { getDirtyFields } from "utils/helpers/formHelpers";
import { Controller, useForm } from "react-hook-form";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import updateIssue from "api/setup/updateIssue";
import { useAppContextController } from "context/AppContext";
import CustomCard from "components/CustomCard";
import CommentSection from "../IssuesInformation/CommentSection";


const useStyle = makeStyles({
    editor: {
        height: 100,
    },
    box: {
        overflow: "visible",
        height: 850,
    },
})

const IssuesComments = ({ control, setValue, dirtyFields, reset, handleSubmit }) => {

    const { isMobile, currentIssue, setCurrentIssue, currentLoggedUser, userType, company } =
        useAppContextController();
    const isEdit = !!currentIssue?._id;
    const classes = useStyle();
    const [toastAlert, setToastAlert] = useState({
        isShow: false,
        message: "",
    });
    const queryClient = useQueryClient();


    const updateIssueMutation = useMutation(updateIssue, {
        onError: (err) =>
            setToastAlert({
                isShow: true,
                message: `Something went wrong!  ${err.toString()}`,
                status: "error",
            }),
        onSuccess: async (_, { data }) => {
            await queryClient.invalidateQueries("issues");
            setCurrentIssue({ ...currentIssue, ...data });
            setToastAlert({ isShow: true, message: "Issue has been updated!", status: "success" });
        },
    });


    const handleCancelChanges = () => {
        reset({ comments: isEdit ? currentIssue.comments : [] })
    };


    const saveChanges = async (values) => {
        if (!currentIssue._id) {
            setToastAlert({ isShow: true, message: "Something went wrong!, no Id", status: "error" });
            return;
        }
        const modifiedFields = getDirtyFields(values, dirtyFields);
        // eslint-disable-next-line
        const data = { ...modifiedFields, modifiedDate: new Date() };
        await updateIssueMutation.mutateAsync({
            id: currentIssue._id,
            data,
        });
    };

    return (
        <SnackbarProvider
            maxSnack={3}
            open={toastAlert}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setToastAlert(false)}
            key="top-center"
        >
            <form onSubmit={handleSubmit(saveChanges)}>

                <CustomCard
                    icon={<CommentIcon color="white" />}
                    cardTitle="Comments"
                    cardActions={
                        dirtyFields?.comments && <PanelActions onCancel={handleCancelChanges} />
                    }

                    {...customPanelCard(isMobile, "info")}
                >
                    <MDBox display="flex" key={`box_sendMessage_${currentIssue?.id}`} padding={2}>

                        <MDBox width="100%">

                            <Controller
                                name="comments"
                                control={control}
                                render={({ field }) => (
                                    <CommentSection
                                        {...field}
                                        setValue={setValue}
                                        comments={field.value}
                                        currentIssue={currentIssue}
                                        currentLoggedUser={currentLoggedUser}
                                        mode={isEdit ? "edit" : "create"}
                                        dirtyFields={dirtyFields}
                                    />
                                )}
                            />
                        </MDBox>
                    </MDBox>
                </CustomCard>

            </form>
        </SnackbarProvider >
    );
};

export default IssuesComments;
