import { Box, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import parse from "html-react-parser";

const TopMainPageContent = ({ company }) => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#FFF", borderRadius: "4px" }}>
      <Grid container spacing={2} p={3}>
        <Grid item md={12} lg={3} pr={2} display="flex" justifyContent="center">
          <CompanyLogoSquare company={company} height={150} width={150} />
        </Grid>
        <Grid item lg={9}>
          {
            // eslint-disable-next-line no-extra-boolean-cast
            !!company?.companyContactMessage ? (
              parse(company.companyContactMessage)
            ) : (
              <>
                <MDTypography variant="h2">Your new great job awaits!</MDTypography>
                <MDTypography variant="h5">
                  Search & apply today to setup your interview.
                </MDTypography>
              </>
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopMainPageContent;
