import AvTimerIcon from "@mui/icons-material/AvTimer";
import CheckIcon from "@mui/icons-material/Check";
import HourglassIcon from "@mui/icons-material/HourglassTop";
import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import { useRosterApplicants } from "hooks/useRosterApplicants";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

const checkColor = {
  Roster: "success",
  Waitlist: "warning",
  Request: "warning",
};

const MyTasksActions = ({
  row,
  setCurrentTask,
  setTaskModalOpen,
  setTaskMode,
  setMessageModalOpen,
  setMessageModalInfo,
  assignedBy,
}) => {
  const onClickHandler = (action, e) => {
    e.stopPropagation();
    switch (action) {
      case "tasksmessage":
        setMessageModalInfo({
          type: "usermail",
          data: { applicantId: assignedBy?.applicantId, id: assignedBy?._id },
        });
        setMessageModalOpen(true);
        break;
      default:
        break;
    }
  };

  const actions = useMemo(
    () => [
      {
        name: "info",
        color: "info",
        icon: "info",
        clickAction: () => {
          setCurrentTask(row);
          setTaskMode("edit");
          setTaskModalOpen(true);
        },
        tooltip: "View Task",
      },
      {
        name: "pending",
        color: "warning",
        icon: "assignmentIcon",
        clickAction: (e) => onClickHandler("taskspending", e),
        tooltip: "Task Pending",
      },
      {
        name: "mail",
        color: "error",
        icon: "mail",
        showBadge: true,
        clickAction: (e) => onClickHandler("tasksmessage", e),
        tooltip: "Task Message",
      },
    ],
    []
  );

  return (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap">
      {actions.map((prop) => (
        <TableAction {...prop} key={`mytasks_actions_${prop.name}`} />
      ))}
    </MDBox>
  );
};

MyTasksActions.defaultProps = {
  taskUrl: "",
  isFavorite: false,
};

MyTasksActions.propTypes = {
  taskUrl: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default MyTasksActions;
