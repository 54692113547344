import { Tooltip } from "@mui/material";

import {
  Person,
  Block,
  Handshake,
  CheckCircle,
  PersonAdd,
  ToggleOff,
  ConnectWithoutContact,
  FolderShared,
} from "@mui/icons-material";

const CommonIconProps = {
  fontSize: "large",
  color: "warning",
};

const EmployeeStatus = ({ applicant, size = "md" }) => {
  const EMPLOYEE_STATUSES = {
    Applicant: { icon: <PersonAdd {...CommonIconProps} color="warning" /> },
    Employee: {
      icon: <Person {...CommonIconProps} color="error" />,
    },
    Partner: {
      icon: <Handshake {...CommonIconProps} color="primary" />,
    },
  };
  const EMPLOYEE_APPLICANT_STATUSES = {
    New: { icon: <PersonAdd {...CommonIconProps} color="warning" /> },
    ATC: {
      icon: <ConnectWithoutContact {...CommonIconProps} color="dark" />,
    },
    "Pre-Hire": {
      icon: <FolderShared {...CommonIconProps} color="info" />,
    },
    Screened: {
      icon: <CheckCircle {...CommonIconProps} color="warning" />,
    },
    Declined: {
      icon: <ToggleOff {...CommonIconProps} color="dark" />,
    },
  };

  let iconImage = null;
  let label = null;

  if (applicant?.status) {
    if (applicant.status === "Applicant") {
      if (applicant?.applicantStatus && EMPLOYEE_APPLICANT_STATUSES[applicant.applicantStatus]) {
        iconImage = EMPLOYEE_APPLICANT_STATUSES[applicant.applicantStatus].icon;
        label = applicant.applicantStatus;
      } else {
        iconImage = <PersonAdd color="error" fontSize="large" />;
        label = "Applicant";
      }
    } else if (EMPLOYEE_STATUSES[applicant.status]) {
      iconImage = EMPLOYEE_STATUSES[applicant.status].icon;
      label = applicant.status;
    } else {
      iconImage = <PersonAdd color="error" fontSize="large" />;
      label = "Applicant";
    }
  } else {
    iconImage = <PersonAdd color="error" fontSize="large" />;
    label = "Applicant";
  }

  return (
    <>
      <Tooltip title={label}>{iconImage}</Tooltip>
    </>
  );
};

export default EmployeeStatus;
