import { useState } from "react";

import { Button, FormHelperText, InputLabel, Grid, Autocomplete, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { PatternFormat, NumericFormat } from "react-number-format";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

import { transformYupErrorsIntoObject } from "utils/helpers/formHelpers";
import states from "assets/stateCodes.json";

import FormField from "layouts/applications/wizard/components/FormField";
import GenericModal from "components/GenericModal";
import jobHistorySchema from "./jobHistorySchema";

const salaryUnits = ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"];

const fullPartTimeList = ["Full-Time", "Part-Time"];

const defaultValues = {
  companyName: "",
  address: "",
  city: "",
  state: null,
  zip: "",
  phone: "",
  supervisor: "",
  fromDate: null,
  toDate: null,
  startingTitle: "",
  lastTitle: "",
  fullPartTime: null,
  startingSalary: 0,
  endingSalary: 0,
  salaryUnit: null,
  duties: "",
  reasonForLeaving: "",
};

const JobHistoryModal = ({
  jobHistoryList,
  currentJobHistory,
  setJobHistory,
  jobHistoryIndex,
  setJobHistoryIndex,
  onJobHistoryChange,
  isModalOpen,
  setModalOpen,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [errors, setErrors] = useState();
  console.log("current", currentJobHistory)
  const reset = () => {
    setJobHistory(defaultValues);
    setJobHistoryIndex(null);
    setErrors();
  };

  const handleClose = () => {
    setModalOpen(false);
    reset();
  };

  const handleChange = (name, value) => setJobHistory({ ...currentJobHistory, [name]: value });

  const handleSubmit = async () => {
    let updatedJobHistory;
    setErrors();

    await jobHistorySchema
      .validate(currentJobHistory, { abortEarly: false })
      .then(() => {
        if (jobHistoryList) {
          if (jobHistoryIndex !== null) {
            updatedJobHistory = jobHistoryList.map((item, index) => {
              if (index === jobHistoryIndex) {
                return currentJobHistory;
              }
              return item;
            });
          } else {
            updatedJobHistory = [...jobHistoryList, currentJobHistory];
          }
        } else {
          updatedJobHistory = [currentJobHistory];
        }

        onJobHistoryChange(updatedJobHistory);
        handleClose();
      })
      .catch((error) => {
        const validationErrors = transformYupErrorsIntoObject(error);
        setErrors(validationErrors);
      });
  };

  const modalBody = (
    <>
      <IconButton onClick={handleClose} sx={{ position: "absolute", top: "18px", right: "21px" }}>
        <CancelIcon />
      </IconButton>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    value={currentJobHistory?.companyName}
                    onChange={(e) => handleChange("companyName", e.target.value)}
                    label="Company Name"
                  />
                  <FormHelperText error>{errors?.companyName}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    value={currentJobHistory?.address}
                    onChange={(e) => handleChange("address", e.target.value)}
                    label="Address"
                  />
                  <FormHelperText error>{errors?.address}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    value={currentJobHistory?.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                    label="City"
                  />
                  <FormHelperText error>{errors?.city}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={states || []}
                    value={currentJobHistory?.state || null}
                    onChange={(e, v) => handleChange("state", v.toUpperCase())}
                    renderInput={(params) => (
                      <>
                        <FormField {...params} type="text" label="State" />
                        <FormHelperText error>{errors?.state}</FormHelperText>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    value={currentJobHistory?.zip}
                    onChange={(e) => handleChange("zip", e.target.value)}
                    label="Zip Code"
                  />
                  <FormHelperText error>{errors?.zip}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PatternFormat
                    format="(###) ### ####"
                    value={currentJobHistory?.phone}
                    allowEmptyFormatting
                    mask="_"
                    label="Phone"
                    onValueChange={({ value }) => handleChange("phone", value)}
                    customInput={FormField}
                  />
                  <FormHelperText error>{errors?.phone}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormField
                    value={currentJobHistory?.supervisor}
                    onChange={(e) => handleChange("supervisor", e.target.value)}
                    label="Supervisor"
                  />
                  <FormHelperText error>{errors?.supervisor}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Start Date"
                      value={currentJobHistory?.fromDate || null}
                      onChange={(e) => handleChange("fromDate", new Date(e))}
                      renderInput={(params) => (
                        <>
                          <FormField {...params} />
                          <FormHelperText error>{errors?.fromDate}</FormHelperText>
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="End Date"
                      value={currentJobHistory?.toDate || null}
                      onChange={(e) => handleChange("toDate", new Date(e))}
                      renderInput={(params) => (
                        <>
                          <FormField {...params} />
                          <FormHelperText error>{errors?.toDate}</FormHelperText>
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    value={currentJobHistory?.startingTitle}
                    onChange={(e) => handleChange("startingTitle", e.target.value)}
                    label="Hired as"
                  />
                  <FormHelperText error>{errors?.startingTitle}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    value={currentJobHistory?.lastTitle}
                    onChange={(e) => handleChange("lastTitle", e.target.value)}
                    label="Last Position"
                  />
                  <FormHelperText error>{errors?.lastTitle}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumericFormat
                    value={currentJobHistory?.startingSalary}
                    InputProps={{
                      startAdornment: "$",
                      inputProps: {
                        style: { textAlign: "end" },
                        inputMode: "numeric",
                      },
                    }}
                    label="Starting Salary"
                    decimalScale={2}
                    onValueChange={(e) => {
                      handleChange("startingSalary", e.value);
                    }}
                    customInput={FormField}
                  />
                  <FormHelperText error>{errors?.startingSalary}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumericFormat
                    value={currentJobHistory?.endingSalary}
                    InputProps={{
                      startAdornment: "$",
                      inputProps: {
                        style: { textAlign: "end" },
                        inputMode: "numeric",
                      },
                    }}
                    label="Ending Salary"
                    decimalScale={2}
                    onValueChange={(e) => {
                      handleChange("endingSalary", e.value);
                    }}
                    customInput={FormField}
                  />
                  <FormHelperText error>{errors?.endingSalary}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={salaryUnits || []}
                    value={currentJobHistory?.salaryUnit || null}
                    onChange={(e, v) => handleChange("salaryUnit", v)}
                    renderInput={(params) => (
                      <>
                        <FormField {...params} type="text" label="Salary Unit" />
                        <FormHelperText error>{errors?.salaryUnit}</FormHelperText>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={fullPartTimeList || []}
                    value={currentJobHistory?.fullPartTime || null}
                    onChange={(e, v) => handleChange("fullPartTime", v)}
                    renderInput={(params) => (
                      <>
                        <FormField {...params} type="text" label="Employment Status" />
                        <FormHelperText error>{errors?.fullPartTime}</FormHelperText>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink>Duties & Responsibilities</InputLabel>
          <FormField
            variant="outlined"
            name="duties"
            label=""
            value={currentJobHistory?.duties || null}
            InputLabelProps={{ shrink: false }}
            multiline
            rows={4}
            onChange={(e) => handleChange("duties", e.target.value)}
          />
          <FormHelperText error>{errors?.duties}</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink>Reason For Leaving</InputLabel>
          <FormField
            variant="outlined"
            name="reasonForLeaving"
            value={currentJobHistory?.reasonForLeaving || null}
            label=""
            InputLabelProps={{ shrink: false }}
            multiline
            rows={4}
            onChange={(e) => handleChange("reasonForLeaving", e.target.value)}
          />
          <FormHelperText error>{errors?.reasonForLeaving}</FormHelperText>
        </Grid>
      </Grid>
    </>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClose}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={isModalOpen}
        setOpen={setModalOpen}
        header="Add Job History"
        body={modalBody}
        buttons={modalButtons}
        width={isMobile ? "90%" : "50%"}
        borderRadius="4px"
      />
    </>
  );
};

export default JobHistoryModal;
