import { useState } from "react";
import GenericModal from "components/GenericModal";
import { Button, Stack } from "@mui/material";
import { Save } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import EditorPreviewModal from "components/SendMessage/EditorPreviewModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers";
import FormField from "layouts/applications/wizard/components/FormField";
import { APPLICANT_JOB_STATUS_ENUM } from "utils/constants/applicant";

const ApplicantJobStatusFilledModal = ({ open, setOpen, applicantName, handleUpdateStage }) => {
  const [editorValue, setEditorValue] = useState("");
  const [showMessagePreview, setShowMessagePreview] = useState(false);
  const [startDateTime, setStartDateTime] = useState(new Date());

  const closeModal = () => {
    setOpen(false);
    setEditorValue("");
  };

  const header = (
    <>
      <MDTypography variant="h5" sx={{ mt: 2 }}>
        Job Status Change
      </MDTypography>
      <MDTypography variant="body2" sx={{ mt: 1 }}>
        You are going to change the status of applicant
        <MDTypography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
          {` ${applicantName} `}
        </MDTypography>
        to{" "}
        <MDTypography
          component="span"
          variant="body2"
          sx={{ fontWeight: "bold", color: "info.main" }}
        >
          Filled
        </MDTypography>
      </MDTypography>
    </>
  );

  const modalBody = (
    <>
      <MDBox
        p={3}
        pt={0}
        sx={{
          "& .ql-editor": {
            minHeight: "100px !important",
            maxHeight: "300px",
            overflow: "hidden",
            overflowY: "auto",
            overflowX: "auto",
          },
        }}
      >
        <MDBox sx={{ width: "fit-content", mb: 2 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              disablePast
              label="Start Date/Time"
              value={startDateTime}
              onChange={(date) => {
                setStartDateTime(new Date(date));
              }}
              renderInput={(params) => <FormField {...params} />}
            />
          </LocalizationProvider>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <MDTypography variant="body2" color="dark">
            Job Note
          </MDTypography>
          <Button onClick={() => setShowMessagePreview(true)}>Preview</Button>
        </MDBox>
        <MDEditor
          value={editorValue}
          onChange={(e) => setEditorValue(e)}
          style={{ height: "100%" }}
        />
        {showMessagePreview && (
          <EditorPreviewModal
            toggleOpen={() => setShowMessagePreview(false)}
            isOpen={showMessagePreview}
            message={editorValue}
          />
        )}
      </MDBox>
    </>
  );

  const modalButtons = (
    <Stack direction="row" spacing={2} px={2}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => closeModal()}
        sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<Save />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
        disabled={!startDateTime}
        onClick={() => {
          handleUpdateStage(APPLICANT_JOB_STATUS_ENUM.FILLED, startDateTime, editorValue);
          closeModal();
        }}
      >
        Submit
      </Button>
    </Stack>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      header={header}
      body={modalBody}
      buttons={modalButtons}
      borderRadius={5}
      overflow="auto"
      contentPadding={0}
      width="50%"
      maxWidth={1170}
      maxHeight={800}
    />
  );
};

export default ApplicantJobStatusFilledModal;
