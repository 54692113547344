import { useState } from "react";
import { Autocomplete, Chip, FormHelperText } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import useDropdowns from "hooks/useDropdowns";
import MDBox from "components/MDBox";

const TagList = ({
  setValue,
  value = [],
  setError,
  clearErrors,
  currentComponent,
  outsideMode = "",
  label = "Tags",
  errorMessage,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const { dropdowns: tagOptions } = useDropdowns({ entity: "tagOptions", outsideMode });

  const handleAddTag = (v) => {
    const correctTag = tagOptions.find((tag) => {
      return tag?.toLocaleLowerCase() === v?.toLocaleLowerCase();
    });

    if (!correctTag) {
      setError("invalidTag", {
        type: "custom",
        message: "Tag is Invalid",
      });
    } else {
      clearErrors("invalidTag");
      if (correctTag && !value?.includes(v)) {
        setValue(props?.name, [...value, correctTag], { shouldDirty: true });
        setInputValue("");
      }
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setValue(
      props?.name,
      value?.filter((chip) => chip !== chipToDelete),
      { shouldDirty: true }
    );
  };

  const tagsToSelect = tagOptions?.filter((item) => !value?.includes(item));

  return (
    <div>
      <Autocomplete
        key={`autocomplete_tagList_${currentComponent?.slug}`}
        {...props}
        freeSolo
        autoSelect
        options={tagsToSelect?.sort((a, b) => a.localeCompare(b)) || []}
        renderInput={(params) => (
          <FormField
            {...params}
            label={label}
            type="text"
            inputProps={{ ...params.inputProps, value: inputValue }}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
        onChange={(e, v) => handleAddTag(v)}
      />
      <MDBox sx={{ border: "1px solid grey" }}>
        {value?.length
          ? value?.map((tag, index) => (
            <Chip
              label={tag}
              onDelete={handleDelete(tag)}
              key={tag.replace(" ", "_")}
              style={{ margin: "0.5em" }}
            />
          ))
          : null}
      </MDBox>
      {errorMessage && (
        <FormHelperText error sx={{ fontSize: "0.75rem" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

export default TagList;
