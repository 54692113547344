import axios from "axios";
import { baseAxios } from "config";

export default async function fetchEventApplicants(eventUrl) {
  try {
    const res = await baseAxios.get(`/events/url/${eventUrl}/roster`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
}
