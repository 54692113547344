import * as yup from "yup";

export const directDepositSchema = yup.object().shape({
  paymentType: yup.string().default("").required("Please select Payment Type"),
  account1: yup.string().when("paymentType", {
    is: (val) => val === "DirectDeposit",
    then: yup.string().required("Bank One Account Number is required"),
    otherwise: yup.string().default("").notRequired(),
  }),
  accountType1: yup.string().when("paymentType", {
    is: (val) => val === "DirectDeposit",
    then: yup.string().required("Bank One Account Type is required"),
    otherwise: yup.string().default("").notRequired(),
  }),
  amountPercentage1: yup.string().when("paymentType", {
    is: (val) => val === "DirectDeposit",
    then: yup
      .string()
      .required("Bank One Account % is required")
      .test(
        "max-account-percentage-1",
        "Amount percentage 1 should total to 100%",
        function (value) {
          const { amountPercentage2 } = this.parent;
          if (amountPercentage2) {
            return true;
          }
          if (parseInt(value, 10) === 100) {
            return true;
          }
          return false;
        }
      ),
    otherwise: yup.string().default("").notRequired(),
  }),
  bankName1: yup.string().when("paymentType", {
    is: (val) => val === "DirectDeposit",
    then: yup.string().required("Bank One Bank Name is required"),
    otherwise: yup.string().default("").notRequired(),
  }),
  routing1: yup.string().when("paymentType", {
    is: (val) => val === "DirectDeposit",
    then: yup.string().required("Bank One Routing # is required"),
    otherwise: yup.string().default("").notRequired(),
  }),

  bankName2: yup.string(),
  routing2: yup.string().when("bankName2", {
    is: (val) => val?.length,
    then: yup.string().required("Bank Two Routing # is required"),
    otherwise: yup.string().notRequired(),
  }),
  account2: yup.string().when(["bankName2", "routing2"], {
    is: (bankName, routing) => bankName?.length || routing?.length,
    then: yup.string().required("Bank Two Account # is required"),
    otherwise: yup.string().notRequired(),
  }),
  accountType2: yup.string().when(["bankName2", "routing2", "account2"], {
    is: (bankName, routing, account) => bankName?.length || routing?.length || account?.length,
    then: yup.string().required("Bank Two Account Type is required"),
    otherwise: yup.string().notRequired(),
  }),
  amountPercentage2: yup
    .string()
    .when(["bankName2", "routing2", "account2", "accountType2"], {
      is: (bankName, routing, account, accountType) =>
        bankName?.length || routing?.length || account?.length || accountType?.length,
      then: yup.string().required("Bank Two Account % is required"),
      otherwise: yup.string().notRequired(),
    })
    .test("max", "Amount percentage of 2 banks should total to 100%", function (value) {
      const { amountPercentage1 } = this.parent;
      if (value) {
        return parseInt(value, 10) + parseInt(amountPercentage1, 10) === 100;
      }
      return true;
    }),
  date: yup.date().default(new Date()),
  // checkStubOptions: yup.string().default("").required("Please select Stub Options"),
  // email: yup.string().default("").required("Email is required"),
  // employeeName: yup.string().default("").required("Name is required"),
  // socialSecurity: yup.string().default("").required("Social Security is required"),
});
