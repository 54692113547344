import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// const ConfirmDialog = ({ title = "Data has changed!", bodyText, open, setOpen, setResponse }) => {
const ConfirmDialog = ({
  state: { title, bodyText, isOpen },
  setState,
  setOrganizationDetailOpen,
}) => {
  const handleCancel = () => {
    setState((previousState) => ({ ...previousState, isOpen: false, response: false }));
    setOrganizationDetailOpen(true);
  };

  const handleConfirm = () => {
    setState((previousState) => ({ ...previousState, isOpen: false, response: true }));
    setOrganizationDetailOpen(false);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCancel}>
        <DialogTitle>{title || "Data has changed!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyText || "Please Confirm/Cancel to proceed."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" style={{ color: "white" }} onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
