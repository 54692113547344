import { useState } from "react";
import { isEqual } from "lodash";

import { Card, styled } from "@mui/material";

import { useSnackbar } from "notistack";
import FileDropzone from "components/Dropzone";

import uploadFileCompany from "api/companies/uploadFileCompany";
import updateCompany from "api/companies/updateCompany";
import { useMutation, useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";

const initialAttachment = { file: null };

const UploadFileModal = ({ isOpen, setIsOpen, label, attachmentTypes, typeOfUploader }) => {
  const { company, setCompany } = useAppContextController();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [attachmentDetails, setAttachmentDetails] = useState(initialAttachment);
  const { mutateAsync: uploadFileCompanyMutation } = useMutation(uploadFileCompany);
  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);

  const uploaderId = isEqual(typeOfUploader, "company") ? company?._id : "";
  const uploaderUrl = isEqual(typeOfUploader, "company") ? company?.companyUrl : "";
  const slug = isEqual(typeOfUploader, "company") ? company?.slug : "";

  const uploadFileHandler = async (form, filePath, fileExt) => {
    const newAttachment = {
      docType: fileExt,
      filename: filePath,
      uploadDate: new Date(),
    };

    if (isEqual(typeOfUploader, "company")) {
      const newAttachmentList = company?.attachments ? [...company?.attachments] : [];

      // if (
      //   newAttachmentList.length &&
      //   !isEqual(attachmentDetails.fileType, "Other") &&
      //   newAttachmentList.findIndex((item) => isEqual(item.type, attachmentDetails.fileType)) > -1
      // ) {
      //   const idx = company?.attachments.findIndex((item) =>
      //     isEqual(item.type, attachmentDetails.fileType)
      //   );
      //   newAttachmentList[idx] = newAttachment;
      // } else

      newAttachmentList.push(newAttachment);

      const updatedCompany = { ...company, attachments: newAttachmentList };

      uploadFileCompanyMutation(
        { companySlug: slug, uploadForm: form },
        {
          onSuccess: async (res) => {
            await queryClient.invalidateQueries("companies");
            enqueueSnackbar("Company File has been uploaded.", { variant: "success" });
          },
          onError: (err) => console.log(err),
        }
      );

      updateCompanyMutation(
        { companyId: company._id, body: updatedCompany },
        {
          onSuccess: async (res) => {
            await queryClient.invalidateQueries("companies");
            setCompany(updatedCompany);
            enqueueSnackbar("Company has been updated.", { variant: "success" });
          },
          onError: (err) => console.log(err),
        }
      );
    }
  };

  const onFileDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles.length <= 0) return;

    const formUpload = new FormData();
    const uploadedFile = acceptedFiles[0];
    const uploadedFileExtension = uploadedFile?.path?.split(".").pop();

    formUpload.append("file", uploadedFile);

    if (uploaderId) uploadFileHandler(formUpload, uploadedFile.path, uploadedFileExtension);

    setAttachmentDetails(initialAttachment);
  };

  return (
    <>
      <FileDropzone
        message="Drop files here to upload"
        multiple={false}
        onDrop={onFileDropHandler}
      />
    </>
  );
};

const StyledContainerModal = styled(Card)(({ theme }) => ({}));

export default UploadFileModal;
