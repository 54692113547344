import { Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";

const useStyle = makeStyles({
  text: {
    color: "white",
    textAlign: "center",
  },
});

const WaitListModal = ({ open, setOpen, onCancel }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const classes = useStyle();
  const header = (
    <MDTypography variant="h5" className={classes.text}>
      This is currently full.
    </MDTypography>
  );

  const buttons = (
    <>
      <Box display="flex" flexDirection="column">
        <Button
          variant="contained"
          color="white"
          sx={{
            color: "red!important",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          Add me to waitlist
        </Button>

        <Button variant="text" size="large" onClick={onCancel}>
          <MDTypography variant="bod2" color="white" fontWeight="bold">
            Cancel this sign-up
          </MDTypography>
        </Button>
      </Box>
    </>
  );

  const modalBody = (
    <Box>
      <MDTypography variant="h5" className={classes.text}>
        If you would like to be added to the waitlist and contacted when a position opens simply let
        us know below.
      </MDTypography>
    </Box>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        backgroundColor="red"
        width={isSmallScreen ? "70%" : "30%"}
        buttonLocation="center"
      />
    </>
  );
};

export default WaitListModal;
