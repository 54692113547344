import { Box, Card, Grid, Icon, Stack, useMediaQuery, styled, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import useCompanyCache, { useOutsideCompanyCache } from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import parse from "html-react-parser";
import fetchSingleJob from "layouts/pages/jobs/actions/fetchSingleJob";
import { getCompanyBaseImageUrl } from "utils/helpers/upload";
import { useEffect, useMemo, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import useSessionAuth from "hooks/useSessionAuth";
import Apply from "../components/Apply";
import HomeHeader from "../components/HomeHeader";
import JobDescription from "./JobDescription";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
    width: "85%",
  },
});
const TableContainer = styled(FlexWrapper)(({ theme }) => ({
  "& #table-results": {
    "& .MuiBox-root": {
      [theme.breakpoints.down("lTablet")]: {
        padding: "10px",
      },
    },
  },
  "& .MuiPaper-root .MuiBox-root .MuiPaper-root": {
    paddingBottom: "20px",
    "& > div:last-child": {
      height: "100%",
      maxHeight: "450px",
      [theme.breakpoints.up(1680)]: {
        maxHeight: "calc(100vh - 550px)",
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme, url }) => ({
  backgroundImage: `url(${url})`,
  justifyContent: "center",
  alignItems: "center",
  backgroundSize: "100%",
  backgroundRepeat: "no-repeat",
  position: "relative",
  width: "100%",
  height: "50%",
  minHeight: "50vh",
  [theme.breakpoints.down("laptop")]: {
    height: "100%",
  },
}));

const JobApply = () => {
  const classes = useStyle();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { company, setCompany, venues, setVenues, setCompanyType, isMobile } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "protected",
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({
    venues,
    setVenues,
    company,
    outsideMode: "protected",
  });

  const [job, setJob] = useState(null);
  const [venueInfo, setVenueInfo] = useState(null);
  const [customer, setCustomer] = useState(null);
  const isVenues = company?.companyType === "Venue" || company?.settings?.companyType === "Venue";

  const { jobSlug, id } = useParams();

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const getJob = async (jSlug, mongoId) => {
      const result = await fetchSingleJob(jSlug, mongoId, "public");

      if (result?.data?.job) {
        const newJob = result.data.job;
        setJob(newJob);
        if (isVenues) setVenueInfo(venues?.[newJob?.venueSlug]);
        else if (newJob?.companySlug) {
          try {
            const cust = await fetchSingleCustomer({ slug: newJob.companySlug });
            if (cust?.data) setCustomer(cust?.data);
          } catch (error) {
            if (String(error).includes("401") || error?.response?.status === 401) {
              logoutAuth0User();
            }
          }
        }
      }
    };

    if (!isLoadingVenues && jobSlug) {
      getJob(jobSlug);
    } else if (id) {
      getJob(null, id);
    }
  }, [isVenues, venues, jobSlug, id, isLoadingVenues]);

  const activeCompany = job?.showCustomer === "Yes" ? customer : company;

  const parsedUrl = useMemo(() => {
    if (isVenues && venueInfo?.venueUrl) {
      const { venueUrl } = venueInfo;
      if (venueUrl.search("https://") === 0 || venueUrl.search("http://") === 0) {
        return venueUrl;
      }
      return `https://${venueUrl}`;
    }
    if (activeCompany && activeCompany?.companyUrl) {
      const { companyUrl } = activeCompany;
      if (companyUrl.search("https://") === 0 || companyUrl.search("http://") === 0) {
        return companyUrl;
      }
      return `https://${companyUrl}`;
    }

    return null;
  }, [venueInfo, activeCompany, isVenues]);

  const location = useMemo(() => {
    if (isVenues) {
      return `${venueInfo?.city}, ${venueInfo?.state}`;
    }
    if (job?.companyCity && job?.companyState) {
      return `${job?.companyCity}, ${job?.companyState}`;
    }
    if (!isVenues) {
      return `${customer?.city}, ${customer?.state}`;
    }
    return null;
  }, [customer, job, venueInfo, isVenues]);

  return (
    <>
      <Box sx={{ backgroundColor: "#303030" }}>
        <HomeHeader company={company} />
        <StyledBox
          url={`${getCompanyBaseImageUrl(company)}/backgroundImage/${company?.backgroundImageUrl}`}
        >
          <Button component={NavLink} to="/" variant="text" sx={{ color: "#FFF" }}>
            <ArrowBackIosIcon /> Back to Job Results
          </Button>
          {company?.companyType === "Venue" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                margin: isMobile || "0 25%",
              }}
            >
              <MDTypography
                fontWeight="bold"
                fontSize={!isMobile ? "5rem" : "3rem"}
                color="white"
                textAlign="center"
                mt={isMobile ? 5 : 10}
              >
                BE A PART OF OUR
                <span style={{ color: "red" }}>&nbsp;MISSION</span>
              </MDTypography>
              <MDTypography
                variant={!isMobile ? "h4" : "h6"}
                color="white"
                textAlign="center"
                mb={isMobile ? 0 : 10}
              >
                Join our events team today and experience the magic of the crowd management industry
                & event staff jobs, making extra money, and meeting new people.
              </MDTypography>
            </Box>
          )}
          <ContainerWrapper>
            <FlexWrapper px={{ xs: 0, sm: 5, sDesktop: 0 }} mt={{ xs: "0px", sDesktop: "30px" }}>
              <TableContainer px={{ sDesktop: 10 }} py={3}>
                <Box my={3} sx={{ width: "100%" }}>
                  <JobDescription job={job} activeCompany={activeCompany} />
                </Box>

                <Apply job={job} />
              </TableContainer>
            </FlexWrapper>
          </ContainerWrapper>
        </StyledBox>
        <MDBox
          sx={{ py: "15px", px: "20px" }}
          bgColor="#222"
          display="flex"
          justifyContent="center"
        >
          <img
            src="/images/png/logos/powered-by-gignology.png"
            alt="powered by gig-nology"
            width="205.8px"
            height="82.8px"
          />
        </MDBox>
      </Box>
    </>
  );
};

export default JobApply;
