import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import { Stack, Button, Grid, Box, Backdrop, CircularProgress, useMediaQuery } from "@mui/material";
import DataList from "components/DataList";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import { useQuery } from "react-query";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import Searchbar from "components/Searchbar";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDTypography from "components/MDTypography";
import moment from "moment";
import fetchCustomers from "api/companies/fetchCustomers";
import useSessionAuth from "hooks/useSessionAuth";
import DateRangeSelector from "components/DateRangeSelector";
import CompanyMyJobsActions from "../CompanyMyJobsActions";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const MyJobs = ({ fetchAll, filters, setFilters }) => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");

  const {
    venues,
    setVenues,
    company,
    setCompany,
    companyType,
    setCompanyType,
    setCurrentJob,
    currentLoggedUser,
    userType,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [myCustomers, setMyCustomers] = useState([]);
  const [userApplicant, setUserApplicant] = useState(null);
  const [userIsLoading, setUserIsLoading] = useState(true);

  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});

  const [dateRangeFilter, setDateRangeFilter] = useState(null);

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const setUserCustomers = async (appId) => {
      const app = await fetchSingleApplicant({ applicantId: appId });
      setUserApplicant(app.data);
      const customerList = app?.data?.companies
        ?.filter((item) => item.companySlug)
        ?.map((item) => item.companySlug);
      if (customerList?.length) {
        const tempList =
          companies?.data?.filter((item) => {
            return currentLoggedUser.companies.includes(item.slug);
          }) || [];
        if (userType === "User") setMyCustomers(tempList);
      }
    };

    if (currentLoggedUser) {
      if (userType === "User") {
        try {
          setUserCustomers(currentLoggedUser.applicantId);
          setUserIsLoading(false);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      } else if (userType && userType !== "User") {
        setMyCustomers(
          currentLoggedUser?.companies?.length ? [...currentLoggedUser.companies] : []
        );
        setUserIsLoading(false);
      }
    }
  }, [currentLoggedUser, companies, userType]);

  const options = fetchAll ? { fetchAll } : { page, limit: null };
  const allOptions = {
    ...options,

    filters: {
      ...filters,
      // hideThisJob: "No",
      companySlug: myCustomers?.length ? myCustomers.join(";") : [],
      status: "Active",
    },
  };

  if (dateRangeFilter) {
    allOptions.startDate = dateRangeFilter.startDate.toISOString();
    allOptions.endDate = dateRangeFilter.endDate.toISOString();
  }

  const fetchCustomerData = async (slug) => {
    const companiesOptions = { slug, primaryCompany: false };
    try {
      const { data } = await fetchCustomers({ filters: companiesOptions, fetchAll: true });
      if (data.length) {
        setCompanies([...companies, ...data]);
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const { data: jobs, isLoading } = useQuery(["myjobs", allOptions], () => fetchJobs(allOptions), {
    refetchOnWindowFocus: false,
    enabled: !!currentLoggedUser?.companies?.length, // Only run this query if myCustomers is not null or undefined

    onSuccess: ({ data }) => {
      if (data) {
        // const filtered = data.filter((item) => item.hideThisJob !== "Yes");
        setFilteredJobs(data);

        let distinctCompanies = data.filter((jobItem) => {
          const isExistingCompany = companies.some(
            (existingItem) => jobItem && existingItem.slug === jobItem.companySlug
          );
          return jobItem && !isExistingCompany;
        });

        distinctCompanies = [...new Set(distinctCompanies.map((item) => item.companySlug))];
        if (distinctCompanies.length) {
          fetchCustomerData(distinctCompanies.join(";"));
        }
      }
    },
  });

  const columns = useMemo(
    () => [
      { title: "", field: "logoUrl", type: "image", imageWidth: "50", imageHeight: "50" },
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Start Date",
        field: "startDate",
        customCell: (date) => moment(date).format("MM/DD/YYYY"),
      },
      { title: "Status", field: "status" },
    ],
    []
  );

  const searchColumns = useMemo(
    () => [
      {
        title: "",
        field: "logoUrl",
        type: "image",
        width: 40,
        imageWidth: "50",
        imageHeight: "50",
      },
      {
        title: "Company",
        field: "companySlug",
        width: 100,
        customCell: (slug) => {
          const existingCompany = companies.find((comp) => comp.slug === slug);
          return <CompanyLogoSquare company={existingCompany} defaultCompany={company} />;
        },
      },
      { title: "Job Url", field: "jobSlug", width: 150 },
      { title: "Title", field: "title", width: 150 },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },

      { title: "Status", field: "status" },
    ],
    [companies, company]
  );

  const navigate = useNavigate();
  const addNew = () => {
    setCurrentJob({});
    navigate("/companyjobs/create");
  };
  const viewAll = () => navigate("/companyjobs");

  const getCompanyFromSlug = (slug) => {
    const ndx = companies.findIndex((item) => item.slug === slug);
    if (ndx > -1) return companies[ndx];
    return null;
  };

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center" key={row._id}>
        <Grid item xs={12} sm={1.5}>
          <CompanyLogoSquare
            company={row?.companySlug ? getCompanyFromSlug(row.companySlug) : company}
            defaultCompany={company}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <MDTypography
                variant="subtitle2"
                sx={{ fontWeight: "bold" }}
                key={`${row._id}_${row.jobSlug}`}
              >
                {row.title}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="body2" key={`${row._id}_${row.companyName}`}>
                {row?.companyName}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="body2" key={`${row._id}_${row.companyName}`}>
                Pay Rate: {row?.payRate}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Box sx={{ textAlign: "right" }} pr={2}>
            <CompanyMyJobsActions slug={row.jobSlug} />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const searchBarRowClick = (item) => {
    setCurrentJob(item);
    navigate(`/companyjobs/${item.jobSlug}/action/jobinfo`);
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2}>
        <Grid item sm={5}>
          <Searchbar
            fetch={fetchJobs}
            fetchAll={fetchAll}
            placeholder="Search Job"
            columns={searchColumns}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy="title,companySlug,companyName"
            filterBy="companySlug"
            onRowClick={(item) => searchBarRowClick(item)}
            dropRight
          />
        </Grid>
        <Grid item container sm={2}>
          <DateRangeSelector
            setDateRangeFilter={setDateRangeFilter}
            dateRangeFilter={dateRangeFilter}
          />
        </Grid>
        <Grid item sm={5}>
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  const parsedJobs = useMemo(() => {
    const isFilterDirty = Object.values(searchFilter).some((item) => item);
    if (filteredJobs.length || isFilterDirty) {
      return filteredJobs;
    }
    if (jobs?.data) {
      return jobs?.data; // filtered;
    }
    return [];
  }, [filteredJobs, jobs, searchFilter]);

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={parsedJobs}
        filters={{ ...filters, status: "Active" }}
        icon="work"
        iconColor="warning"
        title="My Jobs"
        isLoading={isLoading}
        tableHeight={420}
        height={isLaptop ? 500 : null}
        greybar
        divider
        header={header}
        scrollY
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
        open={isLoading && parsedJobs.length === 0}
      >
        <CircularProgress
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            margin: "auto",
            top: 0,
            bottom: 0,
            zIndex: 50,
          }}
        />
      </Backdrop>
    </>
  );
};

export default MyJobs;
