import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchSingleJob = async (jobSlug, mongoId, outsideMode = "") => {
  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;
    const url = jobSlug
      ? `/${outsideModeQuery}jobs/slug/${jobSlug}`
      : `/${outsideModeQuery}jobs/${mongoId}`;
    const res = await baseAxios.get(url);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchSingleJob;
