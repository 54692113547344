import { baseAxios } from "config";

const updateApplicantStage = ({ status, applicantId, jobSlug, sendOnboardingMessage }) => {
  return baseAxios.put(`/applicants/${applicantId}/job/applicantstatus`, {
    status,
    jobSlug,
    sendOnboardingMessage,
  });
};

export default updateApplicantStage;
