import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "300px",
  },
  list: {
    width: "90%",
    height: "300px",
    overflowY: "scroll",
    border: "solid black 1px",
    padding: 2,
    marginTop: 10,
  },
  button: {
    margin: "8px",
  },
  leftListStyle: {
    display: "flex",
    justifyContent: "center",
  },
}));

const NotificationRecipientsModal = ({
  open,
  setOpen,
  NotificationRecipientsArray,
  setValue,
  isCreate,
  setReminderToSaveChangesModalOpen,
}) => {
  const { company } = useAppContextController();
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const options = { fetchAll: true };
  const allOptions = { ...options, filters, order: "lastName", orderBy: "lastName" };
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [selectedLeftItem, setSelectedLeftItem] = useState(null);
  const [selectedRightItem, setSelectedRightItem] = useState(null);

  useEffect(() => {
    setFilters({ spStatus: "Employee" });
  }, []);

  const filteredUsers = [];
  const { data: users } = useQuery(["users", allOptions], () => fetchUsers(allOptions));
  users?.data
    ?.filter((item) => !["Terminated", "Inactive"].includes(item.status))
    .map((user) => {
      // const obj = filteredUsers.push(`${user.firstName} ${user.lastName}`);
      filteredUsers.push({
        userId: user._id,
        applicantId: user.applicantId,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.emailAddress,
      });
      return filteredUsers;
    });

  filteredUsers.push({
    userId: "",
    applicantId: "",
    firstName: "Jobs",
    lastName: "",
    fullName: "Jobs",
    email: company?.companyEmail,
  });
  const sortUsers = (a, b) => {
    if (a.fullName > b.fullName) return 1;
    return -1;
  };
  filteredUsers.sort(sortUsers);

  const moveItem = (index, sourceList, targetList, direction) => {
    const source = [...sourceList];
    const target = [...targetList];
    const item = source.splice(index, 1);
    target.push(...item);

    source.sort(sortUsers);
    target.sort(sortUsers);
    if (direction === "left") {
      setLeftList(source);
      setRightList(target);
    } else {
      setRightList(source);
      setLeftList(target);
    }
  };

  const handleLeftItemClick = (index) => {
    setSelectedLeftItem(index);
    setSelectedRightItem(null);
  };

  const handleRightItemClick = (index) => {
    setSelectedRightItem(index);
    setSelectedLeftItem(null);
  };

  const handleButtonClick = (direction) => {
    if (direction === "left") {
      if (selectedLeftItem !== null) {
        moveItem(selectedLeftItem, leftList, rightList, direction);
        setSelectedLeftItem(null);
      }
    } else if (direction === "right") {
      if (selectedRightItem !== null) {
        moveItem(selectedRightItem, rightList, leftList, direction);
        setSelectedRightItem(null);
      }
    }
  };

  const handleButtonDoubleClick = (index, direction) => {
    if (direction === "left") {
      moveItem(index, leftList, rightList, direction);
      setSelectedLeftItem(null);
    } else if (direction === "right") {
      moveItem(index, rightList, leftList, direction);
      setSelectedRightItem(null);
    }
  };

  useEffect(() => {
    setRightList(NotificationRecipientsArray);
    const currentList = filteredUsers.filter((item) => {
      return !NotificationRecipientsArray?.find((e) => e.userId === item.userId);
    });
    setLeftList(currentList);
  }, [users, NotificationRecipientsArray, open]);

  const header = (
    <>
      <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
        <MDTypography>Add Recipients</MDTypography>
        <MDBox display="flex" alignItems="center">
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            onClick={() => setOpen(false)}
            sx={{ padding: "0.5rem 1rem" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SaveIcon />}
            style={{ color: "white" }}
            sx={{ padding: "0.5rem 1rem" }}
            onClick={() => {
              if (!(JSON.stringify(NotificationRecipientsArray) === JSON.stringify(rightList))) {
                setValue("notificationRecipients", rightList, { shouldDirty: true });
                if (!isCreate) setReminderToSaveChangesModalOpen(true);
              }
              setOpen(false);
            }}
          >
            OK
          </Button>
        </MDBox>
      </MDBox>
    </>
  );

  const modalBody = (
    <Box className={classes.root}>
      <Grid container>
        <Grid item sm={5} className={classes.leftListStyle}>
          <List className={classes.list}>
            {leftList?.map((item, index) => (
              <ListItemButton
                key={item?.userId}
                selected={index === selectedLeftItem}
                onClick={() => handleLeftItemClick(index)}
                onDoubleClick={() => handleButtonDoubleClick(index, "left")}
              >
                <ListItemText primary={item?.fullName} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item sm={2} p={3}>
          <Box display="flex" flexDirection="column">
            <Button
              className={classes.button}
              variant="contained"
              color="success"
              onClick={() => handleButtonClick("left")}
            >
              &gt;
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="error"
              onClick={() => handleButtonClick("right")}
            >
              &lt;
            </Button>
          </Box>
        </Grid>
        <Grid item sm={5} className={classes.leftListStyle}>
          <List className={classes.list}>
            {rightList?.map((item, index) => (
              <ListItemButton
                key={item?.userId}
                selected={index === selectedRightItem}
                onClick={() => handleRightItemClick(index)}
                onDoubleClick={() => handleButtonDoubleClick(index, "right")}
              >
                <ListItemText primary={item?.fullName} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );

  const buttons = <></>;

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        sx={(theme) => ({
          width: "50% !important",
          maxWidth: "70% !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default NotificationRecipientsModal;
