import { baseAxios } from "config";

const declineJob = async ({ jobSlug, data }) => {
  try {
    const res = await baseAxios.post(`/outside-protected/jobs/${jobSlug}/decline`, data);
    return res.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};

export default declineJob;
