import axios from "axios";
import { baseAxios } from "config";

const createJob = async ({ data }) => {
  try {
    const res = await baseAxios.post(`/jobs`, data);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default createJob;
