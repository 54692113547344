import { Grid, Icon, Stack } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});


const JobHistoryItem = ({ row, idx, onEdit, onRemove }) => {
  // const { companyName, city, state, fromDate, toDate } = row;
  const { companyName, city, state, fromDate, toDate, lastTitle, duties } = row;

  let tooltipText
  if (Array.isArray(duties)) {
    tooltipText = duties.join('\n')
  } else {
    tooltipText = duties
  }

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          onEdit(idx, row, true);
        }}
      >


        <Grid container display="flex" alignItems="center">
          <Grid item sm={11}>
            <Grid container justifyContent="left" alignItems="center" spacing={1}>
              <Grid item sm={2.5}>
                <MDTypography sx={{ fontSize: "0.8rem" }}>{companyName}</MDTypography>
              </Grid>

              <Grid item sm={1.5} textAlign="center">
                <MDTypography sx={{ fontSize: "0.8rem" }}>{city}</MDTypography>
              </Grid>
              <Grid item sm={1} textAlign="end">
                <MDTypography sx={{ fontSize: "0.8rem" }}>{state}</MDTypography>
              </Grid>

              <Grid item sm={2} textAlign="end">
                <MDTypography sx={{ fontSize: "0.8rem" }}>
                  {(fromDate !== null && fromDate !== "") ? moment(fromDate).format("L") : ""}
                </MDTypography>
              </Grid>
              <Grid item sm={2} textAlign="end">
                <MDTypography sx={{ fontSize: "0.8rem" }}>
                  {(toDate !== null && toDate !== "") ? moment(toDate).format("L") : ""}
                </MDTypography>
              </Grid>
              <Grid item sm={3} textAlign="end">
                {tooltipText ? (<CustomWidthTooltip title={`${tooltipText?.substring(0, 50)}...`}>
                  <MDTypography sx={{ fontSize: "0.8rem" }}>
                    {lastTitle}
                  </MDTypography>
                </CustomWidthTooltip>)
                  :
                  (<MDTypography sx={{ fontSize: "0.8rem" }}>
                    {lastTitle}
                  </MDTypography>)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={1} mt={0.5}>
            <MDBox textAlign="right">
              <Icon
                color="error"
                fontSize="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(idx);
                }}
              >
                cancel
              </Icon>
            </MDBox>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default JobHistoryItem;
