import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CopyButton from "components/CopyButton";
import { MESSAGE_VARIABLES } from "utils/constants/emailTemplate";

const EmailVariableTable = () => {
  const variablesLength = MESSAGE_VARIABLES.length;

  return (
    <Accordion sx={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Message Variables</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: "200px", overflow: "auto" }}>
        <TableContainer sx={{ borderRadius: 0, boxShadow: "none" }}>
          <Table>
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Variable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {MESSAGE_VARIABLES.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>
                    <Typography sx={{ fontSize: "1rem" }}>{row.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <CopyButton textToCopy={row.key} withIcon={false}>
                      <Typography sx={{ fontSize: "1rem" }}>{row.key}</Typography>
                    </CopyButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailVariableTable;
