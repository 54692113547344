/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { feetToMeters } from "utils/helpers/conversion";
import { Button, Stack } from "@mui/material";
import { Save, LocationOn, AllOut } from "@mui/icons-material";

import colors from "assets/theme/base/colors";

import GenericModal from "components/GenericModal";
import CustomMap from "components/CustomMap";
import MDTypography from "components/MDTypography";

const defaultCenter = { lat: 53.54, lng: 10 };
const defaultRadius = 100;
const defaultGraceDistance = 100;

const MapModal = ({ open, setOpen, name, currentLocation, onSubmit, title }) => {
  const [isRadiusEditable, setRadiusEditable] = useState(false);
  const [isCenterEditable, setCenterEditable] = useState(false);
  const [isGDistanceEditable, setGDistanceEditable] = useState(false);
  const [initialCenter, setInitialCenter] = useState(null);
  const [center, setCenter] = useState(null);
  const [radius, setRadius] = useState(defaultRadius);
  const [graceDistance, setGraceDistance] = useState(null);

  const warnBtnStyle = {
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    ...(!isGDistanceEditable && {
      color: "#FFFFFF",
      backgroundColor: "#F7C501",
      "&:hover": {
        backgroundColor: "#F7C501",
      },
      "&:active": {
        backgroundColor: "#FFFFFF",
      },
    }),
    ...(isGDistanceEditable && {
      color: "#F7C501",
      backgroundColor: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      "&:active": {
        backgroundColor: "#F7C501",
      },
    }),
  };

  const handleVenueCenter = () => {
    setCenterEditable(!isCenterEditable);
    setRadiusEditable(false);
    setGDistanceEditable(false);
  };

  const handleUpdateRadius = () => {
    setRadiusEditable(!isRadiusEditable);
    setCenterEditable(false);
    setGDistanceEditable(false);
  };

  const handleUpdateGDistance = () => {
    setGDistanceEditable(!isGDistanceEditable);
    setCenterEditable(false);
    setRadiusEditable(false);
  };

  const reset = () => {
    setRadiusEditable(false);
    setCenterEditable(false);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    if (currentLocation?.longitude && currentLocation?.latitude) {
      setCenter({
        lng: Number(currentLocation?.longitude),
        lat: Number(currentLocation?.latitude),
      });
      setInitialCenter({
        lng: Number(currentLocation?.longitude),
        lat: Number(currentLocation?.latitude),
      });
    } else {
      setCenter(defaultCenter);
      setInitialCenter(defaultCenter);
    }

    if (currentLocation?.radius) setRadius(Math.abs(currentLocation?.radius));
    else setRadius(defaultRadius);

    if (currentLocation?.graceDistanceFeet)
      setGraceDistance(Math.abs(feetToMeters(currentLocation?.graceDistanceFeet)));
    else setGraceDistance(feetToMeters(defaultGraceDistance));
  }, [currentLocation]);

  const modalBody = (
    <Stack>
      <Stack direction="row" mb={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<LocationOn />}
            onClick={handleVenueCenter}
            color={isCenterEditable ? "white" : "primary"}
            sx={{
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              ...(isCenterEditable && { color: colors?.info?.main }),
            }}
          >
            {title} Center
          </Button>
          <Button
            variant="contained"
            startIcon={<AllOut />}
            color={isRadiusEditable ? "white" : "success"}
            sx={{
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              ...(isRadiusEditable && { color: colors?.success?.main }),
            }}
            onClick={handleUpdateRadius}
          >
            Update Radius
          </Button>
          <Button
            variant="contained"
            startIcon={<AllOut />}
            color={isGDistanceEditable ? "white" : "warning"}
            sx={warnBtnStyle}
            onClick={handleUpdateGDistance}
          >
            Grace Distance
          </Button>
        </Stack>
        <MDTypography variant="h6" align="right">
          {`${currentLocation?.address}, ${currentLocation?.city}, ${currentLocation?.state}, ${currentLocation?.zip}`}{" "}
          <br />
          lng: {center?.lng}, lat: {center?.lat}
        </MDTypography>
      </Stack>

      {center && (
        <CustomMap
          initialCenter={initialCenter}
          center={center}
          radius={radius}
          graceDistance={graceDistance}
          setCenter={setCenter}
          setRadius={setRadius}
          setGraceDistance={setGraceDistance}
          isCenterEditable={isCenterEditable}
          isRadiusEditable={isRadiusEditable}
          isGDistanceEditable={isGDistanceEditable}
          onMarkerClick={handleVenueCenter}
          onCircleClick={handleUpdateRadius}
          onGDClick={handleUpdateGDistance}
        />
      )}
    </Stack>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClose}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<Save />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
        onClick={() => {
          onSubmit({ center, radius, graceDistance });
          reset();
        }}
      >
        Save
      </Button>
    </>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      header={`${title} Map: ${name} - ${currentLocation?.locationName}`}
      body={modalBody}
      buttons={modalButtons}
      width="65%"
      borderRadius="4px"
    />
  );
};

export default MapModal;
