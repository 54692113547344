import axios from "axios";

import { baseAxios } from "config";

const updateEvent = async ({ eventId, data }) => {
  try {
    const res = await baseAxios.put(`/events/${eventId}`, data);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateEvent;
