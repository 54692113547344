import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, IconButton, TextField, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import useDropdowns from "hooks/useDropdowns";
import { useState } from "react";
import { tagsParser } from "utils/helpers/tagsParser";

// import tagOptions from "assets/tagOptions.json";

const TagListSearch = ({ searchTagList, setSearchTagList, filters, setFilters }) => {
  const [inputValue, setInputValue] = useState("");
  const { dropdowns: tagOptions } = useDropdowns({ entity: "tagOptions" });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleAddTag = (v) => {
    const correctTag = tagOptions.find((tag) => {
      return tag?.toLocaleLowerCase() === v?.toLocaleLowerCase();
    });

    if (correctTag) {
      setSearchTagList([...searchTagList, correctTag]);
      setFilters({ ...filters, tags: tagsParser([...searchTagList, correctTag]) });
      setInputValue("");
    }
  };

  const tagsToSelect = tagOptions?.filter((item) => !searchTagList?.includes(item));

  return (
    <MDBox display="flex">
      <MDBox sx={{ width: isSmallScreen ? "100%" : 300, position: "relative" }} pr={2}>
        <MDBox
          sx={{
            p: "2px 4px !important",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Autocomplete
            key="autocomplete_TagListSearch"
            options={tagsToSelect || []}
            sx={{ width: "100%" }}
            disableClearable
            forcePopupIcon={false}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder="Add Tag"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    value: inputValue,
                  }}
                  // eslint-disable-next-line
                  InputProps={{
                    ...params.InputProps,
                    value: inputValue,
                    endAdornment: (
                      <IconButton position="end">
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              );
            }}
            onChange={(e, v) => handleAddTag(v)}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default TagListSearch;
