import { useState } from "react";
import { useAppContextController } from "context/AppContext";

import { styled, Divider, IconButton, Grid, Icon, TextField, Tooltip, Box } from "@mui/material";
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";

import ImagePreviewModal from "layouts/pages/companies/components/ImagePreviewModal";
import { getCommonBaseImageUrl } from "utils/helpers/upload";

const AttachmentFiles = ({
  isShownLabel = true,
  files,
  company,
  onDelete,
  onRename,
  onConfirmRename,
  onCancelRename,
  isVenue = false,
  isJobs = false,
  isIssue = false,
  pageType
}) => {
  const { company: _company, currentJob, currentIssue } = useAppContextController();
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  // const page = "jobs"; // isVenue ? "venues" : "company";
  let page;
  if (isJobs) page = "jobs";
  else if (pageType) {
    switch (pageType) {
      case "Customer":
        page = "company"

        break;
      case "Venue":
        page = "venues"

        break;
      case "Partner":
        page = "partners"

        break;

      default:
        page = "Unknown"

        break;
    }
  }
  else { page = isVenue ? "venues" : "company"; }


  const IMAGE_SERVER = isVenue ? _company?.imageUrl : company?.imageUrl;

  let filePath;
  if (isJobs) {
    if (isVenue)
      filePath = `${IMAGE_SERVER}/${currentJob?.venueSlug}/${page}/${currentJob?.jobSlug}`;
    else filePath = `${IMAGE_SERVER}/${currentJob?.jobSlug}/${page}/other`;
  }
  else if (isIssue) {
    filePath = `${IMAGE_SERVER}/issues/${currentIssue.category}/${currentIssue._id}`;
  }
  else {
    filePath = `${IMAGE_SERVER}/${company?.slug}/${page}/other`;
  }

  let typeOfPreview;
  if (isJobs) {
    typeOfPreview = "companyjobs";
  } else if (isIssue) {
    typeOfPreview = "issue";
  } else {
    typeOfPreview = "company";
  }


  return (
    <>
      <MDBox width="100%">
        {isShownLabel && (
          <>
            <StyledDivider />
            <FlexWrapper justifyContent="start" width="100%">
              <MDTypography
                variant="body2"
                fontWeight="bold"
                color="info"
                fontSize={14}
                width="100%"
              >
                ADDITIONAL ATTACHMENTS
              </MDTypography>
            </FlexWrapper>
          </>
        )}

        <StyledImageContainer>
          {files.map((file, idx) => {
            const fileNameWithoutType = file?.filename
              ? file?.filename?.substring(0, file?.filename?.lastIndexOf("."))
              : file?.name?.substring(0, file?.name?.lastIndexOf("."));
            // const fileNameWithoutSpace = file?.filename?.replace(/ /g, "%");
            return (
              <StyledImageBox key={file?.filename || file?.name}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <StyledRemoveBtn
                    color="info"
                    onClick={(e) => onDelete(e, idx, fileNameWithoutType)}
                  >
                    <Icon fontSize="small">cancel</Icon>
                  </StyledRemoveBtn>
                  <Grid>
                    {["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg"].includes(
                      file?.docType?.toLowerCase()
                    ) && (
                        <MDBox
                          onClick={() => {
                            setPreviewFile(file);
                            setImagePreviewOpen(true);
                          }}
                        >
                          <img
                            src={`${filePath}/${file?.filename || file?.name}`}
                            alt={file.filename}
                            height={100}
                            width={100}
                          />
                        </MDBox>
                      )}
                    {file?.docType?.toLowerCase() === "pdf" && (
                      <MDBox
                        onClick={() => {
                          window.open(`${filePath}/${file?.filename || file?.name}`);
                        }}
                      >
                        <StyledImageDocs
                          component="img"
                          src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                          alt="preview"
                        />
                      </MDBox>
                    )}
                    {["docx", "rtf"].includes(file?.docType?.toLowerCase()) && (
                      <MDBox
                        onClick={() => {
                          window.open(`${filePath}/${file?.filename || file?.name}`);
                        }}
                      >
                        <StyledImageDocs
                          component="img"
                          src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                          alt="preview"
                        />
                      </MDBox>
                    )}
                  </Grid>
                  <MDBox display="flex">
                    <TextField
                      size="small"
                      defaultValue={fileNameWithoutType}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={files[idx].editedName?.substring(
                        0,
                        file?.editedName?.lastIndexOf(".")
                      )}
                      onInput={(e) => onRename(e, idx)}
                    />
                    {file?.isEdited && (
                      <>
                        <Tooltip title="Confirm" placement="bottom">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={(e) => onConfirmRename(e, idx)}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel" placement="bottom">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={(e) => onCancelRename(e, idx)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </MDBox>
                </Grid>
              </StyledImageBox>
            );
          })}
        </StyledImageContainer>
      </MDBox>
      {imagePreviewOpen && (
        <ImagePreviewModal
          imagePreviewOpen={imagePreviewOpen}
          setImagePreviewOpen={setImagePreviewOpen}
          currentIssue={currentIssue}
          currentEvent={isJobs ? currentJob : company}
          previewFile={previewFile}
          typeOfPreview={typeOfPreview}
        />
      )}
    </>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  opacity: 1,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  margin: "2rem 0 0.5rem 0",
}));

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: 1,
  overflowX: "auto",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  padding: "1rem 0",
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  margin: "0px 8px",
  cursor: "pointer",
}));

const StyledRemoveBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "-15px",
  top: "-15px",
  backgroundColor: "white",
}));

const StyledImageDocs = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
}));

export default AttachmentFiles;
