import { baseAxios } from "config";
// import createApiParams from "utils/createApiParams";

const fetchSingleCustomer = async ({
  slug,
  id, 
  outsideMode = ""
}) => {
  try {
    const outsideModeQuery = outsideMode ? `outside-${outsideMode}/` : "";

    const url = slug ? `/${outsideModeQuery}companies/slug/${slug}` : `/companies/${id}`;
    const res = await baseAxios.get(url);

    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchSingleCustomer;
