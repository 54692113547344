import { useState } from "react";

import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

import GenericModal from "components/GenericModal";
import Loading from "components/Loading";

const FilterModal = ({ title, data, currentFilters, isModalOpen, setModalOpen, onSubmit }) => {
  const [state, setState] = useState(currentFilters);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.value]: event.target.checked,
    });
  };

  const modalBody = (
    <Grid container>
      {data ? (
        data?.map(({ label, value }, index) => (
          <Grid item xs={12} key={`${index.toString() + value}modal_body_grid_item`}>
            <FormControlLabel
              control={
                <Checkbox value={value} checked={state[value] || false} onChange={handleChange} />
              }
              label={label}
            />
          </Grid>
        ))
      ) : (
        <Loading />
      )}
    </Grid>
  );

  const modalButtons = (
    <>
      {JSON.stringify(state) !== "{}" && (
        <Button
          variant="text"
          color="error"
          onClick={() => setState({})}
          sx={{ padding: "0.5rem 1rem" }}
        >
          Clear
        </Button>
      )}
      <Button
        variant="text"
        startIcon={<CancelIcon />}
        onClick={() => setModalOpen(false)}
        sx={{ padding: "0.5rem 1rem" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        endIcon={<SaveIcon />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem" }}
        onClick={() => {
          onSubmit(state);
          setModalOpen(false);
        }}
      >
        OK
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={isModalOpen}
        setOpen={setModalOpen}
        header={title}
        body={modalBody}
        buttons={modalButtons}
        width="20rem"
      />
    </>
  );
};

export default FilterModal;
