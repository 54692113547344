import { Button, Card, Fade, Grid, Modal, Radio, Stack, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import VenueIcon from "components/VenueIcon";
import { PersonOff, Save } from "@mui/icons-material";
import { useQuery } from "react-query";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import fetchCustomers from "api/companies/fetchCustomers";
import useSessionAuth from "hooks/useSessionAuth";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import { JOB_STATUS_ENUM } from "utils/constants/applicant";
import JobStatusButton from "components/JobStatusButton";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "40%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
});
const JobSelectionModal = ({ open, setOpen, onSubmitAction, alreadyOnHelm }) => {
  const classes = useStyle();
  const [selectedCompanySlug, setSelectedCompanySlug] = useState(null);
  const [selectedJobSlug, setSelectedJobSlug] = useState(null);
  const { currentApplicant, venues, company } = useAppContextController();
  const { logoutAuth0User } = useSessionAuth();

  const [customerData, setCustomerData] = useState({});
  const isCompany = company?.companyType === "Company";
  const allOptions = {
    fetchAll: true,
    filters: { status: "Active" },
    venues,
  };

  const {
    data: jobs,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["jobsForSelectionModal", allOptions],
    () => (isCompany ? fetchCompanyJob(allOptions) : fetchJobs(allOptions)),
    isCompany && {
      select: (data) => {
        if (data?.data == null) return data;
        const newData = data.data.map((jb) => {
          let isJobFull = false;
          if (jb.positions != null) {
            const openPositions = jb.positions.filter((pos) => pos.status === "Open").length;
            isJobFull = openPositions <= 0;
          }

          return {
            ...jb,
            isJobFull,
          };
        });
        return {
          ...data,
          data: newData,
        };
      },
      onSuccess: async (data) => {
        // Assuming that data is an array of interviews
        const customers = {};

        try {
          // Get all company slugs
          let companySlugs = data?.data?.map((comp) => comp.companySlug);
          // Remove duplicates
          companySlugs = [...new Set(companySlugs)];

          const results = await fetchCustomers({
            filters: { slug: companySlugs.join(";") },
            page: 1,
            limit: 0,
          });

          (results?.data ?? []).forEach((cstmr) => {
            customers[cstmr.slug] = cstmr;
          });
          setCustomerData(customers);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      },
    }
  );

  const allJobs = Object.values(jobs?.data || []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, jobSlug) => {
    setSelectedCompanySlug(event.target.value);
    setSelectedJobSlug(jobSlug);
  };

  const renderRow = (row) => {
    const rowCompany =
      customerData[row.companySlug] && isCompany ? customerData[row.companySlug] : {};
    const status = getApplicantJobStatus(currentApplicant, row.jobSlug);

    return (
      <>
        <MDBox
          key={`${row.name}_${row.jobSlug}`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            opacity: row.isJobFull && status === JOB_STATUS_ENUM.PENDING ? 0.4 : 1,
            cursor: row.isJobFull && status === JOB_STATUS_ENUM.PENDING ? "not-allowed" : "pointer",
          }}
        >
          <Radio
            disabled={row.isJobFull && status === JOB_STATUS_ENUM.PENDING}
            checked={selectedCompanySlug === row.companySlug}
            onChange={(event) => handleChange(event, row.jobSlug)}
            value={row.companySlug}
            name="job-selection-radio-buttons"
          />
          <Grid item xs={12} sm={2} lg={1.5}>
            {isCompany ? (
              <CompanyLogoSquare company={rowCompany} defaultCompany={company} />
            ) : (
              <VenueIcon
                logoUrl={row.logoUrl}
                slug={row.jobSlug}
                name={row.venueName}
                goToEnabled={false}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={isCompany ? 3.5 : 4.5} lg={isCompany ? 4 : 5}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row.title}
                  {row.status === "Inactive" && (
                    <Tooltip title="Inactive">
                      <PersonOff
                        fontSize="small"
                        color="error"
                        sx={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </Tooltip>
                  )}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  {isCompany ? row.companyCity : row.venueCity},{" "}
                  {isCompany ? row.companyState : row.venueState}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={isCompany ? 5.5 : 4.5} lg={isCompany ? 5.5 : 4.5}>
            <MDTypography variant="body3">
              {isCompany ? row.companyName : row.venueName}
            </MDTypography>
            {row.isJobFull && status === JOB_STATUS_ENUM.PENDING && (
              <MDTypography variant="body3" color="error" ml={2}>
                (No position available)
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={1} lg={1}>
            <Stack direction="row" justifyContent="right">
              <JobStatusButton status={status} job={row} viewOnly />
            </Stack>
          </Grid>
        </MDBox>
      </>
    );
  };

  const filteredJobs = useMemo(() => {
    return allJobs
      .filter((item) => {
        const status = getApplicantJobStatus(currentApplicant, item.jobSlug);
        return status === JOB_STATUS_ENUM.PENDING || status === JOB_STATUS_ENUM.ON_ASSIGNMENT;
      })
      .sort((itemA, itemB) => {
        if (itemA.title > itemB.title) return 1;
        if (itemA.title < itemB.title) return -1;
        return 0;
      });
  }, [allJobs, currentApplicant]);

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Card className={classes.container}>
          <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 1, ml: 2 }}>
            <MDTypography variant="h5" color="dark">
              Select a Job
            </MDTypography>
            {alreadyOnHelm && (
              <MDTypography variant="h5" color="error">
                (Record already exists on Helm)
              </MDTypography>
            )}
          </Stack>
          <MDTypography variant="h6" color="dark" sx={{ mb: 1, ml: 2 }}>
            Please select which Job this applicant will be assigned to.
          </MDTypography>
          <DataList
            renderRow={renderRow}
            data={filteredJobs}
            isLoading={isFetching}
            greybar
            header={null}
            tableHeight={400}
            height={450}
            scrollY
          />
          <Grid container spacing={2}>
            <Grid item xs={12} marginY={3} mr={3} textAlign="end">
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(false)}
                  sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selectedCompanySlug}
                  type="submit"
                  variant="contained"
                  startIcon={<Save />}
                  style={{ color: "white" }}
                  sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
                  onClick={() => {
                    onSubmitAction(selectedCompanySlug, true, selectedJobSlug);
                    setOpen(false);
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </Modal>
  );
};

export default JobSelectionModal;
