import React, { useMemo } from "react";

import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEventContext } from "context/EventContext";
import "./ApplicantsTable.css";
import { useAppContextController } from "context/AppContext";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import ApplicantStatus from "components/ApplicantStatus";

export const ApplicantsTable = ({
  selectedRecipients,
  setSelectedRecipients,
  applicants = [],
  isLoading,
  jobSlug,
}) => {
  let { eventRoster } = useEventContext();
  const { currentEvent, currentVenue, company } = useAppContextController();
  // console.log(applicants);
  if (applicants.length) {
    eventRoster = applicants;
  }
  const columns = useMemo(
    () => [
      { headerName: "Status", field: "status", flex: 0.5 },
      {
        headerName: "Applicant Status",
        field: "applicantStatus",
        flex: 0.5,
        renderCell: ({ value, row }) => {
          if (company?.companyType === "Company" && jobSlug && row.jobs != null) {
            const foundJob = (row.jobs ?? []).find((applJob) => applJob.jobSlug === jobSlug);
            if (foundJob && foundJob.applicantStatus) {
              return (
                <ApplicantStatus
                  applicant={{ ...row, applicantStatus: foundJob.applicantStatus }}
                  size="lg"
                />
              );
            }
          }
          return <ApplicantStatus applicant={row} size="lg" />;
        },
      },
      {
        headerName: "Last Name",
        field: "lastName",
        flex: 0.5,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "First Name",
        field: "firstName",
        flex: 0.5,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "Phone",
        field: "phone",
        flex: 0.5,
        renderCell: ({ value }) =>
          value && value !== "" ? (
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          ) : null,
      },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        renderCell: ({ value }) =>
          value && value !== "" ? (
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          ) : null,
      },
    ],
    []
  );

  const parsedRows = useMemo(
    () =>
      eventRoster.map((item) => {
        const venueSlug = currentEvent?.venueSlug || currentVenue?.slug;
        const venueStatus = getApplicantVenueStatus(item, venueSlug);

        return {
          ...item,
          id: item._id,
          venueStatus,
          enabled: true,
        };
      }),
    [eventRoster]
  );

  const handleSelectionChange = (newSelection) => {
    setSelectedRecipients(newSelection);
    // console.log("Selected IDs:", newSelection);
  };

  return (
    <Box
      sx={{
        maxHeight: "350px",
        height: "350px",
        overflow: "scroll",
        width: "100%",
      }}
    >
      <DataGrid
        rows={parsedRows}
        columns={columns}
        checkboxSelection
        selectionModel={selectedRecipients}
        onSelectionModelChange={handleSelectionChange}
        disableSelectionOnClick
        isRowSelectable={(params) => {
          return params?.row?.enabled;
        }} // Check if params.data is defined
        getRowClassName={(params) => {
          if (selectedRecipients.includes(params?.row?._id)) {
            return "row-selected";
          }
          return "";
        }}
        loading={isLoading}
      />
    </Box>
  );
};
