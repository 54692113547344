import { useEffect, useState, useCallback } from "react";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import useSessionAuth from "hooks/useSessionAuth";
import MySavedReportsTable from "./components/MySavedReportsTable";
import ReportBuilder from "./components/ReportBuilder";
import ReportsTable from "./components/ReportsTable";
import ActiveNumbers from "./components/ActiveNumbers";

function Reports({ mode = "edit" }) {
  const navigate = useNavigate();

  const { userType, currentReport, setCurrentReport, venues } = useAppContextController();
  const [applicant, setApplicant] = useState({});
  const { id, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [page, setPage] = useState(1);
  const { logoutAuth0User } = useSessionAuth();

  const [filters, setFilters] = useState({});

  useEffect(() => {
    const getApplicant = async () => {
      const appl = await fetchSingleApplicant({ applicantId: currentReport?.applicantId });
      if (appl) setApplicant(appl.data);
    };
    if (currentReport?.applicantId) {
      try {
        getApplicant();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [userType, currentReport?.applicantId]);

  const getReportAndSetCurrentReport = useCallback(async () => {
    // TODO: uncomment this
    // setFilters({ _id: selectedId });
    // const reports = await fetchReportById(selectedId);
    // if (reports && reports?.data?.length) setCurrentReport(reports?.data[0]);
  }, [selectedId]);

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    if (id && !filters._id && action) {
      setFilters({ _id: id });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }

    if (selectedId && (!currentReport || currentReport._id !== selectedId)) {
      getReportAndSetCurrentReport();
    }
  }, [selectedId, action, actionSelected, currentReport, getReportAndSetCurrentReport]);

  // Default Filter Card
  // TODO: Implement this
  // useEffect(() => {
  //   if (isEmpty(filters) || (!id && filters._id)) {
  //     const { filterField, filterValue } = initialCards.find((card) => card.default);
  //     if (filterField && filterValue) {
  //       setFilters(() => ({ [filterField]: filterValue }));
  //     }
  //   }
  // }, [filters, id]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <ActiveNumbers />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MySavedReportsTable />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReportBuilder />
        </Grid>

        {/* {["reportinfo", "reportall", ""].includes(actionSelected) && (id || mode === "create") && ( */}
        <Grid item xs={12} sm={12}>
          <ReportsTable />
        </Grid>
        {/* )} */}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Reports.defaultProps = {
  mode: "edit",
};

// Typechecking props
Reports.propTypes = {
  mode: PropTypes.string,
};
export default Reports;
