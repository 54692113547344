// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import BadgeIcon from "@mui/icons-material/Badge";
// import ChatIcon from '@mui/icons-material/Chat';

const CompanyTableActions = ({ id, row, setCurrentCompany, setGenericModalOpen, setModalInfo }) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    navigate(`/companies/${id}/action/${action}`);
    setCurrentCompany(row);
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("companyinfo", e)}
        tooltip="Company Info"
      />
      &nbsp;
      {/* <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickHandler("companyjobs", e)}
        tooltip="Company Jobs"
      />
      &nbsp; */}
      <TableAction
        color="secondary"
        icon="movie_filter"
        clickAction={(e) => onClickHandler("companymedia", e)}
        tooltip="Company Media"
      />
      &nbsp;
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("companyall", e)}
        tooltip="All Company Panels"
      />
      &nbsp;&nbsp; */}
    </div>
  );
};

export default CompanyTableActions;

CompanyTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
CompanyTableActions.propTypes = {
  id: PropTypes.string,
};
