import { Routes, Route, Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
  const navigate = useNavigate();
  if (!isAllowed) {
    return <Navigate to={redirectPath} />;
  }
  // if (!isAllowed) {
  //   navigate(redirectPath);
  //   return <></>;
  // }

  return children || <Outlet />;
};

export default ProtectedRoute;
