// import React from "react";
import { Card, Fade, Modal } from "@mui/material";
import ActivitiesInformation from "layouts/pages/activities/components/ActivitiesDetails";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const ActivitiesDetailModal = ({
  activity,
  detailActivityModalOpen,
  setDetailActivityModalOpen,
}) => {
  const classes = useStyle();
  const handleClose = () => setDetailActivityModalOpen(false);
  return (
    activity && (
      <Modal
        open={detailActivityModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={detailActivityModalOpen}>
          <Card className={classes.container}>
            <ActivitiesInformation activity={activity} onModalClose={handleClose} />
          </Card>
        </Fade>
      </Modal>
    )
  );
};

export default ActivitiesDetailModal;
