import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import MDBox from "components/MDBox";
import GenericModal from "components/GenericModal";
import ActivitiesInformation from "layouts/pages/activities/components/ActivitiesDetails";

const ProfileActivityModal = ({ isModalOpen, setModalOpen }) => {
  const modalBody = (
    <MDBox sx={{ pt: 4, mb: -2 }}>
      <IconButton
        onClick={() => setModalOpen(false)}
        sx={{ position: "absolute", top: "15px", right: "15px" }}
      >
        <CancelIcon />
      </IconButton>

      <ActivitiesInformation displayExtraDetails />
    </MDBox>
  );

  return (
    <>
      <GenericModal
        open={isModalOpen}
        setOpen={setModalOpen}
        body={modalBody}
        width="50%"
        borderRadius="4px"
      />
    </>
  );
};

export default ProfileActivityModal;
