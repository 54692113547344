import { Button, Card, Fade, Grid, Modal, Radio, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import { APPLICANT_VENUE_STATUS_ENUM } from "utils/constants/applicant";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import VenueIcon from "components/VenueIcon";
import { Save } from "@mui/icons-material";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
});
const VenueSelectionModal = ({ open, setOpen, onSubmitAction, alreadyOnHelm }) => {
  const classes = useStyle();
  const [selectedVenue, setSelectedVenue] = useState(null);
  const { currentApplicant, venues } = useAppContextController();

  const allVenues = venues ? Object.values(venues) : [];

  // const applicantVenues = ven
  const filteredVenues = useMemo(() => {
    return allVenues?.filter(
      (item) =>
        getApplicantVenueStatus(currentApplicant, item.slug) === APPLICANT_VENUE_STATUS_ENUM.PENDING
    );
  }, [allVenues, currentApplicant]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedVenue(event.target.value);
  };

  const renderRow = (row) => {
    return (
      <>
        <MDBox key={`${row.name}_${row.slug}`} display="flex" justifyContent="space-between">
          <Radio
            checked={selectedVenue === row.slug}
            onChange={handleChange}
            value={row.slug}
            name="venue-selection-radio-buttons"
          />
          <Grid item xs={12} sm={2} lg={1.5}>
            <VenueIcon logoUrl={row.logoUrl} slug={row.slug} name={row.name} goToEnabled={false} />
          </Grid>
          <Grid item xs={12} sm={6} lg={8.5}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row.name}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  {row.city}, {row.state}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Card className={classes.container}>
          <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 1, ml: 2 }}>
            <MDTypography variant="h5" color="dark">
              Select a Venue
            </MDTypography>
            {alreadyOnHelm && (
              <MDTypography variant="h5" color="error">
                (Record already exists on Helm)
              </MDTypography>
            )}
          </Stack>
          <MDTypography variant="h6" color="dark" sx={{ mb: 1, ml: 2 }}>
            Please select which Venue Staffing Pool this applicant will be assigned to.
          </MDTypography>
          <DataList
            renderRow={renderRow}
            data={filteredVenues}
            isLoading={false}
            greybar
            header={null}
            tableHeight={400}
            height={450}
            scrollY
          />
          <Grid container spacing={2}>
            <Grid item xs={12} marginY={3} mr={3} textAlign="end">
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(false)}
                  sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selectedVenue}
                  type="submit"
                  variant="contained"
                  startIcon={<Save />}
                  style={{ color: "white" }}
                  sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
                  onClick={() => {
                    onSubmitAction(selectedVenue, true);
                    setOpen(false);
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </Modal>
  );
};

export default VenueSelectionModal;
