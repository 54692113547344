import axios from "axios";
import { baseAxios } from "config";

const renameFileVenue = async ({ rename, original, venueSlug }) => {
  try {
    const url = `/upload/rename/${venueSlug}/venues/other?rename=${encodeURIComponent(
      rename
    )}&original=${encodeURIComponent(original)}`;
    const res = await baseAxios.patch(url);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default renameFileVenue;
