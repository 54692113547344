import axios from "axios";

import { baseAxios } from "config";
// import createApiParams from "utils/createApiParams";

const fetchSingleApplicant = async ({ applicantId }) => {
  try {
    if (!applicantId) return null;
    const res = await baseAxios.get(`/applicants/${applicantId}`);

    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchSingleApplicant;
