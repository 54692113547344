import axios from "axios";
import { baseAxios } from "config";

const checkUserEvent = async ({ eventUrl, applicantId }) => {
  try {
    const res = await baseAxios.get(`/events/url/${eventUrl}/enroll/${applicantId}`);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return null;
    throw new Error(err.toString());
  }
};

export default checkUserEvent;
