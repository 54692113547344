// import {useState} from "react";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// import ChatIcon from '@mui/icons-material/Chat';

const MySavedReportsTableActions = ({
  id,
  row,
  setCurrentTemplate,
  toggleEditModal,
  toggleConfirmationModal,
  setContext,
}) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    // setCurrentTemplate(row);
    // switch (action) {
    //     case "templateinfo":
    //         if (row?.context) {
    //             setContext(row.context);
    //         } else {
    //             setContext(["Applicant"]);
    //         }
    //         toggleEditModal(true);
    //         break;
    //     case "templatedelete":
    //         toggleConfirmationModal(true);
    //         break;
    //     default:
    //         break;
    // }
    // e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="error"
        icon="delete"
        clickAction={(e) => onClickHandler("templatedelete", e)}
        tooltip={row?.isSystem === "Yes" ? "" : "Delete Message Template"}
        disabled={row?.isSystem === "Yes"}
      />
    </div>
  );
};

export default MySavedReportsTableActions;

MySavedReportsTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
MySavedReportsTableActions.propTypes = {
  id: PropTypes.string,
};
