import { useCallback, useReducer } from "react";
import eventReducer, { eventInitialState } from "reducers/eventReducer";

const useEvent = () => {
  const [state, dispatch] = useReducer(eventReducer, eventInitialState);
  const setEvent = useCallback((event) => {
    dispatch({ type: "SET_EVENT", data: event });
  });

  const setEventRoster = useCallback((data) => {
    dispatch({ type: "SET_EVENT_ROSTER", data });
  });

  const setRefetchEventsTable = useCallback((refetch) => {
    dispatch({ type: "SET_REFETCH_EVENTS_TABLE", data: refetch });
  });

  const setEvents = useCallback((events) => {
    dispatch({ type: "SET_EVENTS", data: events });
  });

  const setEventsTableFilter = useCallback((events) => {
    dispatch({ type: "SET_EVENTS_TABLE_FILTERS", data: events });
  });

  return {
    ...state,
    setEvent,
    setEventRoster,
    setRefetchEventsTable,
    setEvents,
    setEventsTableFilter
  };
};

export default useEvent;
