import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import useCompanyStore from "stores/useCompanyStore";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import updateCompany from "api/companies/updateCompany";
import { isEmpty } from "lodash";
import CustomCard from "components/CustomCard";
import { Settings } from "@mui/icons-material";
import CompanyConfiguration from "layouts/pages/companies/components/CompanyConfiguration";
import PanelActions from "components/PanelActions";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import { customerSchema } from "data/customer";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";
import useDropdowns from "hooks/useDropdowns";
import { getDirtyFields } from "utils/helpers/formHelpers";

const {
  WORKWEEK_ENTITY_NAME,
  OVERTIME_WARNINGS_ENTITY_NAME,
  CLOCKOUT_REMINDERS_ENTITY_NAME,
  APPROVAL_REMINDERS_ENTITY_NAME,
  STATES_ENTITY_NAME,
  PAY_FREQUENCY,
  GROUP_IDS,
  WC_CODES,
} = COMPANY_ENTITIES;

const CustomerSettings = () => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));
  const { dropdowns: workweek } = useDropdowns({ entity: WORKWEEK_ENTITY_NAME });
  const { dropdowns: overtimeWarnings } = useDropdowns({ entity: OVERTIME_WARNINGS_ENTITY_NAME });
  const { dropdowns: clockoutReminders } = useDropdowns({ entity: CLOCKOUT_REMINDERS_ENTITY_NAME });
  const { dropdowns: approvalReminders } = useDropdowns({ entity: APPROVAL_REMINDERS_ENTITY_NAME });
  const { dropdowns: states } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const { dropdowns: payFrequencyValues } = useDropdowns({ entity: PAY_FREQUENCY });
  const { dropdowns: groupIds } = useDropdowns({ entity: GROUP_IDS });
  const { dropdowns: wcCodes } = useDropdowns({ entity: WC_CODES });

  const { isMobile, setCurrentFormDirty, changePanel, company, setCompany } =
    useAppContextController();
  const validationSchema = customerSchema(
    workweek,
    overtimeWarnings,
    clockoutReminders,
    approvalReminders,
    states,
    payFrequencyValues,
    groupIds,
    wcCodes
  );
  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    errors,
    formState: { isDirty, dirtyFields },
  } = useForm({ resolver });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);

  const handleUpdateCompany = (updatedValues) => {
    updateCompanyMutation(
      { companyId: currentCompany?._id, body: updatedValues },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("companies");
          setCurrentCompany({ ...currentCompany, ...updatedValues });
          if (currentCompany?.primaryCompany) {
            setCompany({ ...company, ...updatedValues });
          }
          enqueueSnackbar("Customer has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };

  const onSubmit = (data) => {
    const values = getDirtyFields(data, dirtyFields);
    handleUpdateCompany(values);
  };

  const handleCancelChanges = () => reset(currentCompany);

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  useEffect(() => {
    reset(currentCompany);
  }, [currentCompany]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Settings color="white" />}
        iconContainerSx={{ backgroundColor: "info" }}
        cardTitle={`Settings: ${currentCompany?.name}`}
        cardSx={{ overflow: "visible!important", borderRadius: 0, height: 850 }}
        cardActions={
          isDirty && !isEmpty(dirtyFields) && <PanelActions onCancel={handleCancelChanges} />
        }
        actionsSx={isMobile ? {} : { mt: -4 }}
      >
        <CompanyConfiguration
          control={control}
          sourceComponent="Customer"
          errors={errors}
          setValue={setValue}
        />
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default CustomerSettings;
