import { useMemo } from "react";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import AIInterviewChatWindowMessage from "./components/AIInterviewChatWindowMessage";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    maxWidth: 1170,
    padding: "2rem",
  },
};

const AIInterviewChatWindow = ({
  firstName,
  lastName,
  items,
  inverted,
  interviewFinished,
  onPressUpdateInfo,
  onPressBackToHome,
  listRef,
}) => {
  const { company } = useAppContextController();
  const processedItems = useMemo(() => {
    const newItems = [];
    const oldItems = (items ?? []).sort((item) => item.index);

    let lastDate = null;

    for (let index = 0; index < oldItems.length; index += 1) {
      const item = oldItems[index];
      const currentDate = item.timestamp;
      const currentDateMoment = moment(currentDate);

      // Add date label when necessary
      if (lastDate == null || !currentDateMoment.isSame(lastDate, "day")) {
        // Add first date label
        if (currentDateMoment.isSame(new Date(), "day")) {
          newItems.push({
            isDateLabel: true,
            message: "TODAY",
          });
        } else {
          newItems.push({
            isDateLabel: true,
            message: currentDateMoment.format("LLLL"),
          });
        }
        lastDate = currentDateMoment;
      }

      // Add item
      newItems.push({
        isDateLabel: false,
        isAnswer: item.isAnswer,
        timeString: currentDateMoment.format("LTS"),
        message: item.message,
      });
    }

    return newItems;
  }, [items]);

  return (
    <Grid
      container
      item
      flexDirection="column"
      overflow="auto"
      height="100%"
      flexWrap="nowrap"
      pb={2}
      ref={listRef}
    >
      {processedItems.map((item, index) => {
        if (item.isDateLabel) {
          return (
            <MDTypography
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              component="label"
              fontWeight="medium"
              color="dark"
              sx={{ fontSize: "0.74rem", textAlign: "center", margin: "4px 0", pt: 2 }}
            >
              {item.message}
            </MDTypography>
          );
        }
        return (
          <AIInterviewChatWindowMessage
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            right={inverted ? item.isAnswer : !item.isAnswer}
            isBot={!item.isAnswer}
            isResponse={inverted ? !item.isAnswer : item.isAnswer}
            message={item.message}
            firstName={firstName}
            lastName={lastName}
            includeLinks={
              index === processedItems.length - 1 && !item.isAnswer && interviewFinished
            }
            company={company}
            onPressUpdateInfo={onPressUpdateInfo}
            onPressBackToHome={onPressBackToHome}
          />
        );
      })}
    </Grid>
  );
};

// Setting default values for the props of CalendarDayModal
AIInterviewChatWindow.defaultProps = {};

// Typechecking props of the CalendarDayModal
AIInterviewChatWindow.propTypes = {};

export default AIInterviewChatWindow;
