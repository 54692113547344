import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import omit from "lodash/omit";
import has from "lodash/has";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";

export const initialApplicantStatusSubFilterCards = [
  {
    icon: "check_circle_outline",
    title: "Docs Complete",
    color: "success",
    tooltip: "All required documents are complete",
    number: 0,
    filterField: "onboardingDocsComplete.complete",
    filterValue: "Yes",
  },
  {
    icon: "error",
    title: "Missing Docs",
    color: "error",
    tooltip: "Some documents are missing",
    number: 0,
    filterField: "onboardingDocsComplete.complete",
    filterValue: "No",
  },
  {
    icon: "info",
    title: "Acknowledged",
    color: "warning",
    tooltip: "Acknowledgement exists",
    number: 0,
    filterField: "acknowledged.signature",
    filterValue: "signatureFile.png",
  },

  {
    icon: "task",
    title: "BG Checked",
    color: "success",
    tooltip: "Background checked",
    number: 0,
    filterField: "attachments.type",
    filterValue: "Background Check Result",
  },
  {
    icon: "pending",
    color: "warning",
    title: "BG Pending",
    tooltip: "Background check pending",
    number: 0,
    filterField: "attachments.type",
    filterValue: "!!Background Check Result",
  },
];

const ApplicantStatusSubFilters = ({ setFilters, setPage, filters, baseFilters }) => {
  const { setCurrentApplicant, company } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const initialCards = useMemo(() => {
    return [
      {
        icon: "assignment",
        title: "Applied",
        color: "info",
        tooltip: "Applicants Applied",
        number: 0,
        filterField: "applicantStatus",
        filterValue: filters.applicantStatus ?? "",
        default: true,
      },
      ...initialApplicantStatusSubFilterCards,
    ];
  }, [filters]);

  const { cards } = useBadgeValues({
    initialCards,
    filters: { ...(baseFilters ?? {}), ...filters },
    entity: "applicants",
    refreshBadges,
  });

  const navigate = useNavigate();

  const setApplicantFilters = (filter) => {
    navigate("/applicants");
    setCurrentApplicant(null);
    const newFilters = omit(
      filters,
      has(filter, "systemSource") ? "referralSource" : "systemSource"
    );
    delete newFilters._id;
    setFilters({
      ...newFilters,
      ...(filters?.tags ? { tags: filters.tags } : filter),
    });
    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  const initialIndex = cards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setApplicantFilters}
        setPage={setPage}
        initialIndex={initialIndex || 0}
      />
    </span>
  );
};

export default ApplicantStatusSubFilters;

ApplicantStatusSubFilters.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

ApplicantStatusSubFilters.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
};
