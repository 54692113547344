import { Box, styled } from "@mui/material";
import { useEffect } from "react";

import parse from "html-react-parser";
import MDTypography from "components/MDTypography";
import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import useCompanyCache, { useOutsideCompanyCache } from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import LogoTitle from "layouts/pages/web/components/LogoTitle";
import Results from "layouts/pages/web/components/Results";
import TopMainPageContent from "layouts/pages/web/components/TopMainPageContent";
import { getCompanyBaseImageUrl } from "utils/helpers/upload";
import MDBox from "components/MDBox";
import HomeHeader from "../components/HomeHeader";

const TableContainer = styled(FlexWrapper)(({ theme }) => ({
  "& #table-results": {
    "& .MuiBox-root": {
      [theme.breakpoints.down("lTablet")]: {
        padding: "10px",
      },
    },
  },
  "& .MuiPaper-root .MuiBox-root .MuiPaper-root": {
    paddingBottom: "20px",
    "& > div:last-child": {
      height: "100%",
      maxHeight: "450px",
      [theme.breakpoints.up(1680)]: {
        maxHeight: "calc(100vh - 550px)",
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme, url }) => ({
  backgroundImage: url ? `url(${url})` : undefined,

  justifyContent: "center",
  alignItems: "center",
  backgroundSize: "100%",
  backgroundRepeat: "no-repeat",
  position: "relative",
  width: "100%",
  height: "100%",
  minHeight: "50vh",
  [theme.breakpoints.down("laptop")]: {
    height: "100%",
  },
}));

const Homepage = () => {
  const {
    company,
    setCompany,
    companyType,
    setCompanyType,
    imageUrl,
    setImageUrl,
    venues,
    setVenues,
    isMobile,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "public",
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({
    venues,
    setVenues,
    company,
    outsideMode: "public",
  });

  return (
    <>
      <Box sx={{ backgroundColor: "#303030" }}>
        <HomeHeader company={company} />
        {isMobile && (
          <>
            <StyledBox
              url={
                company?.backgroundImageUrl
                  ? `"${getCompanyBaseImageUrl(company)}/backgroundImage/${
                      company?.backgroundImageUrl
                    }"`
                  : null
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  margin: isMobile || "0 25%",
                }}
              >
                {company?.companyType === "Venue" && (
                  <>
                    <MDTypography
                      fontWeight="bold"
                      fontSize={!isMobile ? "5rem" : "3rem"}
                      color="white"
                      textAlign="center"
                      mt={10}
                    >
                      BE A PART OF OUR
                      <span style={{ color: "red" }}>&nbsp;MISSION</span>
                    </MDTypography>
                    <MDTypography
                      variant={!isMobile ? "h4" : "h6"}
                      padding={1}
                      color="white"
                      textAlign="center"
                      mb={isMobile ? 0 : 10}
                    >
                      Join our events team today and experience the magic of the crowd management
                      industry & event staff jobs, making extra money, and meeting new people.
                    </MDTypography>
                  </>
                )}
              </Box>
            </StyledBox>

            <ContainerWrapper>
              <FlexWrapper px={{ xs: 0, sm: 5, sDesktop: 0 }} mt={{ xs: "0px", sDesktop: "30px" }}>
                <TableContainer px={{ sDesktop: 10 }} py={3}>
                  {company?.companyContactMessage ? (
                    <TopMainPageContent company={company} />
                  ) : (
                    <LogoTitle company={company} />
                  )}
                  <Box mt={3} sx={{ width: "100%" }}>
                    <Results />
                  </Box>
                </TableContainer>
              </FlexWrapper>
            </ContainerWrapper>
          </>
        )}
        {!isMobile && (
          <StyledBox
            url={
              company?.backgroundImageUrl
                ? `"${getCompanyBaseImageUrl(company)}/backgroundImage/${
                    company?.backgroundImageUrl
                  }"`
                : null
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                margin: isMobile || "0 25%",
              }}
            >
              {company?.companyType === "Venue" && (
                <>
                  <MDTypography
                    fontWeight="bold"
                    fontSize={!isMobile ? "5rem" : "3rem"}
                    color="white"
                    textAlign="center"
                    mt={10}
                  >
                    BE A PART OF OUR
                    <span style={{ color: "red" }}>&nbsp;MISSION</span>
                  </MDTypography>
                  <MDTypography
                    variant={!isMobile ? "h4" : "h6"}
                    padding={1}
                    color="white"
                    textAlign="center"
                    mb={isMobile ? 0 : 10}
                  >
                    Join our events team today and experience the magic of the crowd management
                    industry & event staff jobs, making extra money, and meeting new people.
                  </MDTypography>
                </>
              )}
            </Box>

            <ContainerWrapper>
              <FlexWrapper px={{ xs: 0, sm: 5, sDesktop: 0 }} mt={{ xs: "0px", sDesktop: "30px" }}>
                <TableContainer px={{ sDesktop: 10 }} py={3}>
                  {
                    // eslint-disable-next-line no-extra-boolean-cast
                    !!company?.companyContactMessage ? (
                      <TopMainPageContent company={company} />
                    ) : (
                      <LogoTitle company={company} />
                    )
                  }
                  <Box mt={3} sx={{ width: "100%" }}>
                    <Results />
                  </Box>
                </TableContainer>
              </FlexWrapper>
            </ContainerWrapper>
          </StyledBox>
        )}
        <MDBox
          sx={{ py: "15px", px: "20px" }}
          bgColor="#222"
          display="flex"
          justifyContent="center"
        >
          <img
            src="/images/png/logos/powered-by-gignology.png"
            alt="powered by gig-nology"
            width="205.8px"
            height="82.8px"
          />
        </MDBox>
      </Box>
    </>
  );
};

export default Homepage;
