import { useState } from "react";

import { Grid, Icon, Stack, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import { CheckCircle, CheckCircleOutline, Cancel, Email } from "@mui/icons-material";

import { dataStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const LocationsItem = ({ row, idx, onEdit, onRemove, onPrimaryChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { locationName, address, city, state, zip, primaryLocation } = row;
  const isPrimary = primaryLocation === "Yes";
  const isIconMenuOpen = Boolean(anchorEl);

  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  const handleChange = (e) => {
    e.stopPropagation();
    onPrimaryChange(idx);
    handleIconClose();
  };

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          onEdit(idx, row);
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={3}>
            <MDTypography sx={styles.font}>{locationName}</MDTypography>
          </Grid>
          <Grid item sm={3}>
            <MDTypography sx={styles.font}>{address}</MDTypography>
          </Grid>
          <Grid item sm={1.5}>
            <MDTypography sx={styles.font}>{city}</MDTypography>
          </Grid>
          <Grid item sm={1}>
            <MDTypography sx={styles.font}>{state}</MDTypography>
          </Grid>
          <Grid item sm={1.5}>
            <MDTypography sx={styles.font}>{zip}</MDTypography>
          </Grid>
          <Grid item sm={1} textAlign="right">
            {isPrimary ? (
              <Tooltip title="Primary" placement="top">
                <CheckCircle color="success" fontSize="medium" sx={{ mb: -0.5, mt: 0.5 }} />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Set Primary" placement="top">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIconClick(e);
                    }}
                  >
                    <CheckCircleOutline color="" fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Menu anchorEl={anchorEl} open={isIconMenuOpen} onClose={handleIconClose}>
                  <MenuItem onClick={handleChange} disableRipple sx={{ p: 0 }}>
                    <MDTypography variant="h6">Set as Primary</MDTypography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Grid>

          <Grid item sm={1}>
            <MDBox textAlign="right">
              {!isPrimary && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(idx);
                  }}
                >
                  <Cancel color="error" fontSize="medium" />
                </IconButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default LocationsItem;
