import axios from "axios";
import { baseAxios } from "config";

const updateApplicantVenue = async ({ applicantId, data }) => {
  console.log("applicantId", applicantId);
  console.log("data", data);
  const res = await baseAxios.put(`/applicants/${applicantId}/venue`, data);
  return res.data;
};

export default updateApplicantVenue;
