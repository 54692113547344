import { Apple, DateRange, Today } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { Button, Card, CardContent, IconButton, Modal } from "@mui/material";
import { useMemo, useState } from "react";

import { GoogleCalendarSvg, OutlookSvg, YahooMailSvg } from "assets/svg/icons";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import {
  addToAppleCalendar,
  addToGoogleCalendar,
  addToOutlookCalendar,
  addToYahooCalendar,
} from "utils/helpers/calendar";
import { removeHtmlTags } from "utils/string";

const AddToCalendarButton = ({ event }) => {

  const { eventDate, eventEndTime, eventName, description, venueName, companyName } = event;
  const [isOpen, toggleModalOpen] = useState(false);
  const calendarButtons = useMemo(
    () => [
      {
        title: "Add to Google Calendar",
        icon: <GoogleCalendarSvg />,
        onClick: () =>
          addToGoogleCalendar({
            startDate: eventDate,
            endDate: eventEndTime,
            title: eventName,
            details: description,
            location: venueName || companyName,
          }),
      },
      {
        title: "Add to Yahoo Calendar",
        icon: <YahooMailSvg />,
        onClick: () =>
          addToYahooCalendar({
            startDate: eventDate,
            endDate: eventEndTime,
            title: eventName,
            details: description,
            location: venueName || companyName,
          }),
      },
      {
        title: "Add to Apple Calendar",
        icon: <Apple sx={{ height: "24px", width: "24px", color: "grey" }} />,
        onClick: () =>
          addToAppleCalendar({
            startDate: eventDate,
            endDate: eventEndTime,
            title: eventName,
            details: removeHtmlTags(description),
            location: venueName || companyName,
          }),
      },
      {
        title: "Download ICS (Outlook)",
        icon: <Today sx={{ height: "24px", width: "24px", color: "green" }} />,
        onClick: () =>
          addToAppleCalendar({
            startDate: eventDate,
            endDate: eventEndTime,
            title: eventName,
            details: description,
            location: venueName || companyName,
          }),
      },
      {
        title: "Add to Outlook Live Calendar",
        icon: <OutlookSvg />,
        onClick: () =>
          addToOutlookCalendar({
            startDate: eventDate,
            endDate: eventEndTime,
            title: eventName,
            details: description,
            location: venueName || companyName,
          }),
      },
    ],
    [event]
  );

  return (
    <>
      <Button
        startIcon={<DateRange />}
        sx={{
          color: "green",
          "& svg": {
            fontSize: "1.15rem !important",
          },
          padding: "0",
          textTransform: 'none',
          fontSize: "1.15rem !important",
        }}
        onClick={() => toggleModalOpen(true)}
      >
        Add to Calendar
      </Button>
      <Modal open={isOpen} onClose={() => toggleModalOpen(false)}>
        <Card
          sx={{
            position: "absolute",
            width: "90%",
            top: "50%",
            right: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "340px",
            maxHeight: "640px",
          }}
        >
          <CardContent sx={{ pt: "50px" }}>
            <FlexWrapper flexDirection="column" alignItems="flex-start" rowGap="10px">
              {calendarButtons.map(({ onClick, title, icon }) => (
                <Button startIcon={icon} onClick={onClick} sx={{ maxWidth: "300px" }}>
                  {title}
                </Button>
              ))}
            </FlexWrapper>
            <IconButton
              onClick={() => toggleModalOpen(false)}
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <Close />
            </IconButton>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default AddToCalendarButton;
